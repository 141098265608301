

/*

    [
        {
            "id": 0,
            "name": "string",
            "desc": "string",
            "code": "string",
            "isPbs": true,
            "vialUnits": 0,
            "marketingUrl": "string",
            "orderUrl": "string",
            "order": 0,
            "created": 0,
            "createdBy": 0,
            "modified": 0,
            "modifiedBy": 0
        }
    ]
*/

function getInjectMedicines() {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/inject-medicines',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: {},

                        inCacheKey: "getInjectMedicines"
                        });
}



module.exports = { 

    getInjectMedicines,

};
