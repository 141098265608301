const axios = require("axios");
//const { date } = require("faker");
//const lodash = require("lodash");
const endpoint = '/rest/user/login';

/*
    RESPONSE
    (dict)
    
    accountStatus: "P"
    created: 1459779796000
    createdBy: 0
    daysLeft: 9999
    modified: 1625270653487
    modifiedBy: 63
    practiseId: 1
    practiseName: "Refresh Richmond 1"
    statusCode: "A"
    userEmail: "peter@interact.technology"
    userEmailValidated: "Y"
    userId: 63
    userIsAccountLocked: "N"
    userLastLogin: 1633480173108
    userName: "Peter Li"
    userRoleCodes: ["S"]
*/

function login({
                inEmail,
                inPassword
                }={}) {

    return new Promise((resolve, reject) => {
        const axiosInstance = axios.create({
            headers: {
                'x-neurointeract-authtoken': 'blah'
            }
        });

        let url = this.baseUrl + endpoint;

        if (this.debugLevel >= 1) {
            console.log("API Request: " + url);
        }

        axiosInstance
            .post(url, 
                /* BODY of POST payload */
                {
                     userEmail : inEmail,
                  userPassword : inPassword
                },
                {
                    /* config */
                }
            )
            .then(response => {
                if (this.debugLevel >= 3) {
                    console.log("API Response (no error): ", response);
                }

                this.btxAuthToken = response.headers['x-neurointeract-authtoken']
                
                // login details are good, so persist it.
                this.d.e = inEmail
                this.d.p = inPassword

                try {
                    this.callbacks.authToken(this.btxAuthToken)
                } catch (err) {
                    console.log("authToken callback threw EXCEPTION: ", err)
                }

                resolve({
                        authToken: response.headers['x-neurointeract-authtoken'],
                        userDetails : response.data,
                        response
                        }
                     );
            })
            .catch(error => {
                if (this.debugLevel >= 2) {
                    console.log("API Response (ERROR): ", /* error.toJSON(), */ error.response);
                }
                
                if (error.response == undefined) {
                    // if we get here we are seriously in trouble
                    
                    reject({
                        responseCode: -666,
                        responseMsg: "You may have networking / connectivity issues.",
                        error /* include the raw error object, rarely will use, but may be needed */
                    });
                } else {
                    reject({
                        responseCode: error.response.data.responseCode,
                        responseMsg: error.response.data.responseMsg,
                        error /* include the raw error object, rarely will use, but may be needed */
                    });
                }
            });
        });
}

module.exports = { login };
