
/**
 *  Used by patient via their phone say OR clinic software, to fetch available consents a patient can consent to.
 * 
 *  NOTE: (Patient Mobile Flow) auth token should be prefixed with "copay-" so the service manager knows to use "copayment-setup-nanoid"
 *  header for auth token, rather than "x-neurointeract-authtoken". For prefixed auth token, we need to override/inject the token
 *  directly into the service manager. itBnTx.btxAuthToken = 'copay-' + copaymentSetupId
 *  (Clinic Flow) use the standard auth token from login.
 * 
 **/
function getAllCopaymentConsents() {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/copayment/consents',
        inRequireAuth: true,
        inQueryParams: {
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: '',

        inCacheKey: "getAllCopaymentConsents"

        });
}

function getAllCopaymentTiers() {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/copayment/payer-tiers',
        inRequireAuth: true,
        inQueryParams: {
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: '',

        inCacheKey: "getAllCopaymentTiers"
        });
}

/*

{
    "id": 1,
    "uuid": "AWvRRMdT3rpKpZl7S9j6P",
    "patientId": 1,
    "consents": [
        {
            "id": 1,
            "uuid": "78d7e6fe6961dfc2324bdcb5a5c9da63",
            "name": "BnTx consent 1",
            "description": "By ticking this box, you acknowledge that the patient has acknowledged and accepted the PBS prescription co-payment terms (up to $42.50 per prescription, excluding private prescriptions).",
            "created": 1649389780808,
            "createdBy": 0,
            "modified": null,
            "modifiedBy": null
        }
    ],
    "patientContact": null,
    "payerTier": null,
    "created": 1649399944609,
    "createdBy": 349,
    "modified": null,
    "modifiedBy": null
}
*/
function getCopaymentDetailsForPatient({inPatientID} = {}) {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/copayment/' + inPatientID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}

// used via clinic portal
function getPatientPaymentConfigURL({inCopaymentConfigID} = {}) {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/copayment/stripe-url/' + inCopaymentConfigID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}

/**
 *  Used by patient via their phone say, allowing them to edit their consent and payment methods.
 *  
 *  NOTE: auth token should be prefixed with "copay-" so the service manager knows to use "copayment-setup-nanoid"
 *  header for auth token, rather than "x-neurointeract-authtoken"
 * 
 *  Returns json with { stripe : 'stripe url' }
 * 
 * @param inCopaymentUUID is ID from the validation call ('/rest/copayment/validate') which they get message from BE via SMS.
 **/
function getMobilePatientPaymentConfigURL({inCopaymentUUID} = {}) {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/copayment/stripe/' + inCopaymentUUID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}


/**
 *  Used to fetch the needed stripe elements config data from BE for a specific copayment uuid.
 *  
 *  Returns json for configuring stripe elements object
 * 
 * @param inCopaymentUUID is ID from the validation call ('/rest/copayment/validate') which they get message from BE via SMS.
 **/
function getStripeClientSecretForCopaymentUUID({inCopaymentUUID} = {}) {
    return this.sendRequest({
        inMethod: 'post',
        inURI: '/rest/copayment/stripe-element/' + inCopaymentUUID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}




/* 
    inDetails - can be the dict from getAllCopaymentTiers or a simple dict with id.

    eg
    {
        "id": 1,
        "uuid": "9b41ac7472decd4870a22034022942aa",
        "tierName": "Medicare",
        "tierOrder": 1,
        "created": 1649161095751,
        "createdBy": 0,
        "modified": null,
        "modifiedBy": null
    }

    OR

    {
        "id" : 1,
        "safetyNet" : false     <<< optional

    }

*/
function setCopaymentTier({inCopaymentConfigID, inTierDetails} = {}) {
    return this.sendRequest({
        inMethod: 'put',
        inURI: '/rest/copayment/payer-tier/' + inCopaymentConfigID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: JSON.stringify(inTierDetails)
        });
}

/**
 *  Used by patient via their phone say OR clinic software, to fetch the consents a patient has consented to.
 * 
 *  NOTE: (Patient Mobile Flow) auth token should be prefixed with "copay-" so the service manager knows to use "copayment-setup-nanoid"
 *  header for auth token, rather than "x-neurointeract-authtoken". For prefixed auth token, we need to override/inject the token
 *  directly into the service manager. itBnTx.btxAuthToken = 'copay-' + copaymentSetupId
 *  (Clinic Flow) use the standard auth token from login.
 * 
 **/
 function getPatientConsentedConsents({inCopaymentUUID} = {}) {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/copayment/consents/' + inCopaymentUUID,
        inRequireAuth: true,
        inQueryParams: {
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: '',

        });
}

function setConsent({inCopaymentConfigID, inConsents} = {}) {
    return this.sendRequest({
        inMethod: 'put',
        inURI: '/rest/copayment/consents/' + inCopaymentConfigID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: JSON.stringify(inConsents)
        });
}

function sendPatientPaymentSetupSMS({ inOrderID } = {}) {
                
    return this.sendRequest({
                        inMethod: 'POST',
                        inURI: '/rest/copayment/payment-setup/' + inOrderID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: {}
                        });
}



function validatePatientCopaymentSMSCode({ inPatientMobile = "", inPatientSMSCode = "" } = {}) {
    return this.sendRequest({
        inMethod: 'post',
        inURI: '/rest/copayment/validate',
        inRequireAuth: false,
        inQueryParams: {
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: JSON.stringify({
                                        'mobile' : inPatientMobile,
                                        'validationCode' : inPatientSMSCode,
                                    }),

        inSilenceError : true

        });
}




module.exports = { 

    getAllCopaymentConsents,
    getAllCopaymentTiers,

    getCopaymentDetailsForPatient,
    getPatientPaymentConfigURL,
    getMobilePatientPaymentConfigURL,

    getStripeClientSecretForCopaymentUUID,

    setCopaymentTier,
    getPatientConsentedConsents,
    setConsent,

    sendPatientPaymentSetupSMS,
    validatePatientCopaymentSMSCode,


};
