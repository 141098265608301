<template>
    <!-- ==================================================================================================
        IMPORTANT NOTE
        The Camera Permissions Prompt and functionality on Phone (iOS) ONLY works when this webapp is loaded from
        https:// as only that is considered secure, loading from http:// simply will not load the camera.
        ================================================================================================== -->
<!-- container -->
    <!-- tailwindcss: class="w-full" -->
    <div class="" style="width: 100%; display: flex; flex-direction: column; align-items: center;">
        <!-- :style="{ height: 'calc(75%)', width: '100%'}" -->

        <div style="margin: 16px; font-weight: 700; font-size: 16px;">Patient Name: {{patientName}}</div>

        <div style="margin: 8px; font-weight: 900; font-size: 30px;">BnTx file uploader</div>

        <div style="margin: 16px; font-weight: 300; font-size: 17px; color: rgba(75, 85, 99, 1); text-align: center;">Take a photo of the script or upload an existing file. It will instantly appear in the order record.</div>

        <Upload
            multiple
            name='uploadedFile'
            :show-upload-list="true"
            :action="uploadActionURL"
            :data="uploadExaminationAttachmentData"
            :before-upload="handleBeforeUploadAttachment"
            :format="['jpg','jpeg','png']"
            :max-size="104800000"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :on-success="handleSuccess"
            :on-error="handleError"
            class=""
            >
            <!-- tailwindcss: class="bg-blue-400 text-white" -->
            <Button class="" style="width: 220px; background-color: rgba(96, 165, 250, 1); color: white;">Upload Files</Button>
        </Upload>

        <Button style="margin-top: 16px; width: 280px; " @click="handleCloseWindow" size="large" type="error">Finished with Patient. Close Window.</Button>

        <Button style="margin-top: 16px; width: 280px; " @click="handleReload" size="small" >Wrong patient? Refresh Now</Button>


        <!-- <div class="">
        
            <div class="w-full border rounded-lg border-gray-300 ">
                <video v-show="showVideo" id="webcam" autoplay playsinline class="w-full p-1"></video>
                <canvas v-show="false" id="canvas" ></canvas>
                <audio id="snapSound" src="audio/snap.wav" preload = "auto"></audio>

                <! -- <img v-show="false" :src="picture"  style="aspect-ratio:auto 640/480" width="auto" height="100%" /> -- >
                <img v-show="!showVideo" :src="picture" width="100%"  />
            </div>

            <div class="absolute border-gray-300 rounded-lg border top-5 left-5 p-2 bg-gray-100 bg-opacity-40">
                <span class="font-bold">Patient:</span> '{{patientName}}' <Button style="margin-left:8px;" size="small" icon="md-refresh" @click="handleReload"></Button>
            </div>

            <div class="absolute border-gray-300 rounded-lg border w-full bottom-2 justify-center px-2 bg-gray-100 bg-opacity-40 flex flex-wrap">

                <Button :disabled="showVideo" @click="startCamera" class="w-44 mt-2 mx-2">Reset</Button>
                <Button :disabled="!showVideo" @click="flipCamera" class="w-44 mt-2 mx-2">Flip Camera</Button>
                <Button :disabled="!showVideo" @click="capture" class="w-44 mt-2 mx-2">Capture Low Resolution</Button>
                <Button :disabled="showVideo" 
                        type="primary"
                        :loading="uploadInProgress"
                        @click="save"
                        class="w-44 mt-2">
                    <span v-if="!uploadInProgress">Upload and Save</span>
                    <span v-else>Saving... please wait.</span>
                </Button>

                <Upload
                    multiple
                    name='uploadedFile'
                    :show-upload-list="true"
                    :action="uploadActionURL"
                    :data="uploadExaminationAttachmentData"
                    :before-upload="handleBeforeUploadAttachment"
                    :format="['jpg','jpeg','png']"
                    :max-size="104800000"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    class="mt-2 mx-2"
                    >
                    <Button class="w-44">Use High Resolution…</Button>
                </Upload>
            </div>


        </div> -->

    </div>
</template>

<script>

//const {CONFIG} = require('@/js/bntx-config')
import AnalyticsMgr from '@/js/AnalyticsManager.js';
// var qs = require('qs');

import Webcam from 'webcam-easy';

const {itBnTx} = require('@/js/itBnTx')

const axios = require('axios');


export default {
    name: 'CaptureMediaOrderScript',
    props: {

    },

    components: {
        // PhotoCapture,
    },

    created () {

   AnalyticsMgr.sendPageView('/CaptureMediaOrderScript')
    },
    mounted() {

        var queryParams = this.$route.query

        if (queryParams.a != undefined) { 
            this.authToken = queryParams.a;
            
            this.$connect(itBnTx.baseWebSocketURL + '/rest/ws/notify?t=' + this.authToken, {
                reconnection: true, // (Boolean) whether to reconnect automatically (false)
                reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
                reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
            })
        } else {
            this.$router.push({name:"login"});
        }

        if (queryParams.p != undefined) { this.practiseId = queryParams.p;  } else { this.$router.push({name:"login"}); }
        // if (queryParams.u != undefined) { this.userId = queryParams.u;      } else { this.$router.push({name:"login"}); }
        if (queryParams.o != undefined) { this.orderUUID = queryParams.o;   } else { this.$router.push({name:"login"}); }
        if (queryParams.n != undefined) { this.patientName = queryParams.n; } else { this.$router.push({name:"login"}); }


        const webcamElement = document.getElementById('webcam');
        const canvasElement = document.getElementById('canvas');
        const snapSoundElement = document.getElementById('snapSound');
        this.webcam = new Webcam(webcamElement, 'user', canvasElement, snapSoundElement);

        this.startCamera()
    },

    data () {
        return {
            showVideo: true,

            webcam : null,
            picture : null,

            // Used by Camera Uploading UI
            authToken : '',
            practiseId : '',
            // userId : '',
            orderUUID : '',
            patientName : '',

            // Used by Uploader Component
            uploadExaminationAttachmentData : {
                practiseId : 0,
                // userId : 0,
                authToken : '',
                uploadType : "OrderAttachment", //"Signature", "LogoLight", "LogoDark", "Attachment"
                orderUuid : 0
            },

            uploadInProgress : false,
        }
    },

    computed: {
        uploadActionURL() {
            return itBnTx.baseUrl + "/rest/user/uploadFile"
        },
    },

    methods: {
        // https://stackoverflow.com/questions/26667820/upload-a-base64-encoded-image-using-formdata
        DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], { type: mimeString })
        },

       async save() {
			var png_data_uri = this.picture
			
            const formData = new FormData();

            formData.append('authToken', this.authToken);
            formData.append('practiseId', this.practiseId);
            // formData.append('userId', this.userId);
            formData.append('orderUuid', this.orderUUID);
            formData.append('uploadType', 'OrderAttachment');

            const file = this.DataURIToBlob(png_data_uri)
            formData.append('uploadedFile', file, 'attachment.jpg') 

            this.uploadInProgress = true

            /*const res = */ await axios.post(this.uploadActionURL, formData, {
                    'Content-Type': 'multipart/form-data'
                })
                .then(response => {
                    console.log(response);
                    this.$Message.success('Uploaded.');
                    
                    AnalyticsMgr.sendEvent('/CaptureMediaOrderScript', 'api', 'CaptureMediaOrderScript_UploadCameraPhoto_Success')

                    try {
                        this.$socket.send('{"cmd":"OSAT"}')
                    } catch(error) {
                        console.log(error)  
                    }
                })
                .catch(error => {
                    console.log(error);

                    AnalyticsMgr.sendEvent('/CaptureMediaOrderScript', 'api', 'CaptureMediaOrderScript_UploadCameraPhoto_Failed')

                    this.$Message.error('ERROR uploading.');
                })
                .then(() => {
                    // always executed
                    this.uploadInProgress = false
                });  

            // console.log(res.data.files); // 'yinyang.png': an extremely long binary string

            // console.log(res.data.form); // form: { id: '1', string: 'Text we want to add to the submit' }

            // console.log(res.data.headers); 
		},
		
        
        startCamera() {
            this.showVideo = true

            this.webcam.start()
                .then(result =>{
                    result
                    console.log("webcam started");
                })
                .catch(err => {
                    console.log(err);
                });
        },

        stopCamera() {
            this.webcam.stop();
            this.showVideo = false
        },

        flipCamera() {
            AnalyticsMgr.sendEvent('/CaptureMediaOrderScript', 'click', 'CaptureMediaOrderScript_FlipCamera')

            this.stopCamera()
            this.webcam.flip()
            this.startCamera()
        },

        capture() {
            // this.picture = this.webcam.snap();

            AnalyticsMgr.sendEvent('/CaptureMediaOrderScript', 'click', 'CaptureMediaOrderScript_Capture')

            this.webcam._canvasElement.height = this.webcam._webcamElement.scrollHeight;
            this.webcam._canvasElement.width = this.webcam._webcamElement.scrollWidth;
            let context = this.webcam._canvasElement.getContext('2d');
            if(this.webcam._facingMode == 'user'){
            context.translate(this.webcam._canvasElement.width, 0);
            context.scale(-1, 1);
            }
            context.clearRect(0, 0, this.webcam._canvasElement.width, this.webcam._canvasElement.height);
            context.drawImage(this.webcam._webcamElement, 0, 0, this.webcam._canvasElement.width, this.webcam._canvasElement.height);
            this.picture = this.webcam._canvasElement.toDataURL('image/jpeg');

            this.stopCamera()
        },

        handleReload() {
            window.location.reload();
        },

        handleCloseWindow() {
            window.close();
        },

        // ===================
        // == Upload Component
        // ===================
        handleBeforeUploadAttachment(inFile) {
            inFile

            this.uploadExaminationAttachmentData.practiseId = this.practiseId
            this.uploadExaminationAttachmentData.userId = this.userId
            this.uploadExaminationAttachmentData.orderUuid = this.orderUUID
            this.uploadExaminationAttachmentData.sessionId = this.sessionId
            this.uploadExaminationAttachmentData.authToken = this.authToken

            return true
        },

        handleFormatError (file) {

            AnalyticsMgr.sendEvent('CaptureMediaOrderScript', 'api', 'CaptureMediaOrderScript_WrongFormat')

            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select jpg, jpeg, png.'
            });
        },
        handleMaxSize (file) {
            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 10 MB.'
            });
        },

        handleSuccess (response, file, fileList) {
            console.log(response)
            console.log(file)
            console.log(fileList)

            AnalyticsMgr.sendEvent('CaptureMediaOrderScript', 'api', 'CaptureMediaOrderScript_UploadMedia_Success')

            this.$Message.success('Uploaded "' + file.name + '"');

            try {
                this.$socket.send('{"cmd":"OSAT"}')
            } catch(error) {
                console.log(error)  
            }
        },

        handleError (error, responseData, fileList) {
            console.log("Upload Error: " + error)
            console.log(responseData)
            console.log(fileList)

            this.$Notice.error({
                title: 'Error Uploading',
                desc: responseData.responseMsg
            });

            AnalyticsMgr.sendEvent('CaptureMediaOrderScript', 'api', 'CaptureMediaOrderScript_UploadMedia_Failed')
        },
    },

    watch: {
   
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* https://stackoverflow.com/questions/1719452/how-to-make-a-div-always-full-screen */
.container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(200, 255, 0, 0.5);
    z-index: 10;
  }

</style>
