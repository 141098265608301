<template>
<div v-observe-visibility="visibilityChanged">
    <Row style="margin-bottom:8px">
        <RadioGroup v-model="currentlyDisplayingSubConditionsForConditionID" type="button" button-style="solid">
            <Radio v-for="item in conditionsRefData"
                    v-show="patientDetails.patientConditionIds != undefined && patientDetails.patientConditionIds.includes(item.conditionId)"
                    :key="item.conditionId"
                    :label="item.conditionId"><span :style="[1, 2, 4, 5, 6, 7, 8, 9, 10].includes(item.conditionId) ? '' : 'font-weight: bold;'">
                                                {{item.conditionName}}
                                            </span>
            </Radio>

        </RadioGroup>
    </Row>

    <Row v-show="[1, 2, 4, 5, 6, 7, 8, 9, 10].includes(currentlyDisplayingSubConditionsForConditionID)">
        <Col>
            No additional details are available to be specified.
        </Col>
    </Row>
            <!-------------------------------->
            <!-- CERVICAL DYSTONIA          -->
            <!-------------------------------->

<!--
cd-101-anterocaput.png
cd-102-anterocollis.png
cd-103-retrocaput.png
cd-104-retrocollis.png
cd-105-sagittal-shift-back.png
cd-106-sagittal-shift-forward.png
cd-107-laterocaput-left.png
cd-108-laterocaput-right.png
cd-109-laterocollis-left.png
cd-110-laterocollis-right.png
cd-111-lateroshift-left.png
cd-112-lateroshift-right.png
cd-113-tortocaput-left.png
cd-114-tortocaput-right.png
cd-115-tortocollis-left.png
cd-116-tortocollis-right.png
cd-117-shoulder-elevation-left.png
cd-118-shoulder-elevation-right.png
cd-119-tremor-multidirectional.png
cd-120-tremor-negative.png
cd-121-tremor-positive.png
-->
    <Row v-show="currentlyDisplayingSubConditionsForConditionID == 3">
        <Col>
            <Divider orientation="left">Patient Condition(s)</Divider>
            <Row style="margin-bottom: 8px">
                <Grid square :border="false" hover>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(101)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-101-anterocaput.png'" /><center>Anterocaput</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(102)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-102-anterocollis.png'" /><center>Anterocollis</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(103)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-103-retrocaput.png'" /><center>Retrocaput</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(104)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-104-retrocollis.png'" /><center>Retrocollis</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(105)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-105-sagittal-shift-back.png'" /><center>Sagittal Shift Back</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(106)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-106-sagittal-shift-forward.png'" /><center>Sagittal Shift Forward</center></div></GridItem>
                        
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(107)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-107-laterocaput-left.png'" /><center>Laterocaput Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(108)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-108-laterocaput-right.png'" /><center>Laterocaput Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(109)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-109-laterocollis-left.png'" /><center>Laterocollis Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(110)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-110-laterocollis-right.png'" /><center>Laterocollis Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(111)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-111-lateroshift-left.png'" /><center>Lateroshift Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(112)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-112-lateroshift-right.png'" /><center>Lateroshift Right</center></div></GridItem>

                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(113)" ><div> <img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-113-tortocaput-left.png'" /><center>Tortocaput Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(114)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-114-tortocaput-right.png'" /><center>Tortocaput Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(115)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-115-tortocollis-left.png'" /><center>Tortocollis Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(116)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-116-tortocollis-right.png'" /><center>Tortocollis Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(117)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-117-shoulder-elevation-left.png'" /><center>Shoulder Elevation Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(118)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-118-shoulder-elevation-right.png'" /><center>Shoulder Elevation Right</center></div></GridItem>

                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(119)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-119-tremor-multidirectional.png'" /><center>Tremor Multidirectional</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(120)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-120-tremor-negative.png'" /><center>Tremor Negative</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(121)" ><div><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-121-tremor-positive.png'" /><center>Tremor Positive</center></div></GridItem>

                        <!-- <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(101)" ><div @click="handleCervicalDystoniaTypeCellClick(101)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-101-anterocaput.png'" /><center>Anterocaput</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(102)" ><div @click="handleCervicalDystoniaTypeCellClick(102)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-102-anterocollis.png'" /><center>Anterocollis</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(103)" ><div @click="handleCervicalDystoniaTypeCellClick(103)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-103-retrocaput.png'" /><center>Retrocaput</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(104)" ><div @click="handleCervicalDystoniaTypeCellClick(104)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-104-retrocollis.png'" /><center>Retrocollis</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(105)" ><div @click="handleCervicalDystoniaTypeCellClick(105)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-105-sagittal-shift-back.png'" /><center>Sagittal Shift Back</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(106)" ><div @click="handleCervicalDystoniaTypeCellClick(106)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-106-sagittal-shift-forward.png'" /><center>Sagittal Shift Forward</center></div></GridItem>
                        
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(107)" ><div @click="handleCervicalDystoniaTypeCellClick(107)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-107-laterocaput-left.png'" /><center>Laterocaput Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(108)" ><div @click="handleCervicalDystoniaTypeCellClick(108)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-108-laterocaput-right.png'" /><center>Laterocaput Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(109)" ><div @click="handleCervicalDystoniaTypeCellClick(109)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-109-laterocollis-left.png'" /><center>Laterocollis Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(110)" ><div @click="handleCervicalDystoniaTypeCellClick(110)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-110-laterocollis-right.png'" /><center>Laterocollis Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(111)" ><div @click="handleCervicalDystoniaTypeCellClick(111)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-111-lateroshift-left.png'" /><center>Lateroshift Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(112)" ><div @click="handleCervicalDystoniaTypeCellClick(112)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-112-lateroshift-right.png'" /><center>Lateroshift Right</center></div></GridItem>

                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(113)" ><div @click="handleCervicalDystoniaTypeCellClick(113)"> <img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-113-tortocaput-left.png'" /><center>Tortocaput Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(114)" ><div @click="handleCervicalDystoniaTypeCellClick(114)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-114-tortocaput-right.png'" /><center>Tortocaput Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(115)" ><div @click="handleCervicalDystoniaTypeCellClick(115)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-115-tortocollis-left.png'" /><center>Tortocollis Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(116)" ><div @click="handleCervicalDystoniaTypeCellClick(116)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-116-tortocollis-right.png'" /><center>Tortocollis Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(117)" ><div @click="handleCervicalDystoniaTypeCellClick(117)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-117-shoulder-elevation-left.png'" /><center>Shoulder Elevation Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(118)" ><div @click="handleCervicalDystoniaTypeCellClick(118)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-118-shoulder-elevation-right.png'" /><center>Shoulder Elevation Right</center></div></GridItem>

                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(119)" ><div @click="handleCervicalDystoniaTypeCellClick(119)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-119-tremor-multidirectional.png'" /><center>Tremor Multidirectional</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(120)" ><div @click="handleCervicalDystoniaTypeCellClick(120)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-120-tremor-negative.png'" /><center>Tremor Negative</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeSelectedSummaryCellStyle()" v-show="cervicalDystoniaSubTypeSelected(121)" ><div @click="handleCervicalDystoniaTypeCellClick(121)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-121-tremor-positive.png'" /><center>Tremor Positive</center></div></GridItem> -->
                </Grid>
                <span style="margin-left: 70    px" v-show="cervicalDystoniaTypes.length == 0">No conditions selected.</span>
            </Row>
            <div v-show="showWarning" class="">* Missing conditions? Please note these conditions are on a per-session basis, which may differ from the patients current conditions.</div>

            <Divider orientation="left">Choose from Condition(s)... &nbsp;&nbsp;&nbsp;<Checkbox v-model="revealCervicalDystoniaSubConditions">Reveal</Checkbox> </Divider>
            <div v-show="revealCervicalDystoniaSubConditions">
                <Row style="margin-bottom: 8px">
                    <Grid square :border="false" hover>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(101)" ><div @click="handleCervicalDystoniaTypeCellClick(101)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-101-anterocaput.png'" /><center>Anterocaput</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(102)" ><div @click="handleCervicalDystoniaTypeCellClick(102)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-102-anterocollis.png'" /><center>Anterocollis</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(103)" ><div @click="handleCervicalDystoniaTypeCellClick(103)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-103-retrocaput.png'" /><center>Retrocaput</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(104)" ><div @click="handleCervicalDystoniaTypeCellClick(104)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-104-retrocollis.png'" /><center>Retrocollis</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(105)" ><div @click="handleCervicalDystoniaTypeCellClick(105)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-105-sagittal-shift-back.png'" /><center>Sagittal Shift Back</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(106)" ><div @click="handleCervicalDystoniaTypeCellClick(106)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-106-sagittal-shift-forward.png'" /><center>Sagittal Shift Forward</center></div></GridItem>
                    </Grid>
                </Row>
                <Row style="margin-bottom: 8px">
                    <Grid square :border="false" hover>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(107)" ><div @click="handleCervicalDystoniaTypeCellClick(107)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-107-laterocaput-left.png'" /><center>Laterocaput Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(108)" ><div @click="handleCervicalDystoniaTypeCellClick(108)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-108-laterocaput-right.png'" /><center>Laterocaput Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(109)" ><div @click="handleCervicalDystoniaTypeCellClick(109)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-109-laterocollis-left.png'" /><center>Laterocollis Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(110)" ><div @click="handleCervicalDystoniaTypeCellClick(110)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-110-laterocollis-right.png'" /><center>Laterocollis Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(111)" ><div @click="handleCervicalDystoniaTypeCellClick(111)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-111-lateroshift-left.png'" /><center>Lateroshift Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(112)" ><div @click="handleCervicalDystoniaTypeCellClick(112)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-112-lateroshift-right.png'" /><center>Lateroshift Right</center></div></GridItem>
                    </Grid>
                </Row>
                <Row  style="margin-bottom: 8px">
                    <Grid square :border="false" hover>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(113)" ><div @click="handleCervicalDystoniaTypeCellClick(113)"> <img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-113-tortocaput-left.png'" /><center>Tortocaput Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(114)" ><div @click="handleCervicalDystoniaTypeCellClick(114)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-114-tortocaput-right.png'" /><center>Tortocaput Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(115)" ><div @click="handleCervicalDystoniaTypeCellClick(115)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-115-tortocollis-left.png'" /><center>Tortocollis Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(116)" ><div @click="handleCervicalDystoniaTypeCellClick(116)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-116-tortocollis-right.png'" /><center>Tortocollis Right</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(117)" ><div @click="handleCervicalDystoniaTypeCellClick(117)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-117-shoulder-elevation-left.png'" /><center>Shoulder Elevation Left</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(118)" ><div @click="handleCervicalDystoniaTypeCellClick(118)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-118-shoulder-elevation-right.png'" /><center>Shoulder Elevation Right</center></div></GridItem>
                    </Grid>
                </Row>

                <Row>
                    <Grid square :border="false" hover>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(119)" ><div @click="handleCervicalDystoniaTypeCellClick(119)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-119-tremor-multidirectional.png'" /><center>Tremor Multidirectional</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(120)" ><div @click="handleCervicalDystoniaTypeCellClick(120)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-120-tremor-negative.png'" /><center>Tremor Negative</center></div></GridItem>
                        <GridItem :style="cervicalDystoniaTypeCellStyle(121)" ><div @click="handleCervicalDystoniaTypeCellClick(121)"><img class="cervicalDystoniaTypeGridImage" :src="'./images/cd-121-tremor-positive.png'" /><center>Tremor Positive</center></div></GridItem>
                    </Grid>
                </Row>
            </div>
        </Col>
    </Row>
    <!-- </TabPane> -->
</div>
</template>

<script>


export default {
  name: 'PatientConditionEditor',
  props: {

    // cervicalDystoniaTypes : {
    //   type: Array,
    //   required: false,
    //   default () {
    //                 return [];
    //             },
    // },

    patientDetails : {      // THIS IS A "PROXY/PROTOCOL" OBJECT, IT ONLY REQUIRES THE TWO FIELDS BELOW, thus you can pass a patientDetails object or an object with the below fields for this 
      type: Object,
      required: false,
      default () {
                    return {
                        patientConditionIds : [],
                        patientSubconditionIds : []
                    }
                },
    },


    showWarning: {
      type: Boolean,
      required: false,
      default: false
    },

    // title: {
    //   type: String,
    //   required: true,
    //   default: ''
    // },

    // description: String,
    
    // titleLineClampCount: {
    //   type: Number,
    //   required: false,
    //   default: 1
    //   // validator: value => ['start', 'middle', 'end'].indexOf(value) !== -1   // todo figure out how to do none|number...
    // },
    
    // descriptionLineClampCount: {
    //   type: Number,
    //   required: false,
    //   default: 3
    // },

    // entryID : {
    //   type: String,
    //   required: true,
    //   default: ''
    // },

    // type: String,

    // tileIconURL: String,
    
    // entryFileURL: String,
    
    // lastModified: Number,

    // tags: {
    //   type: Array,
    //   required: false,
    //   default () {
    //                 return [];
    //             },
    // },

    // contentContext: {
    //   type: String,
    //   required: false,
    //   default: ''
    // },
    
    // clickCallback: {
    //   type: Function,
    //   required: false,
    //   default () {
    //                 return {};
    //             }
    // }
  },

  data() { return {
        currentlyDisplayingSubConditionsForConditionID : 0,
        revealCervicalDystoniaSubConditions : true
    }
  },

  methods: {

    visibilityChanged (isVisible, entry) {
        entry
        
        // always select the first condition the patient has to edit its details.
        if (this.patientDetails.patientConditionIds != undefined) {
            this.currentlyDisplayingSubConditionsForConditionID = this.patientDetails.patientConditionIds[0]
        }

        if (this.cervicalDystoniaTypes.length == 0)
            this.revealCervicalDystoniaSubConditions = true
        else 
            this.revealCervicalDystoniaSubConditions = false

        // if (isVisible == true) {
            //console.log("visibilityChanged (Condition Subtypes)", this.patientDetails)

        // }
    },

    // ================
    // == Cervical Dystonia Tab Helpers
    // ================
    handleCervicalDystoniaTypeCellClick(inTypeID) {
        if (this.cervicalDystoniaTypes.includes(inTypeID)) {
            // if present, then remove...
            const index = this.cervicalDystoniaTypes.indexOf(inTypeID);
            if (index > -1) {
                this.cervicalDystoniaTypes.splice(index, 1);
            }
        } else {
            // otherwise toggle and add it
            this.cervicalDystoniaTypes.push(inTypeID)
        }
    },

    cervicalDystoniaTypeCellStyle(inTypeID) {
        if (this.cervicalDystoniaSubTypeSelected(inTypeID)) {
            // its present, so return style that indicates selected...
            return "background:#F2F2F2; max-width: 140px; border: 2px solid #E0E0E0; font-size:11px;line-height: 1.0;"
        }

        // else not selected
        return "background:white; max-width: 140px; border:2px solid #E0E0E0; font-size:11px;line-height: 1.0;"
    },

    cervicalDystoniaTypeSelectedSummaryCellStyle(/*inTypeID*/) {
        // if (this.cervicalDystoniaTypes.includes(inTypeID)) {
        //     // its present, so return style that indicates selected...
        //     return "background:#F2F2F2; max-width: 180px; border: 2px solid #E0E0E0;"
        // }

        // else not selected
        return "background:white; min-width: 140px; max-width: 140px; border:2px solid #E0E0E0; font-size:11px;line-height: 1.0;"
    },

    cervicalDystoniaSubTypeSelected(inTypeID) {
        return this.cervicalDystoniaTypes.includes(inTypeID);
    },


  },

  computed: {
    conditionsRefData() { return this.$store.state.refDataConditions },

    cervicalDystoniaTypes() {
        // console.log("********", this.patientDetails)

        if (this.patientDetails.patientConditionIds == undefined)
            return []
        if (this.patientDetails.patientSubconditionIds == undefined)
            return []
        
        if (this.patientDetails.patientConditionIds.includes(3 /* Cervical Dystonia */) == true) {
            var cervicalDystoniaSubConditions = this.patientDetails.patientSubconditionIds.filter((element) => {
                if (element.parentConditionId == 3 /* Cervical Dystonia */) {
                    return true
                } else {
                    return false
                }
            })
            if (cervicalDystoniaSubConditions.length == 1) {
                return cervicalDystoniaSubConditions[0].subconditionIds
            } else if (cervicalDystoniaSubConditions.length == 0 ) {
                console.log("ERROR> Missing subcondition array entry with cervical dystonia condition ID")
                return []
            } else {
                console.log("ERROR> multiple subcondition array entries with same parent condition ID - cervical dystonia")
                return []
            }
        }

        return []
    },

  },

  watch : {
    "patientDetails" : function() {
        // ensure what is assigned has the minimum fields this component requires...
        if (this.patientDetails.patientConditionIds == undefined)
            this.patientDetails.patientConditionIds = []
        if (this.patientDetails.patientSubconditionIds == undefined)
            this.patientDetails.patientSubconditionIds = []
    },

    "patientDetails.patientConditionIds" : function() {
        this.patientDetails.patientConditionIds.map((parentConditionID) => {
            var subConditions = this.patientDetails.patientSubconditionIds.filter((element) => {
                if (element.parentConditionId == parentConditionID) {
                    return true
                } else {
                    return false
                }
            })
            if (subConditions.length == 1) {
                // nothing more to do, as we have an entry, so the rest of this component will work well.
            } else if (subConditions.length == 0 ) {
                // we dont appear to have a subcondition entry, so lets create an empty one with empty array of subcondition id's, allowing
                // our component to operate correctly.
                
                var emptySubConditions = {
                    parentConditionId : parentConditionID,
                    subconditionIds : []
                }

                this.patientDetails.patientSubconditionIds.push(emptySubConditions)
            } else {
                // else we have multiple, which really should not happen!
                console.log("ERROR WATCH> multiple subcondition array entries with same parent condition ID - cervical dystonia")
            }
        })

    },

    // 'descriptionClamped': function (newVal, oldVal){
    //   newVal  // shut up lint.
    //   oldVal

    //   console.log(newVal, oldVal);
    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.cervicalDystoniaTypeGridImage {
    width: 100%;
    position: relative;
}

.infoTip {
    font-size: 11px;
    color: rgb(110, 110, 110);
}

</style>
