import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./Store/store";


const {CONFIG} = require('@/js/bntx-config')

import './js/filters.js';


import ViewUI from 'view-design';
import locale from 'view-design/dist/locale/en-US';
Vue.use(ViewUI, { locale });

import iViewPro from '@/libs/iview-pro/iview-pro.min.js';
//import locale_iViewPro from '@/libs/iview-pro/locale/en-US';
//Vue.use(iViewPro, { locale : locale_iViewPro });
Vue.use(iViewPro);

import 'view-design/dist/styles/iview.css';
import '@/libs/iview-pro/iview-pro.css';
import '@/theme/index.less';
import 'vue-resize/dist/vue-resize.css'

// import '@/theme/tailwind.css'

/////////////////
// FONT AWESOME
/////////////////
import { library } from '@fortawesome/fontawesome-svg-core'
import {
          faSyringe, faUserFriends, faChartBar, faSquarePollVertical, faClipboardMedical, faUniversity,

          faFileInvoiceDollar, faDoorOpen,
  
          faClinicMedical, faCreditCard, faUserMd, faUserInjured, faCommentAltEdit, faBooksMedical,

          faHospitalUser, faPrescriptionBottle, faCommentTimes, faCommentCheck, faFilesMedical, faBookMedical, faBadgeCheck, faHandHoldingUsd, faVoteYea,

          faUserAlt, faClipboardListCheck, faMedkit, faStethoscope, faCalendarAlt,

          faFlag, faFlagPennant, faFlagCheckered,

          faScalpel, faGlobe, faHistory, faPaperclip, faCommentEdit, faStarHalfAlt,

          faEye, faCloudDownload, faTrashAlt,

          faHeadSideBrain,

          faChevronCircleUp, faChevronCircleDown, faChevronCircleLeft, faChevronCircleRight, faInfoCircle, 
          
          faUndoAlt, faRedoAlt, faEyeSlash, faRepeatAlt, //faFlagAlt,



        } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
            faSyringe,                 // home nav side bar - patient management
            faUserFriends,             // home nav side bar - patient management
            faChartBar,                // home nav side bar - patient management, superuser
            faSquarePollVertical,      // home nav side bar - patient assessment
            faClipboardMedical,        // home nav side bar x2 - patient management
            faUniversity,              // home nav side bar - patient management

            faFileInvoiceDollar,       // home nav side bar - practice management
            faDoorOpen,                // home nav side bar - practice management

            faClinicMedical,            // home nav side bar - administration
            faCreditCard,               // home nav side bar - administration
            faUserMd,                   // home nav side bar - administration
            faUserInjured,              // home nav side bar - administration
            faCommentAltEdit,           // home nav side bar - administration
            faBooksMedical,             // home nav side bar - administration

            faHospitalUser,             // home nav side bar - superuser
            faPrescriptionBottle,       // home nav side bar - superuser
            faCommentTimes,             // home nav side bar - superuser
            faCommentCheck,             // home nav side bar - superuser
            faFilesMedical,             // home nav side bar - superuser
            faBookMedical,              // home nav side bar - superuser
            faBadgeCheck,               // home nav side bar - superuser
            faHandHoldingUsd,           // home nav side bar - superuser
            faVoteYea,                  // home nav side bar - superuser
            
            faUserAlt,                 // home nav side bar x2 - administration, patient details
            faClipboardListCheck,      // home nav side bar - patient details
            faMedkit,                  // home nav side bar - patient details
            faStethoscope,             // home nav side bar - patient details
            faCalendarAlt,             // home nav side bar - patient details

            faFlag,                    // practice management tab
            faFlagPennant,             // practice management tab
            faFlagCheckered,           // practice management tab

            faScalpel,                 // assessment tab - pre-injection suitability
            faGlobe,                   // assessment tab - cervical dystonia type
            faHistory,                 // assessment tab - HPI
            faPaperclip,               // assessment tab - examination
            faCommentEdit,             // assessment tab - benefits and side effects
            faStarHalfAlt,             // assessment tab - scores and ratings

            faEye,                     // Assessment tab - examination 2x (also used in injection UI)
            faCloudDownload,           // Assessment tab - examination
            faTrashAlt,                // Assessment tab - examination

            faHeadSideBrain,           // Patient Assessment tab - Migraine
            
            faChevronCircleUp,         // Injection Tab
            faChevronCircleDown,       // Injection Tab
            faChevronCircleLeft,       // Injection Tab
            faChevronCircleRight,      // Injection Tab
            faInfoCircle,              // Injection Tab
            faUndoAlt,                 // Injection Tab
            faRedoAlt,                 // Injection Tab
            faEyeSlash,                // Injection Tab
            faRepeatAlt,               // Injection Tab

            )

Vue.component('font-awesome-icon', FontAwesomeIcon)

/*
  load icon for usage...

  import { faSyringe, faUserFriends } from '@fortawesome/pro-light-svg-icons'
  library.add(faSyringe, faUserFriends)

  then use in .vue file
  <font-awesome-icon :icon="['fal', 'syringe']" size="lg" pull="left" style="color:red" />
  <font-awesome-icon :icon="['fal', 'syringe']" size="24" pull="left" style="color:red" />

  GREAT GUIDE... has examples of badges, animation etc.
  https://blog.logrocket.com/full-guide-to-using-font-awesome-icons-in-vue-js-apps-5574c74d9b2d/

  vs iViewUI

  <Icon type="ios-stats-outline" size="24" color="red" />
  

*/
/////////////////



// import { Row, Col, List, ListItem, ListItemMeta, Slider } from 'view-design';
// Vue.component('Row', Slider);
// Vue.component('Col', Slider);
// Vue.component('List', Slider);
// Vue.component('ListItem', Slider);
// Vue.component('ListItemMeta', Slider);

// Vue.component('Slider', Slider);
//Vue.component('Table', Table);


// https://justineo.github.io/vue-clamp/demo/
import VueClamp from 'vue-clamp'
Vue.component('VueClamp', VueClamp);



import VueResize from 'vue-resize'
Vue.use(VueResize)


import iCopyright from '@/components/copyright';
Vue.component('i-copyright', iCopyright);

Vue.use(require("vue-shortkey"));   // https://github.com/iFgR/vue-shortkey

// allows triggering actions when views become visible... allowing optimized loading of content only when required.
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

// adds support for swipe gestures... eg in Injection View
import VueRecognizer from 'vue-recognizer';
Vue.use(VueRecognizer);

// detects idle activity and shows alert with countdown
import Vidle from 'v-idle'
Vue.use(Vidle)

// guided intro sequence
import VueIntro from 'vue-introjs';
Vue.use(VueIntro);
import 'intro.js/introjs.css';

import introJs from 'intro.js'    // webpack way didn't work... add it the bruit force way
window.introJs = introJs          // webpack way didn't work... add it the bruit force way


import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, 'ws://localhost:9090', {
  connectManually: true,
})

// registering chart stuff
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


// Calendar component
import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  // componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  //...,                // ...other defaults
});


// registering vue additional capabilities
import VComp from "@vue/composition-api";
Vue.use(VComp);


const {itBnTx} = require('@/js/itBnTx')

//                        

itBnTx.initialize({inCallbacks : 
                  {
                    initialize: function() { console.log('itBnTx.callbacks.initialize(); complete'); },
                    authToken: function(inToken) { store.commit("btxAuthToken", inToken); },
                    error: function(inError) { ViewUI.Message.error({
                                                                      content: inError.responseMsg + " [ID=" + inError.responseCode + "]",
                                                                      duration: 5 /* show backend errors for longer, as default of 1.5s a bit too short to read */
                                                                    }); }
                  },
                  inEnvironment : undefined
                })

console.log("ENV: " + itBnTx.environment)

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: CONFIG.GOOGLE_ANALYTICS_ID() }
});

// import './assets/tailwind.css'

Vue.config.productionTip = false

//debugger; // eslint-disable-line no-debugger

function startApp() {

  window.name = 'bntx-webapp';

  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}




if (CONFIG.DEV_DEBUG == true) {

  // BEGIN ======= DEVELOPMENT AND DEBUGGING ONLY =======
  // NOTE: this code is similar to the code in Login.vue... 
  itBnTx.login({
                  inEmail : "user",
              inPassword : "pass"
                })
        .then(response => {
          
          store.commit("loggedInDoctorDetails", response.userDetails )
          
          itBnTx.clearAllCache()  // init to well defined state.

          itBnTx.getAllConditions().then(response => {
              store.commit("refDataConditions", response.data )
          })

          var loggedInPracticeData = itBnTx.getPractise({inPractiseID : store.state.loggedInDoctorDetails.practiseId })
          var conditionData = itBnTx.getAllConditions()
          var genderData = itBnTx.getGenders()
          var midasData = itBnTx.getAllMidasQuestions()
          var muscleData = itBnTx.getMuscleImagesData()
          var default_mandatoryPatientDOB = itBnTx.getPractisePref({inPrefKey : "_btxw.default-mandatory-patient-dob", inDefaultValue : "1"});
          var default_mandatoryPatientReferrerPhysician = itBnTx.getPractisePref({inPrefKey : "_btxw.default-mandatory-patient-referrer-physician", inDefaultValue : "1"});
          var default_defaultDateInputFormat = itBnTx.getPractisePref({inPrefKey : "_btxw.default-date-input-format", inDefaultValue : "dd-MM-yyyy"});

          Promise.all([loggedInPracticeData,
                       conditionData,
                       genderData,
                       midasData,
                       muscleData,
                       default_mandatoryPatientDOB,
                       default_mandatoryPatientReferrerPhysician,
                       default_defaultDateInputFormat
                      ]).then(values => {
            // results come back in same order as promise array passed in.
            store.commit("loggedInPracticeDetails", values[0].data)
            store.commit("refDataConditions", values[1].data)
            store.commit("defaultMandatoryPatientDOB", values[5].data.value == "1")
            store.commit("defaultMandatoryPatientReferrerPhysician", values[6].data.value == "1")
            store.commit("defaultDateInputFormat", values[7].data.value)

            startApp()
          })
          .catch(error => {
            error
            ViewUI.Message.error('Loading some initialization data appears to have failed.');
          })
        })
  // END ===============================================

} else {

  startApp()

}


