<template>
    <div id="su-pharmacy-management" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
         <Drawer
            v-model="showDetails"
            width="500"
            :mask-closable="false"
            :styles="detailsDrawerStyles">

            <p slot="header"><center>Pharmacy Details</center></p>

            <Row :gutter="20">
                <Form ref="editEntryDetailsFormFields" :model="editEntryDetails" :rules="editEntryDetailsFieldRules" :label-width="130" label-position="right" style="width: 95%;">
                    <!-- <FormItem label="Title:">
                        <Select v-model="editEntryDetails.title" style="width:200px" filterable>
                            <Option v-for="item in physicianTitlesRefData" :value="item.title" :key="item.title">{{ item.title }}</Option>
                        </Select>
                    </FormItem> -->
                    <FormItem label="Pharmacy Name:" prop="sName" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.sName" ></Input>
                    </FormItem>
                    <FormItem label="Pharmacy Name:" prop="sContact" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.sContact" ></Input>
                    </FormItem>
                    <FormItem label="Pharmacy Email:" prop="sEmail" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.sEmail" ></Input>
                    </FormItem>
                    <FormItem label="Address:" prop="sAddress" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.sAddress" ></Input>
                    </FormItem>
                    <FormItem label="Postcode:" prop="postcode" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.postcode" ></Input>
                    </FormItem>
                    <FormItem label="Country" style="width:100%" prop="countryCode" :class="mandatoryFieldsStyle" >
                        <Select v-model="editEntryDetails.countryCode" style="width:100%" filterable ref="countryCode">
                            <Option v-for="item in countriesRefData" :value="item.code" :key="item.code">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="Contract Ref ID:" prop="sContractId" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.sContractId" placeholder="Eg. HPS-2021-10" ></Input>
                    </FormItem>
                    <!-- <FormItem label="Date of Birth:" prop="dateOfBirth" :class="mandatoryFieldsStyle">
                        <DatePicker v-model="editEntryDetails.dateOfBirth" ref="dateOfBirth" type="date" :options="allowOnlyPastDatesOptions" placeholder="YYYY-MM-DD" style="width: 200px"></DatePicker>
                    </FormItem> -->

                    <FormItem label="Status:">
                        <Select v-model="editEntryDetails.status" style="width:200px" filterable>
                            <Option v-for="item in statusRefData" :value="item.statusCode" :key="item.statusCode">{{ item.statusName }}</Option>
                        </Select>
                    </FormItem>
                    
                </Form>
            </Row>

            <div class="drawer-footer">
                <Button style="margin-right: 8px" @click="handleCancelEntryDetails">Cancel</Button>
                <Button type="primary" :loading="savingDetailsToBackend" @click="handleSaveEntryDetails">
                    <span v-if="!savingDetailsToBackend">Save</span>
                    <span v-else>Saving...</span>
                </Button>
            </div>
        </Drawer>
        
        
        <Divider orientation="left">Pharmacy Management <span style="font-weight:200">({{ entriesTotalCount }} pharmacies)</span>
            <Tooltip placement="bottom">
                <Icon type="md-help-circle" color="#4287f5" />
                <div slot="content" style="white-space: normal">
                    <p>This section allows you to manage all the dispensing pharmacies you have in the BnTx Interact system.</p>
                </div>
            </Tooltip>
        </Divider>
        
        <Row :gutter="8" >
            <Button @click="handleAddEntry" style="margin-left:8px">Add Entry</Button>
            <Button @click="handleEditEntry" style="margin-left:8px; margin-right:8px">Edit Entry</Button>

            <Poptip
                confirm transfer
                width="300"
                :title="confirmRemoveEntryMessage"
                ok-text="Yes"
                cancel-text="No"
                @on-ok="handleRemoveEntry">
                
                <Button type="error" style="margin-right:8px">Remove Entry</Button>
            </Poptip>

            <Input v-model="filterPharmacyBy" placeholder="Search by Pharmacy." :clearable="true" style="width: 200px; margin-right:8px" />
            <!-- <Input v-model="filterLastnameBy" placeholder="Search by lastname." :clearable="true" style="width: 200px; margin-right:8px" /> -->
            <!-- <Input v-model="filterByFirstname" placeholder="First name" :clearable="true" @on-change="throttledSearch" style="width: 300px; margin-right: 8px" />
            <Input v-model="filterByLastname" placeholder="Last name" :clearable="true"  @on-change="throttledSearch" style="width: 300px; margin-right: 8px" /> -->

            <Checkbox v-model="showAllStatusPharmacies" style="margin-top:6px; margin-right:8px">Include All Status Pharmacies</Checkbox>
            

        </Row>

        <Row style="margin-top:8px">
            <Table style="width:100%" :height="tableHeightToFit" size="default" draggable :columns="entriesTableColumnsConfig" :data="entriesToDisplay" border highlight-row @on-current-change="handleEntriesSelectionChanged" @on-row-dblclick="handleEditEntry">
                <template slot-scope="{ row }" slot="status"> {{ row.status | userFriendlyStatus }}</template>

                <!-- <template slot-scope="{ row }" slot="logo">
                    <img v-if="row.practiseLogo != ''" :src="row.practiseLogo" height="50px" />
                </template> -->
            </Table>
        </Row>                                        

       
        <!-- <Row style="margin-top:8px">
            <Col span="24" class="ivu-text-right">
                <Page :total="entriesTotalCount" :page-size="entriesListPageSize" :page-size-opts="[20,100,200,500,1000]" show-sizer @on-change="handleLoadEntriesListToNthPage" @on-page-size-change="handlePageLimitChanged" />
            </Col>
        </Row> -->
       
        <div style="width: 100%">
            <!-- <button @click="shuffleData2">Shuffle</button> -->
            <!-- <BarChart v-bind="barChartProps2" /> -->
            <!-- <LineChart v-bind="barChartProps2" /> -->
            <!-- <ReportInsightsHIT6 /> -->
            <ReportInsightsQoL />
        </div>
    </div>
</template>

<script>
const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");

// import ReportInsightsHIT6 from '@/components/report-insights-hit6.vue'
import ReportInsightsQoL from '@/components/report-insights-qol.vue'



var _ = require('lodash');
// import AnalyticsMgr from '@/js/AnalyticsManager.js';

// import { Utils } from "chart.js";

const {itBnTx} = require('@/js/itBnTx')

// import { LineChart, /*BarChart,*/ /*useBarChart*/ } from "vue-chart-3";
// import { ref, computed, /*defineComponent*/ } from "@vue/composition-api";
// import { shuffle } from "lodash";

// import {DateTime } from "luxon"

// import {enUS} from 'date-fns/locale';
// import 'chartjs-adapter-moment';
// import 'chartjs-adapter-luxon';


export default {
    name: 'su-pharmacy-management',
    components: {
        // LineChart,
        // ReportInsightsHIT6,
        ReportInsightsQoL,
        // BarChart,
    },

    // setup() {
    //     const data = ref([30, 40, 60, 70, 5]);
    //     const legendTop = ref(true);
    //     const imgData = ref(null);

    //     const options = computed(() => ({
    //     scales: {
    //         y: {
    //         beginAtZero: true,
    //         },
    //     },
    //     plugins: {
    //         zoom: {
    //         zoom: {
    //             wheel: {
    //             enabled: true,
    //             },
    //             pinch: {
    //             enabled: true,
    //             },
    //             mode: "xy",
    //         },
    //         },
    //     },
    //     }));

    //     const chartData = computed(() => ({
    //     labels: ["Paris", "Nîmes", "Toulon", "Perpignan", "Autre"],
    //     datasets: [
    //         {
    //         data: data.value,
    //         backgroundColor: [
    //             "#77CEFF",
    //             "#0079AF",
    //             "#123E6B",
    //             "#97B0C4",
    //             "#A5C8ED",
    //         ],
    //         },
    //     ],
    //     }));

    //     const { barChartProps, barChartRef } = useBarChart({
    //     chartData,
    //     options,
    //     });

    //     function shuffleData() {
    //     data.value = shuffle(data.value);
    //     legendTop.value = !legendTop.value;
    //     imgData.value = barChartRef.value.chartInstance.toBase64Image();
    //     //   barChartRef.value.chartInstance.resetZoom();
    //     }

    //     function zoom() {
    //     barChartRef.value.chartInstance.zoom(1.01);
    //     }

    //     return { shuffleData, barChartProps, barChartRef, imgData, zoom };
    // },


    created () {

        // create a throttler function which will automatically manage throttling search requests to a limit, currently 1 per however many milliseconds
        this.throttledSearch = _.debounce(() => { this.handleLoadEntriesListToNthPage(1); console.log("handleThrottledSearch 600ms") }, 600, { 'leading': false })

    },

    destroyed() {

    },

    mounted() {
// console.log("DATE", DateTime.now().plus(1).toJSDate())
    },


    data () {
            // const DATA_COUNT = 7;
            // const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

            return {
//                 barChartProps2 : {
//                     chartData : {
//                         // labels: ["Paris", "Nîmes", "Toulon", "Perpignan", "Autre"],
//                         // labels : [DateTime.now().plus(0).toJSDate(),
//                         //             DateTime.now().plus(1).toJSDate(),
//                         //             DateTime.now().plus(2).toJSDate(),
//                         //             DateTime.now().plus(3).toJSDate(),
//                         //             DateTime.now().plus(4).toJSDate(),
//                         //             DateTime.now().plus(5).toJSDate(),
//                         //             DateTime.now().plus(6).toJSDate(),
//                         //             DateTime.now().plus(7).toJSDate(),
//                         //             DateTime.now().plus(8).toJSDate(),
//                         //             DateTime.now().plus(9).toJSDate(),
//                         //             DateTime.now().plus(10).toJSDate()
//                         // ],

//                         // datasets: [
//                         //     {
//                         //     data: [30, 40, 60, 70, 5],
//                         //     backgroundColor: [
//                         //         "#77CEFF",
//                         //         "#0079AF",
//                         //         "#123E6B",
//                         //         "#97B0C4",
//                         //         "#A5C8ED",
//                         //     ],
//                         //     // backgroundColor: "#0079AF"
//                         //     },
//                         // ],

//                         datasets: [{
//                             label : 'HIT1',
//                             borderColor : 'red',
//                             cubicInterpolationMode: 'monotone',
//                             data:[
//                                     { x: 1644989845000, y: 12 },
//                                     { x: 1645076245000, y: 21 },
//                                     { x: 1645162645000, y: 11 },
//                                     { x: 1645335445000, y: 41 },
//                                     { x: 1645421845000, y: 1 },
//                                     { x: 1645594645000, y: 31 },
//                                     { x: 1645767445000, y: 32 }
//                             ]
//                             // data : [10, 24, 50, 13, 33, 54, 11, 4, 23, 32]
//                         },
//                         {
//                             label : 'HIT2',
//                             borderColor : 'orange',
//                             cubicInterpolationMode: 'monotone',
//                             data:[
//                                     { x: 1644989845000, y: 2 },
//                                     { x: 1645076245000, y: 1 },
//                                     { x: 1645162645000, y: 4 },
//                                     { x: 1645335445000, y: 7 },
//                                     { x: 1645421845000, y: 11 },
//                                     { x: 1645493805000, y: 21 },
//                                     { x: 1645767445000, y: 12 }
//                             ]
//                             // data : [10, 24, 50, 13, 33, 54, 11, 4, 23, 32]
//                         }],
//                     },
//                     options: {
                        
//                         // scales: {
//                         //     y: {
//                         //         beginAtZero: true,
//                         //     },
//                         // },

//                         scales: {
//                             // adapters: {
//                             //     date: {
//                             //         // locale: enUS
//                             //     }
//                             // },

//                             x: {
//                                 type: 'time',
//                                 time: {
//                                   // Luxon format string
//                                   tooltipFormat: 'DD T',
//                                     // unit: 'day'

//                                 },
//                                 title: {
//                                     display: true,
//                                     text: 'Date'
//                                 },
//                                 ticks: {
//                                     // For a category axis, the val is the index so the lookup via getLabelForValue is needed
//                                     callback: function(val, index) {
//                                         // Hide every 2nd tick label
// // console.log(">>>>>", val)

//                                         return index % 2 === 0 ? val : '';
//                                     },
//                                     color: 'red',
//                                 }

//                             },
//                             y: {
//                                 title: {
//                                     display: true,
//                                     text: 'Score'
//                                 }
//                             }
//                         },

//                         plugins: {
//                             title: {
//                                 text: 'Chart.js Time Scale',
//                                 display: true,
//                                 position : 'top'
//                             },
//                             zoom: {
//                                 zoom: {
//                                     wheel: {
//                                       enabled: true,
//                                     },
//                                     pinch: {
//                                       enabled: true,
//                                     },
//                                     mode: "xy",
//                                 },
//                             },
//                         },
//                     },
//                     width: 300,
//                     height: 300
//                 },


                physicianTitlesRefData: CONFIG.PATIENT_TITLES,

                statusRefData : [
                    { statusCode : 'A', statusName: 'Active'},
                    { statusCode : 'I', statusName: 'Inactive'},
                    { statusCode : 'D', statusName: 'Deleted'},
                ],


                entriesTableColumnsConfig: [
                    {                      title: 'Pharmacy Name',        key: 'sName', resizable: true, sortable: false, width: 220 },
                    {                      title: 'Contact',              key: 'sContact', resizable: true, width: 220 },
                    {                      title: 'Email',                key: 'sEmail', resizable: true, width: 220 },
                    {                      title: 'Postcode',             key: 'postcode', resizable: true, width: 150 },
                    {                      title: 'Country Code',         key: 'countryCode', resizable: true, width: 150 },
                    { slot: 'status',      title: 'Status',               key: 'status', resizable: true, width: 150 },
                ],

                throttledSearch : () => {},
                filterByFirstname: "",
                filterByLastname: "",

                entriesListIsLoading : false,
                entriesListPageSize : 20,
                entriesTableData: [],

                entriesTotalCount : 0,
                
                editEntryDetails: {
                                    sName : "",
                                    sContact : "",
                                    sEmail : "",
                                    sAddress : "",
                                    postcode : "",
                                    countryCode : "",
                                    sContractId : "",
                                    status : "A"
                                  },
                
                allowOnlyPastDatesOptions : {
                    disabledDate (date) {
                        return date && date.valueOf() > Date.now();
                    }
                },
                savingDetailsToBackend : false,



                liveValidating: true,
                liveValidatingFieldsOK: true,
                editEntryDetailsFieldRules : {
                    sName: [
                        { required: true, message: 'Pharmacy name cannot be empty', trigger: 'blur' },
                    ],
                    sContact: [
                        { required: true, message: 'Contact cannot be empty', trigger: 'blur' },
                    ],
                    sEmail: [
                        { required: true, message: 'Email cannot be empty', trigger: 'blur' },
                    ],
                    postcode: [
                        { required: true, message: 'Postcode cannot be empty', trigger: 'blur' },
                    ],
                    countryCode: [
                        { required: true, message: 'Country cannot be empty', trigger: 'blur' },
                    ],
                    // dateOfBirth: [
                    //     { required: true, type: 'date', message: 'Date of Birth is required', trigger: 'blur' },
                    // ],
                },



                filterPharmacyBy : '',
                // filterLastnameBy : '',
                showAllStatusPharmacies : false,


                isCreatingNewEntry : false,
                selectedEntryDetailsBeforeCreatingNewEntry : {},      // this is used to restore the previous selection when user cancels create new entry.

                showDetails: false,
                detailsDrawerStyles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static'
                },

                datacollection : {labels:[], datasets:[]},

            }
        },

    filters: {

    },
    
    computed: {

        countriesRefData() { return CONFIG.COUNTRIES; },

        // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
        mandatoryFieldsStyle() { return (this.liveValidating && (this.liveValidatingFieldsOK == false)) ? "revealValidationMessages" : "" },

        entriesToDisplay() {

            return this.entriesTableData.filter(entry => {

                if (this.showAllStatusPharmacies == false && (entry.status != 'A')) return false   // show Active only or everyone.

                // if (entry.firstName == undefined && this.filterFirstnameBy != '') return false  // if we are searching firstname, but entry firstname if not defined or empty, we are definitely not a match.
                // if (entry.lastName == undefined && this.filterLastnameBy != '') return false  // if we are searching firstname, but entry firstname if not defined or empty, we are definitely not a match.


                // // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                // // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                if (this.filterPharmacyBy != '' && entry.sName != undefined && entry.sName.toLowerCase().includes(this.filterPharmacyBy.toLowerCase()) == false) return false // remove all items that dont match our sponsor name filter
                // if (this.filterLastnameBy != '' && entry.lastName != undefined && entry.lastName.toLowerCase().includes(this.filterLastnameBy.toLowerCase()) == false) return false // remove all items that dont match our lastname filter

                return true
            })

        },

        confirmRemoveEntryMessage() {
            return "Are you sure you wish to remove '" 
                    + this.editEntryDetails.sName+ "' from the system?"
        },
        
        tableHeightToFit() {
            return window.innerHeight - 220
        },

    },
    methods: {
        

        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                // AnalyticsMgr.sendPageView('/Superuser/SponsorManagement');

                this.refreshDataForUI()
            }
        },

        refreshDataForUI() {

            this.handleLoadEntriesListToNthPage(1)

        },
        
        handlePageLimitChanged(inNewLimit) {
            this.entriesListPageSize = inNewLimit       // silly two way binding not working... so have to do this way
            this.handleLoadEntriesListToNthPage(1)
        },


        handlePhysicianSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            this.editEntryDetails = currentRow
        },

        handleLoadEntriesListToNthPage(inPageNumber) {
            inPageNumber

            var loadOrSearchPromise;

            // show loading spinner
            this.entriesListIsLoading = true

            // if (this.filterByFirstname != "" || this.filterByLastname != "") {
            //     loadOrSearchPromise = itBnTx.searchPatientsByName({
            //                                     inSearchFirstnameString : this.filterByFirstname,
            //                                     inSearchLastnameString : this.filterByLastname,
            //                                     inStart : this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit : this.entriesListPageSize })
            // } else {
            //     loadOrSearchPromise = itBnTx.searchPatientsInPractise({
            //                                     inStart: this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit: this.entriesListPageSize})
            // }

            loadOrSearchPromise = itBnTx.getAllSponsors()

            loadOrSearchPromise.then(response => {
                
//                var entries = response.data.patients;
                var entries = response.data;

//                this.entriesTotalCount = response.data.totalCount
                this.entriesTotalCount = response.data.length

                // entries.map(entry => { if (entry.userLastLogin == undefined) entry['userLastLogin'] = 0 })
                this.entriesTableData = entries;
                
                var foundEntryToHighlight = false

                if (this.editEntryDetails.sId != undefined) {
                    // if we previously had a selection, try to preserve the same record which was previously selected
                    var previousSelection = this.entriesToDisplay.find(entry => { return entry.sId == this.editEntryDetails.sId} )

                    // sanity check... should never happen... unless someone deletes it from another location.
                    if (previousSelection != undefined) {
                        previousSelection["_highlight"] = true
                        this.handleEntriesSelectionChanged(previousSelection, null)
                        foundEntryToHighlight = true
                    }
                }
                
                if (foundEntryToHighlight == false) {
                    // else select the first record if nothing was selected

                    // if we have at least one entry, select the first one.
                    if (this.entriesToDisplay.length != 0) {
                        this.entriesToDisplay[0]["_highlight"] = true
                        this.handleEntriesSelectionChanged(this.entriesToDisplay[0], null)
                    }
                }

            }).finally(() => {
                // hide loading spinner
                this.entriesListIsLoading = false
            })
        },
        
        
        handleEntriesSelectionChanged(currentRow, oldCurrentRow)  {
            oldCurrentRow
            
            this.editEntryDetails = {}
            
            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                return
            }

            // clone data so when cancelling an edit session, we can restore the original details in UI
            this.editEntryDetails = _.cloneDeep(currentRow)

        },
        
        handleAddEntry() {
            // AnalyticsMgr.sendEvent('/Superuser/SponsorManagement', 'click', 'Sponsor_Add')

            this.liveValidating = false

            this.selectedEntryDetailsBeforeCreatingNewEntry = _.cloneDeep(this.editEntryDetails)

            this.editEntryDetails = {
                                    sName : "",
                                    sContact : "",
                                    sEmail : "",
                                    sAddress : "",
                                    postcode : "",
                                    countryCode : "",
                                    sContractId : "",
                                    status : "A"
                                  },
            this.$refs['editEntryDetailsFormFields'].resetFields()

            this.isCreatingNewEntry = true;
            this.showDetails = true

        },

        handleEditEntry() {
            // AnalyticsMgr.sendEvent('/Superuser/SponsorManagement', 'click', 'Sponsor_Edit')

            this.liveValidating = true

            this.checkFormMandatoryFields()

            this.isCreatingNewEntry = false
            this.showDetails = true
        },

        handleRemoveEntry() {
            // AnalyticsMgr.sendEvent('/Superuser/SponsorManagement', 'click', 'Sponsor_Remove')
        
        // this.data = [30, 40, 60]
        console.log( this)

            // if (this.editEntryDetails.sId != undefined) {
            //     itBnTx.deleteSponsorWithSponsorID({inSponsorID : this.editEntryDetails.sId})
            //           .then(response => {
            //               response
                        
            //               this.refreshDataForUI()

            //               this.$Message.success('Successfully removed/made inactive.');
            //           })
            // }
            
        },


        checkFormMandatoryFields() {
            if (this.liveValidating) {
                this.$refs['editEntryDetailsFormFields'].validate((valid) => {
                    if (valid) {
                        this.liveValidatingFieldsOK = true
                    } else {
                        this.liveValidatingFieldsOK = false
                    }
                })
            }
        },



        handleCancelEntryDetails() {
            // AnalyticsMgr.sendEvent('/Superuser/SponsorManagement', 'click', 'Sponsor_Edit_Cancel')

            if (this.isCreatingNewEntry == false) {
                var previousSelection = this.entriesTableData.find(entry => { return entry.sId == this.editEntryDetails.sId} )

                // restore any changes back to what they were since the user cancelled.
                this.editEntryDetails = _.cloneDeep(previousSelection)
            } else {
                this.editEntryDetails = this.selectedEntryDetailsBeforeCreatingNewEntry
            }

            this.showDetails = false
        },

        handleSaveEntryDetails() {
        //    AnalyticsMgr.sendEvent('/Superuser/SponsorManagement', 'click', 'Sponsor_Edit_Save')

            this.$refs['editEntryDetailsFormFields'].validate((valid) => {
                if (valid) {
                    this.liveValidatingFieldsOK = true

                    var savingDetails = this.editEntryDetails

                    // if we are a new record, make sure to clear the userId so the API knows to create a new record
                    if (savingDetails.sId == undefined) {
                        delete savingDetails.sId
                    }
                                        
                    delete savingDetails._highlight // delete UI key, otherwise API barfs

 //                   console.log("SAVE DETAILS> ", JSON.stringify(this.savingDetails));

                    this.savingDetailsToBackend = true
                    
                    var savingPromise

                    if (this.isCreatingNewEntry == true)
                        savingPromise = itBnTx.createSponsor({inSponsorDetails : savingDetails })
                    else
                        savingPromise = itBnTx.updateSponsor({inSponsorDetails : savingDetails })

                    savingPromise
                          .then(response => {
                                response
                                
                                // reload data into UI
                                this.refreshDataForUI()
                                this.showDetails = false
                          })
                          .finally(() => {
                              this.savingDetailsToBackend = false
                          })

                } else {
                    this.liveValidating = true
                    this.liveValidatingFieldsOK = false

                    this.$Message.error('Enter mandatory fields before trying again.');
                }
            })
        },



    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
    
        // revalidate the form as the user enters details, so give live feedback that what they entered is ok
        "editEntryDetails.sName" :            function() { if (this.liveValidating && this.showDetails == true) this.$refs['editEntryDetailsFormFields'].validate() },
        "editEntryDetails.sEmail" :           function() { if (this.liveValidating && this.showDetails == true) this.$refs['editEntryDetailsFormFields'].validate() },
        "editEntryDetails.sContact" :         function() { if (this.liveValidating && this.showDetails == true) this.$refs['editEntryDetailsFormFields'].validate() },
        "editEntryDetails.postcode" :         function() { if (this.liveValidating && this.showDetails == true) this.$refs['editEntryDetailsFormFields'].validate() },
        "editEntryDetails.countryCode" :      function() { if (this.liveValidating && this.showDetails == true) this.$refs['editEntryDetailsFormFields'].validate() },

    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  
.drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;

 }

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}


.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}



.small {
    max-width: 600px;
    margin:  150px auto;
}

</style>

