<template>
    <div class="layout">
        <!-- <Layout>
            <Sider ref="side1" hide-trigger collapsible :collapsed-width="78" v-model="isCollapsed"> -->
        
        <!-- TAILWINDCSS: class="absolute top-8 left-0 w-8 h-8 rounded-2xl bg-gray-100 hover:bg-gray-200 bg-opacity-80 hover:bg-opacity-80 shadow-lg cursor-pointer" -->
        <div 
        class="mockTailwindHoverButton"
            style="z-index:1000

                    display: none;
                    position: absolute;
                    top: 2rem/* 32px */;
                    left: 0px;
                    width: 2rem /* 32px */;
                    height: 2rem /* 32px */;
                    border-radius: 1rem /* 16px */;
                    background-color: rgba(243, 244, 246, 0.8);
                    cursor: pointer;

                    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
                    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

                    "
             @click="handleToggleLeftNav">

            <!-- TAILWINDCSS: class="mt-1 ml-1" -->
            <Icon class=""
                  style="margin-top: 0.25rem/* 4px */;
                        margin-left: 0.25rem/* 4px */;"
                  :class="rotateIcon"
                  :type="navToggleIcon"
                  size="24"
                  color="black" />
        </div>

        <Layout :style="{height: 'calc(100vh - 2px)'}">
            <Sider v-show="showLeftNav" ref="leftNav" disable__breakpoint="md" :hide-trigger="true" collapsible width="255" :collapsed-width="255" v-model="isCollapsed" :style="{overflow: 'auto'}">

                <Menu width="auto" :active-name="selectedNavMenuID" :open-names="openedNavMenus"  :class="menuitemClasses" v-on:on-select="handleNavMenuSelection">
                    
                    <div style="display: flex; justify-content: center;">
                        <img src="@/assets/app_logo.svg" style="width:160px;padding:12px;"/>
                    </div>


                    <Submenu name="patient-management" v-show="canSeePatientManagementSection" id="side_nav_patient_management" >
                        <template slot="title">
                            <!-- <Icon type="ios-analytics" /> -->
                            Patient management
                        </template>
                        <MenuItem name="pm-patient">                                       <font-awesome-icon :icon="['fal', 'user-friends']" size="lg" pull="left" fixed-width />     Patients</MenuItem>
                        <MenuItem name="pm-assessment" :disabled="currentSessionID == 0">  <font-awesome-icon :icon="['fal', 'chart-bar']" size="lg" pull="left" fixed-width />        Assessments <Badge :count="preInjectionSuitabilityWarningCount" v-show="preInjectionSuitabilityWarningCount != 0" class-name="bntx-badge" style="width:20px"></Badge></MenuItem>
                        <MenuItem name="pm-patient-assessment" v-show="practiceSupportsMigraineApp">  <font-awesome-icon :icon="['fal', 'square-poll-vertical']" size="lg" pull="left" fixed-width />Patient Assessment</MenuItem>
                        <MenuItem name="pm-injections" :disabled="currentSessionID == 0">  <font-awesome-icon :icon="['fal', 'syringe']" size="lg" pull="left" fixed-width />          Injections</MenuItem>
                        <MenuItem name="pm-report" :disabled="currentSessionID == 0">      <font-awesome-icon :icon="['fal', 'clipboard-medical']" size="lg" pull="left" fixed-width />    Report</MenuItem>
                        <MenuItem name="pm-ref-stats" >                                    <font-awesome-icon :icon="['fal', 'chart-bar']" size="lg" pull="left" fixed-width />    Ref National Stats</MenuItem>
                    </Submenu>

                    <Submenu name="practice-management" v-show="practiceSupportsCoPayments" id="side_nav_practice_management" >
                        <template slot="title">
                            <!-- <Icon type="ios-analytics" /> -->
                            Practice management
                        </template>
                        <!-- <MenuItem name="practice-reception">                              <font-awesome-icon :icon="['fal', 'door-open']" size="lg" pull="left" fixed-width />     [WIP] Reception</MenuItem> -->
                        <MenuItem name="practice-copayment">                              <font-awesome-icon :icon="['fal', 'file-invoice-dollar']" size="lg" pull="left" fixed-width />     Orders/Co-payments</MenuItem>
                        <MenuItem name="practice-bntrx">                                  <font-awesome-icon :icon="['fal', 'file-invoice-dollar']" size="lg" pull="left" fixed-width />     BnTRx</MenuItem>
                    </Submenu>

                    <!-- <MenuItem name="pm-education">                                         <font-awesome-icon :icon="['fal', 'university']" size="lg" pull="left" fixed-width />       Community &amp; Resources</MenuItem> -->
                    <MenuItem name="pm-education">                                               Community &amp; Resources</MenuItem>

                    <Submenu name="administration" v-show="canSeePracticeAdminSection" id="side_nav_administration">
                        <template slot="title">
                            <!-- <Icon type="ios-filing" /> -->
                            Administration
                        </template>
                        <MenuItem name="admin-practise-management">         <font-awesome-icon :icon="['fal', 'clinic-medical']" size="lg" pull="left" fixed-width />         Practice Configuration</MenuItem>
                        <MenuItem name="admin-billing">                     <font-awesome-icon :icon="['fal', 'credit-card']" size="lg" pull="left" fixed-width />            Billing</MenuItem>
                        <MenuItem name="admin-user-management">             <font-awesome-icon :icon="['fal', 'user-alt']" size="lg" pull="left" fixed-width />               User Management</MenuItem>
                        <MenuItem name="admin-patient-management">          <font-awesome-icon :icon="['fal', 'user-injured']" size="lg" pull="left" fixed-width />           Patient Management</MenuItem>
                        <MenuItem name="admin-referring-physicians">        <font-awesome-icon :icon="['fal', 'user-md']" size="lg" pull="left" fixed-width />                Referring Physicians</MenuItem>
                        <MenuItem name="admin-setup-practise-hpi-text" v-show="false">     <font-awesome-icon :icon="['fal', 'comment-alt-edit']" size="lg" pull="left" fixed-width />       Setup Practise HPI Text</MenuItem>
                        <MenuItem name="admin-practise-injection-protocols"><font-awesome-icon :icon="['fal', 'books-medical']" size="lg" pull="left" fixed-width />          Injection Protocols</MenuItem>
                    </Submenu>
                    <Submenu name="superuser" v-show="canSeeSuperUserSection">
                        <template slot="title">
                            <!-- <Icon type="ios-filing" /> -->
                            Superuser
                        </template>
                        <MenuItem name="su-setup-practise-and-users">                                  <font-awesome-icon :icon="['fal', 'hospital-user']" size="lg" pull="left" fixed-width />         Setup Practice &amp; Users</MenuItem>
                        <MenuItem v-show="practiceSupportsCoPayments" name="su-pharmacy-management">   <font-awesome-icon :icon="['fal', 'prescription-bottle']" size="lg" pull="left" fixed-width />   [WIP] Pharmacy Management</MenuItem>

<!-- 
                        <MenuItem name="su-setup-side-effects">             <font-awesome-icon :icon="['fal', 'comment-times']" size="lg" pull="left" fixed-width />         Setup Side Effects</MenuItem>
                        <MenuItem name="su-setup-benefits">                 <font-awesome-icon :icon="['fal', 'comment-check']" size="lg" pull="left" fixed-width />         Setup Benefits</MenuItem>
                        <MenuItem name="su-setup-hpi-text">                 <font-awesome-icon :icon="['fal', 'comment-alt-edit']" size="lg" pull="left" fixed-width />      Setup HPI Text</MenuItem>
                        <MenuItem name="su-setup-conditions">               <font-awesome-icon :icon="['fal', 'files-medical']" size="lg" pull="left" fixed-width />         Setup Conditions</MenuItem>
                        <MenuItem name="su-setup-muscle-list">              <font-awesome-icon :icon="['fal', 'book-medical']" size="lg" pull="left" fixed-width />          Setup Muscle List</MenuItem>
                        <MenuItem name="su-setup-assessments">              <font-awesome-icon :icon="['fal', 'chart-bar']" size="lg" pull="left" fixed-width />             Setup Assessments</MenuItem>
                        <MenuItem name="su-setup-recommendations">          <font-awesome-icon :icon="['fal', 'badge-check']" size="lg" pull="left" fixed-width />           Setup Recommendations</MenuItem>
 -->
                        <MenuItem name="su-sponsor-management">             <font-awesome-icon :icon="['fal', 'hand-holding-usd']" size="lg" pull="left" fixed-width />           Sponsor Management</MenuItem>
                        <MenuItem name="su-campaign-management">            <font-awesome-icon :icon="['fal', 'vote-yea']" size="lg" pull="left" fixed-width />           Campaign Management</MenuItem>
                    </Submenu>
                
                    <List border style="width:100%" :split="false" size="small" >
                        <ListItem>Practice Name:<br />
                                {{ loggedInUserDetails.practiseName }}
                        </ListItem>
                        <ListItem>Logged in as:<br />
                                {{ loggedInUserDetails.userName }}
                        </ListItem>
                        <ListItem>
                            
                            <Poptip
                                confirm transfer
                                width="300"
                                title="Are you sure you want to logout?"
                                ok-text="Yes"
                                cancel-text="No"
                                @on-ok="handleLogout">
                                
                                <Button style="margin-right:8px">Logout</Button>
                            </Poptip>
                            
                            <Dropdown @on-click="handleHelpMenu" id="side_nav_helpmenu">
                                <Button type="primary">
                                    Help
                                    <Icon type="ios-arrow-down"></Icon>
                                </Button>
                                
                                <DropdownMenu slot="list">
                                    <DropdownItem name="intro-help">Show Guided Help</DropdownItem>
                                    <DropdownItem name="user-guide">User Guide</DropdownItem>
                                    <DropdownItem name="support" divided>Support</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </ListItem>
                        <ListItem v-show="canSeePatientManagementSection"><font-awesome-icon :icon="['fal', 'user-alt']" size="lg" pull="left" fixed-width />Patient:<br />
                                {{ currentSelectedPatientDetails.firstName }} {{ currentSelectedPatientDetails.lastName }}
                        </ListItem>
                        <ListItem v-show="canSeePatientManagementSection"><font-awesome-icon :icon="['fal', 'clipboard-list-check']" size="lg" pull="left" fixed-width />Conditions:<br />
                                {{ currentSelectedPatientDetails.patientConditionIds | conditionsAsString }}
                        </ListItem>
                        <ListItem v-show="canSeePatientManagementSection"><font-awesome-icon :icon="['fal', 'medkit']" size="lg" pull="left" fixed-width />Treating Physician:<br />
                                {{ currentSelectedPatientDetails.treatingPhysician }}
                        </ListItem>
                        <ListItem v-show="canSeePatientManagementSection"><font-awesome-icon :icon="['fal', 'stethoscope']" size="lg" pull="left" fixed-width />Referring Doctor:<br />
                                {{ currentSelectedPatientDetails.referringPhysician }}
                        </ListItem>
                        <ListItem v-show="canSeePatientManagementSection"><font-awesome-icon :icon="['fal', 'calendar-alt']" size="lg" pull="left" fixed-width />Previous Session:<br />
                                {{ patientLastSessionDetails.sessionDate | userFriendlyDateTime }}
                        </ListItem>
                        <ListItem v-show="canSeePatientManagementSection"><font-awesome-icon :icon="['fal', 'calendar-alt']" size="lg" pull="left" fixed-width />Current Session:<br />
                                {{ patientCurrentSessionDetails.sessionDate | userFriendlyDateTime }}
                        </ListItem>
                        
                        <!-- ID's for Automation Help -->
                        <span id="auto_current_session_id" style="display:none;" :data-value="patientCurrentSessionDetails.sessionId">{{patientCurrentSessionDetails.sessionId}}</span>
                        <span id="auto_current_patient_id" style="display:none;" :data-value="currentSelectedPatientDetails.patientId">{{currentSelectedPatientDetails.patientId}}</span>
                        <div id="auto_config_show" @click="auto_helper_info_show = !auto_helper_info_show">.</div>
                        <div v-show="auto_helper_info_show">
                            <Input element-id="auto_purl" v-model="automation.purl" />
                            <Input element-id="auto_apik" v-model="automation.apik" />
                            <div id="auto_doit" @click="automateAction">*</div>
                        </div>
                    </List>
                </Menu>
            </Sider>

            <Layout >
                <!-- , minHeight: '768px'} -->
                <Content :style="{background: '#fff'}">
                    <Header style="padding: 0; line-height:18px !important; height:auto !important">
                        
                        <!-- ========================== -->
                        <!--    EMAIL VALIDATION        -->
                        <!-- ========================== -->
                        <Alert type="warning" show-icon v-show="loggedInUserDetails.userEmailValidated == 'N'">
                            Email Not Validated
                            <template slot="desc">
                                The email address used during login appears to not be validated. Please check your email and validate at your earliest convenience for improved security.
                            </template>
                        </Alert>
                        
                        <!-- <WarningBanner 
                            v-show="loggedInUserDetails.userEmailValidated == 'N'"
                            message="Email address is currently not validated. Check your email to validate at your earliest convenience for improved security." /> -->

                        <!-- ========================== -->
                        <!--    TRIAL ENDING            -->
                        <!-- ========================== -->
                        <Alert type="warning" show-icon v-show="loggedInUserDetails.accountStatus == 'T' && loggedInUserDetails.daysLeft < 4">
                            Trial Period Ending
                            <template slot="desc">
                                Thank you for evaluating BnTx Interact. You have {{loggedInUserDetails.daysLeft}} day{{ loggedInUserDetails.daysLeft == 1 ? '' : 's' }} left to evaluate.
                                To continue using please complete the payment details in Billing under the Administration section.
                            </template>
                        </Alert>

                        <!-- ============================= -->
                        <!--    PAYMENT METHOD PROBLEMS    -->
                        <!-- ============================= -->
                        <Alert type="warning" show-icon v-show="loggedInUserDetails.accountStatus == 'P' && loggedInUserDetails.daysLeft < 15 && loggedInUserDetails.paymentMethodIssueExists == true">
                            Payment Method Issues
                            <template slot="desc">
                                We hope you are enjoying using BnTx Interact.
                                Your subscription is coming up for renewal in {{loggedInUserDetails.daysLeft}} day{{ loggedInUserDetails.daysLeft == 1 ? '' : 's' }}, and it appears there is an issue with your payment method.
                                To continue using the service uninterrupted, please update the payment details in Billing under the Administration section.
                            </template>
                        </Alert>
                    </Header>
                    
                    <Alert type="warning" ref="idleAlert" show-icon v-show="showIdleActivityAlert" closable @on-close="handleIdleAlertClosed">
                        Auto Logout Pending
                        <template slot="desc">Are you still there?<br />The system will auto-logout if there continues to be inactivity.
                            <v-idle
                                @idle="handleOnIdleActivity"
                                @remind="handleOnImminentIdleReminder"
                                :reminders="[30]"
                                :duration="idleActivityDurationInSeconds" />
                            </template>
                        <span slot="close">I'm still here</span>
                    </Alert>
                <!-- <Col span="20"> -->
                    <PMPatients v-show="showPMPatient" />
                    <PMInjections v-show="showPMInjections" />
                    <PMAssessments v-show="showPMAssessment" />
                    <PMPatientAssessment v-show="showPMPatientAssessment" />
                    <PMReport v-show="showPMReport" />
                    <PMRefStats v-show="showPMRefStats" />
                    <PMEducation v-show="showPMEducation" />

                    <PracticeCoPayment v-show="showPracticeCoPayment" />
                    <PracticeBnTRx v-show="showPracticeBnTRx" />
                    <PracticeReception v-show="showPracticeReception" />
                    
                    <AdminPractiseManagement v-show="showAdminPractiseManagement" />
                    <AdminBilling v-show="showAdminBilling" />
                    <AdminUserManagement v-show="showAdminUserManagement" />
                    <AdminPatientManagement v-show="showAdminPatientManagement" />
                    <AdminReferringPhysicians v-show="showAdminReferringPhysicians" />
                    <AdminSetupPractiseHPIText v-show="showAdminSetupPractiseHPIText" />
                    <AdminPractiseInjectionProtocols v-show="showAdminPractiseInjectionProtocols" />

                    <!-- <SUSetupPractisesAndUsers v-show="showSUSetupPractisesAndUsers" />
                    <SUSetupSideEffects v-show="showSUSetupSideEffects" />
                    <SUSetupBenefits v-show="showSUSetupBenefits" />
                    <SUSetupHPIText v-show="showSUSetupHPIText" />
                    <SUSetupConditions v-show="showSUConditions" />
                    <SUSetupMuscleList v-show="showSUMuscleList" />
                    <SUSetupAssessments v-show="showSUAssessments" />
                    <SUSetupRecommendations v-show="showSURecommendations" /> -->
                    <component v-show="showSuperUserSection" :is="superUserTabToShow" />

                    <!-- <Button @click="handleToggleLeftNav" type="info" icon="md-more" class="hideShowNavToggle" /> -->

                    <!-- </Sider>
                    <Layout>
                        <Header :style="{padding: 0}" class="layout-header-bar">
                            <Icon @click.native="collapsedSider" :class="rotateIcon" :style="{margin: '0 20px'}" type="md-menu" size="24"></Icon>
                        </Header>
                        <Content :style="{margin: '20px', background: '#fff', minHeight: '260px'}">
                            Content
                        </Content>
                    </Layout>
                </Layout> -->

                </Content>
            </Layout>
        </Layout>

    </div>
</template>

<script>
import INTROS from '@/js/introjs-config.js';

const {CONFIG} = require('@/js/bntx-config')
const {itBnTx} = require('@/js/itBnTx')

var _ = require('lodash');

//import WarningBanner from '@/components/WarningBanner';

import PMPatients from '@/components/pm-patients';
import PMInjections from '@/components/pm-injections';
import PMAssessments from '@/components/pm-assessments';
import PMPatientAssessment from '@/components/pm-patient-assessment';
import PMReport from '@/components/pm-report';
import PMRefStats from '@/components/pm-ref-stats';
import PMEducation from '@/components/pm-education';

import PracticeCoPayment from '@/components/practice-copayment';
import PracticeBnTRx from '@/components/practice-bntrx';
import PracticeReception from '@/components/practice-reception';

import AdminPractiseManagement from '@/components/admin-practise-management';
import AdminBilling from '@/components/admin-billing';
import AdminUserManagement from '@/components/admin-user-management';
import AdminPatientManagement from '@/components/admin-patient-management';
import AdminReferringPhysicians from '@/components/admin-referring-physicians';
import AdminSetupPractiseHPIText from '@/components/admin-setup-practise-hpi-text';
import AdminPractiseInjectionProtocols from '@/components/admin-practise-injection-protocols';

import SUSetupPractisesAndUsers from '@/components/su-setup-practises-and-users';
import SUPharmacyManagement from '@/components/su-pharmacy-management';

// import SUSetupSideEffects from '@/components/su-setup-side-effects';
// import SUSetupBenefits from '@/components/su-setup-benefits';
// import SUSetupHPIText from '@/components/su-setup-hpi-text';
// import SUSetupConditions from '@/components/su-setup-conditions';
// import SUSetupMuscleList from '@/components/su-setup-muscle-list';
// import SUSetupAssessments from '@/components/su-setup-assessments';
// import SUSetupRecommendations from '@/components/su-setup-recommendations';

import SUSponsorManagement from '@/components/su-sponsor-management';
import SUCampaignManagement from '@/components/su-campaign-management';

/*
    https://www.npmjs.com/package/@fortawesome/vue-fontawesome
*/


export default {
    name: 'bntx-home',
    components: {
        // WarningBanner,

        PMPatients,
        PMInjections,
        PMAssessments,
        PMPatientAssessment,
        PMReport,
        PMRefStats,
        PMEducation,

        PracticeCoPayment,
        PracticeBnTRx,
        PracticeReception,

        AdminPractiseManagement,
        AdminBilling,
        AdminUserManagement,
        AdminPatientManagement,
        AdminReferringPhysicians,
        AdminSetupPractiseHPIText,
        AdminPractiseInjectionProtocols,
        

        SUSetupPractisesAndUsers,
        SUPharmacyManagement,

        // SUSetupSideEffects,
        // SUSetupBenefits,
        // SUSetupHPIText,
        // SUSetupConditions,
        // SUSetupMuscleList,
        // SUSetupAssessments,
        // SUSetupRecommendations,

        SUSponsorManagement,
        SUCampaignManagement
    },

    created () {
        // init to zero, so the nav menu disable states render properly, to prevent premature clicking.
        this.$store.commit("currentPatientID", 0);
        this.$store.commit("currentSessionID", 0);
    },

    destroyed() {

    },

    mounted() {

        // this will add triggering of a confirmation alert, which appears to be controlled by the specific browsers... so message below
        // is irrelevant. 
        // NOTE: the alert is triggered automatically when a page with interactivity is allowed is entered... eg page with input fields
        // if we never show any interactive elements then there will be nothing to save.
        // We put the code here, as if we get here, we should have logged in... which is when something may have been entered to be saved.
        // We added this so as to reduce the API calls when say switching tabs to continually save... just in case. With this in place we
        // can remove the excessive saving, as its up to the user to cancel and go back to save.

        if (CONFIG.DEV_DEBUG == false) {
            window.onbeforeunload = function(){
                return "Please logout before closing the window to avoid losing any patient data entered.";
            }
        }


        // Check the users' role, if they have access to see the patient screen, show that...
        // otherwise if they only can see the admin screen, show that... otherwise... we are in trouble :)
        if (this.loggedInUserDetails.userRoleCodes != undefined) {

            // NOTE: setTimeout added here to not stuff up the MS Visual Studio Code debugging of UI... one day figure out the root cause.
            setTimeout(() => {
                // if (this.loggedInUserDetails.userRoleCodes.includes('P')    // physician
                //     || this.loggedInUserDetails.userRoleCodes.includes('N') // nurse
                // )
                if (this.canSeePatientManagementSection == true)
                {
                    this.openedNavMenus = ['patient-management']
                    this.switchNavMenuSelection('pm-patient')
                    // this.$store.commit("currentNavMenuSelectionID", 'pm-patient');
                //} else if (this.loggedInUserDetails.userRoleCodes.includes('PA')) {
                } else if (this.canSeePracticeAdminSection == true) {

                    this.openedNavMenus = ['administration']
                    this.switchNavMenuSelection('admin-practise-management')
                    // this.$store.commit("currentNavMenuSelectionID", 'admin-practise-management');
                } else {
                    // else maybe I am super user or some other thing... just show patient UI
                    this.openedNavMenus = ['patient-management']
                    this.selectedNavMenuID = 'pm-education'
                    this.switchNavMenuSelection('pm-education')
                    // this.$store.commit("currentNavMenuSelectionID", 'pm-patient');
                }
            }, 100)
            
        }

    },

    data () {
            return {
                isCollapsed: false,

                openedNavMenus: ['patient-management'],

                //practiseName: "My Practise Name",
                //loggedInDoctorName: "John Smith",

                patient: {
                    name: "-- Patient Name --",
                    conditions: ["-- Condition --"],
                    treatingPhysician: "-- Treating Physician --",
                    referringDoctor: "-- Referring Doctor --",
                    lastSession: "Thursday, 1 Jun 2017 9:56 am",
                    currentSession: "Friday, 2 Jun 2017 12:56 pm",
                    

                },

                showLeftNav : true,
                
                guidedHelpTimer : null,


                showIdleActivityAlert : false,

                selectedNavMenuID : 'pm-patient',

                showPMPatient: true,
                showPMAssessment: false,
                showPMPatientAssessment: false,
                showPMInjections: false,
                showPMReport: false,
                showPMRefStats: false,
                showPMEducation: false,

                showPracticeCoPayment: false,
                showPracticeBnTRx: false,
                showPracticeReception: false,

                showAdminPractiseManagement: false,
                showAdminBilling: false,
                showAdminUserManagement: false,
                showAdminPatientManagement: false,
                showAdminReferringPhysicians: false,
                showAdminSetupPractiseHPIText: false,
                showAdminPractiseInjectionProtocols: false,
                
                showSUSetupPractisesAndUsers: false,
                showSUPharmacyManagement: false,

                showSUSetupSideEffects: false,
                showSUSetupBenefits: false,
                showSUSetupHPIText: false,
                showSUConditions: false,
                showSUMuscleList: false,
                showSUAssessments: false,
                showSURecommendations: false,

                showSUSponsorManagement: false,
                showSUCampaignManagement: false,
                
                superUserTabToShow: '',
                showSuperUserSection: false,


                auto_helper_info_show : false,
                automation : {
                    purl : "",
                    apik : ""
                }
            }
        },
    computed: {

        loggedInUserDetails()             { return this.$store.state.loggedInDoctorDetails },
        currentSelectedPatientDetails()   { return this.$store.state.currentSelectedPatientDetails },
        currentSessionID()                { return this.$store.state.currentSessionID },

        practiceSupportsCoPayments()      { return this.$store.state.loggedInPracticeDetails.supportsCoPayments },
        practiceSupportsMigraineApp()     { return this.$store.state.loggedInPracticeDetails.supportsMigrainePatientApp },


        canSeePatientManagementSection() {
            // check if trial over or paid subscription still ok
            // if CC expired for more than 31 days and still not updated, then they have not paid for the next month.
            if (this.loggedInUserDetails != undefined
                && (
                    (this.loggedInUserDetails.accountStatus == 'T' && this.loggedInUserDetails.daysLeft < 0)
                    || (this.loggedInUserDetails.accountStatus == 'P' && this.loggedInUserDetails.daysLeft < -31)
                )) {
                
                return false
            }       

            if (this.loggedInUserDetails != undefined
                && this.loggedInUserDetails.userRoleCodes != undefined
                && (this.loggedInUserDetails.userRoleCodes.includes("P")
                    || this.loggedInUserDetails.userRoleCodes.includes("N")
                    //|| this.loggedInUserDetails.userRoleCodes.includes("PA")
                    || this.loggedInUserDetails.userRoleCodes.includes("S")
                    ))
                return true
            
            return false
        },

        canSeePracticeAdminSection() {

            if (this.loggedInUserDetails != undefined
                && this.loggedInUserDetails.userRoleCodes != undefined
                && (this.loggedInUserDetails.userRoleCodes.includes("S")
                    || this.loggedInUserDetails.userRoleCodes.includes("PA")))
                return true
            
            return false
        },

        canSeeSuperUserSection() {
            if (this.loggedInUserDetails != undefined
                && this.loggedInUserDetails.userRoleCodes != undefined
                && this.loggedInUserDetails.userRoleCodes.includes("S"))
                return true
            
            return false
        },

        patientLastSessionDetails() {
            var sessions = this.$store.state.currentPatientSessionsSummary;
            if (sessions.length == 0)
                return {}
            return sessions[0]  // assuming array is ordered cronologically
        },

        patientCurrentSessionDetails() {
            var session = this.$store.state.currentPatientSessionsSummary.find(entry => { return entry.sessionId == this.$store.state.currentSessionID });
            return (session == undefined) ? {} : session
        },

        navToggleIcon() {
            return this.showLeftNav ? 'md-menu' : 'md-add'
        },

        rotateIcon () {
            return [
                'menu-icon',
                !this.showLeftNav ? 'rotate-icon' : ''
            ];
        },
        // rotateIcon () {
        //     return [
        //         'menu-icon',
        //         this.isCollapsed ? 'rotate-icon' : ''
        //     ];
        // },
        menuitemClasses () {
            return [
                'menu-item',
                this.isCollapsed ? 'collapsed-menu' : ''
            ]
        },

        idleActivityDurationInSeconds() { return CONFIG.IDLE_ACTIVITY_DURATION_IN_SECONDS },

        preInjectionSuitabilityWarningCount() {
            var currentSession = this.$store.state.currentPatientSessionsSummary.find(entry => { return entry.sessionId == this.$store.state.currentSessionID })

            if (currentSession == undefined)
                return 0

            return currentSession.preInjectionWarningCount
        },


    },
    methods: {
        collapsedSider () {
            this.$refs.side1.toggleCollapse();
        },

        handleNavMenuSelection(inMenu) {
            console.log("handleNavMenuSelection", inMenu, this.$store.state.currentNavMenuSelectionID)

            this.$store.commit("currentNavMenuSelectionID", inMenu);
        },

        handleToggleLeftNav() {
            this.showLeftNav = !this.showLeftNav
            //this.$refs.leftNav.toggleCollapse()                    
        },

        switchNavMenuSelection(inMenu) {
            console.log("switchNavMenuSelection", inMenu)

            // if (inMenu == 'pm-education') {
            //     window.open(CONFIG.EDUCATION_TRAINING_URL + encodeURIComponent(this.$store.state.btxAuthToken), '_blank');
            //     return
            // }


            this.showPMPatient = false;
            this.showPMInjections = false;
            this.showPMAssessment = false;
            this.showPMPatientAssessment = false;
            this.showPMReport = false;
            this.showPMRefStats = false;
            this.showPMEducation = false;

            this.showPracticeCoPayment = false;
            this.showPracticeBnTRx = false;
            this.showPracticeReception = false;

            this.showAdminPractiseManagement = false;
            this.showAdminBilling = false;
            this.showAdminUserManagement = false;
            this.showAdminPatientManagement = false;
            this.showAdminReferringPhysicians = false;
            this.showAdminSetupPractiseHPIText = false;
            this.showAdminPractiseInjectionProtocols = false;

            this.showSUSetupPractisesAndUsers = false;
            this.showSUPharmacyManagement = false;

            this.showSUSetupSideEffects = false;
            this.showSUSetupBenefits = false;
            this.showSUSetupHPIText = false;
            this.showSUConditions = false;
            this.showSUMuscleList = false;
            this.showSUAssessments = false;
            this.showSURecommendations = false;

            this.showSUSponsorManagement = false;
            this.showSUCampaignManagement = false;


            this.showSuperUserSection = false;

            // reset, then set to an ID if we support help... eventually when all UI's support guided help, this line becomes irrelivent
            this.$store.commit("currentUIContextHelpID", '');

            switch (inMenu) {
                case 'pm-patient':
                    this.showPMPatient = true;
                    this.$store.commit("currentUIContextHelpID", 'help-id-patients-main');
                    break;

                case 'pm-injections':
                    this.showPMInjections = true;
                    this.$store.commit("currentUIContextHelpID", 'help-id-injections-main');
                    break;
                case 'pm-assessment': this.showPMAssessment = true; break;
                case 'pm-patient-assessment': this.showPMPatientAssessment = true; break;
                case 'pm-report': this.showPMReport = true; break;
                case 'pm-ref-stats': this.showPMRefStats = true; break;
                case 'pm-education': this.showPMEducation = true; break;

                case 'practice-copayment': this.showPracticeCoPayment = true; break;
                case 'practice-bntrx': this.showPracticeBnTRx = true; break;
                case 'practice-reception': this.showPracticeReception = true; break;

                case 'admin-practise-management': this.showAdminPractiseManagement = true; break;
                case 'admin-billing': this.showAdminBilling = true; break;
                case 'admin-user-management': this.showAdminUserManagement = true; break;
                case 'admin-patient-management': this.showAdminPatientManagement = true; break;
                case 'admin-referring-physicians': this.showAdminReferringPhysicians = true; break;
                case 'admin-setup-practise-hpi-text': this.showAdminSetupPractiseHPIText = true; break;
                case 'admin-practise-injection-protocols': this.showAdminPractiseInjectionProtocols = true; break;

                case 'su-setup-practise-and-users': this.showSUSetupPractisesAndUsers = true; this.showSuperUserSection = true; this.superUserTabToShow = 'SUSetupPractisesAndUsers'; break;
                case 'su-pharmacy-management': this.showSUPharmacyManagement = true; this.showSuperUserSection = true;  this.superUserTabToShow = 'SUPharmacyManagement'; break;

                case 'su-setup-side-effects': this.showSUSetupSideEffects = true; this.showSuperUserSection = true; this.superUserTabToShow = 'SUSetupSideEffects'; break;
                case 'su-setup-benefits': this.showSUSetupBenefits = true; this.showSuperUserSection = true; this.superUserTabToShow = 'SUSetupBenefits'; break;
                case 'su-setup-hpi-text': this.showSUSetupHPIText = true; this.showSuperUserSection = true; this.superUserTabToShow = 'SUSetupHPIText'; break;
                case 'su-setup-conditions': this.showSUConditions = true; this.showSuperUserSection = true; this.superUserTabToShow = 'SUSetupConditions'; break;
                case 'su-setup-muscle-list': this.showSUMuscleList = true; this.showSuperUserSection = true; this.superUserTabToShow = 'SUSetupMuscleList'; break;
                case 'su-setup-assessments': this.showSUAssessments = true; this.showSuperUserSection = true; this.superUserTabToShow = 'SUSetupAssessments'; break;
                case 'su-setup-recommendations': this.showSURecommendations = true; this.showSuperUserSection = true;  this.superUserTabToShow = 'SUSetupRecommendations'; break;

                case 'su-sponsor-management': this.showSUSponsorManagement = true; this.showSuperUserSection = true;  this.superUserTabToShow = 'SUSponsorManagement'; break;
                case 'su-campaign-management': this.showSUCampaignManagement = true; this.showSuperUserSection = true;  this.superUserTabToShow = 'SUCampaignManagement'; break;
            }
        },

        handleLogout() {
            itBnTx.clearAllCache()

            // disconnect the websocket stuff, so we dont get duplicate messages.
            this.$disconnect()

            this.$router.push({name:"login"});
        },

        handleHelpMenu(inMenuName) {
            switch( inMenuName ) {
                case 'intro-help':
                    var helpScript = []

                    //console.log("GUIDED HELP: ", this.$store.state.currentUIContextHelpID)
                    var helpIDToShow = this.$store.state.currentUIContextHelpID

                    switch (helpIDToShow) {
                        case 'help-id-patients-main':
                            helpScript = _.concat(INTROS.introHelpLocation(), INTROS.mainNavIntro(), INTROS.patientsMainUIIntros())
                            break;

                        case 'help-id-injections-main':
                            helpScript = INTROS.injectionsMainUIIntros()
                            break;
                        default:
                            helpScript = INTROS.noHelpForThisSection()
                            break;
                    }

                    if (helpScript.length != 0) {
                        this.$intro()
                            .setOptions({ steps: helpScript })
                            .start()
                            .onexit(() => {
                                // we ended or exited... either way user is finished with viewing this specific UI's help guide
                                // so lets tag it as 'viewed' so we dont show it again.
                                itBnTx.updateUserPref({   inPrefKey : CONFIG.USER_PREF_PREFIX + helpIDToShow,
                                                        inPrefValue : "1" })
                            })
                    }
                    break;
                case 'user-guide':
                    window.open(CONFIG.USER_GUIDE_URL(), '_blank');
                    break;
                
                case 'support':
                    window.open(CONFIG.SUPPORT_EMAIL, '_new');
                    break;

            }
        },

        handleIdleAlertClosed()        { this.showIdleActivityAlert = false; this.$refs.idleAlert.closed = false; },
        handleOnImminentIdleReminder() { this.showIdleActivityAlert = true; },
        handleOnIdleActivity()         { this.handleLogout() },

        automateAction() { 
            //alert('clicked:' + this.automation.purl + ", " + this.automation.apik) 
            console.log('PING API:' + this.automation.purl + ", " + this.automation.apik)
            itBnTx.pingBEURI({inURI : this.automation.purl, inAPIKey : this.automation.apik })
        }
    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
        "$store.state.loggedInDoctorDetails" (newValue, oldValue) {
            oldValue

            if (_.isEmpty(newValue) == false) {

console.log("logged in as: ", newValue)

                
            } 

        },

        "$store.state.currentNavMenuSelectionID" (newValue, oldValue) {
            console.log("$store.state.currentNavMenuSelectionID", oldValue, newValue);
            oldValue;
            this.switchNavMenuSelection(newValue); this.selectedNavMenuID = newValue;
        },
    
        "$store.state.currentUIContextHelpID" (newValue, oldValue) {
            oldValue;
            
            if (newValue != '') {
                itBnTx.getUserPref({      inPrefKey : CONFIG.USER_PREF_PREFIX + newValue,
                                     inDefaultValue : '0' })
                      .then(response => {
                          if (response.data.value == "0") {

                              // the user has not seen the help guide for this UI, so lets queue it for showing to the user.
                              this.guidedHelpTimer = setTimeout(() => {
                                  this.handleHelpMenu('intro-help')
                                  this.guidedHelpTimer = null
                              }, 2000)
                          }
                      })
            } else {
                // user clicked on a new UI which we dont support help for... so clear the timer for the pending help guide.
                if (this.guidedHelpTimer != null) {
                    clearTimeout(this.guidedHelpTimer)
                    this.guidedHelpTimer = null
                }
            }
        },

    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
   .layout{
        border: 1px solid #d7dde4;
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .layout-header-bar{
        background: #fff;
        box-shadow: 0 1px 1px rgba(0,0,0,.1);
    }
    .layout-logo-left{
        width: 90%;
        height: 30px;
        background: #5b6270;
        border-radius: 3px;
        margin: 15px auto;
    }
    .menu-icon{
        transition: all .3s;
    }
    .rotate-icon{
        transform: rotate(-90deg);
    }
    .menu-item span{
        display: inline-block;
        overflow: hidden;
        width: 69px;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: bottom;
        transition: width .2s ease .2s;
    }
    .menu-item i{
        transform: translateX(0px);
        transition: font-size .2s ease, transform .2s ease;
        vertical-align: middle;
        font-size: 16px;
    }
    .collapsed-menu span{
        width: 0px;
        transition: width .2s ease;
    }
    .collapsed-menu i{
        transform: translateX(5px);
        transition: font-size .2s ease .2s, transform .2s ease .2s;
        vertical-align: middle;
        font-size: 22px;
    }


    .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
        color: #334155;
        background: #4ceafb;
    }

    .ivu-menu-vertical .ivu-menu-item, .ivu-menu-vertical .ivu-menu-submenu-title {
        padding: 8px 24px;
    }

    .ivu-list-small .ivu-list-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .ivu-layout-sider {
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        position: relative;
        background: #ffffff;
        min-width: 0;
    }


    .hideShowNavToggle {
        position:absolute;
        top:55px;
        width:8px;
        background-color: #d5f0fd;
        border-color: transparent;
    }


    .mockTailwindHoverButton:hover {
        background-color: rgba(229, 231, 235, 0.8) !important;
    }
</style>

