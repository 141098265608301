<template>
    <GlobalFooter class="i-copyright" :links="links" :copyright="copyright" />
</template>
<script>
    export default {
        name: 'i-copyright',
        data () {
            return {
                links: [
                    {
                        title: 'BnTx Interact Home',
                        key: 'BnTx Interact Home',
                        href: 'https://www.bntxinteract.com/',
                        blankTarget: true
                    },
                    {
                        title: 'BnTx Inject Portal',
                        key: 'BnTx Inject Portal',
                        href: 'https://inject.bntxinteract.com/',
                        blankTarget: true
                    },
                    {
                        title: 'myINTERACT',
                        key: 'myINTERACT',
                        href: 'https://my.interact.technology',
                        blankTarget: true
                    }
                ],
                copyright: 'Copyright © 2021 BnTx Interact'
            }
        }
    }
</script>
<style lang="less">
    .i-copyright{
        flex: 0 0 auto;
        &-hidden{
            display: none;
        }
    }
</style>
