import Vue from 'vue'
import moment from 'moment'
import store from "@/Store/store";

/*
    returns things like follows on the fly:

    3 seconds ago
    1 hour ago
    1 day ago
    2 years ago
*/
Vue.filter('formatTimeAgo', function(value) {
  if (value) {
    return moment.unix(value / 1000).fromNow()
  }
})

/*
    returns number of days ago, eg

    today
    1 day ago
    5 days ago
*/
Vue.filter('formatDaysAgo', function(value) {
  if (value) {
    var days = Math.round((Date.now() - new Date(value)) / (1000 * 60 * 60 * 24))

    if (days == 0) return 'today'
    else if (days == 1) return 'yesterday'
    else if (days > 1) return days + ' days ago'
    
    return 'in the future'
  }
})

/*
    Given a date string, parse it and format in a specific format way...
    returns a date in DD-MMM-YYYY format
*/
Vue.filter('userFriendlyDateFromDateString', function(inUnixDateString) {
  if (inUnixDateString) {
    // return moment.unix(new Date(inUnixDateString) / 1000).format("DD-MMM-YYYY")
    return moment.unix(new Date(inUnixDateString) / 1000).format(store.state.defaultDateInputFormat.toUpperCase())
  }
  return ''
})

/*
    Given number of seconds since 1-1-1970... render as a date string
    returns a date in DD-MMM-YYYY format
*/
Vue.filter('userFriendlyDate', function(inUnixDate) {
  if (inUnixDate) {
    // return moment.unix(inUnixDate / 1000).format("DD-MMM-YYYY")
    return moment.unix(inUnixDate / 1000).format(store.state.defaultDateInputFormat.toUpperCase())
  }
  return ''
})

/*
    returns a date in DD-MMM-YYYY HH:MM format
*/
Vue.filter('userFriendlyDateTime', function(inUnixDate) {
  if (inUnixDate) {
    return moment.unix(inUnixDate / 1000).format("ddd, MMMM Do, YYYY h:mm a")
  }
})

/*
    returns a status code as nice english text.
*/
Vue.filter('userFriendlyStatus', function(inStatusCode) {
    if (inStatusCode == "A") return "Active";
    else if (inStatusCode == "I") return "Inactive";
    else if (inStatusCode == "D") return "Deleted";

    return inStatusCode
  })

  
/*
    returns a status code as nice english text.
*/
Vue.filter('userFriendlyInjectionProtocolStatus', function(inStatusCode) {
  if (inStatusCode == "A") return "Active";
  else if (inStatusCode == "I") return "Inactive";
  else if (inStatusCode == "T") return "Training Entry";
  else if (inStatusCode == "D") return "Deleted";

  return inStatusCode
})


/*
    returns a treating physician status code as nice english text.
*/
Vue.filter('userFriendlyTreatingPhysicianStatus', function(inStatusCode) {

  switch (inStatusCode) {
    case 'A': return "Active";
    case 'P': return "Pending";
    case 'I': return "Inactive";
    case 'D': return "Deleted";
  }

  return inStatusCode
})

/*
    returns a status code as nice english text.
*/
Vue.filter('userFriendlyReferringPhysicianStatus', function(inStatusCode) {

  // statusRefData : [
  //     { statusCode : 'A', statusName: 'Active'},
  //     { statusCode : 'T', statusName: 'Training Entry'},
  //     { statusCode : 'M', statusName: 'Moved Address'},
  //     { statusCode : 'R', statusName: 'Retired'},
  //     { statusCode : 'D', statusName: 'Deceased'},
  //     { statusCode : 'U', statusName: 'Unavailable'},
  // ],

  switch (inStatusCode) {
    case 'A': return "Active";
    case 'T': return "Training Entry";
    case 'M': return "Moved Address";
    case 'R': return "Retired";
    case 'D': return "Deceased";
    case 'U': return "Unavailable";
  }

  return inStatusCode
})

/*
    returns a status code as nice english text.
*/
Vue.filter('userFriendlyPatientStatus', function(inStatusCode) {

  switch (inStatusCode) {
    case 'A': return "Active";
    case 'T': return "Training Entry";
    case 'M': return "Moved Away";
    case 'D': return "Deceased";
    case 'I': return "Inactive";
    case 'F': return "Form";            // this stands for "Form" and represents an incoming form from BnTRX Form that will appear in the BnTRx Intray. 
    case 'X': return "Archived Form";   // stands for "Archived Form" - when a Form has been processed, we keep it as an archived record (but no longer show it in the Intray).
  }

  return inStatusCode
})

/*
    returns a account status code as nice english text.
*/
Vue.filter('userFriendlyAccountStatus', function(inAccountStatusCode) {
    if (inAccountStatusCode == "S") return "Sponsored";
    else if (inAccountStatusCode == "P") return "Paid";
    else if (inAccountStatusCode == "T") return "Trial";
    else if (inAccountStatusCode == "C") return "Closed";

    return inAccountStatusCode
  })


/*
    returns a campaign status code as nice english text.
*/
Vue.filter('userFriendlyCampaignStatus', function(inCampaignStatusCode) {
    if (inCampaignStatusCode == "L") return "Live";
    else if (inCampaignStatusCode == "R") return "Draft";
    else if (inCampaignStatusCode == "D") return "Deleted";

    return inCampaignStatusCode
})
  

/*
    returns a activation code status code as nice english text.
*/
Vue.filter('userFriendlyActivationCodeStatus', function(inActivationCodeStatusCode) {
  if (inActivationCodeStatusCode == "U") return "Unassigned";
  else if (inActivationCodeStatusCode == "A") return "Assigned";
  else if (inActivationCodeStatusCode == "R") return "Replaced";

  return inActivationCodeStatusCode
})

/*
    returns a copayment order status code as nice english text.
*/

// this function needs to MIRROR practice-copayment > getUserFriendlyOrderStatus
Vue.filter('userFriendlyOrderStatus', function(inStatusCode) {

  switch (inStatusCode) {
    case 'UN': return "Incomplete";           // unassigned, indicated as 'incomplete'... ie user needs to complete
    case 'DR': return "Draft";                // DRaft, or what in the UI is Preparing Order... ie UI taps button to create an order to fill for a session.
    case 'PP': return "Preparing Order";      // Preparing Order, means BE has queued the order by request of FE user tapping 'send'
    case 'OR': return "Charged";              // ORdered, means BE has taken request from queue, done all it needs to do and sent off order. Well now its charged payment, but awaiting sending email to HPS
    case 'PR': return "Received";             // Product Reconciled, practice has reconciled the received product with order.
    case 'ON': return "Order Not Required";   // Order Not Required, eg Probono.
    case 'AB': return "Failed to Send";       // ABorded = when there is a error sending order or email/CC fails...

    /* PSEUDO STATUS - used for setting status */
    // case 'PU' - Product Unreconciled - this is used to set an order from Reconciled (say by accident, back to Ordered which is the (only) state before reconciled.)
    case 'so': return "Sent";                 // (Sent Ok) Order Sent is the pseudo new actual status, where the backend 'sent' (actually now co-payment charged), and emailSent == true
    case 'sf': return "Send (failed)";        // (Send Failed) Order Sent is the pseudo new actual status, where the backend 'sent' (actually now co-payment charged), and emailSent == true

  }

  return inStatusCode
})




// value can be a condition number OR an array of condition ID's
Vue.filter('conditionsAsString', function(value) {  
    var REF_CONDITIONS = store.state.refDataConditions

    if (typeof(value) == 'number') {
        for (var index1 = 0; index1 < REF_CONDITIONS.length; index1++) {
            if (REF_CONDITIONS[index1].conditionId == value)
                return REF_CONDITIONS[index1].conditionName;
        }

        return value.toString()
    } else if (Array.isArray(value) == true) {
        var conditionsString = "";

        for (var conditionIndex = 0; conditionIndex < value.length; conditionIndex++) {
            for (var index = 0; index < REF_CONDITIONS.length; index++) {
                if (REF_CONDITIONS[index].conditionId == value[conditionIndex]) {
                    if (conditionsString.length == 0)
                        conditionsString = REF_CONDITIONS[index].conditionName;
                    else   
                        conditionsString = conditionsString + ", " + REF_CONDITIONS[index].conditionName;
                }
            }
        }
        
        return conditionsString;
    }
})