<template>
    <div id="admin-practise-management" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">

        <Form :model="practiseDetails" :label-width="120">
            
            <Row justify="space-around" :gutter="8">

                <!----------------------------->
                <!-- My Practice Details     -->
                <!----------------------------->
               <Col v-bind="responsiveGrid2Sections">
                    <Card :bordered="true" style="height:100%">
                        <p slot="title">My Practice Details</p>

                        <FormItem label="Practice Name">
                            <Input v-model="practiseDetails.practiseName"></Input>
                        </FormItem>
                        <FormItem label="Contact Name">
                            <Input v-model="practiseDetails.practiseContactName"></Input>
                        </FormItem>
                        <FormItem label="Email">
                            <Input v-model="practiseDetails.practiseContactEmail"></Input>
                        </FormItem>
                        <FormItem label="Phone">
                            <Input v-model="practiseDetails.practiseContactPhone"></Input>
                        </FormItem>
                        <FormItem label="Fax">
                            <Input v-model="practiseDetails.practiseFax"></Input>
                        </FormItem>
                        <FormItem label="Address">
                            <Input v-model="practiseDetails.practiseAddress"></Input>
                        </FormItem>
                        <FormItem label="City">
                            <Input v-model="practiseDetails.practiseCity"></Input>
                        </FormItem>
                        <FormItem label="Postcode/Zip">
                            <Input v-model="practiseDetails.practisePostcode"></Input>
                        </FormItem>
                        <FormItem label="Country">
                            <Select v-model="practiseDetails.countryCode" filterable>
                                <Option v-for="item in countriesRefData" :value="item.code" :key="item.code">{{ item.name }}</Option>
                            </Select>
                        </FormItem>
                    </Card>
                </Col>
                <Col v-bind="responsiveGrid2Sections">
                    <!----------------------------->
                    <!-- Misc Details            -->
                    <!----------------------------->

                    <!-- <Row>
                        <Card :bordered="true" style="width:100%;">
                            <p slot="title">Misc Details</p>
                            
                            <FormItem label="Is Hospital">
                                <Checkbox v-model="practiseDetails.isHospital"></Checkbox>
                            </FormItem>
                            
                        </Card>
                    </Row> -->

                    <!----------------------------->
                    <!-- Practice Defaults       -->
                    <!----------------------------->

                    <Row style="width:100%">
                        <Card :bordered="true" style="width:100%;">
                            <p slot="title">Practice Defaults</p>

                            <FormItem label="Admin Email">
                                <Input v-model="practiseDetails.practiseAdminEmail"></Input>
                            </FormItem>

                            <FormItem label="Report Type">
                                <Select v-model="practiseDetails.reportType" style="maxWidth:200px">
                                    <Option v-for="item in reportColorOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="Date Format">
                                <Select v-model="practiseDefaultsDateInputFormat" style="maxWidth:200px">
                                    <Option v-for="item in dateInputFormatOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="Medication">
                                <Select v-model="practiseDetails.defaultMedicationId" style="maxWidth:200px">
                                    <Option v-for="item in medicationOptions" :value="item.medicationID" :key="item.medicationID">{{ item.medicationName }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="">
                                <Checkbox v-model="practiseDetails.isBatchMandatory">Mandatory Batch Information †</Checkbox>
                            </FormItem>
                            
                            <FormItem label="">
                                <Checkbox v-model="practiseDefaultsMandatoryPatientDOB">Mandatory Patient DOB †</Checkbox>
                            </FormItem>
                            <FormItem label="">
                                <Checkbox v-model="practiseDefaultsMandatoryPatientReferrerPhysician">Mandatory Patient Referrer Physician †</Checkbox>
                            </FormItem>
                            <span class="uploadTip">Note: † Some setting may only take effect on next login.</span>
                        </Card>
                    </Row>
                </Col>
            </Row>

            <Row style="margin-top:8px">
                <Card :bordered="true" style="width:100%;">
                    <p slot="title">Branding - Practice Logo</p>

                    <Row>
                        <Col span="6">
                            <Select v-model="selectedUploadLogoOption" style="width:100%">
                                <Option v-for="item in uploadLogoOptions" :value="item.value" :key="item.value" :title="item.tip">{{ item.label }}</Option>
                            </Select>

                            <Upload
                                :multiple="allowSelectingMultipleFilesToUpload"
                                type="drag"
                                name='uploadedFile'
                                :action="uploadActionURL"
                                :data="uploadPractiseLogoData"
                                :before-upload="handleBeforeUploadLogo"
                                :format="['jpg','jpeg','png']"
                                :max-size="2048"
                                :on-format-error="handleFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :on-success="handleSuccess"
                                :on-error="handleError"

                                style="margin-top:8px"
                                >
                                <div style="padding: 10px 0">
                                    <Icon type="ios-cloud-upload" size="42" style="color: #3399ff"></Icon>
                                    <p>Click or drag files here to upload your logo(s)</p>
                                </div>
                            </Upload>

                            <span class="uploadTip">{{ uploadLogoOptions[selectedUploadLogoOption].tip }}</span>
                        </Col>
                        
                        <Col span="6">
                            <Row justify="center"> Web / Desktop: </Row>
                            <Row justify="center" style="margin-top:8px"> <img :src="practiseDetails.practiseLogo" style="margin:16px; width:80%; maxWidth:200px" />  </Row>
                        </Col>

                        <Col span="6">
                            <Row justify="center"> Mobile - Dark Mode: </Row>
                            <Row justify="center" style="margin-top:8px; background-color:#334155;"> <img :src="practiseDetails.practiseLogoDark" style="margin:16px; width:80%; maxWidth:200px" />  </Row>
                        </Col>

                        <Col span="6">
                            <Row justify="center"> Mobile - Light Mode: </Row>
                            <Row justify="center" style="margin-top:8px; background-color:#FFFFFF;"> <img :src="practiseDetails.practiseLogoLight" style="margin:16px; width:80%; maxWidth:200px" />  </Row>
                        </Col>
                        
                    </Row>
                </Card>
            </Row>

            <Row style="margin-top:8px">
               
                <Card :bordered="true" style="width:100%;">
                    <p slot="title">Branding - Report Footer
                        <Tooltip placement="bottom">
                            <Icon type="md-help-circle" color="#FFFFFF" />
                            <div slot="content" style="white-space: normal">
                                Provide a custom report footer to promote and educate the services available to your practice to others.<br /><br />
                                It can include the conditions you treat, as well as the procedures you perform.<br /><br />
                                You can essentially put whatever you like.
                            </div>
                        </Tooltip>
                    </p>
            
             
            
                    <Row>
                        <Input v-model="practiseDetails.reportFooter" maxlength="4000" show-word-limit type="textarea" placeholder="Enter your custom practice footer here." :autosize="{ minRows: 4, maxRows: 15 }" style="width: 100%;font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;" />

                        
                        <!-- <Col span="24">
                            <Row justify="center"> Web / Desktop: </Row>
                            <Row justify="center" style="margin-top:8px"> <img :src="practiseDetails.practiseLogo" style="margin:16px; width:80%; maxWidth:200px" />  </Row>
                        </Col> -->
                        
                    </Row>
                </Card>
            </Row>
       
        </Form>


        
        <Button type="primary" @click="handleSave" style="margin-top:8px" >Save Details</Button>


    </div>
</template>

<script>
const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
import AnalyticsMgr from '@/js/AnalyticsManager.js';

const {itBnTx} = require('@/js/itBnTx')



export default {
    name: 'admin-practise-management',
    components: {

    },

    created () {
        

    },

    destroyed() {

    },

    mounted() {


    },

    data () {
            return {
                dateInputFormatOptions: [
                    { value: 'dd-MM-yyyy', label: 'DD-MM-YYYY' },
                    { value: 'dd-MMM-yyyy', label: 'DD-MMM-YYYY' },
                    { value: 'MM-dd-yyyy', label: 'MM-DD-YYYY' },
                    { value: 'MMM-dd-yyyy', label: 'MMM-DD-YYYY' },
                    { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
                    { value: 'yyyy-MMM-dd', label: 'YYYY-MMM-DD' },
                ],

                reportColorOptions: [
                    { value: 0, label: 'Color' },
                    { value: 1, label: 'Black and White' },
                ],

                uploadLogoOptions: [
                    { value: 0, label: 'Automatic',            tip: "Filenames including the word 'dark' will be uploaded as dark logo, 'light' as light logo, otherwise uploaded as desktop logo." },
                    { value: 1, label: 'Web / Desktop Logo',   tip: "" },
                    { value: 2, label: 'Mobile - Dark Logo',   tip: "" },
                    { value: 3, label: 'Mobile - Light Logo',  tip: "" },
                ],
                selectedUploadLogoOption : 0,


                medicationOptions : CONFIG.MEDICATION_OPTIONS,


                practiseDetails: {},

                practiseDefaultsDateInputFormat : 'dd-MM-yyyy',
                practiseDefaultsMandatoryPatientDOB : true,
                practiseDefaultsMandatoryPatientReferrerPhysician : true,
                

                allowSelectingMultipleFilesToUpload: true,

                uploadPractiseLogoData : {
                    practiseId : 0,
                    authToken : '',
                    uploadType : "Logo", //"Signature", "LogoLight", "LogoDark", "Attachment"
                    userId : 0
                },


                responsiveGrid2Sections : {
                  xl: 12,
                  lg: 12,
                  md: 12,
                  sm: 24,
                  xs: 24,
                },
            }
        },

    filters: {

    },
    
    computed: {
        uploadActionURL() {
            return itBnTx.baseUrl + "/rest/user/uploadFile"
        },

        countriesRefData() { return CONFIG.COUNTRIES; }

    },
    methods: {
        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/Administration/PractiseManagement');

                this.refreshDataForUI()
            }
        },

        refreshDataForUI() {

            itBnTx.getPractise({inPractiseID : this.$store.state.loggedInDoctorDetails.practiseId })
                    .then(response => {
                        this.practiseDetails = response.data;
                        //this.practiseDetails.practiseTimezoneId = "AEST"
                    })

            itBnTx.getPractisePref({inPrefKey : "_btxw.default-date-input-format", inDefaultValue : "dd-MM-yyyy"})
                    .then(response => {
                        this.practiseDefaultsDateInputFormat = response.data.value;
                        this.$store.commit("defaultDateInputFormat", this.practiseDefaultsDateInputFormat)
                    })
            itBnTx.getPractisePref({inPrefKey : "_btxw.default-mandatory-patient-dob", inDefaultValue : "1"})
                    .then(response => {
                        this.practiseDefaultsMandatoryPatientDOB = response.data.value == "1";
                        this.$store.commit("defaultMandatoryPatientDOB", this.practiseDefaultsMandatoryPatientDOB)
                    })
            itBnTx.getPractisePref({inPrefKey : "_btxw.default-mandatory-patient-referrer-physician", inDefaultValue : "1"})
                    .then(response => {
                        this.practiseDefaultsMandatoryPatientReferrerPhysician = response.data.value == "1";
                        this.$store.commit("defaultMandatoryPatientReferrerPhysician", this.practiseDefaultsMandatoryPatientReferrerPhysician)

                    })
        },

        handleBeforeUploadLogo(inFile) {
            
            if (this.selectedUploadLogoOption == 0) {
            
                if (inFile.name.toLocaleLowerCase().indexOf("dark") != -1)        this.uploadPractiseLogoData.uploadType = "LogoDark"
                else if (inFile.name.toLocaleLowerCase().indexOf("light") != -1)  this.uploadPractiseLogoData.uploadType = "LogoLight"
                else                                                              this.uploadPractiseLogoData.uploadType = "Logo"
            
            } else if (this.selectedUploadLogoOption == 1) {
                this.uploadPractiseLogoData.uploadType = "Logo"
            } else if (this.selectedUploadLogoOption == 2) {
                this.uploadPractiseLogoData.uploadType = "LogoDark"
            } else if (this.selectedUploadLogoOption == 3) {
                this.uploadPractiseLogoData.uploadType = "LogoLight"
            }

            this.uploadPractiseLogoData.practiseId = this.$store.state.loggedInDoctorDetails.practiseId
            this.uploadPractiseLogoData.userId = this.$store.state.loggedInDoctorDetails.userId
            this.uploadPractiseLogoData.authToken = this.$store.state.btxAuthToken

            return true
        },

        handleFormatError (file) {
            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
            });
        },
        handleMaxSize (file) {
            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 2M.'
            });
        },

        handleSuccess (response, file, fileList) {
            console.log(response)
            console.log(file)
            console.log(fileList)

            this.refreshDataForUI()
        },

        handleError (error, file, fileList) {
            console.log(error)
            console.log(file)
            console.log(fileList)
        },


        handleUserSelectionChanged() {

        },
        
        handlePracticeSelectionChanged() {

        },

        handleSave() {
            itBnTx.updatePractise({inPractiseRecord : this.practiseDetails })
                  .then(rersponse => {
                      this.$store.commit("loggedInPracticeDetails", rersponse.data)
                      this.$Message.info("Practice details saved.")
                    })
            
            itBnTx.updatePractisePref({inPrefKey : "_btxw.default-date-input-format",
                                     inPrefValue : this.practiseDefaultsDateInputFormat})
            itBnTx.updatePractisePref({inPrefKey : "_btxw.default-mandatory-patient-dob",
                                     inPrefValue : this.practiseDefaultsMandatoryPatientDOB ? "1" : "0"})
            itBnTx.updatePractisePref({inPrefKey : "_btxw.default-mandatory-patient-referrer-physician",
                                     inPrefValue : this.practiseDefaultsMandatoryPatientReferrerPhysician ? "1" : "0"})

            this.$store.commit("defaultDateInputFormat", this.practiseDefaultsDateInputFormat)
            this.$store.commit("defaultMandatoryPatientDOB", this.practiseDefaultsMandatoryPatientDOB)
            this.$store.commit("defaultMandatoryPatientReferrerPhysician", this.practiseDefaultsMandatoryPatientReferrerPhysician)
            

        },

        handleEditEntry() {

        },

        handleRemoveEntry() {
            
        }
    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
        "selectedUploadLogoOption"(newValue, oldValue) {
            oldValue
            switch(newValue) {
                case 0: // auto
                    this.allowSelectingMultipleFilesToUpload = true
                    break;
                case 1: // desktop
                case 2: // mobile - dark
                case 3: // mobile - light
                default:
                    this.allowSelectingMultipleFilesToUpload = false
                    break;
            }
        }
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>


.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}

.uploadTip {
    font-size: 11px;
    color: rgb(110, 110, 110);
}

</style>

