
var formurlencoded = require('form-urlencoded');

/*
    REQUEST:
    {
        userEmail : "peter@home.com",
        userName:"Peter",
        practiseId:1,
        userRoleCodes:["P"],
        statusCode: "A",
        }

    RESPONSE:

    {
        "userId" : 1,
        "statusCode" : "A",  
        "practiseId" : 1,
        "userName" : "Aaron McMullen",
        "userEmail" : "a@a.com",
        "userEmailValidated" : "Y",
        "userIsAccountLocked" : "N",
        "userLastLogin" : 1391980356929,
        "userRoleCodes" : "[P]",
        "userSignatureFile" : "nfkas/sadgfsd/fsdf.jpg",
        "createdBy" : 1,
        "created" : 1391980356929,
        "modifyBy" : 1,
        "modified" : 1391980356929
    }
*/
function createUser({inUserDetails} = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/user',
                        inRequireAuth: true,
                        inQueryParams: {
                            version: 2,
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inUserDetails)
                        });
}

/*
    REQUEST:
        {
            "userId" : 1,
            "statusCode" : "A",  
            "practiseId" : 1,
            "userName" : "Aaron McMullen",
            "userEmail" : "a@a.com",
            "userEmailValidated" : "Y",
            "userIsAccountLocked" : "N",
            "userLastLogin" : 1391980356929,
            "userRoleCodes" : "[P]",
            "userSignatureFile" : "nfkas/sadgfsd/fsdf.jpg",
            "createdBy" : 1,
            "created" : 1391980356929,
            "modifyBy" : 1,
            "modified" : 1391980356929
        }


    RESPONSE:

     {
        "userId" : 1,
        "statusCode" : "A",  
        "practiseId" : 1,
        "userName" : "Aaron McMullen",
        "userEmail" : "a@a.com",
        "userEmailValidated" : "Y",
        "userIsAccountLocked" : "N",
        "userLastLogin" : 1391980356929,
        "userRoleCodes" : "[P]",
        "userSignatureFile" : "nfkas/sadgfsd/fsdf.jpg",
        "createdBy" : 1,
        "created" : 1391980356929,
        "modifyBy" : 1,
        "modified" : 1391980356929
    }
*/
function updateUser({inUserDetails} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/user',
                        inRequireAuth: true,
                        inQueryParams: {
                            version: 2,
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inUserDetails)
                    });
 
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteUser({inUserID} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/user/' + inUserID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE:

    {
        created: 1631491967066
        createdBy: 3
        modified: 1631492778338
        modifiedBy: 149
        practiseId: 5
        practiseName: "Inner West Neurology"
        statusCode: "A"
        userEmail: "peter@interact.technology"
        userEmailValidated: "Y"
        userId: 149
        userIsAccountLocked: "N"
        userLastLogin: 1632164025649
        userName: "Peter Li"
        userRoleCodes: ["P", "PA", "S", "N"]
        userSignatureFile: ""
    }

    essentially the same as login api response.
*/
function switchPractise({inPractiseID} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/user/switch/' + inPractiseID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}




function createUserPref({inPrefKey, inPrefValue} = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/user/pref',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify({ 'key' : inPrefKey, 'value' : inPrefValue})
                        });
}

/* 
    RESPONSE:

    {
        "key":"_btxw.help-id-patients-main",
        "value":"0"
    }

    OR standard error response payload

    This API will fetch the pref for the key, if it exists the value will be returned.
    If the key is not in DB, and if the defaultValue is not specified, then an error with 'key not found' is returned
    otherwise if a defaultValue is supplied, the key is created in the DB with the value of defaultValue and the normal response
    is returned.

    This pattern, allows for a clean client side code to be written.
*/
function getUserPref({inPrefKey, inDefaultValue = null} = {}) {

    var requestPayload = {}

    requestPayload['key'] = inPrefKey

    if (inDefaultValue != null) {
        requestPayload['defaultValue'] = inDefaultValue
    }

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/user/pref',
                        inRequireAuth: true,
                        inQueryParams: requestPayload,
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

function updateUserPref({inPrefKey, inPrefValue} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/user/pref',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify({ 'key' : inPrefKey, 'value' : inPrefValue})
                        });
}

function deleteUserPref({inPrefKey} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/user/pref',
                        inRequireAuth: true,
                        inQueryParams: {
                            key : inPrefKey
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}





/*
    response array of dicts:

        {
            "userId" : 1,
            "statusCode" : "A",  
            "practiseId" : 1,
            "userName" : "Aaron McMullen",
            "userEmail" : "a@a.com",
            "userEmailValidated" : "Y",
            "userIsAccountLocked" : "N",
            "userLastLogin" : 1391980356929,
            "userRoleCodes" : "[P]",
            "userSignatureFile" : "nfkas/sadgfsd/fsdf.jpg",
            "createdBy" : 1,
            "created" : 1391980356929,
            "modifyBy" : 1,
            "modified" : 1391980356929
        }

*/
function searchUsersInPractise({inPractiseID} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/user/search/practise',
                        inRequireAuth: true,
                        inQueryParams: {
                            practiseId : inPractiseID,
                            userRoleLevel : 0
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*
    REQUEST:

    {
        userId: 0,
        existingPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
    },


    RESPONSE:

    {
        "responseCode" : 0,
        "responseMsg" : "User password has been reset. You can change it now with new password."
    }
*/
function changePassword({inDetails} = {}) {
    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/user/changePassword',
                        inRequireAuth: false,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: formurlencoded(inDetails),
                        inSendBodyContentType: 'application/x-www-form-urlencoded; charset=UTF-8'
                        });
}

/*


    RESPONSE:

    {
        "responseCode" : 0,
        "responseMsg" : "User password has been reset. You can change it now with new password."
    }
*/
function resetPassword({inEmail} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/user/resetPassword',
                        inRequireAuth: false,
                        inQueryParams: {
                            email : inEmail,
                            version: 2
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*


    RESPONSE:

    HTTP 200
    {
        "userId":599,
        "userEmail":"selfregistertest2@yopmail.com",
        "userIsTempPassword":false
    }

    HTTP 400
    {
        "responseCode":1025,
        "responseMsg":"The email has already validated"
    }
    OR
    {
        "responseCode":1006,
        "responseMsg":"Invalid validation token!"
    }
*/
function validateEmail({inToken, inSilenceError = false} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/user/validateEmail',
                        inRequireAuth: false,
                        inQueryParams: {
                            regId : inToken
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inSilenceError : inSilenceError
                        });
}


/*


    RESPONSE:
    HTTP 200
    {
        "responseCode":0,
        "responseMsg":"on error"
    }
    
    HTTP 400
    {
        "responseCode":<error number>,
        "responseMsg":"<error message>!"
    }
*/
function resendValidateEmail({inUserID} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/user/resendValidateEmail',
                        inRequireAuth: true,
                        inQueryParams: {
                            userId : inUserID
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inSilenceError : false
                        });
}



module.exports = { 

    createUser,
    updateUser,
    deleteUser,

    switchPractise,

    createUserPref,
    getUserPref,
    updateUserPref,
    deleteUserPref,
    


    searchUsersInPractise,

    changePassword,
    resetPassword,
    validateEmail,


    resendValidateEmail,
};
