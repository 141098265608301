

/*

    [
        {
            "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",   <<<< this is necessary
            "injectMedicine": {
                "id": 0,
                "name": "string",
                "desc": "string",
                "code": "string",
                "isPbs": true,
                "vialUnits": 0,
                "marketingUrl": "string",
                "orderUrl": "string",
                "order": 0,
                "created": 0,
                "createdBy": 0,
                "modified": 0,
                "modifiedBy": 0
            },
            "vialCount": 0,   <<<< this is necessary
            "created": 0,
            "createdBy": 0,
            "modified": 0,
            "modifiedBy": 0
        }
    ]
*/
function updateMedicineStockForPhysician({ inTreatingPhysicianID, inMedicineStockArray } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/medicine-stocks',
                        inRequireAuth: true,
                        inQueryParams: { treatingPhysicianId : inTreatingPhysicianID },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inMedicineStockArray)
                        });
}

/*

    [
        {
            "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
            "injectMedicine": {
                "id": 0,
                "name": "string",
                "desc": "string",
                "code": "string",
                "isPbs": true,
                "vialUnits": 0,
                "marketingUrl": "string",
                "orderUrl": "string",
                "order": 0,
                "created": 0,
                "createdBy": 0,
                "modified": 0,
                "modifiedBy": 0
            },
            "vialCount": 0,
            "created": 0,
            "createdBy": 0,
            "modified": 0,
            "modifiedBy": 0
        }
    ]
*/
function getMedicineStockForPhysician({ inTreatingPhysicianID = null, inSuppressErrorNotification = true } = {}) {
    var params = {}

    if (inTreatingPhysicianID != null) {
        params["treatingPhysicianId"] = inTreatingPhysicianID
    }

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/medicine-stocks',
                        inRequireAuth: true,
                        inQueryParams: params,
                        inAdditionalHeaders: {},
                        inBodyPayloadData: {},
                        inSilenceError : inSuppressErrorNotification
                        });
}



module.exports = { 

    updateMedicineStockForPhysician,
    getMedicineStockForPhysician,

};
