import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/Store/store";
const {CONFIG} = require('@/js/bntx-config')



import BnTxHome from "@/components/bntx-home";
// import EducationMain from "@/components/EducationMain.vue";
import PageNotFound from '@/components/PageNotFound.vue'
import About from '@/components/About.vue'
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'

import Subscription from '@/components/subscription.vue'
import CoPayment from '@/components/copayment.vue'
import MsgNotice from '@/components/notice.vue'

import MihubValidate from '@/components/mihub-validate.vue'

import ChangePassword from '@/components/ChangePassword.vue'
import CaptureSignature from '@/components/CaptureSignature.vue'
import CaptureMedia from '@/components/CaptureMedia.vue'
import CaptureMediaOrderScript from '@/components/CaptureMediaOrderScript.vue'

import PatientAssessment from "@/components/pm-patient-assessment";

import MobilePatientConsent from "@/components/mobile-patient-consent";

Vue.use(VueRouter);

const routes = [
    { path: '/about', name: "about", component: About },

    { path: '/login', name: "login", component: Login },
    { path: '/register', name: "register", component: Register },

    { path: '/subscription', name: "subscription", component: Subscription },
    { path: '/copayment', name: "copayment", component: CoPayment },
    { path: '/notice', name: "notice", component: MsgNotice },

    { path: '/consent/:validationID', name: "consent", component: MobilePatientConsent },
    
    { path: '/mihub/:connectionNanoID', name: "mihub-validate", component: MihubValidate },

    { path: '/change-password', name: "change-password", component: ChangePassword },
    { path: '/capture-signature', name: "capture-signature", component: CaptureSignature },
    { path: '/capture-media', name: "capture-media", component: CaptureMedia },
    { path: '/capture-script', name: "capture-script", component: CaptureMediaOrderScript },


    { path: '/patient-assessment', name: "patient-assessment", component: PatientAssessment },

    
    // { path: '/unauthorized', name: "unauthorized", component: Unauthorized },

    { path: '/',
      name: "bntx-home",
      component: BnTxHome,
      beforeEnter: (to, from, next) => {
        to, from
        // for this specific route, ensure we are logged in (or in debug more) before proceeding, otherwise flick to Login screen.
        if (CONFIG.DEV_DEBUG == false && store.state.btxAuthToken == "") {
          next({name:"login"});
        } else {
          next()
        }
      }
    },

    { path: '*', name: "notfound", component: PageNotFound }
  ]
  

export default new VueRouter({
    //mode: "hash",
    routes // short for `routes: routes`
});
