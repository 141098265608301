<template>
    <div >
        <Row justify="center" style="margin-top:100px"> 
            <Card style="width: 400px;">
                <Row justify="center">
                    <img src="@/assets/app_logo.svg" style="width:200px;padding:16px;"/>
                </Row>
                <Row v-if="!isValidateEmailOnly" justify="center">
                    CHANGE PASSWORD <br /><br />
                    <Form ref="changePasswordFormFields" style="width:100%" :model="changePasswordFormFields" :rules="changePasswordFieldRules" :label-width="130" autocomplete="on" inline >
                        <FormItem label="Email" style="width:100%">
                            <Input type="text" v-model="userEmail"></Input>
                        </FormItem>
                        <FormItem label="New Password" style="width:100%" prop="newPassword" :class="mandatoryFieldsStyle" >
                            <Input ref="newPassword" type="password" password autocomplete="new-password" name="new-password" v-model="changePasswordFormFields.newPassword"></Input>
                        </FormItem>
                        <FormItem label="Confirm Password" style="width:100%" prop="newPasswordConfirm" :class="mandatoryFieldsStyle" >
                            <Input ref="newPasswordConfirm" type="password" password autocomplete="new-password" name="new-password" v-model="changePasswordFormFields.newPasswordConfirm"></Input>
                        </FormItem>

                        <center><span style="font-size: 10px; color: gray;">Minumum password requirements are: 1 lowercase, 1 uppercase, and 1 digit, at least 6 characters long.</span></center>
                    </Form>

                <!-- <vue-recaptcha  :sitekey="recaptchaSiteKey"
                                :loadRecaptchaScript="true"
                                @verify="onVerify"
                                @expired="onExpired"> -->

                     <Button    type="primary"
                                style="width: 100%; margin-top:16px"
                                :loading="changePasswordInProgress"
                                @click="handleChangePassword">
                        <span v-if="!changePasswordInProgress">Change Password</span>
                        <span v-else>Updating Password... please wait.</span>
                    </Button>
                <!-- </vue-recaptcha> -->

                </Row>

                <Row v-else justify="center">
                    <br />
                    {{simpleMessage}}
                    
                    <br />
                    <br />

                    <Button type="primary"
                            style="width: 100%; margin-top:16px"
                            @click="handleProceedToLogin">
                            Proceed to Login
                    </Button>
                </Row>

            </Card>
        </Row>
    </div>
</template>

<script>

const {CONFIG} = require('@/js/bntx-config')
import AnalyticsMgr from '@/js/AnalyticsManager.js';
const {itBnTx} = require('@/js/itBnTx')

// import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'ChangePassword',
    props: {

    },

    components: {
    // VueRecaptcha,
    },

    created () {

        AnalyticsMgr.sendPageView('/ChangePassword')
    },
    mounted() {

        /*
            extract what we have from the query params
          
            /change-password?uId=599&eventId=c47c0474-3570-40e7-b50d-feff17946a55
            OR
            /change-password?regId=11c8dd36-e2c2-4927-90b3-98fcb84cba20
        */
        var token = ''

        if (this.$route.query.regId != undefined) {
            token = this.$route.query.regId
        } else if (this.$route.query.eventId != undefined) {
            token = this.$route.query.eventId
        } else {
            this.$router.push({name:"login"});
        } 

        if (token != '') {
            itBnTx.validateEmail({ inToken : token, inSilenceError : true })
                  .then(response => {
                      this.changePasswordFormFields.userId = response.data.userId
                      this.changePasswordFormFields.existingPassword = token
                      this.userEmail = response.data.userEmail
                      this.isValidateEmailOnly = response.data.userIsTempPassword == false

                      AnalyticsMgr.sendEvent('/ChangePassword', 'api', 'ChangePassword_ValidateEmail_Success')
                  })
                  .catch(err => {
                      this.isValidateEmailOnly = true

                      AnalyticsMgr.sendEvent('/ChangePassword', 'api', 'ChangePassword_ValidateEmail_Failed')

                      try {
                        if (err.responseCode == 1025) {     // email already validated.
                            this.simpleMessage = "Email has now been validated."
                            return
                        }
                      } catch {
                          console.log("oh dear... validateEmail failed")
                      }

                      this.simpleMessage = "The link you have used is no longer valid or has expired."
                  })
        }
            

    },

    data () {
        return {
            recaptchaSiteKey : CONFIG.CAPTCHA_SITE_KEY,

            changePasswordInProgress : false,

            liveValidating: false,
            liveValidatingFieldsOK: true,

            userEmail : '',
            isValidateEmailOnly : false,    // either validate self rego email, or validating email from create user via practice admin

            simpleMessage: '',

            changePasswordFormFields: {
                userId: 0,
                existingPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            },

            changePasswordFieldRules: {
                
                newPassword: [
                    { required: true, message: 'Please fill in the password.', trigger: 'blur' },
                    { 
                        type: 'string',
                        pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,     // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
                        message: 'Minumum requirement(s) not met.',
                        trigger: 'blur'
                    }
                ],
                newPasswordConfirm: [
                    { required: true, message: 'Please confirm your password.', trigger: 'blur' },
                    { type: 'string', min: 6, message: 'Password too short', trigger: 'blur' }
                ],               
            },

            }
    },

    computed: {

        // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
        mandatoryFieldsStyle() { return (this.liveValidating && this.liveValidatingFieldsOK == false) ? "revealValidationMessages" : "" },

    },

    methods: {
        /*
            https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
        */

        

        handleChangePassword() {
            this.$refs['changePasswordFormFields'].validate((valid) => {

                if (this.changePasswordFormFields.newPassword != this.changePasswordFormFields.newPasswordConfirm) {
                    valid = false
                    this.$Message.error("Confirm password does not match.")
                }

                if (valid) {
                    this.changePasswordInProgress = true

                    itBnTx.changePassword({inDetails : this.changePasswordFormFields})
                          .then(response => {
                                response
                                this.isValidateEmailOnly = true
                                this.simpleMessage = "You have successfully updated your password. You may now login using your new password"

                                AnalyticsMgr.sendEvent('/ChangePassword', 'api', 'ChangePassword_ChangePassword_Passed')
                          })
                          .catch(err => {
                              this.isValidateEmailOnly = true
                              this.simpleMessage = "An error occurred while updating your password." + '[ID=' + err.responseCode + ']'

                              AnalyticsMgr.sendEvent('/ChangePassword', 'api', 'ChangePassword_ChangePassword_Failed')
                          })
                          .finally(() => {
                              this.changePasswordInProgress = false
                          })


                } else {
                    this.liveValidating = true
                    this.liveValidatingFieldsOK = false

                }
          })
        },
        
        
        handleProceedToLogin() {
            this.$router.push({name:'login'})
        },
        

        validateAndUpdateUI() { if (this.liveValidating) this.$refs['changePasswordFormFields'].validate() },
    },

    watch: {
        "changePasswordFormFields.newPassword" :        function () { this.liveValidating = true; this.$refs['changePasswordFormFields'].validate() },
        "changePasswordFormFields.newPasswordConfirm" : function () { this.liveValidating = true; this.$refs['changePasswordFormFields'].validate() },
    //   "registerFormFields.practiseName" :            function() { this.validateAndUpdateUI() },
    //   "registerFormFields.practiseContactName" :     function() { this.validateAndUpdateUI() },
    //   "registerFormFields.practiseContactEmail" :    function() { this.validateAndUpdateUI() },
    //   "registerFormFields.password" :                function() { this.validateAndUpdateUI() },
    //   "registerFormFields.practiseContactPhone" :    function() { this.validateAndUpdateUI() },
    //   "registerFormFields.practiseAddress" :         function() { this.validateAndUpdateUI() },
    //   "registerFormFields.practiseCity" :            function() { this.validateAndUpdateUI() },
    //   "registerFormFields.practisePostcode" :        function() { this.validateAndUpdateUI() },
    //   "registerFormFields.countryCode" :             function() { this.validateAndUpdateUI() },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.ivu-layout-header {
    background: #FFFFFF;
}

.ivu-layout-content {
    background: #FFFFFF;
}


.finePrint {
    font-size: 10px;
    line-height: 12px
}

.ivu-card {
    background-color: #eff4f4 !important;
    padding-top: 0px
}




.ivu-form-item {
    margin-bottom: 24px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}

.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}

</style>

<style>
/* body {
  background: #334155 !important;
} */


</style>
