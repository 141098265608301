<template>
    <div id="pm-report" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
        <Row :gutter="8" >
            <Col>
                Session Report: 
                <Select v-model="reportColorSelection" style="width:200px">
                    <Option v-for="item in reportColorOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </Col>
            <Col>
                <Drawer
                    v-model="showSessionDetails"
                    width="380"
                    :mask-closable="false"
                    :styles="detailsDrawerStyles">

                    <p slot="header"><center>Session Details</center></p>

                    <Col style="margin:8px">
                        <Row :gutter="20">
                            <strong>Referring Physician:</strong>
                            <Select v-model="sessionDetails.referringPhysicianId"
                                    style="width:100%"
                                    @on-change="handleReferringDoctorChanged"
                                    :remote-method="loadingFilteringReferringPhysicians"
                                    :loading="referringPhysiciansLoadingFiltering"
                                    placeholder="Type to search, then select."
                                    clearable filterable>

                                    <!-- @on-set-default-options="setDefaultReferringPhysicianOptions" -->
                                    <!-- :default-label="referringPhysicianDefaultLabel" -->


                                <Option v-for="item in referringPhysiciansFiltered" :value="item.referringPhysicianId" :key="item.referringPhysicianId">{{ item.title }} {{ item.firstName }} {{ item.lastName }}</Option>
                            </Select>

                            <Checkbox v-model="useReferringPhysicianMovingForward" style="margin-top:8px">
                                Use this Referring Physician moving forward.
                            </Checkbox>

                        </Row>
                        <Row :gutter="20" style="margin-top:8px">
                            <strong>Treating Physician Notes:</strong>
                            <Input v-model="sessionDetails.notesForReport" maxlength="4000" show-word-limit type="textarea" :rows="10" placeholder="Enter any notes you wish to add to the report..." style="width: 100%" />

                        
                        </Row>
                    </Col>
                    <div class="drawer-footer">
                        <Button style="margin-right: 8px" @click="handleCancelSessionDetails">Cancel</Button>
                        <Button type="success" :loading="savingDetailsToBackend" @click="handleSaveSessionDetails">
                            <span v-if="!savingDetailsToBackend">Save</span>
                            <span v-else>Saving...</span>
                        </Button>
                    </div>
                </Drawer>

                <Button @click="handleShowSessionDetails">Edit Session Details</Button>
            </Col>
            <Col>
                <Button @click="handlePrintReport">Print</Button>
            </Col>
            <Col>

                <Drawer
                    v-model="showEmailReport"
                    width="380"
                    :styles="detailsDrawerStyles">
                        <p slot="header"><center>Email session report to</center></p>

                        <Checkbox v-model="emailReportData.emailPracticeAdmin"
                                  :disabled="practiceAdminEmail.length == 0" >
                                  
                                  Practice Administration ({{ practiceAdminEmail.length != 0 ? practiceAdminEmail : 'no email configured' }})
                        </Checkbox><br /><br />
                        
                        <Checkbox v-model="emailReportData.emailReferringPhysician"
                                  :disabled="referringPhysicianEmail.length == 0" >
                                  
                                  Referring physician ({{ referringPhysicianEmail.length != 0 ? referringPhysicianEmail : 'no email available' }})
                        </Checkbox><br /><br />
                        
                        Other email (please specify):<br />
                        <Input type="text" v-model="otherEmailField" placeholder="Email" style="width: 280px;" /><br /><br />

                        <Row justify="end">
                            <Button @click="handleCancelEmailReport" style="margin-right:8px">Cancel</Button>
                            <Button type="primary" :loading="sendingEmailViaBackend" @click="handleEmailReport">
                                <span v-if="!sendingEmailViaBackend">Email</span>
                                <span v-else>Emailing...</span>
                            </Button>
                        </Row>
                </Drawer>


                <Button @click="handleShowEmailReportDetails">Email</Button>
                <span id="ToggleDebugZoom" @click="debugReportZoom = !debugReportZoom"> .</span>
            </Col>
            <Col>
                <Button @click="handleDownloadReport">Download</Button>
            </Col>
        </Row>

        <Row style="margin-top:8px; height:calc(100vh - 60px)">
            <Spin v-show="reportIsLoading" fix>
                <Icon type="ios-loading" size=68 class="spin-icon-load"></Icon>
                <div>Loading</div>
            </Spin>
            <iframe id="reportFrame" :class="debugReportZoom ? 'scaled-frame' : ''" :src="reportHTMLData" frameborder="1" width="100%" height="100%" />
        </Row>                                        
    </div>
</template>

<script>
//const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
import AnalyticsMgr from '@/js/AnalyticsManager.js';
import moment from 'moment'

var _ = require('lodash');


const {itBnTx} = require('@/js/itBnTx')



export default {
    name: 'pm-report',
    components: {

    },

    created () {
        //var self = this;
        var date = new Date();
        var offset = date.getTimezoneOffset();
        
        this.emailReportData.gmtOffsetMins = -offset
    },

    destroyed() {

    },

    mounted() {


    },

    data () {
            return {

                reportColorOptions: [
                    {
                        value: 'true',
                        label: 'Color'
                    },
                    {
                        value: 'false',
                        label: 'Black and White'
                    },
                ],

                reportColorSelection : 'true',

                reportHTMLData : "about:blank",
                originalTitle: '',                          // used to preserve the title when printing tp PDF and specifying a 'filename' via the webpage title.

                reportIsLoading: false,
                otherEmailField : "",
                emailReportData : { // mirrors the API payload
                    emailPracticeAdmin : false,
                    emailReferringPhysician : false,
                    emailOther : [],
                    gmtOffsetMins : 0,
                    color : true
                },
                sendingEmailViaBackend : false,

                showEmailReport: false,
                detailsDrawerStyles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static'
                },

                showSessionDetails : false,
                savingDetailsToBackend : false,

                referringPhysiciansData: [],

                sessionDetails : { referringPhysicianId : 0, notesForReport : "" },

                referringPhysiciansFiltered: [],
                referringPhysiciansLoadingFiltering : false,

                selectedNewReferringPhysician : undefined,

                useReferringPhysicianMovingForward : false,

                debugReportZoom : false,
            }
        },

    filters: {

    },
    
    computed: {
        currentSelectedPatientDetails()   { return this.$store.state.currentSelectedPatientDetails },

        practiceAdminEmail() { 
            if (this.$store.state.loggedInPracticeDetails != undefined
                && this.$store.state.loggedInPracticeDetails.practiseAdminEmail != undefined) {
                return this.$store.state.loggedInPracticeDetails.practiseAdminEmail
            }
            return ''   
        },

        referringPhysicianEmail() { 
            if (this.$store.state.currentSelectedPatientDetails.referringPhysicianJSON != undefined
                && this.$store.state.currentSelectedPatientDetails.referringPhysicianJSON.physicianEmail != undefined) {
                return this.$store.state.currentSelectedPatientDetails.referringPhysicianJSON.physicianEmail
            }
            return ''   
        }
    },
    methods: {
        handlePrintReport() {
            AnalyticsMgr.sendEvent('/Report', 'click', 'Report_Print')

            this.originalTitle = document.title

            document.title = this.currentSelectedPatientDetails.firstName + "_"
                             + this.currentSelectedPatientDetails.lastName + "_"
                             + this.currentSelectedPatientDetails.masterPatientId + '_'
                             + moment().format("Do_MMM_YYYY")

            document.getElementById("reportFrame").contentWindow.postMessage('print', '*')
        },

        handleDownloadReport() {
            AnalyticsMgr.sendEvent('/Report', 'click', 'Report_Download')

            var currentSessionID = this.$store.state.currentSessionID

            if (currentSessionID != 0) {
                // this.reportIsLoading = true
                
                setTimeout(() => {
                    this.$store.commit("justUpdatingAssessmentData", false)

                    itBnTx.downloadSessionReport({
                            inSessionID : currentSessionID,
                            inColor : (this.reportColorSelection == 'true' ? true : false)
                            })
                        .then(response => {

                            // then create a dynamic anchor tag, and set its attributes, then simulate a click on it to download.
                            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            
                            var downloadElement = document.createElement('a');
                            var downloadFilename = this.currentSelectedPatientDetails.firstName + "_"
                                                    + this.currentSelectedPatientDetails.lastName + "_"
                                                    + this.currentSelectedPatientDetails.masterPatientId + '_'
                                                    + moment().format("Do_MMM_YYYY")

                            downloadElement.setAttribute('href', fileURL);
                            downloadElement.setAttribute('download', downloadFilename + ".pdf");
                            downloadElement.setAttribute('target', "_blank");

                            downloadElement.style.display = 'none';
                            document.body.appendChild(downloadElement);

                            downloadElement.click();

                            document.body.removeChild(downloadElement);

                            // this.reportIsLoading = false
                        })
                }, (this.$store.state.justUpdatingAssessmentData == true) ? 1500 : 0);

            } else {
                console.log("currentSessionID == 0 while trying to generate a report to download")
            }
        },
        
        
        handleShowEmailReportDetails() {
            AnalyticsMgr.sendEvent('/Report', 'click', 'Report_Email_ShowDetails')

            this.showEmailReport = true
        },

        handleEmailReport() {
            AnalyticsMgr.sendEvent('/Report', 'click', 'Report_Email_Send')

            // support comma separated values, and trim off spaces...
            var otherEmails = (this.otherEmailField != "") ? this.otherEmailField.split(',') : []
            this.emailReportData.emailOther = otherEmails.map(entry => entry.trim())

            this.sendingEmailViaBackend = true
            
            itBnTx.emailSessionReport({
                            inSessionID : this.$store.state.currentSessionID,
                        inRequestParams : this.emailReportData
                        })
                  .then(response => {
                        response

                        this.$Message.success("Email request has been sent.")
                        this.otherEmailField = ''
                        this.showEmailReport = false
                  })
                  .finally(() => {
                      this.sendingEmailViaBackend = false
                  })

        },
        
        handleCancelEmailReport() {
            AnalyticsMgr.sendEvent('/Report', 'click', 'Report_Email_Cancel')

            // clear out fields.
            this.otherEmailField = ''

            this.showEmailReport = false
        },

        updateReportToUI() {
            var currentSessionID = this.$store.state.currentSessionID

            if (currentSessionID != 0) {
                this.reportIsLoading = true
                
                setTimeout(() => {
                    this.$store.commit("justUpdatingAssessmentData", false)

                    itBnTx.createSessionReport({
                            inSessionID : currentSessionID,
                            inColor : (this.reportColorSelection == 'true' ? true : false)
                            })
                        .then(response => {
                            this.reportHTMLData = 'data:text/html;charset=utf-8,' + encodeURIComponent(response.data);
                            this.reportIsLoading = false
                        })
                }, (this.$store.state.justUpdatingAssessmentData == true) ? 1500 : 0);

            } else {
                this.reportHTMLData = "about:blank"
            }
        },


        handleShowSessionDetails() {
            AnalyticsMgr.sendEvent('/Report', 'click', 'Report_SessionDetails_Show')

            this.showSessionDetails = true

            // TODO: is there a timing issue here, when we load the referring physician and it loads slower than the session details...
            // Or are the referring physician api cached?!
            this.refreshReferringPhysicians()

            itBnTx.getSession({inSessionID : this.$store.state.currentSessionID })
                  .then(response => {
                      this.sessionDetails = response.data

                      this.selectedNewReferringPhysician = this.sessionDetails.referringPhysicianJSON
                  })
        },

        handleCancelSessionDetails() {
            AnalyticsMgr.sendEvent('/Report', 'click', 'Report_SessionDetails_Cancel')

            this.showSessionDetails = false
        },

        handleSaveSessionDetails() {
            AnalyticsMgr.sendEvent('/Report', 'click', 'Report_SessionDetails_Save')

            this.savingDetailsToBackend = true

            itBnTx.updateSession({ inSessionDetails : this.sessionDetails })
                  .then(response => {
                        response

                        this.updateReportToUI()
                        this.savingDetailsToBackend = false
                        this.showSessionDetails = false
                  })

            if (this.useReferringPhysicianMovingForward) {
                // console.log("PD", this.currentSelectedPatientDetails)
                // console.log("RP", this.selectedNewReferringPhysician)
                this.currentSelectedPatientDetails.referringPhysicianJSON = this.selectedNewReferringPhysician
                
                var updatePatientDetails = _.cloneDeep(this.currentSelectedPatientDetails)

                delete updatePatientDetails["_highlight"]
                
                itBnTx.updatePatient({inPatientDetails : updatePatientDetails })
                        .then(response => {
                            response
                        })
            }
        },

        handleReferringDoctorChanged( physicianID ) {
            var newReferringPhysician = this.referringPhysiciansData.find(entry => entry.referringPhysicianId == physicianID)

            //this.sessionDetails.referringPhysicianId = physicianID
            this.selectedNewReferringPhysician = newReferringPhysician
        },

                            
        refreshReferringPhysicians() {
            itBnTx.searchReferringPhysiciansInPractise().then(response => { 
                // get the referring physicians, then sort by title/firstname/lastname
                this.referringPhysiciansData = response.data
                                                        .filter(p => { return p.status == 'A' || p.status == 'T' })
                                                        .sort((a, b) => {
                                                            var a_title = a.title == undefined ? '' : a.title
                                                            var a_firstName = a.firstName == undefined ? '' : a.firstName
                                                            var a_lastName = a.lastName == undefined ? '' : a.lastName
                                                            var b_title = b.title == undefined ? '' : b.title
                                                            var b_firstName = b.firstName == undefined ? '' : b.firstName
                                                            var b_lastName = b.lastName == undefined ? '' : b.lastName
                                                            
                                                            return (([a_title, a_firstName, a_lastName].join(' ')).trim())
                                                                    .localeCompare(([b_title, b_firstName, b_lastName].join(' ')).trim()) 
                                                        });
                
                this.loadingFilteringReferringPhysicians("")
            })
        },

        loadingFilteringReferringPhysicians(query) {
            if (
                //query != '' && 
                this.sessionDetails.referringPhysicianId != 0) {

                this.referringPhysiciansLoadingFiltering = true;
                setTimeout(() => {
                    this.referringPhysiciansFiltered = this.referringPhysiciansData.filter(item => {

                            if (item.referringPhysicianId == 0
                                || item.referringPhysicianId == this.sessionDetails.referringPhysicianId) {
                                return true
                            }

                            var a_title = item.title == undefined ? '' : item.title
                            var a_firstName = item.firstName == undefined ? '' : item.firstName
                            var a_lastName = item.lastName == undefined ? '' : item.lastName
                                                            
                            return ([a_title, a_firstName, a_lastName].join(' ')).trim().toUpperCase().indexOf(query.toUpperCase()) > -1
                        });
                    this.referringPhysiciansLoadingFiltering = false;
                    // console.log("*********** loadingFilteringReferringPhysicians - filter  ", this.referringPhysiciansFiltered)
                }, 1);
            } else {
                //this.referringPhysiciansFiltered = [this.referringPhysiciansData[0]];
                this.referringPhysiciansLoadingFiltering = false;
                // console.log("*********** loadingFilteringReferringPhysicians - filter 2 ", this.referringPhysiciansFiltered)
            }
        },



        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/Report');

                this.reportColorSelection = this.$store.state.loggedInPracticeDetails.reportType == 0 ? 'true' : 'false'
                this.updateReportToUI()
            } else {
                if (this.originalTitle != '') {
                    document.title = "BnTx INTERACT" //this.originalTitle
                }
            }
        },
                
    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
    
        "$store.state.currentSessionID" (newValue, oldValue) {newValue, oldValue; this.reportHTMLData = "about:blank"; /* this.updateReportToUI()*/ },
        "reportColorSelection" (newValue, oldValue) {newValue, oldValue; 
            AnalyticsMgr.sendEvent('/Report', 'click', (this.reportColorSelection == 'true') ? 'Report_Color' : 'Report_Mono')

            this.updateReportToUI()
        },
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>

.drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #334155;

 }

.spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}


.scaled-frame {
  width: 1900px;
  height: 3500px;
  border: 0px;

  zoom: 0.50;
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}

</style>

