<template>

    <div class="" style="height: 300px; width: 100%; background-color: rgba(255, 255, 255, 1); text-align: center;">
        
        <h1>{{practiceDetails.practiseName}}</h1>

        <Input v-model="verifyLastname" placeholder="Patient Lastname" />
        <br />
        <Input v-model="verifyDOB" placeholder="Patient DOB eg YYYY-MM-DD" />
       
        <Button @click="handleVerify" style="width: 220px; margin:16px">Verify</Button>

    </div>
</template>

<script>

//import CONFIG from '@/js/config.json';
//const {CONFIG} = require('@/js/bntx-config')
const {itBnTx} = require('@/js/itBnTx')



// const axios = require('axios');


export default {
    name: 'MihubValidate',
    props: {
        // connectionNanoID: {
        //     type: String,
        //     required: false,
        //     default: ""
        // },
    },

    components: {
    },

    created () {

    },
    mounted() {
        itBnTx.getRequestConnectionDetails({inConnectionNanoID : this.$route.params.connectionNanoID})
              .then(response => {
                    console.log(response.data);
                    this.practiceDetails = response.data
              })
        

    },

    data () {
        return {
            practiceDetails : {
                practiseName : "",
                practiseLogo : "",
                practiseContactPhone : "",
                practiseAddress : "",
                practiseCity : "",
                practisePostcode : ""
            },

            verifyLastname : "",
            verifyDOB : "",

            verified : false
        }
    },

    computed: {
        
    },

    methods: {
       
        handleVerify() {
            itBnTx.validatePatientConnection({inConnectionNanoID : this.$route.params.connectionNanoID,
                                                 inLastname : this.verifyLastname,
                                                 inDOB : this.verifyDOB})
                    .then(response => {
                            console.log(response.data);
                            window.open(response.data.oauthUrl, "bntx-oauth-patient-app")
                    })
        },

    },

    watch: {
   
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>
