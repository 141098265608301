

/*
    returns an array of sessions with corresponding (if applicable) order object of info


    {
        "sessions": [
            {
            "sessionId": 0,
            "patientName": "string",
            "treatingPhysicianName": "string",
            "sessionDate": 0,
            "conditionIds": [
                0
            ],
            "medicationName": "BOTOX(\"BOTOX®\"),     DYSPORT(\"DYSPORT®\"),     NEUROBLOC(\"NEUROBLOC®\"),     XEOMIN(\"XEOMIN®\");",
            "injectedUnits": 0,
            "orderStatus": "DRAFT(\"DR\", \"draft\", \"ordDrafted\"),     PREPARING(\"PO\", \"preparing\", \"ordPreparing\"),     ORDERED(\"OR\", \"ordered\", \"ordOrdered\"),     PRODUCT_RECONCILED(\"PR\", \"product_reconciled\", \"ordPReconciled\"),     ORDER_NOT_REQUIRED(\"ON\", \"order_not_required\", \"ordNotRequired\");",
            "copaymentStatus": "PENDING(\"PE\", \"pending\"),     PAID(\"PA\", \"paid\"),     REFUNDED(\"RE\", \"refunded\"),     PAYMENT_FAILED(\"PF\", \"payment_failed\"),     BILL_RECONCILED(\"BR\", \"bill_reconciled\");",
            "order": {
                "uuid": "string",
                "draftedDate": 0,
                "preparingDate": 0,
                "orderedDate": 0,
                "pReconciledDate": 0,
                "distributorOrderId": "string",
                "bReconciledDate": 0,
                "orderStatus": "DRAFT(\"DR\", \"draft\", \"ordDrafted\"),     PREPARING(\"PO\", \"preparing\", \"ordPreparing\"),     ORDERED(\"OR\", \"ordered\", \"ordOrdered\"),     PRODUCT_RECONCILED(\"PR\", \"product_reconciled\", \"ordPReconciled\"),     ORDER_NOT_REQUIRED(\"ON\", \"order_not_required\", \"ordNotRequired\");",
                "copaymentStatus": "PENDING(\"PE\", \"pending\"),     PAID(\"PA\", \"paid\"),     REFUNDED(\"RE\", \"refunded\"),     PAYMENT_FAILED(\"PF\", \"payment_failed\"),     BILL_RECONCILED(\"BR\", \"bill_reconciled\");",
                "paidAmount": 0,
                "orderQuantity": 0,
                "paymentFailedReason": "string",
                "created": 0,
                "createdBy": 0,
                "modified": 0,
                "modifiedBy": 0
            }
            }
        ],
        "totalCount": 1
    }
*/
function getAllSessionOrders({  inStatus = null,
                                inFromDateTime = null,  // Long type UTC milliseconds from 1970
                                inToDateTime = null,
                                inStart = 0,
                                inLimit = 100} = {}) {
    var params = {
                    start : inStart,
                    limit : inLimit,
                    showMedication : true
                }

    if (inStatus != null) {
        params['status'] = inStatus
    }
    if (inFromDateTime != null) {
        params['from'] = inFromDateTime
    }
    if (inToDateTime != null) {
        params['to'] = inToDateTime
    }
                
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session-orders',
                        // inURI: '/rest/sessions',
                        inRequireAuth: true,
                        inQueryParams: params,
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    returns a single order object... 

    {
        "uuid": "string",
        "draftedDate": -9223372036854776000,
        "preparingDate": -9223372036854776000,
        "orderedDate": 0,
        "pReconciledDate": -9223372036854776000,
        "distributorOrderId": "string",
        "bReconciledDate": -9223372036854776000,
        "orderStatus": "DR",
        "copaymentStatus": "PE",
        "paidAmount": -1.7976931348623157e+308,
        "orderQuantity": -2147483648,
        "paymentFailedReason": "string",
        "created": 0,
        "createdBy": 0,
        "modified": 0,
        "modifiedBy": 0
    }
*/
function createOrderForSession({ inSessionID, inOrderOnlyMedID = null, inOrderOnlyQuantity = null } = {}) {
                
    var orderOnlyPayload = { injectMedicineId : inOrderOnlyMedID,
                                orderQuantity : inOrderOnlyQuantity
                            }

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session-orders/' + inSessionID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: (inOrderOnlyMedID != null && inOrderOnlyQuantity != null) ? JSON.stringify(orderOnlyPayload) : '{}'
                        });
}


/*
    returns a single order object... 

    used to update PR (product reconciled)
*/
function updateOrderForSession({ inSessionID, 
                                 inOrderData } = {}) {
                
    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session-orders/' + inSessionID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inOrderData)
                        });
}



function deleteOrderForSession({ inSessionID } = {}) {
                
    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session-orders/' + inSessionID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: {}
                        });
}

// inStatus = CA, PP, ON
// CANCEL("CA", "cancel", ""), PREPARING("PP", "preparing", "ordPreparing"), ORDER_NOT_REQUIRED("ON", "order_not_required", "ordNotRequired");
// PP = "Send"
function changeOrderStatusForSession({ inSessionID, inStatus } = {}) {
                
    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session-orders/' + inSessionID + '/change-state',
                        inRequireAuth: true,
                        inQueryParams: {
                            'new-state' : inStatus
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: {}
                        });
}


function getAllOrderAttachmentsForOrder({ inOrderID } = {}) {
                
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/orders/' + inOrderID + '/attachments',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: {}
                        });
}

function deleteOrderAttachment({ inOrderAttachmentID } = {}) {
                
    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/orders/attachments/' + inOrderAttachmentID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: {}
                        });
}



module.exports = { 

    getAllSessionOrders,
    createOrderForSession,
    updateOrderForSession,

    deleteOrderForSession,
    changeOrderStatusForSession,

    getAllOrderAttachmentsForOrder,
    deleteOrderAttachment,

};
