
export default {

// https://www.w3schools.com/howto/howto_js_draggable.asp
// https://www.horuskol.net/blog/2020-08-15/drag-and-drop-elements-on-touch-devices/

dragElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "Header")) {
    // if present, the header is where you move the DIV from:
    document.getElementById(elmnt.id + "Header").onmousedown = dragMouseDown;
    document.getElementById(elmnt.id + "Header").ontouchstart = dragMouseDown;
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown;
    elmnt.ontouchstart = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    if (e.clientX) {
      pos3 = e.clientX;
      pos4 = e.clientY;
    } else {
      // touch support
      pos3 = e.changedTouches[0].clientX;
      pos4 = e.changedTouches[0].clientY;
    }
    document.onmouseup = closeDragElement;
    document.ontouchend = closeDragElement;

    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
    document.ontouchmove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    if (e.clientX) {
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
    } else {
      // touch
      pos1 = pos3 - e.changedTouches[0].clientX;
      pos2 = pos4 - e.changedTouches[0].clientY;
      pos3 = e.changedTouches[0].clientX;
      pos4 = e.changedTouches[0].clientY;
    }
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
    document.ontouchend = null;
    document.ontouchmove = null;
  }
}

}