<template>
    <div v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;width:100%">
        <div v-show="rawData.data.length > 1">
            <LineChart v-bind="hit1ChartData" style="min-height:130px; max-height:160px;" />
            <LineChart v-bind="hit2ChartData" style="min-height:130px; max-height:160px;" />
            <LineChart v-bind="hit3ChartData" style="min-height:130px; max-height:160px;" />
            <LineChart v-bind="hit4ChartData" style="min-height:130px; max-height:160px;" />
            <LineChart v-bind="hit5ChartData" style="min-height:130px; max-height:160px;" />
            <LineChart v-bind="hit6ChartData" style="min-height:195px; max-height:250px;" />
        </div>
        <Row v-show="rawData.data.length <= 1">
            There is insufficient data to show meaningful charts. Once there is at least two sessions worth of data available, the charts will be shown.
        </Row>
    </div>
</template>

<script>
// const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
var _ = require('lodash');
// import AnalyticsMgr from '@/js/AnalyticsManager.js';

// import { Utils } from "chart.js";

const {itBnTx} = require('@/js/itBnTx')

import { LineChart, /*BarChart,*/ /*useBarChart*/ } from "vue-chart-3";
// import { ref, computed, /*defineComponent*/ } from "@vue/composition-api";
// import { shuffle } from "lodash";

// import {DateTime } from "luxon"

// import {enUS} from 'date-fns/locale';
// import 'chartjs-adapter-moment';
import 'chartjs-adapter-luxon';


const MAGIC_NULL_VAL = 5.25


export default {
    // name: 'su-pharmacy-management',
    name: 'report-insights-hit6',
    props: {

        patientID: {
            type: Number,
            required: false,
            default: 1
        },

    },
    components: {
        LineChart,
        // BarChart,
    },

    // setup() {
    //     const data = ref([30, 40, 60, 70, 5]);
    //     const legendTop = ref(true);
    //     const imgData = ref(null);

    //     const options = computed(() => ({
    //     scales: {
    //         y: {
    //         beginAtZero: true,
    //         },
    //     },
    //     plugins: {
    //         zoom: {
    //         zoom: {
    //             wheel: {
    //             enabled: true,
    //             },
    //             pinch: {
    //             enabled: true,
    //             },
    //             mode: "xy",
    //         },
    //         },
    //     },
    //     }));

    //     const chartData = computed(() => ({
    //     labels: ["Paris", "Nîmes", "Toulon", "Perpignan", "Autre"],
    //     datasets: [
    //         {
    //         data: data.value,
    //         backgroundColor: [
    //             "#77CEFF",
    //             "#0079AF",
    //             "#123E6B",
    //             "#97B0C4",
    //             "#A5C8ED",
    //         ],
    //         },
    //     ],
    //     }));

    //     const { barChartProps, barChartRef } = useBarChart({
    //     chartData,
    //     options,
    //     });

    //     function shuffleData() {
    //     data.value = shuffle(data.value);
    //     legendTop.value = !legendTop.value;
    //     imgData.value = barChartRef.value.chartInstance.toBase64Image();
    //     //   barChartRef.value.chartInstance.resetZoom();
    //     }

    //     function zoom() {
    //     barChartRef.value.chartInstance.zoom(1.01);
    //     }

    //     return { shuffleData, barChartProps, barChartRef, imgData, zoom };
    // },


    created () {


    },

    destroyed() {

    },

    mounted() {

    },


    data () {
            // const DATA_COUNT = 7;
            // const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

            return {
                // following are cached, and loaded as soon as we are visible, so save on unnecessary API calls. Sometimes too dynamic is not good.
                toLoadOnShowUI_patientID: null,


                rawData : {
                        data: [
                            {
                                "choiceAnswers": [
                                    {
                                        "identifier": "q1",
                                        "answer": 8
                                    },
                                    {
                                        "identifier": "q2",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q3",
                                        "answer": 11
                                    },
                                    {
                                        "identifier": "q4",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q5",
                                        "answer": 11
                                    },
                                    {
                                        "identifier": "q6",
                                        "answer": 10
                                    }
                                ],
                                "source" : 2,
                                "timestamp": 1599443921000
                            },
                            {
                                "choiceAnswers": [
                                    {
                                        "identifier": "q1",
                                        "answer": 6
                                    },
                                    {
                                        "identifier": "q2",
                                        "answer": 6
                                    },
                                    {
                                        "identifier": "q3",
                                        "answer": 6
                                    },
                                    {
                                        "identifier": "q4",
                                        "answer": null
                                    },
                                    {
                                        "identifier": "q5",
                                        "answer": null
                                    },
                                    {
                                        "identifier": "q6",
                                        "answer": 6
                                    }
                                ],
                                "source" : 1,
                                "timestamp": 1593050321000
                            },
                            {
                                "choiceAnswers": [
                                    {
                                        "identifier": "q1",
                                        "answer": 6
                                    },
                                    {
                                        "identifier": "q2",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q3",
                                        "answer": 8
                                    },
                                    {
                                        "identifier": "q4",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q5",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q6",
                                        "answer": 10
                                    }
                                ],
                                "source" : 2,
                                "timestamp": 1587520853000
                            },
                            {
                                "choiceAnswers": [
                                    {
                                        "identifier": "q1",
                                        "answer": 8
                                    },
                                    {
                                        "identifier": "q2",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q3",
                                        "answer": 11
                                    },
                                    {
                                        "identifier": "q4",
                                        "answer": 11
                                    },
                                    {
                                        "identifier": "q5",
                                        "answer": 11
                                    },
                                    {
                                        "identifier": "q6",
                                        "answer": 8
                                    }
                                ],
                                "source" : 2,
                                "timestamp": 1582164053000
                            },
                            {
                                "choiceAnswers": [
                                    {
                                        "identifier": "q1",
                                        "answer": 6
                                    },
                                    {
                                        "identifier": "q2",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q3",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q4",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q5",
                                        "answer": 10
                                    },
                                    {
                                        "identifier": "q6",
                                        "answer": 10
                                    }
                                ],
                                "source" : 2,
                                "timestamp": 1578016853000
                            }
                        ]
                },

                barChartProps2 : {
                    chartData : {
                        // labels: ["Paris", "Nîmes", "Toulon", "Perpignan", "Autre"],
                        // labels : [DateTime.now().plus(0).toJSDate(),
                        //             DateTime.now().plus(1).toJSDate(),
                        //             DateTime.now().plus(2).toJSDate(),
                        //             DateTime.now().plus(3).toJSDate(),
                        //             DateTime.now().plus(4).toJSDate(),
                        //             DateTime.now().plus(5).toJSDate(),
                        //             DateTime.now().plus(6).toJSDate(),
                        //             DateTime.now().plus(7).toJSDate(),
                        //             DateTime.now().plus(8).toJSDate(),
                        //             DateTime.now().plus(9).toJSDate(),
                        //             DateTime.now().plus(10).toJSDate()
                        // ],

                        // datasets: [
                        //     {
                        //     data: [30, 40, 60, 70, 5],
                        //     backgroundColor: [
                        //         "#77CEFF",
                        //         "#0079AF",
                        //         "#123E6B",
                        //         "#97B0C4",
                        //         "#A5C8ED",
                        //     ],
                        //     // backgroundColor: "#0079AF"
                        //     },, .3
                        // ],

                        datasets: [
                            {
                                label : 'HIT1',
                                borderColor : '#4f7aa7',
                                fill : {
                                    target: 'origin',
                                    above: 'rgb(200, 200, 200, .3)',   // Area will be red above the origin
                                    //below: 'rgb(0, 0, 255)'    // And blue below the origin
                                },
                                
                                pointBorderColor : (context) => { return this.pointBorderColor(context, 'crimson') },
                                pointStyle : this.pointStyle,
                                pointRadius : this.pointRadius,

                                cubicInterpolationMode: 'monotone',
                                data:[]
                            },
                            {
                                label : 'HIT2',
                                borderColor : '#9fcbe8',
                                // fill : true,
                                fill : {
                                    target: 'origin',
                                    above: 'rgb(200, 200, 200, .3)',   // Area will be red above the origin
                                    //below: 'rgb(0, 0, 255)'    // And blue below the origin
                                },

                                pointBorderColor : (context) => { return this.pointBorderColor(context, 'coral') },
                                pointStyle : this.pointStyle,
                                pointRadius : this.pointRadius,

                                cubicInterpolationMode: 'monotone',
                                data:[]
                            },
                            {
                                label : 'HIT3',
                                borderColor : '#f28f2a',
                                // fill : true,
                                fill : {
                                    target: 'origin',
                                    above: 'rgb(200, 200, 200, .3)',   // Area will be red above the origin
                                    //below: 'rgb(0, 0, 255)'    // And blue below the origin
                                },

                                pointBorderColor : (context) => { return this.pointBorderColor(context, 'gold') },
                                pointStyle : this.pointStyle,
                                pointRadius : this.pointRadius,

                                cubicInterpolationMode: 'monotone',
                                data:[]
                            },
                            {
                                label : 'HIT4',
                                borderColor : '#ffbe7e',
                                // fill : true,
                                fill : {
                                    target: 'origin',
                                    above: 'rgb(200, 200, 200, .3)',   // Area will be red above the origin
                                    //below: 'rgb(0, 0, 255)'    // And blue below the origin
                                },

                                pointBorderColor : (context) => { return this.pointBorderColor(context, 'mediumslateblue') },
                                pointStyle : this.pointStyle,
                                pointRadius : this.pointRadius,

                                cubicInterpolationMode: 'monotone',
                                data:[]
                            },
                            {
                                label : 'HIT5',
                                borderColor : '#58a14f',
                                // fill : true,
                                fill : {
                                    target: 'origin',
                                    above: 'rgb(200, 200, 200, .3)',   // Area will be red above the origin
                                    //below: 'rgb(0, 0, 255)'    // And blue below the origin
                                },

                                pointBorderColor : (context) => { return this.pointBorderColor(context, 'seagreen') },
                                pointStyle : this.pointStyle,
                                pointRadius : this.pointRadius,

                                cubicInterpolationMode: 'monotone',
                                data:[]
                            },
                            {
                                label : 'HIT6',
                                borderColor : '#8cd17e',
                                // fill : true,
                                fill : {
                                    target: 'origin',
                                    above: 'rgb(200, 200, 200, .3)',   // Area will be red above the origin
                                    //below: 'rgb(0, 0, 255)'    // And blue below the origin
                                },

                                pointBorderColor : (context) => { return this.pointBorderColor(context, 'orchid') },
                                pointStyle : this.pointStyle,
                                pointRadius : this.pointRadius,

                                cubicInterpolationMode: 'monotone',
                                data:[]
                            }
                        ],
                    },
                    options: {
                        
                        // scales: {
                        //     y: {
                        //         beginAtZero: true,
                        //     },
                        // },

                        scales: {
                            // adapters: {
                            //     date: {
                            //         // locale: enUS
                            //     }
                            // },

                            x: {
                                type: 'time',
                                time: {
                                  // Luxon format string
                                  tooltipFormat: 'DD T',
                                    // unit: 'day'

                                },
                                title: {
                                    display: true,
                                    text: 'Date'
                                },
                                ticks: {
                                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                                    callback: function(val, index) {
                                        // Hide every 2nd tick label
// console.log(">>>>>", val)

                                        return index % 2 === 0 ? val : '';
                                    },
                                    color: 'red',
                                }

                            },
                            y: {
                                title: {
                                    display: true,
                                    text: 'Score'
                                },
                                ticks: {
                                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                                    callback: function(val, index) {
                                        index
                                        // HIT6 scores range from 6 to 13, but we show a chart from 5 to 14 so the
                                        // results are not shown to the edge, but 5 and 14 are not valid results
                                        // so we exclude all non-real results from showing in the axis tick mark values.
                                        return val >= 6 && val <= 13 ? val : '';
                                    },
                                    color: 'red',
                                },

                                // fix the score axis to a min/max so all charts are shown in context of what the
                                // valid ranges can be. HIT 6 ranges from 6 to 13, but we use a min/max of 5 and 14
                                // to allow the chart to have padding when viewing results so results are not 
                                // to the edge of the chart to be visually better.
                                min : 5,
                                max : 14,
                            }
                        },

                        plugins: {
                            title: {
                                text: 'HIT6 over Time',
                                display: false,
                                position : 'top'
                            },

                            legend : {
                                display: false
                            },

                            zoom: {
                                zoom: {
                                    wheel: {
                                      enabled: true,
                                    },
                                    pinch: {
                                      enabled: true,
                                    },
                                    mode: "xy",
                                },
                            },
                        },
                    },
                    width: 300,
                    height: 150
                },




            }
        },

    filters: {

    },
    
    computed: {

        hit1ChartData() {
            var hit1Data = _.cloneDeep(this.barChartProps2)

            hit1Data.chartData.datasets = [_.cloneDeep(this.barChartProps2.chartData.datasets[0])]
            hit1Data.options.scales.x.title.display = false
            hit1Data.options.scales.x.ticks.callback = (val, index) => { index == 0 ? val : '' }
            hit1Data.options.scales.y.title.text = 'HIT 1'
            hit1Data.height = 100

            return hit1Data
        },

        hit2ChartData() {
            var hit1Data = _.cloneDeep(this.barChartProps2)

            hit1Data.chartData.datasets = [_.cloneDeep(this.barChartProps2.chartData.datasets[1])]
            hit1Data.options.scales.x.title.display = false
            hit1Data.options.scales.x.ticks.callback = (val, index) => { index == 0 ? val : '' }
            hit1Data.options.scales.y.title.text = 'HIT 2'
            hit1Data.height = 100

            return hit1Data
        },

        hit3ChartData() {
            var hit1Data = _.cloneDeep(this.barChartProps2)

            hit1Data.chartData.datasets = [_.cloneDeep(this.barChartProps2.chartData.datasets[2])]
            hit1Data.options.scales.x.title.display = false
            hit1Data.options.scales.x.ticks.callback = (val, index) => { index == 0 ? val : '' }
            hit1Data.options.scales.y.title.text = 'HIT 3'
            hit1Data.height = 100

            return hit1Data
        },

        hit4ChartData() {
            var hit1Data = _.cloneDeep(this.barChartProps2)

            hit1Data.chartData.datasets = [_.cloneDeep(this.barChartProps2.chartData.datasets[3])]
            hit1Data.options.scales.x.title.display = false
            hit1Data.options.scales.x.ticks.callback = (val, index) => { index == 0 ? val : '' }
            hit1Data.options.scales.y.title.text = 'HIT 4'
            hit1Data.height = 100

            return hit1Data
        },

        hit5ChartData() {
            var hit1Data = _.cloneDeep(this.barChartProps2)

            hit1Data.chartData.datasets = [_.cloneDeep(this.barChartProps2.chartData.datasets[4])]
            hit1Data.options.scales.x.title.display = false
            hit1Data.options.scales.x.ticks.callback = (val, index) => { index == 0 ? val : '' }
            hit1Data.options.scales.y.title.text = 'HIT 5'
            hit1Data.height = 100

            return hit1Data
        },

        hit6ChartData() {
            var hit1Data = _.cloneDeep(this.barChartProps2)

            hit1Data.chartData.datasets = [_.cloneDeep(this.barChartProps2.chartData.datasets[5])]
            //hit1Data.options.scales.x.title.display = false
            //hit1Data.options.scales.x.ticks.callback = (val, index) => { index == 0 ? val : '' }
            hit1Data.options.scales.y.title.text = 'HIT 6'
            hit1Data.height = 150

            return hit1Data
        },

    },
    methods: {
        // ====================================
        // CancasJS Scriptable Callback Helpers
        // ====================================
        pointBorderColor(context, defaultColor) {
            var index = context.dataIndex;
            var entryData = context.dataset.data[index]

            if (entryData == undefined) return defaultColor

            var source = context.dataset.data[index].source;
            return source == 2 /* patient */ ? defaultColor : 'black';
        },
        
        pointStyle(context) {
            var index = context.dataIndex;
            var entryData = context.dataset.data[index]

            if (entryData == undefined) return 'circle'

            var source = context.dataset.data[index].source;
            var value = context.dataset.data[index].y;
            return source == 2 /* patient */
                        ? 'circle'
                        : ( value == MAGIC_NULL_VAL /* mapped from null */ ? 'crossRot' : 'triangle');
        },

        pointRadius(context) {
            var index = context.dataIndex;
            var entryData = context.dataset.data[index]

            if (entryData == undefined) return 3

            var source = context.dataset.data[index].source;
            return source == 2 /* patient */ ? 3 : 8;
        },



        // ====================================

        shuffleData2() {
            // this.barChartProps2.chartData.datasets[0].data = shuffle(this.barChartProps2.chartData.datasets[0].data)
        },

        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                // AnalyticsMgr.sendPageView('/Superuser/SponsorManagement');

                 if (this.toLoadOnShowUI_patientID != null) {
                    this.refreshDataForUI()
                    this.toLoadOnShowUI_patientID = null
                 }
            }
        },

        refreshDataForUI() {

            // this.handleLoadEntriesListToNthPage(1)
            //console.log("HIT6 re-load data for patientID", this.toLoadOnShowUI_patientID)
            
            this.barChartProps2.chartData.datasets[0].data = []
            this.barChartProps2.chartData.datasets[1].data = []
            this.barChartProps2.chartData.datasets[2].data = []
            this.barChartProps2.chartData.datasets[3].data = []
            this.barChartProps2.chartData.datasets[4].data = []
            this.barChartProps2.chartData.datasets[5].data = []

            itBnTx.getPatientFeedbackInsights({inPatientID : this.patientID,
                                                inStartDate : null /* format "YYYY-MM-DD" */,
                                                inEndDate : null /* format "YYYY-MM-DD" */,
                                                inType : 2 /* 1 = QoL, 2 = HIT6 */})
                    .then(response => {
                        this.rawData = response.data
                        
                        this.rawData.data.map((entry) => {
                            var v = entry.choiceAnswers.filter((result) => { return (result.identifier == "q1" ? true : false) })[0].answer;
                        
                            this.barChartProps2.chartData.datasets[0].data.push({source : entry.source, x : entry.timestamp, y: (v != null ? v : MAGIC_NULL_VAL) })
                            v = entry.choiceAnswers.filter((result) => { return (result.identifier == "q2" ? true : false) })[0].answer;
                            this.barChartProps2.chartData.datasets[1].data.push({source : entry.source, x : entry.timestamp, y: (v != null ? v : MAGIC_NULL_VAL) })
                            v = entry.choiceAnswers.filter((result) => { return (result.identifier == "q3" ? true : false) })[0].answer;
                            this.barChartProps2.chartData.datasets[2].data.push({source : entry.source, x : entry.timestamp, y: (v != null ? v : MAGIC_NULL_VAL) })
                            v = entry.choiceAnswers.filter((result) => { return (result.identifier == "q4" ? true : false) })[0].answer;
                            this.barChartProps2.chartData.datasets[3].data.push({source : entry.source, x : entry.timestamp, y: (v != null ? v : MAGIC_NULL_VAL) })
                            v = entry.choiceAnswers.filter((result) => { return (result.identifier == "q5" ? true : false) })[0].answer;
                            this.barChartProps2.chartData.datasets[4].data.push({source : entry.source, x : entry.timestamp, y: (v != null ? v : MAGIC_NULL_VAL) })
                            v = entry.choiceAnswers.filter((result) => { return (result.identifier == "q6" ? true : false) })[0].answer;
                            this.barChartProps2.chartData.datasets[5].data.push({source : entry.source, x : entry.timestamp, y: (v != null ? v : MAGIC_NULL_VAL) })
                        })
                    })
                //   .catch(error => { error;  })

        },
        
       

    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
    
        "patientID" (newValue, oldValue) { oldValue; /* console.log("NEW PATIENT_ID - HIT6", newValue);*/ this.toLoadOnShowUI_patientID = newValue }
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  

</style>

