<template>
    <div id="su-setup-practises-and-users" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
        <Divider orientation="left">All Practices</Divider>

        <Row :gutter="8" >
            <!-- <Button @click="handleAddEntry" style="margin-right:8px">Add Entry</Button>
            <Button @click="handleEditEntry" style="margin-right:8px">Edit Entry</Button>
            <Button @click="handleRemoveEntry" style="margin-right:8px">Remove Entry</Button> -->
            <Button @click="handleSwitchPractice" style="margin-left:8px; margin-right:8px">Switch Practice</Button>

            <Poptip
                confirm transfer
                width="300"
                :title="confirmResetTitle"
                ok-text="Reset"
                cancel-text="Cancel"
                @on-ok="handleResetBilling">
                
                <Button type="error" style="margin-right:8px">Reset Billing</Button>
            </Poptip>
            
            <DatePicker type="date" v-model="trialStartDate" placeholder="Select a date" style="width: 130px;margin-right:8px"></DatePicker>

            <Poptip
                confirm transfer
                width="300"
                :title="confirmUpdateTrialStartDateTitle"
                ok-text="Update"
                cancel-text="Cancel"
                @on-ok="confirmUpdateTrialStartDate">
                
                <Button type="warning" style="margin-right:8px">Update Trial Start Date</Button>
            </Poptip>
            
            <Button v-if="editEntryDetails.supportsCoPayments == false" style="margin-right:8px" @click="handleEnableCopaymentSupport">Enable Co-Payment Support</Button>
            <Button v-else style="margin-right:8px" @click="handleDisableCopaymentSupport">Disable Co-Payment Support</Button>

            <Button v-if="editEntryDetails.supportsMigrainePatientApp == false" style="margin-right:8px" @click="handleEnableMigraineAppSupport">Enable Migraine App Support</Button>
            <Button v-else style="margin-right:8px" @click="handleDisableMigraineAppSupport">Disable Migraine Support</Button>
        </Row>

        <Row>
            <Table style="width: 100%; margin-top:8px" size="default"  :height="tableHeightToFit" draggable :columns="practicesTableColumnsConfig" :data="practisesTableData" border highlight-row @on-current-change="handlePracticeSelectionChanged">
                <template slot-scope="{ row }" slot="status"> {{ row.statusCode | userFriendlyStatus }} </template>
                <template slot-scope="{ row }" slot="acc_status"> {{ row.accountStatusCode | userFriendlyAccountStatus }} </template>
                <template slot-scope="{ row }" slot="dayleft"> {{ row.accountStatusCode == 'T' ? row.daysLeft : '' }} </template>
                <template slot-scope="{ row }" slot="created"> {{ row.created | userFriendlyDate }} </template>

                <template slot-scope="{ row }" slot="logo">
                    <img v-if="row.practiseLogo != ''" :src="row.practiseLogo" height="50px" />
                </template>
            </Table>

            <!-- <Table style="width: 100%;" size="default" draggable :columns="usersTableColumnsConfig" :data="usersTableData" border highlight-row @on-current-change="handleUserSelectionChanged">
                <template slot-scope="{ row }" slot="condition">
                    {{ row.patientConditionIds | conditionsAsString }}
                </template>
            </Table> -->
        </Row>                                        


        

    </div>
</template>

<script>
//const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
import AnalyticsMgr from '@/js/AnalyticsManager.js';
import moment from 'moment'

const {itBnTx} = require('@/js/itBnTx')



export default {
    name: 'su-setup-practises-and-users',
    components: {

    },

    created () {

    },

    destroyed() {

    },

    mounted() {


    },

    data () {
            return {

                practicesTableColumnsConfig: [
                    {                      title: 'Practice ID',           key: 'practiseId', resizable: true, sortable: true, width: 120 },
                    { slot: 'status',      title: 'Status',                key: 'statusCode', resizable: true, sortable: true, width: 120 },
                    { slot: 'acc_status',  title: 'Account Status',        key: 'accountStatusCode', resizable: true, sortable: true, width: 130 },
                    { slot: 'dayleft',     title: 'Days Left',             key: 'daysLeft', resizable: true, sortable: true, width: 120 },
                    { /* slot: 'condition', */  title: 'Practice Name',    key: 'practiseName', resizable: true, sortable: true, minWidth: 200 },
                    {                      title: 'Address',               key: 'practiseAddress', resizable: true, width: 300 },
                    {                      title: 'City',                  key: 'practiseCity', resizable: true, sortable: true, width: 120 },
                    {                      title: 'Postcode',              key: 'practisePostcode', resizable: true, sortable: true, width: 120 },
                    {                      title: 'Country Code',          key: 'countryCode', resizable: true, sortable: true, width: 120 },
                    {                      title: 'Support Co-Payments',   key: 'supportsCoPayments', resizable: true, sortable: true, width: 120 },
                    {                      title: 'Support Migraine App',  key: 'supportsMigrainePatientApp', resizable: true, sortable: true, width: 120 },
                    { slot: 'logo',        title: 'Practice Logo',         key: 'practiseLogo', resizable: true, width: 150 },
                    {                      title: 'Contact Name',          key: 'practiseContactName', resizable: true, sortable: true, width: 150 },
                    {                      title: 'Contact Phone',         key: 'practiseContactPhone', resizable: true, width: 150 },
                    {                      title: 'Contact Fax',           key: 'practiseFax', resizable: true, width: 150 },
                    {                      title: 'Contact Email',         key: 'practiseContactEmail', resizable: true, sortable: true, width: 220 },
                    {                      title: 'Created By',            key: 'createdBy', resizable: true, width: 150 },
                    { slot: 'created',     title: 'Created',               key: 'created', resizable: true, width: 150 },
                ],

                practisesTableData: [],


                usersTableColumnsConfig: [
                    {                      title: 'User ID',           key: 'firstName', resizable: true, sortable: true, width: 300 },
                    {                      title: 'Status Code',            key: 'lastName', resizable: true, sortable: true, width: 300 },
                    {                      title: 'Practice ID',        key: 'dateOfBirth', resizable: true, width: 150 },
                    { /* slot: 'condition', */  title: 'User Name',           key: 'conditions', resizable: true, minWidth: 100 },
                    {                      title: 'User Email',  key: 'referringPhysician', resizable: true, width: 300 },
                    {                      title: 'User Email Validated',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Is Temp Password',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Password Hint',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Password Failures',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User is Account Locked',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Last Login',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Role Codes',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'Created By',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'Created',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'Modified By',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'Modified',        key: 'dateOfBirth', resizable: true, width: 150 },
                ],

                usersTableData: [],


                editEntryDetails : { practiseName : ""},

                trialStartDate : new Date()

            }
        },

    filters: {

    },
    
    computed: {
        confirmResetTitle() {
            return "Are you sure you want to reset billing for '" + this.editEntryDetails.practiseName + "'"
        },

        confirmUpdateTrialStartDateTitle() {
            return "Are you sure you want to change trial start date to '" + moment(this.trialStartDate).format('YYYY-MM-DD') + "' for '" + this.editEntryDetails.practiseName + "'"
        },

        tableHeightToFit() {
            return window.innerHeight - 120
        },

    },
    methods: {

        refreshUI() {
            itBnTx.getAllPractises()
                    .then(response => {
                        this.practisesTableData = response.data;
                    })
//          AnalyticsMgr.sendPageView('/Assessments')
        },


        visibilityChanged (isVisible, entry) {
            entry
  
            if (isVisible == true) {
                this.refreshUI()

            }
        },

        handleUserSelectionChanged() {

        },
        
        handlePracticeSelectionChanged(currentRow, oldCurrentRow) {
            currentRow, oldCurrentRow

            this.editEntryDetails = { practiseName : ""}
            // clear previously selected data, before loading new data.

            if (currentRow == null) {
                return
            }

            // clone data so when cancelling an edit session, we can restore the original details in UI
            this.editEntryDetails = currentRow
        },

        handleAddEntry() {

        },

        handleEditEntry() {

        },

        handleRemoveEntry() {
            
        },

        handleSwitchPractice() {

            if (this.editEntryDetails.practiseId != undefined) {
                itBnTx.switchPractise({inPractiseID : this.editEntryDetails.practiseId })
                    .then(response => {

                            AnalyticsMgr.sendEvent('/SU_SwitchPractice', 'api', 'SU_SwitchPractice_Success')

                            this.$store.commit("loggedInDoctorDetails", response.data )

                            this.$Message.success('Switched.');
                    })

            }
            
        },

        handleResetBilling() {
            if (this.editEntryDetails.practiseId != undefined) {
                itBnTx.resetPracticeStripe({
                                        inPracticeID : this.editEntryDetails.practiseId
                                    })
                      .then(response => {
                          this.$Message.success({
                                            content: response.data.responseMsg,
                                            duration: 3
                                        })
                      })
            }
        },

        confirmUpdateTrialStartDate() {
            //console.log(this.trialStartDate.valueOf())

            if (this.editEntryDetails.practiseId != undefined) {
                itBnTx.updateTrialStartDate({
                                        inPracticeID : this.editEntryDetails.practiseId,
                                        inStartDate : this.trialStartDate.valueOf(),
                                        inIsExtension : false
                                    })
                      .then(response => {
                          this.$Message.success({
                                            content: response.data.responseMsg,
                                            duration: 3
                                        })
                          this.refreshUI()
                      })
            }
        },

        handleEnableCopaymentSupport() {
            if (this.editEntryDetails.practiseId != undefined) {
                this.editEntryDetails.supportsCoPayments = true
                itBnTx.updatePractise({inPractiseRecord : this.editEntryDetails})
                      .then(response => {
                          response
                           this.refreshUI()
                      })
            }
        },

        handleDisableCopaymentSupport() {
            if (this.editEntryDetails.practiseId != undefined) {
                this.editEntryDetails.supportsCoPayments = false
                itBnTx.updatePractise({inPractiseRecord : this.editEntryDetails})
                      .then(response => {
                          response
                           this.refreshUI()
                      })
            }
        },

        handleEnableMigraineAppSupport() {
            if (this.editEntryDetails.practiseId != undefined) {
                this.editEntryDetails.supportsMigrainePatientApp = true
                itBnTx.updatePractise({inPractiseRecord : this.editEntryDetails})
                      .then(response => {
                          response
                           this.refreshUI()
                      })
            }
        },

        handleDisableMigraineSupport() {
            if (this.editEntryDetails.practiseId != undefined) {
                this.editEntryDetails.supportsMigrainePatientApp = false
                itBnTx.updatePractise({inPractiseRecord : this.editEntryDetails})
                      .then(response => {
                          response
                           this.refreshUI()
                      })
            }
        },
    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
    
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  

</style>

