<template>
    <div id="admin-practise-injection-protocols" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
         <Drawer
            v-model="showDetails"
            width="500"
            :mask-closable="false"
            :styles="detailsDrawerStyles">

            <p slot="header"><center>Injection Protocol Details</center></p>

            <Row :gutter="20">
                <Form ref="editEntryDetailsFormFields" :model="editEntryDetails" :rules="editEntryDetailsFieldRules" :label-width="130" label-position="right">
                    <FormItem label="Protocol Name:" prop="pipName" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.pipName" type="text" placeholder="Protocol Name"></Input><br />
                    </FormItem>
                    <FormItem label="Associate with Condition:" prop="conditions" :class="mandatoryFieldsStyle">
                        <RadioGroup v-model="editEntryDetails.conditionId" type="button" button-style="solid" :style=" 'margin-top: 8px'">
                            <Radio v-for="condition in conditionsRefData" :key="condition.conditionId" :label="condition.conditionId" style="width:160px; text-align:center;" >{{ condition.conditionName }}</Radio>
                        </RadioGroup>
                    </FormItem>

                    <br />
                    <FormItem label="Status:">
                        <Select v-model="editEntryDetails.status" style="width:200px" filterable>
                            <Option v-for="item in statusRefData" :value="item.statusCode" :key="item.statusCode">{{ item.statusName }}</Option>
                        </Select>
                    </FormItem>
                    
                </Form>
            </Row>

            <div class="drawer-footer">
                <Button style="margin-right: 8px" @click="handleCancelEntryDetails">Cancel</Button>
                <Button type="success" :loading="savingDetailsToBackend" @click="handleSaveEntryDetails">
                    <span v-if="!savingDetailsToBackend">Save</span>
                    <span v-else>Saving...</span>
                </Button>
            </div>
        </Drawer>



        <Divider orientation="left">Practice Injection Protocols
            <Tooltip placement="bottom">
                <Icon type="md-help-circle" color="#4287f5" />
                <div slot="content" style="white-space: normal">
                    <p>This section allows you to manage all the injection protocols you have created for your practice.</p>
                    <br />
                    <p>Injection protocols can be created from the Injections section. Simply create all the relevent injections for all the muscles you require, then create an injection protocol from the session of injection points.</p>
                    <br />
                    <p>To disable/deprecate a specific injection protocol from showing in the list of protocols in the Injections section, simply edit an entry here and change its status to Inactive.</p>
                    <br />
                    <p>NOTE: Injection protocols can only be deleted if they are not used with any patients, otherwise they will be made Inactive.</p>
                </div>
            </Tooltip>
        </Divider>

        <Row :gutter="8" >
            <!-- <Button @click="handleAddEntry" style="margin-right:8px">Add Entry</Button> -->
            <Button @click="handleEditEntry" style="margin-left:8px; margin-right:8px">Edit Entry</Button>

            <Poptip
                confirm transfer
                width="300"
                :title="confirmRemoveEntryMessage"
                ok-text="Yes"
                cancel-text="No"
                @on-ok="handleRemoveEntry">
                
                <Button type="error" style="margin-right:8px">Remove Entry</Button>
            </Poptip>

            <Input v-model="filterProtocolBy" placeholder="Search by protocol name." :clearable="true" style="width: 200px; margin-right:8px" />
            <!-- <Input v-model="filterByFirstname" placeholder="First name" :clearable="true" @on-change="throttledSearch" style="width: 300px; margin-right: 8px" />
            <Input v-model="filterByLastname" placeholder="Last name" :clearable="true"  @on-change="throttledSearch" style="width: 300px" /> -->

            <Checkbox v-model="showAllStatusProtocols" style="margin-top:6px; margin-right:8px">Include All Status Protocols</Checkbox>
        </Row>
        


        <Row style="margin-top:8px">
            <Table style="width: 100%;" :height="tableHeightToFit" size="default" draggable :columns="entriesTableColumnsConfig" :data="entriesToDisplay" border highlight-row @on-current-change="handleEntriesSelectionChanged" @on-row-dblclick="handleEditEntry">
                <template slot-scope="{ row }" slot="created"> {{ row.created | userFriendlyDate }} </template>
                <template slot-scope="{ row }" slot="condition"> {{ row.conditionId | conditionsAsString }} </template>
                <template slot-scope="{ row }" slot="status"> {{ row.status | userFriendlyInjectionProtocolStatus }}</template>
               <!-- <template slot-scope="{ row }" slot="logo">
                    <img v-if="row.practiseLogo != ''" :src="row.practiseLogo" height="50px" />
                </template> -->
            </Table>

            <!-- <Table style="width: 100%;" size="small" draggable :columns="usersTableColumnsConfig" :data="usersTableData" border highlight-row @on-current-change="handleUserSelectionChanged">
                <template slot-scope="{ row }" slot="condition">
                    {{ row.patientConditionIds | conditionsAsString }}
                </template>
            </Table> -->
        </Row>                                        

        <!-- <Row>
            <Col span="12" class="ivu-text-right">
                <Page :total="entriesTableData.totalCount" :page-size="entriesListPageSize" :page-size-opts="[20,100,200,500,1000]" show-sizer @on-change="handleLoadEntriesListToNthPage" @on-page-size-change="handlePageLimitChanged" />
            </Col>
        </Row> -->
       

    </div>
</template>

<script>
//const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
var _ = require('lodash');
import AnalyticsMgr from '@/js/AnalyticsManager.js';

const {itBnTx} = require('@/js/itBnTx')



export default {
    name: 'admin-practise-injection-protocols',
    components: {

    },

    created () {


        // create a throttler function which will automatically manage throttling search requests to a limit, currently 1 per however many milliseconds
        this.throttledSearch = _.debounce(() => { this.handleLoadEntryListToNthPage(1); console.log("handleThrottledSearch 600ms") }, 600, { 'leading': false })

    },

    destroyed() {

    },

    mounted() {


    },



    data () {
            return {
                statusRefData : [
                    { statusCode : 'A', statusName: 'Active'},
                    { statusCode : 'T', statusName: 'Training Entry'},
                    { statusCode : 'I', statusName: 'Inactive'},
                ],

                entriesTableColumnsConfig: [
                    {                      title: 'Injection Protocol Name',        key: 'pipName', resizable: true, sortable: true, width: 300 },
                    { slot: 'condition',   title: 'Applicable Condition',           key: 'conditionId', resizable: true, sortable: true, minWidth: 120 },
                    { slot: 'status',      title: 'Status',                         key: 'status', resizable: true, sortable: true, width: 150 },
                    { slot: 'created',     title: 'Date Created',                   key: 'created', resizable: true, sortable: true, width: 140 },
                    {                      title: 'Created By',                     key: 'createdBy', resizable: true, sortable: true, width: 120 },
                ],


                throttledSearch : () => {},
                // filterByFirstname: "",
                // filterByLastname: "",

                entriesListIsLoading : false,
                entriesListPageSize : 20,
                entriesTableData: [],
                editEntryDetails: {
                                        // practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                                        // firstName: "",
                                        // lastName: "",
                                        // physicianAddress: "",
                                        // physicianPhone: "",
                                        // status: "A",
                                    },
                savingDetailsToBackend : false,



                liveValidating: true,
                liveValidatingFieldsOK: true,
                editEntryDetailsFieldRules : {
                    pipName: [
                        { required: true, message: 'Protocol name cannot be empty', trigger: 'blur' },
                    ],
                    
                },



                filterProtocolBy : '',
                // filterLastnameBy : '',
                showAllStatusProtocols : false,


                isCreatingNewEntry : false,
                selectedEntryDetailsBeforeCreatingNewEntry : {},      // this is used to restore the previous selection when user cancels create new entry.

                showDetails: false,
                detailsDrawerStyles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static'
                },


            }
        },

    filters: {

    },
    
    computed: {
        conditionsRefData() { return this.$store.state.refDataConditions },


        // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
        mandatoryFieldsStyle() { return (this.liveValidating && (this.liveValidatingFieldsOK == false)) ? "revealValidationMessages" : "" },

        entriesToDisplay() {

            return this.entriesTableData.filter(entry => {

                if (this.showAllStatusProtocols == false && (entry.status != 'A' && entry.status != 'T')) return false   // show Active only or everything.

                if (entry.pipName == undefined && this.filterProtocolBy != '') return false  // if we are searching firstname, but entry firstname if not defined or empty, we are definitely not a match.


                // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                if (this.filterProtocolBy != '' && entry.pipName != undefined && entry.pipName.toLowerCase().includes(this.filterProtocolBy.toLowerCase()) == false) return false // remove all items that dont match our protocol filter
                // if (this.filterLastnameBy != '' && entry.lastName != undefined && entry.lastName.toLowerCase().includes(this.filterLastnameBy.toLowerCase()) == false) return false // remove all items that dont match our lastname filter

                return true
            })

        },

        confirmRemoveEntryMessage() {
            return "Are you sure you wish to remove '" 
                    + this.editEntryDetails.pipName
                    + "' from the system?"
        },
        
        tableHeightToFit() {
            return window.innerHeight - 120
        },


    },
    methods: {
        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/Administration/InjectionProtocol');

                this.refreshDataForUI()
            }
        },

        refreshDataForUI() {

            this.handleLoadEntriesListToNthPage(1)

        },
        
        handlePageLimitChanged(inNewLimit) {
            this.entriesListPageSize = inNewLimit       // silly two way binding not working... so have to do this way
            this.handleLoadEntriesListToNthPage(1)
        },


        handleLoadEntriesListToNthPage(inPageNumber) {
            inPageNumber

            var loadOrSearchPromise;

            // show loading spinner
            this.entriesListIsLoading = true

            // if (this.filterByFirstname != "" || this.filterByLastname != "") {
            //     loadOrSearchPromise = itBnTx.searchPatientsByName({
            //                                     inSearchFirstnameString : this.filterByFirstname,
            //                                     inSearchLastnameString : this.filterByLastname,
            //                                     inStart : this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit : this.entriesListPageSize })
            // } else {
            //     loadOrSearchPromise = itBnTx.searchPatientsInPractise({
            //                                     inStart: this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit: this.entriesListPageSize})
            // }
            loadOrSearchPromise = itBnTx.searchInjectionProtocolsInPractise()

            loadOrSearchPromise.then(response => {
                
                var entries = response.data;
                // entries.map(entry => { if (entry.userLastLogin == undefined) entry['userLastLogin'] = 0 })
                this.entriesTableData = entries;
                
                var foundEntryToHighlight = false

                if (this.editEntryDetails.pipId != undefined) {
                    // if we previously had a selection, try to preserve the same record which was previously selected
                    var previousSelection = this.entriesToDisplay.find(entry => { return entry.pipId == this.editEntryDetails.pipId} )

                    // sanity check... should never happen... unless someone deletes it from another location.
                    if (previousSelection != undefined) {
                        previousSelection["_highlight"] = true
                        this.handleEntriesSelectionChanged(previousSelection, null)
                        foundEntryToHighlight = true
                    }
                }
                
                if (foundEntryToHighlight == false) {
                    // else select the first record if nothing was selected

                    // if we have at least one entry, select the first one.
                    if (this.entriesToDisplay.length != 0) {
                        this.entriesToDisplay[0]["_highlight"] = true
                        this.handleEntriesSelectionChanged(this.entriesToDisplay[0], null)
                    }
                }

            }).finally(() => {
                // hide loading spinner
                this.entriesListIsLoading = false
            })
        },
        
        
        handleEntriesSelectionChanged(currentRow, oldCurrentRow)  {
            oldCurrentRow
            
            this.editEntryDetails = {}
            
            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                return
            }

            // clone data so when cancelling an edit session, we can restore the original details in UI
            this.editEntryDetails = _.cloneDeep(currentRow)

        },
        
        handleAddEntry() {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'InjectionProtocol_Add')

            this.liveValidating = false

            this.selectedEntryDetailsBeforeCreatingNewEntry = _.cloneDeep(this.editEntryDetails)

            this.editEntryDetails = {
                                        // practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                                        // firstName: "",
                                        // lastName: "",
                                        // physicianAddress: "",
                                        // physicianPhone: "",
                                        // status: "A",
                                    }
            this.$refs['editEntryDetailsFormFields'].resetFields()

            this.isCreatingNewEntry = true;
            this.showDetails = true

        },

        handleEditEntry() {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'InjectionProtocol_Edit')

            this.liveValidating = true

            this.checkFormMandatoryFields()

            this.isCreatingNewEntry = false
            this.showDetails = true
        },

        handleRemoveEntry() {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'InjectionProtocol_Remove')

            if (this.editEntryDetails.pipId != undefined) {
                itBnTx.deletePractiseInjectionProtocol({inPractiseInjectionProtocolID : this.editEntryDetails.pipId})
                      .then(response => {
                          response
                        
                          this.refreshDataForUI()

                          this.$Message.success('Successfully removed');
                      })
            }
            
        },


        checkFormMandatoryFields() {
            if (this.liveValidating) {
                this.$refs['editEntryDetailsFormFields'].validate((valid) => {
                    if (valid) {
                        this.liveValidatingFieldsOK = true
                    } else {
                        this.liveValidatingFieldsOK = false
                    }
                })
            }
        },



        handleCancelEntryDetails() {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'InjectionProtocol_Edit_Cancel')

            if (this.isCreatingNewEntry == false) {
                var previousSelection = this.entriesTableData.find(entry => { return entry.pipId == this.editEntryDetails.pipId} )

                // restore any changes back to what they were since the user cancelled.
                this.editEntryDetails = _.cloneDeep(previousSelection)
            } else {
                this.editEntryDetails = this.selectedEntryDetailsBeforeCreatingNewEntry
            }

            this.showDetails = false
        },

        handleSaveEntryDetails() {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'InjectionProtocol_Edit_Save')

            this.$refs['editEntryDetailsFormFields'].validate((valid) => {
                if (valid) {
                    this.liveValidatingFieldsOK = true

                    var savingDetails = this.editEntryDetails

                    // if we are a new record, make sure to clear the userId so the API knows to create a new record
                    if (savingDetails.pipId == undefined) {
                        delete savingDetails.pipId
                    }
                                        
                    delete savingDetails._highlight // delete UI key, otherwise API barfs

 //                   console.log("SAVE DETAILS> ", JSON.stringify(this.savingDetails));

                    this.savingDetailsToBackend = true
                    
                    var savingPromise

                    if (this.isCreatingNewEntry == true)
                        savingPromise = itBnTx.createPractiseInjectionProtocol({inPractiseInjectionProtocolRecord : savingDetails })
                    else
                        savingPromise = itBnTx.updatePractiseInjectionProtocol({inPractiseInjectionProtocolRecord : savingDetails })

                    savingPromise
                          .then(response => {
                                response
                                
                                // reload data into UI
                                this.refreshDataForUI()
                                this.showDetails = false
                          })
                          .finally(() => {
                              this.savingDetailsToBackend = false
                          })

                } else {
                    this.liveValidating = true
                    this.liveValidatingFieldsOK = false

                    this.$Message.error('Enter mandatory fields before trying again.');
                }
            })
        },

    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...

        "editEntryDetails.pipName" :      function() { if (this.liveValidating) this.$refs['editEntryDetailsFormFields'].validate() },

        
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  
.drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #334155;

 }

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}


.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}

</style>

