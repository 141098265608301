<template>
  <div >
    <!-- <Layout>
      <Header></Header>
      <Content> -->
          <!-- <Row justify="center" style="margin-top:10px"> 
              <img src="@/assets/app_logo.svg" style="width:250px;padding:16px;"/>
          </Row> -->
          <Row justify="center" style="margin-top:30px"> 
            <!-- <Col v-bind="responsiveRegistration">
                <div class="productBlurb">
BnTx INTERACT is available for clinics specialising in the injection of botulinum toxin treating headache, dystonia, spasticity and other conditions. <br /> <br />

<span style="font-weight:250">
    BnTx provides an extended free-to-use period for piloting in your clinic for 30 days. No credit card is required upfront. If you choose to proceed after this period we have packages starting at $20USD per month. Note: this offering is available for a limited time only.  <br /> <br />
</span>

Designed by leading injecting neurologists, BnTX has captured over 500,000 injections over several years. The system is secure and compliant adhering to global health data and technology requirements.  <br /> <br />

<span style="font-weight:250">
    Simply fill in the form on this page and you can immediately start your trial. <br /> <br />
</span>
                </div>
            </Col> -->
            <!-- <Col v-bind="responsiveRegistration">
            <Card style="margin: 10px 50px 80px 50px; width: 350px;"> -->
            <Col>
            <Card style="margin: 10px 50px 80px 50px; maxWidth: 500px;">
                <Row justify="center">
                  <img src="@/assets/app_logo.svg" style="width:250px;padding:16px;"/>

                  <center>
                    BnTx INTERACT is available for clinics specialising in the injection of botulinum toxin treating headache, dystonia, spasticity and other conditions. <br />

                    Simply fill in the form on this page and you can immediately start your trial. <br />
                    
                    Your 30 day trial will start immediately. No credit card is required upfront. <br /> <br />
                  </center>

                  <Form ref="registerFormFields" style="width:100%" :model="registerFormFields" :rules="registerFieldRules" :label-width="110" autocomplete="on" inline >
                    <FormItem label="Contact Name" style="width:100%" prop="practiseContactName" :class="mandatoryFieldsStyle" >
                        <Input ref="practiseContactName" type="text" autocomplete="name" name="name" v-model="registerFormFields.practiseContactName"></Input>
                    </FormItem>
                    <FormItem label="Email" style="width:100%" prop="practiseContactEmail" :class="mandatoryFieldsStyle" >
                        <Input ref="practiseContactEmail" type="text" autocomplete="email" name="email" v-model="registerFormFields.practiseContactEmail"></Input>
                    </FormItem>
                    <FormItem label="Password" style="width:100%" prop="password" :class="mandatoryFieldsStyle" >
                        <Input ref="password" type="password" password autocomplete="new-password" name="new-password" v-model="registerFormFields.password"></Input>
                    </FormItem>
                    <FormItem label="Phone" style="width:100%" prop="practiseContactPhone" :class="mandatoryFieldsStyle" >
                        <Input ref="practiseContactPhone" type="text" autocomplete="tel" name="tel" v-model="registerFormFields.practiseContactPhone"></Input>
                    </FormItem>
                    <FormItem label="Practice Name" style="width:100%" prop="practiseName" :class="mandatoryFieldsStyle" >
                        <Input ref="practiseName" type="text" autocomplete="organization" name="organization" v-model="registerFormFields.practiseName"></Input>
                    </FormItem>
                    <FormItem label="Address" style="width:100%" prop="practiseAddress" :class="mandatoryFieldsStyle" >
                        <Input ref="practiseAddress" type="text" autocomplete="street-address" name="street-address" v-model="registerFormFields.practiseAddress" element-id="registerAddressFinder"></Input>
                    </FormItem>
                    <FormItem label="City" style="width:100%" prop="practiseCity" :class="mandatoryFieldsStyle" >
                        <Input ref="practiseCity" type="text" autocomplete="address-level2" name="address-level2" v-model="registerFormFields.practiseCity"></Input>
                    </FormItem>
                    <FormItem label="Postcode/Zip" style="width:100%" prop="practisePostcode" :class="mandatoryFieldsStyle" >
                        <Input ref="practisePostcode" type="text" autocomplete="postal-code" name="postal-code" v-model="registerFormFields.practisePostcode"></Input>
                    </FormItem>
                    <FormItem label="State" style="width:100%" prop="practiseState" :class="mandatoryFieldsStyle" >
                        <Input ref="practiseState" type="text" autocomplete="address-level1" name="address-level1" v-model="registerFormFields.practiseState"></Input>
                    </FormItem>
                    <FormItem label="Country" style="width:100%" prop="countryCode" :class="mandatoryFieldsStyle" >
                        <Select v-model="registerFormFields.countryCode" style="width:100%" filterable ref="countryCode">
                            <Option v-for="item in countriesRefData" :value="item.code" :key="item.code">{{ item.name }}</Option>
                        </Select>
                    </FormItem>

                    <!-- <input type="submit" value="Send Request"> -->
                </Form>

                <br />

                <center>
                    <span style="line-height: 12px" class="finePrint">By clicking on 'Register' I acknowledge that I have read and agree to 
                        <a style="text-decoration:underline;" target="_new" href="https://prod-content.bntxinteract.com/legal/bntx-terms-and-conditions.pdf">Terms &amp; Conditions</a>
                        of BnTx Interact.
                    </span>
                </center>

                <Button @click="handleBackToLogin" style="width: 120px; margin-top:16px; margin-left:16px; margin-right:16px">Back to Login</Button>

                <vue-recaptcha  :sitekey="recaptchaSiteKey"
                                :loadRecaptchaScript="true"
                                @verify="onVerify"
                                @expired="onExpired">

                     <Button    type="primary"
                                style="minWidth: 120px; margin-top:16px;margin-left:16px; margin-right:16px"
                                :loading="registrationInProgress">
                        <span v-if="!registrationInProgress">Register</span>
                        <span v-else>Registering…</span>
                    </Button>
                </vue-recaptcha>

              </Row>

            </Card>
            </Col>
        </Row>
        <!-- <Row>
            <br />
        </Row> -->
      <!-- </Content>
      <Footer><i-copyright /></Footer>
    </Layout> -->
  </div>
</template>

<script>

const {CONFIG} = require('@/js/bntx-config')
import AnalyticsMgr from '@/js/AnalyticsManager.js';
const {itBnTx} = require('@/js/itBnTx')

var jstz = require('jstimezonedetect');

import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Register',
  props: {
    
  },

  components: {
    VueRecaptcha,
  },

  created () {

    AnalyticsMgr.sendPageView('/Register')
  },
    mounted() {

        itBnTx.gestimateUserLocationDetails()
            .then(response => {
                this.registerFormFields.practiseCity = response.data.city
                this.registerFormFields.practiseState = response.data.region
                this.registerFormFields.countryCode = response.data.countryCode
                this.registerFormFields.lon = response.data.lon
                this.registerFormFields.lat = response.data.lat

                if (this.registerFormFields.countryCode == "AU") {
                    this.downloadAddressFinder()
                }
            })

        this.registerFormFields.timezone = jstz.determine().name()
    
  },

  data () {
    return {
        recaptchaSiteKey : CONFIG.CAPTCHA_SITE_KEY,

        registrationInProgress : false,

        liveValidating: false,
        liveValidatingFieldsOK: true,

        registerFormFields: {
            practiseName: '',
            practiseContactName: '',
            practiseContactEmail: '',
            password: '',
            practiseContactPhone: '',
            practiseAddress: '',
            practiseCity: 'Los Angeles',
            practisePostcode: '',
            practiseState: 'California',
            countryCode: 'US',
            timezone: 'America/Los_Angeles',

            lon : '',
            lat: '',
          
            token : '',    // recaptcha token

        },
        registerFieldRules: {
            practiseName: [
                { required: true, message: 'Practice name cannot be empty', trigger: 'blur' },
            ],
            practiseContactName: [
                { required: true, message: 'Contact name cannot be empty', trigger: 'blur' },
            ],
            practiseContactEmail: [
                { required: true, message: 'Email cannot be empty', trigger: 'blur' },
                { type: 'email', message: 'Incorrect email format', trigger: 'blur' }
            ],
            password: [
                { required: true, message: 'Please fill in the password.', trigger: 'blur' },
                { type: 'string', min: 6, message: 'Password too short', trigger: 'blur' }
            ],
            practiseContactPhone: [
                { required: true, message: 'Practice phone cannot be empty', trigger: 'blur' },
            ],
            practiseAddress: [
                { required: true, message: 'Practice address cannot be empty', trigger: 'blur' },
            ],
            practiseCity: [
                { required: true, message: 'Practice city cannot be empty', trigger: 'blur' },
            ],
            practisePostcode: [
                { required: true, message: 'Practice postcode/zip cannot be empty', trigger: 'blur' },
            ],
            practiseState: [
                { required: true, message: 'Practice state/region cannot be empty', trigger: 'blur' },
            ],
            countryCode: [
                { required: true, message: 'Country cannot be empty', trigger: 'blur' },
            ],

        },

        responsiveRegistration : {
                xl: 12,
                lg: 12,
                md: 12,
                sm: 24,
                xs: 24,
            },

      }
    },

  computed: {
    
    // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
    mandatoryFieldsStyle() { return (this.liveValidating && this.liveValidatingFieldsOK == false) ? "revealValidationMessages" : "" },

    countriesRefData() { return CONFIG.COUNTRIES; }
    
    /*
        token: 148|VFpWwBwQQjcx2Xmxp43tZRueo48uCBVMZZmdkcCJ
        https://restfulcountries.com/request-access-token

        https://github.com/therebelrobot/countryjs

        https://momentjs.com/timezone/

        https://github.com/pellepim/jstimezonedetect

        https://github.com/runk/node-maxmind
    */
  },

  methods: {
        /*
            https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
        */

        loginOnSuccessfulRegister() {
            
            itBnTx.login({
                                inEmail : this.registerFormFields.practiseContactEmail,
                            inPassword : this.registerFormFields.password
                            })
                    .then(response => {

                       AnalyticsMgr.sendEvent('/LoginViaRegisterResult', 'api', 'Success')

                        this.$Message.success('Login successful. Have a good day!');

                        this.$store.commit("loggedInDoctorDetails", response.userDetails )
                        
                        var loggedInPracticeData = itBnTx.getPractise({inPractiseID : this.$store.state.loggedInDoctorDetails.practiseId })
                        var conditionData = itBnTx.getAllConditions()
                        var genderData = itBnTx.getGenders()
                        var midasData = itBnTx.getAllMidasQuestions()
                        var muscleData = itBnTx.getMuscleImagesData()

                        Promise.all([loggedInPracticeData,
                                        conditionData,
                                        genderData,
                                        midasData,
                                        muscleData,
                                        ]).then(values => {
                            // results come back in same order as promise array passed in.
                            this.$store.commit("loggedInPracticeDetails", values[0].data)
                            this.$store.commit("refDataConditions", values[1].data)

                            // newly registered users for a new clinic, will have a practice admin AND physician role, so since
                            // they are a physician they can see patients, so show them the patient screen.
                            this.$store.commit("currentNavMenuSelectionID", 'pm-patient');

                            // all our reference data is loaded... so we can now safely switch to our UI to load.
                            this.$router.push({name:"bntx-home"});

                        })
                        .catch(error => {
                            error
                            this.$Message.error('Loading some initialization data appears to have failed.');
                        })

                    })
                    .catch(err => {                            
                        AnalyticsMgr.sendEvent('/LoginViaRegisterResult', 'api', 'Failure_' + err.responseCode)
                        
                        this.$Message.error('Login failed. Please check your credentials before trying again.');
                    });
        },

        handleRegister() {

            console.log(this.registerFormFields)

            this.$refs['registerFormFields'].validate((valid) => {
                if (valid) {
                    this.registrationInProgress = true

                    itBnTx.registerPractise({inNewPractiseRecord : this.registerFormFields})
                          .then(response => {
                                response

                                AnalyticsMgr.sendEvent('/Register', 'api', 'Register_Success')

                                this.$Message.success('Registration Successful. Logging in.');
                                this.loginOnSuccessfulRegister()
                          })
                          .catch(err => {
                              AnalyticsMgr.sendEvent('/Register', 'api', 'Register_Failed')

                              this.$Message.error('Registration failed. [ID=' + err.responseCode + ']');
                          })
                          .finally(() => {
                              this.registrationInProgress = false
                          })


                } else {
                    this.liveValidating = true
                    this.liveValidatingFieldsOK = false

                }
          })
        },
        
        handleSubmit() {

            // AnalyticsMgr.sendEvent('/LoginScreen', 'click', 'Login')

            // this.$refs['loginFormFields'].validate((valid) => {
            //     if (valid) {
                    
            //       itBnTx.login({
            //                      inEmail : this.loginFormFields.email,
            //                   inPassword : this.loginFormFields.password
            //                     })
            //             .then(response => {

                            

            //             })
            //             .catch(err => {                            
            //                 AnalyticsMgr.sendEvent('/LoginResult', 'api', 'Failure_' + err.responseCode)
                            
            //                 this.$Message.error('Login failed. Please check your credentials before trying again.');
            //             });

            //     } else {
            //         self.$Message.error('Check credentials before trying again');
            //     }
            // })
        },

        handleBackToLogin() {
            window.open('/#/login', 'bntx-webapp')
        },

        onVerify(response) {
            console.log('Verify: ' + response)
            this.registerFormFields.token = response

            // special code to bypass captcha
            if (this.registerFormFields.practiseContactEmail.includes("@email.ghostinspector.com") == true) {
                this.registerFormFields.token = "GHOSTHERE"
            }

            this.handleRegister()
        },
        onExpired() {
            console.log('Expired')
        },

        validateAndUpdateUI() { if (this.liveValidating) this.$refs['registerFormFields'].validate() },


        // ==================================
        // ======== Address Finder ==========
        // ==================================
        initAddressFinder() {
            // https://addressfinder.com.au/docs/widget_code_generator/

            /* eslint-disable no-undef */
            var widget = new AddressFinder.Widget(
                                document.getElementById('registerAddressFinder'),
                                CONFIG.ADDRESSFINDER_KEY,
                                'AU', {
                                    // "address_params": {
                                    // // "gnaf" : "1",
                                    // "au_paf" : "1",
                                    // // "post_box" : "1"
                                    // }
                                    "address_metadata_params": {
                                        "gps": "1"
                                    },
                                    "address_params": {
                                        "au_paf": "1"
                                    },
                                    "empty_content": "No addresses were found. This could be a new address, or you may need to check the spelling."
                                }
                            );
            
            var self = this
            widget.on('result:select', function(fullAddress, metaData) {

                self.registerFormFields.practiseAddress = fullAddress
                self.registerFormFields.practiseCity = metaData.locality_name
                self.registerFormFields.practiseState = metaData.state_territory
                self.registerFormFields.practisePostcode = metaData.postcode

                // console.log("METADATA: ", metaData, fullAddress)
                // self.addressFinderMetadata.address_line_1 = metaData.address_line_1
                // self.addressFinderMetadata.address_line_2 = metaData.address_line_2
                // self.addressFinderMetadata.locality_name = metaData.locality_name
                // self.addressFinderMetadata.state_territory = metaData.state_territory
                // self.addressFinderMetadata.postcode = metaData.postcode
            });
        },

        downloadAddressFinder() {
            var script = document.createElement('script');
            script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
            script.async = true;
            script.onload = this.initAddressFinder;
            document.body.appendChild(script);
        },
    },

    watch: {
      "registerFormFields.practiseName" :            function() { this.validateAndUpdateUI() },
      "registerFormFields.practiseContactName" :     function() { this.validateAndUpdateUI() },
      "registerFormFields.practiseContactEmail" :    function() { this.validateAndUpdateUI() },
      "registerFormFields.password" :                function() { this.validateAndUpdateUI() },
      "registerFormFields.practiseContactPhone" :    function() { this.validateAndUpdateUI() },
      "registerFormFields.practiseAddress" :         function() { this.validateAndUpdateUI() },
      "registerFormFields.practiseCity" :            function() { this.validateAndUpdateUI() },
      "registerFormFields.practisePostcode" :        function() { this.validateAndUpdateUI() },
      "registerFormFields.practiseState" :           function() { this.validateAndUpdateUI() },
      "registerFormFields.countryCode" :             function() { this.validateAndUpdateUI() },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.ivu-layout-header {
  background: #FFFFFF;
}

.ivu-layout-content {
  background: #FFFFFF;
}


.productBlurb {
  margin:50px;

  font-weight:400;

  font-size: 20px;
  line-height: 28px;


}

.finePrint {
  font-size: 10px;
  line-height: 12px
}

.ivu-card {
    background-color: #eff4f4 !important;
    padding-top: 0px
}




.ivu-form-item {
    margin-bottom: 4px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}

.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}

</style>

<style>
/* body {
  background: #334155 !important;
} */


</style>
