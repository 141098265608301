<template>
    <div id="practice-copayment" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
         
         <!-- ======================== -->
         <!-- View Uploaded Attachment -->
         <!-- ======================== -->
         <Modal v-model="showDocumentAttachmentDialog"
               fullscreen__
               :title="documentAttachmentDialogTitle"
               :footer-hide="true">

            <img :src="documentAttachmentDialogDocumentURL" width="100%" />
        </Modal>


         <!-- ======================== -->
         <!-- Bulk Set BatchRef        -->
         <!-- ======================== -->
         <Modal v-model="showBulkSetBatchRefDialog"
               title="Bulk Set Batch Reference"
              
               @on-ok="handleBulkSetBatchRef"
               okText="Set Batch Ref"
            >
            
            <input ref="bulkEditBatchReferenceElement"
                    v-model="bulkEditBatchReferenceValue"
                    autocomplete="off"
                    spellcheck="false"
                    type="text"
                    placeholder="Please enter a Batch Reference. eg 20221005"
                    class="ivu-input ivu-input-default"
                    v-on:keyup.enter="handleBulkSetBatchRef" />

            <!-- TAILWINDCSS: class="py-2 font-bold text-red-400" -->
            <div class=""
                style="padding-top: 0.5rem/* 8px */;
                        padding-bottom: 0.5rem/* 8px */;
                        font-weight: 700;
                        color: rgba(248, 113, 113, 1);">
                Caution: Bulk setting the batch reference will update all empty and existing values to this new value.
            </div>
            
            <!-- TAILWINDCSS: class="py-2 text-gray-500" -->
            <div class=""
                    style="padding-top: 0.5rem/* 8px */;
                            padding-bottom: 0.5rem/* 8px */;
                            color: rgba(107, 114, 128, 1);">
                The new batch reference will only be applied to entries which have an order created for a session.
            </div>
            
        </Modal>



         <!-- ======================== -->
         <!-- Order Only Capture Input -->
         <!-- ======================== -->
         <Modal v-model="showOrderOnlyCaptureInputPrompt"
               title="No recorded injections."
              
               cancelText="Record Injections First"
               okText="Create Order"

               @on-ok="handleCreateOrderWithNoInjections"
            >
            
            <!-- TAILWINDCSS: class="pb-3 text-gray-500" -->
            <div class=""
                    style="padding-bottom: 0.75rem/* 12px */;
                            color: rgba(107, 114, 128, 1);">
                This session appears to have no recorded injections.<br /><br />
                If this is intentional, and you wish to manually specify the medication and quantity, please fill out and Create Order.<br />
                <br />
                Otherwise please Record Injections First, before trying again.
            </div>

            <!-- TAILWINDCSS: class="font-bold"  -->
            <span class=""
                     style="font-weight: 700;">Order: </span>

            <Select v-model="orderOnlyMedID" style="width:170px; margin-right:8px" filterable>
                <Option v-for="med in injectMedicinesRefData" :value="med.id" :key="med.id">{{ med.name }} - {{ med.vialUnits }} units</Option>
            </Select>
            <InputNumber v-model="orderOnlyQuantity" :min="1" :step="1" :precision="0" style="width:70px; margin-right:4px" />
            vial(s)
              
        </Modal>



         <!-- ======================== -->
         <!-- ORDER Details Drawer UI  -->
         <!-- ======================== -->
         <Drawer
            v-model="showDetails"
            :width="dynamicPatientDetailsDrawerWidth"
            :mask-closable="false"
            :styles="detailsDrawerStyles">

            <p slot="header"><span style="margin-left:8px; font-weight: 700; font-size: 1.125rem;">Order Details</span></p>

            <Row :gutter="20">
                <Col v-bind="responsiveGrid2Sections" style="margin-bottom: 8px;">
                    <Card :padding="8" style="width: 100%; min-height: 263px;">
                        <!-- TAILWINDCSS: class="font-bold" -->
                        <span class="" style="font-weight: 700;">Patient:</span>
                        <div>{{editEntryDetails.patientName}}</div>
                        
                        <div style=" margin-top:12px;"></div>

                        <span class="" style="font-weight: 700;">Date of birth:</span>
                        <div>{{editEntryDetails.patientDob  | userFriendlyDateFromDateString }}</div>
                        
                        <div style=" margin-top:12px;"></div>

                        <!-- TAILWINDCSS: class="font-bold" -->
                        <span class="" style="font-weight: 700;">Condition(s):</span>
                        <div>{{ editEntryDetails.conditionIds | conditionsAsString }}</div>

                        <div style=" margin-top:12px;"></div>

                        <!-- TAILWINDCSS: class="font-bold" -->
                        <span class="" style="font-weight: 700;" >Treating Physician:</span>
                        <div>{{ editEntryDetails.treatingPhysicianName }}</div>

                        <div style=" margin-top:12px;"></div>

                        <!-- TAILWINDCSS: class="font-bold" -->
                        <span class="" style="font-weight: 700;" >Co-Payment Method:</span>
                        <div>
                            <Checkbox v-model="editEntryDetails.manualOrder" :disabled="orderIsReadOnly">Is Manual Order</Checkbox>
                            
                            <Tooltip placement="bottom">
                                <Icon type="md-help-circle" color="#4287f5" />
                                <div slot="content" style="white-space: normal">
                                    <p>Select this option only when supported co-payment options from BnTx Interact are not supported by the patient.</p>
                                    <br />
                                    <p>This option may be set automatically by the system if a payment issue is detected with the current patients' payment method details.</p>
                                </div>
                            </Tooltip>
                        </div>

                        
                    </Card>
                </Col>
                <Col v-bind="responsiveGrid2Sections" style="margin-bottom: 8px">
                    <Card :padding="8" style="width: 100%;">

                        <span class="" style="font-weight: 700;">Session Created Date/Time:</span>
                        <div>{{ editEntryDetails.sessionDate | userFriendlyDateTime }}</div>

                        <div style=" margin-top:12px;"></div>

                        <span class="" style="font-weight: 700;">Session Usage:</span>

                        <div style=" margin-top:6px;"></div>

                        <span v-if="editEntryDetails.injectedUnits != null">{{ editEntryDetails.medicationName }} - {{ editEntryDetails.injectedUnits }} units </span>
                        <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;no injections </span>

                        <Tooltip placement="bottom">
                            <Icon type="md-help-circle" color="#4287f5" />
                            <div slot="content" style="white-space: normal">
                                <p>This field indicates the medication and amount used during a session.</p>
                                <br />
                                <p>The amount information is not sent as part of the order, only the number of vials is send in the order.</p>
                            </div>
                        </Tooltip>

                        <div style=" margin-top:12px;"></div>

                        <span class="" style="font-weight: 700;">Vial Order Quantity:</span>

                        <div style=" margin-top:6px;"></div>

                        <Select v-model="editEntryDetails.order.injectMedicineId" style="width:170px; margin-right:8px" filterable :disabled="orderIsReadOnly">
                            <Option v-for="med in injectMedicinesRefData" :value="med.id" :key="med.id">{{ med.name }} - {{ med.vialUnits }} units</Option>
                        </Select>
                        <InputNumber v-model="editEntryDetails.order.orderQuantity" :min="0" :step="1" :precision="0" style="width:70px; margin-right:4px" :disabled="orderIsReadOnly" />
                        vial(s)


                        <div style=" margin-top:12px;"></div>

                        <span class="" style="font-weight: 700;">Batch Reference (optional):</span>
                        <div style="width:100%">
                            <Input v-model="editEntryDetails.order.batchRef" style="width:200px; margin-right:8px; margin-bottom:8px; margin-top:8px"></Input>
                        </div>

                    </Card>
                </Col>
            </Row>
            <Row :gutter="20">
                <Col span="24" style="margin-bottom: 8px">
                
                    <Card :padding="8" style="width: 100%;">
                        <span class="" style="font-weight: 700; font-size: 1.125rem;">Script - Upload a copy</span>
                        
                        <div style=" margin-top:6px;"></div>

                        <Row align="middle" v-show="editEntryDetails.orderStatus == 'DR'">
                            <Col span="12">
                                <div style="margin:8px; height:240px">
                                    <span class="" style="font-weight: 700;">OPTION 1 - Phone</span><br />
                                    <span class="" style="font-weight: 400; font-size: 0.75rem;">Scan the QR Code to upload photo</span>
                                    <div style=" margin-top:12px;"></div>

                                    <Card :padding="8" style="width: 100%;" class="tintedCardBackground">
                                        <center>
                                            <vue-qrcode :value="companionURLForMediaUpload" :width="150" title="Scan this QR code on your phone, to take photos from your phone to attach to this order." />
                                        </center>
                                    </Card>
                                </div>
                            </Col>
                            <Col span="12">
                                <div style="margin:8px; height:240px">
                                    <span class="" style="font-weight: 700;">OPTION 2 - Computer</span><br />
                                    <span class="" style="font-weight: 400; font-size: 0.75rem;">Drag and drop or click and search</span>
                                    <div style=" margin-top:12px;"></div>

                                    <Upload
                                        ref="scriptUploader"
                                        multiple
                                        type="drag"
                                        name='uploadedFile'
                                        :show-upload-list="true"
                                        :action="uploadActionURL"
                                        :data="uploadOrderAttachmentData"
                                        :before-upload="handleBeforeUploadAttachment"
                                        :format="['jpg','jpeg','png']"
                                        :max-size="104800000"
                                        :on-format-error="handleFormatError"
                                        :on-exceeded-size="handleMaxSize"
                                        :on-success="handleSuccess"
                                        :on-error="handleError"
                                        >
                                        <div style="padding: 10px 0; height: 172px; display: flex; 	justify-content: center; align-items: center;" class="tintedCardBackground">
                                            <div>
                                                <Icon type="ios-cloud-upload" size="64" style="color: #3399ff"></Icon>
                                                <p>Click or drag files here to upload</p>
                                            </div>
                                        </div>
                                    </Upload>
                                </div>
                            </Col>
                        </Row>

                        <span class="" style="font-weight: 700;font-size: 1.125rem;">Uploaded Files:</span>
                        
                        <div style=" margin-top:6px;"></div>

                        <List border size="small" style="width:100%">
                            <!-- <div slot="header"><b>Attachments</b></div> -->
                            <Scroll :height="170">
                                <ListItem v-for="entry in currentOrderAttachmentsData" :key="entry.uuid">
                                    <Row style="width:100%">
                                        <Col span="18">{{ entry.name }}</Col>
                                        <Col span="2">  <font-awesome-icon style="cursor: pointer" @click="handleAttachmentView(entry)" :icon="['fal', 'eye']" size="lg" pull="left" fixed-width />                  </Col>
                                        <Col span="2">  <font-awesome-icon style="cursor: pointer" @click="handleAttachmentDownload(entry)" :icon="['fal', 'cloud-download']" size="lg" pull="left" fixed-width />   </Col>
                                        <Col span="2" v-show="editEntryDetails.orderStatus == 'DR'">
                                            <Poptip
                                                confirm transfer
                                                title="Are you sure you want to delete this attachment? This action is irreversible."
                                                @on-ok="handleCurrentSessionAttachmentDelete(entry)">
                                                <font-awesome-icon style="cursor: pointer" :icon="['fal', 'trash-alt']" size="lg" pull="left" fixed-width />
                                            </Poptip>
                                        </Col>
                                    </Row>
                                </ListItem>
                            </Scroll>
                        </List>

                    </Card>

                    <Col span="24" style="margin-top: 8px; text-align: left; color: #888888">NOTE: Orders can be cancelled.
                        <Tooltip placement="bottom">
                            <Icon type="md-help-circle" color="#4287f5" />
                            <div slot="content" style="white-space: normal">
                                <p>Orders are queued (Preparing Order status) and can be cancelled within 2 hours of sending.</p>
                                <br />
                                <p>Once an order status changes to Ordered, it cannot be cancelled.</p>
                                <br />
                                <p>You can initiate a cancellation of an order via the Tools menu.</p>
                            </div>
                        </Tooltip>
                    </Col>
                </Col>
                    
            </Row>

        

            <div class="drawer-footer">
                <Row justify="end">
                    <Button style="margin-right: 8px" @click="handleSaveButDontSent">Save - Don't send now</Button>
                    <Button type="success" :disabled="editEntryDetails.orderStatus != 'DR'" :loading="savingDetailsToBackend" @click="handleSaveEntryDetailsNSent">
                        <span v-if="!savingDetailsToBackend">Send Order Now</span>
                        <span v-else>Preparing...</span>
                    </Button>
                </Row>
            </div>
        </Drawer>
        
         <!-- ======================== -->
         <!-- Main UI - Header         -->
         <!-- ======================== -->

        <Divider orientation="left">Sessions and Order/Co-Payment Management <span style="font-weight:200">({{ entriesTotalCount }} sessions - in {{ numberPeriodDaysString }})</span>
            <Tooltip placement="bottom">
                <Icon type="md-help-circle" color="#4287f5" />
                <div slot="content" style="white-space: normal">
                    <p>This section allows you to manage all the orders at your practice.</p>
                    <br />
                    <p>Sessions can either be associated with an Order or Pro Bono (Order Not Required)</p>
                    <br />
                    <p>In order for an order to be successfully queued for sending, the patients contact details, consent, payment tier, and payment details are required to be collected and configured.</p>
                    <br />
                    <p>Upon receiving the stock for the order, the relevant orders can be reconciled and marked as received.</p>
                    
                </div>
            </Tooltip>

            <DatePicker v-model="ordersDateRange" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="Specify custom date range" style="margin-left: 8px; width: 200px" />
        </Divider>
        
         <!-- ======================== -->
         <!-- Main UI - Buttons        -->
         <!-- ======================== -->
        <Row :gutter="0" >
            <div v-show="reconcileMode == false">
                <ButtonGroup>
                    <Button @click="reconcileMode = !reconcileMode" style="margin:0 0 8px 8px;" type="primary">Orders Not Sent</Button>
                    <Button @click="reconcileMode = !reconcileMode" style="margin:0 8px 8px 0;">Orders Sent</Button>
                </ButtonGroup>

                <Button @click="handleAddEntry" style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus != 'UN'">Complete Order</Button>
                <Button @click="handleEditEntry" style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus == 'UN' || editEntryDetails.orderStatus == 'ON'">Edit Order</Button>
                <Button @click="handleToolsMenu('send-order')" style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus != 'DR'" type="success" >Send Order</Button>
                <Button @click="handleToolsMenu('cancel-order')" style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus != 'PP' && editEntryDetails.orderStatus != 'ON'" type="error" >{{ editEntryDetails.orderStatus == 'ON' ? 'Reset Order-Not-Required' : 'Stop Order'}}</Button>
                <Poptip
                    confirm transfer
                    width="300"
                    :title="confirmRemoveEntryMessage"
                    ok-text="Yes"
                    cancel-text="No"
                    @on-ok="handleRemoveEntry">
                    
                    <Button type="error" style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus != 'DR'">Reset Order</Button>
                </Poptip>
                <!-- <Button @click="handleToolsMenu('order-not-required')" style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus != 'UN'" >Order Not Required</Button> -->
                
                <Dropdown @on-click="handleToolsMenu">
                    <Button style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus != 'UN'">
                        Order Not Required
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    
                    <DropdownMenu slot="list">
                        <DropdownItem name="onr-private-patient" >Private patient</DropdownItem>
                        <DropdownItem name="onr-pro-bono" >Pro bono</DropdownItem>
                        <DropdownItem name="onr-training" >Training</DropdownItem>
                        <DropdownItem name="onr-other" >Other</DropdownItem>
                    </DropdownMenu>
                </Dropdown>


            </div>
            <div v-show="reconcileMode == true">
                <ButtonGroup>
                    <Button @click="reconcileMode = !reconcileMode" style="margin:0 0 8px 8px;">Orders Not Sent</Button>
                    <Button @click="reconcileMode = !reconcileMode" style="margin:0 8px 8px 0;" type="primary">Orders Sent</Button>
                </ButtonGroup>

                <Button @click="handleToolsMenu('product-reconciled')" style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus != 'so'" >Received</Button>
                <Button @click="handleToolsMenu('product-un-reconcile')" style="margin:0 8px 8px 0;" :disabled="editEntryDetails.orderStatus != 'PR'" >Reset</Button>
                <Button @click="handleToolsMenu('cancel-order')" style="margin:0 8px 8px 0;" v-show="editEntryDetails.orderStatus == 'ON'" :disabled="editEntryDetails.orderStatus != 'PP' && editEntryDetails.orderStatus != 'ON'" type="error" >{{ editEntryDetails.orderStatus == 'ON' ? 'Reset Order-Not-Required' : 'Stop Order'}}</Button>
                <Button @click="handleToolsMenu('show-order-summary')" style="margin:0 8px 8px 0;" >Vial Summary</Button>
              
            </div>

            <Dropdown @on-click="handleToolsMenu">
                <Button type="primary">
                    Tools
                    <Icon type="ios-arrow-down"></Icon>
                </Button>
                
                <DropdownMenu slot="list">
                    <!-- <DropdownItem name="send-order" :disabled="editEntryDetails.orderStatus != 'DR'">Send Order</DropdownItem>
                    <DropdownItem name="cancel-order" :disabled="editEntryDetails.orderStatus != 'PP' && editEntryDetails.orderStatus != 'ON'">{{ editEntryDetails.orderStatus == 'ON' ? 'Reset Order Not Required' : 'Cancel Order'}}</DropdownItem>
                    <DropdownItem name="order-not-required" :disabled="editEntryDetails.orderStatus != 'UN'">Order Not Required / Pro bono </DropdownItem>
                    <DropdownItem name="product-reconciled" :disabled="editEntryDetails.orderStatus != 'OR'">Order Received (Reconciled)</DropdownItem>
                    <DropdownItem name="product-un-reconcile" :disabled="editEntryDetails.orderStatus != 'PR'">Order Un-reconcile</DropdownItem> -->

                    <!-- <DropdownItem name="bulk-set-order-reference" divided>Bulk Set Batch Ref (Displayed Orders)</DropdownItem> -->
                    <DropdownItem name="bulk-set-order-reference" >Bulk Set Batch Ref (Displayed Orders)</DropdownItem>
                    <DropdownItem name="bulk-send-orders" >Bulk Send Orders (Displayed Orders)</DropdownItem>
                    <DropdownItem name="bulk-reconcile-orders" >Bulk Reconcile Orders (Displayed Orders)</DropdownItem>
                    <!-- <DropdownItem name="show-order-summary" >Show Stock Summary for Displayed Orders</DropdownItem> -->
                </DropdownMenu>
            </Dropdown>


            <!-- <Input v-model="filterFirstnameBy" placeholder="Search by firstname." :clearable="true" style="width: 200px; margin-right:8px" />
            <Input v-model="filterLastnameBy" placeholder="Search by lastname." :clearable="true" style="width: 200px; margin-right:8px" /> -->
            <!-- <Input v-model="filterByFirstname" placeholder="First name" :clearable="true" @on-change="throttledSearch" style="width: 300px; margin-left:8px; margin-right: 8px" />
            <Input v-model="filterByLastname" placeholder="Last name" :clearable="true"  @on-change="throttledSearch" style="width: 300px; margin-right: 8px" /> -->

            <!-- <Checkbox v-model="showAllStatusOrders" style="margin-top:6px; margin-left:8px; margin-right:8px">Include All Status Orders</Checkbox> -->

        </Row>

        <!-- =======================
             MEDICATION STOCK STATUS - Pill Status UI
             ======================= -->
        <Row style="margin-top:8px">

            <!-- TAILWINDCSS: class="space-x-2 space-y-1" -->
            <!-- margin-right: calc(0.5rem * 0);
                margin-left: calc(0.5rem * calc(1 - 0));
                margin-top: calc(0.25rem * calc(1 - 0));
                margin-bottom: calc(0.25rem * 0); -->
            <div class=""
                    style=" ">

                <!-- TAILWINDCSS: class="inline-block" -->
                <div class="mockTailwindSpaceX2Y1" style="display: inline-block;">
                    <Select v-model="selectedTreatingPhysician" filterable>
                        <Option v-for="physician in treatingPhysicians" :value="physician.id" :key="physician.id">{{ physician.name }}</Option>
                    </Select>
                </div>

                <!-- TAILWINDCSS: class="inline-block" -->
                <div class="mockTailwindSpaceX2Y1" style="display: inline-block;">
                    <Select v-model="selectedOrderStatus" filterable>
                        <Option v-for="status in displayedItemOrderStatuses" :value="status.id" :key="status.id">{{ status.name }}</Option>
                    </Select>
                </div>

                <!-- TAILWINDCSS: class="inline-block" -->
                <div class="mockTailwindSpaceX2Y1" style="display: inline-block;">
                    <Select v-model="selectedBatchRef" filterable>
                        <Option v-for="batchRef in batchRefs" :value="batchRef.id" :key="batchRef.id">{{ batchRef.name }}</Option>
                    </Select>
                </div>

                 <!-- TAILWINDCSS: class="inline-block" -->
                 <div class="mockTailwindSpaceX2Y1" style="display: inline-block;">
                    <Select v-model="selectedMedicationName" filterable>
                        <Option v-for="medicationName in medicationNames" :value="medicationName.id" :key="medicationName.id">{{ medicationName.name }}</Option>
                    </Select>
                </div>
                
                
                <!-- TAILWINDCSS: class="inline-block border rounded-xl border-gray-500 bg-gray-400 text-white" -->
                <div    v-for="med in medicationStockData"
                        :key="med.name"
                        class="mockTailwindSpaceX2Y1"
                        style="display: inline-block; 
                                border-width: 1px;
                                border: 1px solid rgba(107, 114, 128, 1);
                                border-radius: 0.75rem/* 12px */;
                                border-color: rgba(107, 114, 128, 1);
                                background-color: rgba(156, 163, 175, 1);
                                color: rgba(255, 255, 255, 1);"
                        >

                    <!-- TAILWINDCSS: class="mx-2 inline-block cursor-pointer" -->
                    <div class=""
                         style="margin-left: 0.5rem/* 8px */;
                                margin-right: 0.5rem/* 8px */;
                                display: inline-block;
                                cursor: pointer;"
                         title="Click to visit the manufacturers website for more information on the product."
                         @click="medicationRegisterToOrder(med)"
                         >
                        {{ med.name }}
                    </div>

                    <!-- TAILWINDCSS: :class="medicationStockLevelStatusClass(med, 'pl-2 border-2 rounded-xl inline-block cursor-pointer')" -->
                    <div class=""
                         :style="medicationStockLevelStatusStyle(med, 'padding-left: 0.5rem/* 8px */; border-width: 2px; border: 2px solid rgba(213, 221, 237, 1); border-radius: 0.75rem/* 12px */; display: inline-block; cursor: pointer;')"
                         title="Click to Order more stock."
                         @click="medicationOrderStock(med)"
                         >
                        {{ med.stock }} {{ med.stock == 1 ? "vial" : "vials" }}

                        <Tooltip placement="top">
                            <!-- TAILWINDCSS: class="ml-1 px-2 border rounded-xl bg-gray-500 inline-block cursor-pointer" -->
                            <div class=""
                                 style="margin-left: 0.25rem/* 4px */;
                                        padding-left: 0.5rem/* 8px */;
                                        padding-right: 0.5rem/* 8px */;
                                        border-width: 1px;
                                        border: 1px solid ;
                                        border-radius: 0.75rem/* 12px */;
                                        background-color: rgba(107, 114, 128, 1);
                                        display: inline-block;
                                        cursor: pointer;
                                            "
                                 title="">i</div>

                            <div slot="content" style="white-space: normal" v-html="medicationVialSummary(med)">
                            </div>
                        </Tooltip>
                    </div>
                </div>
                
                <Tooltip placement="bottom">
                    <Icon type="md-help-circle" color="#4287f5" />
                    <div slot="content" style="white-space: normal">
                        <p>Stock status indicators for specific Physicians or across the Practice.</p>
                        <br />
                        <p>Click on the medication name to visit the Manufacturers Website</p>
                        <br />
                        <p>Click on the vial count to visit the Manufacturers Ordering Website for the medication.</p>
                        <br />
                        <p>Point to the (i) icon to show a breakdown of vial count for specific unit sized vials.</p>
                    </div>
                </Tooltip>

            </div>
        </Row>

         <!-- ======================== -->
         <!-- Main UI - Table          -->
         <!-- ======================== -->
        <Row style="margin-top:8px">
            <Table name="copayment-table" style="width:100%" :height="tableHeightToFit" size="default" draggable :columns="entriesTableColumnsConfig" :data="entriesToDisplay" border highlight-row @on-current-change="handleEntriesSelectionChanged" @on-row-dblclick="handleEditEntryDoubleClick">
                <template slot-scope="{ row }" slot="patientName">
                    <span :class="row.authedPayment != true ? 'preAuthFailed' : ''"
                          :title="'DOB: ' + row.patientDob"
                          :id="'copayment_session_' + row.sessionId">
                        {{ row.patientName }}
                    </span>
                </template>
                <!-- <template slot-scope="{ row }" slot="dob"> {{ row.patientDob | userFriendlyDateFromDateString }} </template> -->
                <template slot-scope="{ row }" slot="condition"> {{ row.conditionIds | conditionsAsString }} </template>
                <template slot-scope="{ row }" slot="orderStatus">
                    <span :title="row.manualOrder ? '(HPS)' : '(IMS)'"
                          :style="row.manualOrder ? 'font-weight: 500;' : 'font-weight: normal;'">
                        {{ row.orderStatus | userFriendlyOrderStatus }}
                        {{ (row.orderStatus == 'ON' ? (" (" + ((row.onrSubType != null) ? row.onrSubType : "Other") + ")") : "") }}
                    </span>
                </template>
                <template slot-scope="{ row }" slot="sessionDate"> {{ row.sessionDate | userFriendlyDate }} </template>
                <template slot-scope="{ row }" slot="attachments"> {{ row.hasAttachments != undefined && row.hasAttachments == true ? '✅' : '' }}</template>

                <!-- 1 vial (100 units), 1 vial (200 units) -->
                <template slot-scope="{ row }" slot="quantity"><span :title="row.injectedUnits != null ? 'Used: ' + row.injectedUnits + ' units' : 'no injections'">{{ row.order != null && row.order.userEditedQuantity == true ? '👤' : '&nbsp;&nbsp;&nbsp;&nbsp;'}} {{ row.order != null ? row.order.quantity : '-' }} </span></template>

                <template slot-scope="{ row }" slot="batchref"> {{ row.order != null ? row.order.batchRef : '' }} </template>

                <!-- <template slot-scope="{ row }" slot="logo">
                    <img v-if="row.practiseLogo != ''" :src="row.practiseLogo" height="50px" />
                </template> -->
            </Table>
        </Row>                                        

       
        <Row style="margin-top:8px">
            <Col span="24" class="ivu-text-right">
                <Page :total="entriesTotalCount" :page-size="entriesListPageSize" :page-size-opts="[20,100,200,500,1000]" show-sizer @on-change="handleLoadEntriesListToNthPage" @on-page-size-change="handlePageLimitChanged" />
            </Col>
        </Row>
       
         <!-- ======================== -->
         <!-- Vial Summary Drawer      -->
         <!-- ======================== -->
        <Drawer title="Ordered Vial Summary (of displayed items)" placement="bottom" :closable="false" v-model="showVialSummaryDrawer">
            <p v-for="(value, key) in vialSummaryData" :key="key">
                {{ key }}: {{ value }}x
            </p>
            
        </Drawer>
    </div>
</template>

<script>
const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
var _ = require('lodash');
import AnalyticsMgr from '@/js/AnalyticsManager.js';

const {itBnTx} = require('@/js/itBnTx')

var qs = require('qs');
import VueQrcode from 'vue-qrcode'
// import { BnTxError } from '../../js/itBnTx';

// this function needs to MIRROR filters.js - userFriendlyOrderStatus
function getUserFriendlyOrderStatus(inStatusCode) {

    switch (inStatusCode) {
    case 'UN': return "Incomplete";           // unassigned, indicated as 'incomplete'... ie user needs to complete
    case 'DR': return "Draft";                // DRaft, or what in the UI is Preparing Order... ie UI taps button to create an order to fill for a session.
    case 'PP': return "Preparing Order";      // Preparing Order, means BE has queued the order by request of FE user tapping 'send'
    case 'OR': return "Charged";              // ORdered, means BE has taken request from queue, done all it needs to do and sent off order. Well now its charged payment, but awaiting sending email to HPS
    case 'PR': return "Received";             // Product Reconciled, practice has reconciled the received product with order.
    case 'ON': return "Order Not Required";   // Order Not Required, eg Probono.
    case 'AB': return "Failed to Send";       // ABorded = when there is a error sending order or email/CC fails...

    /* PSEUDO STATUS - used for setting status */
    // case 'PU' - Product Unreconciled - this is used to set an order from Reconciled (say by accident, back to Ordered which is the (only) state before reconciled.)
    case 'so': return "Sent";                 // (Sent Ok) Order Sent is the pseudo new actual status, where the backend 'sent' (actually now co-payment charged), and emailSent == true
    case 'sf': return "Send (failed)";        // (Send Failed) Order Sent is the pseudo new actual status, where the backend 'sent' (actually now co-payment charged), and emailSent == true

    }

    return inStatusCode
}

export default {
    name: 'practice-copayment',
    components: {
        VueQrcode,

    },

    created () {

        itBnTx.getInjectMedicines()
              .then(response => {
                    this.injectMedicinesRefData = response.data
                })
                
        // create a throttler function which will automatically manage throttling search requests to a limit, currently 1 per however many milliseconds
        this.throttledSearch = _.debounce(() => { this.handleLoadEntriesListToNthPage(1); console.log("handleThrottledSearch 600ms") }, 600, { 'leading': false })

    },

    destroyed() {

    },

    mounted() {

 

// CMD: OSAT (Order Script ATtachment)
    },


    data () {
            return {
                // following are cached, and loaded as soon as we are visible, so save on unnecessary API calls. Sometimes too dynamic is not good.
                toLoadOnShowUI_patientID: null,
                toLoadOnShowUI_sessionID: null,

                showVialSummaryDrawer : false,
                vialSummaryData : {},

                medicationStockDataRaw : [],
                // medicationStockDataRaw : [
                //     {
                //         id : "1",
                //         injectMedicine : {
                //                 name : "BOTOX®",
                //                 vialUnits : 100,
                //                 marketingUrl : "https://www.allergandirect.com.au/en_AU/login",
                //                 orderUrl : "https://www.allergandirect.com.au/en_AU/login",
                //             },
                //         vialCount : 20
                //     },
                //     {
                //         id : "11",
                //         injectMedicine : {
                //                 name : "BOTOX®",
                //                 vialUnits : 200,
                //                 marketingUrl : "https://www.allergandirect.com.au/en_AU/login",
                //                 orderUrl : "https://www.allergandirect.com.au/en_AU/login",
                //             },
                //         vialCount : 8
                //     },
                //     {
                //         id : "2",
                //         injectMedicine : {
                //                 name : "Dysport®",
                //                 vialUnits : 300,
                //                 marketingUrl : "https://www.ipsen.com/aus/distribution/",
                //                 orderUrl : "https://direct.ch2.net.au/index.php?mode=intouch",
                //             },
                //         vialCount : 2
                //     },
                //     {
                //         id : "22",
                //         injectMedicine : {
                //                 name : "Dysport®",
                //                 vialUnits : 500,
                //                 marketingUrl : "https://www.ipsen.com/aus/distribution/",
                //                 orderUrl : "https://direct.ch2.net.au/index.php?mode=intouch",
                //             },
                //         vialCount : 3
                //     },
                //     {
                //         id : "3",
                //         injectMedicine : {
                //                 name : "Xeomin®",
                //                 vialUnits : 100,
                //                 marketingUrl : "https://encapsulatepharma.com/registration/",
                //                 orderUrl : "https://orders.encapsulatepharmalabs.com/auth/login/",
                //             },
                //         vialCount : 14
                //     },
                // ],
                
                // medicationStockData : [
                //     {
                //         name : "BOTOX®",
                //         stock : 28,
                //         vialSummary : "100 units : [20x], 200 units : [8x]",
                //         marketingUrl : "https://www.allergandirect.com.au/en_AU/login",
                //         orderUrl : "https://www.allergandirect.com.au/en_AU/login",
                //     },
                //     {
                //         name : "Dysport®",
                //         stock : 5,
                //         vialSummary : "300 units : [2x], 500 units : [3x]",
                //         marketingUrl : "https://www.ipsen.com/aus/distribution/",
                //         orderUrl : "https://direct.ch2.net.au/index.php?mode=intouch",
                //     },
                //     {
                //         name : "Xeomin®",
                //         stock : 14,
                //         vialSummary : "100 units : [14x]",
                //         marketingUrl : "https://encapsulatepharma.com/registration/",
                //         orderUrl : "https://orders.encapsulatepharmalabs.com/auth/login/",
                //     },
                // ],

                selectedTreatingPhysician : "--ALL--",
                selectedOrderStatus : "--ALL--",
                selectedBatchRef : "--ALL--",
                selectedMedicationName : "--ALL--",

                physicianTitlesRefData: CONFIG.PATIENT_TITLES,

                statusRefData : [
                    { statusCode : 'A', statusName: 'Active'},
                    { statusCode : 'T', statusName: 'Training Entry'},
                    { statusCode : 'M', statusName: 'Moved Away'},
                    { statusCode : 'D', statusName: 'Deceased'},
                    { statusCode : 'I', statusName: 'Inactive'},
                ],

                injectMedicinesRefData : [{"id":1,"name":"BOTOX®","code":null,"vialUnits":100,"marketingUrl":"https://www.allergandirect.com.au/en_AU/login","orderUrl":"https://www.allergandirect.com.au/en_AU/login","created":1665965485680,"createdBy":0,"modified":null,"modifiedBy":null,"desc":null,"isPbs":true,"order":1},{"id":2,"name":"BOTOX®","code":null,"vialUnits":200,"marketingUrl":"https://www.allergandirect.com.au/en_AU/login","orderUrl":"https://www.allergandirect.com.au/en_AU/login","created":1665965485680,"createdBy":0,"modified":null,"modifiedBy":null,"desc":null,"isPbs":false,"order":2},{"id":3,"name":"DYSPORT®","code":null,"vialUnits":300,"marketingUrl":"https://www.ipsen.com/aus/distribution/","orderUrl":"https://direct.ch2.net.au/index.php?mode=intouch","created":1665965485680,"createdBy":0,"modified":null,"modifiedBy":null,"desc":null,"isPbs":true,"order":3},{"id":4,"name":"DYSPORT®","code":null,"vialUnits":500,"marketingUrl":"https://www.ipsen.com/aus/distribution/","orderUrl":"https://direct.ch2.net.au/index.php?mode=intouch","created":1665965485680,"createdBy":0,"modified":null,"modifiedBy":null,"desc":null,"isPbs":false,"order":4},{"id":5,"name":"XEOMIN®","code":null,"vialUnits":100,"marketingUrl":"https://encapsulatepharma.com/registration/","orderUrl":"https://orders.encapsulatepharmalabs.com/auth/login/","created":1665965485680,"createdBy":0,"modified":null,"modifiedBy":null,"desc":null,"isPbs":true,"order":5}],


                entriesTableColumnsConfig: [
//                    {                      title: 'Title',                key: 'title', resizable: true, sortable: false, width: 130 },
                    { slot: 'patientName', title: 'Patient Name',         key: 'patientName', resizable: true, sortable: true, width: 300 },
//                    { slot: 'dob',         title: 'DOB',                  key: 'patientDob', resizable: true, width: 110 },
                    { slot: 'orderStatus', title: 'Order Status',               key: 'orderStatus', resizable: true, sortable: true, width: 120 },
                    { slot: 'attachments', title: 'Has Attachments',      key: 'hasAttachments', resizable: true, sortable: true, width: 80 },
//                    {                      title: 'First Name',           key: 'firstName', resizable: true, sortable: false, width: 220 },
//                    {                      title: 'Last Name',            key: 'lastName', resizable: true, width: 220 },
                    { slot: 'sessionDate', title: 'Session Date',         key: 'sessionDate', resizable: true, sortable: true, minWidth: 100 },
                    {                      title: 'Medication',           key: 'medicationName', resizable: true, sortable: true, width: 130 },
                    { slot: 'quantity',    title: 'Quantity',             key: 'order.quantity', resizable: true, sortable: true, width: 250 },
//                    {                      title: 'Units',                key: 'injectedUnits', resizable: true, sortable: false, width: 70 },
                    { slot: 'condition',   title: 'Conditions',           key: 'conditionIds', resizable: true, minWidth: 100 },
                    {                      title: 'Treating Physician',   key: 'treatingPhysicianName', resizable: true, sortable: true, width: 250 },
                    { slot: 'batchref',    title: 'Batch Ref',            key: 'order.batchRef', resizable: true, sortable: true, width: 100 },
                ],

                throttledSearch : () => {},
                filterByFirstname: "",
                filterByLastname: "",

                entriesListIsLoading : false,
                entriesListPageSize : 500,
                entriesTableData: [],

                entriesTotalCount : 0,
                
                editEntryDetails: {
                                        sessionId : null,
                                        order : { quantity : '', uuid : '' }
                                        // practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                                        // firstName: "",
                                        // lastName: "",
                                        // physicianAddress: "",
                                        // physicianPhone: "",
                                        // status: "A",
                                    },


                showOrderOnlyCaptureInputPrompt : false,
                orderOnlyMedID : 1,
                orderOnlyQuantity : 1,

                // editEntryQuantityDetails : '',
                // editEntryReferenceDetails : '',
                
                allowOnlyPastDatesOptions : {
                    disabledDate (date) {
                        return date && date.valueOf() > Date.now();
                    }
                },
                savingDetailsToBackend : false,



                liveValidating: true,
                liveValidatingFieldsOK: true,
                editEntryDetailsFieldRules : {
                    firstName: [
                        { required: true, message: 'Firstname cannot be empty', trigger: 'blur' },
                    ],
                    lastName: [
                        { required: true, message: 'Lastname cannot be empty', trigger: 'blur' },
                    ],
                    dateOfBirth: [
                        { required: true, type: 'date', message: 'Date of Birth is required', trigger: 'blur' },
                    ],
                },

                ordersDateRange : undefined,

                filterFirstnameBy : '',
                filterLastnameBy : '',
                // showAllStatusOrders : false,

                reconcileMode : false,


                isCreatingNewEntry : false,
                selectedEntryDetailsBeforeCreatingNewEntry : {},      // this is used to restore the previous selection when user cancels create new entry.

                showDetails: false,
                detailsDrawerStyles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static',
                    'background-color': 'rgb(250, 250, 250)'
                },


                showBulkSetBatchRefDialog : false,
                bulkEditBatchReferenceValue : '',


                showImportHelp : false,

                uploadPatientImportCSVData : {
                    practiseId : 0,
                    authToken : '',
                    importType : "Patient", // "Patient", "ReferringPhysician"
                },

                showDocumentAttachmentDialog : false,
                documentAttachmentDialogTitle : "",
                documentAttachmentDialogDocumentURL : "",
                
                currentOrderAttachmentsData : [],

                companionURLForMediaUpload: '',

                uploadOrderAttachmentData : {
                    practiseId : 0,
                    authToken : '',
                    uploadType : "OrderAttachment", //"Signature", "LogoLight", "LogoDark", "Attachment", "OrderAttachment"
                    orderUuid : ''
                },

                responsiveGrid2Sections : {
                    xl: 12,
                    lg: 24,
                    md: 24,
                    sm: 24,
                    xs: 24,
                },
                //batchReferenceIdentifier : '',
            }
        },

    filters: {

    },
    
    computed: {
        dynamicPatientDetailsDrawerWidth() {
            return window.innerWidth < 500 ? 98 : 65
        },

        // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
        mandatoryFieldsStyle() { return (this.liveValidating && (this.liveValidatingFieldsOK == false)) ? "revealValidationMessages" : "" },

        entriesToDisplay() {

            return this.entriesTableData.filter(entry => {
                
                /*
                    case 'UN': return "";                     // unassigned, no order status specified, 
                    case 'DR': return "Draft";                // DRaft, or what in the UI is Preparing Order... ie UI taps button to create an order to fill for a session.
                    case 'PP': return "Preparing Order";      // Preparing Order, means BE has queued the order by request of FE user tapping 'send'
                    case 'OR': return "Ordered";              // ORdered, means BE has taken request from queue, done all it needs to do and sent off order.
                    case 'PR': return "Reconciled";           // Product Reconciled, practice has reconciled the received product with order.
                    case 'ON': return "Order Not Required";   // Order Not Required, eg Probono.
                    case 'AB': return "Failed to Send";       // ABorded = when there is a error sending order or email/CC fails...
                */
                if (this.reconcileMode == true) {
                    // don't show order mode stuff => I don't want to see all null, UNspecified and DRaft, Preparing order and Failed to Send (Abort)
                    if (entry.orderStatus == null || entry.orderStatus == 'UN' || entry.orderStatus == 'DR' || entry.orderStatus == 'PP' || entry.orderStatus == 'AB')
                        return false
                    // else fall through and apply other filters.
                } else {
                    // we are in order mode, so don't show reconcile stuff
                    // => don't want to see PreParing order, ORdered, Product Reconciled, Order Not required
                    if (entry.orderStatus == 'OR' || entry.orderStatus == 'PR' || entry.orderStatus == 'ON'
                        || entry.orderStatus == 'so'    // PSEUDO status - order sent OK
                        || entry.orderStatus == 'sf'    // PSEUDO status - order sent failed
                    )
                        return false
                    // else fall through and apply other filters.
                }

                // if (this.showAllStatusOrders == false && (entry.orderStatus != null && entry.orderStatus != 'UN' && entry.orderStatus != 'DR' && entry.orderStatus != 'PP')) return false   // show Active only or everyone.


                // if (entry.firstName == undefined && this.filterFirstnameBy != '') return false  // if we are searching firstname, but entry firstname if not defined or empty, we are definitely not a match.
                // if (entry.lastName == undefined && this.filterLastnameBy != '') return false  // if we are searching firstname, but entry firstname if not defined or empty, we are definitely not a match.


                // // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                // // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                // if (this.filterFirstnameBy != '' && entry.firstName != undefined && entry.firstName.toLowerCase().includes(this.filterFirstnameBy.toLowerCase()) == false) return false // remove all items that dont match our firstname filter
                // if (this.filterLastnameBy != '' && entry.lastName != undefined && entry.lastName.toLowerCase().includes(this.filterLastnameBy.toLowerCase()) == false) return false // remove all items that dont match our lastname filter
                
                if (this.selectedTreatingPhysician == "--ALL--") {
                    // fall through and check more...
                } else if (this.selectedTreatingPhysician != entry.treatingPhysicianId) {
                    return false
                }

                if (this.selectedOrderStatus == "--ALL--") {
                    // fall through and check more
                } else if (this.selectedOrderStatus != entry.orderStatus) {
                    return false
                }

                if (this.selectedBatchRef == "--ALL--") {
                    // fall through and check more
                } else if (entry.order == null || (this.selectedBatchRef != entry.order.batchRef)) {
                    return false
                }

                if (this.selectedMedicationName == "--ALL--") {
                    // fall through and check more
                } else if (this.selectedMedicationName != entry.medicationName) {
                    return false
                }
                
                return true
            })

        },

        /**
         * Fetches the list of unique treating physicians which make up all the displayed sessions (which are in the date range for display)
         * 
         **/ 
        treatingPhysicians() {
            var physicianNames = {}
            var aPhysician;

            this.entriesTableData.map((session) => {
                aPhysician = physicianNames[session.treatingPhysicianName]

                if (aPhysician == undefined) {
                    aPhysician = physicianNames[session.treatingPhysicianName] = {
                                                                                    id : "",
                                                                                    name : ""
                                                                                }
                }

                aPhysician.id = session.treatingPhysicianId
                aPhysician.name = session.treatingPhysicianName
            })

            return _.concat([{ id : "--ALL--", name: "-- All Physicians --"}],
                            _.sortBy(_.values(physicianNames), ['name']))
        }, 

        displayedItemOrderStatuses() {
            var statuses = {}
            var aStatus;

            this.entriesTableData.map((session) => {
                aStatus = statuses[session.orderStatus]

                if (aStatus == undefined) {
                    aStatus = statuses[session.orderStatus] = {
                                                                    id : "",
                                                                    name : ""
                                                                }
                }

                aStatus.id = session.orderStatus
                aStatus.name = getUserFriendlyOrderStatus(session.orderStatus)
            })

            return _.concat([{ id : "--ALL--", name: "-- All Statuses --"}],
                            _.sortBy(_.values(statuses), ['name']))
        },

        /**
         * Fetches the list of unique batch references which make up all the displayed sessions (which are in the date range for display)
         * 
         **/ 
        batchRefs() {
            var batchRefs = {}
            var aBatchRef;

            this.entriesTableData.map((session) => {
                if (session.order != null) {
                    aBatchRef = batchRefs[session.order.batchRef]

                    if (aBatchRef == undefined) {
                        aBatchRef = batchRefs[session.order.batchRef] = {
                                                                            id : "",
                                                                            name : ""
                                                                        }
                    }

                    aBatchRef.id = session.order.batchRef
                    aBatchRef.name = session.order.batchRef
                }
            })

            return _.concat([{ id : "--ALL--", name: "-- All Batch Refs --"}],
                            _.sortBy(_.values(batchRefs), ['name']))
        }, 

        /**
         * Fetches the list of unique medication names which make up all the displayed sessions (which are in the date range for display)
         * 
         **/ 
        medicationNames() {
            var medicationNames = {}
            var aMedicationName;

            this.entriesTableData.map((session) => {
                if (session.order != null) {
                    aMedicationName = medicationNames[session.medicationName]

                    if (aMedicationName == undefined) {
                        aMedicationName = medicationNames[session.medicationName] = {
                                                                                        id : "",
                                                                                        name : ""
                                                                                    }
                    }

                    aMedicationName.id = session.medicationName ?? ""
                    aMedicationName.name = session.medicationName ?? ""
                }
            })

            return _.concat([{ id : "--ALL--", name: "-- All Medications --"}],
                            _.sortBy(_.values(medicationNames), ['name']))
        }, 

        medicationStockData() {
            var stockData = {}
            var medStatus

            this.medicationStockDataRaw.map((medEntry) => {
                medStatus = stockData[medEntry.injectMedicine.name]
                if (medStatus == undefined) {
                    medStatus = stockData[medEntry.injectMedicine.name] = {
                                                                            name : "",
                                                                            stock : 0,
                                                                            vialSummary : "",
                                                                            marketingUrl : "",
                                                                            orderUrl : "",
                                                                        }
                }

                medStatus.name = medEntry.injectMedicine.name
                medStatus.stock += medEntry.vialCount
                medStatus.vialSummary += (medStatus.vialSummary.length == 0 ? '' : "<br />") + "<span style='font-weight: 700;'>" + medEntry.injectMedicine.vialUnits + ' units:</span> ' + medEntry.vialCount + ' vial' + (medEntry.vialCount == 1 ? '' : 's')
                medStatus.marketingUrl = medEntry.injectMedicine.marketingUrl
                medStatus.orderUrl = medEntry.injectMedicine.orderUrl
            })

            return _.values(stockData)
        },

        orderIsReadOnly() {
            if (this.editEntryDetails.order == null)
                return false

            switch (this.editEntryDetails.orderStatus) {
                case 'PP':  // preparing order
                case 'OR':  // ordered
                case 'PR':  // product reconciled
                case 'ON':  // order not required
                case 'so':  // PSEUDO status - order sent OK
                case 'sf':  // PSEUDO status - order sent Failed
                    return true
            }

            return false
        },

        numberPeriodDaysString() {
            if (this.ordersDateRange == undefined
                || (this.ordersDateRange.length == 2 && this.ordersDateRange[0] == "" && this.ordersDateRange[1] == "")) {
            
                return "last 30 days"
            } else {
                var daysCount = ((this.ordersDateRange[1].getTime() + (60*60*24*1000 /* to account for the whole of end day, not 00:01 */)) - this.ordersDateRange[0].getTime()) / (60 * 60 * 24 * 1000)

                return daysCount.toFixed().toString() + ((daysCount == 1) ? " day" : " days")
            }
        }, 

        confirmRemoveEntryMessage() {
            return "Are you sure you wish to reset the order for '" 
                    + this.editEntryDetails.patientName + "' from the system?"
        },
        
        tableHeightToFit() {
            return window.innerHeight - 220
        },

        patientImportTemplateURL() { return CONFIG.PATIENT_IMPORT_TEMPLATE_URL() },


        uploadActionURL() { return itBnTx.baseUrl + "/rest/user/uploadFile" },
    },
    methods: {
        visibilityChanged (isVisible, entry) {
            entry
            
            console.log("visibilityChanged (COPAYMENT)", isVisible, this.toLoadOnShowUI_patientID, this.toLoadOnShowUI_sessionID)

            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/PracticeManagement/CoPayments');

                if (this.$store.state.newOrderForSessionID != 0) {
                    this.reconcileMode = false;
                    this.selectedTreatingPhysician = "--ALL--";
                    this.selectedOrderStatus = "--ALL--";
                    this.selectedBatchRef = "--ALL--";
                    this.selectedMedicationName = "--ALL--";
                    this.editEntryDetails = {
                                                sessionId : this.toLoadOnShowUI_sessionID,
                                                order : { quantity : '', uuid : '' }
                                            }
                }

                this.refreshDataForUI()


                this.$options.sockets.onmessage = (data) => {
                    console.log(data)
                    // "" 
                    this.reloadCurrentOrderAttachments()
                    // this.refreshDataForUI()
                    // upon receiving a message to refresh UI, ensure all changes to UI are saved, otherwise data changes will be lost.
                    this.saveChangedQuantityForOrder()  // this will internally call refreshDataForUI()
                }
            }
        },

        refreshDataForUI() {

            this.handleLoadEntriesListToNthPage(1)
            this.handleLoadMedicationStockCount()

        },
        
        handlePageLimitChanged(inNewLimit) {
            this.entriesListPageSize = inNewLimit       // silly two way binding not working... so have to do this way
            this.handleLoadEntriesListToNthPage(1)
        },


        handlePhysicianSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            this.editEntryDetails = currentRow
        },

        handleLoadMedicationStockCount() {


            itBnTx.getMedicineStockForPhysician({inTreatingPhysicianID : (this.selectedTreatingPhysician == "--ALL--" ? null : this.selectedTreatingPhysician)})
              .then(response => {
                this.medicationStockDataRaw = response.data
              })



        }, 
        

        handleLoadEntriesListToNthPage(inPageNumber) {
            inPageNumber

            var loadOrSearchPromise;

            // show loading spinner
            this.entriesListIsLoading = true

            // if (this.filterByFirstname != "" || this.filterByLastname != "") {
            //     loadOrSearchPromise = itBnTx.searchPatientsByName({
            //                                     inSearchFirstnameString : this.filterByFirstname,
            //                                     inSearchLastnameString : this.filterByLastname,
            //                                     inStart : this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit : this.entriesListPageSize })
            // } else {
                loadOrSearchPromise = itBnTx.getAllSessionOrders({
                                                inFromDateTime : (this.ordersDateRange == undefined || this.ordersDateRange[0] == "") ? null : this.ordersDateRange[0].getTime(),
                                                inToDateTime : (this.ordersDateRange == undefined || this.ordersDateRange[1] == "") ? null : this.ordersDateRange[1].getTime()
                                                                 + (60 * 60 * 24 * 1000) /* add 1 day of milli seconds - as the date picker always returns the 'to date'
                                                                                            as 12am on the day rather than midnight on the day to allow finding orders
                                                                                            throughtout that day the user picked as the end date */,
                                                inStart: this.entriesListPageSize * (inPageNumber - 1),
                                                inLimit: this.entriesListPageSize})
            // }

            loadOrSearchPromise.then(response => {
                
                var entries = response.data.sessions;

                this.entriesTotalCount = response.data.totalCount

                entries.map((entry) => {
                    if (entry.orderStatus == 'OR' /* ordered => the new 'charged', but need to check emailSent flag */) {
                        if (entry.emailSent == true && entry.emailFailedDetail == null) {
                            entry.orderStatus = 'so';   // PSEUDO status - "Sent order Ok"
                        } else if (entry.emailSent == true && entry.emailFailedDetail != null) {
                            entry.orderStatus = 'sf';   // PSEUDO status - "Send order Failed"
                        } else {
                            // retain... show "Charged" in UI
                        }
                    }
                })

//entries[3].order.quantity = "1 vial (100 units), 1 vial (200 units)"  // TEST QUANTITY

                // entries.map(entry => { if (entry.userLastLogin == undefined) entry['userLastLogin'] = 0 })
                this.entriesTableData = entries;
                
                var foundEntryToHighlight = false

                if (this.editEntryDetails.sessionId != undefined) {
                    // if we previously had a selection, try to preserve the same record which was previously selected
                    var previousSelection = this.entriesToDisplay.find(entry => { return entry.sessionId == this.editEntryDetails.sessionId} )

                    // sanity check... should never happen... unless someone deletes it from another location.
                    if (previousSelection != undefined) {
                        previousSelection["_highlight"] = true
                        this.handleEntriesSelectionChanged(previousSelection, null)
                        foundEntryToHighlight = true
                    }
                }
                
                if (foundEntryToHighlight == false) {
                    // else select the first record if nothing was selected

                    // if we have at least one entry, select the first one.
                    if (this.entriesToDisplay.length != 0) {
                        this.entriesToDisplay[0]["_highlight"] = true
                        this.handleEntriesSelectionChanged(this.entriesToDisplay[0], null)
                    }
                }

                // if we detect we are called as part of a create new order flow, initiate a create new order for the session...
                if (this.$store.state.newOrderForSessionID != 0) {
                    this.$store.commit("newOrderForSessionID", 0);
                    this.handleAddEntry()
                }

            }).finally(() => {
                // hide loading spinner
                this.entriesListIsLoading = false
            })
        },
        
        
        handleEntriesSelectionChanged(currentRow, oldCurrentRow)  {
            oldCurrentRow

            this.editEntryDetails = {
                sessionId : null,
                order : { quantity : '', uuid : '' }
            }
            
            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                return
            }

            // clone data so when cancelling an edit session, we can restore the original details in UI
            var newSelection = _.cloneDeep(currentRow)

            // setup dummy data so UI will not throw exceptions.
            if (newSelection.order == null) {
                newSelection.order = { quantity : '', uuid : '' }
            }
            
            this.editEntryDetails = newSelection

            // cache quantity, so we can check and update if required when we close the info slide UI
            // this.editEntryQuantityDetails = (this.editEntryDetails.order != null ? this.editEntryDetails.order.quantity : '')
            // this.editEntryReferenceDetails = (this.editEntryDetails.order != null ? this.editEntryDetails.order.batchRef : '')

        },
        
        handleAddEntry() {

            if (this.editEntryDetails.injectedUnits == null || this.editEntryDetails.injectedUnits == 0) {

               this.showOrderOnlyCaptureInputPrompt = true

            } else {

                this.liveValidating = false

                itBnTx.createOrderForSession({ inSessionID : this.editEntryDetails.sessionId })
                    .then(response => {
                            this.editEntryDetails = response.data
                            this.selectedEntryDetailsBeforeCreatingNewEntry = _.cloneDeep(this.editEntryDetails)

                            this.currentOrderAttachmentsData = []   // clear attachment list, don't show stale data.

                            // cache quantity, so we can check and update if required when we close the info slide UI
                            // this.editEntryQuantityDetails = (this.editEntryDetails.order != null ? this.editEntryDetails.order.quantity : '')
                            // this.editEntryReferenceDetails = (this.editEntryDetails.order != null ? this.editEntryDetails.order.batchRef : '')

                            // this.editEntryDetails = {
                            //                             practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                            //                             firstName: "",
                            //                             lastName: "",
                            //                             physicianAddress: "",
                            //                             physicianPhone: "",
                            //                             status: "A",
                            //                         }
                            // this.$refs['editEntryDetailsFormFields'].resetFields()

                            this.isCreatingNewEntry = true;
                            this.showDetails = true

                            this.refreshDataForUI()
                    })

            }

        },

        handleCreateOrderWithNoInjections() {
            this.liveValidating = false

            itBnTx.createOrderForSession({ inSessionID : this.editEntryDetails.sessionId,
                                        inOrderOnlyMedID : this.orderOnlyMedID,
                                        inOrderOnlyQuantity : this.orderOnlyQuantity })
                .then(response => {
                        this.editEntryDetails = response.data
                        this.selectedEntryDetailsBeforeCreatingNewEntry = _.cloneDeep(this.editEntryDetails)

                        this.currentOrderAttachmentsData = []   // clear attachment list, don't show stale data.

                        // cache quantity, so we can check and update if required when we close the info slide UI
                        // this.editEntryQuantityDetails = (this.editEntryDetails.order != null ? this.editEntryDetails.order.quantity : '')
                        // this.editEntryReferenceDetails = (this.editEntryDetails.order != null ? this.editEntryDetails.order.batchRef : '')

                        // this.editEntryDetails = {
                        //                             practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                        //                             firstName: "",
                        //                             lastName: "",
                        //                             physicianAddress: "",
                        //                             physicianPhone: "",
                        //                             status: "A",
                        //                         }
                        // this.$refs['editEntryDetailsFormFields'].resetFields()

                        this.isCreatingNewEntry = true;
                        this.showDetails = true

                        this.refreshDataForUI()
                })
        },

        handleEditEntry() {
            AnalyticsMgr.sendEvent('/PracticeManagement/CoPayments', 'click', 'SessionOrder_Edit')

            this.liveValidating = true

            this.reloadCurrentOrderAttachments()

            this.isCreatingNewEntry = false
            this.showDetails = true
        },

        handleEditEntryDoubleClick(rowData, index) {
            //console.log(rowData, index)
            index
            // only allow editing of entries which have an order data present.
            if (rowData.order != null) {
                // setup internals as if row were selected.
                this.handleEntriesSelectionChanged(rowData, null)
                // edit selected row entry.
                this.handleEditEntry()
            }
        },

        handleRemoveEntry() {
            if (this.editEntryDetails.sessionId != undefined) {
                itBnTx.deleteOrderForSession({inSessionID : this.editEntryDetails.sessionId})
                      .then(response => {
                          response
                        
                          this.refreshDataForUI()

                          this.$Message.success('Successfully reset order.');
                      })
            }
            
        },

        // ============================
        // == MEDICATION STOCK STATUS
        // ============================
        medicationStockLevelStatusClass(inMedData, inPrependClasses) {
            return inPrependClasses + " " + (inMedData.stock <= 5 ? "bg-red-500" : (inMedData.stock > 6 & inMedData.stock <= 15 ? "bg-yellow-500" : "bg-green-500"))
        },

        medicationStockLevelStatusStyle(inMedData, inPrependStyle) {
            return inPrependStyle + " " + (inMedData.stock <= 5 ? "background-color: rgba(239, 68, 68, 1);" : (inMedData.stock > 6 & inMedData.stock <= 15 ? "background-color: rgba(245, 158, 11, 1);" : "background-color: rgba(16, 185, 129, 1);"))
        },

        medicationVialSummary(inMedData) {
            return inMedData.vialSummary;
        },

        medicationRegisterToOrder(inMedData) {
            window.open(inMedData.marketingUrl, '_blank');
        },
        
        medicationOrderStock(inMedData) {
            window.open(inMedData.orderUrl, '_blank');
        },


        // ============================
        
       

        saveChangedQuantityForOrder() {
            const myPromise = new Promise((resolve, reject) => {
                if (this.editEntryDetails.order != null) {
                    // console.log("saveChangedQuantityForOrder",this.editEntryQuantityDetails, this.editEntryDetails.order.quantity)

                    // if (this.editEntryQuantityDetails != this.editEntryDetails.order.quantity
                    //     || this.editEntryReferenceDetails != this.editEntryDetails.order.batchRef) {
                        
                        var changedData = {};
                        // var updatedQuantity = false
                        // var updatedbatchRef = false

                        // if (this.editEntryQuantityDetails != this.editEntryDetails.order.quantity) {
                            // updatedQuantity = true
                            // changedData['quantity'] = this.editEntryDetails.order.quantity
                        // }
                        // if (this.editEntryReferenceDetails != this.editEntryDetails.order.batchRef) {
                            // updatedbatchRef = true
                            changedData['batchRef'] = this.editEntryDetails.order.batchRef
                        // }
                        changedData['injectMedicineId'] = this.editEntryDetails.order.injectMedicineId
                        changedData['orderQuantity'] = this.editEntryDetails.order.orderQuantity

                        changedData['manualOrder'] = this.editEntryDetails.manualOrder

                        return itBnTx.updateOrderForSession({ inSessionID : this.editEntryDetails.sessionId, 
                                                            inOrderData : changedData })
                                    .then(response => {
                                        response
                                        // if (updatedQuantity == true)
                                        //     this.editEntryQuantityDetails = this.editEntryDetails.order.quantity   // cache new value
                                        // if (updatedbatchRef == true)
                                        //     this.editEntryReferenceDetails = this.editEntryDetails.order.batchRef   // cache new value

                                        this.refreshDataForUI()
                                        resolve()
                                    })
                                    .catch(error => {
                                        error
                                        reject()
                                    })
                    // } else {
                    //     resolve()
                    // }
                } else {
                    reject()
                }
            })

            return myPromise
        },

        handleSaveButDontSent() {
            AnalyticsMgr.sendEvent('/PracticeManagement/CoPayments', 'click', 'SessionOrder_Edit_Done')

            // if (this.isCreatingNewEntry == false) {
            //     var previousSelection = this.entriesTableData.find(entry => { return entry.patientId == this.editEntryDetails.patientId} )

            //     // restore any changes back to what they were since the user cancelled.
            //     this.editEntryDetails = _.cloneDeep(previousSelection)
            // } else {
            //     this.editEntryDetails = this.selectedEntryDetailsBeforeCreatingNewEntry
            // }

            this.saveChangedQuantityForOrder()

            this.showDetails = false
        },

        handleSaveEntryDetailsNSent() {
            AnalyticsMgr.sendEvent('/PracticeManagement/CoPayments', 'click', 'SessionOrder_Edit_Send')

            var promise = this.saveChangedQuantityForOrder()

            promise.then(response => {
                response
                this.handleToolsMenu('send-order')
            })
            

//             AnalyticsMgr.sendEvent('/PracticeManagement/CoPayments', 'click', 'SessionOrder_Edit_Save')

//             this.$refs['editEntryDetailsFormFields'].validate((valid) => {
//                 if (valid) {
//                     this.liveValidatingFieldsOK = true

//                     var savingDetails = this.editEntryDetails

//                     // if we are a new record, make sure to clear the userId so the API knows to create a new record
//                     if (savingDetails.patientId == undefined) {
//                         delete savingDetails.patientId
//                     }
                                        
//                     delete savingDetails._highlight // delete UI key, otherwise API barfs

//  //                   console.log("SAVE DETAILS> ", JSON.stringify(this.savingDetails));

//                     this.savingDetailsToBackend = true
                    
//                     var savingPromise

//                     if (this.isCreatingNewEntry == true)
//                         savingPromise = itBnTx.createPatient({inPatientDetails : savingDetails })
//                     else
//                         savingPromise = itBnTx.updatePatient({inPatientDetails : savingDetails })

//                     savingPromise
//                           .then(response => {
//                                 response
                                
//                                 // reload data into UI
//                                 this.refreshDataForUI()
//                                 this.showDetails = false
//                           })
//                           .finally(() => {
//                               this.savingDetailsToBackend = false
//                           })

//                 } else {
//                     this.liveValidating = true
//                     this.liveValidatingFieldsOK = false

//                     this.$Message.error('Enter mandatory fields before trying again.');
//                 }
//             })
        },


        handleToolsMenu(inMenuName) {
            switch( inMenuName ) {
                case 'send-order':
                    //AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'Patient_Import_Select')

                    if (this.editEntryDetails.sessionId != undefined) {
                        itBnTx.changeOrderStatusForSession({ inSessionID : this.editEntryDetails.sessionId,
                                                                inStatus : 'PP'})   // PP = PreParing
                            .then(response => {
                                response
                                
                                this.refreshDataForUI()

                                this.$Message.success('Order successfully queued for sending.');

                                // on successful save/order, close the details UI.
                                this.showDetails = false
                            })
                    }
                    break;
                
                case 'cancel-order':
                    //AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'Patient_Import_Help')
                    //this.showImportHelp = true
                    var isOrderNotRequiredItem = this.editEntryDetails.orderStatus == 'ON'

                    if (this.editEntryDetails.sessionId != undefined) {
                        itBnTx.changeOrderStatusForSession({ inSessionID : this.editEntryDetails.sessionId,
                                                                inStatus : 'CA'})   // CA = CAncel
                            .then(response => {
                                response
                                
                                if (isOrderNotRequiredItem) {
                                    itBnTx.deleteOrderForSession({inSessionID : this.editEntryDetails.sessionId})
                                            .then(response => {
                                                response
                                                
                                                this.refreshDataForUI()

                                                this.$Message.success('Successfully reset Order Not Required.');
                                            })
                                } else {
                                    this.refreshDataForUI()

                                    this.$Message.success('Order successfully cancelled from send queue.');
                                }
                            })
                    }
                    break;

                case 'order-not-required':
                    if (this.editEntryDetails.sessionId != undefined) {
                        itBnTx.changeOrderStatusForSession({ inSessionID : this.editEntryDetails.sessionId,
                                                                inStatus : 'ON'})   // ON = Order Not required / pro bono
                            .then(response => {
                                response
                                
                                this.refreshDataForUI()

                                this.$Message.success('Session marked as Order Not Required.');
                            })
                    }
                    break;

                case 'product-reconciled':
                    if (this.editEntryDetails.sessionId != undefined) {
                        itBnTx.updateOrderForSession({ inSessionID : this.editEntryDetails.sessionId,
                                                       inOrderData : {
                                                                        orderStatus: "PR",
                                                                        distributorOrderId: this.editEntryDetails.order.uuid
                                                                     }
                                                    })   // PR = Product Reconciled
                            .then(response => {
                                response
                                
                                this.refreshDataForUI()

                                this.$Message.success('Order has been reconciled.');
                            })
                    }

                    break;
                
                case 'product-un-reconcile':
                    if (this.editEntryDetails.order != null) {
                        itBnTx.changeOrderStatusForSession({ inSessionID : this.editEntryDetails.sessionId,
                                                                inStatus : 'PU'  // PU = (pseudo status) Product Un-reconcile
                                                            })
                            .then(response => {
                                response
                                
                                this.refreshDataForUI()

                                this.$Message.success('Order has been un-reconciled.');
                            })
                    }
                    break;
                    
                case 'bulk-set-order-reference':
                    this.showBulkSetBatchRefDialog = true
                    setTimeout(() => { this.$refs.bulkEditBatchReferenceElement.focus() }, 500)
                    break;

                case 'bulk-send-orders':
                    //console.log(this.entriesToDisplay)

                    this.$Modal.confirm({
                        title: 'Confirm Bulk Send',
                        content: '<p>Are you sure you want to send displayed orders?</p>',
                        okText: "Send Displayed Orders",
                        onOk: () => {

                            this.entriesToDisplay.map((entry) => {
                                if (entry.order != null) {
                                    itBnTx.changeOrderStatusForSession({ inSessionID : entry.sessionId,
                                                                            inStatus : 'PP'})   // PP = PreParing
                                        .then(response => {
                                            response
                                            
                                            this.$Message.success('Order successfully queued for sending.');
                                        })
                                }
                            })
                            
                            setTimeout(() => { this.refreshDataForUI() }, 1500)
                        },
                        
                    });

                    break;

                case 'bulk-reconcile-orders':
                    this.$Modal.confirm({
                            title: 'Confirm Bulk Reconcile',
                            content: '<p>Are you sure you want to reconcile the displayed orders?</p>',
                            okText: "Reconcile Displayed Orders",
                            onOk: () => {

                            this.entriesToDisplay.map((entry) => {
                                
                                if (entry.order != null) {

                                    itBnTx.updateOrderForSession({ inSessionID : entry.sessionId,
                                                                    inOrderData : {
                                                                                        orderStatus: "PR",
                                                                                        distributorOrderId: entry.order.uuid
                                                                                    }
                                                                    })   // PR = Product Reconciled
                                            .then(response => {
                                                response
                                                
                                                this.$Message.success('Order has been reconciled.');
                                            })

                                }
                            })
                            
                            setTimeout(() => { this.refreshDataForUI() }, 1500);
                        }
                    });
                    break;

                case 'show-order-summary':
                    this.showVialSummaryDrawer = true

                    // force show all orders in list as we filter on visible items... and summary needs to have ordered orders... so show them!
                    // this.showAllStatusOrders = true

                    var medicationSummary = {}
                    var curCount = 0;
                    var injectMed;
                    var injectMedKeyString;


                    this.entriesToDisplay.map((entry) => {
                        // process Ordered & Reconciled orders only.
                        if (entry.orderStatus == "OR" || entry.orderStatus == "PR") {
                            console.log(entry)

                            injectMed = this.injectMedicinesRefData.find((value) => { return value.id == entry.order.injectMedicineId } )    
                            
                            if (injectMed != null)
                                injectMedKeyString = injectMed.name + " (" + injectMed.vialUnits + " units)"
                            else {
                                console.log("ERROR (show-order-summary) - injectMed null!")
                                return
                            }

                            curCount = medicationSummary[injectMedKeyString]
                            if (curCount == null || curCount == undefined) {
                                curCount = entry.order.orderQuantity
                            } else {
                                curCount += entry.order.orderQuantity
                            }

                            medicationSummary[injectMedKeyString] = curCount
                            
                        }
                    })

                    console.log(medicationSummary)

                    this.vialSummaryData = medicationSummary
                    break;
                
                case "onr-pro-bono":
                case "onr-private-patient":
                case "onr-training":
                case "onr-other":
                    if (this.editEntryDetails.sessionId != undefined) {
                        itBnTx.changeOrderStatusForSession({ inSessionID : this.editEntryDetails.sessionId,
                                                                inStatus : 'ON'})   // ON = Order Not required / pro bono
                            .then(response => {
                                response
                                
                                var onrMap = {
                                                "onr-pro-bono" : "Pro Bono",
                                                "onr-private-patient" : "Private Patient",
                                                "onr-training" : "Training",
                                                "onr-other" : "Other"
                                                }
                                itBnTx.updateOrderForSession({ inSessionID : this.editEntryDetails.sessionId,
                                                               inOrderData : {
                                                                                onrSubType: onrMap[inMenuName]
                                                                             }
                                                                })
                                            .then(response => {
                                                response
                                                
                                                this.$Message.success('Session marked as Order Not Required (' + onrMap[inMenuName] + ').');

                                                this.refreshDataForUI()
                                            })

                                // this.$Message.success('Session marked as Order Not Required.');
                            })
                    }
                    break;

            }
        },

        handleBulkSetBatchRef() {
            this.entriesToDisplay.map((entry) => {
                if (entry.order != null) {
                    itBnTx.updateOrderForSession({ inSessionID : entry.sessionId, 
                                                    inOrderData : {
                                                        distributorOrderId: entry.order.uuid,
                                                        batchRef : this.bulkEditBatchReferenceValue
                                                    }
                                                 })
                    .then(response => {
                        response
                    })
                    .catch(error => {
                        error
                    })
                }
            })
            setTimeout(() => { this.refreshDataForUI() }, 1500)

            this.showBulkSetBatchRefDialog = false
        },

        handleEmptyQuantity() {
            if (this.editEntryDetails.order.quantity.trim() == "" || this.editEntryDetails.order.quantity.indexOf('.') != -1) {

                this.editEntryDetails.order.quantity = this.editEntryQuantityDetails

                this.$Message.warning('Order quantity is invalid, reverting to previous value.');
            }
        },


        // ================
        // == EXAMINATION Tab Helpers
        // ================
        reloadCurrentOrderAttachments() {
            if (this.editEntryDetails.order.uuid == "") return;

            itBnTx.getAllOrderAttachmentsForOrder({ inOrderID : this.editEntryDetails.order.uuid })
                  .then(response => { this.currentOrderAttachmentsData = response.data })
        },

        handleBeforeUploadAttachment(inFile) {
            inFile

            this.uploadOrderAttachmentData.practiseId = this.$store.state.loggedInDoctorDetails.practiseId
            this.uploadOrderAttachmentData.authToken = this.$store.state.btxAuthToken

            this.uploadOrderAttachmentData.orderUuid = this.editEntryDetails.order.uuid

            return true
        },

        handleFormatError (file) {
            try { 
                //AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'error', 'Patient_Import_WrongFormat_' + file.name.split('.').pop())
            } catch { console.log("oh dear") }

            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select jpg, jpeg, png.'
            });
        },
        handleMaxSize (file) {
            // AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'error', 'Patient_Import_TooLarge')

            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 100 MB.'
            });
        },

        handleSuccess (response, file, fileList) {
            console.log(response)
            console.log(file)
            console.log(fileList)
            this.saveChangedQuantityForOrder()

            this.reloadCurrentOrderAttachments()
            this.refreshDataForUI() // update the 'hasAttachment' column...

            // AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'error', 'Patient_Import_Success')

        },


        handleError (error, responseData, fileList) {
            console.log("Upload Error: " + error)
            console.log(responseData)
            console.log(fileList)

            this.$Notice.error({
                title: 'Error Uploading',
                desc: responseData.responseMsg
            });
            // AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'error', 'Patient_Import_Error')

        },


        reloadPreviousSessionAttachmentsForSessionID(inSession) {
            itBnTx.getAllSessionAttachmentsForSession({ inSessionID : inSession })
                  .then(response => { this.selectedPreviousExaminationSessionAttachmentsData = response.data })
        },

        handleExaminationSessionSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow
//   console.log("handleExaminationSessionSelectionChanged", this.selectedPreviousExaminationSessionData)
          
            if (currentRow != undefined) {
                this.selectedPreviousExaminationSessionData = currentRow
            } else {
                this.selectedPreviousExaminationSessionData = {}
                return
            }
            
            this.reloadPreviousSessionAttachmentsForSessionID(this.selectedPreviousExaminationSessionData.sessionId)
        },

        handleAttachmentView(inAttachmentEntryDetails) {

            itBnTx.getSignedURL({ inURL : inAttachmentEntryDetails.s3Key })
                  .then(response => {
                        this.documentAttachmentDialogTitle = inAttachmentEntryDetails.attachmentName
                        this.documentAttachmentDialogDocumentURL = response.data.responseMsg
                        this.showDocumentAttachmentDialog = true
                    });
        },

        handleAttachmentDownload(inAttachmentEntryDetails) {
            
            // get signed URL of the attachment...
            itBnTx.getSignedURL({ inURL : inAttachmentEntryDetails.s3Key })
                  .then(response => {
                        // then create a dynamic anchor tag, and set its attributes, then simulate a click on it to download.
                        var downloadElement = document.createElement('a');

                        downloadElement.setAttribute('href', response.data.responseMsg);

                        // https://stackoverflow.com/questions/17527713/force-browser-to-download-image-files-on-click
                        // Hmm... download attribute wont work for CORS links... need to proxy via BE maybe.

                        downloadElement.setAttribute('download', inAttachmentEntryDetails.name);
                        downloadElement.setAttribute('target', "_blank");

                        downloadElement.style.display = 'none';
                        document.body.appendChild(downloadElement);

                        downloadElement.click();

                        document.body.removeChild(downloadElement);
                  })
        },

        handleCurrentSessionAttachmentDelete(inAttachmentEntryDetails) {
            itBnTx.deleteOrderAttachment({ inOrderAttachmentID : inAttachmentEntryDetails.uuid })
                  .then(response => {
                      response;
                      this.reloadCurrentOrderAttachments()
                      this.refreshDataForUI()
                  })
        },

        // handleAttachmentDelete(inAttachmentEntryDetails) {
        //     itBnTx.deleteSessionAttachment({ inAttachmentID : inAttachmentEntryDetails.sessionAttachmentId })
        //           .then(response => {
        //               response; this.reloadPreviousSessionAttachmentsForSessionID(this.selectedPreviousExaminationSessionData.sessionId)
        //           })
        // },
    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
        showDetails(newVal, oldVal) {
            oldVal
            if (newVal == true) {
                this.$refs.scriptUploader.clearFiles()

                // ========================
                // Create QR code for companion app - signature
                // ========================
                itBnTx.getCompanionToken()
                    .then(response => {
                        response
                            this.companionURLForMediaUpload = itBnTx.getLocationOrigin()
                                                            + "/#/capture-script?"
                                                            + qs.stringify({
                                                                    a : response.data.companionToken,                           // obscured key: authToken
                                                                    p : this.$store.state.loggedInDoctorDetails.practiseId,     // obscured key: practiseId
                                                                    o : this.editEntryDetails.order.uuid,                       // obscured key: orderUUID
                                                                    n : this.editEntryDetails.patientName                       // obscured key: patient name
                                                                })
                            console.log(this.companionURLForMediaUpload)
                        })
                // ========================
            }
        },

        ordersDateRange(newVal, oldVal) {
            newVal, oldVal
            //console.log("orderDateRange", newVal, oldVal)
            this.handleLoadEntriesListToNthPage(1)
        },

        selectedTreatingPhysician(newVal, oldVal) {
            newVal, oldVal
            this.handleLoadMedicationStockCount()
        },

        "$store.state.currentPatientID" (newValue, oldValue) { oldValue; this.toLoadOnShowUI_patientID = newValue; console.log("COPAYMENT UI received new currentPatientID", newValue) },
        "$store.state.currentSessionID" (newValue, oldValue) { oldValue; this.toLoadOnShowUI_sessionID = newValue; console.log("COPAYMENT UI received new currentSessionID", newValue) },

        // "editEntryDetails.order.injectMedicineId"(newValue, oldValue) { console.log("injectMedicineId changed from: " + oldValue + " to: " + newValue) },
        // "editEntryDetails.order.orderQuantity"(newValue, oldValue) { console.log("orderQuantity changed from: " + oldValue + " to: " + newValue) },
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  
.drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #334155;

 }

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}


.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}


.preAuthFailed {
    color: rgb(194, 139, 0);
    font-weight: bold;
}


.mockTailwindSpaceX2Y1 {
    margin-right: calc(0.5rem * 0);
    margin-left: calc(0.5rem * calc(1 - 0));
    margin-top: calc(0.25rem * calc(1 - 0));
    margin-bottom: calc(0.25rem * 0);
}


.tintedCardBackground {
    background-color:rgb(250, 250, 250)
}




</style>

