<template>
    <div style="width: 100%">
        <Modal
            v-model="modal"
            title="Patient Consents"
            cancel-text=""
            ok-text="Cancel"
            @on-ok="handleOK"
            :closable="false"
            :mask-closable="false"
            >
            <Card v-show="!userValidated" :padding="8" style="width: 100%;">
                <p slot="title">Patient Validation</p>

                <div style="margin-bottom:8px">You have been send this link to update your consents and billing details. Please confirm your identity with your mobile number before proceeding.</div>

                <Input v-model="mobileNumber" placeholder="Mobile number" type="tel" autocomplete="tel" style="width: 300px; margin-bottom: 8px" />

                <Button type="primary" @click="handleValidateUser" style="margin-left: 8px; margin-bottom: 8px" >Validate to continue…</Button>
            </Card>

            <Card v-show="userValidated" :padding="8" style="width: 100%;">
                <p slot="title">Co-payment Authority Consents</p>

                <CheckboxGroup v-model="copaymentConsentedIDs" @on-change="handleUpdateAConsent">
                    <span v-for="consent in copaymentAllConsents" :key="consent.id">
                        <Checkbox :label="consent.id"><div style="margin-left: 25px; margin-top: -20px;" v-html="consent.description.replaceAll('\n', '<br />')"></div></Checkbox><br /><br />
                    </span>
                </CheckboxGroup>

            </Card>

            <Card v-show="userValidated" :padding="8" style="margin-top:8px; width: 100%;">
                <p slot="title">Billing</p>

                <Button type="info" @click="handleEditPaymentSettings" style="margin-left: 8px;" >Payment Settings…</Button>

                <div id="card-element">
                    <!-- Elements will create form elements here -->
                </div>
            </Card>
        </Modal>

        <Row justify="center" align="middle" style="height:300px">
            <Col>Thank you for configuring your settings.</Col>
        </Row>
    </div>
</template>

<script>
    const {itBnTx} = require('@/js/itBnTx')

    // const {CONFIG} = require('@/js/bntx-config')
    // import {loadStripe} from '@stripe/stripe-js';

    // const stripePromise = loadStripe(CONFIG.STRIPE_PUBLISHABLE_KEY)
    // var stripe = undefined

    // stripePromise.then((stripeInstance) => {
    //     stripe = stripeInstance
    // })

    export default {
        name: 'MobilePatientConsent',

        mounted() {
            // this.$gtag.pageview(this.$route)
            /*

                https://inject.bntxinteract.com/#/consent/validationID
                do phone number validation... 
                once validated, return copayment_setup_nanoid

                itBnTx.bntxAuthToken = "COPAY-" + copayment_setup_nanoid
            */
            console.log(this.$route.params.validationID) 

            
        },

        data () {
            return {
                userValidated : false,
                mobileNumber : '',
                validateResponse : {
                                    copaymentSetupId: "",
                                    copaymentUuid: ""
                                    },


                //copaymentAllConsents : [],  // kinda like refdata
                copaymentAllConsents : [],
                copaymentConsentedIDs : [],


                modal: true,

                elementsOptions: {
                    appearance: {}, // appearance options
                },
            }
        },
        methods: {
            handleOK () {
                //this.$Message.info('Clicked ok');
                this.modal = false;
                // var self = this;
                // setTimeout(function() {self.$router.push({name:"login"}) }, 100);

                // Stripe Elements UI
                // stripe.confirmCardSetup(this.elementsOptions.clientSecret /*, {
                //         payment_method: {
                //         card: card,
                //         billing_details: { email: email }
                //         }
                //     }*/)
                //     .then(function(result) {
                //         if (result.error) {
                //         //changeLoadingState(false);
                //         //var displayError = document.getElementById("card-errors");
                //         //displayError.textContent = result.error.message;
                //         console.log(result.error.message)
                //         } else {
                //         // The PaymentMethod was successfully set up
                //         // orderComplete(stripe, setupIntent.client_secret);
                //         }
                //     });
                
            },

            handleValidateUser() {
                // this.userValidated = true

                itBnTx.validatePatientCopaymentSMSCode({ inPatientMobile : this.mobileNumber,
                                                         inPatientSMSCode : this.$route.params.validationID })
                      .then(response => {
                            this.validateResponse = response.data

                            // setup special token for patient copayment API calls
                            itBnTx.btxAuthToken = 'copay-' + this.validateResponse.copaymentSetupId

                            // fetch all consents a patient can consent to
                            itBnTx.getAllCopaymentConsents()
                                  .then(response => {
                                      this.copaymentAllConsents = response.data

                                        // now fetch all the consents the user has consented to.
                                      itBnTx.getPatientConsentedConsents({inCopaymentUUID : this.validateResponse.copaymentUuid})
                                            .then(response => {
                                                //console.log(response.data)
                                                for (var consentIndex in response.data) {
                                                    this.copaymentConsentedIDs.push(response.data[consentIndex].id)
                                                }
                                            })
                                })
                            
                            

                                  
                        
                            this.userValidated = true
                      })
                      .catch(error => {
                        // console.log(error)
                        this.$Message.error({
                                                content: "This link has expired or the mobile your specified is invalid. [ID=" + error.responseCode + "]",
                                                duration: 5 /* show backend errors for longer, as default of 1.5s a bit too short to read */
                                            });
                      })
            },

            handleEditPaymentSettings() {
                
                itBnTx.getMobilePatientPaymentConfigURL({inCopaymentUUID : this.validateResponse.copaymentUuid })
                      .then(response => {
                            var stripeURL = response.data['url']
                            // var newWin = null

                            // @13 Jan 2023 - discussed and decided (Matt/Leo/Peter), we would just replace btx UI with stripe payment
                            // and in the future loop back and implement the elements method if still required.
                            // Replacing document URL will no longer cause any popup blocking issues.
                            document.location = stripeURL;
                            
                            // newWin = window.open(stripeURL, 'PatientStripePaymentConfig')

                            // setTimeout(() => {
                            //     // https://stackoverflow.com/questions/2914/how-can-i-detect-if-a-browser-is-blocking-a-popup
                            //     if(!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                            //     {
                            //         alert("Grrr! A popup blocker may be preventing the application from opening the page. If you have a popup blocker, try disabling it to open the window. For now we will just open the page in the current window.")
                            //         document.location = stripeURL;
                            //     }
                            // }, 1500)
                      })
                

//                 itBnTx.getStripeClientSecretForCopaymentUUID({ inCopaymentUUID : this.validateResponse.copaymentUuid})
//                     .then(response => {
//                         console.log(response.data)
//                         this.elementsOptions.clientSecret = response.data.clientSecret

//                         // stripePromise.then((stripe) => {
//                             var elements = stripe.elements(this.elementsOptions);

//                             // Create a new card element
//                             // var card = elements.create('payment');
//                             var card = elements.create('card');

//                             // Mount the card element to your page
//                             card.mount('#card-element');
//                         // });
// // console.log(stripe)
//                         // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
//                         // const elements = stripe.elements();//(this.elementsOptions);

//                         // // Create and mount the Payment Element
//                         // const paymentElement = elements.create('card');
//                         // paymentElement.mount('#payment-element');
//                     })
            },

            handleUpdateAConsent() {
                var consents = [];

                for (var aConsentIndex in this.copaymentConsentedIDs) {
                    consents.push( { id : this.copaymentConsentedIDs[aConsentIndex] } )
                }

                itBnTx.setConsent({   inCopaymentConfigID : this.validateResponse.copaymentUuid,
                                            inConsents : consents})
            }
        }
    }
</script>

<style scoped>



</style>