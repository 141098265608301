<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
    <div id="pm-patient-assessment" class="layout" v-observe-visibility="visibilityChanged" >

        <div v-show="altKeyPressed || isForceReconnect" class="ivu-m">
            <h3>Problems viewing Patient Data Insights?</h3>
            Attempt to re-connection with patient.<br />
            <Button :disabled="connectionInProgress" @click="handleRequestConnection(true)" style="width: 220px; margin:16px">Request Re-Connection</Button>
            <div v-show="connectionInProgress">Waiting for connection for another {{ connectionInProgressCountdownInSeconds }}s </div>
        </div>

        <div v-show="!altKeyPressed">
            <div v-show="!patientHasClinicConnection" class="ivu-m">
                <h3>Patient Data Insights</h3>
                This patient is currently not connected to this clinic.<br />
                Please request a connection, which will send an SMS to the patient to ask for their consent to share their diary data with this clinic.<br />
                <Button :disabled="connectionInProgress" @click="handleRequestConnection(false)" style="width: 220px; margin:16px">Request Connection</Button>
                <div v-show="connectionInProgress">Waiting for connection for another {{ connectionInProgressCountdownInSeconds }}s </div>
            </div>

            <div v-show="patientHasClinicConnection && !patientRefreshTokenValid" class="ivu-m">
                <h3>Patient Data Insights</h3>
                There appears to be a problem with the connection to the patient. Please request a re-connection.<br />
                Requesting a re-connection, will send an SMS to the patient to ask for their consent to share their diary data with this clinic.<br />
                <Button :disabled="connectionInProgress" @click="handleRequestConnection(false)" style="width: 220px; margin:16px">Request Re-Connection</Button>
                <div v-show="connectionInProgress">Waiting for connection for another {{ connectionInProgressCountdownInSeconds }}s </div>
            </div>
        </div>

        <iframe v-show="patientHasClinicConnection && patientRefreshTokenValid"
                :src="patientReportURL"
                style="width:100%; height:100%; border-width: 0;"
                />
        <!-- <Button @click="handleShowPatientAssessment" style="width: 220px; margin:16px">Show Patient Assessment</Button> -->


    </div>
</template>

<script>

// const {CONFIG} = require('@/js/bntx-config')
// import AnalyticsMgr from '@/js/AnalyticsManager.js';
// var qs = require('qs');
// import VueQrcode from 'vue-qrcode'
const axios = require('axios');

// const {RECORDS_SYMPTOMS} = require('@/js/migraineMock.js')

const {itBnTx} = require('@/js/itBnTx')
// var _ = require('lodash');



// import moment from 'moment'


export default {
    name: 'pm-patient-assessment',
    components: {
    },

    
    created () {
        window.addEventListener('keyup', this.handleKeyUp);
        window.addEventListener('keydown', this.handleKeyDown);

    },

    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUp);
        window.removeEventListener('keydown', this.handleKeyDown);
    },

    destroyed() {
        
    },

    mounted() {
        

    },

    data () {
            return {
                // following are cached, and loaded as soon as we are visible, so save on unnecessary API calls. Sometimes too dynamic is not good.
                toLoadOnShowUI_patientID: null,
                toLoadOnShowUI_sessionID: null,

                ///////////////////////////////////////////////////////

                patientID : 0,

                patientConnection: {
                            id: 0,
                            status: "-",
                            act: null,  // access token
                            rft: null,  // refresh token
                            rftExpIn: null,
                            actExpIn: null,
                        },
                
                patientHasClinicConnection : false,
                patientRefreshTokenValid : false,



                isCapturing : false,
                startPointX : 0,
                startPointY : 0,
                deltaX : 0,
                deltaY : 0,
                debug : "",

                altKeyPressed : false,

                isForceReconnect : false,
                connectionInProgress : false,
                connectionInProgressCountdownInSeconds : 0,

            }
        },
    computed: {
        currentSelectedPatientDetails()   { return this.$store.state.currentSelectedPatientDetails },
        practiceSupportsCoPayments()      { return this.$store.state.loggedInPracticeDetails.supportsCoPayments },
        patientReportURL()                {
            return ((itBnTx.environment === 'prod') ? 'https://cmreport.bntxinteract.com' : 'https://test-cmreport.bntxinteract.com')
                     + "?client_id=app.clinic&token=" + encodeURIComponent(this.patientConnection.rft)
        },

        

    },
    methods: {
        handleKeyUp(event) {
            if (!event.altKey) {
                this.altKeyPressed = false;
            }
        },
        handleKeyDown(event) {
            if (event.altKey) {
                this.altKeyPressed = true;
            }
        },
        
        countdownTimer(seconds) {
            this.connectionInProgressCountdownInSeconds = seconds

            // Base case: If the timer reaches 0, stop the countdown
            if (seconds <= 0) {
                // console.log("Countdown complete!");
                return;
            }

            // console.log(seconds + " seconds remaining");

            // every 5 seconds check connection and status of token.
            if (seconds % 5 == 0) {
                this.checkConnectionAndValidToken()
            }

            // Decrement the timer by 1 second
            seconds--;

            if (this.patientRefreshTokenValid == true) {
                // if the refresh token is now valid, assume the patient has consented... our job is done, so exit.
                this.isForceReconnect = false
                this.connectionInProgress = false
                this.connectionInProgressCountdownInSeconds = 0
                return
            }

            // Call the countdownTimer function recursively with a 1-second delay
            setTimeout(() => {
                this.countdownTimer(seconds);
            }, 1000);
        },

        handleRequestConnection(inForce) {
            this.isForceReconnect = inForce
            this.connectionInProgress = true

            console.log("patientID: " + this.patientID, this.patientHasClinicConnection, this.patientRefreshTokenValid, this.isForceReconnect);
            itBnTx.requestPatientConnection({
                    inPatientID: this.patientID,
                    inForceReconnect : (this.patientHasClinicConnection && !this.patientRefreshTokenValid ) || this.isForceReconnect
                })


            // itBnTx.requestPatientConnection({inPatientID: 28703})

            if (this.connectionInProgressCountdownInSeconds == 0)
                this.countdownTimer(120) // wait 120 seconds before enabling button to try again.

        },

        // handleShowPatientAssessment() {
           
        //     var assessmentDashboardURL = "https://coralline.vercel.app" + "?token=" + encodeURIComponent((this.patientConnection.rft != null) ? this.patientConnection.rft : "not_connected");

        //     window.open(assessmentDashboardURL, 'bntx-patient-assessment');

        // },



        checkAndLoadSessionData(inPatientIDChangedToo) {
            inPatientIDChangedToo

            if (this.toLoadOnShowUI_sessionID != null) {
                //this.loadDataOnChangeInSessionID(this.toLoadOnShowUI_sessionID, inPatientIDChangedToo)
                this.toLoadOnShowUI_sessionID = null
            }
        },

        /*
            checks the connection of the current patient to see if it has a refresh token assigned.
            If a refresh token exists, check to see if we can get an access token (only possible if refresh token valid)
            Just to be sure, in case we have an oddball situation in BE, we use access token to call api to get tasks,
            if it succeeds, then we are all a go!
            If something fails then authentication not working somewhere.
        */
        checkConnectionAndValidToken() {
            this.patientHasClinicConnection = false

            itBnTx.getPatientConnections({inPatientID : this.patientID })
                    .then(response => {
                        // console.log(response.data)
                        this.patientConnection = response.data;

                        this.patientHasClinicConnection = true
                        
                        if (this.patientConnection.rft !== null
                            // below is for debugging only... should be commented out for PROD. Copy an existing refresh token here to simulate an invalid token
                            //&& this.patientConnection.rft !== 'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJjY2NiODY3Ni1lZDVhLTQ4ZTAtYTU4Zi0zZTM4ZWM2OTIwM2MifQ.eyJleHAiOjE3MDk2NDM1MTAsImlhdCI6MTY5NDA5MTUxMSwianRpIjoiYmZhMDQ5OTEtY2VjNC00NDk1LWE2NDgtMzIxM2UwNmFlOGI1IiwiaXNzIjoiaHR0cDovL3Rlc3QtYm50eC1rZXljbG9hay5sb2NhbDo4MDgwL2F1dGgvcmVhbG1zL2JudHgiLCJhdWQiOiJodHRwOi8vdGVzdC1ibnR4LWtleWNsb2FrLmxvY2FsOjgwODAvYXV0aC9yZWFsbXMvYm50eCIsInN1YiI6IjY4YzA1YmEzLTE0NmYtNDE2Yi04MzA1LWVlMjZjY2U5OGI4OCIsInR5cCI6IlJlZnJlc2giLCJhenAiOiJhcHAuY2xpbmljIiwic2Vzc2lvbl9zdGF0ZSI6ImQ0OTNlZTU2LWNkODAtNDIzNy1iZTg0LTVhYjM2ZWY0NTcwNCIsInNjb3BlIjoiZW1haWwgYm50eC1yZWNvcmQtdGFza3MgcHJvZmlsZSBibnR4LXJlY29yZC1zeW1wdG9tcyBibnR4LXBlcnNvbmFsLWluZm9ybWF0aW9uIiwic2lkIjoiZDQ5M2VlNTYtY2Q4MC00MjM3LWJlODQtNWFiMzZlZjQ1NzA0In0.WF1kK9LS2fBK8S3ZHS_Q42uvrtR8LH0zr2phK7FWja0'
                            ) {

                            axios.post(itBnTx.hubUrl + '/api/v1/oauth2/refresh?client_id=app.clinic&refresh_token=' + this.patientConnection.rft)
                                .then(response => {
                                    response
                                    // all good.
                                    // console.log("GOOD TOKEN: ", response.data)
                                    /*
                                        {
                                            "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJnQmF0Q0lFc3lzbHBTV09IT2hLcUVGVHZDUktWSkJzUUZqSHdjYndrQ0RFIn0.eyJleHAiOjE2OTM3OTk4NTMsImlhdCI6MTY5Mzc5ODA1MywiYXV0aF90aW1lIjoxNjkzNDg5MDY2LCJqdGkiOiJiY2UxOWY0OC1jNmUzLTRlNmUtOTE4Zi02MzUzZGNkYzgwNzgiLCJpc3MiOiJodHRwOi8vdGVzdC1ibnR4LWtleWNsb2FrLmxvY2FsOjgwODAvYXV0aC9yZWFsbXMvYm50eCIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiI2OGMwNWJhMy0xNDZmLTQxNmItODMwNS1lZTI2Y2NlOThiODgiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJhcHAuY2xpbmljIiwic2Vzc2lvbl9zdGF0ZSI6Ijk3YjA1Y2RhLWU4OTUtNDY1Yi05Y2YzLTIxZmZhZGM2ZjE4MiIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly90ZXN0LWluamVjdC5ibnR4aW50ZXJhY3QuY29tIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJST0xFX1VTRVIiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIiwiZGVmYXVsdC1yb2xlcy1ibnR4Il19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJibnR4LXJlY29yZC10YXNrcyBibnR4LXJlY29yZC1zeW1wdG9tcyBibnR4LXBlcnNvbmFsLWluZm9ybWF0aW9uIiwic2lkIjoiOTdiMDVjZGEtZTg5NS00NjViLTljZjMtMjFmZmFkYzZmMTgyIn0.PYvPInsbg50ioFlKnci2j8-3Fl35_12ct0oraGzYyDvCX6AAGvWNpqsOi_L6GYGQjIqt04sg6riVA25q0mRaw6T_8k4OkfEl5n8FnjiT1NOw9xbUyEZlfPZpNL9Ei46mh-Nn-L-No0GuCJHnd-StSmnuFxC_vihKGNncn3ZqybOmkEkqPpJccHJBaFplmdlZ1cDSOfyiNNWZRwV6ZSXEN57eqHRZBLlEjQCA1NZ-54khEQaBoNDMyVnLTyheLuGgObD1MH-retaUAMKTN-EW_ncGz2_h-DZPPnb4Xo4ZvWONZ3Je5AZPf6U5_nrbnB2Hy8xwbUymBtuZpwSCx7bnUw",
                                            "expires_in": 1800,
                                            "token_type": "Bearer"
                                        }
                                    */
                                    axios.get(itBnTx.hubUrl + '/api/v1/records/tasks', {
                                            headers: {
                                                Authorization: `Bearer ${response.data.access_token}`,
                                            }
                                        })
                                        .then(response => {
                                            response
                                            this.patientRefreshTokenValid = true
                                        })
                                        .catch(error => {
                                            error
                                            this.patientRefreshTokenValid = false
                                        })
                                    
                                })
                                .catch(error => {
                                    error
                                    // problematic refresh token
                                    /*
                                        {
                                            "entityName": "PatientUser",
                                            "errorKey": "patient.user.refresh_token",
                                            "type": "https://www.jhipster.tech/problem/problem-with-message",
                                            "title": "Invalid refresh token due to {\n  \"error\": \"invalid_grant\",\n  \"error_description\": \"Invalid refresh token. Token client and authorized client don't match\"\n}",
                                            "status": 400,
                                            "message": "error.patient.user.refresh_token",
                                            "params": "PatientUser"
                                            }
                                    */
                                    // console.log("BAD TOKEN: ", error)
                                    // if (error.status == 400 && error.message == "error.patient.user.refresh_token") {
                                        this.patientRefreshTokenValid = false
                                    // }
                                })
                        } else {
                            this.patientRefreshTokenValid = false
                        }
                    })
                    .catch(error => {
                        // console.log("ERR: ", error)
                        if (error.responseCode == 1007) {   // The patient doesn't have connection created.

                        }
                    })
        },

        visibilityChanged (isVisible, entry) {
            entry

            console.log("visibilityChanged (PATIENT ASSESSMENT)", isVisible, this.toLoadOnShowUI_patientID, this.toLoadOnShowUI_sessionID)
            
            if (this.needToSyncToDB == false && isVisible == true)
                this.needToSyncToDB = true

            if (isVisible == false) {
                // this.$store.commit("justUpdatingAssessmentData", true)
                // this.saveDetailsBackToDB()
                this.needToSyncToDB = false
            }

            if (isVisible == true) {
                this.connectionInProgress = false
                this.isForceReconnect = false
                
                // AnalyticsMgr.sendPageView('/Assessments')

                var patientIDChangedToo = false

                if (this.toLoadOnShowUI_patientID != null) {
                    this.patientID = this.toLoadOnShowUI_patientID;
                    // this.loadDataOnChangeInPatientID(this.toLoadOnShowUI_patientID)
                    this.toLoadOnShowUI_patientID = null
                    patientIDChangedToo = true
                }
                
                if (patientIDChangedToo == false) {
                    // this.checkAndLoadSessionData(patientIDChangedToo)
                } else {
                    this.checkConnectionAndValidToken()
                }
console.log(">>>>>>>>>>>>>>>SETUP")
                // this.$options.sockets.onmessage = (data) => {
                //     console.log(data)
                //     // "" 
                //     this.reloadCurrentSessionAttachments()
                // }

            }
        },



    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...

        "$store.state.currentPatientID" (newValue, oldValue) { oldValue; this.toLoadOnShowUI_patientID = newValue; this.conditionTabsCurrentSelection = 'symptoms'; console.log("PATIENT ASSESSMENT UI received new currentPatientID", newValue) },
        "$store.state.currentSessionID" (newValue, oldValue) { oldValue; this.toLoadOnShowUI_sessionID = newValue; console.log("ASSESSMENT UI received new currentSessionID", newValue) },

    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>

.layout {
    padding: 8px;
    height: 100%;
}

.currentSessionRowHighlight {
    font-weight: bold;
}

.ivu-form-item-content {
    position: relative;
    line-height: 20px;
    font-size: 14px;
}

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}




.spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}

.blah {
    color: #dade01;
}



/* ===========
   == Pulsating orb - https://codepen.io/peeke/pen/BjxXZa
   =========== */

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
}
.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #f1a4e9;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 122, 122, 0.685);
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
          animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@-webkit-keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

/* ================== */



.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


/* TAILWINDCSS converted styles */

@media (min-width: 640px) {
    .sm\:flex-wrap {
        flex-wrap: wrap;
    }

    .sm\:w-full {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .md\:flex {
        display: flex;
    }

    .md\:w-1\/3 {
        width: 33.333333%;
    }

    .md\:w-1\/2 {
        width: 50%;
    }

    .md\:w-full {
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .lg\:flex {
        display: flex;
    }
}

@media (min-width: 1280px) {
    .xl\:w-1\/6 {
        width: 16.666667%;
    }

    .xl\:w-3\/6 {
        width: 50%;
    }

    .xl\:w-1\/4 {
        width: 25%;
    }
}

.w-full {
    width: 100%;
}

.justify-items-center {
    justify-items: center;
}

.font-bold {
    font-weight: 700;
}

.text-center {
    text-align: center;
}

.text-lg {
    font-size: 1.125rem/* 18px */;
    line-height: 1.75rem/* 28px */;
}

.p-3 {
    padding: 0.75rem/* 12px */;
}

.border {
    border-width: 1px;
}

.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}


.rounded-lg {
    border-radius: 0.5rem/* 8px */;
}

.h-48 {
    height: 12rem/* 192px */;
}

.relative {
    position: relative;
}




</style>



<style>

.ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
    height: 32px;
    padding-bottom: 5px;
    background: #4ceafb !important;
    transform: translateZ(0);
    border-color: #dcdee2;
    color: #334155;
}

/* * { border: 0.5px solid black; } */


</style>