import Vue from 'vue'



export default {

    sendPageView(inScreenHierarchyID) {
        Vue.$gtag.pageview(inScreenHierarchyID)
    },

    sendEvent(inCategory, inAction, inLabel) {
        Vue.$gtag.event(inAction, {
                            'event_category': inCategory,
                            'event_label': inLabel,
                        })

    },

    sendEventWithValue(inCategory, inAction, inLabel, inValue) {
        Vue.$gtag.event(inAction, {
                            'event_category': inCategory,
                            'event_label': inLabel,
                            'value':inValue
                        })

    },




    /**
     * @param {any} inContext The context
     * @param {String} inSectionName The Section being shown
     */
    /* UNUSED - TO REMOVE */
    sendAnalyticsShowContent(inContext, inSectionName) {
        inContext.$gtag.event("Show", {
                                'event_category': "Content",
                                'event_label': "Section_" + inSectionName
                            })
    },
    
    /**
     * @param {any} inContext The context
     * @param {String} inEntryName The Entry Name being shown
     * @param {String} inEntryID The Entry ID being shown
     * @param {String} inContentContext The Context of the content, eg section name.
     */
    /* UNUSED - TO REMOVE */
    sendAnalyticsViewEntry(inContext, inEntryName, inEntryID, inContentContext) {

        inContentContext    // silence unused.

        inContext.$gtag.event("view_item", {
                                'event_category': "engagement",
                                'event_label': inEntryName,
                                'value':inEntryID
                            })
    },
    

};
