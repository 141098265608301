<template>
  <div >
    <!-- <Layout>
      <Header></Header>
      <Content> -->
        <Row justify="center" style="margin-top:100px"> 
            <img src="@/assets/app_logo.svg" style="width:200px;padding:16px;"/>
            
            <Card style="width: 320px;">
                <span v-if="checkoutStatus=='success'">Your changes have been successfully applied.</span>
                <span v-else-if="checkoutStatus=='cancelled'">Any changes made have not been applied and the patients' payment details will remain as it previously was.</span>
                <span v-else-if="cameFrom=='customerportal'">Thank you for configuring the Patient Payment Method.</span>
                <span v-else-if="cameFrom=='patient'">Thank you for configuring your Patient Payment Method.</span>
            </Card>
        </Row>
        <Row justify="center"  style="margin-top:30px">
            <Button v-show="cameFrom!='patient'" @click="handleBackToMainPortal">Back to BnTx Interact</Button>
        </Row>
      <!-- </Content>
      <Footer><i-copyright /></Footer>
    </Layout> -->
  </div>
</template>

<script>

//const {CONFIG} = require('@/js/bntx-config')
// import AnalyticsMgr from '@/js/AnalyticsManager.js';
// const {itBnTx} = require('@/js/itBnTx')

// import Vue from 'vue'




export default {
    name: 'CoPayment',
    props: {

    },

    created () {

    // AnalyticsMgr.sendPageView('/LoginScreen')
    },
    mounted() {
        window.name = 'bntx-copayment';

        /*
            https://inject.bntxinteract.com/#/subscription?checkout=success&session_id=SESSIONID
            https://inject.bntxinteract.com/#/subscription?checkout=cancelled
            https://inject.bntxinteract.com/#/subscription?from=customerportal
        */
        if (this.$route.query.checkout != undefined) {
            this.cameFrom = ''
            this.checkoutStatus = this.$route.query.checkout    // possible value: "success", "cancelled"
        } else if (this.$route.query.from != undefined) {
            this.cameFrom = this.$route.query.from              // possible value: "customerportal"
            this.checkoutStatus = ''

        } else {
            // should not browser here by itself, if we do, redirect to login screen
            this.$router.push({name:"login"});
        }
    },

    data () {
        return {
            checkoutStatus : 'cancelled',
            cameFrom : 'checkout'

        }
    },

    computed: {

    },

    methods: {
        handleBackToMainPortal() {
            window.open('/#/', 'bntx-webapp')
        }
    },

    watch: {



    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
