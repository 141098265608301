<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
    <div id="pm-ref-stats" class="layout" v-observe-visibility="visibilityChanged">

        <!-- <iframe src="https://bntrx.online"
                style="width:100%; height:100%; border-width: 0;"
                /> -->

                <Button @click="gotoBnTRxSystem()" style="width: 220px; margin:16px">Open BnTRx System…</Button>
    </div>
</template>

<script>

const {CONFIG} = require('@/js/bntx-config')
// import AnalyticsMgr from '@/js/AnalyticsManager.js';
// var qs = require('qs');
// import VueQrcode from 'vue-qrcode'
// const axios = require('axios');

// const {RECORDS_SYMPTOMS} = require('@/js/migraineMock.js')

// const {itBnTx} = require('@/js/itBnTx')
// var _ = require('lodash');



// import moment from 'moment'


export default {
    name: 'pm-ref-stats',
    components: {
    },

    
    created () {

    },

    beforeDestroy() {

    },

    destroyed() {
        
    },

    mounted() {
        

    },

    data () {
            return {
                

            }
        },
    computed: {
       

        

    },
    methods: {
        gotoBnTRxSystem() {
            window.open(CONFIG.BNTRX_CLINIC_URL(), "au.com.bntrx.clinic")
        },

        visibilityChanged (isVisible, entry) {
            entry

            console.log("visibilityChanged (BNTRX)", isVisible)
            

            if (isVisible == true) {

                this.gotoBnTRxSystem()
            }
        },


    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...


    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>

.layout {
    padding: 8px;
    height: 100%;
}



</style>



