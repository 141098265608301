
/*
    practice record

    REQUEST
    {
        "practiseName": "",
        "practiseContactName": "",
        "practiseContactEmail": "",
        "password": "",
        "practiseContactPhone": "",
        "practiseAddress": "",
        "practiseCity": "",
        "practisePostcode": "",
        "countryCode": "",
        "token": "captcha token"
    }

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Practise has been created."
    }
*/
function registerPractise({inNewPractiseRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/practise/register',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inNewPractiseRecord)
                        });
}



/*
    practice record

    REQUEST
    {
        "statusCode": "A",
        "accountStatusCode": "P",
        "practiseName": "HealthInteract",
        "practiseAddress": "Hurstville,NSW 2220",
        "practiseCity": "Hurstville",
        "practisePostcode": "2220",
        "countryCode": "AU",
        "practiseLogo": "http://sfssfa.jpg",
        "practiseFax": "3232323"
        "practiseContactName": "leochen",
        "practiseContactPhone": "0430142735",
        "practiseContactEmail": "cenling40cloud@gmail.com",
        "isHospital": true
    }

    RESPONSE
    {
        "practiseId":5
        "statusCode":"A", 
        "accountStatusCode":"P", 
        "practiseName":"HealthInteract", 
        "practiseAddress":"Hurstville,NSW 2220", 
        "practiseCity":"Hurstville", 
        "practisePostcode":"2220", 
        "countryCode":"61", 
        "practiseLogo":"http"://sfssfa.jpg", 
        "practiseFax": "3232323"
        "practiseContactName":"leo chen", 
        "practiseContactPhone":"0430142735", 
        "practiseContactEmail":"cenling40cloud@gmail.com", 
        "isHospital":true, 
        "created":"Thu Jan 22 15:30:14 EST 2015", 
        "createdBy":1
    }
*/
function createPractise({inPractiseRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/practise',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inPractiseRecord)
                        });
}

/*
    RESPONSE
    {
        accountStatusCode: "P"
        countryCode: "61"
        created: 1429143960000
        createdBy: 1
        isBatchMandatory: false
        isHospital: true
        modified: 1610940862033
        modifiedBy: 349
        practiseAddress: "144 Starkey Street"
        practiseAdminEmail: "carolina@c.com"
        practiseCity: "Killarney Heights "
        practiseContactEmail: "carolina@c.com"
        practiseContactName: "Prajakta "
        practiseContactPhone: "0413025975"
        practiseFax: "02 9451 3163"
        practiseId: 1
        practiseLogo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVY
        practiseLogoDark: "https://test-btxinteract-files.s3.amazonaws.com/1/1_LogoDark.png?AWSAccessKeyId=AKIAJBPN3PHQAXPFZ7CA&Expires=1657140265&Signature=C0Ro7cGyZuGupaCDz4RdsufI9ow%3D"
        practiseLogoLight: "https://test-btxinteract-files.s3.amazonaws.com/1/1_LogoLight.png?AWSAccessKeyId=AKIAJBPN3PHQAXPFZ7CA&Expires=1657140265&Signature=Krt7gjFIRAWszW5lugmC%2BoL4UI8%3D"
        practiseName: "Refresh Richmond "
        practisePostcode: "2087"
        practiseTimezoneId: "Australia/Sydney"
        reportType: 0
        statusCode: "A"
    }
*/
function getPractise({inPractiseID} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/practise/' + inPractiseID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*
    RESPONSE
    array of responses like that of getPractice()
*/
function getAllPractises() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/practise/all',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*
    REQUEST
    same as single record from getAllPractises
    {
        "practiseId":"3",
        "statusCode":"A", 
        "accountStatusCode":"P", 
        "practiseName":"HealthInteract", 
        "practiseAddress":"Hurstville,NSW 2220", 
        "practiseCity":"Hurstville", 
        "practisePostcode":"2220", 
        "countryCode":"61", 
        "practiseLogo":"http"://sfssfa.jpg", 
        "practiseFax": "3232323"
        "practiseContactName":"leo chen", 
        "practiseContactPhone":"0430142735", 
        "practiseContactEmail":"cenling40cloud@gmail.com", 
        "isHospital":true, 
        "created":"Thu Jan 22 15:30:14 EST 2015", 
        "createdBy":1
    }

    RESPONSE
    Responses like that of getPractice()
*/
function updatePractise({inPractiseRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/practise',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inPractiseRecord)
                        });
}

/*
    RESPONSE

    { 
       "responseCode" : 0,
        "responseMsg" : "Practise has been deleted."
    }
*/
function deletePractise({inPractiseID,
                        inHardDelete = false
                        } = {}) {
    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/practise/' + inPractiseID,
                        inRequireAuth: true,
                        inQueryParams: { hard : inHardDelete ? 'true' : 'false' },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



/*
    response array of dicts: (BUT ONLY PHYSICIANS user type)

        {
            "userId" : 1,
            "statusCode" : "A",  
            "practiseId" : 1,
            "userName" : "Aaron McMullen",
            "userEmail" : "a@a.com",
            "userEmailValidated" : "Y",
            "userIsAccountLocked" : "N",
            "userLastLogin" : 1391980356929,
            "userRoleCodes" : "[P]",
            "userSignatureFile" : "nfkas/sadgfsd/fsdf.jpg",
            "createdBy" : 1,
            "created" : 1391980356929,
            "modifyBy" : 1,
            "modified" : 1391980356929
        }

*/
function getAllTreatingPhysicians() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/practise/treatingPhysicians',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inCacheKey: "getAllTreatingPhysicians"
                        });
}



/*
    whitelist record
    REQUEST
    {
        "practiseId": 3,
        "ipAddress": "192.168.90.200"
    }

    RESPONSE
    {
        "ipWhiteListId" : 1
        "practiseId": 3,
        "ipAddress": "192.168.90.200",
        "created":"Thu Jan 22 15:30:14 EST 2015", 
        "createdBy":1
    }
*/
function createIpWhiteList({inIPWhitelistRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/practise/ipWhiteList',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inIPWhitelistRecord)
                        });
}

/*
    whitelist record
    REQUEST
    {
        "ipWhiteListId" : 1,
        "practiseId" : 3,
        "ipAddress" : "192.168.90.100"
    }

    RESPONSE
    {
        "ipWhiteListId" : 1
        "practiseId": 3,
        "ipAddress": "192.168.90.100",
        "created":"Thu Jan 22 15:30:14 EST 2015", 
        "createdBy":1,
        "modified":"Thu Jan 23 15:30:14 EST 2015",
        "modifiedBy":1
    }
*/
function updateIpWhiteList({inIPWhitelistRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/practise/ipWhiteList',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inIPWhitelistRecord)
                        });
}

/*

    RESPONSE
    {
        "responseCode" : 0,
        "responseMsg" : "The Ip address successfully deleted in the practise"
    }
*/
function deleteIpWhiteList({inIPWhitelistID} = {}) {
    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/practise/ipWhiteList/' + inIPWhitelistID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*

    RESPONSE
    [
        {
            "ipWhiteListId" : 1
            "practiseId": 3,
            "ipAddress": "192.168.90.100",
            "created":"Thu Jan 22 15:30:14 EST 2015", 
            "createdBy":1,
            "modified":"Thu Jan 23 15:30:14 EST 2015",
            "modifiedBy":1
        },
        {
            "ipWhiteListId" : 2
            "practiseId": 3,
            "ipAddress": "192.168.90.103",
            "created":"Thu Jan 22 15:30:14 EST 2015", 
            "createdBy":1,
            "modified":"Thu Jan 23 15:30:14 EST 2015",
            "modifiedBy":1
        }
    ]

    TOCHECK
*/
function searchIpWhiteListInPractise() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/practise/ipWhiteLists',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST
    {
        "practiseId": 1,
        "firstName": "leo",
        "lastName": "chen", 
        "physicianEmail" : "a@a.com"
        "physicianAddress" : "avsads"
        "countryCode" : "AU"
        "stateCode" : "NSW"
        "city" : "Sydney"
        "suburb" : "Allawah"
        "postcode" : "2218",
        "street" : "mona street"
        "physicianFax" : ""
        "physicianPhone" : ""
    }

    RESPONSE
    {
        "referringPhysicianId" : 1
        "practiseId": 1,
        "firstName": "leo",
        "lastName": "chen", 
        "physicianEmail" : "a@a.com"
        "physicianAddress" : "avsads"
        "countryCode" : "AU"
        "stateCode" : "NSW"
        "city" : "Sydney"
        "suburb" : "Allawah"
        "postcode" : "2218",
        "street" : "mona street"
        "physicianFax" : ""
        "physicianPhone" : ""
        "created":"Thu Jan 22 15:30:14 EST 2015", 
        "createdBy":1
    }
*/
function createReferringPhysician({inReferringPhysicianRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/practise/referringPhysician',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inReferringPhysicianRecord),

                        inClearCacheKey: 'searchReferringPhysiciansInPractise'
                        });
}


/*
    REQUEST
    {
        "practiseId": 1,
        "firstName": "leo",
        "lastName": "chen", 
        "physicianEmail" : "a@a.com", 
        "referringPhysicianId" : 1
        "physicianAddress" : "avsads"
        "countryCode" : "AU"
        "stateCode" : "NSW"
        "city" : "Sydney"
        "suburb" : "Allawah"
        "postcode" : "2218",
        "street" : "mona street"
        "physicianFax" : ""
        "physicianPhone" : ""
    }

    RESPONSE
    {
        "referringPhysicianId" : 1
        "practiseId": 1,
        "firstName": "leo",
        "lastName": "chen", 
        "physicianEmail" : "b@a.com"
        "physicianAddress" : "avsads"
        "countryCode" : "AU"
        "stateCode" : "NSW"
        "city" : "Sydney"
        "suburb" : "Allawah"
        "postcode" : "2218",
        "street" : "mona street"
        "physicianFax" : ""
        "physicianPhone" : ""
        "created":"Thu Jan 22 15:30:14 EST 2015", 
        "createdBy":1,
        "modified":"Thu Jan 22 15:30:14 EST 2015", 
        "modifiedBy":1
    }
*/
function updateReferringPhysician({inReferringPhysicianRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/practise/referringPhysician',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inReferringPhysicianRecord),

                        inClearCacheKey: 'searchReferringPhysiciansInPractise'
                        });
}

/*
    

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "The Referring Physician successfully deleted in the practise"
    }
*/
function deleteReferringPhysician({inReferringPhysicianID} = {}) {
    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/practise/referringPhysician/' + inReferringPhysicianID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inClearCacheKey: 'searchReferringPhysiciansInPractise'
                        });
}

/*
    

    RESPONSE
    Array of dicts
    {
        created: 1488594345267
        createdBy: 349
        firstName: ""
        lastName: "asdghj"
        modified: 1622517073146
        modifiedBy: 66
        physicianAddress: ""
        physicianEmail: ""
        physicianFax: ""
        physicianPhone: ""
        practiseId: 1
        referringPhysicianId: 3148
        title: "Associate Prof."
    }

    TOCHECK
*/
function searchReferringPhysiciansInPractise() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/practise/referringPhysicians',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inCacheKey: "searchReferringPhysiciansInPractise"
                        });
}

/*
    RESPONSE:

    {
        "referringPhysicians": [
            {
                "referringPhysicianId": 3213,
                "practiseId": 62,
                "title": "",
                "firstName": "Abbey",
                "lastName": "Abbey",
                "physicianAddress": null,
                "countryCode": null,
                "stateCode": null,
                "city": null,
                "suburb": null,
                "postcode": null,
                "street": null,
                "physicianEmail": null,
                "physicianFax": null,
                "physicianPhone": null,
                "status": "A",
                "practiseName": null,
                "streetAddress": null,
                "created": 1488559491489,
                "createdBy": 66,
                "modified": null,
                "modifiedBy": null
            }
        ],
        "totalCount": 1
    }
*/
function searchReferringPhysiciansInPractiseNoCache({
                                    inSearchFirstnameString = "",
                                    inSearchLastnameString = "",
                                    inStart = 0,
                                    inLimit = 100,
                                    inExport = false,
                                    inExportFormat = 'json'     // 'json' or 'csv'
                                } = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/practise/referringPhysicians',
                        inRequireAuth: true,
                        inQueryParams: {
                            firstName : inSearchFirstnameString,
                            lastName : inSearchLastnameString,
                            start : inStart,
                            limit : inLimit,
                            version : 2,
                            export : inExport,
                            format : inExportFormat,
                            includePatientCount : inExport ? true : false
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',
                        inResponseType : inExportFormat == 'json' ? 'json':'text',
                        inSilenceResponseDebug : inExport ? true : false
                        });
}


/*
    REQUEST
    {
        "pipName" : "fdsfsd"
        "practiseId" : 1573
        "sessionId" :  1
        "conditionId" :  1   
    }

    RESPONSE
    {
        conditionId: 3
        created: 1627620516846
        createdBy: 63
        pipId: 42
        pipName: "peter 2"
        practiseId: 1
        sessionId: 1574
    }

    NOTE: backend will snapshot the session used to define the protocol, then create a phantom session for use only
    for the protocol.
*/
function createPractiseInjectionProtocol({inPractiseInjectionProtocolRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/practise/practiseInjectionProtocol',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inPractiseInjectionProtocolRecord)
                        });
}


/*
    REQUEST
    {
        "pipId" : 1,
        "pipName" : "fdsfsd"
        "practiseId" : 1
        "sessionId" :  1
        "conditionId" :  1
        "created":"Thu Jan 22 15:30:14 EST 2015", 
        "createdBy":1
    }

    RESPONSE
    {
        "practiseInjectionProtocolId" : 1
        "practiseInjectionProtocolName": "fsdfsdfsdf",
        "practiseId": 1,
        "sessionId": 1, 
        "conditionId" : 1,
        "created":"Thu Jan 22 15:30:14 EST 2015", 
        "createdBy":1,
        "modified":"Thu Jan 22 15:30:14 EST 2015", 
        "modifiedBy":1
    }
*/
function updatePractiseInjectionProtocol({inPractiseInjectionProtocolRecord} = {}) {
    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/practise/practiseInjectionProtocol',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inPractiseInjectionProtocolRecord)
                        });
}

/*
    

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "The Injection Protocol successfully deleted in the practise"
    }
*/
function deletePractiseInjectionProtocol({inPractiseInjectionProtocolID} = {}) {
    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/practise/practiseInjectionProtocol/' + inPractiseInjectionProtocolID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*
    

    RESPONSE
    [
        {
            conditionId: 4
            created: 1496273588536
            createdBy: 64
            pipId: 19
            pipName: "167"
            practiseId: 1
            sessionId: 1181
        }
    ]

    TOCHECK
*/
function searchInjectionProtocolsInPractise() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/practise/practiseInjectionProtocols',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}




function createPractisePref({inPrefKey, inPrefValue} = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/practise/pref',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify({ 'key' : inPrefKey, 'value' : inPrefValue})
                        });
}

/* 
    RESPONSE:

    {
        "key":"_btxw.required-patient-dob",
        "value":"0"
    }

    OR standard error response payload

    This API will fetch the pref for the key, if it exists the value will be returned.
    If the key is not in DB, and if the defaultValue is not specified, then an error with 'key not found' is returned
    otherwise if a defaultValue is supplied, the key is created in the DB with the value of defaultValue and the normal response
    is returned.

    This pattern, allows for a clean client side code to be written.

    _btxw => btx web, _btxm => btx mobile


    Valid Keys:
    _btxw.default-mandatory-patient-dob                      "0" | "1"
    _btxw.default-mandatory-patient-referrer-physician       "0" | "1"
    _btxw.default-date-input-format                          "dd-MM-yyyy" (default if not set), other possible values
                                                             "MM-dd-yyyy"
                                                             "yyyy-MM-dd" 

*/
function getPractisePref({inPrefKey, inDefaultValue = null} = {}) {

    var requestPayload = {}

    requestPayload['key'] = inPrefKey

    if (inDefaultValue != null) {
        requestPayload['defaultValue'] = inDefaultValue
    }

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/practise/pref',
                        inRequireAuth: true,
                        inQueryParams: requestPayload,
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

function updatePractisePref({inPrefKey, inPrefValue} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/practise/pref',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify({ 'key' : inPrefKey, 'value' : inPrefValue})
                        });
}

function deletePractisePref({inPrefKey} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/practise/pref',
                        inRequireAuth: true,
                        inQueryParams: {
                            key : inPrefKey
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}











module.exports = { 
    registerPractise,

    createPractise,
    getPractise,
    getAllPractises,
    updatePractise,
    deletePractise,

    getAllTreatingPhysicians,

    createIpWhiteList,
    updateIpWhiteList,
    deleteIpWhiteList,
    searchIpWhiteListInPractise,

    createReferringPhysician,
    updateReferringPhysician,
    deleteReferringPhysician,
    searchReferringPhysiciansInPractise,
    searchReferringPhysiciansInPractiseNoCache,

    createPractiseInjectionProtocol,
    updatePractiseInjectionProtocol,
    deletePractiseInjectionProtocol,
    searchInjectionProtocolsInPractise,


    createPractisePref,
    getPractisePref,
    updatePractisePref,
    deletePractisePref,

};
