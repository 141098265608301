<template>
    <div id="practice-reception" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
         <Drawer
            v-model="showDetails"
            width="500"
            :mask-closable="false"
            :styles="detailsDrawerStyles">

            <p slot="header"><center>Patient Details</center></p>

            <Row :gutter="20">
                <Form ref="editEntryDetailsFormFields" :model="editEntryDetails" :rules="editEntryDetailsFieldRules" :label-width="130" label-position="right">
                    <FormItem label="Title:">
                        <Select v-model="editEntryDetails.title" style="width:200px" filterable>
                            <Option v-for="item in physicianTitlesRefData" :value="item.title" :key="item.title">{{ item.title }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="Firstname:" prop="firstName" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.firstName" ></Input>
                    </FormItem>
                    <FormItem label="Lastname:" prop="lastName" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.lastName" ></Input>
                    </FormItem>
                    <FormItem label="Date of Birth:" prop="dateOfBirth" :class="mandatoryFieldsStyle">
                        <DatePicker v-model="editEntryDetails.dateOfBirth" ref="dateOfBirth" type="date" :options="allowOnlyPastDatesOptions" placeholder="YYYY-MM-DD" style="width: 200px"></DatePicker>
                    </FormItem>

                    <FormItem label="Status:">
                        <Select v-model="editEntryDetails.status" style="width:200px" filterable>
                            <Option v-for="item in statusRefData" :value="item.statusCode" :key="item.statusCode">{{ item.statusName }}</Option>
                        </Select>
                    </FormItem>
                    

                    <FormItem label="Next Appointment:" prop="nextAppointment" >
                        <DatePicker v-model="editEntryDetails.nextAppointment" ref="nextAppointment" type="date" :options="allowOnlyFutureDatesOptions" placeholder="YYYY-MM-DD" style="width: 200px"></DatePicker>
                    </FormItem>
                </Form>
                <ButtonGroup style="margin:2px 8px 0px 130px;">
                    <Button @click="editEntryDetails.flag1 = editEntryDetails.flag2 = editEntryDetails.flag3 = ''">All</Button>
                    <Button :type="editEntryDetails.flag1 == '1' ? 'primary' : 'default'" @click="editEntryDetails.flag1 = (editEntryDetails.flag1 == '1' ? '' : '1')"><font-awesome-icon :icon="['fal', 'flag']" size="lg"  fixed-width /></Button>
                    <Button :type="editEntryDetails.flag2 == '1' ? 'primary' : 'default'" @click="editEntryDetails.flag2 = (editEntryDetails.flag2 == '1' ? '' : '1')"><font-awesome-icon :icon="['fal', 'flag-checkered']" size="lg" fixed-width /></Button>
                    <Button :type="editEntryDetails.flag3 == '1' ? 'primary' : 'default'" @click="editEntryDetails.flag3 = (editEntryDetails.flag3 == '1' ? '' : '1')"><font-awesome-icon :icon="['fal', 'flag-pennant']" size="lg" fixed-width /></Button>
                </ButtonGroup>

            </Row>

            <div class="drawer-footer">
                <Button style="margin-right: 8px" @click="handleCancelEntryDetails">Cancel</Button>
                <Button type="primary" :loading="savingDetailsToBackend" @click="handleSaveEntryDetails">
                    <span v-if="!savingDetailsToBackend">Save</span>
                    <span v-else>Saving...</span>
                </Button>
            </div>
        </Drawer>
        
        
        <Divider orientation="left">Patient Management <span style="font-weight:200">({{ entriesTotalCount }} patients)</span>
            <!-- <Tooltip placement="bottom">
                <Icon type="md-help-circle" color="#4287f5" />
                <div slot="content" style="white-space: normal">
                    <p>This section allows you to manage all the patients you have at your practice.</p>
                    <br />
                    <p>Patients are generally created/edited via the Patients section. This section is primarily used to manage whether a user is to be shown in the Patients section.</p>
                    <br />
                    <p>Since patients may move away, pass away or become unavailable, you may not want them to show in the Patients section to reduce showing unnecessary information. In order to remove them from display, simply edit an entry here and change their status to whatever is appropriate.</p>
                    <br />
                    <p>NOTE: <br />
                        &bull; Patients can only be deleted if they have not had any sessions associated with them, otherwise they will be made Inactive. <br />
                        &bull; Set a patient status to Training, if you are using for training purposes, and do not with the injection data to pollute your practice reports. <br />
                    </p>
                </div>
            </Tooltip> -->
        </Divider>
        
        <Row :gutter="8" >
            <!-- <Button @click="handleAddEntry" style="margin-right:8px">Add Entry</Button> -->
            <Button @click="handleEditEntry" style="margin-left:8px; margin-right:8px">Edit Entry</Button>

            <!-- <Poptip
                confirm transfer
                width="300"
                :title="confirmRemoveEntryMessage"
                ok-text="Yes"
                cancel-text="No"
                @on-ok="handleRemoveEntry">
                
                <Button type="error" style="margin-right:8px">Remove Entry</Button>
            </Poptip> -->

            <ButtonGroup style="margin-left:4px; margin-right:8px">
                <Button @click="flag1 = flag2 = flag3 = false">All</Button>
                <Button :type="flag1 ? 'primary' : 'default'" @click="flag1 = !flag1"><font-awesome-icon :icon="['fal', 'flag']" size="lg"  fixed-width /></Button>
                <Button :type="flag2 ? 'primary' : 'default'" @click="flag2 = !flag2"><font-awesome-icon :icon="['fal', 'flag-checkered']" size="lg" fixed-width /></Button>
                <Button :type="flag3 ? 'primary' : 'default'" @click="flag3 = !flag3"><font-awesome-icon :icon="['fal', 'flag-pennant']" size="lg" fixed-width /></Button>
            </ButtonGroup>
        
            <!-- <Input v-model="filterFirstnameBy" placeholder="Search by firstname." :clearable="true" style="width: 200px; margin-right:8px" />
            <Input v-model="filterLastnameBy" placeholder="Search by lastname." :clearable="true" style="width: 200px; margin-right:8px" /> -->
            <Input v-model="filterByFirstname" placeholder="First name" :clearable="true" @on-change="throttledSearch" style="width: 300px; margin-right: 8px" />
            <Input v-model="filterByLastname" placeholder="Last name" :clearable="true"  @on-change="throttledSearch" style="width: 300px; margin-right: 8px" />

            <Checkbox v-model="showAllStatusPatients" style="margin-top:6px; margin-right:8px">Include All Status Patients</Checkbox>
            
            <Drawer
                v-model="showImportHelp"
                width="500"
                :styles="detailsDrawerStyles">
                    <p slot="header"><center>Import Help</center></p>

                    <div style="margin-left: 20px; margin-right: 20px;">
                            You can bulk import your patients by populating the contact's CSV template file defined below and then using the 'Import…' button<br />
                            <br />
                            <a target="_blank" download :href="patientImportTemplateURL">patients_template.csv</a><br />
                            <br />
                            <b>Patients import guide:</b><br />
                            <br />
                            <ul id=patientImportGuide>
                                <li>Columns can appear in any order but <span class=“underline”>must</span> have the correct column names</li>
                                <li>Only the MASTER_PATIENT_ID, FIRST_NAME and LAST_NAME columns are <span class=“underline”>mandatory</span>, all others are <span class=“underline”>optional</span></li> 
                                <li>Column names cannot be changed, and adding new columns or removing existing columns are not allowed</li> 
                                <li>No duplicate values are permitted for MASTER_PATIENT_ID</li> 
                                <li>All fields have a maximum field length which if exceeded will cause errors upon import. See below for field length limits: <br/>
                                    &raquo; <strong>MASTER_PATIENT_ID</strong> (50) (This can represent the unique patient ID from your existing practice software/system)<br/>
                                    &raquo; <strong>TITLE</strong> (50) (eg Mr., Mrs., Miss., Master., Dr., Prof., Assocciate Prof.)<br/>
                                    &raquo; <strong>FIRST_NAME</strong> (50)<br/>
                                    &raquo; <strong>LAST_NAME</strong> (50)<br/>
                                    &raquo; <strong>SEX</strong> (10) (M for male, F for female, N for Non-binary, D	for Gender Diverse, T for Transgender, O for Other)<br/>
                                    &raquo; <strong>PATIENT_EMAIL</strong> (255)<br/>
                                    &raquo; <strong>DATE_OF_BIRTH</strong> (30) (Format should be one of: YYYY-MM-DD, DD-MM-YYYY, DD/MM/YYYY, MM/DD/YYYY, MM/DD/YY, DD/MM/YY)<br/>
                                    &raquo; <strong>OCCUPATION</strong> (100)
                                </li>
                            </ul>

                    </div>
            </Drawer>

            <!-- <Dropdown @on-click="handleImportMenu">
                <Button type="primary">
                    Tools
                    <Icon type="ios-arrow-down"></Icon>
                </Button>
                
                <DropdownMenu slot="list">
                    <DropdownItem name="import-now">
                         <Upload
                            multiple
                            name='uploadedFile'
                            :action="uploadActionURL"
                            :data="uploadPatientImportCSVData"
                            :before-upload="handleBeforeUploadCSV"
                            :format="['csv']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-exceeded-size="handleMaxSize"
                            :on-success="handleSuccess"
                            :on-error="handleError"
                            >
                            Import…
                        </Upload>
                    </DropdownItem>
                    <DropdownItem name="import-help">Import Help</DropdownItem>
                </DropdownMenu>
            </Dropdown> -->
        </Row>

        <Row style="margin-top:8px">
            <Table style="width:100%" :height="tableHeightToFit" size="default" draggable :columns="entriesTableColumnsConfig" :data="entriesToDisplay" border highlight-row @on-current-change="handleEntriesSelectionChanged" @on-row-dblclick="handleEditEntry">
                <template slot-scope="{ row }" slot="date"> {{ row.dateOfBirth | userFriendlyDateFromDateString }} </template>
                <template slot-scope="{ row }" slot="condition"> {{ row.patientConditionIds | conditionsAsString }} </template>
                <template slot-scope="{ row }" slot="status"> {{ row.status | userFriendlyPatientStatus }}</template>
                <template slot-scope="{ row }" slot="nextAppointment"> {{ row.nextAppointment | userFriendlyDate }} </template>

                <!-- <template slot-scope="{ row }" slot="logo">
                    <img v-if="row.practiseLogo != ''" :src="row.practiseLogo" height="50px" />
                </template> -->
            </Table>
        </Row>                                        

       
        <Row style="margin-top:8px">
            <Col span="24" class="ivu-text-right">
                <Page :total="entriesTotalCount" :page-size="entriesListPageSize" :page-size-opts="[20,100,200,500,1000]" show-sizer @on-change="handleLoadEntriesListToNthPage" @on-page-size-change="handlePageLimitChanged" />
            </Col>
        </Row>


        <!-- <input type="search" id="addressFinder"  /> -->
        
        <Input element-id="addressFinder" :search="true" placeholder="Search address here..." />
        <Input v-model="addressFinderMetadata.address_line_1" />
        <Input v-model="addressFinderMetadata.address_line_2" />
        <Input v-model="addressFinderMetadata.locality_name" />
        <Input v-model="addressFinderMetadata.state_territory" />
        <Input v-model="addressFinderMetadata.postcode" />
       
    </div>
</template>

<script>
const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
var _ = require('lodash');
import moment from 'moment'

import AnalyticsMgr from '@/js/AnalyticsManager.js';

const {itBnTx} = require('@/js/itBnTx')



export default {
    name: 'practice-reception',
    components: {

    },

    created () {

        // create a throttler function which will automatically manage throttling search requests to a limit, currently 1 per however many milliseconds
        this.throttledSearch = _.debounce(() => { this.handleLoadEntriesListToNthPage(1); console.log("handleThrottledSearch 600ms") }, 600, { 'leading': false })

    },

    destroyed() {

    },

    mounted() {
        // https://codepen.io/addressfinder/pen/ErXXax
        this.downloadAddressFinder()

    },


    data () {
            return {
                flag1 : false,
                flag2 : false,
                flag3 : false,
                
                addressFinderMetadata : {
                    address_line_1 : "a",
                    address_line_2 : "b",
                    locality_name : "c",
                    state_territory : "d",
                    postcode : "e",
                },


                physicianTitlesRefData: CONFIG.PATIENT_TITLES,

                statusRefData : [
                    { statusCode : 'A', statusName: 'Active'},
                    { statusCode : 'T', statusName: 'Training Entry'},
                    { statusCode : 'M', statusName: 'Moved Away'},
                    { statusCode : 'D', statusName: 'Deceased'},
                    { statusCode : 'I', statusName: 'Inactive'},
                ],


                entriesTableColumnsConfig: [
                    {                      title: 'Title',                key: 'title', resizable: true, sortable: false, width: 130 },
                    {                      title: 'First Name',           key: 'firstName', resizable: true, sortable: false, width: 220 },
                    {                      title: 'Last Name',            key: 'lastName', resizable: true, width: 220 },
                    { slot: 'date',        title: 'Date Of Birth',        key: 'dateOfBirth', resizable: true, width: 150 },
                    { slot: 'condition',   title: 'Conditions',           key: 'conditions', resizable: true, minWidth: 100 },
                    { slot: 'nextAppointment', title: 'Next Appointment', key: 'nextAppointment', resizable: true, width: 150 },
                    // {                      title: 'Referring Physician',  key: 'referringPhysician', resizable: true, width: 300 },
                    // { slot: 'status',      title: 'Status',               key: 'status', resizable: true, width: 150 },
                ],

                throttledSearch : () => {},
                filterByFirstname: "",
                filterByLastname: "",

                entriesListIsLoading : false,
                entriesListPageSize : 20,
                entriesTableData: [],

                entriesTotalCount : 0,
                
                editEntryDetails: {
                                        // practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                                        // firstName: "",
                                        // lastName: "",
                                        // physicianAddress: "",
                                        // physicianPhone: "",
                                        // status: "A",
                                    },
                
                allowOnlyFutureDatesOptions : {
                    disabledDate (date) {
                        return date && date.valueOf() < (Date.now() - 60*60*24*1000);
                    }
                },

                allowOnlyPastDatesOptions : {
                    disabledDate (date) {
                        return date && date.valueOf() > Date.now();
                    }
                },
                savingDetailsToBackend : false,



                liveValidating: true,
                liveValidatingFieldsOK: true,
                editEntryDetailsFieldRules : {
                    firstName: [
                        { required: true, message: 'Firstname cannot be empty', trigger: 'blur' },
                    ],
                    lastName: [
                        { required: true, message: 'Lastname cannot be empty', trigger: 'blur' },
                    ],
                    dateOfBirth: [
                        { required: true, type: 'date', message: 'Date of Birth is required', trigger: 'blur' },
                    ],
                },



                filterFirstnameBy : '',
                filterLastnameBy : '',
                showAllStatusPatients : false,


                isCreatingNewEntry : false,
                selectedEntryDetailsBeforeCreatingNewEntry : {},      // this is used to restore the previous selection when user cancels create new entry.

                showDetails: false,
                detailsDrawerStyles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static'
                },


                showImportHelp : false,

                uploadPatientImportCSVData : {
                    practiseId : 0,
                    authToken : '',
                    importType : "Patient", // "Patient", "ReferringPhysician"
                },
            }
        },

    filters: {

    },
    
    computed: {

        // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
        mandatoryFieldsStyle() { return (this.liveValidating && (this.liveValidatingFieldsOK == false)) ? "revealValidationMessages" : "" },

        entriesToDisplay() {

            return this.entriesTableData.filter(entry => {

                if (this.showAllStatusPatients == false && (entry.status != 'A' && entry.status != 'T')) return false   // show Active only or everyone.

                // if (entry.firstName == undefined && this.filterFirstnameBy != '') return false  // if we are searching firstname, but entry firstname if not defined or empty, we are definitely not a match.
                // if (entry.lastName == undefined && this.filterLastnameBy != '') return false  // if we are searching firstname, but entry firstname if not defined or empty, we are definitely not a match.


                // // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                // // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                // if (this.filterFirstnameBy != '' && entry.firstName != undefined && entry.firstName.toLowerCase().includes(this.filterFirstnameBy.toLowerCase()) == false) return false // remove all items that dont match our firstname filter
                // if (this.filterLastnameBy != '' && entry.lastName != undefined && entry.lastName.toLowerCase().includes(this.filterLastnameBy.toLowerCase()) == false) return false // remove all items that dont match our lastname filter

                return true
            })

        },

        confirmRemoveEntryMessage() {
            return "Are you sure you wish to remove '" 
                    + this.editEntryDetails.firstName + " "
                    + this.editEntryDetails.lastName + "' from the system?"
        },
        
        tableHeightToFit() {
            return window.innerHeight - 220
        },

        patientImportTemplateURL() { return CONFIG.PATIENT_IMPORT_TEMPLATE_URL() },
        uploadActionURL() { return itBnTx.baseUrl + "/rest/user/upload" },
    },
    methods: {
        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/Administration/PatientManagement');

                this.refreshDataForUI()
            }
        },

        refreshDataForUI() {

            this.handleLoadEntriesListToNthPage(1)

        },
        
        handlePageLimitChanged(inNewLimit) {
            this.entriesListPageSize = inNewLimit       // silly two way binding not working... so have to do this way
            this.handleLoadEntriesListToNthPage(1)
        },


        handlePhysicianSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            this.editEntryDetails = currentRow
        },

        fixupDatesForIview() {
            // iview doco states when using DatePicker with v-model the value has to be a Date()
            // this seems to fix an odd issue where by saving an edited record a second time no longer works (as the type may be somehow changed)
            // PLI @30 Aug 2021 - tweaked again, check for undefined/null and '' to not parse as it result in Invalid Date NaN to show in UI
            //                    This seems to only occur when used in conjuction with the date validator.
            if (this.editEntryDetails != undefined && this.editEntryDetails.dateOfBirth != null && this.editEntryDetails.dateOfBirth != '')
                this.editEntryDetails.dateOfBirth = new Date(this.editEntryDetails.dateOfBirth)
            if (this.editEntryDetails != undefined && this.editEntryDetails.nextAppointment != null && this.editEntryDetails.nextAppointment != '')
                this.editEntryDetails.nextAppointment = new Date(this.editEntryDetails.nextAppointment)
        },

        handleLoadEntriesListToNthPage(inPageNumber) {
            inPageNumber

            var loadOrSearchPromise;

            // show loading spinner
            this.entriesListIsLoading = true

            if (this.filterByFirstname != "" || this.filterByLastname != "" || this.flag1 || this.flag2 || this.flag3) {
                loadOrSearchPromise = itBnTx.searchPatientsByName({
                                                inSearchFirstnameString : this.filterByFirstname,
                                                inSearchLastnameString : this.filterByLastname,
                                                inFlag1 : this.flag1 ? '1' : '',
                                                inFlag2 : this.flag2 ? '1' : '',
                                                inFlag3 : this.flag3 ? '1' : '',
                                                inStart : this.entriesListPageSize * (inPageNumber - 1),
                                                inLimit : this.entriesListPageSize })
            } else {
                loadOrSearchPromise = itBnTx.searchPatientsInPractise({
                                                inStart: this.entriesListPageSize * (inPageNumber - 1),
                                                inLimit: this.entriesListPageSize})
            }

            loadOrSearchPromise.then(response => {
                
                var entries = response.data.patients;

                this.entriesTotalCount = response.data.totalCount

                // entries.map(entry => { if (entry.userLastLogin == undefined) entry['userLastLogin'] = 0 })
                this.entriesTableData = entries;
                
                var foundEntryToHighlight = false

                if (this.editEntryDetails.patientId != undefined) {
                    // if we previously had a selection, try to preserve the same record which was previously selected
                    var previousSelection = this.entriesToDisplay.find(entry => { return entry.patientId == this.editEntryDetails.patientId} )

                    // sanity check... should never happen... unless someone deletes it from another location.
                    if (previousSelection != undefined) {
                        previousSelection["_highlight"] = true
                        this.handleEntriesSelectionChanged(previousSelection, null)
                        foundEntryToHighlight = true
                    }
                }
                
                if (foundEntryToHighlight == false) {
                    // else select the first record if nothing was selected

                    // if we have at least one entry, select the first one.
                    if (this.entriesToDisplay.length != 0) {
                        this.entriesToDisplay[0]["_highlight"] = true
                        this.handleEntriesSelectionChanged(this.entriesToDisplay[0], null)
                    }
                }

            }).finally(() => {
                // hide loading spinner
                this.entriesListIsLoading = false
            })
        },
        
        
        handleEntriesSelectionChanged(currentRow, oldCurrentRow)  {
            oldCurrentRow
            
            this.editEntryDetails = {}
            
            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                return
            }

            if (currentRow.dateOfBirth == undefined || currentRow.dateOfBirth == null)
                delete currentRow.dateOfBirth     // sanity check and add something so the UI does not go bonkers.
            if (currentRow.nextAppointment == undefined || currentRow.nextAppointment == null)
                delete currentRow.nextAppointment     // sanity check and add something so the UI does not go bonkers.

            // clone data so when cancelling an edit session, we can restore the original details in UI
            this.editEntryDetails = _.cloneDeep(currentRow)

            this.fixupDatesForIview()
        },
        
        // handleAddEntry() {
        //     this.liveValidating = false

        //     this.selectedEntryDetailsBeforeCreatingNewEntry = _.cloneDeep(this.editEntryDetails)

        //     this.editEntryDetails = {
        //                                 practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
        //                                 firstName: "",
        //                                 lastName: "",
        //                                 physicianAddress: "",
        //                                 physicianPhone: "",
        //                                 status: "A",
        //                             }
        //     this.$refs['editEntryDetailsFormFields'].resetFields()

        //     this.isCreatingNewEntry = true;
        //     this.showDetails = true

        // },

        handleEditEntry() {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'Patient_Edit')

            this.liveValidating = true

            this.checkFormMandatoryFields()

            this.fixupDatesForIview()

            this.isCreatingNewEntry = false
            this.showDetails = true
        },

        handleRemoveEntry() {
            if (this.editEntryDetails.patientId != undefined) {
                itBnTx.deletePatientWithPatientID({inPatientID : this.editEntryDetails.patientId})
                      .then(response => {
                          response
                        
                          this.refreshDataForUI()

                          this.$Message.success('Successfully removed/made inactive.');
                      })
            }
            
        },


        checkFormMandatoryFields() {
            if (this.liveValidating) {
                this.$refs['editEntryDetailsFormFields'].validate((valid) => {
                    if (valid) {
                        this.liveValidatingFieldsOK = true
                    } else {
                        this.liveValidatingFieldsOK = false
                    }
                })
            }
        },



        handleCancelEntryDetails() {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'Patient_Edit_Cancel')

            if (this.isCreatingNewEntry == false) {
                var previousSelection = this.entriesTableData.find(entry => { return entry.patientId == this.editEntryDetails.patientId} )

                // restore any changes back to what they were since the user cancelled.
                this.editEntryDetails = _.cloneDeep(previousSelection)
            } else {
                this.editEntryDetails = this.selectedEntryDetailsBeforeCreatingNewEntry
            }

            this.fixupDatesForIview()

            this.showDetails = false
        },

        // format a date as YYYY-MM-DD or '' if not defined
        // The backend supports "2016-11-01T13:00:00.000Z" and "2016-11-02"
        // But backend does not handle the time component properly, so will save incorrectly... so have to format as YYYY-MM-DD
        formatAsAPIDate(inDate) {
            return (inDate != undefined && inDate != '') ? moment(inDate).format('YYYY-MM-DD') : ''
        },

        handleSaveEntryDetails() {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'Patient_Edit_Save')

            this.$refs['editEntryDetailsFormFields'].validate((valid) => {
                if (valid) {
                    this.liveValidatingFieldsOK = true

                    var savingDetails = this.editEntryDetails

                    // if we are a new record, make sure to clear the userId so the API knows to create a new record
                    if (savingDetails.patientId == undefined) {
                        delete savingDetails.patientId
                    }
                    
                    savingDetails.dateOfBirth               = this.formatAsAPIDate(savingDetails.dateOfBirth)
                    savingDetails.nextAppointment           = this.formatAsAPIDate(savingDetails.nextAppointment)

                    delete savingDetails._highlight // delete UI key, otherwise API barfs

 //                   console.log("SAVE DETAILS> ", JSON.stringify(this.savingDetails));

                    this.savingDetailsToBackend = true
                    
                    var savingPromise

                    if (this.isCreatingNewEntry == true)
                        savingPromise = itBnTx.createPatient({inPatientDetails : savingDetails })
                    else
                        savingPromise = itBnTx.updatePatient({inPatientDetails : savingDetails })

                    savingPromise
                          .then(response => {
                                response
                                
                                // reload data into UI
                                this.refreshDataForUI()
                                this.showDetails = false
                          })
                          .finally(() => {
                              this.savingDetailsToBackend = false
                          })

                } else {
                    this.liveValidating = true
                    this.liveValidatingFieldsOK = false

                    this.$Message.error('Enter mandatory fields before trying again.');
                }
            })
        },


        handleImportMenu(inMenuName) {
            switch( inMenuName ) {
                case 'import-now':
                    AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'Patient_Import_Select')
                    break;
                
                case 'import-help':
                    AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'click', 'Patient_Import_Help')
                    this.showImportHelp = true
                    break;

            }
        },


        handleBeforeUploadCSV(inFile) {
            inFile

            this.uploadPatientImportCSVData.practiseId = this.$store.state.loggedInDoctorDetails.practiseId
            this.uploadPatientImportCSVData.authToken = this.$store.state.btxAuthToken

            return true
        },

        handleFormatError (file) {
            try { 
                AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'error', 'Patient_Import_WrongFormat_' + file.name.split('.').pop())
            } catch { console.log("oh dear") }

            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select a .csv file'
            });
        },
        handleMaxSize (file) {
            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'error', 'Patient_Import_TooLarge')

            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 2M.'
            });
        },

        handleSuccess (response, file, fileList) {
            response, file, fileList
            // console.log(response)
            // console.log(file)
            // console.log(fileList)

            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'error', 'Patient_Import_Success')

            this.refreshDataForUI()

            this.$Notice.success({
                title: 'Import Successful',
                desc: response.responseMsg,
                duration: 20
            });
        },

        handleError (error, file, fileList) {
            error, fileList
            // console.log(error)
            // console.log(file)
            // console.log(fileList)

            AnalyticsMgr.sendEvent('/Administration/PatientManagement', 'error', 'Patient_Import_Error')

            this.$Notice.error({
                title: 'Error Importing CSV',
                desc: file.responseMsg
            });
        },


        // ==================================
        // ======== Address Finder ==========
        // ==================================
        initAddressFinder() {
            // https://addressfinder.com.au/docs/widget_code_generator/

            /* eslint-disable no-undef */
            var widget = new AddressFinder.Widget(
                                document.getElementById('addressFinder'),
                                CONFIG.ADDRESSFINDER_KEY,
                                'AU', {
                                    // "address_params": {
                                    // // "gnaf" : "1",
                                    // "au_paf" : "1",
                                    // // "post_box" : "1"
                                    // }
                                    "address_metadata_params": {
                                        "gps": "1"
                                    },
                                    "address_params": {
                                        "au_paf": "1"
                                    },
                                    "empty_content": "No addresses were found. This could be a new address, or you may need to check the spelling."
                                }
                            );
            
            var self = this
            widget.on('result:select', function(fullAddress, metaData) {
                fullAddress
                console.log("METADATA: ", metaData, fullAddress)
                self.addressFinderMetadata.address_line_1 = metaData.address_line_1
                self.addressFinderMetadata.address_line_2 = metaData.address_line_2
                self.addressFinderMetadata.locality_name = metaData.locality_name
                self.addressFinderMetadata.state_territory = metaData.state_territory
                self.addressFinderMetadata.postcode = metaData.postcode
            });
        },

        downloadAddressFinder() {
            var script = document.createElement('script');
            script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
            script.async = true;
            script.onload = this.initAddressFinder;
            document.body.appendChild(script);
        },

    },

    watch : {
        "flag1" : function() { this.throttledSearch() },
        "flag2" : function() { this.throttledSearch() },
        "flag3" : function() { this.throttledSearch() },
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
    
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  
.drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;

 }

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}


.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}
</style>

