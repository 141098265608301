
/*
    REQUEST:
    {
        "countryCode": null,
        "stateCode": null,
        "city": null,
        "suburb": null,
        "postcode": null,
        "street": null,
        "status": "A",
        "sName": "golden sponsor",
        "sEmail": "cenling40cloud@gmail.com",
        "sAddress": "full address will be here",
        "sContact": "111111111",
        "sContractId": "abc1111111"
    }
    RESPONSE:

     {
        "countryCode": null,
        "stateCode": null,
        "city": null,
        "suburb": null,
        "postcode": null,
        "street": null,
        "status": "A",
        "created": 1638764279525,
        "createdBy": 1,
        "modified": 1638764281828,
        "modifiedBy": 1,
        "sId" : 12,
        "sName": "golden sponsor",
        "sEmail": "cenling40cloud@gmail.com",
        "sAddress": "full address will be here",
        "sContact": "111111111",
        "sContractId": "abc1111111"
    }
*/
function createSponsor({inSponsorDetails} = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/code/sponsor',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inSponsorDetails)
                        });
}

/*
    REQUEST:
    {
        "countryCode": null,
        "stateCode": null,
        "city": null,
        "suburb": null,
        "postcode": null,
        "street": null,
        "status": "A",
        "sName": "golden sponsor",
        "sEmail": "cenling40cloud@gmail.com",
        "sAddress": "full address will be here",
        "sContact": "111111111",
        "sContractId": "abc1111111"
    }
    OR
    {
        "countryCode": null,
        "stateCode": null,
        "city": null,
        "suburb": null,
        "postcode": null,
        "street": null,
        "status": "A",
        "created": 1638764279525,
        "createdBy": 1,
        "modified": 1638764281828,
        "modifiedBy": 1,
        "sId" : 12,
        "sName": "golden sponsor",
        "sEmail": "cenling40cloud@gmail.com",
        "sAddress": "full address will be here",
        "sContact": "111111111",
        "sContractId": "abc1111111"
    }


    RESPONSE:

     {
        "countryCode": null,
        "stateCode": null,
        "city": null,
        "suburb": null,
        "postcode": null,
        "street": null,
        "status": "A",
        "created": 1638764279525,
        "createdBy": 1,
        "modified": 1638764281828,
        "modifiedBy": 1,
        "sId" : 12,
        "sName": "golden sponsor",
        "sEmail": "cenling40cloud@gmail.com",
        "sAddress": "full address will be here",
        "sContact": "111111111",
        "sContractId": "abc1111111"
    }
*/
function updateSponsor({inSponsorDetails} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/code/sponsor',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inSponsorDetails)
                    });
 
}

/*
    RESPONSE:
    array of

     {
        "countryCode": null,
        "stateCode": null,
        "city": null,
        "suburb": null,
        "postcode": null,
        "street": null,
        "status": "A",
        "created": 1638764279525,
        "createdBy": 1,
        "modified": 1638764281828,
        "modifiedBy": 1,
        "sId" : 12,
        "sName": "golden sponsor",
        "sEmail": "cenling40cloud@gmail.com",
        "sAddress": "full address will be here",
        "sContact": "111111111",
        "sContractId": "abc1111111"
    }
*/
function getAllSponsors() {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/code/sponsors',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                    });
 
}

/*
    REQUEST:
    
    RESPONSE:
    { "responseCode": 0, "responseMsg": "Sponsor successfully deleted." }

*/
function deleteSponsorWithSponsorID({inSponsorID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/code/sponsor/' + inSponsorID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST:
    {
        "cName": "campaign local second",
        "cDesc": "campaign local description optional second",
        "cStartDate": 1634925733812,
        "cEndDate": 1639925733812,
        "cBenefitDuration": 12,
        "sPriceId": "price_1JnA1ZIKIxEKoiJRe53pbIsD",
        "aCodeCountRequested": 100,
        "aCodePrefix": "Campaign best",
        "sponsor": {
            "sName": "golden sponsor",
            "sEmail": "cenling40cloud@gmail.com",
            "sAddress": "full address will be here",
            "sContact": "111111111",
            "sContractId": "abc1111111"
        }
    }
    RESPONSE:

     {
        "sponsor": {
            "countryCode": null,
            "stateCode": null,
            "city": null,
            "suburb": null,
            "postcode": null,
            "street": null,
            "status": "A",
            "created": 1638764279525,
            "createdBy": 1,
            "modified": 1638764279525,
            "modifiedBy": 1,
            "sId": 1,
            "sName": "golden sponsor",
            "sEmail": "cenling40cloud@gmail.com",
            "sAddress": "full address will be here",
            "sContact": "111111111",
            "sContractId": "abc1111111"
        },
        "status": "R",
        "created": 1638764279544,
        "createdBy": 1,
        "modified": 1638764279544,
        "modifiedBy": 1,
        "cId": 1,
        "cName": "campaign local second",
        "cDesc": "campaign local description optional second",
        "cStartDate": 1634925733812,
        "cEndDate": 1639925733812,
        "cBenefitDuration": 12,
        "sPriceId": "price_1JnA1ZIKIxEKoiJRe53pbIsD",
        "sCouponId": null,
        "aCodeCountRequested": 100,
        "aCodePrefix": "Campaign best"
    }


*/
function createCampaign({inCampaignDetails} = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/code/campaign',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inCampaignDetails)
                        });
}

/*
    REQUEST:
    {
        "cName": "campaign local second",
        "cDesc": "campaign local description optional second",
        "cStartDate": 1634925733812,
        "cEndDate": 1639925733812,
        "cBenefitDuration": 12,
        "sPriceId": "price_1JnA1ZIKIxEKoiJRe53pbIsD",
        "aCodeCountRequested": 100,
        "aCodePrefix": "Campaign best",
        "sponsor": {
            "sName": "golden sponsor",
            "sEmail": "cenling40cloud@gmail.com",
            "sAddress": "full address will be here",
            "sContact": "111111111",
            "sContractId": "abc1111111"
        }
    }
    OR
    {
        "sponsor": {
            "countryCode": null,
            "stateCode": null,
            "city": null,
            "suburb": null,
            "postcode": null,
            "street": null,
            "status": "A",
            "created": 1638764279525,
            "createdBy": 1,
            "modified": 1638764279525,
            "modifiedBy": 1,
            "sId": 1,
            "sName": "golden sponsor",
            "sEmail": "cenling40cloud@gmail.com",
            "sAddress": "full address will be here",
            "sContact": "111111111",
            "sContractId": "abc1111111"
        },
        "status": "R",
        "created": 1638764279544,
        "createdBy": 1,
        "modified": 1638764279544,
        "modifiedBy": 1,
        "cId": 1,
        "cName": "campaign local second",
        "cDesc": "campaign local description optional second",
        "cStartDate": 1634925733812,
        "cEndDate": 1639925733812,
        "cBenefitDuration": 12,
        "sPriceId": "price_1JnA1ZIKIxEKoiJRe53pbIsD",
        "sCouponId": null,
        "aCodeCountRequested": 100,
        "aCodePrefix": "Campaign best"
    }


    RESPONSE:

    {
        "sponsor": {
            "countryCode": null,
            "stateCode": null,
            "city": null,
            "suburb": null,
            "postcode": null,
            "street": null,
            "status": "A",
            "created": 1638764279525,
            "createdBy": 1,
            "modified": 1638764279525,
            "modifiedBy": 1,
            "sId": 1,
            "sName": "golden sponsor",
            "sEmail": "cenling40cloud@gmail.com",
            "sAddress": "full address will be here",
            "sContact": "111111111",
            "sContractId": "abc1111111"
        },
        "status": "R",
        "created": 1638764279544,
        "createdBy": 1,
        "modified": 1638764279544,
        "modifiedBy": 1,
        "cId": 1,
        "cName": "campaign local second",
        "cDesc": "campaign local description optional second",
        "cStartDate": 1634925733812,
        "cEndDate": 1639925733812,
        "cBenefitDuration": 12,
        "sPriceId": "price_1JnA1ZIKIxEKoiJRe53pbIsD",
        "sCouponId": null,
        "aCodeCountRequested": 100,
        "aCodePrefix": "Campaign best"
    }

*/
function updateCampaign({inCampaignDetails} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/code/campaign',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inCampaignDetails)
                    });
 
}

/*
    RESPONSE:
    [
        {
            "sponsor": {
                "countryCode": null,
                "stateCode": null,
                "city": null,
                "suburb": null,
                "postcode": null,
                "street": null,
                "status": "A",
                "created": 1638764279525,
                "createdBy": 1,
                "modified": 1638764281828,
                "modifiedBy": 1,
                "sId": 1,
                "sName": "golden sponsor",
                "sEmail": "cenling40cloud@gmail.com",
                "sAddress": "full address will be here",
                "sContact": "111111111",
                "sContractId": "abc1111111"
                },
            "status": "I",
            "created": 1638758931676,
            "createdBy": 1,
            "modified": 1638787214800,
            "modifiedBy": 1,
            "cId": 1,
            "cName": "campaign local second update",
            "cDesc": "campaign local description optional second update",
            "cStartDate": 1634925733812,
            "cEndDate": 1639925733812,
            "cBenefitDuration": 12,
            "sPriceId": "price_1JnA1ZIKIxEKoiJRe53pbIsD",
            "sCouponId": "9CYJyNFR",
            "aCodeCountRequested": 100,
            "aCodePrefix": "Campaign best"
        }
    ]
*/
function getAllCampaigns() {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/code/campaigns',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                    });
 
}

/*
    REQUEST:
    
    RESPONSE:
    { "responseCode": 0, "responseMsg": "Campaign successfully deleted." }


*/
function deleteCampaignWithCampaignID({inCampaignID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/code/campaign/' + inCampaignID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*
    REQUEST:
    
    RESPONSE:
    Array of 

    {
        "status": "U",
        "created": 1642050616385,
        "createdBy": 1,
        "modified": 1642050616385,
        "modifiedBy": 1,
        "clinicName": null,
        "clinicCountryCode": null,
        "clinicState": null,
        "clinicPostcode": null,
        "aCodeId": "Campaign_best_20220113-dD36Nu3X02ksWhAJP34i5",
        "cId": 4,
        "aStartDate": null,
        "aEndDate": null,
        "aClinicId": null
    },


*/
function getActivationCodesForCampaignID({inCampaignID,
                                          inExport = false,
                                          inExportFormat = 'csv'    // csv | json
                                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/code/activation-codes',
                        inRequireAuth: true,
                        inQueryParams: {
                            campaignId : inCampaignID,
                            export : inExport,
                            format : inExportFormat
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',
                        inResponseType : inExportFormat == 'json' ? 'json':'text'
                        });
}



module.exports = { 
    createSponsor,
    updateSponsor,
    getAllSponsors,
    deleteSponsorWithSponsorID,

    createCampaign,
    updateCampaign,
    getAllCampaigns,
    deleteCampaignWithCampaignID,

    getActivationCodesForCampaignID,
    
    
};
