import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    btxAuthToken: "",
    educationToken: "",

    currentPatientID: 0,
    currentSessionID: 0,

    newOrderForSessionID: 0,

    currentPatientSessionsSummary : [],

    currentNavMenuSelectionID: "",
    currentUIContextHelpID: "",

    loggedInPracticeDetails : {},
    loggedInDoctorDetails: {},

    currentSelectedPatientDetails: {},

    justUpdatingAssessmentData: false,  // used to delay the report loading

    defaultDateInputFormat: 'dd-MM-yyyy',
    defaultMandatoryPatientDOB: true,
    defaultMandatoryPatientReferrerPhysician: true,


    checkoutData : {},

    refDataConditions : [],
  },
  getters: {
    // syncUpConsentGiven(state) {
    //   return state.userGivenConsentsArr.some(e => e.consent.consentType.name === "Sync this Directory back to my User Profile" && e.value === "Y");
    // }
  },
  mutations: {
    // showShareDetailsPopup(state, appearAction) {
    //   state.shareDetailsPopup = appearAction;
    // },
    btxAuthToken(state, newToken) {
      state.btxAuthToken = newToken;
    },
    educationToken(state, newToken) {
      state.educationToken = newToken;
    },

    currentPatientID(state, newPatientID) {
      state.currentPatientID = newPatientID;
    },
    currentSessionID(state, newSessionID) {
      state.currentSessionID = newSessionID;
    },

    newOrderForSessionID(state, newSessionID) {
      state.newOrderForSessionID = newSessionID;
    },

    currentPatientSessionsSummary(state, newPatientSessions) {
      state.currentPatientSessionsSummary = newPatientSessions;
    },


    currentNavMenuSelectionID(state, newNavMenuSelectionID) {
      state.currentNavMenuSelectionID = newNavMenuSelectionID;
    },
    currentUIContextHelpID(state, newCurrentUIContextHelpID) {
      state.currentUIContextHelpID = newCurrentUIContextHelpID;
    },


    loggedInPracticeDetails(state, newDetails) {
      state.loggedInPracticeDetails = newDetails;
    },
    loggedInDoctorDetails(state, newDetails) {
      state.loggedInDoctorDetails = newDetails;
    },

    currentSelectedPatientDetails(state, newDetails) {
      state.currentSelectedPatientDetails = newDetails;
    },


    justUpdatingAssessmentData(state, newValue) {
      state.justUpdatingAssessmentData = newValue;
    },
   

    defaultDateInputFormat(state, newValue) {
      state.defaultDateInputFormat = newValue;
    },
    defaultMandatoryPatientDOB(state, newValue) {
      state.defaultMandatoryPatientDOB = newValue;
    },
    defaultMandatoryPatientReferrerPhysician(state, newValue) {
      state.defaultMandatoryPatientReferrerPhysician = newValue;
    },




    checkoutData(state, newDetails) {
      state.checkoutData = newDetails;
    },


    // REF DATA
    refDataConditions(state, newConditions) {
      state.refDataConditions = newConditions;
    }

  },
  actions: {
    // getRefData(context, { url, axiosServices, paramName, paramValue }) {
    //   let params = {};
    //   params[paramName] = paramValue;
    //   return new Promise((resolve, reject) => {
    //     //pass params={isoCode:"AU"} aka ---> ?isoCode=AU
    //     return axiosServices
    //       .get(url, {
    //         params: params
    //       })
    //       .then(res =>
    //         resolve({
    //           results: res.data
    //         })
    //       )
    //       .catch(err =>
    //         reject({
    //           error: err
    //         })
    //       );
    //   });
    // }
  }
});
