<template>
<Card class="shadow clickable">
    <Row @click.native="handleClick()" :gutter="16">
        <Col span="8">
          <div class="image-wrapper">
            <div class="image-bg" v-bind:style="{ backgroundImage: 'url(' + tileIconURL + ')' }"></div>
          </div>
            <br />
        </Col>
        <Col span="16">

            <Row span="24" style="position:relative;">
              <div class="last-modified">
                <strong>
                      {{ lastModified | formatTimeAgo }}
                </strong>
              </div>
            </Row>

            <Row span="24" style="text-align:left; margin-top:10px;" :title="titleTooltip">
              <strong><VueClamp :max-lines="titleLineClampCount"
                                :autoresize="true"
                                v-on:clampchange="handleTitleClampChange">
                        {{ title }}
                      </VueClamp>
              </strong>
            </Row>

            <Row span="24" style="text-align:left;" :title="descriptionTooltip">
              <VueClamp class="description" :max-lines="descriptionLineClampCount"
                        :autoresize="true"
                        v-on:clampchange="handleDescriptionClampChange">
                  {{ description }}
              </VueClamp>
            </Row>
            <Row span="24" style="text-align:left;">
                <Tag v-for="tag in tags" :key="tag">{{ tag }}</Tag>
            </Row>
        </Col>
    </Row>
</Card>
</template>

<script>


export default {
  name: 'SmartlibBasicEntryCell',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },

    description: String,
    
    titleLineClampCount: {
      type: Number,
      required: false,
      default: 1
      // validator: value => ['start', 'middle', 'end'].indexOf(value) !== -1   // todo figure out how to do none|number...
    },
    
    descriptionLineClampCount: {
      type: Number,
      required: false,
      default: 3
    },

    entryID : {
      type: String,
      required: true,
      default: ''
    },

    type: String,

    tileIconURL: String,
    
    entryFileURL: String,
    
    lastModified: Number,

    tags: {
      type: Array,
      required: false,
      default () {
                    return [];
                },
    },

    contentContext: {
      type: String,
      required: false,
      default: ''
    },
    
    clickCallback: {
      type: Function,
      required: false,
      default () {
                    return {};
                }
    }
  },

  data() { return {
      titleClamped: false,
      descriptionClamped: false

    }
  },

  methods: {
    handleClick() {
      this.clickCallback( this.entryFileURL,
                          this.title,
                          this.entryID,
                          this.type,
                          this.contentContext)
    },

    handleTitleClampChange(clamped) {
      this.titleClamped = clamped;
    },

    handleDescriptionClampChange(clamped) {
      this.descriptionClamped = clamped;
    }
  },

  computed: {
    titleTooltip() {
      return this.titleClamped ? this.title : "";
    },

    // intelligently only show tooltip (title) when the displayed text is ellipsis'ed.
    descriptionTooltip() {
      return this.descriptionClamped ? this.description : "";
    } 

  },

  watch : {

    // 'descriptionClamped': function (newVal, oldVal){
    //   newVal  // shut up lint.
    //   oldVal

    //   console.log(newVal, oldVal);
    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.clickable {
  cursor: pointer;
}

/* https://css-tricks.com/almanac/properties/b/box-shadow/ */
.shadow {
  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
  -webkit-box-shadow: 3px 3px 5px 2px rgb(243, 243, 243);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 2px rgb(243, 243, 243);  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 2px rgb(243, 243, 243);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.description{
  color: #4A5B69;
  font-size: 14px;
  font-family: 'Roboto';
}

.image-wrapper {
  background: #F0F0F0;
}

.image-wrapper .image-bg {  
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-size: contain;
  background-position: center center;
}

.last-modified{
  font-size: 11px;
  color: #334155;
  position: absolute;
  right: 0;
  top: -6px;
}


/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
