<template>
    <div id="admin-user-management" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
        <Drawer
            v-model="showDetails"
            width="500"
            :mask-closable="false"
            :styles="detailsDrawerStyles">

            <p slot="header"><center>User Details</center></p>

            <Row :gutter="20">
                <span v-show="isInTrialPeriod" style="padding:20px; color:red">
                    During the Trial Period, you can add as many users as you like. After the trial period every Active (physician) who actively uses the system will add to the subscription count for BnTx Standard plan and higher. BnTx Lite subscription is only allowed for practices with one physician user.
                </span>
                
                <Form ref="editEntryDetailsFormFields" :model="editEntryDetails" :rules="editEntryDetailsFieldRules" :label-width="130" label-position="right">
                    <FormItem label="User name:" prop="userName" :class="mandatoryFieldsStyle">
                        <Input v-model="editEntryDetails.userName" ></Input>
                    </FormItem>
                    <FormItem label="Email:" prop="userEmail" :class="mandatoryFieldsStyle" style="margin-bottom: 16px">
                        <Input v-model="editEntryDetails.userEmail" ></Input>
                    </FormItem>
                    <FormItem label="Roles:" prop="userRoleCodes" :class="mandatoryFieldsStyle">
                        <CheckboxGroup v-model="editEntryDetails.userRoleCodes">
                            <Checkbox v-for="item in rolesRefData" v-show="item.roleCode == 'S' ? (isSuper ? true : false) : true" :key="item.roleCode" :label="item.roleCode">{{ item.roleName }}</Checkbox>
                        </CheckboxGroup>
                    </FormItem>
                    <FormItem label="Account Status:" prop="statusCode" :class="mandatoryFieldsStyle">
                        <Select v-model="editEntryDetails.statusCode" :disabled="isCreatingNewEntry" style="width:200px" filterable>
                            <Option v-for="item in statusRefData" :value="item.statusCode" :key="item.statusCode">{{ item.statusName }}</Option>
                        </Select>
                    </FormItem>

                    <FormItem label="Signature Image:" v-show="editEntryDetails.userRoleCodes.includes('P')">
                        <img :src="selectedEntrySignedSignatureURL" width="200px" /> <br />

                        <Upload
                            ref="signatureUpload"
                            type="drag"
                            name='uploadedFile'
                            :action="uploadActionURL"
                            :data="uploadSignatureData"
                            :before-upload="handleBeforeUploadSignature"
                            :format="['jpg','jpeg','png']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-exceeded-size="handleMaxSize"
                            :on-success="handleSuccess"
                            :on-error="handleError"
                            >
                            <div style="padding: 10px 0">
                                <Icon type="ios-cloud-upload" size="32" style="color: #3399ff"></Icon>
                                <p>Click or drag signature here to upload</p>
                            </div>
                        </Upload>
                        <Row align="middle" v-show="!isCreatingNewEntry">
                            <Col span="12">
                                <a :href="companionURLForSignature" target="bntx-signature"><vue-qrcode :value="companionURLForSignature" :width="180" /></a>
                            </Col>
                            <Col span="12" style="line-height: 14px;">
                                Scan QR on your mobile, then sign on your mobile.
                            </Col>
                        </Row>
                    </FormItem>
                    
                </Form>
            </Row>

            <div class="drawer-footer">
                <Button v-if="!isCreatingNewEntry && editEntryDetails.userEmailValidated=='N'" style="margin-right: 80px" @click="handleResendValidationEmail">
                    <span v-if="!resendingValidationEmail">Resend Validation Email</span>
                    <span v-else>Resending...</span>
                </Button>

                <Button style="margin-right: 8px" @click="handleCancelEntryDetails">Cancel</Button>
                <Button type="success" :loading="savingDetailsToBackend" @click="handleSaveEntryDetails">
                    <span v-if="!savingDetailsToBackend">Save</span>
                    <span v-else>Saving...</span>
                </Button>
            </div>
        </Drawer>

        <!--
            =====================================
            S T O C K   S T A T U S   D R A W E R
            =====================================
        -->
        <Drawer
            v-model="showStockDetails"
            width="500"
            :mask-closable="false"
            :styles="detailsDrawerStyles">

            <p slot="header"><center>User Medication Stock Details</center></p>

            <Row :gutter="20">
                <!-- TAILWINDCSS: class="px-2 pb-2 text-gray-500" -->
                <div v-if="medicationStockDataRaw.length != 0"
                    class=""
                    style="padding-left: 0.5rem/* 8px */;
                            padding-right: 0.5rem/* 8px */;
                            padding-bottom: 0.5rem/* 8px */;
                            color: rgba(107, 114, 128, 1);"
                    >
                    These values represent the stock levels you have in storage, they will be added to and subtracted from when orders are send and reconciled.<br /><br />
                    A summary of stock levels can be seen in the left navigation menu, Practice management > Orders/Co-payments.
                </div>
                
                <!-- TAILWINDCSS: class="px-2 pb-2 text-gray-500 font-medium" -->
                <div v-else
                    class=""
                    style="padding-left: 0.5rem/* 8px */;
                            padding-right: 0.5rem/* 8px */;
                            padding-bottom: 0.5rem/* 8px */;
                            color: rgba(107, 114, 128, 1);
                            font-weight: 500;">
                    Medication Stock is only available for Treating Physicians.
                </div>

                <Form :label-width="230" label-position="right">
                    <FormItem v-for="med in medicationStockDataRaw" :key="med.id" :label="medicationAndUnitsDisplayLabel(med)">
                        <InputNumber v-model="med.vialCount" :min="0" :step="1" :precision="0" @on-blur="updateMedStockOnBlur(med)" />
                    </FormItem>
                    
                </Form>

                
            </Row>

            <div class="drawer-footer">
                <Button style="margin-right: 8px" @click="handleCancelStockDetails">Cancel</Button>
                <Button type="success" :loading="savingStockStatusDetails" @click="handleSaveStockDetails">
                    <span v-if="!savingStockStatusDetails">Save</span>
                    <span v-else>Saving...</span>
                </Button>
            </div>
        </Drawer>



        <Divider orientation="left">User Management 
            <!-- <span style="font-weight:200">
                ({{countOfActivelyUsingPhysicians}} actively using physicians)
            </span> -->

            <Tooltip placement="bottom">
                <Icon type="md-help-circle" color="#4287f5" />
                <div slot="content" style="white-space: normal">
                    <p>Users represent individuals whom login to the system to perform tasks. This includes Treating Physicians, Nurses and Administrators.</p>
                    <br />
                    <p>When a paid subscription is active, a per (physician) user per month fee is charged.</p>
                    <br />
                    <p>A user license is required for every <b><u>Active</u></b> (physician) user who has acceessed the system within a billing period (or in last month if initially starting a subscription).</p>
                    <br />
                    <p>NOTE: Users can only be deleted if they are not associated with any patients (eg Treating Physician), otherwise they will be made Inactive.</p>
                </div>
            </Tooltip>
        </Divider>

        <Row :gutter="8" >
            <Button type="primary" @click="handleAddEntry" style="margin-left:8px; margin-right:8px">Add Entry</Button>
            <Button @click="handleEditEntry" style="margin-right:8px">Edit Entry</Button>
            <Button @click="handleEditStockDetailsForPhysician" style="margin-right:8px">Edit Stock Levels</Button>

            <Poptip
                confirm transfer
                width="300"
                :title="confirmRemoveEntryMessage"
                ok-text="Yes"
                cancel-text="No"
                @on-ok="handleRemoveEntry">
                
                <Button type="error" style="margin-right:8px">Remove Entry</Button>
            </Poptip>

            <Input v-model="filterUsernameBy" placeholder="Search username by..." :clearable="true" style="width: 200px; margin-right:8px" />
            <!-- <Input v-model="filterByFirstname" placeholder="First name" :clearable="true" @on-change="throttledSearch" style="width: 300px; margin-right: 8px" />
            <Input v-model="filterByLastname" placeholder="Last name" :clearable="true"  @on-change="throttledSearch" style="width: 300px" /> -->

            <Checkbox v-model="showInactiveAndDeletedUsers" style="margin-top:6px; margin-right:8px">Include Inactive and Deleted Users</Checkbox>


        </Row>

        <Row style="margin-top:8px">
            <Table style="width:100%" :height="tableHeightToFit" size="default" draggable :columns="entriesTableColumnsConfig" :data="entriesToDisplay" border highlight-row @on-current-change="handleEntriesSelectionChanged" @on-row-dblclick="handleEditStockDetailsForPhysician">
                <template slot-scope="{ row }" slot="status"> {{ row.statusCode | userFriendlyTreatingPhysicianStatus }} </template>
                <template slot-scope="{ row }" slot="lastlogin"><span :title="row.userLastLogin | userFriendlyDateTime"> {{ row.userLastLogin | formatTimeAgo }} </span></template>
                <template slot-scope="{ row }" slot="roles"> {{ row.userRoleCodes != undefined ? row.userRoleCodes.join(', ') : '' }} </template>
                <template slot-scope="{ row }" slot="created"> {{ row.created | userFriendlyDate }} </template>
                <template slot-scope="{ row }" slot="modified"> {{ row.modified | userFriendlyDate }} </template>

            </Table>
        </Row>
        
        <!-- <Row>
            <Col span="12" class="ivu-text-right">
                <Page :total="entriesTableData.totalCount" :page-size="entriesListPageSize" :page-size-opts="[20,100,200,500,1000]" show-sizer @on-change="handleLoadEntriesListToNthPage" @on-page-size-change="handlePageLimitChanged" />
            </Col>
        </Row> -->

        

    </div>
</template>

<script>
//const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
import VueQrcode from 'vue-qrcode'
var qs = require('qs');

import AnalyticsMgr from '@/js/AnalyticsManager.js';

var _ = require('lodash');

const {itBnTx} = require('@/js/itBnTx')



export default {
    name: 'admin-user-management',
    components: {
        VueQrcode,
    },

    created () {

        // create a throttler function which will automatically manage throttling search requests to a limit, currently 1 per however many milliseconds
        this.throttledSearch = _.debounce(() => { this.handleLoadEntryListToNthPage(1); console.log("handleThrottledSearch 600ms") }, 600, { 'leading': false })
    },

    destroyed() {

    },

    mounted() {

        
    },
 

    data () {
            return {
                rolesRefData : [
                    { roleCode : 'P', roleName: 'Physician'},
                    { roleCode : 'N', roleName: 'Nurse/Clerk'},
                    { roleCode : 'PA', roleName: 'Admin'},
                    { roleCode : 'S', roleName: 'Super'},
                ],
                statusRefData : [
                    { statusCode : 'A', statusName: 'Active'},
                    { statusCode : 'P', statusName: 'Pending'},
                    { statusCode : 'I', statusName: 'Inactive'},
                    { statusCode : 'D', statusName: 'Deleted'},
                ],


                entriesTableColumnsConfig: [
                    {                      title: 'User ID',                key: 'userId', resizable: true, sortable: true, width: 100 },
                    { slot: 'status',      title: 'Status Code',            key: 'statusCode', resizable: true, sortable: true, width: 130 },
                    {                      title: 'User Name',              key: 'userName', resizable: true, sortable: true, width: 200 },
                    { /* slot: 'condition', */  title: 'Email',             key: 'userEmail', resizable: true, sortable: true, minWidth: 220 },
                    { slot: 'lastlogin',   title: 'Last Login',             key: 'userLastLogin', resizable: true, sortable: true, width: 150 },
                    { slot: 'roles',       title: 'Role Codes',             key: 'userRoleCodes', resizable: true, sortable: true, width: 150 },
                    {                      title: 'Signature',              key: 'userSignatureFile', resizable: true, width: 150 },
                    {                      title: 'Email Validated',        key: 'userEmailValidated', resizable: true, sortable: true, width: 150 },
                    {                      title: 'Account Locked',         key: 'userIsAccountLocked', resizable: true, sortable: true, width: 150 },
                    {                      title: 'Created By',             key: 'createdBy', resizable: true, sortable: true, width: 150 },
                    { slot: 'created',     title: 'Created',                key: 'created', resizable: true, sortable: true, width: 150 },
                    {                      title: 'Modified By',            key: 'modifiedBy', resizable: true, sortable: true, width: 150 },
                    { slot: 'modified',    title: 'Modified',               key: 'modified', resizable: true, sortable: true, width: 150 },
                ],

                throttledSearch : () => {},
                filterByFirstname: "",
                filterByLastname: "",

                entriesListIsLoading : false,
                entriesListPageSize : 20,
                entriesTableData: [],
                editEntryDetails: {
                                        practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                                        statusCode: "P",
                                        userEmail: "",
                                        userName: "",
                                        userRoleCodes: ["P"],
                                        //userSignatureFile: ""
                                    },
                savingDetailsToBackend : false,


                selectedEntrySignedSignatureURL : "",

                
                liveValidating: true,
                liveValidatingFieldsOK: true,
                editEntryDetailsFieldRules : {
                    userName: [
                        { required: true, message: 'Username cannot be empty', trigger: 'blur' },
                    ],
                    userEmail: [
                        { required: true, message: 'Email cannot be empty', trigger: 'blur' },
                    ],
                    userRoleCodes: [
                        { required: true, type: 'array', min: 1, message: 'A user role is required', trigger: 'blur' },
                    ],
                    statusCode: [
                        { required: true, message: 'Status must be specified', trigger: 'blur' },
                    ],
                },



                showInactiveAndDeletedUsers : false,
                filterUsernameBy : "",


                isCreatingNewEntry : false,
                selectedEntryDetailsBeforeCreatingNewEntry : {},      // this is used to restore the previous selection when user cancels create new entry.


                showStockDetails : false,
                medicationStockDataRaw : [],
                savingStockStatusDetails : false,


                showDetails: false,
                detailsDrawerStyles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static'
                },


                // upload signature
                uploadSignatureData : {
                    practiseId : 0,
                    authToken : '',
                    uploadType : "Signature", //"Signature", "LogoLight", "LogoDark", "Attachment"
                    userId : ''
                },


                companionURLForSignature : '',


                resendingValidationEmail : false,
            }
        },

    filters: {

    },
    
    computed: {
        isSuper() {
            try {
                return this.$store.state.loggedInDoctorDetails.userRoleCodes.includes('S')
            } catch {
                return false
            }
        },

        loggedInUserDetails()             { return this.$store.state.loggedInDoctorDetails },
        isInTrialPeriod()                 { return this.loggedInUserDetails.accountStatus == 'T' },

        // Paid users are represented as Active or Pending users.
        countOfActivelyUsingPhysicians() {
            return this.entriesTableData.filter(entry => (entry.statusCode == 'A' && entry.userRoleCodes != undefined && entry.userRoleCodes.includes("P"))).length
        },

        // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
        mandatoryFieldsStyle() { return (this.liveValidating && (this.liveValidatingFieldsOK == false)) ? "revealValidationMessages" : "" },

        entriesToDisplay() {

            return this.entriesTableData.filter(entry => {
                if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                if (this.filterUsernameBy != '' && entry.userName.toLowerCase().includes(this.filterUsernameBy.toLowerCase()) == false) return false // remove all items that dont match our username filter

                return true
            })

        },

        confirmRemoveEntryMessage() {
            return "Are you sure you wish to remove '" + this.editEntryDetails.userName + "' from the system?\n\nNOTE: This action will not permanently remove the User. You can reactivate it at a later date if necessary."
        },

        uploadActionURL() {
           return itBnTx.baseUrl + "/rest/user/uploadFile"
        },

        tableHeightToFit() {
            return window.innerHeight - 120
        }

    },
    methods: {
        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/Administration/UserManagement');

                this.refreshDataForUI()

                this.$options.sockets.onmessage = (data) => {
                    console.log(data)   // "CMD:SUBU"
                    this.updateUserSignatureImage()
                }
            }
        },

        refreshDataForUI() {

            this.handleLoadEntriesListToNthPage(1)

        },
        
        handlePageLimitChanged(inNewLimit) {
            this.entriesListPageSize = inNewLimit       // silly two way binding not working... so have to do this way
            this.handleLoadEntriesListToNthPage(1)
        },

        handleLoadEntriesListToNthPage(inPageNumber) {
            inPageNumber

            var loadOrSearchPromise;

            // show loading spinner
            this.entriesListIsLoading = true

            // if (this.filterByFirstname != "" || this.filterByLastname != "") {
            //     loadOrSearchPromise = itBnTx.searchPatientsByName({
            //                                     inSearchFirstnameString : this.filterByFirstname,
            //                                     inSearchLastnameString : this.filterByLastname,
            //                                     inStart : this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit : this.entriesListPageSize })
            // } else {
            //     loadOrSearchPromise = itBnTx.searchPatientsInPractise({
            //                                     inStart: this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit: this.entriesListPageSize})
            // }
            loadOrSearchPromise = itBnTx.searchUsersInPractise({ inPractiseID : this.$store.state.loggedInDoctorDetails.practiseId })

            loadOrSearchPromise.then(response => {
                
                var entries = response.data;
                entries.map(entry => { if (entry.userLastLogin == undefined) entry['userLastLogin'] = 0 })
                this.entriesTableData = entries;
                
                var foundEntryToHighlight = false

                if (this.editEntryDetails.userId != undefined) {
                    // if we previously had a selection, try to preserve the same record which was previously selected
                    var previousSelection = this.entriesToDisplay.find(entry => { return entry.userId == this.editEntryDetails.userId} )

                    // sanity check... should never happen... unless someone deletes it from another location.
                    if (previousSelection != undefined) {
                        previousSelection["_highlight"] = true
                        this.handleEntriesSelectionChanged(previousSelection, null)
                        foundEntryToHighlight = true
                    }
                }
                
                if (foundEntryToHighlight == false && this.isCreatingNewEntry == false) {
                    // else select the first record if nothing was selected

                    // if we have at least one entry, select the first one.
                    if (this.entriesToDisplay.length != 0) {
                        this.entriesToDisplay[0]["_highlight"] = true
                        this.handleEntriesSelectionChanged(this.entriesToDisplay[0], null)
                    }
                }

            }).finally(() => {
                // hide loading spinner
                this.entriesListIsLoading = false
            })
        },
        
        
        handleEntriesSelectionChanged(currentRow, oldCurrentRow)  {
            oldCurrentRow
            
            this.editEntryDetails = {}
            
            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                return
            }

            // clone data so when cancelling an edit session, we can restore the original details in UI
            this.editEntryDetails = _.cloneDeep(currentRow)

        },
        
        handleAddEntry() {
            AnalyticsMgr.sendEvent('/Administration/UserManagement', 'click', 'User_Add')

            this.liveValidating = false

            this.selectedEntryDetailsBeforeCreatingNewEntry = _.cloneDeep(this.editEntryDetails)
            this.selectedEntrySignedSignatureURL = ''

            this.editEntryDetails = {
                                        practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                                        statusCode: "P",
                                        userEmail: "",
                                        userName: "",
                                        userRoleCodes: ["P"],
                                        userSignatureFile: ""
                                    }
            this.$refs['editEntryDetailsFormFields'].resetFields()

            this.$refs['signatureUpload'].clearFiles()

            this.isCreatingNewEntry = true;
            this.showDetails = true

        },

        updateUserSignatureImage() {
            if (this.editEntryDetails.userSignatureFile != undefined && this.editEntryDetails.userSignatureFile != "") {
                itBnTx.getSignedURL({ inURL : this.editEntryDetails.userSignatureFile })
                      .then(response => {
                          this.selectedEntrySignedSignatureURL = response.data.responseMsg
                      })
            } else {
                this.selectedEntrySignedSignatureURL = ""
            }
        },

        handleEditEntry() {
            AnalyticsMgr.sendEvent('/Administration/UserManagement', 'click', 'User_Edit')

            this.liveValidating = true

            this.checkFormMandatoryFields()

            this.updateUserSignatureImage()

            // ========================
            // Create QR code for companion app - signature
            // ========================
            itBnTx.getCompanionToken()
                  .then(response => {
                        this.companionURLForSignature = itBnTx.getLocationOrigin()
                                                        + "/#/capture-signature?"
                                                        + qs.stringify({
                                                                a : response.data.companionToken,       // obscured key: authToken
                                                                p : this.editEntryDetails.practiseId,   // obscured key: practiseId
                                                                u : this.editEntryDetails.userId,       // obscured key: userId
                                                            })
                        console.log(this.companionURLForSignature)
                    })
            // ========================

            this.isCreatingNewEntry = false
            this.showDetails = true
        },

        handleEditStockDetailsForPhysician() {

            itBnTx.getMedicineStockForPhysician({inTreatingPhysicianID : this.editEntryDetails.userId})
                  .then(response => {
                        this.medicationStockDataRaw = response.data
                    })
                  .catch(error => {
                        error
                        this.medicationStockDataRaw = []
                  })

            this.showStockDetails = true
        },

        updateMedStockOnBlur(inMedData) {
            // console.log("hi")
            inMedData.vialCount = (inMedData.vialCount == null ? 0 : inMedData.vialCount)

        },

        handleCancelStockDetails() {

            this.showStockDetails = false
        },

        handleSaveStockDetails() {
            console.log(this.medicationStockDataRaw)

            // convert all null into 0 before saving.
            this.medicationStockDataRaw.map((medEntry) => {
                if (medEntry.vialCount == null || medEntry.vialCount == "")
                    medEntry.vialCount = 0
            })

            console.log(this.medicationStockDataRaw)

            itBnTx.updateMedicineStockForPhysician({ inTreatingPhysicianID : this.editEntryDetails.userId,
                                                     inMedicineStockArray : this.medicationStockDataRaw })
                  .then(response => {
                    response

                    this.showStockDetails = false
                  })
                  .finally(() => {
                    this.savingStockStatusDetails = false
                  })

        },

        medicationAndUnitsDisplayLabel(inInjectMedicationStatus) {
            return inInjectMedicationStatus.injectMedicine.name +  " - " + inInjectMedicationStatus.injectMedicine.vialUnits + " units"
        },  

        handleRemoveEntry() {
            AnalyticsMgr.sendEvent('/Administration/UserManagement', 'click', 'User_Remove')

            if (this.editEntryDetails.userId != undefined) {
                itBnTx.deleteUser({inUserID : this.editEntryDetails.userId})
                      .then(response => {
                          response
                        
                          this.refreshDataForUI()

                          this.$Message.success('Successfully removed');
                      })
            }
            
        },


        checkFormMandatoryFields() {
            if (this.liveValidating) {
                this.$refs['editEntryDetailsFormFields'].validate((valid) => {
                    if (valid) {
                        this.liveValidatingFieldsOK = true
                    } else {
                        this.liveValidatingFieldsOK = false
                    }
                })
            }
        },



        handleCancelEntryDetails() {
            AnalyticsMgr.sendEvent('/Administration/UserManagement', 'click', 'User_Edit_Cancel')

            if (this.isCreatingNewEntry == false) {
                var previousSelection = this.entriesTableData.find(entry => { return entry.userId == this.editEntryDetails.userId} )

                // restore any changes back to what they were since the user cancelled.
                this.editEntryDetails = _.cloneDeep(previousSelection)
            } else {
                this.editEntryDetails = this.selectedEntryDetailsBeforeCreatingNewEntry
            }

            this.showDetails = false
        },

        handleSaveEntryDetails() {
            AnalyticsMgr.sendEvent('/Administration/UserManagement', 'click', 'User_Edit_Save')

            this.$refs['editEntryDetailsFormFields'].validate((valid) => {
                if (valid) {
                    this.liveValidatingFieldsOK = true

                    var savingDetails = this.editEntryDetails

                    // if we are a new record, make sure to clear the userId so the API knows to create a new record
                    if (savingDetails.userId == undefined) {
                        delete savingDetails.userId
                    }
                                        
                    delete savingDetails._highlight // delete UI key, otherwise API barfs

 //                   console.log("SAVE DETAILS> ", JSON.stringify(this.savingDetails));

                    this.savingDetailsToBackend = true
                    
                    var savingPromise

                    if (this.isCreatingNewEntry == true)
                        savingPromise = itBnTx.createUser({inUserDetails : savingDetails })
                    else
                        savingPromise = itBnTx.updateUser({inUserDetails : savingDetails })

                    savingPromise
                          .then(response => {
                                response
                                
                                AnalyticsMgr.sendEvent('/Administration/UserManagement', 'api', (this.isCreatingNewEntry) ? 'User_New_Success' : 'User_Edit_Success')

                                // reload data into UI
                                this.refreshDataForUI()
                                this.showDetails = false

                                if (this.isCreatingNewEntry == true) {
                                    this.$Notice.info({
                                        title: 'Attention',
                                        desc: 'User created. Please inform the user to check their email to activate. The activation email is valid for 24 hours only.',
                                        duration: 0
                                    });
                                } else {
                                    this.$Message.success('Details saved.');
                                }
                          })
                          .catch(error => {
                              AnalyticsMgr.sendEvent('/Administration/UserManagement', 'api', ((this.isCreatingNewEntry) ? 'User_New_Error_' : 'User_Edit_Error_') + error.responseCode)
                          })
                          .finally(() => {
                              this.savingDetailsToBackend = false
                          })

                } else {
                    this.liveValidating = true
                    this.liveValidatingFieldsOK = false

                    this.$Message.error('Enter mandatory fields before trying again.');
                }
            })
        },


        handleResendValidationEmail() {
            this.resendingValidationEmail = true

            itBnTx.resendValidateEmail({ inUserID : this.editEntryDetails.userId })
                  .then(response => {
                      this.$Message.info(response.data.responseMsg);
                  })
                  .finally(() => {
                      this.resendingValidationEmail = false
                  })
        },

        handleBeforeUploadSignature(inFile) {

            // while we have access to the input file DOM element object... grab the image data and update the UI.
            // in theory we should do this on success... but we dont have access to the data later.
            var reader = new FileReader()
            reader.onloadend = () => {
                this.selectedEntrySignedSignatureURL = reader.result    // result is a data:image/... URI
            }
            reader.readAsDataURL(inFile);

            // prepare the additional data for uploader
            this.uploadSignatureData.uploadType = "Signature"
            this.uploadSignatureData.practiseId = this.editEntryDetails.practiseId
            this.uploadSignatureData.userId = this.editEntryDetails.userId == undefined ? '' : this.editEntryDetails.userId
            this.uploadSignatureData.authToken = this.$store.state.btxAuthToken

            return true
        },

        handleFormatError (file) {
            try { 
                AnalyticsMgr.sendEvent('/Administration/UserManagement', 'error', 'Upload_Signature_WrongFormat_' + file.name.split('.').pop())
            } catch { console.log("oh dear") }
            
            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
            });
        },
        handleMaxSize (file) {
            AnalyticsMgr.sendEvent('/Administration/UserManagement', 'error', 'Upload_Signature_TooLarge')

            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 2M.'
            });
        },

        handleSuccess (response, file, fileList) {
            AnalyticsMgr.sendEvent('/Administration/UserManagement', 'error', 'Upload_Signature_Success')

            this.refreshDataForUI()
            

            // on successful upload we get the following payload back
            // {responseCode: 0, responseMsg: "1/signature/344.png"}
            // use the responseMsg as the signature key to get the signed URL to show in the updated UI
            if (this.isCreatingNewEntry == false) {
                // signed URL's are only valid for finalised signatures for created users... 
                // thus if we are a user not yet created... or a /tmp/... image key when uploading
                // the signed URL will fail. Thus only do for real users.
                itBnTx.getSignedURL({ inURL : response.responseMsg })
                      .then(response => {
                          this.selectedEntrySignedSignatureURL = response.data.responseMsg
                      })
            } else {
                
                // we should set the his.selectedEntrySignedSignatureURL data from the file's data via FileReader... here... but
                // at this point we dont have access to the input file DOM object...
                // thus we load it earlier in handleBeforeUploadSignature()
            }

            this.editEntryDetails.userSignatureFile = response.responseMsg

            console.log(response)
            console.log(file)
            console.log(fileList)
        },

        handleError (error, file, fileList) {
            AnalyticsMgr.sendEvent('/Administration/UserManagement', 'error', 'Upload_Signature_Error')

            console.log(error)
            console.log(file)
            console.log(fileList)
        },
    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
    
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  
.drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #334155;

 }

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}

.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}

</style>

