

/*
    response array of dicts:

        "conditionId" : 1,
        "conditionName" : "Hemifacial Spasm (Left Side)",
        "created" : 1392848665210,
        "createdBy" : 0

*/
function getAllConditions() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/admin/condition/all',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inCacheKey: "getAllConditions"
                        });
}

/*
    response array of dicts:

    {
        "created" : 1391980356916,
        "createdBy" : 0,
        "sideEffectId" : 1,
        "sideEffectName" : "Weakness"
    }

*/
function getSideEffects() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/admin/sideEffect/all',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inCacheKey: "getSideEffects"
                        });
}

/*
    response array of dicts:

        "created": 1398995101481,
        "createdBy": 0,
        "genderCode": "M",
        "genderName": "Male",
        "genderPossessiveAdjective": "his",
        "genderPronoun": "he"


        https://uxdesign.cc/designing-forms-for-gender-diversity-and-inclusion-d8194cf1f51
        https://www.medicalnewstoday.com/articles/types-of-gender-identity#types-of-gender-identity

        healthengine.com.au - had an gender identity inclusive of below and more.
        Gender	Name	possessive_adjective		gender_pronoun

        M	Male	his	he
        F	Female	her	she
        N	Non-binary	their	their
        D	Gender diverse	their	their
        T	Transgender	their	their
        O	Other	their	their

*/
function getGenders() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/admin/genders',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inCacheKey: "getGenders"
                        });
}


function pingBEURI({    inURI,
                        inAPIKey} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: inURI,
                        inRequireAuth: false,
                        inQueryParams: {},
                        inAdditionalHeaders: { 'x-api-key' : inAPIKey },
                        inBodyPayloadData: ''
                        });
}

module.exports = { 
    getAllConditions,
    
    getSideEffects,

    getGenders,

    pingBEURI,
    
};
