<template>
  <div>
    <center><h1>Page Not Found</h1></center>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  props: {
    
  },

  created () {
      var self = this;
      setTimeout(function() { self.$router.push({name:"login"})}, 3000);
  },
  
  mounted() {
    this.$gtag.pageview(this.$route)
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
