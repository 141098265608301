<template>
    <div  id="admin-billing" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
        <Divider orientation="left">MY BILLING DETAILS</Divider>

        <Row v-show="loaded == true && ['S', 'P'].includes(subscriptionPlansData.accountStatus)" justify="center" align="middle" style="padding:8px; height:80px">
            {{currentPlanSummary}}<br />
            {{practiceStatusSummary}}<br />
            {{activationCodeStatusSummary}}
        </Row>

        <!-- <stripe-checkout
            ref="checkoutRef"
            mode="subscription"
            :pk="publishableKey"
            :line-items="lineItems"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => loading = v"
            />
        <button @click="submit">Subscribe!</button> -->

        <Divider orientation="left">Activate via Subscription</Divider>

        <Row v-if="loaded == true" justify="center" style="padding:8px">

            <Card v-for="(plan,index) in plans" :key="plan.id" :class="selectedPlanClass(index)" style="margin:8px;width:320px">
                <div style="text-align:center" @click="handlePlanSelection(index)">

                    <h2>{{ plan.product.expandedObject.name }}</h2>
                    <h3 v-if="plan.product.expandedObject.metadata.popular == 'true'" style="color:green">MOST POPULAR</h3>
                    <h3 v-else>&nbsp;</h3>
                    <br />
                    {{ plan.product.expandedObject.description }}

                    <br /><br />

                    <h1>${{ plan.tiers[0].unit_amount / 100.0 }} ({{ plan.currency.toString(10) | capitalise }})*</h1>
                </div>
            </Card>

        </Row>
        <Row v-if="loaded == true" justify="center" style="padding:8px">
            <center style="maxWidth:600px; margin: 0px 40px 0px 40px"><span class="tipInformation">* Note: Tiered pricing applies for Standard plan and higher. <br />Discounts via promotion codes are also available where applicable on checkout.<br /> Above seat license fee is the base fee per active (physician) user per month.</span></center>
        </Row>

        <Row v-else justify="center" align="middle" style="padding:8px; height:200px">
            <Spin size="large"></Spin>
        </Row>

        <br /> 

        <center>
            
            <strong>{{ portalURL == "" ? "Select Plan" : "Current Plan" }}:</strong> {{ plans[selectedPlanIndex].product.expandedObject.name }}
            
            <!-- <InputNumber v-show="isSelectedPlanQuantityLimitedToOne == false" :min="1" v-model="quantity" style="margin-left:8px;margin-right:8px;" :disabled="portalURL != ''"></InputNumber>
            <span v-if="isSelectedPlanQuantityLimitedToOne == true">, 1 seat</span>
            <span v-else-if="isSelectedPlanQuantityLimitedToOne == false && quantity == 1"> seat</span>
            <span v-else> seats</span> <br /> <br /> -->
            

            <br />
            <strong>{{ portalURL == "" ? "Active Physicians †" : "Active Physicians" }}:</strong> 
            {{ activePhysicianCount }}<br /><br />

            <center style="maxWidth:600px; margin: 0px 40px 0px 40px">
                <span v-if="portalURL == ''" class="tipInformation">† Prior to subscribing, active physicians is computed as the number of treating physicians whom have logged in and used the system in the last one month period. You can manage your users and make any unused ones inactive/deleted to not add to your billing quota.</span>
                <span v-else class="tipInformation">Note: Active Physicians represents the number of treating physicians whom are using the system in the current billing period. This number and thus your billed quantity of seat licenses will automatically increase when new treating physicians use the system. When physicians leave, you simply need to mark them as inactive or deleted to reduce your billing quota.</span>
            </center>



        </center>
        <br /> 
        <center><Button type="primary" @click="handleCheckoutOrUpdateSubscription">{{ portalURL == "" ? "Subscribe Now" : "Update Subscription" }}</Button></center>

        <Divider orientation="left">Activate via Activation Code</Divider>

        <center>
            
            <center style="maxWidth:600px; margin: 0px 40px 0px 40px">
                <span class="tipInformation">Activation Codes can be obtained from entities whom wish to sponsor usage of the service for a specific practice, or be granted special exemptions for the platforms usage.</span>
                <br /><br />
                <span class="tipInformation">Please enter a valid Activation Code below and apply the code to activate the service for your practice.</span>
                <br /><br />
                <Input style="margin-left:8px;margin-right:8px;" v-model="activationCode"></Input>
            </center>




        </center>
        <br /> 
        <center><Button type="primary" @click="handleApplyActivationCode" :disabled="activationCode.length == 0">Apply Activation Code</Button></center>

        <Modal
            v-model="showActivationCodeOverrideConfirm"
            title="An existing activation code is currently active and used."
            @on-ok="handleOverrideActivationCodeOK"
            @on-cancel="handleOverrideActivationCodeCancel">
            <p>Would you like to use the new Activation Code and replace the current one?</p>
        </Modal>

        <!-- <center>
            Coming Soon.<br />
            <br />
            Enjoy the Free Trial!
        </center> -->

    </div>
</template>

<script>
//const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
import AnalyticsMgr from '@/js/AnalyticsManager.js';

const {itBnTx} = require('@/js/itBnTx')

// import { StripeCheckout } from '@vue-stripe/vue-stripe';


export default {
    name: 'admin-billing',
    components: {
        // StripeCheckout,
    },

    created () {
        

    },

    destroyed() {

    },

    mounted() {

    },

    data () {
        this.publishableKey = "pk_test_51It5KsIKIxEKoiJR4UqdkZpCrhX5eapLlAW98SNbjWDjEppqv2yuBQ5Kqq7NNMXV4XvoSjxk3UE7kglPfL3WqDyM00iCpdUa0R";

        return {
            loaded: false,
            
            currentPlanSummary : "",
            practiceStatusSummary : "",
            activationCodeStatusSummary : "",

            subscriptionPlansData : {},

            plans: [{id:0,product:{expandedObject:{images:[''],name:'',description:'',metadata:{popular:'false'}}},
                            tiers: [{unit_amount:0}],
                            currency: ''}],
            selectedPlanIndex: 0,
            quantity: 1,
            activePhysicianCount : 1,
            portalURL : "",  // only defined if an active subscription is active.
            
            // successURL: 'https://test-my.interact.technology/__info.php',
            // cancelURL: 'https://test-my.interact.technology/__info.php',

            activationCode : "",

            showActivationCodeOverrideConfirm : false,
        };    
    },

    filters: {
        capitalise: function (data) {
            return data.toUpperCase()
        }
    },
    
    computed: {
        isSelectedPlanQuantityLimitedToOne() {
            var quantity = this.plans[this.selectedPlanIndex].product.expandedObject.metadata.quantity_limit

            if (quantity == undefined || quantity > 1)
                return false

            return true
        }
        
    },
    methods: {
        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/Administration/Billing');

                this.refreshDataForUI()

                this.$options.sockets.onmessage = (data) => {
                    console.log("billing:", data)   // "CMD:SUBU"
                    this.loaded = false
                    this.refreshDataForUI()
                }
            }
        },

        refreshDataForUI() {
            //if (this.loaded == false) {

                itBnTx.getSubscriptionPlans()
                        .then(response => {
                            var currentPlanName = ""

                            console.log("getSubscriptionPlans", response.data)
                            this.subscriptionPlansData = response.data
                            this.plans = response.data.plans

                            // if a planId is specified find it and make it the current selection.
                            if (response.data.planId != "") {
                                for (var index = 0; index < this.plans.length; index++) {
                                    if (this.plans[index].id == response.data.planId) {
                                        this.selectedPlanIndex = index
                                        currentPlanName = this.plans[index].product.expandedObject.name
                                        break
                                    }
                                }
                            } else {
                                if (this.loaded == false) {

                                    this.selectedPlanIndex = 0  // default to first plan as selected...

                                    // iterate through all plans to locate one which has the metadata flag for default set to true.
                                    for (var index2 = 0; index2 < this.plans.length; index2++) {
                                        if (this.plans[index2].product.expandedObject.metadata.default == 'true' ) {
                                            this.selectedPlanIndex = index2
                                            break
                                        }
                                    }
                                }

                            }

                            // grab the quantity if not zero
                            if (response.data.quantity != 0) {
                                this.quantity = response.data.quantity
                            }
                            
                            this.activePhysicianCount = response.data.activePhysicianCount

                            // grab the portal URL which we will use to update the state the button to subscribe or manage subscription.
                            this.portalURL = response.data.url

                            // update other info for user information... 
                            this.currentPlanSummary = ""
                            this.practiceStatusSummary = ""
                            this.activationCodeStatusSummary = ""

                            if (response.data.accountStatus == "T") {
                                this.practiceStatusSummary = "Trial Period. (" + response.data.daysLeft + " days before trial ends)"
                            } else {
                                this.currentPlanSummary = "Your current plan: " + (currentPlanName == "" ? "?" : currentPlanName)
                                this.practiceStatusSummary = "Subscription auto-renewal in " + response.data.daysLeft + " " + (response.data.daysLeft == 1 ? "day" : "days") + "."
                            }
                            
                            if (response.data.usingActivationCode == true) {
                                this.activationCodeStatusSummary = "Activation Code valid for another " + response.data.activationCodeDaysLeft + " " + (response.data.activationCodeDaysLeft == 1 ? "day" : "days") + "."
                            }

                            this.loaded = true
                        })

            //}

        },

        handlePlanSelection(inPlanIndex) {
            if (this.portalURL == "") {
                this.selectedPlanIndex = inPlanIndex
            }
            
            //console.log(inPlanIndex)
        },

        handleCheckoutOrUpdateSubscription() {

            if (this.portalURL != "") {
                window.open(this.portalURL, "bntx-subscription-portal-" + itBnTx.baseUrl);
            } else {

                if (this.isSelectedPlanQuantityLimitedToOne == true && this.activePhysicianCount != 1) {

                    this.$Notice.info({
                        title: 'Subscription not permitted',
                        desc: 'Lite plan is only allowed for practices with one treating physician'
                    });

                } else {
                    itBnTx.createCheckoutSession({
                                inPriceID : this.plans[this.selectedPlanIndex].id
                                // ,
                                // inQuantity : this.isSelectedPlanQuantityLimitedToOne ? 1 : this.quantity
                            })
                        .then(response => {
                                window.open(response.data.url, "bntx-subscription-checkout-" + itBnTx.baseUrl);
                                // console.log(response.data)
                            })
                }

                
            }

            
        },

        selectedPlanClass(inPlanIndex) {
            return this.selectedPlanIndex == inPlanIndex ? 'selectedPlan' : ''
        },


        submit () {
            // You will be redirected to Stripe's secure checkout page
            // this.$refs.checkoutRef.redirectToCheckout();
        },


        handleApplyActivationCode() {
            itBnTx.createSubscriptionUsingActivationCode({inActivationCode : this.activationCode,
                                                        inOverrideExistingActivationCodes : false})
                  .then(response => {
                      response
                      this.$Notice.info({
                        title: 'Activation Code Accepted',
                        desc: 'You may now continue to enjoy the service.'
                      });
                      this.activationCode = ""
                      this.refreshDataForUI()
                  })
                  .catch(error => {
                        if (error.responseCode == 2004) {
                            // The practice has already subscribed to a plan with… Would you like to apply the new activation code?
                            // console.log("ERR", error)
                            this.showActivationCodeOverrideConfirm = true
                        } else {
                            this.$Message.error({  content: error.responseMsg + " [ID=" + error.responseCode + "]",
                                                   duration: 5 /* show backend errors for longer, as default of 1.5s a bit too short to read */
                                                 }); 
                        }
                  })
        },

        handleOverrideActivationCodeOK() {
            itBnTx.createSubscriptionUsingActivationCode({inActivationCode : this.activationCode,
                                                        inOverrideExistingActivationCodes : true})
                  .then(response => {
                      response
                      this.$Notice.info({
                        title: 'Activation Code Accepted',
                        desc: 'You may now continue to enjoy the service.'
                      });
                      this.activationCode = ""
                      this.refreshDataForUI()
                  })
                  .catch(error => {
                      this.$Message.error({  content: error.responseMsg + " [ID=" + error.responseCode + "]",
                                            duration: 5 /* show backend errors for longer, as default of 1.5s a bit too short to read */
                                                 }); 
                  })
        },

        handleOverrideActivationCodeCancel() {
            
        },

    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
    
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>

.tipInformation {
    font-size: 11px;
    color: rgb(110, 110, 110);
}

.selectedPlan {
    /* width: 100px;
    height:100px; */
    background-color: #4ceafb;  
    margin:50px 50px;
    
    -webkit-box-shadow:0 0 20px gray; 
    -moz-box-shadow: 0 0 20px gray; 
    box-shadow:0 0 20px gray;

}

</style>

