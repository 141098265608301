
/*
    this file represents various security token apis used to access different levels of capabilties
    from the backend... especially at a reduced/restricted permission level

*/


/*
    RESPONSE:

    {
        "educationToken" : "EUCT-eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhdXRoMCJ9._s0oX6LhNNi_5hGBbD2uHlqi9diDh04C6idviBqe8dY"
    }

    token used to access education center API's
*/
function getEducationToken() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/token/education',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



/*
    RESPONSE:

    {
        "companionToken" : "CMPT-eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhdXRoMCJ9._s0oX6LhNNi_5hGBbD2uHlqi9diDh04C6idviBqe8dY"
    }

    companion token used by companion app for desktop (eg signature, upload attachment) which restricts
    API access to a handful of capabilities.
*/
function getCompanionToken() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/token/companion',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}




module.exports = { 

    getEducationToken,
    getCompanionToken,

};
