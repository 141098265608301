<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'


export default {
  name: 'app',
  components: {
    // HelloWorld
  },
 
  data() {
    return {
            
        }
  },

  methods: {
     

  },

  computed: {
   

  },

  watch : {
   
  }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}



/* Badge Colour */
.bntx-badge {
    background: #C45D2F !important;
    font-family: 'Roboto' !important;
    transform: scale(0.60) translate(2px, -6px) !important;
}



@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-Light.woff2') format('woff2'),
        url('./fonts/RobotoCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-Bold.woff2') format('woff2'),
        url('./fonts/RobotoCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-Italic.woff2') format('woff2'),
        url('./fonts/RobotoCondensed-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-BoldItalic.woff2') format('woff2'),
        url('./fonts/RobotoCondensed-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-LightItalic.woff2') format('woff2'),
        url('./fonts/RobotoCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-Regular.woff2') format('woff2'),
        url('./fonts/RobotoCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Regular.woff2') format('woff2'),
        url('./fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Medium.woff2') format('woff2'),
        url('./fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Light.woff2') format('woff2'),
        url('./fonts/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('./fonts/Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('./fonts/Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('./fonts/Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('./fonts/Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('./fonts/Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Italic.woff2') format('woff2'),
        url('./fonts/Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Black.woff2') format('woff2'),
        url('./fonts/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Bold.woff2') format('woff2'),
        url('./fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Thin.woff2') format('woff2'),
        url('./fonts/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


</style>

