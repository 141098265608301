<template>
     <Modal
        v-model="modal"
        title="About Education and Training"
        cancel-text=""
        @on-ok="handleOK"
        >
        <p>Education and Training is part of the BnTx Interact software.</p>
        <br />
        <p>It is provided to you to assist and enhance the experience of working with BnTx.</p>
    </Modal>
</template>

<script>
    export default {
        name: 'About',

        mounted() {
            this.$gtag.pageview(this.$route)
        },

        data () {
            return {
                modal: true
            }
        },
        methods: {
            handleOK () {
                //this.$Message.info('Clicked ok');
                this.modal = false;
                var self = this;
                setTimeout(function() {self.$router.push({name:"login"}) }, 100);
            },
        }
    }
</script>