const axios = require('axios');

/*
    this file represents a number of 3rd party service which are useful

*/

/*
    https://blog.logrocket.com/detect-location-and-local-timezone-of-users-in-javascript-3d9523c011b9/

    which uses

    https://extreme-ip-lookup.com/

    Output Schema:
        query	Client IP address or IP address specified
        ipType	Business, Education or Residential
        (Residential is an IP address from an Internet, Hosting or Cloud provider)
        businessName	Name of Business / Education Organization
        businessWebsite	Website domain of Business / Education Organization
        continent	Name of the Continent
        countryCode	Two-letter ISO 3166-1 alpha-2 country code
        country	Name of the country
        region	Name of the region
        city	Name of the city
        lat	Latitude
        lon	Longitude
        ipName	Resolved IP Name
        org	Organization Name
        isp	ISP Name
        timezone	Pro Feature! Samples: America/Chicago, Europe/London, see all on Wikipedia
        utcOffset	Pro Feature! Samples: -10:00, +02:00
        status	Success or fail
        message	Extra status message if fail

    {
        "businessName" : "",
        "businessWebsite" : "",
        "city" : "Sydney",
        "continent" : "Oceania",
        "country" : "Australia",
        "countryCode" : "AU",
        "ipName" : "220-244-34-74.static.tpgi.com.au",
        "ipType" : "Residential",
        "isp" : "TPG Internet Pty Ltd.",
        "lat" : "-33.86785",
        "lon" : "151.20732",
        "org" : "TPG Internet Pty Ltd.",
        "query" : "220.244.34.74",
        "region" : "New South Wales",
        "status" : "success"
    }

*/
function gestimateUserLocationDetails() {
    return axios.get('https://extreme-ip-lookup.com/json/?key=0VqEyLP76cjrbrfBM9eF')
}




module.exports = { 

    gestimateUserLocationDetails,

};
