
function initialize({inCallbacks = {},
                     inEnvironment = undefined} = {}) {

    if (inCallbacks != undefined ) {
        for (var cb in inCallbacks) {
            if (inCallbacks[cb] != null) {
                this.callbacks[cb] = inCallbacks[cb]; // VERY IMPORTANT CALLBACK STUFF
            }
        }
    }
  
    //var self = this;
    if (inEnvironment != undefined)
        this.environment = inEnvironment
    else
        this.environment = this.getEnvironment();

    this.hubUrl           = this.configs[this.environment].hubUrl;
    this.baseUrl          = this.configs[this.environment].serviceUrl;
    this.baseWebSocketURL = this.configs[this.environment].websocketBaseUrl;

    try {
        this.callbacks.initialize();
    } catch (err) {
        console.log("initialize callback threw EXCEPTION: ", err)
    }

}
  
module.exports = { initialize };
  