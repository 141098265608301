function getEnvironment() {
  let environment = ''
  let hostname = window.location.hostname
  //let hostname = "inject.bntxinteract.com"
  if (hostname == 'localhost'){
    environment = 'test'
  } else{
    if (/test-.*\.bntxinteract\.com/.test(hostname)) {
      environment = 'test'
    }else if(/.*\.bntxinteract\.com/.test(hostname)){
      environment = 'prod'
    }else{
      environment = 'test'
    }
  }
  return environment
}


/*
  intelligently return location origin with replacement of localhost with a well defined 
  local machine IP so other systems can browse to it.

  This is useful for QR Code URL's for companion apps like mobile scanning and loading resources
  which would not work well if localhost were used.
*/
function getLocationOrigin() {
  const DEV_IP = "192.168.89.107"

  return window.location.origin.replace('localhost', DEV_IP)     // https://localhost:8080 or https://inject.bntxinteract.com
                               .replace('releases.interact.technology', "releases.interact.technology/~releases/btx-inject/dist")
}


module.exports = { 
  getEnvironment,
  getLocationOrigin,

};
