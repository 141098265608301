<template>
    <div v-observe-visibility="visibilityChanged" >
        <!-- ================================ -->
        <!-- Modal Additional Material Viewer -->
        <!-- ================================ -->
        <Modal v-model="additionalMaterialDialog.show" draggable sticky width="900" transfer :title="additionalMaterialDialog.title" :footer-hide="true">

            <iframe :src="additionalMaterialDialog.url" class="fullScreenIFrame" style="height:480px" frameborder="0"></iframe>

        </Modal>

        <Row justify="space-around" >
            <Col v-bind="responsiveDetailsSection" style="padding-left:8px; padding-right:8px">
                <Row>

                    <!-- ================================ -->
                    <!-- Medication Preparation           -->
                    <!-- ================================ -->
                    <Card :bordered="true" :style="{'margin-top':'8px', width:'100%'}" id="injections_main_medication_preparation">
                        <p slot="title">Medication Preparation*</p>
                        <Form ref="medicationPrepInfoFormFields" :model="medicationPrepInfo" :rules="medicationPrepInfoRules" :label-width="120" >
                            <FormItem label="Medication">
                                <Select v-model="medicationPrepInfo.injectionMedicationId" :disabled="currentSessionDetailedData.length != 0">
                                    <Option v-for="item in medicationOptions" :value="item.medicationID" :key="item.medicationID">{{ item.medicationName }}</Option>
                                </Select>
                            </FormItem>

                            <FormItem label="Batch #" prop="medicationBatch" :class="mandatoryFieldsStyle">
                                <Input v-model="medicationPrepInfo.medicationBatch" placeholder="enter batch number"></Input>
                            </FormItem>
                            <FormItem label="Expiry Date" prop="medicationExpireDate" :class="mandatoryFieldsStyle">
                                <DatePicker type="date" :options="allowOnlyTodayAndFutureDatesOptions" format="dd/MM/yyyy" placeholder="DD/MM/YYYY" v-model="medicationPrepInfo.medicationExpireDate"></DatePicker>
                            </FormItem>

                            <Row style="margin-top:4px">
                                <Col span="12">
                                    <Row justify="end">
                                        <!-- <span style="margin-top:4px">Amount </span> -->
                                        <Select v-model="medicationPrepInfo.injectionMedicationAmount" style="margin-left:4px;maxWidth:90px" @on-change="handleMedicationAmountChanged">
                                            <Option v-for="item in medicationOptions[selectedMedicationIndex].medicationAmounts" :value="item.medicationAmount" :key="item.id">{{ item.amountDisplayString }}</Option>
                                        </Select>
                                        <span style="margin:6px">into</span>
                                    </Row>
                                </Col>
                                <Col span="12">
                                    <InputNumber :min="1"
                                             :max="5"
                                             v-on:on-focus="onFocusMedicationDilution"
                                             v-model="medicationPrepInfo.injectionMedicationDilution"
                                             style="width:90px"
                                             :parser="medicationDilutionValidation"
                                             ></InputNumber> ml
                                </Col>
                            </Row>

                            <!-- <FormItem label="Med. Amount">
                                <Select v-model="medicationPrepInfo.injectionMedicationAmount">
                                    <Option v-for="item in medicationOptions[selectedMedicationIndex].medicationAmounts" :value="item.medicationAmount" :key="item.id">{{ item.amountDisplayString }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="Diluted into">
                                <InputNumber :min="1"
                                             :max="5"
                                             v-model="medicationPrepInfo.injectionMedicationDilution"
                                             controls-outside
                                             :formatter="value => `${value} ml`"
                                             :parser="medicationDilutionValidation"></InputNumber>
                            </FormItem> -->

                            
                            <Divider style="margin:6px" orientation="center" size="small">New Injection Sites</Divider>

                            <Row>
                                <Col span="10">
                                    <Row justify="end">
                                        <InputNumber :min="0"
                                                    v-on:on-focus="onFocusSiteInjectionAmount"
                                                    v-model="medicationPrepInfo.injectionSiteAmount"
                                                    style="width:60px"
                                                    ></InputNumber>
                                        <span style="margin:6px">units</span> 
                                    </Row>
                                </Col>
                                <Col span="9">
                                    <InputNumber :min="0"
                                                v-on:on-focus="onFocusSiteInjectionDilution"
                                                v-model="medicationPrepInfo.injectionSiteDilution"
                                                style="width:60px"
                                                ></InputNumber>
                                    <span style="margin:6px">ml</span> 
                                </Col>
                                <Col span="5">
                                    <Checkbox v-model="medicationPrepInfo.isEmg" style="margin-top:6px">EMG</Checkbox>
                                </Col>
                            </Row>

                            <!-- <FormItem label="New Injection">
                                <InputNumber :min="1"
                                             v-on:on-focus="onFocusSiteInjectionAmount"
                                             v-model="medicationPrepInfo.injectionSiteAmount"
                                             controls-outside
                                             :formatter="value => `${parseFloat(value.toFixed(2))} units`"
                                             :parser="value => value.replace(' units', '')"
                                             ></InputNumber>
                            </FormItem>
                            <FormItem label="Site Dilution">
                                <InputNumber :min="0"
                                             v-on:on-focus="onFocusSiteInjectionDilution"
                                             v-model="medicationPrepInfo.injectionSiteDilution"
                                             controls-outside
                                             :formatter="value => `${parseFloat(value.toFixed(2))} ml`"
                                             :parser="value => value.replace(' ml', '')"
                                             ></InputNumber>
                            </FormItem>
                            <FormItem label="EMG">
                                <Checkbox v-model="medicationPrepInfo.isEmg"> </Checkbox>
                            </FormItem> -->
                        </Form>
                    </Card>

                    <!-- ================================ -->
                    <!-- Injection Protocol               -->
                    <!-- ================================ -->
                    <Row :style="{'margin-top':'8px', width:'100%'}" id="injections_main_injection_protocol">
                    <!-- <Card :bordered="true" :style="{'margin-top':'8px', width:'100%'}" id="injections_main_injection_protocol">
                        <p slot="title">Injection Protocol</p> -->
                        
                        <Col flex="110px"  style="margin-top: 6px">Injection Protocol</Col>
                        <Col flex="auto">
                            <Select v-model="selectedInjectionProtocolId" style="margin-right:8px;" @on-change="handleInjectionProtocolChanged" filterable>
                                <Option v-for="item in availablePracticeInjectionProtocols" :value="item.pipId" :key="item.pipId">{{ item.pipName }}</Option>
                            </Select>
                        </Col>
                        <Col  style="margin-left: 8px">
                            <Button type="primary" :disabled="selectedInjectionProtocolId <= 0" @click="handleInjectViaSelectedInjectionProtocol">
                                <span v-if="!applyingInjectionProtocol">Inject</span>
                                <span v-else>Applying...</span>
                            </Button>
                        </Col>

                        <!-- ==================================== -->
                        <!-- N E W    Injection Protocol Drawer   -->
                        <!-- ==================================== -->
                        <Drawer title="New Injection Protocol" width="400" placement="left" :closable="true" v-model="showNewInjectionProtocolUI">
                            Create a new Injection Protocol from the Current Session Injection points.<br /><br />

                            <strong>New Protocol Name:</strong><br />
                            <Input v-model="newInjectionProtocolDetails.pipName" type="text" placeholder="New Protocol Name"></Input><br />
                            <span v-show="isDuplicateInjectionProtocolName" style="color: red;">Injection Protocol name already exists</span><br /><br />

                            <strong>Associate with which Condition:</strong><br />
                            <RadioGroup v-model="newInjectionProtocolDetails.conditionId" type="button" button-style="solid" :style=" 'margin-top: 8px'">
                                <Radio v-for="condition in conditionsRefData" :key="condition.conditionId" :label="condition.conditionId" style="width:160px; text-align:center;" >{{ condition.conditionName }}</Radio>
                            </RadioGroup>
                            <br />
                            <br />
                            <Row justify="end">
                                <Button @click="handleCancelCreateNewProtocol" style="margin-right:8px">Cancel</Button>
                                <Button :disabled="isDuplicateInjectionProtocolName" type="primary" @click="handleCreateNewProtocol">Create</Button>
                            </Row>

                        </Drawer>

                    <!-- </Card> -->
                    </Row>

                    <!-- ================================ -->
                    <!-- Current Session Summary          -->
                    <!-- ================================ -->
                    <Card :bordered="true" :style="{'margin-top': '8px', width:'100%'}" id="injections_main_current_session_injections">
                        <p slot="title">Current Session Summary <span style="font-weight:200">({{ totalUnitsForCurrentSession }} units)</span></p>

                        <!-- <Divider orientation="left">Current Session Summary ({{ totalUnitsForCurrentSession }} units)</Divider> -->

                        <Table width="100%" height="150" draggable size="default" :columns="currentSessionSummaryTableColumnsConfig" :data="currentSessionSummaryData" border @on-row-click="handleCurrentSessionSummaryRowClicked"></Table>
                    </Card>


                    <!-- ================================ -->
                    <!-- Previous Session Drawer          -->
                    <!-- ================================ -->
                    <!-- <Drawer
                        v-model="showPreviousInjectionSessions"
                        placement="left"
                        width="500"
                        >

                        <p slot="header">Patient Historical Injection Sessions</p> -->

                        <Card :bordered="true" :style="{'margin-top':'8px', width:'100%'}" id="injections_main_previous_sessions_and_injections">
                            <p slot="title">Previous Sessions</p>

                            <Table style="width: 100%;" height="150" draggable size="default" :columns="previousSessionTableColumnsConfig" :data="sessionsData" border highlight-row @on-current-change="handleAllSessionSelectionChanged" >
                                <template slot-scope="{ row }" slot="date">
                                    <span :class="currentSessionHighlightClass(row)">{{ row.sessionDate | userFriendlyDate }}</span>
                                </template>
                                <template slot-scope="{ row }" slot="unit">
                                    <span :class="currentSessionHighlightClass(row)">{{ row.totalUnits }}</span>
                                </template>
                            </Table>

                            <Row justify="end">
                                <Button type="primary"
                                    :disabled="!renderPreviousSessionInjectionsOnScreen"
                                    @click="renderPreviousSessionInjectionsOnScreen = !renderPreviousSessionInjectionsOnScreen"
                                    style="margin-top:8px; margin-right:8px">Hide Previous Injections</Button>

                                <Poptip
                                        confirm transfer
                                        title="Duplicate all injection points from the previous session to this session?"
                                        ok-text="Yes"
                                        cancel-text="Cancel"
                                        @on-ok="handleDuplicatePreviousSession">
                                        
                                        <Button style="margin-top:8px" type="primary" :disabled="selectedPreviousSessionID == currentSessionID || previousSessionSummaryData.length == 0">Duplicate</Button>
                                    </Poptip>
                            </Row>
                        </Card>

                        <Card :bordered="true" :style="{'margin-top':'8px', width:'100%'}">
                            <p slot="title">Selected Session Summary <span style="font-weight:200">({{ totalUnitsForSelectedSession }} units)</span></p>

                            <Table style="width: 100%;" height="150" draggable size="default" :columns="previousSessionSummaryTableColumnsConfig" :data="previousSessionSummaryData" border @on-row-click="handleSelectedSessionSummaryRowClicked"></Table>
                        </Card>

                    <!-- </Drawer> -->
                    
                    <!-- <div id="injections_main_previous_sessions_and_injections">
                        <Button type="primary" @click="showPreviousInjectionSessions = !showPreviousInjectionSessions" style="margin-top:8px; margin-right:8px">Show Previous Sessions</Button>
                        <Button type="primary"
                                :disabled="!renderPreviousSessionInjectionsOnScreen"
                                @click="renderPreviousSessionInjectionsOnScreen = !renderPreviousSessionInjectionsOnScreen"
                                style="margin-top:8px">Hide Previous Injections</Button>
                    </div> -->


                </Row>
            </Col>
            <Col v-bind="responsiveInjectionSection" style="padding-left:8px padding-right:8px">


                <!-- ================================ -->
                <!-- Navigation Floating Palette      -->
                <!-- ================================ -->

                <div id="injectionNavigation">
                    <div id="injectionNavigationHeader">click and drag</div>
                    <span id="ToggleDebugInfo" style="top:20px;left:10px;position:absolute;" @click="showDebugInfo = !showDebugInfo">.</span>
               
                    <font-awesome-icon  title='Toggle Previous Session Injections'
                                        :icon="['fal', (renderPreviousSessionInjectionsOnScreen ? 'eye' : 'eye-slash')]"
                                        v-show="selectedPreviousSessionID != 0 && selectedPreviousSessionID != currentSessionID"
                                        size="4x"
                                        fixed-width
                                        :style="{ color: 'CornflowerBlue' }"
                                        @click="renderPreviousSessionInjectionsOnScreen = !renderPreviousSessionInjectionsOnScreen"
                                        class="navTogglePrevSessionInjections" /> 

                    <font-awesome-icon  title="Undo"  :icon="['fal', 'undo-alt']"    v-show="undoHistory.length != 0"        size="4x"   fixed-width   :style="{ color: undoHistory.length == 0 ? 'lightgray' : 'CornflowerBlue' }"    @click="handleUndo"     class="navUndo" /> 
                    <font-awesome-icon  title="Redo"  :icon="['fal', 'redo-alt']"    v-show="redoHistory.length != 0"        size="4x"   fixed-width   :style="{ color: redoHistory.length == 0 ? 'lightgray' : 'CornflowerBlue' }"    @click="handleRedo"     class="navRedo" /> 

                    <font-awesome-icon                :icon="['fal', 'chevron-circle-up']"     size="4x"   fixed-width   :style="{ color: isAtTopMostLayer ? 'lightgray' : 'black' }"    @click="handleNavigateUp"     class="navUp" /> 
                    <font-awesome-icon                :icon="['fal', 'chevron-circle-left']"   size="4x"   fixed-width   :style="{ color: canNavigateLeft ? 'lightgray' : 'black' }"     @click="handleNavigateLeft"   class="navLeft" /> 
                    <font-awesome-icon                :icon="['fal', 'chevron-circle-down']"   size="4x"   fixed-width   :style="{ color: isAtBottomMostLayer ? 'lightgray' : 'black' }" @click="handleNavigateDown"   class="navDown" /> 
                    <font-awesome-icon                :icon="['fal', 'chevron-circle-right']"  size="4x"   fixed-width   :style="{ color: canNavigateRight ? 'lightgray' : 'black' }"    @click="handleNavigateRight"  class="navRight" /> 
                        
                    <font-awesome-icon  title="Toggle Left/Right"  :icon="['fal', 'repeat-alt']"    v-show="canToggleLeftRight"        size="4x"   fixed-width   :style="{ color: 'CornflowerBlue' }"    @click="handleToggleLeftRight"     class="navToggleLeftRight" /> 
                        
                       
                    <Poptip placement="bottom"
                            width="200" 
                            class="navInfo">

                        <font-awesome-icon  :icon="['fal', 'info-circle']"
                                    size="4x"
                                    fixed-width
                                    :style="{ color: selectedMuscleID == 0 ? 'lightgray' : 'black' }"
                                        /> 

                        <List slot="content" size="small" :split="false">
                            <ListItem v-show="selectedMuscleData != null && selectedMuscleData.muscleSurfaceAnatomyInstructions != ''" >   <a @click="handleSurfaceAnatomyRequest">Surface Anatomy</a></ListItem>
                            <ListItem v-show="selectedMuscleData != null && selectedMuscleData.cadaverVideo != ''">                        <a @click="handleCadaverVideoRequest">Cadaver Video</a></ListItem>
                            <ListItem v-show="selectedMuscleData != null && selectedMuscleData.emgVideo != ''">                            <a @click="handleEMGVideoRequest">EMG Video</a></ListItem>
                            <ListItem v-show="selectedMuscleData != null && selectedMuscleData.model3D != ''">                             <a @click="handle3DModelRequest">3D Model</a></ListItem>
                        </List>
                    </Poptip>

                    <Row v-shortkey="['ctrl','meta','arrowup']"
                         @shortkey.native="handleNavigateUp()" />
                    <Row v-shortkey="['ctrl','meta','arrowleft']"
                         @shortkey.native="handleNavigateLeft()" />
                    <Row v-shortkey="['ctrl','meta','arrowright']"
                         @shortkey.native="handleNavigateRight()" />
                    <Row v-shortkey="['ctrl','meta','arrowdown']"
                         @shortkey.native="handleNavigateDown()" />

                    <Row v-show="showDebugInfo" style="position: absolute; top: 220px">
                        {{ debugOutput }}<br />
                        {{ this.scalingRatio }}<br />
                        <span id="DebugSelectedMuscleName">{{ this.selectedMuscleData == undefined ? '' : this.selectedMuscleData.injectionSummaryName }}</span><br />
                        <input type="text" id="SimInjectXY" value="211,141;211,141;475,170;475,170">
                        <input type="button" id="SimInjectBtn" @click="simInject" value="SimInject" />
                        <input type="button" id="SimKillInjectsBtn" @click="simKillInjection" value="KillInjects" />
                    </Row>
                </div>


                <!-- ================================================== -->
                <!-- Muscle Group/View/Level and Summary Thumbnails     -->
                <!-- ================================================== -->

                <Row style="margin-top:8px">
                    <Col span="8" id="injections_main_muscle_navigation">
                        <Select filterable v-model="selectedMuscleGroupID" style="margin-top: 2px">
                            <Option v-for="item in muscleData.muscleGroupSet" :value="item.muscleGroupId" :key="item.muscleGroupId">{{ item.muscleGroup }}</Option>
                        </Select>
                        <Select filterable v-model="selectedMuscleViewID" style="margin-top: 2px">
                            <Option v-for="item in currentMuscleViewArray" :value="item.view" :key="item.view">{{ item.view }}</Option> <!-- viewCode -->
                        </Select>
                        <Select filterable v-model="selectedMuscleLayerID" style="margin-top: 2px">
                            <Option v-for="item in currentMuscleLayerArray" :value="item.layerId" :key="item.layerId">{{ item.layer }}</Option>
                        </Select>

                        <Row>
                            <Col span="20">
                                <Select ref="listOfAllMuscles" v-model="allMusclesDropdownSelectedMuscleID" style="margin-top: 2px" filterable clearable>
                                    <Option v-for="item in allMusclesSortedByName" :value="item.muscleDetails.muscleId" :key="item.muscleDetails.muscleId"
                                            :class="item != undefined
                                                    && item.groupIndex == selectedMuscleGroupIndex
                                                    && item.viewIndex == selectedMuscleViewIndex
                                                    && item.layerIndex == selectedMuscleLayerIndex
                                                    ? 'muscleInCurrentGroupAndViewAndLayer' : ''"
                                            >
                                        {{ item.muscleDetails.injectionSummaryName }}
                                    </Option>
                                </Select>
                            </Col>
                            <Col span="4">
                                <Button type="primary" style="margin-top: 2px; margin-left: 2px" icon="ios-search" @click="handleSearchMuscles" />
                            </Col>
                        </Row>
                    </Col>
                    <Col span="16" id="injections_main_injection_summary_thumbnails">
                        <div style="margin-left:8px; height: 140px; overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
                            <div v-for="item in thumbnailInjectionViewsMuscleData" :key="item.groupViewID" @click="handleThumbnailViewClicked(item)" style="height: 123px; width:110px; margin: 1px; display: inline-block; border-color: #465768; border-width: 1px; border-style: solid;">
                                <div style="top: 3px; left: 3px; position: relative; ">
                                    <img :src="item.viewBackgroundImageURL" style="" />
                                    
                                    <!-- ADD INJECTION POINTS -->
                                    <div v-for="injection in item.injections"
                                            :key="injection.injectionId"
                                            width="20px"
                                            height="20px"
                                            :style="thumbnailInjectionPointPositionByStyle(injection)"
                                            >
                                        
                                        <img :src="injectionPointImage(injection, true)"
                                            class="injectionPointIcon"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
               
                <Row>
                    <Col span="24">

                    <!-- ================================ -->
                    <!-- I N J E C T I O N    V I E W     -->
                    <!-- ================================ -->

                    <div id="injectionViewContainer" class="injectionContainer">
                        <div class="outer">
                            <div class="inner" v-resize="handleInjectionViewResize"
                                                v-touch:swipe.left="handleNavigateRight"
                                                v-touch:swipe.right="handleNavigateLeft"
                                                v-touch:swipe.top="handleNavigateUp"
                                                v-touch:swipe.bottom="handleNavigateDown"
                                                >

                                <!-- <resize-observer @notify="handleInjectionViewResize" /> -->
                                <img v-resize="handleInjectionViewResize" :src="injectionView.backgroundURL" id="layer_background" />
                                    
                                <img v-for="item in injectionView.muscleLayerURLs" 
                                        :key="item.id"
                                        :src="item.url"
                                        v-show='item.url !== ""'
                                        :class="selectedMuscleLayerCSSClass(item.id)"
                                        :id="muscleLayerElementID(item.id)"
                                        @click="handleClickOnInjectionView" />

                                <!-- ADD INJECTION POINTS -->
                                <div v-for="item in unionOfCurrentAndSelectedPreviousSessionInjectionDetailedData"
                                        :key="item.injectionId"
                                        width="20px"
                                        height="20px"
                                        :style="injectionPointPositionByStyle(item)"
                                        v-show="isInjectionPointCurrentlyVisible(item)">
                                    
                                    <span :class="injectionPointLabelPositionStyle(item.sessionId)">
                                        {{ item.injectionSiteAmount }}
                                    </span>
                                    
                                    <Poptip
                                        confirm transfer
                                        :disabled="item.sessionId != currentSessionID"
                                        title="Are you sure you want to delete this injection?"
                                        @on-ok="handleDeleteInjection(item, false)">
                                        
                                        <img :src="injectionPointImage(item, false)"
                                                class="injectionPointIconPoptip"
                                                @mouseover="hoveringOverInjectionPointDetails = item"
                                                @mouseleave="hoveringOverInjectionPointDetails = null"
                                                />

                                    </Poptip>
                                    <!-- <img :src="injectionPointImage(item, false)"
                                         class="injectionPointIcon"
                                         @mouseover="hoveringOverInjectionPointDetails = item"
                                         @mouseleave="hoveringOverInjectionPointDetails = null"
                                         @contextmenu.prevent="handleRightClickInjectionPoint(item)"
                                         /> -->
                                </div>

                            </div>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Col>
        </Row>


        
    </div>
</template>
<script>

const {CONFIG} = require('@/js/bntx-config')
import AnalyticsMgr from '@/js/AnalyticsManager.js';

import drag from '@/js/draggableElement.js'
import moment from 'moment'

// var axios = require("axios");
var resize = require('vue-resize-directive')
var _ = require('lodash');
const {itBnTx} = require('@/js/itBnTx')


let IMAGE_SIZE_WIDTH = 1000.0
let IMAGE_SIZE_HEIGHT = 1130.0


export default {
    name: 'pm-injections',
    components: {

    },
    directives: {
        resize
    },

    created () {

        this.medicationPrepInfo.injectionMedicationId = this.medicationOptions[0].medicationID

        // setTimeout(() => {
            itBnTx.getMuscleImagesData()
                  .then(response => {
                this.muscleData = response.data;

                this.selectedMuscleGroupID = 1
                this.resyncUIOnMuscleGroupSelectorChange()

                this.createMuscleCacheData()
            })

            this.practiseDetails = this.$store.state.loggedInPracticeDetails

            this.loadPractiseInjectionProtocolData()
        // }, 2000)

        /*
            https://test-inject.bntxinteract.com/rest/session/patient?_dc=1623839964190&patientId=28752&page=1&start=0&limit=25

            created: 1623287146568
            createdBy: 63
            lastSessionBenefitNotes: ""
            lastSessionBenefitsPeriod: ""
            lastSessionBenefitsSatisfaction: ""
            lastSessionNoBenefits: false
            lastSessionNoSideEffects: false
            lastSessionSideEffectsNotes: ""
            lastSessionSideEffectsPeriod: ""
            lastSessionSideEffectsSatisfaction: ""
            modified: 1623289755939
            modifiedBy: 63
            noticedBenefitsWearingOffPeriod: ""
            noticedSideEffectsWearingOffPeriod: ""
            patientConsentsToInjection: false
            patientHasRecentlyUndergoneSurgery: false
            patientId: 28752
            patientIsBreastFeeding: false
            patientIsPendingSurgery: false
            patientIsPregnant: false
            patientIsTakingAnticoagulants: false
            patientReceivedBTX: false
            sessionAssessments: ""
            sessionDate: 1623287146000
            sessionHpiNotes: ""
            sessionId: 1516
            sessionRecommendations: ""
            userId: 63


 
        */


//         document.getElementsByTagName('body')[0].addEventListener('keydown', function(event) {
//             event.preventDefault();
// //            if (Ext.Element.getActiveElement().nodeName !== "INPUT") {

//                 if (event.which === 37) { self.handleNavigateLeft(); }      // left
//                 else if(event.which === 38) { self.handleNavigateUp(); }    // up
//                 else if(event.which === 39) { self.handleNavigateRight(); } // right
//                 else if(event.which==40) { self.handleNavigateDown(); }     // down
//             //}
//         })

        /*
            add following line to work around the following CORS error:
            vue.runtime.esm.js?2b0e:1888 DOMException: Failed to execute 'getImageData' on 'CanvasRenderingContext2D': The canvas has been tainted by cross-origin data.

            See: https://stackoverflow.com/questions/22097747/how-to-fix-getimagedata-error-the-canvas-has-been-tainted-by-cross-origin-data
            Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image

            Tag all image elements with the appropriate crossOrigin attribute, so accessing data via canvas is allowed
        */
        for (var muscleLayerIndex = 0; muscleLayerIndex < 30; muscleLayerIndex++) {
            var injectionMuscleImgElement = document.getElementById("muscle_layer_" + muscleLayerIndex);

            if (injectionMuscleImgElement != undefined)
                injectionMuscleImgElement.crossOrigin = "anonymous";
        }

    },

    destroyed() {

    },

    mounted() {
        drag.dragElement(document.getElementById("injectionNavigation"));
        document.getElementById("injectionNavigation").style.top = '40vh'
        document.getElementById("injectionNavigation").style.right = '10px'

    },

    data () {
        return {
            // following are cached, and loaded as soon as we are visible, so save on unnecessary API calls. Sometimes too dynamic is not good.
            toLoadOnShowUI_patientID: null,
            toLoadOnShowUI_sessionID: null,

            ///////////////////////////////////////////////////////     
            practiseDetails : {},       

            muscleData : { muscleGroupSet : []},    // hierarchical
            muscleCacheData : {},                   // hash lookup by muscleID { groupIndex : <groupIdx>, viewIndex: <viewIdx>,
                                                    //                           layerIndex: <layerIdx>, muscleDetails: <muscleDetailsData> }
            
            injectionCanvasCache : {},

            hoveringOverInjectionPointDetails : null,

            currentSessionSummaryData: [],     // /rest/session/{sessionID}/injectionSummary
            currentSessionDetailedData: [],    // /rest/session/{sessionID}/injection/all

            sessionsData : [],                  // /rest/session/patient/summary?patientId={patientID}
            previousSessionSummaryData: [],     // /rest/session/{sessionID}/injectionSummary
            previousSessionDetailedData: [],    // /rest/session/{sessionID}/injection/all
            selectedPreviousSessionID: 0,

            currentSessionSummaryTableColumnsConfig : [
                    { minWidth: 50, width: 0, resizable: true, sortable: true, key: 'injectionSummaryName', title: 'Muscle' },
                    { minWidth: 20, width: 0, resizable: true, sortable: true, key: 'medication', title: 'Medication' },
                    { minWidth: 10, width: 0, resizable: true, sortable: true, key: 'injectionTotalAmount', title: 'Units' }
                ],
            previousSessionTableColumnsConfig : [
                    { slot: 'date', minWidth: 30, width: 0, resizable: true, sortable: true, key: 'sessionDate', title: 'Date' },
                    { slot: 'unit', minWidth: 30, width: 0, resizable: true, sortable: true, key: 'totalUnits', title: 'Total Units' }
                ],
            previousSessionSummaryTableColumnsConfig : [
                    { minWidth: 50, width: 0, resizable: true, sortable: true, key: 'injectionSummaryName', title: 'Muscle' },
                    { minWidth: 20, width: 0, resizable: true, sortable: true, key: 'medication', title: 'Medication' },
                    { minWidth: 10, width: 0, resizable: true, sortable: true, key: 'injectionTotalAmount', title: 'Units' }
                ],


            medicationOptions : CONFIG.MEDICATION_OPTIONS,

            // Drives the UI for medication prep info
            // This is the same fields/model as from the api /rest/session/{session_id}/injection/all entry
            // The idea is when we do an injection, we clone this and add a few other fields like
            // injection location, muscle id etc... then we can send new injection data to backend API.
            medicationPrepInfo : {
                injectionMedicationAmount: 1,       // THIS VALUE CANNOT BE ZERO
                injectionMedicationDilution: 2,     // THIS VALUE CANNOT BE ZERO, default is 2
                injectionMedicationId: 0,
                injectionSiteAmount: 1,             // minimum 1
                injectionSiteDilution: 0,
                isEmg: false,
                medicationBatch: "",
                unitByInjectionMedicationAmountUnitCode: "ml",
                unitByInjectionMedicationDilutionUnitCode: "ml",
                unitByInjectionSiteAmountUnitCode: "ml",
                unitByInjectionSiteDilutionUnitCode: "ml",

                medicationExpireDate : ''
            },

            lastUsedBatchInfo : {},

            allowOnlyTodayAndFutureDatesOptions : {
                disabledDate (date) {
                    return date && (date.valueOf() < (Date.now() - (60*60*24*1000)));   // account for today
                }
            },
            medicationMandatoriesOK : false,

            showPreviousInjectionSessions : false,

            //patientID : 28752,
            //currentSessionID : 1520,

            debugOutput : "",
            showDebugInfo : false,

            allMusclesDropdownSelectedMuscleID : 0,
            allMusclesDropdownSelectedMuscleIDPauseWatching : false,

            selectedMuscleID : 0,
            selectedMuscleData : null,

            selectedMuscleLateralPositionIndex : 0,

            // these selected ID's are used to control selection of UI
            selectedMuscleGroupID : 0,
            selectedMuscleViewID : "",
            selectedMuscleLayerID : 0,

            scalingRatio : 1.0, // scaling ration of the injection view relative to the original image.
                                // used to compute the position of click for muscle hit tests and also for
                                // positioning of new injections and showing previous injections.

            injectionView : {
                backgroundURL : "",
                muscleLayerURLs : [{id:0,url:""}, {id:1,url:""}, {id:2,url:""}, {id:3,url:""}, {id:4,url:""}, 
                                   {id:5,url:""}, {id:6,url:""}, {id:7,url:""}, {id:8,url:""}, {id:9,url:""}, 
                                   {id:10,url:""}, {id:11,url:""}, {id:12,url:""}, {id:13,url:""}, {id:14,url:""},
                                   {id:15,url:""}, {id:16,url:""}, {id:17,url:""}, {id:18,url:""}, {id:19,url:""},
                                   {id:20,url:""}, {id:21,url:""}, {id:22,url:""}, {id:23,url:""}, {id:24,url:""},
                                   {id:25,url:""}, {id:26,url:""}, {id:27,url:""}, {id:28,url:""}, {id:29,url:""} ], // have 30 pre-created layers
            },

            renderPreviousSessionInjectionsOnScreen : false,

            additionalMaterialDialog : {
                show : false,
                title : "",
                url : ""
            },


            applyingInjectionProtocol: false,

            perPatientSystemWideInjectionProtocolArray : [],

            practiceInjectionProtocolData : [],
            selectedInjectionProtocolId : -1,

            showNewInjectionProtocolUI: false,
            newInjectionProtocolDetails : {         // formatted with same fields as the API payload
                                               pipName : "",
                                            practiseId : 0, //this.$store.state.loggedInDoctorDetails.practiseId,
                                             sessionId : 0, //this.currentSessionID,
                                           conditionId : 0
                                            },

            undoHistory : [],   // { name: "Delete Injection", operation : function(), payload : Any }   payload will be passed to operation function
            redoHistory : [],
            

            lastThumbnailViewClickedData : undefined,
            dontRestoreThumbClickedForUndo : false,     // flag to not restore/simulate restore for undo delete (when loading session injections) vs load session injections for first time.


            responsiveDetailsSection : {
                xl: 7,
                lg: 7,
                md: 24,
                sm: 24,
                xs: 24,
            },
            responsiveInjectionSection : {
                xl: 17,
                lg: 17,
                md: 24,
                sm: 24,
                xs: 24,
            },
        }
    },
    computed: {
        conditionsRefData() { return this.$store.state.refDataConditions },

        allMusclesSortedByName() {
            // get all muscles from teh muscle cache, just grab the values as we dont need indexing by muscle id, then sort the muscles by the injection summary name
            // which is more descriptive then the muscle name, as it should be more unique.
            return _.values(this.muscleCacheData).sort((a, b) => {
                var isMuscleInCurrentViewA = a != undefined
                                              && a.groupIndex == this.selectedMuscleGroupIndex
                                              && a.viewIndex == this.selectedMuscleViewIndex
                                              && a.layerIndex == this.selectedMuscleLayerIndex
                var isMuscleInCurrentViewB = b != undefined
                                              && b.groupIndex == this.selectedMuscleGroupIndex
                                              && b.viewIndex == this.selectedMuscleViewIndex
                                              && b.layerIndex == this.selectedMuscleLayerIndex

                if (!isMuscleInCurrentViewA && isMuscleInCurrentViewB)
                    return 1
                else if (isMuscleInCurrentViewA && !isMuscleInCurrentViewB)
                    return -1
                
                // else both in or both not in current muscle group... so subsort by name
                return a.muscleDetails.injectionSummaryName.toLowerCase().localeCompare(b.muscleDetails.injectionSummaryName.toLowerCase())
            })
        },
       
        patientID()          { return this.$store.state.currentPatientID; },
        currentSessionID()   { return this.$store.state.currentSessionID; },

        unionOfCurrentAndSelectedPreviousSessionInjectionDetailedData : function() {
            
            if (this.renderPreviousSessionInjectionsOnScreen == false)
                return this.currentSessionDetailedData

            // if the user has selected the same session as the currently being edited session
            // just return any one of the arrays... no point returning the same injection points twice.
            if (this.currentSessionID == this.selectedPreviousSessionID) {
                return this.currentSessionDetailedData
            }

            // concatinate the two collections of injection details together
            return this.currentSessionDetailedData.concat(this.previousSessionDetailedData);
        },
/*
created: 1623287169093
            createdBy: 63
            injectionId: 12786
            injectionMedicationAmount: 100
            injectionMedicationDilution: 2
            injectionMedicationId: 1
            injectionSiteAmount: 1
            injectionSiteDilution: 0.02
            injectionXPoint: 0.3411
            injectionYPoint: 0.1525
            isEmg: false
            medicationBatch: ""
            muscleImageId: 7
            sessionId: 1516
            unitByInjectionMedicationAmountUnitCode: "ml"
            unitByInjectionMedicationDilutionUnitCode: "ml"
            unitByInjectionSiteAmountUnitCode: "ml"
            unitByInjectionSiteDilutionUnitCode: "ml"
*/
        thumbnailInjectionViewsMuscleData : function() {
            let injections = this.unionOfCurrentAndSelectedPreviousSessionInjectionDetailedData
            var viewsOfInjections = {}
            var muscleHierarchyData = null
            var uniqueGroupViewID = ""

            injections.forEach(injection => {
                muscleHierarchyData = this.muscleCacheData[injection.muscleImageId]
                
                if (muscleHierarchyData === undefined) {
                    console.log("missing muscle data: ", injection.muscleImageId)
                } else {
                    // create a tuple of group/view/layer ID, and then group all the injections into these tuple groups
                    uniqueGroupViewID = muscleHierarchyData.groupIndex + "-" + muscleHierarchyData.viewIndex + "-" + muscleHierarchyData.layerIndex

                    if (!(uniqueGroupViewID in viewsOfInjections)) {
                        viewsOfInjections[uniqueGroupViewID] = {
                                        groupViewID : uniqueGroupViewID,
                                        // groupID : this.muscleData.muscleGroupSet[muscleHierarchyData.groupIndex].muscleGroupId,
                                        // viewID : this.muscleData.muscleGroupSet[muscleHierarchyData.groupIndex].viewSet[muscleHierarchyData.viewIndex].view,
                                        viewBackgroundImageURL : muscleHierarchyData.muscleDetails.thumbnailImageFile,
                                        injections : []
                                    }
                    }

                    viewsOfInjections[uniqueGroupViewID].injections.push(injection)
                }
            })
            var keys = Object.keys(viewsOfInjections).sort()
            var viewsOfInjectionsOrderedByGroupIndexAndViewIndex = []

            keys.forEach(key => {
                viewsOfInjectionsOrderedByGroupIndexAndViewIndex.push(viewsOfInjections[key])
            })

            return viewsOfInjectionsOrderedByGroupIndexAndViewIndex
        },

        selectedMedicationIndex : function() {
            var index = 0
            for (index = 0; index < this.medicationOptions.length; index++) {
                if (this.medicationOptions[index].medicationID === this.medicationPrepInfo.injectionMedicationId) {
                    break;
                }
            }
            return index;
        },

        medicationDilutionRatio : function() { return this.medicationPrepInfo.injectionMedicationAmount / this.medicationPrepInfo.injectionMedicationDilution },

        /*
            convenience functions to convert between ID's and array indexes.
            we need array indexes in other parts of the app to compute via the muscle data structure
            whether we can navigate to something appropriate.
        */
        selectedMuscleGroupIndex : function(){
            var index = 0
            for (index = 0; index < this.muscleData.muscleGroupSet.length; index++) {
                if (this.muscleData.muscleGroupSet[index].muscleGroupId == this.selectedMuscleGroupID) {
                    break;
                }
            }
            return index;
        },

        selectedMuscleViewIndex : function(){
            var index = 0;
            for (index = 0; index < this.currentMuscleViewArray.length; index++) {
                if (this.currentMuscleViewArray[index].view == this.selectedMuscleViewID) { // viewCode
                    break;
                }
            }
            return index;
        },

        // NOTE: level 0 is the top most surface (say skin), higher levels go 'deeper inward' towards the bone.
        selectedMuscleLayerIndex : function(){
            var index = 0;
            for (index = 0; index < this.currentMuscleLayerArray.length; index++) {
                if (this.currentMuscleLayerArray[index].layerId == this.selectedMuscleLayerID) {
                    break;
                }
            }
            return index;
        },

        currentMuscleViewArray : function() { return (this.selectedMuscleGroupIndex < this.muscleData.muscleGroupSet.length) ? this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].viewSet : [] },
        currentMuscleLayerArray : function() { return (this.selectedMuscleViewIndex < this.currentMuscleViewArray.length) ? this.currentMuscleViewArray[this.selectedMuscleViewIndex].layerSet : [] },

        isAtTopMostLayer : function() { return this.selectedMuscleLayerIndex == 0 },
        isAtBottomMostLayer : function() { // check we have valid indexes to access the arrays, then check to see if layer index is at the end of the array.
                                            return this.selectedMuscleGroupIndex < this.muscleData.muscleGroupSet.length
                                                    && this.selectedMuscleViewIndex < this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].viewSet.length
                                                    && this.selectedMuscleLayerIndex == this.muscleData
                                                                                        .muscleGroupSet[this.selectedMuscleGroupIndex]
                                                                                        .viewSet[this.selectedMuscleViewIndex]
                                                                                        .layerSet.length - 1 },

        /*
            for nextNavigateLeftMuscleViewIndex, nextNavigateRightMuscleViewIndex

            if views can be thought of as a grid navigating horizontally and
            if layers can be thought of as a grid navigating vertically then
            
            if the following are the available views and the layers in the views...

            X X X X
            X   X X
            X   X  
                X

            you can see that you can't always navigate horizontally while preserving the same layer level.
            Thus the below functions, help by peeking into the next possible view, and checking
            to confirm that a layer of the same level as currently selected exists. If it does not exist
            we keep navigating to the next view and checking again. 
            Best case we find a new view we can switch to safely, worse case we can't find a new view
            and we end up finding ourself/current selected view again.

        */
        nextNavigateLeftMuscleViewIndex : function() {
            var jumpToIndex = this.selectedMuscleViewIndex
            
            // sanity check when muscle view changes not via navigation wheel
            if (jumpToIndex > this.currentMuscleViewArray.length - 1)
                return 0

            do {
                jumpToIndex = jumpToIndex - 1
                if (jumpToIndex < 0)
                    jumpToIndex = this.currentMuscleViewArray.length - 1;
            } while (this.selectedMuscleLayerIndex >= this.currentMuscleViewArray[jumpToIndex].layerSet.length)

            return jumpToIndex
        },
        nextNavigateRightMuscleViewIndex : function() {
            var jumpToIndex = this.selectedMuscleViewIndex
            
            // sanity check when muscle view changes not via navigation wheel
            if (jumpToIndex > this.currentMuscleViewArray.length - 1)
                return 0

            do {
                jumpToIndex = jumpToIndex + 1
                if (jumpToIndex >= this.currentMuscleViewArray.length)
                    jumpToIndex = 0;
            } while (this.selectedMuscleLayerIndex >= this.currentMuscleViewArray[jumpToIndex].layerSet.length)

            return jumpToIndex
        },

        canNavigateLeft : function() {
            // if the next view when navigating left is the same as the current view, then effectively we can't navigate left,
            // otherwise there is something else that we can navigate to, so allow navigation
            return this.nextNavigateLeftMuscleViewIndex == this.selectedMuscleViewIndex
        },
        canNavigateRight : function() {
            // if the next view when navigating right is the same as the current view, then effectively we can't navigate right,
            // otherwise there is something else that we can navigate to, so allow navigation
            return this.nextNavigateRightMuscleViewIndex == this.selectedMuscleViewIndex
        },

        canToggleLeftRight : function() {
            if (this.selectedMuscleData != null
                && (this.selectedMuscleData.injectionSummaryName.includes("Right")
                || this.selectedMuscleData.injectionSummaryName.includes("Left"))) {
                
                return true

            } else if (this.selectedMuscleViewID.includes("Right") || this.selectedMuscleViewID.includes("Left")) {
                 
                 return true

            }

            return false
        },

        totalUnitsForCurrentSession() {
            return this.currentSessionSummaryData
                       .reduce((totalUnits, injectionEntry) => totalUnits + injectionEntry.injectionTotalAmount, 0 )
        },
        totalUnitsForSelectedSession() {
            return this.previousSessionSummaryData
                       .reduce((totalUnits, injectionEntry) => totalUnits + injectionEntry.injectionTotalAmount, 0 )
        },

        ///// MEDICATION DETAILS VALIDATION //////
        medicationPrepInfoRules() {
            if (this.practiseDetails.isBatchMandatory == false)
                return {}

            return {
                        medicationBatch: [
                            { required: true, message: 'Batch identifier required', trigger: 'blur' },
                        ],
                        medicationExpireDate: [
                            { required: true, type: 'date', message: 'Expiry date required', trigger: 'blur' },
                        ],
                    }
        },

        // if practice requires batch mandatory info... make the field messages visible.
        mandatoryFieldsStyle() { return (this.practiseDetails.isBatchMandatory && this.medicationMandatoriesOK == false) ? "revealValidationMessages" : "" },


        // New Injection Protocol Helpers
        availablePracticeInjectionProtocols() {
            var patientConditions = []
            
            if (this.$store.state.currentSelectedPatientDetails.patientConditionIds != undefined)
                patientConditions = this.$store.state.currentSelectedPatientDetails.patientConditionIds

            var allApplicablePracticeProtocols = this.practiceInjectionProtocolData.filter(entry => {
                                                        if (entry.pipId == 0) return true                           // return our special create entry
                                                        if (entry.status != 'A') return false                       // don't return any non-active ones
                                                        if (patientConditions.includes(entry.conditionId)) return true    // include protocols applicable for patients conditions
                                                        
                                                        return false    // exclude all others
                                                    })

            return _.concat(_.slice(allApplicablePracticeProtocols, 0, 1),      // grab the "-- Create Protocol ---" special entry
                            this.perPatientSystemWideInjectionProtocolArray,    // append any System Wide Injection Protocols applicable for this patent (with specific conditions)
                            _.slice(allApplicablePracticeProtocols, 1)         // append the rest.
                            )
        },

        isDuplicateInjectionProtocolName() {
            // if the current user configured new Injection Protocol Name exists in the current list of practice injection protocol's, then return true indicating we have a duplicate
            return this.practiceInjectionProtocolData.find(entry => { return entry.pipName == this.newInjectionProtocolDetails.pipName }) != undefined
        },

    },
    methods: {
        

        // create muscle cache data for faster lookup from hierarchical muscle data
        createMuscleCacheData() {
            this.muscleCacheData = {}   // clear first
            
            var groupIndex = 0
            var viewIndex = 0
            var layerIndex = 0
            var muscleIndex = 0
            
            for (groupIndex = 0; groupIndex < this.muscleData.muscleGroupSet.length; groupIndex++) {
                for (viewIndex = 0; viewIndex < this.muscleData.muscleGroupSet[groupIndex].viewSet.length; viewIndex++) {
                    for (layerIndex = 0; layerIndex < this.muscleData.muscleGroupSet[groupIndex].viewSet[viewIndex].layerSet.length; layerIndex++) {
                        for (muscleIndex = 0; muscleIndex < this.muscleData.muscleGroupSet[groupIndex].viewSet[viewIndex].layerSet[layerIndex].muscleSet.length; muscleIndex++) {
                            var muscleEntry = this.muscleData.muscleGroupSet[groupIndex].viewSet[viewIndex].layerSet[layerIndex].muscleSet[muscleIndex]

                            this.muscleCacheData[muscleEntry.muscleId] = {
                                    groupIndex : groupIndex,
                                    viewIndex: viewIndex,
                                    layerIndex: layerIndex,
                                    muscleDetails: muscleEntry.lateralPositionSet[0].muscleImageData[0]
                                }
                        }                                            
                    }                    
                }
            }

//            console.log(this.muscleCacheData)
        },

        handleSearchMuscles() {
            this.$refs['listOfAllMuscles'].isFocused = true
            this.$refs['listOfAllMuscles'].clearSingleSelect()
        },

        handleInjectionViewResize(element) {
            // the resizer directive operates on the container level, since we can't actually add to the 
            // background image element itself.
            // so when we are called on resize of the parent, we look for the layer_background inside the parent
            // (optimization as opposed to doing a whole document getElementById), and then get its size.
            var imageBackgroundElement = element.querySelector("#" + "layer_background");

            if (imageBackgroundElement != null) {
                //console.log('resized', imageBackgroundElement.clientWidth, imageBackgroundElement.clientHeight)
                this.scalingRatio = imageBackgroundElement.clientWidth / IMAGE_SIZE_WIDTH     // HARD CODED IMAGE WIDTH of all images.
            }

            // resized... invalidate our cache to force fresh data in for the new scale.
            this.injectionCanvasCache = {}
        },

        simInject() {
            var simPoints = document.getElementById('SimInjectXY').value.split(';')
            for (var simPoint in simPoints) {
                var simPointXY = simPoints[simPoint].split(',')
                if (simPointXY.length == 2) {
                    this.handleClickOnInjectionView({offsetX:simPointXY[0], offsetY: simPointXY[1]})
                }
            }
            
        },

        simKillInjection() {
            this.currentSessionDetailedData.map(entry => {
                itBnTx.deleteInjection({
                                        inSessionID : entry.sessionId,
                                      inInjectionID : entry.injectionId
                                    })
            })
            setTimeout(() => {this.loadDataOnChangeInSessionID(this.currentSessionID)}, 1000)
        },

        handleClickOnInjectionView(element) {
            console.log(element)
            console.log("handleClickOnInjectionView>>", element.offsetX, element.offsetY);
            try {
                document.getElementById('SimInjectXY').value = element.offsetX + "," + element.offsetY + ";";
            } catch(e) {
                console.log(e)
            }

            var muscleLayerIndex = this.injectionView.muscleLayerURLs.length - 1;
            var hitDetected = false

            for (; muscleLayerIndex--; muscleLayerIndex >= 0) {
                if (this.injectionView.muscleLayerURLs[muscleLayerIndex].url == "") {
                    continue
                } else {
                    // REF: http://jsfiddle.net/thirtydot/9SEMf/869/ - hit test code.

                    var musclelayerImage = document.getElementById("muscle_layer_" + muscleLayerIndex)

                    //console.log("musclelayerImage: ", musclelayerImage.src)
                    var context = this.injectionCanvasCache[musclelayerImage.src]

                    if (context == undefined)
                    {
                        /*
                            add following line to work around the following CORS error:
                            vue.runtime.esm.js?2b0e:1888 DOMException: Failed to execute 'getImageData' on 'CanvasRenderingContext2D': The canvas has been tainted by cross-origin data.

                            See: https://stackoverflow.com/questions/22097747/how-to-fix-getimagedata-error-the-canvas-has-been-tainted-by-cross-origin-data
                            Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image

                            NOTE:
                            Some browsers dont work even with this fix... so to 'sort it permanently' we add the appropriate CORS headers
                            on the AWS Cloudfront configuration:
                            https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/example-function-add-cors-header-response.html

                            Also setup CORS in S3 bucket
                            https://docs.aws.amazon.com/AmazonS3/latest/userguide/enabling-cors-examples.html

                            adding...

                            [
                                {
                                    "AllowedHeaders": [
                                        "*"
                                    ],
                                    "AllowedMethods": [
                                        "GET"
                                    ],
                                    "AllowedOrigins": [
                                        "*"
                                    ],
                                    "ExposeHeaders": [],
                                    "MaxAgeSeconds": 3000
                                }
                            ]
                        */
                        musclelayerImage.crossOrigin = "anonymous";


                        //////////////////////
                        var muscleCanvas = document.createElement('canvas');
                        muscleCanvas.width = IMAGE_SIZE_WIDTH;
                        muscleCanvas.height = IMAGE_SIZE_HEIGHT;

                        context = muscleCanvas.getContext("2d");

                        // cache our context... to optimize hit test speed
                        this.injectionCanvasCache[musclelayerImage.src] = context
                    } else {
                        console.log("CANVAS CONTEXT CACHE FOUND - ", musclelayerImage.src)
                    }

                    context.fillRect(0, 0, IMAGE_SIZE_WIDTH, IMAGE_SIZE_HEIGHT);
                    context.drawImage(musclelayerImage, 0, 0, IMAGE_SIZE_WIDTH, IMAGE_SIZE_HEIGHT);

                    var injectPointXInOriginalScale = element.offsetX * 1.0/this.scalingRatio
                    var injectPointYInOriginalScale = element.offsetY * 1.0/this.scalingRatio

                    var pixelData = context.getImageData(injectPointXInOriginalScale, injectPointYInOriginalScale, 1, 1).data;

                    this.debugOutput = 'R: ' + pixelData[0] + ', G: ' + pixelData[1] + ', B: ' + pixelData[2] + ', A: ' + pixelData[3];

                    console.log("DATA >,", muscleLayerIndex, this.debugOutput);

                    if ((pixelData[0] != 0
                        || pixelData[1] != 0
                        || pixelData[2] != 0)
                        && pixelData[3] != 0) {
                        
                        console.log("HIT!!", muscleLayerIndex);

                        try {
                            this.selectedMuscleData = this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex]
                                                                        .viewSet[this.selectedMuscleViewIndex]
                                                                        .layerSet[this.selectedMuscleLayerIndex]
                                                                        .muscleSet[muscleLayerIndex]
                                                                        .lateralPositionSet[this.selectedMuscleLateralPositionIndex]
                                                                        .muscleImageData[0]

                            if (this.selectedMuscleID != this.selectedMuscleData.muscleId) {
                                // user has selected a new muscle
                                this.selectedMuscleID = this.selectedMuscleData.muscleId
                                this.allMusclesDropdownSelectedMuscleID = this.selectedMuscleData.muscleId
                            } else {
                                // user has clicked on the same muscle, so we assume they now want to start injecting
                                this.createNewInjectionPoint({  inX : injectPointXInOriginalScale / IMAGE_SIZE_WIDTH,
                                                                inY : injectPointYInOriginalScale / IMAGE_SIZE_HEIGHT,
                                                                inMuscleID : this.selectedMuscleID })
                            }
                            
                        } catch (err) {
                            console.log(err)
                        }

                        hitDetected = true
                        break
                    }

                }



            } /* for */

            if (hitDetected == false) {
                this.selectedMuscleID = 0
                this.allMusclesDropdownSelectedMuscleID = 0
                this.selectedMuscleData = null
            }
            
        },


        updateInjectionView() {
            // if (this.selectedMuscleViewIndex < this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].viewSet.length - 1
            //     && this.selectedMuscleLayerIndex < this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].viewSet[this.selectedMuscleViewIndex].layerSet.length - 1)
            {
                this.injectionView.backgroundURL = this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex]
                                                                    .viewSet[this.selectedMuscleViewIndex]
                                                                    .layerSet[this.selectedMuscleLayerIndex]
                                                                    .muscleSet[0]
                                                                    .lateralPositionSet[this.selectedMuscleLateralPositionIndex]
                                                                    .muscleImageData[0]
                                                                    .backgroundImageFile;


                var totalMusclesInView = this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex]
                                                        .viewSet[this.selectedMuscleViewIndex]
                                                        .layerSet[this.selectedMuscleLayerIndex]
                                                        .muscleSet.length
                var index = 0;

                for (index = 0; index < totalMusclesInView; index++) {
// console.log("ABOUT TO SET")
                    this.injectionView.muscleLayerURLs[index].url = this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex]
                                                                                    .viewSet[this.selectedMuscleViewIndex]
                                                                                    .layerSet[this.selectedMuscleLayerIndex]
                                                                                    .muscleSet[index]
                                                                                    .lateralPositionSet[this.selectedMuscleLateralPositionIndex]
                                                                                    .muscleImageData[0]
                                                                                    .overlayImageFile;
// console.log("FINISHED SET")
                } 

                for (; index < 30; index++) {
                    this.injectionView.muscleLayerURLs[index].url = "";
                }

            }
        },

        handleThumbnailViewClicked(inThumbnailViewData) {

            this.lastThumbnailViewClickedData = _.cloneDeep(inThumbnailViewData)

            //console.log(inThumbnailViewData)
            // switch to the group/view/layer of the first injection in this view...
            this.switchInjectionViewToShowMuscle(inThumbnailViewData.injections[0].muscleImageId)

            // after switching all the UI is setup with the group, view, layer... so we can get these to report on easily...
            setTimeout(() => {
                try {
                    AnalyticsMgr.sendEvent('/Injections/ThumbnailView', 'click', 'T_VIEW_'
                                            + this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].muscleGroup
                                            + '_' + this.currentMuscleViewArray[this.selectedMuscleViewIndex].view
                                            + '_' + this.currentMuscleLayerArray[this.selectedMuscleLayerIndex].layer
                                            //+ '_' + this.muscleCacheData[inThumbnailViewData.injections[0].muscleImageId].muscleDetails.injectionSummaryName
                                            )
                } catch { console.log("oh dear") }
            }, 400) // remove this ugly timer hack when we figure out why the other timer hack of 200ms can be removed.
        },

        handleRightClickInjectionPoint(injectionDetails) {
            console.log("right click ", injectionDetails)
            alert("requesting delete of injection point index: " + injectionDetails.injectionId)
        },

        handleNavigateUp() {
            console.log("handleNavigateUp");

            AnalyticsMgr.sendEvent('/Injections/Navigation', 'click', 'Nav_Up')

            // check if we are at the top-most layer, if so, just exit
            if (this.isAtTopMostLayer) {
                return;
            } else {
                this.selectedMuscleLayerID = this.muscleData
                                                .muscleGroupSet[this.selectedMuscleGroupIndex]
                                                .viewSet[this.selectedMuscleViewIndex]
                                                .layerSet[this.selectedMuscleLayerIndex - 1]
                                                .layerId;
            }

            // update the injection UI
            this.updateInjectionView()
        },
        handleNavigateDown() {
            console.log("handleNavigateDown");

            AnalyticsMgr.sendEvent('/Injections/Navigation', 'click', 'Nav_Down')

            // check if we are at the bottom-most layer (in this view), if so, just exit
            if (this.isAtBottomMostLayer) {
                return;
            } else {
                this.selectedMuscleLayerID = this.muscleData
                                                .muscleGroupSet[this.selectedMuscleGroupIndex]
                                                .viewSet[this.selectedMuscleViewIndex]
                                                .layerSet[this.selectedMuscleLayerIndex + 1]
                                                .layerId;
            }

            // update the injection UI
            this.updateInjectionView()
        },
        handleNavigateLeft() {
            console.log("handleNavigateLeft");

            AnalyticsMgr.sendEvent('/Injections/Navigation', 'click', 'Nav_Left')

            this.selectedMuscleViewID = this.currentMuscleViewArray[this.nextNavigateLeftMuscleViewIndex].view // viewCode

            // update the injection UI
            this.updateInjectionView()
        },
        handleNavigateRight() {
            console.log("handleNavigateRight");

            AnalyticsMgr.sendEvent('/Injections/Navigation', 'click', 'Nav_Right')

            this.selectedMuscleViewID = this.currentMuscleViewArray[this.nextNavigateRightMuscleViewIndex].view // viewCode

            // update the injection UI
            this.updateInjectionView()

        },

        handleToggleLeftRight() {
            // console.log("MUSCLE", this.selectedMuscleData)

            AnalyticsMgr.sendEvent('/Injections/Navigation', 'click', 'Nav_ToggleLeftRight')

            if (this.selectedMuscleData != null
                && (this.selectedMuscleData.injectionSummaryName.includes("Right")
                || this.selectedMuscleData.injectionSummaryName.includes("Left"))) {
                var currentMuscleName = this.selectedMuscleData.injectionSummaryName
                var leftRightSwappedMuscleName = ""

                if (currentMuscleName.includes("Right"))
                    leftRightSwappedMuscleName = currentMuscleName.replace("Right", "Left")
                else if (currentMuscleName.includes("Left"))
                    leftRightSwappedMuscleName = currentMuscleName.replace("Left", "Right")
                
                for (var muscleID in this.muscleCacheData) {
                    if (this.muscleCacheData[muscleID].muscleDetails.injectionSummaryName == leftRightSwappedMuscleName) {
                        this.switchInjectionViewToShowMuscle(muscleID)
                        break
                    }
                }
            } else if (this.selectedMuscleViewID.includes("Right") || this.selectedMuscleViewID.includes("Left")) {
                var currentViewName = this.selectedMuscleViewID
                var leftRightSwappedViewName = ""

                if (currentViewName.includes("Right"))
                    leftRightSwappedViewName = currentViewName.replace("Right", "Left")
                else if (currentViewName.includes("Left"))
                    leftRightSwappedViewName = currentViewName.replace("Left", "Right")
                
                this.selectedMuscleViewID = leftRightSwappedViewName
            }

        },


        /*
            backgroundImageFile: "https://test-content.bntxinteract.com/muscle-images-20210324/right_shoulder_upper_arm-posterior-intermediate-right-bg-v1.png"
            cadaverVideo: "https://test-content.bntxinteract.com/cadaver-videos/"
            emgVideo: "https://test-content.bntxinteract.com/emg-videos/"
            imageName: "right_shoulder_upper_arm-posterior-intermediate-teres_minor-right-v1.png"
            imageVersion: 1
            injectionSummaryName: "Right Teres Minor"
            masterImageFile: "https://test-content.bntxinteract.com/muscle-images-20210324/right_shoulder_upper_arm-posterior-intermediate-right-master-v1.png"
            model3D: ""
            muscleId: 139
            muscleName: "Teres Minor"
            muscleSurfaceAnatomyInstructions: "https://test-content.bntxinteract.com/surface-anatomy/"
            overlayImageFile: "https://test-content.bntxinteract.com/muscle-images-20210324/right_shoulder_upper_arm-posterior-intermediate-teres_minor-right-v1.png"
        */

        // show Surface Anatomy material for currently selected muscle
        handleSurfaceAnatomyRequest() {
            this.additionalMaterialDialog.url = this.selectedMuscleData.muscleSurfaceAnatomyInstructions
            this.additionalMaterialDialog.title = "Surface Anatomy"
            this.additionalMaterialDialog.show = true

            try {
                AnalyticsMgr.sendEvent('/Injections/MuscleInformation', 'click', 'SurfaceAnatomy_' + this.selectedMuscleID + '_'+ this.selectedMuscleData.muscleName)
            } catch {
                console.log("handleSurfaceAnatomyRequest", this.selectedMuscleData)
            }
        },

        // show Cadaver Video material for currently selected muscle
        handleCadaverVideoRequest() {
            this.additionalMaterialDialog.url = this.selectedMuscleData.cadaverVideo
            this.additionalMaterialDialog.title = "Cadaver Video"
            this.additionalMaterialDialog.show = true

            try {
                AnalyticsMgr.sendEvent('/Injections/MuscleInformation', 'click', 'CadaverVideo_' + this.selectedMuscleID + '_'+ this.selectedMuscleData.muscleName)
            } catch {
                console.log("handleSurfaceAnatomyRequest", this.selectedMuscleData)
            }
        },
        
        // show EMG Video material for currently selected muscle
        handleEMGVideoRequest() {
            this.additionalMaterialDialog.url = this.selectedMuscleData.emgVideo
            this.additionalMaterialDialog.title = "EMG Video"
            this.additionalMaterialDialog.show = true

            try {
                AnalyticsMgr.sendEvent('/Injections/MuscleInformation', 'click', 'EMGVideo_' + this.selectedMuscleID + '_'+ this.selectedMuscleData.muscleName)
            } catch {
                console.log("handleSurfaceAnatomyRequest", this.selectedMuscleData)
            }
        },

        // show 3D Model material for currently selected muscle
        handle3DModelRequest() {
            this.additionalMaterialDialog.url = this.selectedMuscleData.model3D
            this.additionalMaterialDialog.title = "3D Model"
            this.additionalMaterialDialog.show = true

            AnalyticsMgr.sendEvent('/Injections/MuscleInformation', 'click', '3DModel')
        },


        resyncUIOnMuscleViewSelectorChange() {
            // reset the current selected indexes for sub categories "muscle layer" and lower...
            this.selectedMuscleLateralPositionIndex = 0;

            // when we change the view, just select the first layer of that view, unless we can preserve the layer level in the new view.
            if (this.selectedMuscleLayerIndex > this.currentMuscleLayerArray.length - 1)
                this.selectedMuscleLayerID = this.currentMuscleLayerArray[0].layerId
            else
                this.selectedMuscleLayerID = this.currentMuscleLayerArray[this.selectedMuscleLayerIndex].layerId

            // update the injection UI
            this.updateInjectionView()
        },

        resyncUIOnMuscleGroupSelectorChange() {
            // reset the current selected indexes for sub categories "muscle view" and lower...
            this.selectedMuscleLateralPositionIndex = 0;

            // when we change the muscle group, we default to the first view and first layer of that view.
            this.selectedMuscleViewID = this.currentMuscleViewArray[0].view // viewCode

            // sync the UI with current values.
            this.resyncUIOnMuscleViewSelectorChange()
        },

        // This function is used to dynamically determing the css classes for the various muscle layers, specifically
        // to 'highlight' which is the selected one and which ones are not.
        selectedMuscleLayerCSSClass(inMuscleIndex) {
            try {
                if (this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex]
                                            .viewSet[this.selectedMuscleViewIndex]
                                            .layerSet[this.selectedMuscleLayerIndex]
                                            .muscleSet[inMuscleIndex]
                                            .lateralPositionSet[this.selectedMuscleLateralPositionIndex]
                                            .muscleImageData[0].muscleId == this.selectedMuscleID) {
                    return "layer_muscle activated"
                } else {
                    return "layer_muscle deactivated"
                }
            } catch {
                return "layer_muscle deactivated"
            }
        },

        muscleLayerElementID(inMuscleIndex) {
            return "muscle_layer_" + inMuscleIndex
        },

        thumbnailInjectionPointPositionByStyle(injectionDetails) {
            return "top:" + injectionDetails.injectionYPoint * 116 + "px;"
                   + "left:" + injectionDetails.injectionXPoint * 102 + "px;"
                   + "position: absolute;"
        },

        injectionPointPositionByStyle(injectionDetails) {
            var imageBackgroundElement = document.querySelector("#" + "layer_background");

            return "top:" + injectionDetails.injectionYPoint * imageBackgroundElement.height + "px;"
                   + "left:" + injectionDetails.injectionXPoint * imageBackgroundElement.width + "px;"
                   + "position: absolute;"
        },

        injectionPointLabelPositionStyle(inSessionID) {
            if (inSessionID == this.currentSessionID) {
                return "injectionPointLabelCurrentInjection"
            }

            return "injectionPointLabelPreviousInjection"
        },

        isInjectionPointCurrentlyVisible(injectionDetails) {
            try {
                // console.log("isInjectionPointCurrentlyVisible MID> ", injectionDetails.muscleImageId)
                // console.log("isInjectionPointCurrentlyVisible G> ", this.muscleCacheData[injectionDetails.muscleImageId].groupIndex, this.selectedMuscleGroupIndex)            
                // console.log("isInjectionPointCurrentlyVisible V> ", this.muscleCacheData[injectionDetails.muscleImageId].viewIndex, this.selectedMuscleViewIndex)            
                // console.log("isInjectionPointCurrentlyVisible L> ", this.muscleCacheData[injectionDetails.muscleImageId].layerIndex, this.selectedMuscleLayerIndex)   

                var isInCurrentMuscleGroup = this.muscleCacheData[injectionDetails.muscleImageId].groupIndex == this.selectedMuscleGroupIndex
                var isInCurrentView = this.muscleCacheData[injectionDetails.muscleImageId].viewIndex == this.selectedMuscleViewIndex
                var isInCurrentLayer = this.muscleCacheData[injectionDetails.muscleImageId].layerIndex == this.selectedMuscleLayerIndex

                // if the injection point is in the currently shown muscle group
                // AND the current view'ing orientation/group
                // AND the currently displaying layer/depth
                if (isInCurrentMuscleGroup == true
                    && isInCurrentView == true
                    && isInCurrentLayer == true) {
                    
                    return true
                } else {
                    return false
                }
            } catch {
                console.log("isInjectionPointCurrentlyVisible - muscle lookup failed");
                return false
            }
        },

        injectionPointImage(injectionDetails, forceShow) {

            // if injection point is currently visible to user... show it and the appropriate status
            if (forceShow == true || this.isInjectionPointCurrentlyVisible(injectionDetails) == true) {

                /*
                    correctly pick the relevant image from:

                    injection_emg_current.png
                    injection_emg_highlight.png
                    injection_emg_previous.png
                    injection_standard_current.png
                    injection_standard_highlight.png
                    injection_standard_previous.png

                    NOTE: can only highlight 'current session' injection points

                    INFO: https://stackoverflow.com/questions/7415872/change-color-of-png-image-via-css
                */
                let isCurrent = injectionDetails.sessionId == this.currentSessionID

                return "./images/injection"
                        + ((injectionDetails.isEmg == true) ? "_emg" : "_standard")
                        + ((isCurrent) ? ((this.hoveringOverInjectionPointDetails != null && this.hoveringOverInjectionPointDetails.injectionId == injectionDetails.injectionId) ? "_highlight" : "_current")
                                    : "_previous")
                        + ".png"
            }

            return ""
        },

        currentSessionHighlightClass(sessionDetails) {
            return (sessionDetails.sessionId == this.currentSessionID) ? "currentSessionRowHighlight" : ""
        },

        switchInjectionViewToShowMuscle(inMuscleID) {

            if (this.allMusclesDropdownSelectedMuscleIDPauseWatching == true) {
                console.log("Already queued switching muscle, skipping this request: ", inMuscleID);
                return;
            }

            if (inMuscleID == 0)
                return;

            var self = this
            let muscleLocationData = this.muscleCacheData[inMuscleID]

            if (muscleLocationData == undefined) {
                
                return
            }

            self.allMusclesDropdownSelectedMuscleIDPauseWatching = true

            this.selectedMuscleGroupID = this.muscleData.muscleGroupSet[muscleLocationData.groupIndex].muscleGroupId

            // there is a strange timing issue when rapidly setting all the group/view/layer up... so we put in a small delay. appears to solve it.
            setTimeout(function() {
                self.selectedMuscleViewID = self.muscleData.muscleGroupSet[muscleLocationData.groupIndex].viewSet[muscleLocationData.viewIndex].view
                self.selectedMuscleLayerID = self.muscleData.muscleGroupSet[muscleLocationData.groupIndex].viewSet[muscleLocationData.viewIndex].layerSet[muscleLocationData.layerIndex].layerId
                self.selectedMuscleData = muscleLocationData.muscleDetails
                self.selectedMuscleID = self.selectedMuscleData.muscleId

                self.allMusclesDropdownSelectedMuscleID = self.selectedMuscleData.muscleId
                self.allMusclesDropdownSelectedMuscleIDPauseWatching = false
            }, 200)
        },

        handleCurrentSessionSummaryRowClicked(clickedRowData, clickedRowIndex) {
            clickedRowIndex

            this.switchInjectionViewToShowMuscle(clickedRowData.muscleId)

            // after switching all the UI is setup with the group, view, layer... so we can get these to report on easily...
            setTimeout(() => {
                try {
                    AnalyticsMgr.sendEvent('/Injections/CurrentSessionSummary', 'click', 'CS_VIEW_'
                                            + this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].muscleGroup
                                            + '_' + this.currentMuscleViewArray[this.selectedMuscleViewIndex].view
                                            + '_' + this.currentMuscleLayerArray[this.selectedMuscleLayerIndex].layer
                                            + '_' + this.muscleCacheData[clickedRowData.muscleId].muscleDetails.injectionSummaryName
                                            )
                } catch { console.log("oh dear") }
            }, 400) // remove this ugly timer hack when we figure out why the other timer hack of 200ms can be removed.
        },

        handleAllSessionSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            this.renderPreviousSessionInjectionsOnScreen = false
            
            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                this.previousSessionSummaryData = []
                this.previousSessionDetailedData = []
                this.selectedPreviousSessionID = 0
                return
            }

            this.renderPreviousSessionInjectionsOnScreen = true
            this.selectedPreviousSessionID = currentRow.sessionId


            var today = new Date()
            AnalyticsMgr.sendEvent('/Injections/PreviousSessionSummary', 'click', 'ViewSessionFromDaysAgo_' + Math.ceil((today.getTime() - currentRow.sessionDate) / (1000 * 3600 * 24)) )
            

            itBnTx.getInjectionSummaryForSession({inSessionID : this.selectedPreviousSessionID})
                  .then(response => { this.previousSessionSummaryData = response.data })
                  .catch(error => {error; this.previousSessionSummaryData = [] })

            itBnTx.getInjectionsForSession({inSessionID : this.selectedPreviousSessionID})
                  .then(response => { this.previousSessionDetailedData = response.data })
                  .catch(error => {error; this.previousSessionDetailedData = [] })

        },

        handleSelectedSessionSummaryRowClicked(clickedRowData, clickedRowIndex) {
            clickedRowIndex

            this.switchInjectionViewToShowMuscle(clickedRowData.muscleId)

            // after switching all the UI is setup with the group, view, layer... so we can get these to report on easily...
            setTimeout(() => {
                try {
                    AnalyticsMgr.sendEvent('/Injections/PreviousSessionSummary', 'click', 'PS_VIEW_'
                                            + this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].muscleGroup
                                            + '_' + this.currentMuscleViewArray[this.selectedMuscleViewIndex].view
                                            + '_' + this.currentMuscleLayerArray[this.selectedMuscleLayerIndex].layer
                                            + '_' + this.muscleCacheData[clickedRowData.muscleId].muscleDetails.injectionSummaryName
                                            )
                } catch { console.log("oh dear") }
            }, 400) // remove this ugly timer hack when we figure out why the other timer hack of 200ms can be removed.
        },

        
        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                console.log("visibilityChanged (INJECTION)", this.toLoadOnShowUI_patientID, this.toLoadOnShowUI_sessionID)

                AnalyticsMgr.sendPageView('/Injections')

                if (this.toLoadOnShowUI_patientID != null) {
                    this.lastThumbnailViewClickedData = undefined   // no need to restore, just select first thumbnail tile
                    this.loadDataOnChangeInPatientID(this.toLoadOnShowUI_patientID)
                    this.toLoadOnShowUI_patientID = null
                }
                
                if (this.toLoadOnShowUI_sessionID != null) {
                    this.lastThumbnailViewClickedData = undefined   // no need to restore, just select first thumbnail tile
                    this.loadDataOnChangeInSessionID(this.toLoadOnShowUI_sessionID)
                    this.toLoadOnShowUI_sessionID = null
                }

                // force clear and reload same injection points, as there is an odd bug where by
                // when reloading patient screen then quickly switch back to injection screen again, the injection points
                // are oddly offset for some reason... 
                // TODO: find root cause of this issue.
                if (this.toLoadOnShowUI_patientID == null && this.toLoadOnShowUI_sessionID == null) {
                    var temp = this.currentSessionDetailedData
                    this.currentSessionDetailedData = []
                    this.currentSessionDetailedData = temp
                }
            }
        },

        loadDataOnChangeInPatientID(inPatientID) {
            
            // special logic to only reset things when called from visibilityChanged()
            // for all other callers, which just need the reloading call below for updates in the UI, we just reload without nuking all our other state info.
            if (this.toLoadOnShowUI_patientID != null) {
                this.renderPreviousSessionInjectionsOnScreen = false
                this.selectedInjectionProtocolId = -1
                this.selectedPreviousSessionID = 0
            }

            if (inPatientID != 0) {
                
                itBnTx.getSessionSummaryForPatient({ inPatientID : inPatientID })
                      .then(response => { this.sessionsData = response.data })
                      .catch(error => { error; this.sessionsData = [] })

                itBnTx.getAllInjectionProtocols({ inPatientID : inPatientID })
                      .then(response => {
                          /* 
                                MASSAGE THIS
                                {
                                    created: 1487001810292
                                    createdBy: 0
                                    injectionProtocolId: 1
                                    injectionProtocolName: "PREEMPT"
                                    sessionId: 1
                                }

                                INTO FIELD WITH THIS (USED ones), as we union this array with practice injection protocol array and load into UI
                                The UI needs the "USED" fields.
                                {
                                    conditionId: 1
                                    created: 1630332935337
                                    createdBy: 349
                                    pipId: 59                       <<<< USED
                                    pipName: "Bleph-Leo+practise1"  <<<< USED
                                    practiseId: 1
                                    sessionId: 1743                 <<<< USED
                                    status: "A"
                                }
                          */
                          
                          response.data.map(entry => {
                              entry['pipId'] = 10000000 + entry.injectionProtocolId     // create a fake one which is hopefully unique
                              entry['pipName'] = "• " + entry.injectionProtocolName
                            })

                          this.perPatientSystemWideInjectionProtocolArray = response.data
                      })
                      .catch(error => { error; this.perPatientSystemWideInjectionProtocolArray = [] })

                // try to set from users condition... but sometimes data in DB not properly setup on entry, so condition missing... thus fallback to a refdata item default.
                if (this.$store.state.currentSelectedPatientDetails.patientConditionIds.length != 0) {
                    this.newInjectionProtocolDetails.conditionId = this.$store.state.currentSelectedPatientDetails.patientConditionIds[0]
                } else {
                    this.newInjectionProtocolDetails.conditionId = this.conditionsRefData[0].conditionId
                }
            } else {
                this.sessionsData = []
            }
        },

        loadDataOnChangeInSessionID(inSessionID) {

            // special logic to only reset things when called from visibilityChanged()
            // for all other callers, which just need the reloading call below for updates in the UI, we just reload without nuking all our other state info.
            if (this.toLoadOnShowUI_sessionID != null) {
                this.renderPreviousSessionInjectionsOnScreen = false
                this.selectedInjectionProtocolId = -1
                this.selectedPreviousSessionID = 0
            }

            if (inSessionID != 0) {
                itBnTx.getInjectionSummaryForSession({inSessionID : inSessionID})
                        .then(response => { this.currentSessionSummaryData = response.data })
                        .catch(error => {error; this.currentSessionSummaryData = [] })

                itBnTx.getInjectionsForSession({inSessionID : inSessionID})
                        .then(response => {
                            this.currentSessionDetailedData = response.data

                            console.log("LAST BATCH #", this.lastUsedBatchInfo)

                            // if we currently dont have a cache for the current medication, create a new cache entry placeholder
                            if (this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId] == undefined)
                                this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId] = { "medicationBatch" : "", "medicationExpireDate" : undefined }

                            var lastUsedBatchNumber = this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId]["medicationBatch"]
                            var lastUsedBatchExpDate = this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId]["medicationExpireDate"]

                            // If current session being edited has an injection, then change the current medication id to that of a previosly used one (as we can only have one medication per session)
                            // We do this because the medication menu is disable from user changes when a previous medication was used, so we dont want to disable to 
                            // one that is different, as the use would be unable to change/correct it.
                            if (this.currentSessionDetailedData.length != 0) {
                                // when restoring last used batch details for a session, we now use the details from last injection as opposed to first injection,
                                // as this now properly restores the batch info when adding a few new injections with new batch, and deleting last injection... 
                                // it now restores to last batch, as opposed to first injection batch info
                                var lastInjectionIndex = this.currentSessionDetailedData.length - 1
                                this.medicationPrepInfo.injectionMedicationId = this.currentSessionDetailedData[lastInjectionIndex].injectionMedicationId

                                // show the batch number used in the first injection to illustrate what was used at the time
                                // NOTE: the old UI did not accommodate for when two batches were used which is possible if you were at the end of one
                                // batch and moving onto a new batch.
                                this.medicationPrepInfo.medicationBatch = (this.currentSessionDetailedData[lastInjectionIndex].medicationBatch != undefined)
                                                                             ? this.currentSessionDetailedData[lastInjectionIndex].medicationBatch
                                                                             : ((lastUsedBatchNumber == undefined) ? '' : lastUsedBatchNumber)
                                if (this.currentSessionDetailedData[lastInjectionIndex].medicationExpireDate != undefined)
                                    this.medicationPrepInfo.medicationExpireDate = new Date(this.currentSessionDetailedData[lastInjectionIndex].medicationExpireDate)
                                else
                                    this.medicationPrepInfo.medicationExpireDate = lastUsedBatchExpDate
                            } else {
                                this.medicationPrepInfo.injectionMedicationId = this.$store.state.loggedInPracticeDetails.defaultMedicationId
                               
                                this.medicationPrepInfo.medicationBatch = (lastUsedBatchNumber == undefined) ? '' : lastUsedBatchNumber
                                this.medicationPrepInfo.medicationExpireDate = lastUsedBatchExpDate
                            }

                            if (this.thumbnailInjectionViewsMuscleData.length != 0) {
                                if (this.lastThumbnailViewClickedData == undefined) {
                                    // no need to restore, just select first thumbnail tile
                                    if (this.dontRestoreThumbClickedForUndo == false) {
                                        this.handleThumbnailViewClicked(this.thumbnailInjectionViewsMuscleData[0])  // select the first thumb view when the session has changed.
                                    } else {
                                        this.dontRestoreThumbClickedForUndo = false
                                    }


                                    // 2021-11-17: The above line was changed as its currently used for restoring the view for first time OR when deleting injections say
                                    // but the catch was the thumb had to be clicked on for it to work.
                                    // When its never clicked on, and the user selects a new muscle / view/layer to inject, then the clicked view is undefined
                                    // so when they undo-inject, the above code would click the first undo back to the first thumbnail, which is undesirable.
                                    // Now we simply dont simulate restoring any thumb view, which should just maintain what the user is seeing.
                                } else {
                                    if (this.dontRestoreThumbClickedForUndo == false) {
                                        this.handleThumbnailViewClicked(this.lastThumbnailViewClickedData)
                                    } else {
                                        this.dontRestoreThumbClickedForUndo = false
                                    }
                                }
                                
                            } else {
                                // looks like a new session, lets help user by seeing what the condition is and selecting the most appropriate muscle being injected
                                
                                var currentConditions = this.$store.state.currentSelectedPatientDetails.patientConditionIds
                                console.log("SELECT A RELEVANT MUSCLE for patients condition.", currentConditions)

                                /*
                                        {conditionId: 1, parentConditionId: 0, conditionName: "Blepharospasm", statusCode: "A",…}
                                    1: {conditionId: 2, parentConditionId: 0, conditionName: "Hemifacial Spasm", statusCode: "A",…}
                                    2: {conditionId: 3, parentConditionId: 0, conditionName: "Cervical Dystonia", statusCode: "A",…}
                                    3: {conditionId: 4, parentConditionId: 0, conditionName: "Chronic Migraine", statusCode: "A",…}
                                    4: {conditionId: 6, parentConditionId: 0, conditionName: "Spasticity", statusCode: "A",…}
                                    5: {conditionId: 5, parentConditionId: 0, conditionName: "Other", statusCode: "A", created: 1482253050999,…}
                                */
                                // TODO: convert this into a json config
                                if (currentConditions.includes(1) == true)          // Blepharospasm
                                    this.switchInjectionViewToShowMuscle(17)        // 16, 17 are most injected
                                else if (currentConditions.includes(2) == true)     // Hemifacial Spasm
                                    this.switchInjectionViewToShowMuscle(17)        // 16, 17 are most injected
                                else if (currentConditions.includes(3) == true)     // Cervical Dystonia
                                    this.switchInjectionViewToShowMuscle(77)        // 77, 97, 99 are most injected
                                else if (currentConditions.includes(4) == true)     // Chronic Migraine
                                    this.switchInjectionViewToShowMuscle(1)         // 1, 28, 29, 77 are most injected - probably PREEMPT protocol
                                else {
                                    // else its a newer condition which we have not yet accumulated enough injection data, so 
                                    // just fetch what is recommended via backend api
                                    itBnTx.getDefaultInjectionView({ inSessionID : inSessionID })
                                          .then( response => {
                                              console.log(response.data)
                                              /*
                                                    {
                                                        "muscleGroupId": 3,
                                                        "viewCode": "A"
                                                    }
                                              */
                                             // set the muscle group, which in turn internally sets the default muscle view... 
                                             // DONT set it here, otherwise things will not work, and it will corrupt something internally.
                                             this.selectedMuscleGroupID = response.data.muscleGroupId
//                                              this.selectedMuscleViewID = response.data.viewCode
                                          })
                                }
                            }
                        })
                        .catch(error => {
                            console.log("ERROR loadDataOnChangeInSessionID", error)
                            this.currentSessionDetailedData = []
                        })

            } else {
                this.currentSessionSummaryData = []
                this.currentSessionDetailedData = []
            }
        },

        loadPractiseInjectionProtocolData() {
            itBnTx.searchInjectionProtocolsInPractise()
                  .then(response => {

                        // sort the results by the protocol name
                        this.practiceInjectionProtocolData = response.data
                                                                     .sort((a, b) => { a.pipName.localeCompare(b.pipName) });
                
                        // push a special name into the list which will trigger 
                        // the UI to create a new injection protocol
                        if (this.practiceInjectionProtocolData.length != 0 && this.practiceInjectionProtocolData[0].pipId != 0
                            || this.practiceInjectionProtocolData.length == 0) {
                            
                            this.practiceInjectionProtocolData.unshift({
                                                                            pipId : 0,
                                                                            pipName: "-- Create New Injection Protocol --"
                                                                        })

                        }

                  })
        },

        handleInjectionProtocolChanged(inInjectionProtocolID) {
            if (inInjectionProtocolID == 0) {
                // request to create a new protocol...
                // check and tell the user that they require at least one injection in order to create a new protocol...
                if (this.currentSessionDetailedData.length == 0) {
                    
                    this.$Message.warning({
                                            content: 'You must have at least one injection to create a new Protocol.',
                                            duration: 3
                                        })
                    this.selectedInjectionProtocolId = -1
                    setTimeout(() => { this.selectedInjectionProtocolId = -1 }, 100)    // no idea why it changed to something else... but we set back to 'no choice again.
                    return
                }
                this.showNewInjectionProtocolUI = true
            }

            //console.log("PIP: ", inInjectionProtocolID)

            var pipEntry = this.practiceInjectionProtocolData.find(entry => { return entry.pipId == inInjectionProtocolID} )
            if (pipEntry != undefined) {
                AnalyticsMgr.sendEvent('/Injections/InjectionProtocol', 'select', 'InjectionProtocol_' + pipEntry.pipName )
            }

        },

        handleCreateNewProtocol() {
            // grab the relevant bits of info needed for new protocol.
            this.newInjectionProtocolDetails.practiseId = this.$store.state.loggedInDoctorDetails.practiseId
            this.newInjectionProtocolDetails.sessionId = this.currentSessionID

            itBnTx.createPractiseInjectionProtocol({inPractiseInjectionProtocolRecord :  this.newInjectionProtocolDetails })
                  .then(response => { response
                        this.loadPractiseInjectionProtocolData()
                        this.$Message.success('Successfully create new Injection Protocol')
                        this.selectedInjectionProtocolId = -1
                        this.showNewInjectionProtocolUI = false
                  })



            var condition = this.conditionsRefData.find(entry => { return entry.conditionId == this.newInjectionProtocolDetails.conditionId })
            if (condition != undefined) {
                AnalyticsMgr.sendEvent('/Injections/InjectionProtocol', 'click', 'CreateNewInjectionProtocol_'
                                        + '_' + condition.conditionName
                                        + '_' + this.newInjectionProtocolDetails.pipName )
            }
        },

        handleCancelCreateNewProtocol() {
            this.showNewInjectionProtocolUI = false
            this.selectedInjectionProtocolId = -1


            var condition = this.conditionsRefData.find(entry => { return entry.conditionId == this.newInjectionProtocolDetails.conditionId })
            if (condition != undefined) {
                AnalyticsMgr.sendEvent('/Injections/InjectionProtocol', 'click', 'CancelNewInjectionProtocol_'
                                        + '_' + condition.conditionName
                                        + '_' + this.newInjectionProtocolDetails.pipName )
            }
        },

        // format a date as YYYY-MM-DD or '' if not defined
        // The backend supports "2016-11-01T13:00:00.000Z" and "2016-11-02"
        // But backend does not handle the time component properly, so will save incorrectly... so have to format as YYYY-MM-DD
        formatAsAPIDate(inDate) {
            return (inDate != undefined && inDate != '') ? moment(inDate).format('YYYY-MM-DD') : ''
        },
        
        // canInject: function(type) {
        //     if (type === "inject") {
        //         if (this.application.isBatchMandatory && (Ext.isEmpty(Ext.getCmp('injectionSiteAmount').getValue()) || Ext.isEmpty(Ext.getCmp('medicationExpireDate').getValue()) || Ext.isEmpty(Ext.getCmp('medicationBatch').getValue()) ||Ext.isEmpty(Ext.getCmp('injectionSiteDilution').getValue()) ||Ext.isEmpty(Ext.getCmp('injectionMedicationId').getValue()) || Ext.isEmpty(Ext.getCmp('injectionMedicationAmount').getValue()) || Ext.isEmpty(Ext.getCmp('injectionMedicationDilution').getValue()))) {
        //             Ext.Msg.alert('Caution', 'Medication Prep details must be filled in before the injection.');
        //             return false;
        //         }
        //         if (!this.application.isBatchMandatory && (Ext.isEmpty(Ext.getCmp('injectionSiteAmount').getValue()) || Ext.isEmpty(Ext.getCmp('injectionSiteDilution').getValue()) ||Ext.isEmpty(Ext.getCmp('injectionMedicationId').getValue()) || Ext.isEmpty(Ext.getCmp('injectionMedicationAmount').getValue()) || Ext.isEmpty(Ext.getCmp('injectionMedicationDilution').getValue()))) {
        //             Ext.Msg.alert('Caution', 'Medication Prep details must be filled in before the injection.');
        //             return false;
        //         }
        //     } else if (type === "duplicate" && this.application.isBatchMandatory && (Ext.isEmpty(Ext.getCmp('medicationExpireDate').getValue()) || Ext.isEmpty(Ext.getCmp('medicationBatch').getValue()))) {
        //         Ext.Msg.alert('Caution', 'Medication Batch # and Expiry Date must be filled in before duplicating session.');
        //         return false;
        //     }
        //     // Check invalid input, currently only expiry date
        //     var expiryDate = Ext.getCmp('medicationExpireDate').getValue();
        //     var today = new Date().setHours(0,0,0,0);
        //     if (this.application.isBatchMandatory && isNaN(Date.parse(expiryDate))) {
        //         Ext.Msg.alert('', "Please input a valid date in the format DD/MM/YY");
        //         return false;
        //     } else if (this.application.isBatchMandatory && expiryDate < today) {
        //         Ext.Msg.alert('', "This vial has expired");
        //         return false;
        //     }
        //     return true;
        // },

        createNewInjectionPoint({ inX, inY, inMuscleID } = {}) {
            
            this.$refs['medicationPrepInfoFormFields'].validate((valid) => {
                if (valid) {

                // grab our medication info...
                var newInjectionPointData = _.cloneDeep(this.medicationPrepInfo)
                
                // add the injection position and which muscle, and reformat expiry date
                newInjectionPointData.injectionXPoint = inX
                newInjectionPointData.injectionYPoint = inY
                newInjectionPointData.muscleImageId = inMuscleID
                newInjectionPointData.medicationExpireDate = this.formatAsAPIDate(newInjectionPointData.medicationExpireDate)
                
                console.log(newInjectionPointData)

                // sanity check, and alert user if issues... otherwise send to backend
                this.createNewInjectionFromDetails({        inSessionID : this.currentSessionID,    
                                                    inInjectionDetails : newInjectionPointData
                                                }, false)
                } else {
                    this.$Message.warning('Mandatory fields incomplete - Injections not allowed');
                }
            })
        },

        createNewInjectionFromDetails(inDetails, inIsForUndo) {

            try {
                AnalyticsMgr.sendEvent('/Injections/InjectionPoint', 'click', 'INJECT_'
                                        + this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].muscleGroup
                                        + '_' + this.currentMuscleViewArray[this.selectedMuscleViewIndex].view
                                        + '_' + this.currentMuscleLayerArray[this.selectedMuscleLayerIndex].layer
                                        + '_' + this.muscleCacheData[inDetails.inInjectionDetails.muscleImageId].muscleDetails.injectionSummaryName
                                        )
            } catch { console.log("oh dear") }


            itBnTx.createInjection({        inSessionID : inDetails.inSessionID,    
                                     inInjectionDetails : inDetails.inInjectionDetails
                                    })
                  .then(response => {
                      
                      this.$Message.success('Injection recorded');

                      // reload data and update our UI
                      itBnTx.getInjectionSummaryForSession({inSessionID : inDetails.inSessionID})
                            .then(response => { this.currentSessionSummaryData = response.data })
                            .catch(error => {error; this.currentSessionSummaryData = [] })
                      
                      // for the session injection details, we can optimize by not calling the api as we have 
                      // the record from the response of inject create... so we simply append to the array.
                      this.currentSessionDetailedData.push(response.data)


                      // prepare data for undo/redo history
                      var history = (inIsForUndo ? this.redoHistory : this.undoHistory)
                      history.push({
                                    name: "New Injection",
                                    operation: this.handleDeleteInjection,
                                    payload : _.cloneDeep(response.data)
                                })
                  })
                  .catch(error => {
                      error
                      this.$Message.error('FAILED to record injection');
                  })
        },

        handleDeleteInjection(inInjectionToDelete, inIsForUndo) {
            //console.log("handleDeleteInjection: ", inInjectionToDelete)

            try {
                AnalyticsMgr.sendEvent('/Injections/InjectionPointDelete', 'click', 'REJECT_'
                                        + this.muscleData.muscleGroupSet[this.selectedMuscleGroupIndex].muscleGroup
                                        + '_' + this.currentMuscleViewArray[this.selectedMuscleViewIndex].view
                                        + '_' + this.currentMuscleLayerArray[this.selectedMuscleLayerIndex].layer
                                        + '_' + this.muscleCacheData[this.selectedMuscleID].muscleDetails.injectionSummaryName
                                        )
            } catch { console.log("oh dear") }

            itBnTx.deleteInjection({
                                        inSessionID : inInjectionToDelete.sessionId,
                                      inInjectionID : inInjectionToDelete.injectionId
                                    })
                  .then(response => {
                      response

                      this.$Message.success('Injection DELETED');

                      //console.log("DELETE", this.selectedMuscleGroupIndex, this.selectedMuscleViewIndex, this.selectedMuscleLayerIndex)                      

                      // reload data and our UI
                      this.dontRestoreThumbClickedForUndo = true
                      this.loadDataOnChangeInSessionID(this.currentSessionID)

                      //setTimeout(()=>{console.log("DELETE2", this.selectedMuscleGroupIndex, this.selectedMuscleViewIndex, this.selectedMuscleLayerIndex)}, 1000)

                      // prepare data for undo history
                      var undoPayload = {
                                            inSessionID : this.currentSessionID,    
                                     inInjectionDetails : _.cloneDeep(inInjectionToDelete)
                                        }
                      
                      delete undoPayload.inInjectionDetails.injectionId

                      // prepare data for undo/redo history
                      var history = (inIsForUndo ? this.redoHistory : this.undoHistory)
                      history.push({
                                    name: "Delete Injection",
                                    operation: this.createNewInjectionFromDetails,
                                    payload : undoPayload
                                })

                  })
                  .catch(error => {
                      error
                      this.$Message.error('FAILED to delete injection');
                  })
        },

        handleUndo() {
            if (this.undoHistory.length == 0) return

            var undoEntry = this.undoHistory.pop()

            undoEntry.operation(undoEntry.payload, true)

            AnalyticsMgr.sendEvent('/Injections/Navigation', 'click', 'Nav_Undo' )
        },

        handleRedo() {
            if (this.redoHistory.length == 0) return

            var redoEntry = this.redoHistory.pop()

            redoEntry.operation(redoEntry.payload, false)

            AnalyticsMgr.sendEvent('/Injections/Navigation', 'click', 'Nav_Redo' )
        },


        handleInjectViaSelectedInjectionProtocol() {

            var pipEntry = this.availablePracticeInjectionProtocols.find(entry => { return entry.pipId == this.selectedInjectionProtocolId} )
            if (pipEntry != undefined) {
                AnalyticsMgr.sendEvent('/Injections/InjectionProtocol', 'click', 'InjectInjectionProtocol_' + pipEntry.pipName )
            }


            this.$refs['medicationPrepInfoFormFields'].validate((valid) => {
                if (valid) {
                    var selectedInjectionProtocolSessionID = pipEntry.sessionId
                    
                    itBnTx.getInjectionsForSession({inSessionID : selectedInjectionProtocolSessionID})
                          .then(response => {
                              // a protocol must have at least one injection, and since sessions can only have one medication, we check to see if the first
                              // injection has the same medication as our current medication...
                              if (response.data[0].injectionMedicationId == this.medicationPrepInfo.injectionMedicationId) {
                                        this.applyingInjectionProtocol = true

                                        itBnTx.duplicateInjectionsForSession({
                                                                 inCopyFromSessionID : selectedInjectionProtocolSessionID,
                                                                   inCopyToSessionID : this.currentSessionID,
                                                             inMedicationBatchString : this.medicationPrepInfo.medicationBatch,
                                                              inMedicationExpiryDate : this.formatAsAPIDate(this.medicationPrepInfo.medicationExpireDate)
                                                            })
                                        .then(response => {
                                            response
                                            this.loadDataOnChangeInSessionID(this.currentSessionID)

                                            this.$Message.success('Injection Protocol successfully applied');
                                        })
                                        .finally(() => {
                                            this.applyingInjectionProtocol = false
                                        })
                              } else {
                                  var medDetails = this.medicationOptions.find(entry => { return entry.medicationID == response.data[0].injectionMedicationId }) 

                                  this.$Message.warning('Different medication injections being used in current session (Injection Protocol uses ' + medDetails.medicationName + ') - Inject Protocol not applied');
                              }
                          })
                } else {
                    this.$Message.warning('Mandatory medication fields incomplete - Inject Protocol not applied');
                }
            })

        },

        handleDuplicatePreviousSession() {

            this.$refs['medicationPrepInfoFormFields'].validate((valid) => {
                if (valid) {
                    // fetch the selected previous session to duplicate
                    var previousSessionDetails = this.previousSessionDetailedData.find(entry => { return entry.sessionId == this.selectedPreviousSessionID })

                    // check to ensure any existing injections are using the same medication as the one we are duplicating
                    // as we only allow one type of medication per session.
                    // Since we lock down medication (dropdown) when first injection made, we can just check the currently configured medication info to see if it matches
                    if (this.medicationPrepInfo.injectionMedicationId == previousSessionDetails.injectionMedicationId) {

                        var today = new Date()
                        AnalyticsMgr.sendEvent('/Injections/DuplicateSession', 'click', 'InjectFromSessionDaysAgo_' + Math.ceil((today.getTime() - previousSessionDetails.created) / (1000 * 3600 * 24)) )

                        // copy the medication info from the previous session to this session.
                        this.medicationPrepInfo.injectionMedicationAmount       = previousSessionDetails.injectionMedicationAmount
                        this.medicationPrepInfo.injectionMedicationDilution     = previousSessionDetails.injectionMedicationDilution
                        this.medicationPrepInfo.injectionSiteAmount             = previousSessionDetails.injectionSiteAmount
                        this.medicationPrepInfo.injectionSiteDilution           = previousSessionDetails.injectionSiteDilution
                        this.medicationPrepInfo.isEmg                           = previousSessionDetails.isEmg

                        //console.log("handleDuplicatePreviousSession", previousSessionDetails)
                        itBnTx.duplicateInjectionsForSession({
                                                                 inCopyFromSessionID : previousSessionDetails.sessionId,
                                                                   inCopyToSessionID : this.currentSessionID,
                                                             inMedicationBatchString : this.medicationPrepInfo.medicationBatch,
                                                              inMedicationExpiryDate : this.formatAsAPIDate(this.medicationPrepInfo.medicationExpireDate)
                                                            })
                              .then(response => {
                                  response
                                  this.loadDataOnChangeInSessionID(this.currentSessionID)

                                  this.$Message.success('Injections successfully duplicated');
                              })
                    } else {
                        this.$Message.warning('Different medication injections already used in current session - Duplicate not allowed');
                    }

                } else {
                    this.$Message.warning('Mandatory medication fields incomplete - Duplicate not allowed');
                }
            })

        },
        
        medicationDilutionValidation(value) {
            var parsedVal = parseFloat(value.replace(' ml', ''))
            
            if (parsedVal < 1 || value == '')
                parsedVal = 1

            return parsedVal.toString()
        },

        handleMedicationAmountChanged(newValue) {

            var wellDefinedAmount = this.medicationOptions[this.selectedMedicationIndex].medicationAmounts.find(entry => entry.medicationAmount == newValue)
            
            if (wellDefinedAmount != undefined) {
                // reset the default dilution
                this.medicationPrepInfo.injectionMedicationDilution = wellDefinedAmount.defaultDilutionAmount
                // setup teh preferred site medication amount (based on Tableau historical data)
                this.medicationPrepInfo.injectionSiteAmount = wellDefinedAmount.defaultSiteAmount
            }


        },

        onFocusMedicationDilution(event) { event.srcElement.setSelectionRange(0, event.srcElement.value.length) },
        onFocusSiteInjectionAmount(event) { event.srcElement.setSelectionRange(0, event.srcElement.value.length) },
        onFocusSiteInjectionDilution(event) { event.srcElement.setSelectionRange(0, event.srcElement.value.length) },

    },

    watch : {
        // https://michaelnthiessen.com/how-to-watch-nested-data-vue/
        // https://michaelnthiessen.com/use-quotes-watch-nested-values/
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...

        selectedMuscleGroupID() { this.resyncUIOnMuscleGroupSelectorChange() },
        selectedMuscleViewID()  { this.resyncUIOnMuscleViewSelectorChange() },
        selectedMuscleLayerID() { this.updateInjectionView() },

        allMusclesDropdownSelectedMuscleID() { if (this.allMusclesDropdownSelectedMuscleIDPauseWatching == false) this.switchInjectionViewToShowMuscle(this.allMusclesDropdownSelectedMuscleID) },

        "medicationPrepInfo.injectionMedicationId" : function (newVal, oldVal){
            console.log("MEDICATION CHANGE: ", newVal, oldVal);

            AnalyticsMgr.sendEvent('/Injections/MedicationPrep', 'select', 'Medication_' + newVal + '_'+ this.medicationOptions[this.selectedMedicationIndex].medicationName)

            // dynamically change the medication amount upon selecting a new medication.
            this.medicationPrepInfo.injectionMedicationAmount = this.medicationOptions[this.selectedMedicationIndex].medicationAmounts.find(entry => entry.id == this.medicationOptions[this.selectedMedicationIndex].medicationPreferredAmountID).medicationAmount
            // reset the default dilution
            this.medicationPrepInfo.injectionMedicationDilution = this.medicationOptions[this.selectedMedicationIndex].medicationAmounts.find(entry => entry.id == this.medicationOptions[this.selectedMedicationIndex].medicationPreferredAmountID).defaultDilutionAmount
            // setup teh preferred site medication amount (based on Tableau historical data)
            this.medicationPrepInfo.injectionSiteAmount = this.medicationOptions[this.selectedMedicationIndex].medicationAmounts.find(entry => entry.id == this.medicationOptions[this.selectedMedicationIndex].medicationPreferredAmountID).defaultSiteAmount
        },

        /* 
            define a few intelligent variable watchers, which automatically update corresponding values of inter-related
            calculations, so the UI elements which render these values are always synced up when one of the values changes.
        */
        "medicationPrepInfo.injectionMedicationAmount"   : function () { this.medicationPrepInfo.injectionSiteDilution = this.medicationPrepInfo.injectionSiteAmount / this.medicationDilutionRatio },
        "medicationPrepInfo.injectionMedicationDilution" : function () { this.medicationPrepInfo.injectionSiteDilution = this.medicationPrepInfo.injectionSiteAmount / this.medicationDilutionRatio },
        "medicationPrepInfo.injectionSiteAmount"   : function (newVal, oldVal){ oldVal; /* shut up lint */ this.medicationPrepInfo.injectionSiteDilution = Math.round(((newVal / this.medicationDilutionRatio) + Number.EPSILON) * 1000) / 1000 },
        "medicationPrepInfo.injectionSiteDilution" : function (newVal, oldVal){ oldVal; /* shut up lint */ this.medicationPrepInfo.injectionSiteAmount = Math.round(((newVal * this.medicationDilutionRatio) + Number.EPSILON) * 1000) / 1000 },

        "medicationPrepInfo.medicationBatch" :      function() { 
                    this.$refs['medicationPrepInfoFormFields'].validate((valid) => { this.medicationMandatoriesOK = valid })

                    // if we currently dont have a batch number for the current medication, create a new cache entry placeholder
                    if (this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId] == undefined)
                        this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId] = { "medicationBatch" : "", "medicationExpireDate" : undefined }
                    
                    // cache the batch number for this specific mediation.
                    this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId]["medicationBatch"] = this.medicationPrepInfo.medicationBatch
                    //console.log("WATCH BATCH #", this.lastUsedBatchInfo)
                },
        "medicationPrepInfo.medicationExpireDate" : function() {
                    this.$refs['medicationPrepInfoFormFields'].validate((valid) => { this.medicationMandatoriesOK = valid })

                    // if we currently dont have a batch expiry for the current medication, create a new cache entry placeholder
                    if (this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId] == undefined)
                        this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId] = { "medicationBatch" : "", "medicationExpireDate" : undefined }
                    
                    // cache the batch expiry for this specific mediation.
                    this.lastUsedBatchInfo[this.medicationPrepInfo.injectionMedicationId]["medicationExpireDate"] = this.medicationPrepInfo.medicationExpireDate
                    //console.log("WATCH BATCH EXP", this.lastUsedBatchInfo)
                },

        "additionalMaterialDialog.show": function (newVal, oldVal) { newVal; /* shut up lint */ if (oldVal == true) { this.additionalMaterialDialog.url = "about:blank"; } },    // if user is dismissing the UI... unload the resources for the iframe.
            
        "$store.state.currentPatientID" (newValue, oldValue) { oldValue; this.toLoadOnShowUI_patientID = newValue; console.log("INJECTION UI received new currentPatientID", newValue) },
        "$store.state.currentSessionID" (newValue, oldValue) { oldValue; this.toLoadOnShowUI_sessionID = newValue; this.undoHistory = []; this.redoHistory = []; console.log("INJECTION UI received new currentSessionID", newValue) },
    }
}
</script>

<style scoped>

/* img::before, img::after, div::before, div::after{
    content:none !important
} */

</style>

<style scoped>

.injectionContainer {
    position: absolute;

    width: 90%;

    /* following two lines adds a resizer to injection view, allowing user to resize as they see fit */
    resize: both;
  overflow: auto;
}

/* https://wellcaffeinated.net/articles/2012/12/10/very-simple-css-only-proportional-resizing-of-elements */
.injectionContainer .outer {
    width: 100%;
    padding-top: 113%; /* 1130/1000 = 113% ;75% defines aspect ratio 4:3 */
    position: relative;
}
.injectionContainer .outer .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#layer_background {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.7;

    width: auto;
    height: 100%;

}

.layer_muscle {
    position: absolute;
    top: 0px;
    left: 0px;

    width: auto;
    height: 100%;

}

/*
    Injection point and labels are within an injection point <div>.
    The <div> is hidden/shown depending on if the injection point should be
    shown to the user depending on the group/view/layer use if viewing

    The <div> top left point is the exact injection point position.
    The icon is offset from injection point such that the center of the icon is on the injection point. 
    The label is positioned top left or top right of the icon depending on if its a current or previous 
    injection point.

    [prev injection point label]       [curr injection point label]
                              -----------
                              |         |
                              |    X    |
                              |         |
                              -----------

    X - top left of <div>, also the center of icon which is the box
    [] - is the injection label which can be either top left/right of the icon.

    NOTE: for better clarity, current injections is 80% opacity, previous injections is 75% opacity
          and highlight injection points are 100%.
          This allows overlaps to be identified easily while the highlight to solidly shine through.

    CSS Tint: https://stackoverflow.com/questions/42966641/how-to-transform-black-into-any-given-color-using-only-css-filters/43960991#43960991
*/
.injectionPointIcon {
    max-width: 10px !important;     /* PLI - 13/10/2022 - added this to work around the side effect of tailwindcss global style for img tag of 'max-width: 100%' */
    position: absolute;
    top: -5px;
    left: -5px;
    width: 10px;
    height: 10px;

    transform: scale(0.5);  /* this style is now exclusively used by thumbnail
                               injection points, so reduce the size of injection
                               point for clarity */
}

/*
    previously we were simply using an img tag, but with the addition
    of Poptip component around the img tag, we now need to tweak positioning.
*/
.injectionPointIconPoptip {
    max-width: 10px !important;     /* PLI - 2/9/2022 - added this to work around the side effect of tailwindcss global style for img tag of 'max-width: 100%' */
    position: absolute;
    top: -19px;
    left: -8px;
    width: 10px;
    height: 10px;
}

.injectionPointLabelPreviousInjection {
    position: absolute;
    top: -20px;
    left: -15px;
    width: 10px;
    height: 10px;
    font-weight: bold;
    color: #ff00ff;
    text-shadow: 1px 1px 4px #FFFFFF;
    font-size: 15px;
}

.injectionPointLabelCurrentInjection {
    position: absolute;
    top: -20px;
    left: 5px;
    width: 10px;
    height: 10px;
    font-weight: bold;
    color: #000000;
    text-shadow: 1px 1px 4px #FFFFFF;
    font-size: 15px;
}

.currentSessionRowHighlight {
    font-weight: bold;
}


/* @-webkit-keyframes throb {
0% { opacity: 0.5; }
50% { opacity: 1; }
100% { opacity: 0.5; }
} */

.activated {
    opacity: 1;
    /*     -webkit-animation: throb 1.5s infinite;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; */
}

.deactivated {
    opacity: 0.4;
}

.fullScreenIFrame {
  width: 100%;
  height: 100%;
  margin: -6px 0px 0px 0px;
  
  /* border: 3px solid red; */
}

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}

.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}


#injectionNavigation {
    position: absolute;
    z-index: 1000;
    background-color: rgba(241,241,241,0.5);
    border: 1px solid #d3d3d3;
    text-align: center;
    width: 213px;
    min-height: 210px;
}

#injectionNavigationHeader {
  padding: 2px;
  cursor: move;
  z-index: 1001;
  background-color: rgba(171,171,171,0.7);
  color: rgba(171,171,171,0.7);
}


.navTogglePrevSessionInjections {
    cursor:pointer;
    position: absolute;
    top: 25px;
    left: 0px;
    /* background-color:red; */
}

.navUp {
    cursor:pointer;
    position: absolute;
    top: 25px;
    left: 70px;
    /* background-color:red; */
}

.navLeft {
    cursor:pointer;
    position: absolute;
    top: 85px;
    left: 0px;
    /* background-color:red; */
}

.navDown {
    cursor:pointer;
    position: absolute;
    top: 145px;
    left: 70px;
    /* background-color:red; */
}

.navRight {
    cursor:pointer;
    position: absolute;
    top: 85px;
    left: 140px;
    /* background-color:red; */
}

.navInfo {
    cursor:pointer;
    position: absolute;
    top: 85px;
    left: 70px;
    /* background-color:red; */
}

.navUndo {
    cursor:pointer;
    position: absolute;
    top: 145px;
    left: 0px;
    /* background-color:red; */
}

.navRedo {
    cursor:pointer;
    position: absolute;
    top: 145px;
    left: 140px;
    /* background-color:red; */
}

.navToggleLeftRight {
    cursor:pointer;
    position: absolute;
    top: 25px;
    left: 140px;
    /* background-color:red; */
}


.muscleInCurrentGroupAndViewAndLayer {
    font-weight: bold;
}

</style>

