<template>
    <div id="admin-setup-practise-hpi-text" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">
        <Divider orientation="left">Setup Practice HPI Text</Divider>

        <Row>
            <Table style="width: 100%;" size="default" draggable :columns="practisesTableColumnsConfig" :data="practisesTableData" border highlight-row @on-current-change="handlePracticeSelectionChanged" @on-row-dblclick="handleEditEntry">
                <template slot-scope="{ row }" slot="logo">
                    <img v-if="row.practiseLogo != ''" :src="row.practiseLogo" height="50px" />
                </template>
            </Table>

            <!-- <Table style="width: 100%;" size="small" draggable :columns="usersTableColumnsConfig" :data="usersTableData" border highlight-row @on-current-change="handleUserSelectionChanged">
                <template slot-scope="{ row }" slot="condition">
                    {{ row.patientConditionIds | conditionsAsString }}
                </template>
            </Table> -->
        </Row>                                        


        <Row :gutter="8" >
            <Col>
                <Button @click="handleAddEntry">Add Entry</Button>
            </Col>
            <Col>
                <Button @click="handleEditEntry">Edit Entry</Button>
            </Col>
            <Col>
                <Button @click="handleRemoveEntry">Remove Entry</Button>
            </Col>
        </Row>

    </div>
</template>

<script>
//const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");

// const {itBnTx} = require('@/js/itBnTx')



export default {
    name: 'admin-setup-practise-hpi-text',
    components: {

    },

    created () {
        
    },

    destroyed() {

    },

    mounted() {


    },

    data () {
            return {

                practisesTableColumnsConfig: [
                    {                      title: 'Practice ID',           key: 'practiseId', resizable: true, sortable: true, width: 120 },
                    {                      title: 'Status Code',           key: 'statusCode', resizable: true, sortable: true, width: 120 },
                    {                      title: 'Account Status Code',   key: 'accountStatusCode', resizable: true, width: 120 },
                    { /* slot: 'condition', */  title: 'Practice Name',    key: 'practiseName', resizable: true, minWidth: 200 },
                    {                      title: 'Practice Address',      key: 'practiseAddress', resizable: true, width: 300 },
                    {                      title: 'Practice City',         key: 'practiseCity', resizable: true, width: 120 },
                    {                      title: 'Practice Postcode',     key: 'practisePostcode', resizable: true, width: 120 },
                    {                      title: 'Country Code',          key: 'countryCode', resizable: true, width: 120 },
                    { slot: 'logo',        title: 'Practice Logo',         key: 'practiseLogo', resizable: true, width: 150 },
                    {                      title: 'Practice Contact Name', key: 'practiseContactName', resizable: true, width: 150 },
                    {                      title: 'Practice Contact Phone',key: 'practiseContactPhone', resizable: true, width: 150 },
                    {                      title: 'Practice Contact Fax',  key: 'practiseFax', resizable: true, width: 150 },
                    {                      title: 'Practice Contact Email',key: 'practiseContactEmail', resizable: true, width: 220 },
                    {                      title: 'Is Hospital',           key: 'isHospital', resizable: true, width: 150 },
                    {                      title: 'Created By',            key: 'createdBy', resizable: true, width: 150 },
                    {                      title: 'Created',               key: 'created', resizable: true, width: 150 },
                ],

                practisesTableData: [],


                usersTableColumnsConfig: [
                    {                      title: 'User ID',           key: 'firstName', resizable: true, sortable: true, width: 300 },
                    {                      title: 'Status Code',            key: 'lastName', resizable: true, sortable: true, width: 300 },
                    {                      title: 'Practice ID',        key: 'dateOfBirth', resizable: true, width: 150 },
                    { /* slot: 'condition', */  title: 'User Name',           key: 'conditions', resizable: true, minWidth: 100 },
                    {                      title: 'User Email',  key: 'referringPhysician', resizable: true, width: 300 },
                    {                      title: 'User Email Validated',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Is Temp Password',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Password Hint',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Password Failures',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User is Account Locked',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Last Login',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'User Role Codes',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'Created By',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'Created',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'Modified By',        key: 'dateOfBirth', resizable: true, width: 150 },
                    {                      title: 'Modified',        key: 'dateOfBirth', resizable: true, width: 150 },
                ],

                usersTableData: [],




            }
        },

    filters: {

    },
    
    computed: {

    },
    methods: {
        visibilityChanged (isVisible, entry) {
            entry
  
            if (isVisible == true) {

                // itBnTx.getAllPractises()
                //         .then(response => {
                //             this.practisesTableData = response.data;
                //         })
//                AnalyticsMgr.sendPageView('/Assessments')

            }
        },

        handleUserSelectionChanged() {

        },
        
        handlePracticeSelectionChanged() {

        },

        handleAddEntry() {

        },

        handleEditEntry() {

        },

        handleRemoveEntry() {
            
        }
    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
    
    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  

</style>

