<template>
  <div v-observe-visibility="visibilityChanged" id="educationMain">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  
  <!-- <h1>Education Center</h1> -->
    <!--
      NOTES: it may seem in-elegant to have multiple Model UI instances here, where one could have one and 
             dynamically set the URL every time the user goes to the section. However there are benefits:
             1) MID loads slow so once loaded once, its a nicer experience to instantly switch and see what we need.
             1a) we could tweak the implementation a bit more to avoid the btx token timeout (if required - a non-issue
                 if we just instantiate a new one every time), by having a timer such that when the view is not used
                 for a period of time we 'purge it from memory', and free up the DOM. This way the elegance
                 of UX is there while the functional behaviour is preserved too.
             2) we can still have a re-usable Model specifically for single file items which dont need to keep MID running.
             3) when having multiple instances for viewing different smartlibs, its nice to be able to preserve
                the UI state within that smartlib/MID instance... eg user may be in a folder with a search term entered
                when then go to another section temporarily then come back. A crappy UI would have the user 
                re-navigate all over again, while a nice UI would just 'remember'.
             4) the purpose of setting some Model UI's URL's as "about:blank" is such that it will load faster, and
                we only load the relevant sections when the user requests it. Not doing so will load multiple instances
                of MID for the relevant sections, which may be good as its instantly available when they browse to it
                but it also sucks up resources in the users' browser, while also driving a developer up the wall
                as currently due to CORS and other security issues with iframes, loading https MID via a localhost
                will cause the view pairing in MID to fail with an alert... and the developer would get multiple
                of these alerts.

    -->

    <!-- ================ -->
    <!--    USER GUIDE    -->
    <!-- ================ -->

    <Modal v-model="showUserGuideDialog" fullscreen title="BnTx User Guide" :footer-hide="true">
      <!-- <vue-friendly-iframe src="https://prod-content.bntxinteract.com/support/bntx-userguide.pdf" 
                            @load="onLoadiFrame"
                            frameborder="0"
                            gesture="media"
                            allow="encrypted-media"
                            sandbox="allow-same-origin allow-scripts"
                            ></vue-friendly-iframe> -->
      <!-- <pdf src="https://prod-content.bntxinteract.com/support/bntx-userguide.pdf"></pdf> -->

      <iframe class="fullScreenIFrame" frameborder="0" :src="resourceBnTxUserGuideURL"></iframe>
    </Modal>
    
    <!-- ================ -->
    <!-- ONBOARDING VIDEOS -->
    <!-- ================ -->

    <Modal v-model="showOnboardingVideosDialog" fullscreen transfer title="Onboarding Videos - Powered by myINTERACT" :footer-hide="true">

        <iframe :src="resourceOnboardingVideosDeeplinkURL" class="fullScreenIFrame" frameborder="0"></iframe>

    </Modal>
    
    
    <!-- =================== -->
    <!-- Notices -->
    <!-- =================== -->

    <Modal v-model="showNoticesDialog" fullscreen transfer title="Notices - Powered by myINTERACT" :footer-hide="true">

        <iframe :src="resourceNoticesDeeplinkURL" class="fullScreenIFrame" frameborder="0"></iframe>

    </Modal>    
    
    <!-- =================== -->
    <!-- Community Resources -->
    <!-- =================== -->

    <Modal v-model="showCommunityResourcesDialog" fullscreen transfer title="Community Resources - Powered by myINTERACT" :footer-hide="true">

        <iframe :src="resourceCommunityResourcesDeeplinkURL" class="fullScreenIFrame" frameborder="0"></iframe>

    </Modal>
    
    <!-- =================== -->
    <!-- Lectures -->
    <!-- =================== -->

    <Modal v-model="showLecturesDialog" fullscreen transfer title="Lectures - Powered by myINTERACT" :footer-hide="true">

        <iframe :src="resourceLecturesDeeplinkURL" class="fullScreenIFrame" frameborder="0"></iframe>

    </Modal>
    
    <!-- =================== -->
    <!-- Training Modules -->
    <!-- =================== -->

    <Modal v-model="showTrainingModulesDialog" fullscreen transfer title="Training Modules - Powered by myINTERACT" :footer-hide="true">

        <iframe :src="resourceTrainingModulesDeeplinkURL" class="fullScreenIFrame" frameborder="0"></iframe>

    </Modal>
    
    <!-- =================== -->
    <!-- Case Studies -->
    <!-- =================== -->

    <Modal v-model="showCaseStudiesDialog" fullscreen transfer title="Case Studies - Powered by myINTERACT" :footer-hide="true">

        <iframe :src="resourceCaseStudiesDeeplinkURL" class="fullScreenIFrame" frameborder="0"></iframe>

    </Modal>

    <!-- =================== -->
    <!-- Muscle Localization And Anatomy -->
    <!-- =================== -->

    <Modal v-model="showMuscleLocalizationAndAnatomyDialog" fullscreen transfer title="Muscle Localization And Anatomy - Powered by myINTERACT" :footer-hide="true">

        <iframe :src="resourceMuscleLocalizationAndAnatomyDeeplinkURL" class="fullScreenIFrame" frameborder="0"></iframe>

    </Modal>

    <!-- =================== -->
    <!-- Self Assessment -->
    <!-- =================== -->

    <Modal v-model="showSelfAssessmentDialog" fullscreen transfer title="Self Assessment - Powered by myINTERACT" :footer-hide="true">

        <iframe :src="resourceSelfAssessmentDeeplinkURL" class="fullScreenIFrame" frameborder="0"></iframe>

    </Modal>

    <!-- ==================== -->
    <!-- SmartEntry video/pdf -->
    <!-- ==================== -->

    <Modal v-model="showSmartEntryDialog" fullscreen :title="resourceSmartLibEntryFileTitle" :footer-hide="true">
      <iframe class="fullScreenIFrame" frameborder="0" :src="resourceSmartLibEntryFileURL"></iframe>
    </Modal>
    

    <Layout>
      <Header></Header>
        
      <Content>
    <Row :gutter="16" class="ivu-m">
      <Col v-bind="responsiveGrid2Sections">
        
        <Loading :active="isLoadingNotices" :is-full-page="false"/>

        <Row style="border-bottom: 2px solid #EBF2F7;">
          <Col span="24" class="ivu-text-left"><h1>Notices</h1></Col>
        </Row>
        <Col class="card-wrapper">
          <Row span="24">
            <List item-layout="horizontal" :split="false" size="small" class="">
              <ListItem v-for="item in noticesFiltered" :key="item.id">
                <SmartlibBasicEntryCell
                      :title = "item.title"
                      :description = "item.description"
                      :lastModified = "item.lastModified"
                      :entryID = "item.id"
                      :type = "item.type"
                      :tileIconURL = "item.thumbURL"
                      :entryFileURL = "item.articleURL"
                      :tags = "item.tags"
                      contentContext = "NOTICES"
                      :clickCallback = "tapEntryCallback"
                      />
              </ListItem>
            </List>
          </Row>
          <Row style="margin-top:20px;">
            <Col span="24" class="ivu-text-right"><Button type="primary" @click="handleShowNotices()">View all Notices</Button></Col>
          </Row>
        </Col>

      </Col>

      <Col v-bind="responsiveGrid2Sections">

        <Loading :active="isLoadingCommunityResources" :is-full-page="false"/>

        <Row style="border-bottom: 2px solid #EBF2F7;">
          <Col span="16" class="ivu-text-left"><h1>Community Resources</h1></Col>
        </Row>
        <Col class="card-wrapper">
          <Row span="24">
            <List item-layout="horizontal" :split="false" size="small">
              <ListItem v-for="item in communityFeedFiltered" :key="item.id">
                <SmartlibBasicEntryCell
                      :title = "item.title"
                      :description = "item.description"
                      :lastModified = "item.lastModified"
                      :entryID = "item.id"
                      :type = "item.type"
                      :tileIconURL = "item.thumbURL"
                      :entryFileURL = "item.articleURL"
                      :tags = "item.tags"
                      contentContext = "COMMUNITY"
                      :clickCallback = "tapEntryCallback"
                      />
              </ListItem>
            </List>
          </Row>
          <Row style="margin-top:20px;">
            <Col span="24" class="ivu-text-right"><Button type="primary" @click="handleShowCommunityResources()">View all Community Resources</Button></Col>
          </Row>
        </Col>
      </Col>
    </Row>

    <Row class="heading-row">
      Education and Training
    </Row>
    
    <Row :gutter="16" class="ivu-m">
      <Col>
        <Collapse v-model="sectionsExpandedNames">
          <Panel name="Lectures" style="text-align: left;">
              <strong>General Lectures</strong>
              <Button type="primary" size="small" v-show="selfAssessmentFeed.totalArticles > 3" class="accordionShowAllButton" v-on:click.stop="handleShowLectures()">({{ lecturesFeed.totalArticles }}) General Lectures - View More</Button>
              <p slot="content">
              <Row :gutter="16">
                <Col v-for="item in lecturesFeedFiltered" :key="item.id" v-bind="responsiveGridHorizontalListOfEntries" >

                  <Loading :active="isLoadingLectures" :is-full-page="false"/>

                  <SmartlibBasicEntryCell
                    :title = "item.title"
                    :description = "item.description"
                    :lastModified = "item.lastModified"
                    :entryID = "item.id"
                    :type = "item.type"
                    :tileIconURL = "item.thumbURL"
                    :entryFileURL = "item.articleURL"
                    :tags = "item.tags"
                    contentContext = "LECTURES"
                    :clickCallback = "tapEntryCallback"
                    />
                </Col>
              </Row>
              </p>
          </Panel>


          <Panel name="Training_Modules" style="text-align: left;">
              <strong>Training Modules</strong>
              <Button type="primary" size="small" v-show="trainingModulesFeed.totalArticles > 3" class="accordionShowAllButton" v-on:click.stop="handleShowTrainingModules()">({{ trainingModulesFeed.totalArticles }}) Training Modules - View More</Button>
              <p slot="content">
              <Row :gutter="16">
                <Col v-for="item in trainingModulesFeedFiltered" :key="item.id" v-bind="responsiveGridHorizontalListOfEntries" >

                  <Loading :active="isLoadingTrainingModules" :is-full-page="false"/>

                  <SmartlibBasicEntryCell
                    :title = "item.title"
                    :description = "item.description"
                    :lastModified = "item.lastModified"
                    :entryID = "item.id"
                    :type = "item.type"
                    :tileIconURL = "item.thumbURL"
                    :entryFileURL = "item.articleURL"
                    :tags = "item.tags"
                    contentContext = "TRAINING"
                    :clickCallback = "tapEntryCallback"
                    />
                </Col>
              </Row>
              </p>
          </Panel>


          <Panel name="Case_Studies" style="text-align: left;">
              <strong>Case Studies</strong>
              <Button type="primary" size="small" v-show="caseStudiesFeed.totalArticles > 3" class="accordionShowAllButton" v-on:click.stop="handleShowCaseStudies()">({{ caseStudiesFeed.totalArticles }}) Case Studies - View More</Button>
              <p slot="content">
              <Row :gutter="16">
                <Col v-for="item in caseStudiesFeedFiltered" :key="item.id" v-bind="responsiveGridHorizontalListOfEntries" >

                  <Loading :active="isLoadingCaseStudies" :is-full-page="false"/>

                  <SmartlibBasicEntryCell
                    :title = "item.title"
                    :description = "item.description"
                    :lastModified = "item.lastModified"
                    :entryID = "item.id"
                    :type = "item.type"
                    :tileIconURL = "item.thumbURL"
                    :entryFileURL = "item.articleURL"
                    :tags = "item.tags"
                    contentContext = "CASESTUDY"
                    :clickCallback = "tapEntryCallback"
                    />
                </Col>
              </Row>
              </p>
          </Panel>


          <Panel name="Muscle_Localization_And_Anatomy" style="text-align: left;">
              <strong>Muscle Localization and Anatomy</strong>
              <Button type="primary" size="small" v-show="muscleLocalizationAndAnatomyFeed.totalArticles > 3" class="accordionShowAllButton" v-on:click.stop="handleShowMuscleLocations()">({{ muscleLocalizationAndAnatomyFeed.totalArticles }}) Muscle Localization and Anatomy - View More</Button>
              <p slot="content">
              <Row :gutter="16">
                <Col v-for="item in muscleLocalizationAndAnatomyFeedFiltered" :key="item.id" v-bind="responsiveGridHorizontalListOfEntries" >

                  <Loading :active="isLoadingMuscleLocalizationAndAnatomy" :is-full-page="false"/>

                  <SmartlibBasicEntryCell
                    :title = "item.title"
                    :description = "item.description"
                    :lastModified = "item.lastModified"
                    :entryID = "item.id"
                    :type = "item.type"
                    :tileIconURL = "item.thumbURL"
                    :entryFileURL = "item.articleURL"
                    :tags = "item.tags"
                    contentContext = "ANATOMY"
                    :clickCallback = "tapEntryCallback"
                    />
                </Col>
              </Row>
              </p>
          </Panel>


          <Panel name="Self_Assessment" style="text-align: left;">
              <strong>Self Assessment</strong>
              <Button type="primary" size="small" v-show="selfAssessmentFeed.totalArticles > 3" class="accordionShowAllButton" v-on:click.stop="handleShowSelfAssessment()">({{ selfAssessmentFeed.totalArticles }}) Self Assessment - View More</Button>
              <p slot="content">
              <Row :gutter="16">
                <Col v-for="item in selfAssessmentFeedFiltered" :key="item.id" v-bind="responsiveGridHorizontalListOfEntries" >

                  <Loading :active="isLoadingSelfAssessment" :is-full-page="false"/>

                  <SmartlibBasicEntryCell
                    :title = "item.title"
                    :description = "item.description"
                    :lastModified = "item.lastModified"
                    :entryID = "item.id"
                    :type = "item.type"
                    :tileIconURL = "item.thumbURL"
                    :entryFileURL = "item.articleURL"
                    :tags = "item.tags"
                    contentContext = "SELF_ASSESSMENT"
                    :clickCallback = "tapEntryCallback"
                    />
                </Col>
              </Row>
              </p>
          </Panel>


          <Panel name="BnTx_Software_Resources" v-model="responsiveGridHorizontalListOfEntries" style="text-align: left;">
              <strong>BnTx Software Resources</strong>
              <p slot="content">
              <Row :gutter="5">
                <Col v-bind="responsiveGridHorizontalListOfEntries" >
                  <Card class="shadow">
                    <Row>
                      <Col span="8">
                        <div class="icon-wrapper">
                          <img alt="user guide" src="@/assets/icon-software.svg"><br>
                        </div>
                      </Col>
                      <Col span="16">
                        <div style="padding-left:10px;">
                          <!-- <Icon type="ios-document-outline" size="30" style="padding:5px;" /> -->
                          <strong>BnTx User Guide</strong><br />
                          Discover all the benefits you are able to gain from the features available through the BnTx software.
                          <Row type="flex" justify="end">
                            <Button class="view-guide-button" type="text" @click="handleShowBnTxUserGuide()">View Guide →</Button>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col v-bind="responsiveGridHorizontalListOfEntries" >
                  <Card class="shadow">
                    <Row>
                      <Col span="8">
                        <div class="icon-wrapper">
                          <img alt="onboarding videos" src="@/assets/icon-onboarding.svg"><br>
                        </div>
                      </Col>
                      <Col span="16">
                        <div style="padding-left:10px;">
                          <!-- <Icon type="ios-document-outline" size="30" style="padding:5px;" /> -->
                          <strong>Onboarding Videos</strong><br />
                          No time to read the user guide? No problem. Check out our onboarding videos to get you started in no time.
                          <Row type="flex" justify="end">
                            <Button class="view-guide-button" type="text" @click="handleShowOnboardingVideos()">Show Videos →</Button>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col v-bind="responsiveGridHorizontalListOfEntries" >
                  <Card class="shadow">
                    <Row>
                      <Col span="8">
                        <div class="icon-wrapper">
                          <img alt="support" src="@/assets/icon-contact.svg"><br>
                        </div>
                      </Col>
                      <Col span="16">
                        <div style="padding-left:10px;">
                          <!-- <Icon type="ios-document-outline" size="30" style="padding:5px;" /> -->
                          <strong>Support</strong><br />
                          Having problems? Want to suggest a feature? Contact support and we will assist you the best we can.
                          <Row type="flex" justify="end">
                            <Button class="view-guide-button" type="text" @click="handleEmailSupport()">Email Support →</Button>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              </p>
          </Panel>
        </Collapse>
      </Col>
    </Row>
    <Row>
      <Col></Col>
    </Row>
    <Row>
      <Col></Col>
    </Row>
    <Row>
      <Col></Col>
    </Row>
      </Content>
      <!-- <Footer><i-copyright /></Footer> -->
    </Layout>

    
  </div>
</template>

<script>

// import HelloWorld from './components/HelloWorld.vue'
import SmartlibBasicEntryCell from './SmartlibBasicEntryCell.vue'


import 'vue-loading-overlay/dist/vue-loading.css';
import VueLoading from 'vue-loading-overlay';
const {CONFIG} = require('@/js/bntx-config')
const {itBnTx} = require('@/js/itBnTx')

import AnalyticsMgr from '@/js/AnalyticsManager.js';

//import testing from '../js/mockData.js'   // UNCOMMENT TO ENABLE STUBBED DATA FEED

// import pdf from 'vue-pdf'

var qs = require('qs');
var axios = require("axios");


var axiosInstance = null;
 


export default {
  name: 'EducationMain',
  components: {
    // HelloWorld
    // pdf,
    SmartlibBasicEntryCell,
    Loading : VueLoading
  },

  created () {

  },

  destroyed() {

  },

  mounted() {
    this.$gtag.pageview(this.$route)
  },
  
  data() {
            return {
                isLoadingNotices: true,
                isLoadingCommunityResources: true,
                isLoadingLectures: true,
                isLoadingTrainingModules: true,
                isLoadingCaseStudies: true,
                isLoadingMuscleLocalizationAndAnatomy: true,
                isLoadingSelfAssessment: true,
                isLoadingUserGuide: true,
                isLoadingOnboardingVideos: true,


                showSmartEntryDialog: false,

                showNoticesDialog: false,
                showCommunityResourcesDialog: false,
                showLecturesDialog: false,
                showTrainingModulesDialog: false,
                showCaseStudiesDialog: false,
                showMuscleLocalizationAndAnatomyDialog: false,
                showSelfAssessmentDialog: false,
                showUserGuideDialog: false,
                showOnboardingVideosDialog: false,


                resourceNoticesDeeplinkURL: "about:blank",
                resourceCommunityResourcesDeeplinkURL: "about:blank",
                resourceLecturesDeeplinkURL: "about:blank",
                resourceTrainingModulesDeeplinkURL: "about:blank",
                resourceCaseStudiesDeeplinkURL: "about:blank",
                resourceMuscleLocalizationAndAnatomyDeeplinkURL: "about:blank",
                resourceSelfAssessmentDeeplinkURL: "about:blank",
                resourceBnTxUserGuideURL: "https://prod-content.bntxinteract.com/support/bntx-userguide.pdf",
                resourceOnboardingVideosDeeplinkURL: "about:blank",

                // resourceBnTxUserGuideURL: "http://docs.google.com/gview?url=https://prod-content.bntxinteract.com/support/bntx-userguide.pdf&embedded=true",  // test rendering pdf in google pdf renderer.
                // resourceBnTxUserGuideURL: "http://sites.lafayette.edu/newquisk/files/2011/08/ken-video.m4v",  // test a video
                // resourceTrainingModulesDeeplinkURL: "https://test-my.interact.technology/#/login?companyId=2704&brochureId=15310&autoLoginID=DB93343F-25F2-465A-861D-789881A4BD00&autoLoginAuth=authtoken",
                // resourceCommunityResourcesDeeplinkURL: "https://test-my.interact.technology/#/login?companyId=3484&brochureId=17366&autoLoginID=DB93343F-25F2-465A-861D-789881A4BD00&autoLoginAuth=authtoken",

                resourceSmartLibEntryFileURL: "about:blank",
                resourceSmartLibEntryFileTitle: "",




                sectionsExpandedNames: ["Lectures", "Training_Modules", "Case_Studies", "Muscle_Localization_And_Anatomy", "Self_Assessment", "BnTx_Software_Resources"],

                noticesFeed: this.placeholderFeed(),
                communityFeed: this.placeholderFeed(),
                lecturesFeed: this.placeholderFeed(),
                trainingModulesFeed: this.placeholderFeed(),
                caseStudiesFeed: this.placeholderFeed(),
                muscleLocalizationAndAnatomyFeed: this.placeholderFeed(),
                selfAssessmentFeed: this.placeholderFeed(),
                userGuideFeed: this.placeholderFeed(),
                onboardingVideosFeed: this.placeholderFeed(),

                responsiveGrid2Sections : {
                  xl: 12,
                  lg: 12,
                  md: 12,
                  sm: 24,
                  xs: 24,
                },
                responsiveGridHorizontalListOfEntries : {
                  xl: 8,
                  lg: 8,
                  md: 8,
                  sm: 24,
                  xs: 24,
                }

            }
        },

    methods: {
      visibilityChanged (isVisible, entry) {
          entry
          
          if (isVisible == true) {
              AnalyticsMgr.sendPageView('/EducationTraining')

              this.refreshInternalsAndUI()
          }
      },
      
      refreshInternalsAndUI() {
        // do stuff when the view is created and the data is
        // already being observed

        var queryParams = qs.parse(document.location.search.substr(1));   // remove the ? from the query portion of URL.

        if (this.$store.state.btxAuthToken == "" && queryParams.token !== undefined) {
          this.$store.commit("btxAuthToken", queryParams.token);
        }
        // if we landed on this page without the 'standard' query params:
        // .../?token=blah#/
        // but got the Vue query param from which was routed to from the /login page
        // .../#/login?token=blah
        // then use that token and put it in the place of the other, so the rest of the code works 
        // as though we had got a token the 'proper way'.
        // for that matter, if the Vue style param exists, take that as priority over the other.
        // as when we come in with the proper way and the token expires we go to /login still having the param
        // stay in the URI, then when we add the vue updated param we end up with two tokens.
        // if (/* queryParams.token === undefined && */ this.$route.query.token !== undefined)
        //   queryParams.token = this.$route.query.token;

        // console.log(queryParams);

        if (this.$store.state.btxAuthToken == "") {
          this.$router.push({name:"login"});
        } else {
          axios.get(itBnTx.baseUrl + '/rest/token/education', {
              headers: {'x-neurointeract-authtoken': this.$store.state.btxAuthToken}
          }).then(response => {

            this.$store.commit("educationToken", response.data.educationToken);

            axiosInstance = axios.create({
              baseURL: itBnTx.baseUrl,
              timeout: 10000,
              headers: {'Authorization': response.data.educationToken}
            });


    //        testing.setupMockData(axiosInstance);   // UNCOMMENT TO ENABLE STUBBED DATA FEED


            this.fetchAllFeedData();

          }).catch(error => {
            // handle error

            // if we have a networking error (response is undefined) or an auth token error... go to login page...
            if (error.response !== undefined && error.response.status == 401 && error.response.data.responseCode == 1018) {

              this.$Message.error({content:'Session has expired, please login again.', duration: 5});
              this.$router.push({name:"login"});

            } else {
              this.$router.push({name:"login"});  // for now, direct everything else that's an error to login page too...

            }
          });

        }
      },

      placeholderFeed() {
        var newPlaceholder = new Object()

        newPlaceholder.brochureId = 0;
        newPlaceholder.companyId = 0;
        newPlaceholder.mideeplink = "";
        newPlaceholder.articles = [
                      {
                          id: "placeholder",
                          title: 'Loading...',
                          description: '',
                          type: "PDF",
                          articleDate: 0,
                          lastModified: 0,
                          thumbURL: './images/placeholderThumbnail.png',
                          articleURL: '',
                          tags: []
                        }
                    ];
        return newPlaceholder;
      },

      fetchAllFeedData() {

        // https://docs.google.com/document/d/1EERokKfoWaEYo-Pp7oGOAx6CZlGE49mU_AcSigllgwY/edit

        axiosInstance.get("/rest/education/notices")
                     .then(response => {
                        this.noticesFeed = response.data;
                        this.isLoadingNotices = false;
                     })
                     .catch(error => {
                        // handle error
                        console.log(error);
                     });

        axiosInstance.get("/rest/education/communityResources")
                     .then(response => {
                        this.communityFeed = response.data;
                        this.isLoadingCommunityResources = false;
                      });

        axiosInstance.get("/rest/education/lectures")
                     .then(response => {
                      this.lecturesFeed = response.data;
                      this.isLoadingLectures = false;
                    });

        axiosInstance.get("/rest/education/trainingModules")
                     .then(response => {
                      this.trainingModulesFeed = response.data;
                      this.isLoadingTrainingModules = false;
                    });

        axiosInstance.get("/rest/education/caseStudy")
                     .then(response => {
                      this.caseStudiesFeed = response.data;
                      this.isLoadingCaseStudies = false;
                    });

        axiosInstance.get("/rest/education/muscleLocalizationAndAnatomy")
                     .then(response => {
                      this.muscleLocalizationAndAnatomyFeed = response.data;
                      this.isLoadingMuscleLocalizationAndAnatomy = false;
                    });

        axiosInstance.get("/rest/education/selfAssessment")
                     .then(response => {
                      this.selfAssessmentFeed = response.data;
                      this.isLoadingSelfAssessment = false;
                    });

        axiosInstance.get("/rest/education/userguide")
                     .then(response => {
                      this.userguideFeed = response.data;
                      this.isLoadingUserguide = false;

                      this.userguideFeed.articles.forEach(entry => {
                        if (entry.title.includes("User Guide") === true) {
                          this.resourceBnTxUserGuideURL = entry.articleURL;
                        }
                      });
                    });

        axiosInstance.get("/rest/education/onboardingVideos")
                     .then(response => {
                      this.onboardingVideosFeed = response.data;
                      this.isLoadingOnboardingVideos = false;
                    });


      },

      tapEntryCallback(inURL, inTitle, inEntryID, inType, inContentContext) {

        // https://javascript.info/url
        //inURL = inURL+"&btx_target=1";

        let url = new URL(inURL);

        // if we are a html/url assume the publisher has no idea of the needed requirements, so open in new tab.
        // unless we have a special key/param named "btx_target" which if present will force the URL to load 'inline'.
        // NOTE: if the URL is not matched with this sites protocol (eg http + https), then the iframe will not load.
        if ((inType == 'HTML' || inType == 'URL') && url.searchParams.has('btx_target') == false) {
          window.open(inURL, '_blank');
        } else {
          this.resourceSmartLibEntryFileURL = inURL;
          this.resourceSmartLibEntryFileTitle = inTitle;

          this.showSmartEntryDialog = true;
        }

        // ===== ANALYTICS =====
        AnalyticsMgr.sendEventWithValue("/EducationTraining/ViewContent", "click", "VIEW_" + inContentContext + "__" + inTitle, inEntryID)
        // ===== ANALYTICS =====
      },

      smartLibDeepLinkURLWithCurrentToken(inSmartLibDeepLink) {
        return inSmartLibDeepLink + "&" + qs.stringify({ autoLoginAuth: this.$store.state.btxAuthToken })
      },

      handleShowNotices() {
        this.resourceNoticesDeeplinkURL = this.smartLibDeepLinkURLWithCurrentToken(this.noticesFeed.mideeplink);

        //this.showNoticesDialog = true;
        window.open(this.resourceNoticesDeeplinkURL, "resourceNoticesDeeplinkURL")

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/Notices');
        // ===== ANALYTICS =====
      },

      handleShowCommunityResources() {
        this.resourceCommunityResourcesDeeplinkURL = this.smartLibDeepLinkURLWithCurrentToken(this.communityFeed.mideeplink);

        //this.showCommunityResourcesDialog = true;
        window.open(this.resourceCommunityResourcesDeeplinkURL, "resourceCommunityResourcesDeeplinkURL")

        // ===== ANALYTICS =====
          AnalyticsMgr.sendPageView('/EducationTraining/CommunityResources');
        // ===== ANALYTICS =====
      },

      handleShowLectures() {
        this.resourceLecturesDeeplinkURL = this.smartLibDeepLinkURLWithCurrentToken(this.lecturesFeed.mideeplink);

        // this.showLecturesDialog = true;
        window.open(this.resourceLecturesDeeplinkURL, "resourceLecturesDeeplinkURL")

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/Lectures');
        // ===== ANALYTICS =====
      },

      handleShowTrainingModules() {
        this.resourceTrainingModulesDeeplinkURL = this.smartLibDeepLinkURLWithCurrentToken(this.trainingModulesFeed.mideeplink);

        // this.showTrainingModulesDialog = true;
        window.open(this.resourceTrainingModulesDeeplinkURL, "resourceTrainingModulesDeeplinkURL")

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/TrainingModules');
        // ===== ANALYTICS =====
      },

      handleShowCaseStudies() {
        this.resourceCaseStudiesDeeplinkURL = this.smartLibDeepLinkURLWithCurrentToken(this.caseStudiesFeed.mideeplink);

        // this.showCaseStudiesDialog = true;
        window.open(this.resourceCaseStudiesDeeplinkURL, "resourceCaseStudiesDeeplinkURL")

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/CaseStudies');
        // ===== ANALYTICS =====
      },

      handleShowMuscleLocations() {
        this.resourceMuscleLocalizationAndAnatomyDeeplinkURL = this.smartLibDeepLinkURLWithCurrentToken(this.muscleLocalizationAndAnatomyFeed.mideeplink);

        // this.showMuscleLocalizationAndAnatomyDialog = true;
        window.open(this.resourceMuscleLocalizationAndAnatomyDeeplinkURL, "resourceMuscleLocalizationAndAnatomyDeeplinkURL")

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/MuscleLocalizationAndAnatomy');
        // ===== ANALYTICS =====
      },

      handleShowSelfAssessment() {
        this.resourceSelfAssessmentDeeplinkURL = this.smartLibDeepLinkURLWithCurrentToken(this.selfAssessmentFeed.mideeplink);

        // this.showSelfAssessmentDialog = true;
        window.open(this.resourceSelfAssessmentDeeplinkURL, "resourceSelfAssessmentDeeplinkURL")

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/SelfAssessment');
        // ===== ANALYTICS =====
      },

      handleShowBnTxUserGuide() {
        //this.showUserGuideDialog = true;
        window.open(this.resourceBnTxUserGuideURL, "resourceBnTxUserGuideURL")

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/BnTxUserGuide');
        // ===== ANALYTICS =====
      },

      handleShowOnboardingVideos() {
        this.resourceOnboardingVideosDeeplinkURL = this.smartLibDeepLinkURLWithCurrentToken(this.onboardingVideosFeed.mideeplink);

        // this.showOnboardingVideosDialog = true;
        window.open(this.resourceOnboardingVideosDeeplinkURL, "resourceOnboardingVideosDeeplinkURL")

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/OnboardingVideos');
        // ===== ANALYTICS =====
      },

      handleEmailSupport() {
        var mail = document.createElement("a");
        mail.href = CONFIG.SUPPORT_EMAIL + "?subject=BnTx%20Support%20Required";
        mail.target = "_blank";
        mail.click();

        // ===== ANALYTICS =====
        AnalyticsMgr.sendPageView('/EducationTraining/EmailSupport');
        // ===== ANALYTICS =====
    },




      onLoadiFrame() {

      }

    },

  computed: {
    noticesFiltered() {
      return this.noticesFeed.articles.slice(0, 3);
    },

    communityFeedFiltered() {
      return this.communityFeed.articles.slice(0, 3);
    },
    
    lecturesFeedFiltered() {
      return this.lecturesFeed.articles.slice(0, 3);
    },

    trainingModulesFeedFiltered() {
      return this.trainingModulesFeed.articles.slice(0, 3);
    },

    caseStudiesFeedFiltered() {
      return this.caseStudiesFeed.articles.slice(0, 3);
    },

    muscleLocalizationAndAnatomyFeedFiltered() {
      return this.muscleLocalizationAndAnatomyFeed.articles.slice(0, 3);
    },

    selfAssessmentFeedFiltered() {
      return this.selfAssessmentFeed.articles.slice(0, 3);
    }, 

    userGuideFeedFiltered() {
      return this.userGuideFeed.articles.slice(0, 3);
    }, 

    onboardingVideosFeedFiltered() {
      return this.onboardingVideosFeed.articles.slice(0, 3);
    } 


  },

  watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...

    'showSmartEntryDialog': function (newVal, oldVal){
      newVal  // shut up lint.

      // if user is dismissing the UI... unload the resources for the iframe.
      if (oldVal == true) {
        this.resourceSmartLibEntryFileURL = "about:blank";
        this.resourceSmartLibEntryFileTitle = "";
      }
    }
  }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
h1{
  color: #334155;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Roboto';
}

.heading-row{
  background: #F0F0F0;
  color: #334155;
  font-size: 18px;
  font-weight: bold;
  padding: 9px 28px 11px 28px;
}

#educationMain {
  font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fullScreenIFrame {
  width: 100%;
  height: 100%;
  margin: -6px 0px 0px 0px;
  
  /* border: 3px solid red; */
}

.accordionShowAllButton {
  float: right;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-right: 16px;
  color: #334155 !important;
  background: transparent !important;
  font-weight: bold !important;
  border: 0 !important;
  border-bottom: 1px solid #334155 !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ivu-btn-primary{
  color: #334155 !important;
  background: transparent !important;
  font-weight: bold !important;
  border: 0 !important;
  border-bottom: 1px solid #334155 !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ivu-layout-header {
  background: #FFFFFF;
  height: 32px;
  line-height: 32px;
}

.ivu-layout-content {
  background: #FFFFFF;
}
/* 
.ivu-collapse>.ivu-collapse-item>.ivu-collapse-header{
  background: white;
  border: 0;
  border-bottom: 2px solid #EBF2F7;
  font-size: 18px;
  color: #334155;
  font-weight: bold;
  font-family: 'Roboto';
} */

.ivu-collapse{
  background: #FFF !important;
  border: 0 !important;
}

.ivu-card{
  border: 0 !important;
  width: 100% !important;
}

.ivu-list{
  width: 100%;
}

.ivu-list-item .shadow{
  box-shadow: none !important;
}


.card-wrapper{
  background: white;
  -webkit-box-shadow: 0 5px 10px rgb(82, 87, 93, 0.1);
  -moz-box-shadow: 0 5px 10px rgb(82, 87, 93, 0.1);
  box-shadow: 0 5px 10px rgb(82, 87, 93, 0.1);
  margin-top: 14px !important;
  margin-bottom: 30px !important;
  padding: 10px 20px 20px 5px !important;
}

.card-wrapper .ivu-list-item{
  border-bottom: 1.5px solid #EBF2F7 !important;
}

.view-guide-button{
  background: transparent;
  color: #C2410C !important;
  font-size: 13px;
  font-weight: 600;
}

.icon-wrapper{
  background: #FFF;
  max-width: 108px;
  width: 100%;
  height: 100%;
  max-height: 61px;
  display: inline-block;
  padding: 14px;
  text-align: center;
}
</style>

<style>
.ivu-collapse-header {
  border-bottom: 2px solid #EBF2F7!important;
  color: #334155 !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.ivu-collapse>.ivu-collapse-item{
  border: 0 !important;
}
/* 
.ivu-list-items{
  background: white;
  -webkit-box-shadow: 0 5px 10px rgb(82, 87, 93, 0.1);
  -moz-box-shadow: 0 5px 10px rgb(82, 87, 93, 0.1);
  box-shadow: 0 5px 10px rgb(82, 87, 93, 0.1);
  margin-top: 14px !important;
  margin-bottom: 30px !important;
} */

.shadow {
  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
  -webkit-box-shadow: 3px 3px 5px 2px rgb(243, 243, 243);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 2px rgb(243, 243, 243);  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 2px rgb(243, 243, 243);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

</style>

