<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
    <div id="pm-ref-stats" class="layout" >

        <iframe :src="nationalStatsURL"
                style="width:100%; height:100%; border-width: 0;"
                />
    </div>
</template>

<script>

// const {CONFIG} = require('@/js/bntx-config')
// import AnalyticsMgr from '@/js/AnalyticsManager.js';
// var qs = require('qs');
// import VueQrcode from 'vue-qrcode'
// const axios = require('axios');

// const {RECORDS_SYMPTOMS} = require('@/js/migraineMock.js')

const {itBnTx} = require('@/js/itBnTx')
// var _ = require('lodash');



// import moment from 'moment'


export default {
    name: 'pm-ref-stats',
    components: {
    },

    
    created () {

    },

    beforeDestroy() {

    },

    destroyed() {
        
    },

    mounted() {
        

    },

    data () {
            return {
                

            }
        },
    computed: {
       nationalStatsURL() {
            var helpShown = localStorage.getItem("com.bntxinteract.nationalStatsHelpShown")

            if (helpShown == null) {
                helpShown = false
                localStorage.setItem("com.bntxinteract.nationalStatsHelpShown", true)
            }

            return (itBnTx.environment == 'prod'
                     ? "https://btx-face-vite.vercel.app"
                     : "https://btx-face-vite-git-develop-teerzo.vercel.app/")
                    + (helpShown == false ? "/?help=true" : "")
       }

        

    },
    methods: {




    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...


    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>

.layout {
    padding: 8px;
    height: 100%;
}



</style>



