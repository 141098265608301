// const axios = require('axios');


/*
    activePhysicianCount: 2
    planId: ""
    plans: [{active: true, billing_scheme: "tiered", created: 1632786825, currency: "aud",…},…]
        0: {active: true, billing_scheme: "tiered", created: 1632786825, currency: "aud",…}
            active: true
            billing_scheme: "tiered"
            created: 1632786825
            currency: "aud"
            id: "price_1JeTdlIKIxEKoiJR4gV25di4"
            interval: "month"
            interval_count: 1
            livemode: false
            metadata: {}
            object: "plan"
            product: {id: "prod_KJ5pbTDC4QwTQp", expandedObject: {active: true, attributes: [], created: 1632786824,…}}
                expandedObject: {active: true, attributes: [], created: 1632786824,…}
                active: true
                attributes: []
                created: 1632786824
                description: "Unlimited Patients, Patient Management, Patient Assessments, Patient Injections, Patient Session Report and Education Center."
                id: "prod_KJ5pbTDC4QwTQp"
                images: [,…]
                    0: "https://files.stripe.com/links/MDB8YWNjdF8xSXQ1S3NJS0l4RUtvaUpSfGZsX3Rlc3RfR3Y2QnFSa0lyWnRhejFZbTN4ZkdBRUFU00oNKZbQfO"
                livemode: false
                metadata: {popular: "false"}
                    popular: "false"
                name: "BnTx Starter"
                object: "product"
                type: "service"
                updated: 1633418541
                id: "prod_KJ5pbTDC4QwTQp"
            tiers: [{unit_amount: 2000, unit_amount_decimal: 2000, up_to: 1},…]
                0: {unit_amount: 2000, unit_amount_decimal: 2000, up_to: 1}
                1: {unit_amount: 1500, unit_amount_decimal: 1500}
            tiers_mode: "graduated"
            usage_type: "licensed"
        1: {active: true, billing_scheme: "tiered", created: 1633044508, currency: "aud",…}
        2: {active: true, billing_scheme: "tiered", created: 1633044712, currency: "aud",…}
    publishableKey: "pk_test_51It5KsIKIxEKoiJR4UqdkZpCrhX5eapLlAW98SNbjWDjEppqv2yuBQ5Kqq7NNMXV4XvoSjxk3UE7kglPfL3WqDyM00iCpdUa0R"
    quantity: 0
    url: ""
    accountStatus: "P"
    daysLeft: 30
    usingActivationCode: false
    activationCodeDaysLeft: 0
*/
function getSubscriptionPlans({inReturnAllPlans = false} = {}) {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/billing/subscription-plans',
        inRequireAuth: true,
        inQueryParams: {
            returnAll : inReturnAllPlans
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}

function createCheckoutSession({
                        inPriceID
                    } = {}) {
    return this.sendRequest({
        inMethod: 'post',
        inURI: '/rest/billing/create-checkout-session',
        inRequireAuth: true,
        inQueryParams: {
            priceId : inPriceID
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}

function getCustomerPortalURL() {
    return this.sendRequest({
        inMethod: 'post',
        inURI: '/rest/billing/customer-portal',
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}


function createSubscriptionUsingActivationCode({
                        inActivationCode,
                        inOverrideExistingActivationCodes = false
                    } = {}) {
    return this.sendRequest({
        inMethod: 'post',
        inURI: '/rest/billing/subscribe-with-code',
        inRequireAuth: true,
        inQueryParams: {
            activationCode : inActivationCode,
            override : inOverrideExistingActivationCodes
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: '',
        inSilenceError : true
        });
}


/*
    requires to be superuser to call

    resets the specificed practices' details so that internally the stripe customer id and subscription pland etc etc are all reset. good for testing etc.
 */
function resetPracticeStripe({
                        inPracticeID
                    } = {}) {
    return this.sendRequest({
        inMethod: 'put',
        inURI: '/rest/billing/reset-practice-stripe',
        inRequireAuth: true,
        inQueryParams: {
            practiceId : inPracticeID
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}



/*
    - added new endpoint PUT /rest/billing/update-trial-start-date (requires super user role), the cURL for DEV env:
    curl --location --request PUT 'http://localhost:8081/billing/update-trial-start-date?practiceId=1&newStartDate=1634925733812' \
    --header 'x-neurointeract-authtoken: ODkpVthUQ2r92FTcNrc4rQ=='
    or curl --location --request PUT 'http://localhost:8081/billing/update-trial-start-date?practiceId=1&newStartDate=1634925733812&isExtension=true' \
    --header 'x-neurointeract-authtoken: ODkpVthUQ2r92FTcNrc4rQ=='
    for the BE to execute an auto extension (default 60 days in the application.properties) on the trial date if isExtension=true
*/
function updateTrialStartDate({
                        inPracticeID,
                        inStartDate,
                        inIsExtension = false
                    } = {}) {
    return this.sendRequest({
        inMethod: 'put',
        inURI: '/rest/billing/update-trial-start-date',
        inRequireAuth: true,
        inQueryParams: {
              practiceId : inPracticeID,
            newStartDate : inStartDate,
             isExtension : inIsExtension ? 'true' : 'false'
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}

/*
- added new endpoint /rest/billing/update-cc-expiry-date PUT to update the practice's subscription payment method's expiry date, need super user to execute and only allow to execute in TEST env or practice name contains 'TEST|PVT' in PROD env
- updated the endpoint /rest/billing/update-cc-expiry-date PUT to accept new @QueryParam("practiceId") Long practiceId to allow super user to update the payment method expiry date for TESTING purpose without switching to the practice
 curl --location --request PUT 'http://localhost:8081/practise/new-expiry-date?practiceId=1&expiryDate=10-2021' \
--header 'x-neurointeract-authtoken: O7m6SFJjifrJ/b3pmLC6FQ=='

expiryDate = "MM-YYYY" as string
*/
function updateCCExpiryDate({
                        inPracticeID,
                        inExpiryDate,
                    } = {}) {
    return this.sendRequest({
        inMethod: 'put',
        inURI: '/rest/billing/update-cc-expiry-date',
        inRequireAuth: true,
        inQueryParams: {
              practiceId : inPracticeID,
              expiryDate : inExpiryDate
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
        });
}


module.exports = { 

    getSubscriptionPlans,
    createCheckoutSession,
    getCustomerPortalURL,

    createSubscriptionUsingActivationCode,

    // DEBUG / TEST AUTOMATION HELPERS
    resetPracticeStripe,
    updateTrialStartDate,

    updateCCExpiryDate,
};
