<template>
    <div id="su-campaign-management" v-observe-visibility="visibilityChanged" style="padding: 8px;height:100%;">

         <!-------------------------------
           -- New/Edit Campaign Form UI --
           ------------------------------->
         <Drawer
            v-model="showCampaignDetails"
            width="75"
            :mask-closable="false"
            :styles="detailsDrawerStyles">

            <p slot="header"><center>{{ isCreatingNewEntry ? "Create New" : "Edit" }} Campaign Details</center></p>

            <Row :gutter="20">
                <Col v-bind="responsiveGrid2Sections">
                    <Card :padding="8" style="width: 100%;">
                        <p slot="title">Campaign</p>

                        <Form ref="campaignDetailsFormFields" :model="editEntryDetails" :rules="campaignFieldRules" :label-width="150" label-position="right">

                            <FormItem label="Name:" prop="cName" :class="mandatoryFieldsStyle">
                                <Input v-model="editEntryDetails.cName" ref="cName" placeholder="Eg. Allergan - Migraine Clinics" :disabled="isFormFieldsInReadOnlyMode" ></Input>
                            </FormItem>
                            
                            <FormItem label="Description:" prop="cDesc" :class="mandatoryFieldsStyle">
                                <Input v-model="editEntryDetails.cDesc" ref="cDesc" type="textarea" placeholder="Eg. Sponsoring small to medium migraine injection clinics in CA, USA" :disabled="isFormFieldsInReadOnlyMode"></Input>
                            </FormItem>
                            
                            <FormItem label="Subscription Type:" prop="sPriceId" :class="mandatoryFieldsStyle" >
                                <Select v-model="editEntryDetails.sPriceId" ref="sPriceId" style="width:175px" :disabled="isFormFieldsInReadOnlyMode">
                                    <Option v-for="item in subscriptionTypeData" :value="item.priceID" :key="item.priceID">{{ item.name }}</Option>
                                </Select>
                            </FormItem>

                            <FormItem label="Start Date:" prop="cStartDate" :class="mandatoryFieldsStyle">
                                <DatePicker v-model="editEntryDetails.cStartDate" ref="cStartDate" type="date" :format="defaultDateInputFormat" :placeholder="defaultDateInputFormat.toUpperCase()" :disabled="isFormFieldsInReadOnlyMode" style="maxWidth: 200px"></DatePicker>
                            </FormItem>
                            
                            <FormItem label="End Date:" prop="cEndDate" :class="mandatoryFieldsStyle">
                                <DatePicker v-model="editEntryDetails.cEndDate" ref="cEndDate" type="date" :format="defaultDateInputFormat" :placeholder="defaultDateInputFormat.toUpperCase()" :disabled="isFormFieldsInReadOnlyMode" style="maxWidth: 200px"></DatePicker>
                            </FormItem>

                            <FormItem label="Benefit Duration:">
                                 <InputNumber :min="1"
                                             :max="12"
                                             v-model="editEntryDetails.cBenefitDuration"
                                             :disabled="isFormFieldsInReadOnlyMode"
                                             style="width:90px"
                                             ></InputNumber> months
                            </FormItem>
                            
                            <FormItem label="No. Codes:">
                                 <InputNumber :min="1"
                                             :max="1000"
                                             v-model="editEntryDetails.aCodeCountRequested"
                                             :disabled="isFormFieldsInReadOnlyMode"
                                             style="width:90px"
                                             ></InputNumber> codes
                            </FormItem>

                            <FormItem label="Code Prefix:">
                                <Input v-model="editEntryDetails.aCodePrefix" placeholder="Eg. SMMC" :disabled="isFormFieldsInReadOnlyMode"></Input>
                            </FormItem>
                            
                            <FormItem label="Status:">
                                <Select v-model="editEntryDetails.status" style="width:175px" filterable :disabled="editEntryDetails.cId != 0">
                                    <Option v-for="item in statusRefData" :value="item.statusCode" :key="item.statusCode">{{ item.statusName }}</Option>
                                </Select>
                            </FormItem>

                            <span class="formFieldTip">Note: Campaign status can only be changed to Live after saving and all details are complete. Once made Live details cannot be altered.</span>
                        </Form>
                        
                    </Card>
                </Col>
                <Col v-bind="responsiveGrid2Sections">
                    <Card :padding="8" style="width: 100%;">
                        <p slot="title">Sponsor Details</p>

                        <Form class="bntx-patient-details-form-referrer" ref="sponsorFormFields" :model="editEntryDetails" :rules="sponsorFieldRules" :label-width="130" label-position="right">
                            <FormItem label="Find or Create Sponsor:">
                                <Row>
                                    <Col span="18">
                                        <Select ref="sponsorDropdown"
                                            v-model="editEntryDetails.sponsor.sId"
                                            style="width:100%"
                                            @on-change="handleSponsorChanged"
                                            :remote-method="loadingFilteringSponsors"
                                            :loading="sponsorsLoadingFiltering"
                                            placeholder="Type to search, then select."
                                            clearable filterable
                                            :disabled="isFormFieldsInReadOnlyMode">

                                            <!-- @on-set-default-options="setDefaultReferringPhysicianOptions" -->
                                            <!-- :default-label="referringPhysicianDefaultLabel" -->


                                            <Option v-for="item in sponsorsFiltered" :value="item.sId" :key="item.sId">{{ item.sName }}</Option>
                                        </Select>
                                    </Col>
                                    
                                    <Col span="3">
                                        <Button type="primary" style="margin-top: 0px; margin-left: 8px" icon="md-person-add" @click="handleNewSponsor" :disabled="isFormFieldsInReadOnlyMode" />
                                    </Col>
                                    <Col span="3">
                                        <Button type="primary" style="margin-top: 0px; margin-left: 4px" icon="md-search" @click="handleSearchSponsors" :disabled="isFormFieldsInReadOnlyMode" />
                                    </Col>
                                </Row>

                                <!-- {{editEntryDetails.referringPhysicianJSON.referringPhysicianId}}
                                {{referringPhysicianDefaultLabel}}
                                <Button @click="editEntryDetails.referringPhysicianJSON.referringPhysicianId = 0">Create</Button> -->

                            </FormItem>

                            <FormItem label="Sponsor Name:" prop="sponsor.sName" :class="mandatoryFieldsStyle">
                                <Input v-model="editEntryDetails.sponsor.sName" ref="sponsor.sName" :disabled="isFormFieldsInReadOnlyMode"></Input>
                            </FormItem>
                            <FormItem label="Contact:" prop="sponsor.sContact" :class="mandatoryFieldsStyle">
                                <Input v-model="editEntryDetails.sponsor.sContact" ref="sponsor.sContact" :disabled="isFormFieldsInReadOnlyMode"></Input>
                            </FormItem>
                            <FormItem label="Email:" prop="sponsor.sEmail" :class="mandatoryFieldsStyle">
                                <Input v-model="editEntryDetails.sponsor.sEmail" ref="sponsor.sEmail" :disabled="isFormFieldsInReadOnlyMode"></Input>
                            </FormItem>
                            <FormItem label="Address:" prop="sponsor.sAddress" :class="mandatoryFieldsStyle">
                                <Input v-model="editEntryDetails.sponsor.sAddress" ref="sponsor.sAddress" :disabled="isFormFieldsInReadOnlyMode"></Input>
                            </FormItem>
                            <FormItem label="Postcode:" prop="sponsor.postcode" :class="mandatoryFieldsStyle">
                                <Input v-model="editEntryDetails.sponsor.postcode" ref="sponsor.postcode" :disabled="isFormFieldsInReadOnlyMode"></Input>
                            </FormItem>
                            <FormItem label="Country:" prop="sponsor.countryCode" :class="mandatoryFieldsStyle" >
                                <Select v-model="editEntryDetails.sponsor.countryCode" filterable ref="sponsor.countryCode" :disabled="isFormFieldsInReadOnlyMode">
                                    <Option v-for="item in countriesRefData" :value="item.code" :key="item.code">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="Contract Ref ID:" prop="sponsor.sContractId" :class="mandatoryFieldsStyle">
                                <Input v-model="editEntryDetails.sponsor.sContractId" ref="sponsor.sContractId" placeholder="Eg. Allergan-2021-10" :disabled="isFormFieldsInReadOnlyMode" ></Input>
                            </FormItem>

                            
                            
                        </Form>
                    </Card>

                </Col>
            </Row>

            <div class="drawer-footer">
                <Button style="margin-right: 8px" @click="handleCancelCampaignDetails">Cancel</Button>
                <Button type="success" @click="handleSaveCampaignDetails">Save</Button>
            </div>
        </Drawer>


         <!----------------------------------
           -- Campaign Activation Codes UI --
           ---------------------------------->
         <Drawer
            v-model="showCampaignActivtionCodes"
            width="75"
            :mask-closable="false"
            :styles="detailsDrawerStyles">

            <p slot="header"><center>Campaign Activation Codes</center></p>

            <Table style="width:100%" :height="tableHeightToFit" size="default" row-key="aCodeId" :columns="activationCodesTableColumnsConfig" :data="entriesActivationCodesTableData" border highlight-row >
                <template slot-scope="{ row }" slot="status"> {{ row.status | userFriendlyActivationCodeStatus }}</template>
                <template slot-scope="{ row }" slot="aStartDate"> {{ row.aStartDate | userFriendlyDate }} </template>
                <template slot-scope="{ row }" slot="aEndDate"> {{ row.aEndDate | userFriendlyDate }} </template>

                <!-- <template slot-scope="{ row }" slot="logo">
                    <img v-if="row.practiseLogo != ''" :src="row.practiseLogo" height="50px" />
                </template> -->
            </Table>

            <div class="drawer-footer">
                <Button style="margin-right: 8px" @click="handleExportCampaignActivationCodes">Export</Button>
                <Button type="success" @click="handleDoneCampaignActivationCodes">Done</Button>
            </div>
        </Drawer>




        
        
        <Divider orientation="left">Campaign Management <span style="font-weight:200">({{ entriesTotalCount }} campaigns)</span>
            <Tooltip placement="bottom">
                <Icon type="md-help-circle" color="#4287f5" />
                <div slot="content" style="white-space: normal">
                    <p>This section allows you to manage all the campaigns you have in the BnTx Interact system.</p>
                    <br />
                    <p>Sponsors are the parent to Campaigns which then generate Activation Codes.</p>
                </div>
            </Tooltip>
        </Divider>
        
        <Row :gutter="8" >
            <Button @click="handleAddEntry" style="margin-left:8px; ">Add Entry</Button>
            <Button @click="handleEditEntry" style="margin-left:8px; margin-right:8px">Edit Entry</Button>

            <Poptip
                confirm transfer
                width="300"
                :title="confirmRemoveEntryMessage"
                ok-text="Yes"
                cancel-text="No"
                @on-ok="handleRemoveEntry">
                
                <Button type="error" style="margin-right:8px">Remove Entry</Button>
            </Poptip>

            <Dropdown @on-click="handleMiscActionMenu" style="margin-right:8px">
                <Button type="info">
                    <Icon type="md-settings" size="20"></Icon>
                    <Icon type="ios-arrow-down"></Icon>
                </Button>
                
                <DropdownMenu slot="list">
                    <DropdownItem name="make-campaign-live" :disabled="!canMakeCampaignLive" >Make Campaign Live</DropdownItem>
                    <DropdownItem name="view-activation-codes" :disabled="!isCampaignLive" divided>View Activation Codes</DropdownItem>
                    <DropdownItem name="export-activation-codes" :disabled="!isCampaignLive" >Export Activation Codes</DropdownItem>
                </DropdownMenu>
            </Dropdown>


            <Input v-model="filterNameBy" placeholder="Search by name." :clearable="true" style="width: 200px; margin-right:8px" />
            <Input v-model="filterDescBy" placeholder="Search by description." :clearable="true" style="width: 200px; margin-right:8px" />
            <!-- <Input v-model="filterByFirstname" placeholder="First name" :clearable="true" @on-change="throttledSearch" style="width: 300px; margin-right: 8px" />
            <Input v-model="filterByLastname" placeholder="Last name" :clearable="true"  @on-change="throttledSearch" style="width: 300px; margin-right: 8px" /> -->

            <Checkbox v-model="showAllStatusCampaigns" style="margin-top:6px; margin-right:8px">Include All Status Campaigns</Checkbox>
            

        </Row>

        <Row style="margin-top:8px">
            <Table style="width:100%" :height="tableHeightToFit" size="default" row-key="cId" :columns="entriesTableColumnsConfig" :data="entriesToDisplay" border highlight-row @on-current-change="handleEntriesSelectionChanged" @on-row-dblclick="handlShowCampaignActivationCodes">
                <template slot-scope="{ row }" slot="sponsor"> {{ row.sponsor.sName }}</template>
                <template slot-scope="{ row }" slot="status"> {{ row.status | userFriendlyCampaignStatus }}</template>
                <template slot-scope="{ row }" slot="cStartDate"> {{ row.cStartDate | userFriendlyDate }} </template>
                <template slot-scope="{ row }" slot="cEndDate"> {{ row.cEndDate | userFriendlyDate }} </template>

                <!-- <template slot-scope="{ row }" slot="logo">
                    <img v-if="row.practiseLogo != ''" :src="row.practiseLogo" height="50px" />
                </template> -->
            </Table>
        </Row>                                        

       
        <!-- <Row style="margin-top:8px">
            <Col span="24" class="ivu-text-right">
                <Page :total="entriesTotalCount" :page-size="entriesListPageSize" :page-size-opts="[20,100,200,500,1000]" show-sizer @on-change="handleLoadEntriesListToNthPage" @on-page-size-change="handlePageLimitChanged" />
            </Col>
        </Row> -->
       
    </div>
</template>

<script>
const {CONFIG} = require('@/js/bntx-config')
//var axios = require("axios");
var _ = require('lodash');
import AnalyticsMgr from '@/js/AnalyticsManager.js';

const {itBnTx} = require('@/js/itBnTx')



export default {
    name: 'su-campaign-management',
    components: {

    },

    created () {

        // create a throttler function which will automatically manage throttling search requests to a limit, currently 1 per however many milliseconds
        this.throttledSearch = _.debounce(() => { this.handleLoadEntriesListToNthPage(1); console.log("handleThrottledSearch 600ms") }, 600, { 'leading': false })

    },

    destroyed() {

    },

    mounted() {


    },


    data () {
            return {
                physicianTitlesRefData: CONFIG.PATIENT_TITLES,

                statusRefData : [
                    { statusCode : 'L', statusName: 'Live'},
                    { statusCode : 'R', statusName: 'Draft'},
                    { statusCode : 'D', statusName: 'Deleted'},

                    { statusCode : 'A', statusName: 'Active'},
                    { statusCode : 'I', statusName: 'Inactive'},

                ],

                subscriptionTypeData : [
                    // { priceID : 'price_012983', name: 'BnTx Lite'},
                    // { priceID : 'price_012985', name: 'BnTx Standard'},
                ],

                plans : [],
                sponsorsData: [],

                sponsorsFiltered: [],
                sponsorsLoadingFiltering: false,


                entriesTableColumnsConfig: [
                    {                      title: 'Campaign Name',        key: 'cName', resizable: true, sortable: true, width: 320 },
                    {                      title: 'Description',          key: 'cDesc', resizable: true, sortable: true, width: 420 },
                    { slot: 'sponsor',     title: 'Sponsor Name',         key: 'sponsor', resizable: true, sortable: false, width: 220 },
                    { slot: 'cStartDate',  title: 'Start Date',           key: 'cStartDate', resizable: true, sortable: true, width: 150 },
                    { slot: 'cEndDate',    title: 'End Date',             key: 'cEndDate', resizable: true, sortable: true, width: 150 },
                    { slot: 'status',      title: 'Status',               key: 'status', resizable: true, sortable: true, width: 150 },
                ],

                throttledSearch : () => {},
                filterByFirstname: "",
                filterByLastname: "",

                entriesListIsLoading : false,
                entriesListPageSize : 20,
                entriesTableData: [],

                entriesTotalCount : 0,
                
                editEntryDetails: {
                                        sponsor: { sId : 0 },
                                        cId : undefined,
                                        status : 'I',
                                        cName : ''
                                        // practiseId: this.$store.state.loggedInDoctorDetails.practiseId,
                                        // firstName: "",
                                        // lastName: "",
                                        // physicianAddress: "",
                                        // physicianPhone: "",
                                        // status: "A",
                                    },
                
                allowOnlyPastDatesOptions : {
                    disabledDate (date) {
                        return date && date.valueOf() > Date.now();
                    }
                },
                savingDetailsToBackend : false,



                liveValidating: true,
                liveValidatingFieldsOK: true,

                campaignFieldRules : {
                    cName: [
                        { required: true, message: 'Campaign Name cannot be empty', trigger: 'blur' },
                    ],
                    sPriceId: [
                        { required: true, message: 'Subscription type must be specified', trigger: 'blur' },
                    ],
                    cStartDate: [
                        { required: true, type: 'date', message: 'Start Date is required', trigger: 'blur' },
                    ],
                    cEndDate: [
                        { required: true, type: 'date', message: 'End Date is required', trigger: 'blur' },
                    ],
                    cBenefitDuration: [
                        { required: true, type: 'integer', message: 'Benefit Duration cannot be empty', trigger: 'blur' },
                    ],
                    aCodeCountRequested: [
                        { required: true, type: 'integer', message: 'Number of Codes cannot be empty', trigger: 'blur' },
                    ],
                    
                },
                sponsorFieldRules : {
                    "sponsor.sName": [
                        { required: true, message: 'Sponsor Name cannot be empty', trigger: 'blur' },
                    ],
                    "sponsor.sEmail": [
                        { required: true, message: 'Sponsor Email cannot be empty', trigger: 'blur' },
                    ],
                    "sponsor.sContact": [
                        { required: true, message: 'Sponsor contact cannot be empty', trigger: 'blur' },
                    ],
                    "sponsor.postcode": [
                        { required: true, message: 'Postcode cannot be empty', trigger: 'blur' },
                    ],
                    "sponsor.countryCode": [
                        { required: true, message: 'Country cannot be empty', trigger: 'blur' },
                    ],

                },


                filterNameBy : '',
                filterDescBy : '',
                showAllStatusCampaigns : false,


                isCreatingNewEntry : false,
                selectedEntryDetailsBeforeCreatingNewEntry : {
                    sponsor: { sId : 0 },
                    status : 'I',
                    cId : undefined,
                    cName : ''
                },      // this is used to restore the previous selection when user cancels create new entry.

                showCampaignDetails: false,

                // ====================
                // ====================

                showCampaignActivtionCodes : false,

                activationCodesTableColumnsConfig: [
                    { slot: 'status',      title: 'Status',               key: 'status', resizable: true, sortable: true, width: 150 },
                    {                      title: 'Activation Code',      key: 'aCodeId', resizable: true, sortable: false, width: 320 },
                    {                      title: 'Clinic Name',          key: 'clinicName', resizable: true, sortable: true, width: 320 },

                    {                      title: 'Post Code',            key: 'clinicPostcode', resizable: true, sortable: true, width: 150 },
                    {                      title: 'Country',              key: 'clinicCountryCode', resizable: true, sortable: true, width: 150 },
                    { slot: 'aStartDate',  title: 'Valid From',           key: 'aStartDate', resizable: true, sortable: true, width: 150 },
                    { slot: 'aEndDate',    title: 'Valid Till',           key: 'aEndDate', resizable: true, sortable: true, width: 150 },
                    // {                      title: 'Sponsor',              key: 'sponsor.sName', resizable: true, width: 150 },
                ],

                entriesActivationCodesTableData: [],

                // ====================
                // ====================


                detailsDrawerStyles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static'
                },


                responsiveGrid2Sections : {
                    xl: 12,
                    lg: 24,
                    md: 24,
                    sm: 24,
                    xs: 24,
                },

            }
        },

    filters: {

    },
    
    computed: {

        countriesRefData() { return CONFIG.COUNTRIES; },

        defaultDateInputFormat() {
            return this.$store.state.defaultDateInputFormat
        },

        // when a campaign is Live or Deleted, it can no longer be edited.
        isFormFieldsInReadOnlyMode() {
            if (this.editEntryDetails == undefined)
                return false
            return this.editEntryDetails.status == 'L' || this.editEntryDetails.status == 'D'
        },

        canMakeCampaignLive() {
            if (this.editEntryDetails != undefined
                && this.editEntryDetails.cId != 0
                && this.editEntryDetails.status == 'R' /* Draft */) {
                return true
            }
            
            return false
        },

        isCampaignLive() {
            if (this.editEntryDetails != undefined
                && this.editEntryDetails.cId != 0
                && this.editEntryDetails.status == 'L' /* Live */) {
                return true
            }
            
            return false
        },

        // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
        mandatoryFieldsStyle() { return (this.liveValidating && (this.liveValidatingFieldsOK == false)) ? "revealValidationMessages" : "" },

        entriesToDisplay() {

            return this.entriesTableData.filter(entry => {

                if (this.showAllStatusCampaigns == false && (entry.status != 'L' && entry.status != 'R')) return false   // show Live and dRaft by default

                if (entry.cName == undefined && this.filterNameBy != '') return false  // if we are searching cName, but entry cName if not defined or empty, we are definitely not a match.
                if (entry.cDesc == undefined && this.filterDescBy != '') return false  // if we are searching firstname, but entry firstname if not defined or empty, we are definitely not a match.


                // // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                // // if ((entry.statusCode == 'I' || entry.statusCode == 'D') && this.showInactiveAndDeletedUsers == false) return false   // dont show inactive or deleted...

                if (this.filterNameBy != '' && entry.cName != undefined && entry.cName.toLowerCase().includes(this.filterNameBy.toLowerCase()) == false) return false // remove all items that dont match our cName filter
                if (this.filterDescBy != '' && entry.cDesc != undefined && entry.cDesc.toLowerCase().includes(this.filterDescBy.toLowerCase()) == false) return false // remove all items that dont match our cDesc filter

                return true
            })

        },

        confirmRemoveEntryMessage() {
            return "Are you sure you wish to remove '" 
                    + this.editEntryDetails.cName + "' from the system?"
        },
        
        tableHeightToFit() {
            return window.innerHeight - 220
        },

    },
    methods: {
        visibilityChanged (isVisible, entry) {
            entry
            
            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/Superuser/CampaignManagement');

                this.refreshDataForUI()
            }
        },

        refreshDataForUI() {

            this.refreshSponsors()
                    
            this.refreshSubscriptionPlans()
                .then(response => {
                    response
                    
                    this.handleLoadEntriesListToNthPage(1)
                    
                })


        },
        
        refreshSubscriptionPlans() {
            return itBnTx.getSubscriptionPlans({ inReturnAllPlans : true })
                        .then(response => {
                            // console.log("getSubscriptionPlans", response.data)
                            this.plans = response.data.plans
                            this.subscriptionTypeData = []

                            // iterate through all plans to extract the priceID and name
                            for (var index = 0; index < this.plans.length; index++) {
                                this.subscriptionTypeData.push({
                                                                    priceID : this.plans[index].id,
                                                                    name : this.plans[index].product.expandedObject.name
                                                                })
                            }
                        })
        },

        refreshSponsors() {
            itBnTx.getAllSponsors().then(response => { 
                // get the referring physicians, then sort by title/firstname/lastname
                this.sponsorsData = response.data
                                            .filter(p => { return p.status == 'A' })
                                            .sort((a, b) => {
                                                var a_sponsor = a.sName == undefined ? '' : a.sName
                                                var a_countryCode = a.countryCode == undefined ? '' : a.countryCode
                                                var b_sponsor = b.sName == undefined ? '' : b.sName
                                                var b_countryCode = b.countryCode == undefined ? '' : b.countryCode
                                                
                                                return (([a_sponsor, a_countryCode].join(' ')).trim())
                                                        .localeCompare(([b_sponsor, b_countryCode].join(' ')).trim()) 
                                            });
                
                // push a special referring physician into the list which will trigger 
                // the UI to create a new referring physician
                if (this.sponsorsData.length != 0 && this.sponsorsData[0].sId != 0
                    || this.sponsorsData.length == 0) {

                    this.sponsorsData.unshift({
                        sId : 0,
                        sName: "-- Create New Sponsor --"
                    })
                }
                
               this.loadingFilteringSponsors("")
            })
        },

        loadingFilteringSponsors(query) {
            if (
                query != '' ||
                this.editEntryDetails.sponsor.sId != 0) {

                this.sponsorsLoadingFiltering = true;
                setTimeout(() => {
                    this.sponsorsFiltered = this.sponsorsData.filter(item => {

                            if (item.sId == 0
                                || item.sId == this.editEntryDetails.sponsor.sId) {
                                return true
                            }

                            var a_sponsor = item.sName == undefined ? '' : item.sName
                            // var a_countryCode = item.countryCode == undefined ? '' : item.countryCode
                                                            
                            return ([a_sponsor /*, a_countryCode */].join(' ')).trim().toUpperCase().indexOf(query.toUpperCase()) > -1
                        });
                    this.sponsorsLoadingFiltering = false;
                    console.log("*********** loadingFilteringSponsors - filter  ", this.sponsorsFiltered)
                }, 1);
            } else {
                this.sponsorsFiltered = [this.sponsorsData[0]];
                this.sponsorsLoadingFiltering = false;
                console.log("*********** loadingFilteringSponsors - filter 2 ", this.sponsorsFiltered)
            }
        },

        handlePageLimitChanged(inNewLimit) {
            this.entriesListPageSize = inNewLimit       // silly two way binding not working... so have to do this way
            this.handleLoadEntriesListToNthPage(1)
        },

        handleMiscActionMenu(inMenuName) {
            switch( inMenuName ) {
                case 'make-campaign-live':
                    this.handleMakeLiveEntry()
                    break;
                case 'view-activation-codes':                   
                    this.handlShowCampaignActivationCodes()
                    break;
                
                case 'export-activation-codes':
                    this.handleExportCampaignActivationCodes()
                    break;

            }
        },

        handleLoadEntriesListToNthPage(inPageNumber) {
            inPageNumber

            var loadOrSearchPromise;

            // show loading spinner
            this.entriesListIsLoading = true

            // if (this.filterByFirstname != "" || this.filterByLastname != "") {
            //     loadOrSearchPromise = itBnTx.searchPatientsByName({
            //                                     inSearchFirstnameString : this.filterByFirstname,
            //                                     inSearchLastnameString : this.filterByLastname,
            //                                     inStart : this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit : this.entriesListPageSize })
            // } else {
            //     loadOrSearchPromise = itBnTx.searchPatientsInPractise({
            //                                     inStart: this.entriesListPageSize * (inPageNumber - 1),
            //                                     inLimit: this.entriesListPageSize})
            // }

            loadOrSearchPromise = itBnTx.getAllCampaigns()

            loadOrSearchPromise.then(response => {
                
//                var entries = response.data.patients;
                var entries = response.data;

//                this.entriesTotalCount = response.data.totalCount
                this.entriesTotalCount = response.data.length

                // entries.map(entry => { if (entry.userLastLogin == undefined) entry['userLastLogin'] = 0 })
                this.entriesTableData = entries;
                
                var foundEntryToHighlight = false

                if (this.editEntryDetails.cId != undefined) {
                    // if we previously had a selection, try to preserve the same record which was previously selected
                    var previousSelection = this.entriesToDisplay.find(entry => { return entry.cId == this.editEntryDetails.cId} )

                    // sanity check... should never happen... unless someone deletes it from another location.
                    if (previousSelection != undefined) {
                        previousSelection["_highlight"] = true
                        this.handleEntriesSelectionChanged(previousSelection, null)
                        foundEntryToHighlight = true
                    }
                }
                
                if (foundEntryToHighlight == false) {
                    // else select the first record if nothing was selected

                    // if we have at least one entry, select the first one.
                    if (this.entriesToDisplay.length != 0) {
                        this.entriesToDisplay[0]["_highlight"] = true
                        this.handleEntriesSelectionChanged(this.entriesToDisplay[0], null)
                    }
                }

            }).finally(() => {
                // hide loading spinner
                this.entriesListIsLoading = false
            })
        },
        
        fixupDatesForIview() {
            // iview doco states when using DatePicker with v-model the value has to be a Date()
            // this seems to fix an odd issue where by saving an edited record a second time no longer works (as the type may be somehow changed)
            // PLI @30 Aug 2021 - tweaked again, check for undefined/null and '' to not parse as it result in Invalid Date NaN to show in UI
            //                    This seems to only occur when used in conjuction with the date validator.
            if (this.editEntryDetails != undefined && this.editEntryDetails.cStartDate != null && this.editEntryDetails.cStartDate != '')
                this.editEntryDetails.cStartDate = new Date(this.editEntryDetails.cStartDate)
            if (this.editEntryDetails != undefined && this.editEntryDetails.cEndDate != null && this.editEntryDetails.cEndDate != '')
                this.editEntryDetails.cEndDate = new Date(this.editEntryDetails.cEndDate)
        },

        handleEntriesSelectionChanged(currentRow, oldCurrentRow)  {
            oldCurrentRow
            
            this.editEntryDetails = {
                sponsor: { sName : '', sContact : '', sEmail : '', sAddress : '', 
                            postcode : '', countryCode : '', sContractId : '', sId : 0 },
                status : 'R',
                cId : undefined,
                cName : ''
            }
            
            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                return
            }

            // clone data so when cancelling an edit session, we can restore the original details in UI
            this.editEntryDetails = _.cloneDeep(currentRow)

            this.fixupDatesForIview()

        },
                         
     
         handleAddEntry() {
            AnalyticsMgr.sendEvent('/Superuser/CampaignManagement', 'click', 'Campaign_Add')

            this.liveValidating = false

            this.selectedEntryDetailsBeforeCreatingNewEntry = _.cloneDeep(this.editEntryDetails)
            
            // this.$refs["sponsorDropdown"].clearSingleSelect()

            this.editEntryDetails = {
                                     sponsor: { sName : '', sContact : '', sEmail : '', sAddress : '', 
                                                postcode : '', countryCode : '', sContractId : '', sId : 0 },
                                     cName : '', cDesc : '', cStartDate : null, cEndDate : null, cBenefitDuration : 1, aCodeCountRequested : 1, aCodePrefix : '',
                                     status : 'R', sPriceId :'', cId : undefined
                                     //practiseId : this.$store.state.loggedInDoctorDetails.practiseId
                                  }

            // WTF? why does the above not init referringPhysicianId properly!? below logs show its undefined if the following is not run.
            this.editEntryDetails.sponsor.sId = 0

// console.log("NEW PATIENT", this.patientDetails)
// console.log("NEW PATIENT", this.patientDetails.referringPhysicianJSON.referringPhysicianId)

            // if (this.patientDetails.userId == undefined) {
            //     // when we create a new patient, the treating physician is not currently setup.
            //     // so for convenience, lets check the current logged in user, to see if they have a physician role
            //     // and if they do, set them to be the treating physician.
            //     if (this.$store.state.loggedInDoctorDetails.userRoleCodes.includes("P") == true) {
            //         this.patientDetails.userId = this.$store.state.loggedInDoctorDetails.userId
            //     }

            // }

            // this.referringPhysicianDefaultLabel = '-- Create New Referring Physician --  '
      //      this.loadingFilteringReferringPhysicians("")


            // clear and reset required field messages.
            this.$refs['campaignDetailsFormFields'].resetFields()
            this.$refs['sponsorFormFields'].resetFields()

            this.isCreatingNewEntry = true;
            this.showCampaignDetails = true;
        },

        handleEditEntry() {
            AnalyticsMgr.sendEvent('/Superuser/CampaignManagement', 'click', 'Campaign_Edit')

            this.liveValidating = true
            this.checkFormMandatoryFields()
            
            // this.loadingFilteringReferringPhysicians("")

            this.fixupDatesForIview()

            this.isCreatingNewEntry = false
            this.showCampaignDetails = true

        },
        

        handleRemoveEntry() {
            AnalyticsMgr.sendEvent('/Superuser/CampaignManagement', 'click', 'Campaign_Remove')

            if (this.editEntryDetails.cId != undefined) {
                itBnTx.deleteCampaignWithCampaignID({inCampaignID : this.editEntryDetails.cId})
                      .then(response => {
                          response
                        
                          this.refreshDataForUI()

                          this.$Message.success('Successfully removed/made inactive.');
                      })
            }
            
        },

        handleMakeLiveEntry() {
            AnalyticsMgr.sendEvent('/Superuser/CampaignManagement', 'click', 'Campaign_MakeLive')

            if (this.editEntryDetails.cId != undefined) {
                this.editEntryDetails.status = 'L'

                try {
                    delete this.editEntryDetails._highlight // delete UI key, otherwise API barfs
                } catch(error) {
                    console.log(error)  
                }
                var savingPromise = itBnTx.updateCampaign({inCampaignDetails : this.editEntryDetails })

                savingPromise
                    .then(response => {
                            response
                            
                            // reload data into UI
                            this.refreshDataForUI()
                            this.$Message.success('Campaign now LIVE.');
                    })
                    .catch(error => {
                        console.log(error)
                        this.editEntryDetails.status = 'R'
                    })
            }
            
        },


        checkFormMandatoryFields() {
            if (this.liveValidating) {
                this.$refs['campaignDetailsFormFields'].validate((valid) => {
                    if (valid) {
                        this.liveValidatingFieldsOK = true
                    } else {
                        this.liveValidatingFieldsOK = false
                    }
                })
            }
        },

        handleNewSponsor() {
            // this.$refs['sponsorDropdown'].clearSingleSelect()
            this.$refs['sponsorDropdown'].setQuery("-- Create New Sponsor --")
            
//            this.patientDetails.referringPhysicianJSON.referringPhysicianId = 0     // select the "Create New Referring PHysician" entry
            this.$refs['sponsorDropdown'].isFocused = false
        },

        handleSearchSponsors() {
            this.$refs['sponsorDropdown'].isFocused = true
            this.$refs['sponsorDropdown'].clearSingleSelect()
        },



        handleCancelCampaignDetails() {
            AnalyticsMgr.sendEvent('/Superuser/CampaignManagement', 'click', 'Campaign_Edit_Cancel')

            if (this.entriesTableData.length != 0) {
                // only try to restore UI if there is some entry which was selected.

                if (this.isCreatingNewEntry == false) {
                    var previousSelection = this.entriesTableData.find(entry => { return entry.cId == this.editEntryDetails.cId} )

                    // restore any changes back to what they were since the user cancelled.
                    this.editEntryDetails = _.cloneDeep(previousSelection)

                    this.fixupDatesForIview()

                } else {
                    this.editEntryDetails = this.selectedEntryDetailsBeforeCreatingNewEntry
                }
            }

            this.showCampaignDetails = false
        },

        handleSaveCampaignDetails() {
            AnalyticsMgr.sendEvent('/Superuser/CampaignManagement', 'click', 'Campaign_Edit_Save')

            this.$refs['sponsorFormFields'].validate((valid) => {
                if (valid) {
                    this.liveValidatingFieldsOK = true

                    var savingDetails = this.editEntryDetails

                    // if we are a new record, make sure to clear the cId so the API knows to create a new record
                    if (savingDetails.cId == undefined) {
                        delete savingDetails.cId
                    }
                                        
                    delete savingDetails._highlight // delete UI key, otherwise API barfs

 //                   console.log("SAVE DETAILS> ", JSON.stringify(this.savingDetails));

                    this.$refs['campaignDetailsFormFields'].validate((valid2) => {
                        if (valid2) {

                            this.savingDetailsToBackend = true
                            
                            var savingPromise

                            if (this.isCreatingNewEntry == true)
                                savingPromise = itBnTx.createCampaign({inCampaignDetails : savingDetails })
                            else
                                savingPromise = itBnTx.updateCampaign({inCampaignDetails : savingDetails })

                            savingPromise
                                .then(response => {
                                        response
                                        
                                        // reload data into UI
                                        this.refreshDataForUI()
                                        this.showCampaignDetails = false
                                })
                                .finally(() => {
                                    this.savingDetailsToBackend = false
                                })

                        } else {
                            this.liveValidating = true
                            this.liveValidatingFieldsOK = false

                            this.$Message.error('Enter mandatory fields before trying again.');
                        }
                    })

                } else {
                    this.liveValidating = true
                    this.liveValidatingFieldsOK = false

                    this.$Message.error('Enter mandatory fields before trying again.');
                }
            })
        },


        handleSponsorChanged( sponsorID ) {
            if (sponsorID != undefined) {
                var newSponsor = this.sponsorsData.find(entry => entry.sId == sponsorID)

                this.editEntryDetails.sponsor.sId                    = sponsorID
                this.editEntryDetails.sponsor.sName                  = (newSponsor != undefined && sponsorID != 0) ? newSponsor.sName : ''
                this.editEntryDetails.sponsor.sEmail                 = (newSponsor != undefined && sponsorID != 0) ? newSponsor.sEmail : ''
                this.editEntryDetails.sponsor.sAddress               = (newSponsor != undefined && sponsorID != 0) ? newSponsor.sAddress : ''
                this.editEntryDetails.sponsor.sContact               = (newSponsor != undefined && sponsorID != 0) ? newSponsor.sContact : ''
                this.editEntryDetails.sponsor.sContractId            = (newSponsor != undefined && sponsorID != 0) ? newSponsor.sContractId : ''
                this.editEntryDetails.sponsor.postcode               = (newSponsor != undefined && sponsorID != 0) ? newSponsor.postcode : ''
                this.editEntryDetails.sponsor.countryCode            = (newSponsor != undefined && sponsorID != 0) ? newSponsor.countryCode : ''
                this.editEntryDetails.sponsor.status                 = (newSponsor != undefined && sponsorID != 0) ? newSponsor.status : ''
            }
        },


        handleExportCampaignActivationCodes() {
            console.log('export-activation-codes')

            itBnTx.getActivationCodesForCampaignID({ inCampaignID : this.editEntryDetails.cId, 
                                                         inExport : true                                                    
                                                })
                    .then(response => {
                        console.log(response)
                        var hiddenElement = document.createElement('a');
                        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response.data);
                        hiddenElement.target = '_blank';
                        hiddenElement.download = 'activation_codes.csv';
                        hiddenElement.click();
                    })
        },

        handlShowCampaignActivationCodes() {

            itBnTx.getActivationCodesForCampaignID({ inCampaignID : this.editEntryDetails.cId, 
                                                         inExport : false
                                                })
                    .then(response => {
                        this.entriesActivationCodesTableData = response.data
                    })
                    
            this.showCampaignActivtionCodes = true
        },

        handleDoneCampaignActivationCodes() {


            this.showCampaignActivtionCodes = false
        },


    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...

        // revalidate the form as the user enters details, so give live feedback that what they entered is ok
        "editEntryDetails.cName" :                function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['campaignDetailsFormFields'].validate() },
        "editEntryDetails.sPriceId" :             function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['campaignDetailsFormFields'].validate() },
        "editEntryDetails.cStartDate" :           function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['campaignDetailsFormFields'].validate() },
        "editEntryDetails.cEndDate" :             function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['campaignDetailsFormFields'].validate() },

        "editEntryDetails.sponsor.sName" :        function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['sponsorFormFields'].validate() },
        "editEntryDetails.sponsor.sContact" :     function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['sponsorFormFields'].validate() },
        "editEntryDetails.sponsor.sEmail" :       function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['sponsorFormFields'].validate() },
        "editEntryDetails.sponsor.sAddress" :     function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['sponsorFormFields'].validate() },
        "editEntryDetails.sponsor.postcode" :     function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['sponsorFormFields'].validate() },
        "editEntryDetails.sponsor.countryCode" :  function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['sponsorFormFields'].validate() },
        "editEntryDetails.sponsor.sContractId" :  function() { if (this.liveValidating && this.showCampaignDetails == true) this.$refs['sponsorFormFields'].validate() },

    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  
.drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #334155;

 }

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}


.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}

.formFieldTip {
    font-size: 11px;
    color: rgb(110, 110, 110);
}

</style>

