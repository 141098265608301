<template>
    <div id="pm-patients" class="layout" v-observe-visibility="visibilityChanged">

<!--
chartNotes: ""
conditionFirstReported: "2020-01-01"
conditionFirstTreated: "2020-02-01"
consentToUseDataForHcpEducation: false
consentToUseDataForResearch: true
created: 1480050456000
createdBy: 1
dateOfBirth: "2016-11-25"
dateOfResearchConsent: "2021-07-14"
firstName: "1111 First name, initials"
genderCode: "F"
isProtocolPatient: false
lastName: "Lastname"
masterPatientId: "Master Patient ID"
modified: 1626260313097
modifiedBy: 63
occupation: "Occupation"
otherCondition: "other condition"
patientConditionIds: [5]
patientId: 28752
practiseId: 1
referringPhysician: "Associate Prof. bb      bbbbb"
referringPhysicianJSON: {referringPhysicianId: 38, practiseId: 1, title: "Associate Prof.", firstName: "bb",…}
reportNotes: ""
title: "Mr."
treatingPhysician: "Arlen Hendricks"
userId: 344

referrer
    created: 1488594345267
    createdBy: 349
    firstName: ""
    lastName: "asdghj"
    modified: 1622517073146
    modifiedBy: 66
    physicianAddress: ""
    physicianEmail: ""
    physicianFax: ""
    physicianPhone: ""
    practiseId: 1
    referringPhysicianId: 3148
    title: "Associate Prof."

REF DATR
- titles (dr, mr...)
- gender (M/F)

- treating physicians
- referring physician
- conditions

      if (patientConditionFirstReported !== null) {
            Ext.getCmp('patientFormConditionDuration').
            setValue(Math.round((Date.now() -
                                 new Date(patientConditionFirstReported)) / (1000 * 60 * 68 * 24)) + ' days');
        } else {
            Ext.getCmp('patientFormConditionDuration').setValue(' ');
        }

    -->
        <!------------------------------>
        <!-- PATIENT DETAILS DRAWER   -->
        <!------------------------------>

         <Drawer
            v-model="showPatientDetails"
            :width="dynamicPatientDetailsDrawerWidth"
            :mask-closable="false"
            :styles="patientDetailsDrawerStyles">

            <p slot="header"><center>{{ isCreatingNewPatient ? "Create New" : "Edit" }} Patient Details</center></p>

            <Row :gutter="20">
                <!------------------------------>
                <!-- PATIENT SPECIFIC DETAILS -->
                <!------------------------------>
                <Col v-bind="responsiveGrid2Sections" style="margin-bottom: 8px">
                    <Card :padding="8" style="width: 100%;">
                        <p slot="title">Patient</p>

                        <Form class="bntx-patient-details-form-patient" ref="patientDetailsFormFields" :model="patientDetails" :rules="patientFieldRulesDynamic" :label-width="150" label-position="right">

                            <FormItem label="Title:">
                                <Select v-model="patientDetails.title" style="maxWidth:200px" filterable @on-select="handleOnSelectTitle">
                                    <Option v-for="item in patientTitlesRefData" :value="item.title" :key="item.title">{{ item.title }}</Option>
                                </Select>
                            </FormItem>
                            
                            <FormItem label="Firstname, Initials:" prop="firstName" :class="mandatoryFieldsStyle">
                                <Input v-model="patientDetails.firstName" ref="firstName" @on-blur="handleOnBlurFirstname"></Input>
                            </FormItem>
                            
                            <FormItem label="Lastname:" prop="lastName" :class="mandatoryFieldsStyle">
                                <Input v-model="patientDetails.lastName" ref="lastName"></Input>
                            </FormItem>
                            
                            <FormItem label="Date of Birth:" prop="dateOfBirth" :class="mandatoryFieldsStyle">
                                <DatePicker v-model="patientDetails.dateOfBirth" ref="dateOfBirth" type="date" :options="allowOnlyPastDatesOptions" :format="defaultDateInputFormat" :placeholder="defaultDateInputFormat.toUpperCase()" style="maxWidth: 200px"></DatePicker>
                            </FormItem>
                            
                            <FormItem label="Gender:">
                                <Select v-model="patientDetails.genderCode" style="maxWidth:200px" filterable>
                                    <Option v-for="item in genderRefData" :value="item.genderCode" :key="item.genderCode">{{ item.genderName }}</Option>
                                </Select>
                            </FormItem>
                            
                            <FormItem label="Occupation:">
                                <Input v-model="patientDetails.occupation" ></Input>
                            </FormItem>
                            
                            <!----------------------------->
                            <!-- Sub Conditions Dialog   -->
                            <!----------------------------->
                            <Modal v-model="showPatientSubConditions" fullscreen title="Condition Details" cancel-text="">
                                <PatientConditionEditor :patientDetails="patientDetails" />
                            </Modal>

                            <FormItem label="Condition(s):" title="Condition(s) for which patient is (or will be) treated" prop="patientConditionIds" :class="mandatoryFieldsStyle">
                                <div ref="patientConditionIds">
                                    <CheckboxGroup v-model="patientDetails.patientConditionIds">
                                        <Checkbox v-for="item in conditionsRefData" :key="item.conditionId" :label="item.conditionId">{{ item.conditionName }}</Checkbox>
                                    </CheckboxGroup>
                                    <Input v-show="patientDetails.patientConditionIds != undefined && patientDetails.patientConditionIds.includes(5 /* other */)" v-model="patientDetails.otherCondition" placeholder="specify other condition"></Input>
                                    <Button @click="handleEditSubConditions()" size="small" type="primary" style="margin-right: 8px; margin-top:8px">Condition Details...</Button>
                                </div>
                            </FormItem>
                            
                            <FormItem label="Condition first reported:">
                                <DatePicker v-model="patientDetails.conditionFirstReported" type="date" :options="allowOnlyPastDatesOptions" :format="defaultDateInputFormat" :placeholder="defaultDateInputFormat.toUpperCase()" style="maxWidth: 200px"></DatePicker>
                            </FormItem>
                            
                            <FormItem label="Condition first treated:">
                                <DatePicker v-model="patientDetails.conditionFirstTreated" type="date" :options="allowOnlyPastDatesOptions" :format="defaultDateInputFormat" :placeholder="defaultDateInputFormat.toUpperCase()" style="maxWidth: 200px"></DatePicker>
                            </FormItem>
                            
                            <FormItem label="Condition Duration:">
                                <Input :value="patientDetails.conditionFirstReported | formatDaysAgo" :border="false" readonly></Input>
                            </FormItem>
                            <FormItem label="Treating Physician:" prop="userId" :class="mandatoryFieldsStyle">
                                <Select v-model="patientDetails.userId" ref="userId" style="width:100%" filterable>
                                    <Option v-for="item in treatingPhysiciansDataSpecificForSelectedPatient" :value="item.userId" :key="item.userId">{{ item.userName + ((item.statusCode != 'A') ? (" (FORMER PHYSICIAN)") : "") }}</Option>
                                </Select>
                            </FormItem>


                            <FormItem label="Master Patient ID:" prop="masterPatientId" :class="mandatoryFieldsStyle">
                                <Input v-model="patientDetails.masterPatientId" ref="masterPatientId"></Input>
                            </FormItem>

                            <!-- <center>
                                <vue-qrcode :value="patientGUIDForQRCode" />
                            </center> -->
                        </Form>
                        
                    </Card>

                    <!-------------------------------->
                    <!-- Patient Contact Details    -->
                    <!-------------------------------->
                    <Card :padding="8" style="margin-top: 8px; width: 100%;">
                        <p slot="title">Patient Contact Details</p>
                        <Form ref="patientContactDetailsFormFields" :model="patientContactDetails" :rules="patientContactDetailsFieldRulesDynamic" :label-width="130" label-position="right">
                            <FormItem label="Mobile Number:" prop="mobile" :error="practiceSupportsCoPayments && patientContactDetails.contact.mobile.length == 0 ? 'Mobile recommended' : ''">
                                <Input v-model="patientContactDetails.contact.mobile" ref="mobile"></Input>
                            </FormItem>
                            <!-- :error="practiceSupportsCoPayments && patientContactDetails.contact.email.length == 0 ? 'Email required' : ''" -->
                            <FormItem label="Email:" :error="practiceSupportsCoPayments && patientContactDetails.contact.email.length == 0 ? 'Email recommended' : ''">
                                <Input v-model="patientContactDetails.contact.email" ></Input>
                            </FormItem>
                            <FormItem label="Address:" prop="address">
                                <Input v-model="patientContactDetails.address.inputAddress" ref="address" element-id="patientAddressFinder"></Input>
                            </FormItem>
                            <FormItem label="Postcode/Zip:">
                                <Input v-model="patientContactDetails.contact.postcode" ></Input>
                            </FormItem>
                        </Form>

                        <!----------------------------->
                        <!-- Mobile Setup Dialog     -->
                        <!----------------------------->
                        <Modal v-model="showPatientMobileSetup" fullscreen title="Patient App Setup" cancel-text="">
                            <Row flex justify="center" align="middle" style="margin-bottom: 44px">
                                <Col span="8" style="text-align: right">
                                    <h1>Step 1</h1>
                                </Col>
                                <Col span="16">
                                    <center>
                                       <vue-qrcode value="http://getmyinteract.com" :width="320" title="Scan this QR code on your phone to quickly locate Patient App to download." /><br />
                                        <span style="font-size: 14pt">Scan QRCode - Download the Mobile App</span><br />
                                        <span>Or open <strong>http://getbntxapp.com</strong> on the browser on the mobile.</span>
                                    </center>
                                </Col>
                            </Row>
                             <Row flex justify="center" align="middle" style="margin-bottom: 44px">
                                <Col span="8" style="text-align: right">
                                    <h1>Step 2</h1>
                                </Col>
                                <Col span="16">
                                    <center>
                                        <Button type="primary" >Send Setup SMS to Patient Mobile</Button>
                                    </center>
                                </Col>
                            </Row>
                            <Row flex justify="center" align="middle" style="margin-bottom: 44px">
                                <Col span="8" style="text-align: right">
                                    <h1>Step 3</h1>
                                </Col>
                                <Col span="16">
                                    <center>
                                        <span style="font-size: 14pt">On Mobile - Enter DOB and Mobile Confirmation Code to finalise connection.</span>
                                    </center>
                                </Col>
                            </Row>
                        </Modal>
                        
                        <Button v-show="practiceSupportsCoPayments && false" type="primary" style="margin-left: 130px" @click="handleSetupPatientApp">Setup Patient Mobile App…</Button>
                        <!-- <Button type="success" @click="showCopaymentConsent = true">Consent and Payment Tier…</Button>
                        <Button type="warning" @click="handleViewPrintConsentForm" style="margin-left: 8px" >Manage Payment Method…</Button> -->
                    </Card>


                    <!------------------------------------>
                    <!-- Patient Miscellaneous Details  -->
                    <!------------------------------------>
                    <Card :padding="8" style="margin-top: 8px; width: 100%;">
                        <p slot="title">Patient Miscellaneous Details</p>

                        <Form :model="patientDetails" :label-width="130" label-position="right">
                            
                            <FormItem label="Report CC Emails:">
                                <Input v-model="patientDetails.emailCc" placeholder="person1@email.com;person2@email.com"></Input>
                            </FormItem>
                        </Form>

                    </Card>


                </Col>

                <!--------------------------------->
                <!-- REFERRING PHYSICIAN DETAILS -->
                <!--------------------------------->

                <Col v-bind="responsiveGrid2Sections">
                    <Card :padding="8" style="width: 100%;">
                        <p slot="title">Referring Physician</p>

                        <Form class="bntx-patient-details-form-referrer" ref="referringPhysicianFormFields" :model="patientDetails" :rules="referringPhysicianFieldRulesDynamic" :label-width="130" label-position="right">
                            <FormItem label="Find or Create Physician:">
                                <Row>
                                    <Col span="18">
                                        <Select ref="referringPhysicianDropdown"
                                            v-model="patientDetails.referringPhysicianJSON.referringPhysicianId"
                                            style="width:100%"
                                            @on-change="handleReferringDoctorChanged"
                                            :remote-method="loadingFilteringReferringPhysicians"
                                            :loading="referringPhysiciansLoadingFiltering"
                                            placeholder="Type to search, then select."
                                            clearable filterable>

                                            <!-- @on-set-default-options="setDefaultReferringPhysicianOptions" -->
                                            <!-- :default-label="referringPhysicianDefaultLabel" -->


                                            <Option v-for="item in referringPhysiciansFiltered" :value="item.referringPhysicianId" :key="item.referringPhysicianId">{{ item.title }} {{ item.firstName }} {{ item.lastName }}</Option>
                                        </Select>
                                    </Col>
                                    
                                    <Col span="3">
                                        <Button type="primary" style="margin-top: 0px; margin-left: 8px" icon="md-person-add" @click="handleNewReferringPhysician" />
                                    </Col>
                                    <Col span="3">
                                        <Button type="primary" style="margin-top: 0px; margin-left: 4px" icon="md-search" @click="handleSearchReferrer" />
                                    </Col>
                                </Row>

                                <!-- {{patientDetails.referringPhysicianJSON.referringPhysicianId}}
                                {{referringPhysicianDefaultLabel}}
                                <Button @click="patientDetails.referringPhysicianJSON.referringPhysicianId = 0">Create</Button> -->

                            </FormItem>
                            <FormItem label="Title:">
                                <Select v-model="patientDetails.referringPhysicianJSON.title" style="maxWidth:200px" filterable>
                                    <Option v-for="item in physicianTitlesRefData" :value="item.title" :key="item.title">{{ item.title }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="Firstname:" prop="referringPhysicianJSON.firstName" :class="mandatoryFieldsStyle">
                                <Input v-model="patientDetails.referringPhysicianJSON.firstName" ref="referringPhysicianJSON.firstName"></Input>
                            </FormItem>
                            <FormItem label="Lastname:" prop="referringPhysicianJSON.lastName" :class="mandatoryFieldsStyle">
                                <Input v-model="patientDetails.referringPhysicianJSON.lastName" ref="referringPhysicianJSON.lastName"></Input>
                            </FormItem>
                            <FormItem label="Address:" prop="referringPhysicianJSON.physicianAddress" :class="mandatoryFieldsStyle">
                                <Input v-model="patientDetails.referringPhysicianJSON.physicianAddress" ref="referringPhysicianJSON.physicianAddress" element-id="referrerAddressFinder" ></Input>
                            </FormItem>
                            <FormItem label="Phone Number:" prop="referringPhysicianJSON.physicianPhone" :class="mandatoryFieldsStyle">
                                <Input v-model="patientDetails.referringPhysicianJSON.physicianPhone" ref="referringPhysicianJSON.physicianPhone"></Input>
                            </FormItem>
                            <FormItem label="Fax:">
                                <Input v-model="patientDetails.referringPhysicianJSON.physicianFax" ></Input>
                            </FormItem>
                            <FormItem label="Email:">
                                <Input v-model="patientDetails.referringPhysicianJSON.physicianEmail" ></Input>
                            </FormItem>
                            
                            
                        </Form>
                    </Card>

                    <!-------------------------------->
                    <!-- Patient Co-payment Setup   -->
                    <!-------------------------------->
                    <Card v-show="practiceSupportsCoPayments" :padding="8" style="margin-top: 8px; width: 100%;">
                        <p slot="title">Patient Co-payment Setup</p>

                        <Modal v-model="showCopaymentConsent" width="80" @on-ok="setupNSavePatientCopaymentDetails" @on-cancel="setupNSavePatientCopaymentDetails" cancel-text="" draggable scrollable :mask="false" title="Co-payment Consent and Payment Tier">
                            <Card :padding="8" style="width: 100%;">
                                <p slot="title">Co-payment Authority Consents</p>

                                <CheckboxGroup v-model="copaymentConsentedIDs">
                                    <span v-for="consent in copaymentAllConsents" :key="consent.id">
                                        <Checkbox :label="consent.id" :disabled="consentNPaymentConfiguredByPatient"><div style="margin-left: 25px; margin-top: -20px;" v-html="consent.description.replaceAll('\n', '<br />')"></div></Checkbox><br /><br />
                                    </span>
                                </CheckboxGroup>

                                <!-- <Button type="info" @click="handleViewPrintCopaymentAuthorityForm" style="margin-top: 8px;" >Print Co-payment Authority Form…</Button> -->
                            </Card>

                            <Card :padding="8" style="margin-top: 8px; width: 100%;">
                                <p slot="title">Botulinum toxin co-payment</p>
                                
                                <Checkbox v-model="hasMedicare" :disabled="true">Medicare</Checkbox>
                                <!-- <br /><br /> -->

                                <div style="margin: 8px 0 8px 0; height: 1px; width:100%; background-color: grey;" />

                                <span class="" style="font-weight: 500; font-size: 1rem;">Concession Card</span>
                                <div style=" margin-top:6px;"></div>

                                <RadioGroup v-model="patientCopaymentDetails.payerTier.id" vertical>
                                    <Radio v-for="tier in copaymentTierTypes" :label="tier.id" :key="tier.id"><span>{{tier.tierName}}</span></Radio>
                                </RadioGroup>

                                <!-- <div style=" margin-top:6px;"></div> -->
                                <div style="margin: 8px 0 8px 0; height: 1px; width:100%; background-color: grey;" />

                                <Checkbox v-model="patientCopaymentDetails.safetyNet" >Safety Net Concession Card or Safety Net Entitlement Card</Checkbox><br /><br />
                            </Card>
                        </Modal>
                        <!-- TAILWINDCSS: class="space-y-2 grid justify-items-center" -->
                        <div class=""
                             style="display: grid; justify-items: center; ">

                            <Divider orientation="left">Patient self-serve</Divider>

                            <Button :type="practiceSupportsCoPayments && patientDetails.isCopaymentReady != true ? 'warning' : 'success'"
                                    @click="handleSMSPatientPaymentSetupLink"
                                    style="width: 240px; margin-bottom: 8px;" >
                                    SMS Setup Link to Patient
                            </Button>

                            <Divider orientation="left">Clinic on behalf of patient</Divider>

                            <Button :type="copaymentConsentedIDs.length == copaymentAllConsents.length && patientCopaymentDetails.payerTier.id != null ? 'success' : 'error'"
                                    @click="showCopaymentConsent = true"
                                    style="width: 240px; margin-bottom: 8px;" >
                                    Consent and Co-payment Tier…
                            </Button>
                            
                            <Button :type="practiceSupportsCoPayments && patientDetails.isCopaymentReady != true ? 'warning' : 'success'"
                                    @click="handleManagePaymentMethods"
                                    :disabled="consentNPaymentConfiguredByPatient"
                                    style="width: 240px; margin-bottom: 8px;" >
                                    Co-payment Setup…
                            </Button>
                            
                            <Button @click="handleViewPrintCopaymentAuthorityForm"
                                    :disabled="consentNPaymentConfiguredByPatient"
                                    style="width: 240px;" >
                                    View Co-payment Form
                            </Button>
                            


                        </div>
                    </Card>

                    <!------------->
                    <!-- CONSENT -->
                    <!------------->

                    <Card v-show="false" style="margin-top: 8px; width: 100%;">
                        <p slot="title">Patient Data for Research Consent</p>

                        <ol style="padding:8px; line-height: 1.3; font-style: italic">
                            <li style="padding-bottom:8px;">The patient consents to the use of the patient’s de-identified data by the patient’s treating physician for the purposes of the treating physician conducting research and for the purposes of the treating physician better understanding and improving the treatment of the patient by the treating physician; and</li>
                            <li>The patient consents to the use of the patient’s de-identified data by Neurology Interact by itself or its servants or agents including for any commercial purposes and for reporting, research, improvement of treatment and for any other associated purpose for which Neurology Interact wishes, in its absolute discretion, to use the de-identified data.</li>
                        </ol>   
                        <br />
                        <Checkbox v-model="patientDetails.consentToUseDataForResearch" style="line-height: 1.3;">By ticking this box You acknowledge that You have explained the above to the patient and obtained the patients agreement or otherwise to the above.</Checkbox>
                        <br />
                        <br />
                        Date of consent:
                        <DatePicker v-model="patientDetails.dateOfResearchConsent" type="date" :format="defaultDateInputFormat" :placeholder="defaultDateInputFormat.toUpperCase()" style="width: 200px"></DatePicker>
                        <br />
                        <br />
                        <Button type="primary" @click="handleViewPrintConsentForm">View/Print Consent Form</Button>
                        <br />
                        <br />
                        Before proceeding further with treatment print and have the patient sign this Consent.
                    </Card>
                </Col>
            </Row>

            <div class="drawer-footer">
                <Button style="margin-right: 8px" @click="handleCancelPatientDetails">Cancel</Button>
                <Button style="margin-right: 8px" @click="handleSavePatientDetails">Save</Button>
                <!-- <Button type="success" style="margin-right: 8px" @click="handleSavePatientDetailsAndCreateNewSession">Save and Create Order</Button> -->
                <Button type="success" @click="handleSavePatientDetailsAndCreateNewSession">Save and Create Session</Button>
            </div>
        </Drawer>




        <Row> 
            <!--------------------------------->
            <!-- PATIENT TABLE               -->
            <!--------------------------------->

            <Card :padding="8" style="width: 100%;">
                <p slot="title">Patient List <span style="font-weight:200">({{ patientTableData.totalCount }} patients)</span></p>
                
                <List :split="false" size="small" >
                    <ListItem>
                        <div id="patient_main_patient_list_search">
                            <Input ref="filterByFirstname" v-model="filterByFirstname" placeholder="First name" :clearable="true" @on-change="throttledSearch" style="width: 300px; margin-right: 8px" />
                            <Input ref="filterByLastname" v-model="filterByLastname" placeholder="Last name" :clearable="true"  @on-change="throttledSearch" style="width: 300px; margin-right: 8px" />
                        </div>
                        <Button :loading="refreshPatientListFromBackend" @click="refreshDataForUI(true)">
                            <span v-if="!refreshPatientListFromBackend">Reload Patient List</span>
                            <span v-else>Refreshing...</span>
                        </Button>
                    </ListItem>
                    <ListItem id="patient_main_patient_list">
                        <!-- <Spin v-show="patientListIsLoading" fix>
                            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin> -->
                        <Table style="width: 100%;" height="300" size="default" draggable :columns="patientTableColumnsConfig" :data="patientTableDataFiltered" border highlight-row @on-current-change="handleAllPatientsSelectionChanged" @on-row-dblclick="handleEditPatient">
                            <template slot-scope="{ row }" slot="firstname"><span :class="practiceSupportsCoPayments && row.isCopaymentReady != true ? 'preAuthFailed' : ''">{{ row.firstName }}</span> </template>
                            <template slot-scope="{ row }" slot="lastname"><span :class="practiceSupportsCoPayments && row.isCopaymentReady != true ? 'preAuthFailed' : ''">{{ row.lastName }}</span> </template>
                            <template slot-scope="{ row }" slot="date"> {{ row.dateOfBirth | userFriendlyDateFromDateString }} </template>
                            <template slot-scope="{ row }" slot="condition"> {{ row.patientConditionIds | conditionsAsString }} </template>
                        </Table>
                    </ListItem>
                    <ListItem>
                        <Row style="width: 100%">
                            <Col span="12" class="ivu-text-left">
                                <div id="patient_main_add_edit_patient">
                                    <Button type="primary" @click="handleAddPatient()" style="margin-right: 8px">Add Patient</Button>
                                    <Button type="primary" :disabled="currentPatientID == 0" @click="handleEditPatient()" style="margin-right: 8px">Edit Patient</Button>
                                    <Button type="success" v-show="practiceSupportsCoPayments" @click="handleNewOrderForPatient()">New Order for Patient</Button>
                                </div>
                            </Col>
                            <Col span="12" class="ivu-text-right">
                                <Page :total="patientTableData.totalCount" :page-size="patientListPageSize" :page-size-opts="[20,100,200,500,1000]" show-sizer @on-change="handleLoadPatientListToNthPage" @on-page-size-change="handlePageLimitChanged" />
                            </Col>
                        </Row>
                        
                    </ListItem>
                </List>
            </Card>
        </Row>
        <Row :style="{'margin-top': '8px'}" :gutter="8">

            <!--------------------------------->
            <!-- SESSIONS TABLE              -->
            <!--------------------------------->

            <Col span="10">
                <Card :padding="8">
                    <p slot="title">Patient Sessions <span style="font-weight:200">({{ patientSessionsTableData.length }} sessions)</span></p>

                    <List :split="false" size="small">
                        <ListItem id="patient_main_patient_sessions">
                            <Table style="width: 100%;" height="300" size="default" draggable :columns="patientSessionsTableColumnsConfig" :data="patientSessionsTableData" border highlight-row @on-current-change="handlePatientSessionsSelectionChanged"  @on-row-dblclick="handleEditSession">
                                <template slot-scope="{ row }" slot="date"> {{ row.sessionDate | userFriendlyDate }} <Badge :count="row.preInjectionWarningCount" v-show="row.preInjectionWarningCount != 0" class-name="bntx-badge" style="width:20px"></Badge></template>
                            </Table>
                        </ListItem>
                        <ListItem id="patient_main_add_edit_delete_session">
                            <Row style="margin-top:-8px">
                                <span style="margin-right: 8px; margin-top:14px">New Session Date: </span>
                                <DatePicker v-model="newSessionDate" type="date" :clearable="false" :options="allowOnlyPastDatesOptions"  placeholder="Select date" style="width: 110px; margin-right:8px; margin-top:8px"></DatePicker>
                                <Button @click="handleNewSession(true)" type="primary" :disabled="currentPatientID == 0" style="margin-right: 8px; margin-top:8px">New Session</Button>
                                <Button @click="handleEditSession" :disabled="currentSessionID == 0 || patientSessionsTableData.length == 0 || selectedPatientSession.sessionId == undefined" type="primary" style="margin-right: 8px; margin-top:8px">Edit Session</Button>
                                
                                <Poptip confirm title="Do you really want to remove this session?" ok-text="Yes" cancel-text="No" @on-ok="handleDeleteSession">
                                    <!-- <Button :disabled="currentSessionID == 0 || patientSessionsTableData.length == 0 || (selectedPatientSession.totalUnits != undefined && selectedPatientSession.totalUnits != '')" type="primary" style="margin-right: 8px; margin-top:8px">Delete Session</Button> -->
                                    <Button :disabled="currentSessionID == 0 || patientSessionsTableData.length == 0" type="primary" style="margin-right: 8px; margin-top:8px">Delete Session</Button>
                                </Poptip>
                            </Row>
                        </ListItem>
                    </List>
                </Card>
            </Col>

            <!--------------------------------->
            <!-- ASSESSMENTS TABLE           -->
            <!--------------------------------->

            <Col span="4">
                <Card :padding="8" id="patient_main_assessments_summary">
                    <p slot="title">Assessments</p>
                    <List size="small">
                        <ListItem v-for="item in scoresAndRatingsPerformedSummary" :key="item.id">
                            
                            <Row style="width: 100%">
                                <Col span="12" class="ivu-text-left">
                                    {{ item.id }}
                                </Col>
                                <Col span="12" class="ivu-text-right">
                                    {{ item.score }}
                                </Col>
                            </Row>
                        </ListItem>
                        <ListItem v-show="scoresAndRatingsPerformedSummary.length == 0">None performed</ListItem>
                    </List>
                </Card>               
            </Col>

            <!--------------------------------->
            <!-- INJECTIONS SUMMARY TABLE    -->
            <!--------------------------------->

            <Col span="10">
                <Card :padding="8" id="patient_main_injection_summary">
                    <p slot="title">Injection Summary <span style="font-weight:200">({{ patientSessionSummaryTableData.length }} muscles)</span></p>

                    <List :split="false" size="small" >
                        <ListItem>
                            <Table style="width: 100%;" height="300" size="default" draggable :columns="patientSessionSummaryTableColumnsConfig" :data="patientSessionSummaryTableData" border></Table>
                        </ListItem>
                        <ListItem>
                            Total Units: {{ injectionSummaryTotalUnits }}
                        </ListItem>
                    </List>
                </Card>
            </Col>
        </Row>
    </div>
</template>

<script>

const {CONFIG} = require('@/js/bntx-config')
import AnalyticsMgr from '@/js/AnalyticsManager.js';

import VueQrcode from 'vue-qrcode'
import moment from 'moment'
var _ = require('lodash');

import gender from '@/js/genderGuesstimater.js'

import PatientConditionEditor from '@/components/patient-condition-editor.vue'

//var axios = require("axios");

//const {itBnTx, BnTxError} = require('@/js/itBnTx')
const {itBnTx} = require('@/js/itBnTx')
//const {itBnTx, BnTxError} = require('../../js/itBnTx')
//const {itBnTx} = require('../../js/itBnTx')


export default {
    name: 'pm-patients',
    components: {
        PatientConditionEditor,
        VueQrcode,
    },

    created () {
        this.newSessionDate = new Date()    // set to today

        // alert(BnTxError.serviceNotFoundException)
        // alert(itBnTx.servicesUrl.prod)

        itBnTx.getGenders().then(response => { this.genderRefData = response.data })

//        this.refreshDataForUI(true)   // no longer needed as we now have the on visibility loading mechanism now. saves on double refresh

        // create a throttler function which will automatically manage throttling search requests to a limit, currently 1 per however many milliseconds
        this.throttledSearch = _.debounce(() => { this.handleLoadPatientListToNthPage(1); console.log("handleThrottledSearch 600ms") }, 600, { 'leading': false })
    },

    destroyed() {

    },

    mounted() {

        if (this.$store.state.loggedInPracticeDetails.countryCode == "AU") {
            this.downloadAddressFinder()
        }
        if (this.practiceSupportsCoPayments == true) {
            itBnTx.getAllCopaymentTiers()
                  .then(response => {
                      this.copaymentTierTypes = response.data

                      this.copaymentTierTypes.map(entry => {
                        if (entry.id == 1 /* medicare */) {
                            entry.tierName = "Not Applicable"
                        }
                      })
                  })
            itBnTx.getAllCopaymentConsents()
                  .then(response => {
                      this.copaymentAllConsents = response.data
                  })
        }

    },

    data () {
            return {
                patientTitlesRefData: CONFIG.PATIENT_TITLES,
                physicianTitlesRefData: CONFIG.PHYSICIAN_TITLES,

                genderRefData: [],
                //conditionsRefData: [],
                referringPhysiciansData: [],
                treatingPhysiciansData: [],

                referringPhysiciansFiltered: [],
                referringPhysiciansLoadingFiltering: false,
                // referringPhysicianDefaultLabel: '-- Create New Referring Physician --  ',

                ///////////////////////////////////////////

                showPatientDetails: false,
                showPatientSubConditions: false,
                showPatientMobileSetup : false,

                showCopaymentConsent : false,
                consentNPaymentConfiguredByPatient : false,

                ////////// PATIENT CONTACT STUFF
                patientContactDetails : {
                                            contact: {  
                                                        contactType: "PP",
                                                        mobile : "",
                                                        email : "",
                                                        postcode : ""
                                                    },
                                            address:{ 
                                                        inputAddress: "",
                                            }
                       },


                ////////// CO-PAYMENT STUFF
                copaymentTierTypes : [],    // kinda like refdata
                copaymentAllConsents : [],  // kinda like refdata
                copaymentConsentedIDs : [],

                patientCopaymentDetails : { payerTier : { id : null }, safetyNet : false },

                hasMedicare: true,

                //////////

                patientDetailsDrawerStyles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static',
                    // 'background-color': '#f9f6f6',
                },

                throttledSearch : () => {},
                filterByFirstname: "",
                filterByLastname: "",
                refreshPatientListFromBackend : false,

                patientTableColumnsConfig: [
                    { slot: 'firstname',   title: 'First Name',           key: 'firstName', resizable: true, sortable: true, width: 250 },
                    { slot: 'lastname',    title: 'Last Name',            key: 'lastName', resizable: true, sortable: true, width: 250 },
                    { slot: 'date',        title: 'Date Of Birth',        key: 'dateOfBirth', resizable: true, width: 150 },
                    { slot: 'condition',   title: 'Conditions',           key: 'conditions', resizable: true, minWidth: 200 },
                    {                      title: 'Referring Physician',  key: 'referringPhysician', resizable: true, width: 300 }
                ],

                patientListIsLoading : false,
                patientListPageSize : 20,
                patientTableData: { patients: [] },

                patientDetails: {
//                    contactDetails : { mobile : '', email : '', address : '', postcode: '' },
                    patientConditionIds : [],
                    patientSubconditionIds : [],
                    referringPhysicianJSON: { firstName : '', lastName : '', physicianAddress : '', physicianPhone : ''}

                },
                patientGUIDForQRCode: "",  // current selected users GUID
                isCreatingNewPatient: false,

                allowOnlyPastDatesOptions : {
                    disabledDate (date) {
                        return date && date.valueOf() > Date.now();
                    }
                },


                patientSessionsTableColumnsConfig: [
                    { slot: 'date',        title: 'Session Date',        key: 'sessionDate', resizable: true, sortable: true, width: 140 },
                    {                      title: 'Session Physician',   key: 'sessionPhysician', resizable: true, sortable: true, width: 200 },
                    {                      title: 'Total Units',         key: 'totalUnits', resizable: true, sortable: true, minWidth: 120 }
                ],

                patientSessionsTableData: [],
                selectedPatientSession : {},

                newSessionDate : undefined,

                patientSessionSummaryTableColumnsConfig: [
                    {                     title: 'Muscle',               key: 'injectionSummaryName', resizable: true, sortable: true, minWidth: 100 },
                    {                     title: 'Medication',           key: 'medication', resizable: true, sortable: true, width: 150 },
                    {                     title: 'Units',                key: 'injectionTotalAmount', resizable: true, sortable: true, width: 100 }
                ],

                patientSessionSummaryTableData: [],

                scoresAndRatingsPerformedSummary: [],

                liveValidating: true,
                liveValidatingFieldsOK: true,
                liveValidatingPhysicianFieldsOK: true,
                patientFieldRules : {
                    firstName: [
                        { required: true, message: 'Firstname cannot be empty', trigger: 'blur' },
                    ],
                    lastName: [
                        { required: true, message: 'Lastname cannot be empty', trigger: 'blur' },
                    ],
                    dateOfBirth: [
                        { required: true, type: 'date', message: 'Date of Birth is required', trigger: 'blur' },
                    ],
                    patientConditionIds: [
                        { required: true, type: 'array', min: 1, message: 'A condition is required', trigger: 'blur' },
                    ],
                    userId: [
                        { required: true, type: 'integer', message: 'Treating Physician cannot be empty', trigger: 'blur' },
                    ],
                    // masterPatientId: [
                    //     { required: true, message: 'Master Patient ID cannot be empty', trigger: 'blur' },
                    // ],
                    
                    
                },
                referringPhysicianFieldRules : {
                    "referringPhysicianJSON.firstName": [
                        { required: true, message: 'Firstname cannot be empty', trigger: 'blur' },
                    ],
                    "referringPhysicianJSON.lastName": [
                        { required: true, message: 'Lastname cannot be empty', trigger: 'blur' },
                    ],
                    "referringPhysicianJSON.physicianAddress": [
                        { required: true, message: 'Address cannot be empty', trigger: 'blur' },
                    ],
                    "referringPhysicianJSON.physicianPhone": [
                        { required: true, message: 'Phone cannot be empty', trigger: 'blur' },
                    ],
                },

                responsiveGrid2Sections : {
                    xl: 12,
                    lg: 24,
                    md: 24,
                    sm: 24,
                    xs: 24,
                },
            }
        },

    filters: {
        
    },
    
    computed: {

        conditionsRefData() { return this.$store.state.refDataConditions },

        currentPatientID()                { return this.$store.state.currentPatientID },
        currentSessionID()                { return this.$store.state.currentSessionID },

        practiceSupportsCoPayments()      { return this.$store.state.loggedInPracticeDetails.supportsCoPayments },


        defaultDateInputFormat() {
            return this.$store.state.defaultDateInputFormat
        },

        patientFieldRulesDynamic() {
            var rules = _.cloneDeep(this.patientFieldRules)

            if (this.$store.state.defaultMandatoryPatientDOB == true) {
                rules['dateOfBirth'] = [
                                            { required: true, type: 'date', message: 'Date of Birth is required', trigger: 'blur' },
                                        ]
            } else {
                delete rules['dateOfBirth']
            }

            return rules
        },

        patientContactDetailsFieldRulesDynamic() {
            return {}
        },

        referringPhysicianFieldRulesDynamic() {
            var rules = _.cloneDeep(this.referringPhysicianFieldRules)

            rules["referringPhysicianJSON.firstName"][0].required           = this.$store.state.defaultMandatoryPatientReferrerPhysician
            rules["referringPhysicianJSON.lastName"][0].required            = this.$store.state.defaultMandatoryPatientReferrerPhysician
            rules["referringPhysicianJSON.physicianAddress"][0].required    = this.$store.state.defaultMandatoryPatientReferrerPhysician
            rules["referringPhysicianJSON.physicianPhone"][0].required      = this.$store.state.defaultMandatoryPatientReferrerPhysician

            return rules
        },

        // filtered patient list so we only show active users in patient list UI
        patientTableDataFiltered() { return this.patientTableData.patients.filter(entry => { return entry.status == 'A' || entry.status == 'T'} ) },

        /// returns the sum of all injection amounts
        injectionSummaryTotalUnits() {
            return this.patientSessionSummaryTableData
                       .reduce((totalUnits, injectionEntry) => totalUnits + injectionEntry.injectionTotalAmount, 0 )
        },

        // determine realtime whether we need to show the validation messages (thus make room for them (via css criteria))
        mandatoryFieldsStyle() { return (this.liveValidating && (this.liveValidatingFieldsOK == false || this.liveValidatingPhysicianFieldsOK == false)) ? "revealValidationMessages" : "" },

        // filtered treatingPhysicianArray which includes all Active user (which is obviously applicable), but also include the treating Physician
        // which is associated with the patient, but is now no longer active/employed by the practice.
        treatingPhysiciansDataSpecificForSelectedPatient() { return this.treatingPhysiciansData.filter(entry => { return entry.statusCode == 'A' || this.patientDetails.userId == entry.userId }) },


        dynamicPatientDetailsDrawerWidth() {
            return window.innerWidth < 500 ? 95 : 75
        },
    },
    methods: {

        refreshReferringPhysicians() {
            itBnTx.searchReferringPhysiciansInPractise().then(response => { 
                // get the referring physicians, then sort by title/firstname/lastname
                this.referringPhysiciansData = response.data
                                                        .filter(p => { return p.status == 'A' || p.status == 'T' })
                                                        .sort((a, b) => {
                                                            var a_title = a.title == undefined ? '' : a.title
                                                            var a_firstName = a.firstName == undefined ? '' : a.firstName
                                                            var a_lastName = a.lastName == undefined ? '' : a.lastName
                                                            var b_title = b.title == undefined ? '' : b.title
                                                            var b_firstName = b.firstName == undefined ? '' : b.firstName
                                                            var b_lastName = b.lastName == undefined ? '' : b.lastName
                                                            
                                                            return (([a_title, a_firstName, a_lastName].join(' ')).trim())
                                                                    .localeCompare(([b_title, b_firstName, b_lastName].join(' ')).trim()) 
                                                        });
                
                // push a special referring physician into the list which will trigger 
                // the UI to create a new referring physician
                if (this.referringPhysiciansData.length != 0 && this.referringPhysiciansData[0].referringPhysicianId != 0
                    || this.referringPhysiciansData.length == 0) {

                    this.referringPhysiciansData.unshift({
                        referringPhysicianId : 0,
                        title: "-- Create New Referring Physician --"
                    })
                }
                
                this.loadingFilteringReferringPhysicians("")
            })
        },

        loadingFilteringReferringPhysicians(query) {
            if (
                query != '' || 
                this.patientDetails.referringPhysicianJSON.referringPhysicianId != 0) {

                this.referringPhysiciansLoadingFiltering = true;
                setTimeout(() => {
                    this.referringPhysiciansFiltered = this.referringPhysiciansData.filter(item => {

                            if (item.referringPhysicianId == 0
                                || item.referringPhysicianId == this.patientDetails.referringPhysicianJSON.referringPhysicianId) {
                                return true
                            }

                            var a_title = item.title == undefined ? '' : item.title
                            var a_firstName = item.firstName == undefined ? '' : item.firstName
                            var a_lastName = item.lastName == undefined ? '' : item.lastName
                                                            
                            return ([a_title, a_firstName, a_lastName].join(' ')).trim().toUpperCase().indexOf(query.toUpperCase()) > -1
                        });
                    this.referringPhysiciansLoadingFiltering = false;
                    // console.log("*********** loadingFilteringReferringPhysicians - filter  ", this.referringPhysiciansFiltered)
                }, 1);
            } else {
                this.referringPhysiciansFiltered = [this.referringPhysiciansData[0]];
                this.referringPhysiciansLoadingFiltering = false;
                // console.log("*********** loadingFilteringReferringPhysicians - filter 2 ", this.referringPhysiciansFiltered)
            }
        },

        // this method not really used or called... can delete and cleanup.
        setDefaultReferringPhysicianOptions(options) {
            options
            console.log("######### setDefaultReferringPhysicianOptions  ", options)
            this.referringPhysiciansFiltered = [this.referringPhysiciansData[0]];
        },

        refreshTreatingPhysicians() {
            itBnTx.getAllTreatingPhysicians()
                .then(response => {
                    
                    // dont filter, just order by status code then subsort by username...
                    this.treatingPhysiciansData
                                = response.data
                                            .filter(entry => { return entry.userRoleCodes != undefined && entry.userRoleCodes.includes('P') /* only show physician role users */} )
                                            .sort((a, b) => {
                                                var statusOrder = a.statusCode.localeCompare(b.statusCode)
                                                
                                                if (statusOrder != 0) return statusOrder;

                                                return a.userName.localeCompare(b.userName)
                                            });

                })
        },

        refreshDataForUI(inClearFilterFields) {
            
            if (inClearFilterFields == true) {
                this.filterByFirstname = ""
                this.filterByLastname = ""
                this.$refs.filterByFirstname.focus()
            }

            this.handleLoadPatientListToNthPage(1)
            this.refreshReferringPhysicians()
            this.refreshTreatingPhysicians()

        },

        handlePageLimitChanged(inNewLimit) {
            AnalyticsMgr.sendEvent('/PatientManagement/ViewPatientList', 'click', 'PageLimit_' + inNewLimit)

            this.patientListPageSize = inNewLimit       // silly two way binding not working... so have to do this way
            this.handleLoadPatientListToNthPage(1)
        },

        handleLoadPatientListToNthPage(inPageNumber) {
            var loadOrSearchPromise;

            AnalyticsMgr.sendEvent('/PatientManagement/ViewPatientList', 'click', 'Page_' + inPageNumber)

            // show loading spinner
            this.patientListIsLoading = true

            this.refreshPatientListFromBackend = true

            if (this.filterByFirstname != "" || this.filterByLastname != "") {
                loadOrSearchPromise = itBnTx.searchPatientsByName({
                                                inSearchFirstnameString : this.filterByFirstname.trim(),
                                                inSearchLastnameString : this.filterByLastname.trim(),
                                                inStart : this.patientListPageSize * (inPageNumber - 1),
                                                inLimit : this.patientListPageSize })
            } else {
                loadOrSearchPromise = itBnTx.searchPatientsInPractise({
                                                inStart: this.patientListPageSize * (inPageNumber - 1),
                                                inLimit: this.patientListPageSize})
            }

            loadOrSearchPromise.then(response => {
                
                this.patientTableData = response.data;

                var listOfPatientsShownInUI = this.patientTableDataFiltered
                var selectFirstRecord = true

                if (this.patientDetails.patientId != undefined) {
                    // if we previously had a selection, try to preserve the same record which was previously selected
                    var previousSelection = listOfPatientsShownInUI.find(entry => { return entry.patientId == this.patientDetails.patientId} )

                    // sanity check... should never happen... unless someone deletes it from another location.
                    if (previousSelection != undefined) {
console.log("SELECT PREVIOUS PATIENT SELECTION")
                        previousSelection["_highlight"] = true
                        this.handleAllPatientsSelectionChanged(previousSelection, null)
                        selectFirstRecord = false
                    }
                }

                if (selectFirstRecord == true)
                {
                    // else select the first record

                    // if we have at least one patient, select the first one.
                    if (listOfPatientsShownInUI.length != 0) {
console.log("SELECT FIRST PATIENT ENTRY")
                        listOfPatientsShownInUI[0]["_highlight"] = true
                        this.handleAllPatientsSelectionChanged(listOfPatientsShownInUI[0], null)
                    } else {
console.log("SELECT PATIENT NOTHING")
                        this.handleAllPatientsSelectionChanged(null, null)  // get here when we have zero results returned from a search say...
                    }
                }

            }).finally(() => {
                // hide loading spinner
                this.patientListIsLoading = false
                this.refreshPatientListFromBackend = false
            })
        },

        handleAddPatient() {
            this.liveValidating = false
            
            this.$refs["referringPhysicianDropdown"].clearSingleSelect()

            this.patientDetails = {
//                                    contactDetails : { mobile : '', email : '', address : '', postcode: '' },
                                    patientConditionIds : [],
                                    patientSubconditionIds : [],
                                    referringPhysicianJSON: { firstName : '', lastName : '', physicianAddress : '', physicianPhone : '', referringPhysicianId : 0,
                                                                practiseId : this.$store.state.loggedInDoctorDetails.practiseId },
                                     practiseId : this.$store.state.loggedInDoctorDetails.practiseId
                                  }

            // WTF? why does the above not init referringPhysicianId properly!? below logs show its undefined if the following is not run.
            this.patientDetails.referringPhysicianJSON.referringPhysicianId = 0

// console.log("NEW PATIENT", this.patientDetails)
// console.log("NEW PATIENT", this.patientDetails.referringPhysicianJSON.referringPhysicianId)

            if (this.patientDetails.userId == undefined) {
                // when we create a new patient, the treating physician is not currently setup.
                // so for convenience, lets check the current logged in user, to see if they have a physician role
                // and if they do, set them to be the treating physician.
                if (this.$store.state.loggedInDoctorDetails.userRoleCodes.includes("P") == true) {
                    this.patientDetails.userId = this.$store.state.loggedInDoctorDetails.userId
                }

            }

            this.patientContactDetails = {
                                            contact: {  
                                                contactType: "PP",
                                                mobile : "",
                                                email : "",
                                                postcode : ""
                                                    },
                                            address:{ 
                                                inputAddress: "",
                                            }
                                        }
            
            this.patientCopaymentDetails = { payerTier : { id : 1 /* default to medicare - save user tapping one extra thing... to save time */ }, uuid : null, safetyNet : false }
            this.copaymentConsentedIDs = []

            // this.referringPhysicianDefaultLabel = '-- Create New Referring Physician --  '
            this.loadingFilteringReferringPhysicians("")


            // clear and reset required field messages.
            this.$refs['patientDetailsFormFields'].resetFields()
            this.$refs['referringPhysicianFormFields'].resetFields()

            this.isCreatingNewPatient = true;
            this.showPatientDetails = true;
            this.consentNPaymentConfiguredByPatient = false;

            setTimeout(() => { this.$refs.firstName.focus() }, 500)

            AnalyticsMgr.sendEvent('/PatientManagement/Patients', 'click', 'AddPatient')
        },

        handleEditPatient() {
            this.liveValidating = true
            this.checkFormMandatoryFields()
            
            this.loadingFilteringReferringPhysicians("")

            this.fixupDatesForIview()

            this.loadPatientContactDetails()

            if (this.practiceSupportsCoPayments == true) {
                this.loadPatientCopaymentDetails()
            }

            this.isCreatingNewPatient = false;
            this.showPatientDetails = true;
            this.consentNPaymentConfiguredByPatient = false;

            setTimeout(() => { this.$refs.firstName.focus() }, 500)

            AnalyticsMgr.sendEvent('/PatientManagement/Patients', 'click', 'EditPatient')
        },

        handleNewOrderForPatient() {
            console.log("handleNewOrderForPatient");

            var dateTimeNow = new Date()

            // set new session and thus order date to 'now', adjust the time of the session date which is todays date base.
            this.newSessionDate.setHours(dateTimeNow.getHours());
            this.newSessionDate.setMinutes(dateTimeNow.getMinutes());
            this.newSessionDate.setSeconds(dateTimeNow.getSeconds());

            AnalyticsMgr.sendEvent('/PatientManagement/PatientSessions', 'click', 'NewOrder')

            itBnTx.createSession({ inNewSessionDetails : {
                                                            patientId: this.patientDetails.patientId,
                                                            sessionDate: this.newSessionDate.valueOf(),
                                                            userId: this.patientDetails.userId
                                                        } })
                  .then(response => {

                        // mark this newly created session as the current one... then simulate clicking patient to reload all details in UI properly.
                        // the session id should be setup here so that in handleAllPatientsSelectionChanged() it highlights the correct one.
                        this.selectedPatientSession = response.data
                        
                        //console.log("NEW SESSION CREATED: ", this.selectedPatientSession.sessionId);

                        // force the change as we know we are going to get this eventually
                        this.$store.commit("currentSessionID", this.selectedPatientSession.sessionId);

                        // slowly update / sync the UI
                        this.handleAllPatientsSelectionChanged(this.patientDetails, undefined)

                        // reset to invalid... then later set it, so the assessment UI will know to reload all patient session stuff
                        // otherwise that UI will not pickup the newly created session.
                        this.$store.commit("currentPatientID", 0);

                        // with the correct sessionID in vuex state, just flick to the assessment screen to start the new session.
                        setTimeout(() => {
                            this.$store.commit("newOrderForSessionID", this.selectedPatientSession.sessionId);
                            this.$store.commit("currentPatientID", this.patientDetails.patientId);  // set back to the correct patient id, so assessment UI etc loads the correct info.
                            this.$store.commit("currentNavMenuSelectionID", 'practice-copayment');
                        }, 500)
                  })
        },

        loadPatientContactDetails() {
            
            itBnTx.getPatientContactDetails({inPatientID : this.patientDetails.patientId })
                  .then(response => {
                       var contactDetails = response.data

                       if (contactDetails.contact == null) {
                           contactDetails.contact = {  
                                                        contactType: "PP",
                                                        mobile : "",
                                                        email : "",
                                                        postcode : ""
                                                    }
                       }
                       if (contactDetails.address == null) {
                           contactDetails.address = {  
                                                        inputAddress: "",
                                                        viaAutocomplete : false
                                                    }
                       }
                       this.patientContactDetails = contactDetails
                  })
            
            
        },

        savePatientContactDetails() {
            
            return itBnTx.updatePatientContactDetails({    inPatientID : this.patientDetails.patientId,
                                                             inDetails : this.patientContactDetails })
                        .then(response => {
                            this.patientContactDetails = response.data
                        })

        },



        handleViewPrintConsentForm() {

            AnalyticsMgr.sendEvent('/PatientManagement/EditDetails', 'click', 'ViewPrintConsentForm')

            window.open(CONFIG.PATIENT_CONSENT_FORM_URL(), '_blank' /* , 'location=yes,height=570,width=520,scrollbars=yes,status=yes' */);
        },

        // format a date as YYYY-MM-DD or '' if not defined
        // The backend supports "2016-11-01T13:00:00.000Z" and "2016-11-02"
        // But backend does not handle the time component properly, so will save incorrectly... so have to format as YYYY-MM-DD
        formatAsAPIDate(inDate) {
            return (inDate != undefined && inDate != '') ? moment(inDate).format('YYYY-MM-DD') : ''
        },

        /*
           more internal function, as we need to save the details in a two step process, so 
           we validate/check when applicable the referrer physician, then we check and save the
           patient details.
        */
        validateAndSavePatientDetails({inAndCreateNewSession = false, inClosePatientDetails = true} = {}) {

            this.$refs['patientDetailsFormFields'].validate((valid) => {
                if (valid) {
                    // save all the other stuff.
                    this.liveValidating = false
                    this.liveValidatingFieldsOK = true

                    var savingDetails = this.patientDetails

                    // if we are a new record, make sure to clear the patientId so the API knows to create a new record
                    if (savingDetails.patientId == undefined) {
                        delete savingDetails.patientId
                    }
                    
                    // if the referring doctor is a special / create ID, clear it, so the json has no ID for backend to identify its new and thus create.
                    if (savingDetails.referringPhysicianJSON.referringPhysicianId == undefined
                        || savingDetails.referringPhysicianJSON.referringPhysicianId == 0) {
                        delete savingDetails.referringPhysicianJSON.referringPhysicianId
                    }
                    
                    // TEMP HACK:
                    // Save for backward compatibility the treating physician name back to the field resolved from the userId (treating physician) array
                    // otherwise the treatingPhysician value will mismatch the userId table userName.
                    try {
                        savingDetails.treatingPhysician = this.treatingPhysiciansDataSpecificForSelectedPatient.filter(entry => { return savingDetails.userId == entry.userId })[0].userName
                    } catch (error) {
                        console.log(error)
                    }

                    // format in the way the API wants the dates... YYYY-MM-DD
                    savingDetails.dateOfBirth               = this.formatAsAPIDate(savingDetails.dateOfBirth)
                    savingDetails.dateOfEducationConsent    = this.formatAsAPIDate(savingDetails.dateOfEducationConsent)
                    savingDetails.dateOfResearchConsent     = this.formatAsAPIDate(savingDetails.dateOfResearchConsent)
                    savingDetails.conditionFirstReported    = this.formatAsAPIDate(savingDetails.conditionFirstReported)
                    savingDetails.conditionFirstTreated     = this.formatAsAPIDate(savingDetails.conditionFirstTreated)
                    
                    delete savingDetails._highlight // delete UI key, otherwise API barfs

 //                   console.log("SAVE DETAILS> ", JSON.stringify(this.patientDetails));

                    itBnTx.updatePatient({inPatientDetails : savingDetails })
                        .then(response => {

                            var updatedPatientRecord = response.data

                            // be sure to capture the patientID which is now defined for newly created patients!
                            // this is important as its needed for create new patient and create new session shortly after, otherwise the 
                            // patientID is missing and will result in an error from the backend.
                            this.patientDetails.patientId = updatedPatientRecord.patientId
                            
                            // referrer is cached, so force clearing cache, as update happens via an indirect API, so auto invalidating cache is not triggered.
                            itBnTx.clearCacheForKey({ inKey : "searchReferringPhysiciansInPractise" })
                            this.refreshReferringPhysicians()   // refresh in the event new one created or existing updated.

                            // fix up API shit... it returns in millisecond format rather than a string YYYY-MM-DD!? 
                            updatedPatientRecord.dateOfBirth               = this.formatAsAPIDate(updatedPatientRecord.dateOfBirth)
                            updatedPatientRecord.dateOfEducationConsent    = this.formatAsAPIDate(updatedPatientRecord.dateOfEducationConsent)
                            updatedPatientRecord.dateOfResearchConsent     = this.formatAsAPIDate(updatedPatientRecord.dateOfResearchConsent)
                            updatedPatientRecord.conditionFirstReported    = this.formatAsAPIDate(updatedPatientRecord.conditionFirstReported)
                            updatedPatientRecord.conditionFirstTreated     = this.formatAsAPIDate(updatedPatientRecord.conditionFirstTreated)

// TEMP HACK/FIX till backend returns this in payload
if (updatedPatientRecord.treatingPhysician == undefined)
    updatedPatientRecord.treatingPhysician = savingDetails.treatingPhysician

                            // clear the current selection
                            //this.$refs['patientTable'].clearCurrentRow() // not working!?
                            var currentHighlightedRow = this.patientTableData.patients.find(entry => { return entry['_highlight'] != undefined })
                            if (currentHighlightedRow != undefined)
                                delete currentHighlightedRow['_highlight']

                            // reload data into UI
                            //this.refreshDataForUI(true)   // dont relaod everything, as we only need to update our internal array...
                            if (inAndCreateNewSession == true) {
                                // if a new record, just append to our patient list
                                updatedPatientRecord['_highlight'] = true   // make sure it will get highlighted in UI
                                this.patientTableData.patients.unshift(updatedPatientRecord)
                            } else {
                                // else locate it and update the record. Delete then add.
                                _.remove(this.patientTableData.patients, (patient) => { return patient.patientId == updatedPatientRecord.patientId })
                                updatedPatientRecord['_highlight'] = true   // make sure it will get highlighted in UI
                                this.patientTableData.patients.unshift(updatedPatientRecord)
                            }
                            
                            // update the contact details and consent stuff after we update the patient details...
                            // because this maybe a new patient, and we wont get the details till after we save, specifically the patient id.
                            this.savePatientContactDetails()

                            if (this.practiceSupportsCoPayments == true) {
                               this.setupNSavePatientCopaymentDetails()
                            }

                            // -------------
                            

//                            if (inAndCreateNewSession == false) {

                            // irrespective of if we are creating a new session or not, maybe the patient is newly saved
                            // in which case the session table needs to be refreshed to show an empty list.
                                this.handleAllPatientsSelectionChanged(updatedPatientRecord, null)
                                    .finally(() => {
                                        // async create new session when the previous call has completed being called...
                                        // otherwise backend will get incomplete info when creating a session
                                        // especially the case when creating a new patient... then immediately creating a new session.
                                        // If we create too fast, then the patient id is not defined yet.
                                        if (inAndCreateNewSession == true) {
                                            this.filterByFirstname = this.patientDetails.firstName
                                            this.filterByLastname = this.patientDetails.lastName

                                            this.newSessionDate = new Date()    // set to today and "now"
                                            this.handleNewSession(false)
                                        }
                                    })
  //                          }
                            
                            if (inClosePatientDetails == true) {
                                this.showPatientDetails = false
                            }


                        })



                } else {
                    this.liveValidating = true
                    this.liveValidatingFieldsOK = false

                    this.$Message.error('Enter mandatory fields before trying again.');
                }
            })
        },

        handleNewReferringPhysician() {
            // this.$refs['referringPhysicianDropdown'].clearSingleSelect()
            this.$refs['referringPhysicianDropdown'].setQuery("-- Create New Referring Physician --")
            
            this.patientDetails.referringPhysicianJSON.referringPhysicianId = 0     // select the "Create New Referring PHysician" entry
            this.$refs['referringPhysicianDropdown'].isFocused = false
        },

        handleSearchReferrer() {
            this.$refs['referringPhysicianDropdown'].isFocused = true
            this.$refs['referringPhysicianDropdown'].clearSingleSelect()
        },
        
        handleEditSubConditions() {
            this.showPatientSubConditions = true
        },

        handleSetupPatientApp() {
            if (this.patientDetails.contactDetails.mobile == "") {
                this.$Modal.warning({
                                title: "Required Contact Details",
                                content: "Mobile number is required to setup/authenticate a patients' mobile with the BnTx Interact system.<br /><br />Optionally an email can be setup to send more details to the patient about the patient app service."
                            });

            } else {
                this.showPatientMobileSetup = true
            }

        },

        handleCancelPatientDetails() {
            if (this.isCreatingNewPatient == false) {
                var previousSelection = this.patientTableData.patients.find(entry => { return entry.patientId == this.patientDetails.patientId} )

                // restore any changes back to what they were since the user cancelled.
                var copyDetails = _.cloneDeep(previousSelection)

                if (copyDetails.referringPhysicianJSON == undefined)
                    copyDetails.referringPhysicianJSON = {}     // sanity check and add something so the UI does not go bonkers.

                // if (copyDetails.contactDetails == undefined)
                //     copyDetails.contactDetails = { mobile : '', email : '', address : '', postcode: '' }    // sanity check and add something so the UI does not go bonkers.

                this.patientDetails = copyDetails

                this.fixupDatesForIview()

                AnalyticsMgr.sendEvent('/PatientManagement/Patients/EditPatient', 'click', 'Cancel')
            } else {
                this.handleLoadPatientListToNthPage(1)
            }

            this.showPatientDetails = false

            AnalyticsMgr.sendEvent('/PatientManagement/Patients/AddPatient', 'click', 'Cancel')
        },

        handleSavePatientDetails()                    { this.savePatientDetails({inAndCreateNewSession : false }) },
        handleSavePatientDetailsAndCreateNewSession() { this.savePatientDetails({inAndCreateNewSession : true }) },

        savePatientDetails({inAndCreateNewSession = false, inClosePatientDetails = true} = {}) {
            
            if (this.isCreatingNewPatient == true) {
                if (inAndCreateNewSession == true) {
                    AnalyticsMgr.sendEvent('/PatientManagement/Patients/AddPatient', 'click', 'SaveAndCreateSession')
                } else {
                    AnalyticsMgr.sendEvent('/PatientManagement/Patients/AddPatient', 'click', 'Save')
                }
            } else {
                if (inAndCreateNewSession == true) {
                    AnalyticsMgr.sendEvent('/PatientManagement/Patients/EditPatient', 'click', 'SaveAndCreateSession')
                } else {
                    AnalyticsMgr.sendEvent('/PatientManagement/Patients/EditPatient', 'click', 'Save')
                }
            }

            /*
                if we are creating a new patient, then referrer (for now is optional)
                if we are editing an existing patient OR new patient, AND the referrer is being created/edited, then make checking / validating enabled
            */
            // if (this.patientDetails.referringPhysicianJSON.referringPhysicianId != undefined//)
            //     //|| this.patientDetails.referringPhysicianJSON.referringPhysicianId == 0
            //     ) {
                this.$refs['referringPhysicianFormFields'].validate((valid) => {
                    if (valid) {
                        this.liveValidatingPhysicianFieldsOK = true
                        this.validateAndSavePatientDetails({ inAndCreateNewSession : inAndCreateNewSession, inClosePatientDetails : inClosePatientDetails } )
                    } else {
                        this.liveValidating = true
                        this.liveValidatingPhysicianFieldsOK = false

                        this.$Message.error('Enter mandatory fields before trying again.');
                    }
                })
            // } else {
            //     this.validateAndSavePatientDetails()
            // }
        },

        handleOnSelectTitle(option) {
            var newTitle = option.value

            // exit if title is empty... only process when user has entered a name of some kind
            if (newTitle == "")
                return
            
            if (newTitle == "Mr." || newTitle == "Master.")
                this.patientDetails.genderCode = "M"
            else if (newTitle == "Mrs." || newTitle == "Ms." || newTitle == "Miss.")
                this.patientDetails.genderCode = "F"

            console.log("AFTER", this.patientDetails.genderCode)
        },

        handleOnBlurFirstname() {

            // exit if firstName is empty... only process when user has entered a name of some kind
            if (this.patientDetails.firstName == undefined || this.patientDetails.firstName == "")
                return

            // if gender has a value, respect it, otherwise try to guess the gender from first name
            if (this.patientDetails.genderCode != undefined)
                return

            this.patientDetails.genderCode = gender.guesstimateGender(this.patientDetails.firstName)

            if (this.patientDetails.genderCode != undefined
                && this.patientDetails.title == undefined) {
                
                if (this.patientDetails.genderCode == "M")
                    this.patientDetails.title = "Mr."
                else if (this.patientDetails.genderCode == "F")
                    this.patientDetails.title = "Ms."
            }
            
        },

        fixupDatesForIview() {
            // iview doco states when using DatePicker with v-model the value has to be a Date()
            // this seems to fix an odd issue where by saving an edited record a second time no longer works (as the type may be somehow changed)
            // PLI @30 Aug 2021 - tweaked again, check for undefined/null and '' to not parse as it result in Invalid Date NaN to show in UI
            //                    This seems to only occur when used in conjuction with the date validator.
            if (this.patientDetails != undefined && this.patientDetails.dateOfBirth != null && this.patientDetails.dateOfBirth != '')
                this.patientDetails.dateOfBirth = new Date(this.patientDetails.dateOfBirth)
            if (this.patientDetails != undefined && this.patientDetails.conditionFirstReported != null && this.patientDetails.conditionFirstReported != '')
                this.patientDetails.conditionFirstReported = new Date(this.patientDetails.conditionFirstReported)
            if (this.patientDetails != undefined && this.patientDetails.conditionFirstTreated != null && this.patientDetails.conditionFirstTreated != '')
                this.patientDetails.conditionFirstTreated = new Date(this.patientDetails.conditionFirstTreated)
            if (this.patientDetails != undefined && this.patientDetails.dateOfResearchConsent != null && this.patientDetails.dateOfResearchConsent != '')
                this.patientDetails.dateOfResearchConsent = new Date(this.patientDetails.dateOfResearchConsent)
        },

        handleAllPatientsSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            AnalyticsMgr.sendEvent('/PatientManagement/Patients', 'click', 'PatientSelected')

//  console.log("handleAllPatientsSelectionChanged to ", currentRow.patientId)            
           
            this.patientDetails = { 
                    //contactDetails : { mobile : '', email : '', address : '', postcode: '' },
                    patientConditionIds : [],
                    patientSubconditionIds : [],
                    referringPhysicianJSON: { firstName : '', lastName : '', physicianAddress : '', physicianPhone : ''}
                }
            this.patientSessionSummaryTableData = []
            this.scoresAndRatingsPerformedSummary = []

            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                this.$store.commit("currentSelectedPatientDetails", {referringPhysicianJSON:{}});
                this.$store.commit("currentPatientID", 0);
                this.$store.commit("currentSessionID", 0);
                this.patientSessionsTableData = []
                this.handlePatientSessionsSelectionChanged(null, null)
                return
            }
            
            var copyDetails = _.cloneDeep(currentRow)

            if (copyDetails.dateOfBirth == undefined || copyDetails.dateOfBirth == null)
                delete copyDetails.dateOfBirth     // sanity check and add something so the UI does not go bonkers.
            
            if (copyDetails.referringPhysicianJSON == undefined)
                copyDetails.referringPhysicianJSON = {}     // sanity check and add something so the UI does not go bonkers.

            // if (copyDetails.contactDetails == undefined)
            //     copyDetails.contactDetails = { mobile : '', email : '', address : '', postcode: '' }    // sanity check and add something so the UI does not go bonkers.
            
            this.patientDetails = copyDetails

            this.fixupDatesForIview()

            this.$store.commit("currentSelectedPatientDetails", this.patientDetails);
            this.$store.commit("currentPatientID", currentRow.patientId);

            return itBnTx.getSessionSummaryForPatient({ inPatientID : currentRow.patientId })
                    .then(response => { 
                        this.patientSessionsTableData = response.data 
                        this.$store.commit("currentPatientSessionsSummary", this.patientSessionsTableData)

                        // if we have at least one session, select the first one.
                        if (this.patientSessionsTableData.length != 0) {
                            var foundSessionForCurrentID = this.patientSessionsTableData.find(entry => { return entry.sessionId == this.selectedPatientSession.sessionId })
console.log("DATA", this.selectedPatientSession.sessionId, this.patientSessionsTableData)
                            if (foundSessionForCurrentID != undefined) {
console.log("SELECT PREVIOUS SESSION SELECTION", foundSessionForCurrentID.sessionId)
                                foundSessionForCurrentID["_highlight"] = true
                                this.handlePatientSessionsSelectionChanged(foundSessionForCurrentID, null)
                            } else {
console.log("SELECT FIRST SESSION", this.patientSessionsTableData[0].sessionId)
                                // can't find the one we want to highlight... then just highlight the first session in the array.
                                this.patientSessionsTableData[0]["_highlight"] = true
                                this.handlePatientSessionsSelectionChanged(this.patientSessionsTableData[0], null)
                            }
                        } else {
                            this.$store.commit("currentSessionID", 0);
                        }
                    })
                    .catch(error => {
                        error; this.patientSessionsTableData = [] 
                        this.$store.commit("currentSessionID", 0);
                    })

        },

        handlePatientSessionsSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            AnalyticsMgr.sendEvent('/PatientManagement/PatientSessions', 'click', 'SessionSelected')

// console.log("handlePatientSessionsSelectionChanged to ", currentRow)
            
            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                this.patientSessionSummaryTableData = []
                this.scoresAndRatingsPerformedSummary = []
                this.selectedPatientSession = {}
                return
            }

            this.selectedPatientSession = currentRow

            this.$store.commit("currentSessionID", currentRow.sessionId);

            itBnTx.getInjectionSummaryForSession({inSessionID : currentRow.sessionId})
                  .then(response => { this.patientSessionSummaryTableData = response.data })
                  .catch(error => {error; this.patientSessionSummaryTableData = [] })

            // check the session summary for various rating scores...
            /*
                {
                    recorded: "Yes"
                    sessionDate: 1488761689000
                    sessionHpiNotes: "Patient complains of head turning to the right. \n& has noticed that her symptoms are alleviated by medication. \n& has noticed that her symptoms are interfering with her ability to speak. "
                    sessionId: 877
                    sessionPhysician: "Prajakta Kulkarni"
                    sessionPhysicianId: 374
                    totalDisabilityScore: "0.0"
                    totalEyeScore: "1.0"
                    totalHit6Score: "16.0"
                    totalPainScore: "0.0"
                    totalQolScore: "1"
                    totalTsuiScore: "9.0"
                    totalSeverityScore: "2.0"
                    totalMidasScore: "0"
                    totalFaceScore: "8.0"
                    totalUnits: ""
                }
            */

            this.scoresAndRatingsPerformedSummary = []

            if (currentRow.totalQolScore != undefined)      this.scoresAndRatingsPerformedSummary.push({id:"QoL", score: currentRow.totalQolScore})
            if (currentRow.totalTsuiScore != undefined)     this.scoresAndRatingsPerformedSummary.push({id:"TSUI", score: currentRow.totalTsuiScore})
            if (currentRow.totalSeverityScore != undefined) this.scoresAndRatingsPerformedSummary.push({id:"TWSTRS", score: currentRow.totalSeverityScore})
            if (currentRow.totalEyeScore != undefined)      this.scoresAndRatingsPerformedSummary.push({id:"Eye", score: currentRow.totalEyeScore})
            if (currentRow.totalFaceScore != undefined)     this.scoresAndRatingsPerformedSummary.push({id:"Face", score: currentRow.totalFaceScore})
            if (currentRow.totalHit6Score != undefined)     this.scoresAndRatingsPerformedSummary.push({id:"HIT-6", score: currentRow.totalHit6Score})
            if (currentRow.totalMidasScore != undefined)    this.scoresAndRatingsPerformedSummary.push({id:"MIDAS", score: currentRow.totalMidasScore})
        },

        handleReferringDoctorChanged( physicianID ) {
            var newReferringPhysician = this.referringPhysiciansData.find(entry => entry.referringPhysicianId == physicianID)

            this.patientDetails.referringPhysicianJSON.referringPhysicianId   = physicianID
            this.patientDetails.referringPhysicianJSON.title                  = (newReferringPhysician != undefined && physicianID != 0) ? newReferringPhysician.title : ''
            this.patientDetails.referringPhysicianJSON.firstName              = (newReferringPhysician != undefined && physicianID != 0) ? newReferringPhysician.firstName : ''
            this.patientDetails.referringPhysicianJSON.lastName               = (newReferringPhysician != undefined && physicianID != 0) ? newReferringPhysician.lastName : ''
            this.patientDetails.referringPhysicianJSON.physicianAddress       = (newReferringPhysician != undefined && physicianID != 0) ? newReferringPhysician.physicianAddress : ''
            this.patientDetails.referringPhysicianJSON.physicianPhone         = (newReferringPhysician != undefined && physicianID != 0) ? newReferringPhysician.physicianPhone : ''
            this.patientDetails.referringPhysicianJSON.physicianFax           = (newReferringPhysician != undefined && physicianID != 0) ? newReferringPhysician.physicianFax : ''
            this.patientDetails.referringPhysicianJSON.physicianEmail         = (newReferringPhysician != undefined && physicianID != 0) ? newReferringPhysician.physicianEmail : ''
            this.patientDetails.referringPhysicianJSON.practiseId             = (newReferringPhysician != undefined && physicianID != 0) ? newReferringPhysician.practiseId : this.$store.state.loggedInDoctorDetails.practiseId
        },

        checkFormMandatoryFields() {
            if (this.liveValidating) {
                this.$refs['patientDetailsFormFields'].validate((valid) => {
                    if (valid) {
                        this.liveValidatingFieldsOK = true
                    } else {
                        this.liveValidatingFieldsOK = false
                    }
                })

                if (this.isCreatingNewPatient == true
                    || this.patientDetails.referringPhysicianJSON.referringPhysicianId != undefined
                    //|| this.patientDetails.referringPhysicianJSON.referringPhysicianId == 0
                    ) {

                    this.$refs['referringPhysicianFormFields'].validate((valid) => {
                        if (valid) {
                            this.liveValidatingPhysicianFieldsOK = true
                        } else {
                            this.liveValidatingPhysicianFieldsOK = false
                        }
                    })
                }
                
                
            }
        },


        /////// SESSION METHODS ///////
        handleNewSession(inWithUserSpecifiedDateAndTimeAsNow) {
            
            if (inWithUserSpecifiedDateAndTimeAsNow == true) {
                var dateTimeNow = new Date()
                
                this.newSessionDate.setHours(dateTimeNow.getHours());
                this.newSessionDate.setMinutes(dateTimeNow.getMinutes());
                this.newSessionDate.setSeconds(dateTimeNow.getSeconds());
            }

            AnalyticsMgr.sendEvent('/PatientManagement/PatientSessions', 'click', 'NewSession')

            itBnTx.createSession({ inNewSessionDetails : {
                                                            patientId: this.patientDetails.patientId,
                                                            sessionDate: this.newSessionDate.valueOf(),
                                                            userId: this.patientDetails.userId
                                                        } })
                  .then(response => {

                        // mark this newly created session as the current one... then simlate clicking patient to reload all details in UI properly.
                        // the session id should be setup here so that in handleAllPatientsSelectionChanged() it highlights the correct one.
                        this.selectedPatientSession = response.data
                        
                        console.log("NEW SESSION CREATED: ", this.selectedPatientSession.sessionId);

                        // force the change as we know we are going to get this eventually
                        this.$store.commit("currentSessionID", this.selectedPatientSession.sessionId);

                        // slowly update / sync the UI
                        this.handleAllPatientsSelectionChanged(this.patientDetails, undefined)

                        // reset to invalid... then later set it, so the assessment UI will know to reload all patient session stuff
                        // otherwise that UI will not pickup the newly created session.
                        this.$store.commit("currentPatientID", 0);

                        // with the correct sessionID in vuex state, just flick to the assessment screen to start the new session.
                        setTimeout(() => {
                            this.$store.commit("currentPatientID", this.patientDetails.patientId);  // set back to the correct patient id, so assessment UI etc loads the correct info.
                            this.$store.commit("currentNavMenuSelectionID", 'pm-assessment');
                        }, 500)
                  })
        },

        handleEditSession() {

            AnalyticsMgr.sendEvent('/PatientManagement/PatientSessions', 'click', 'EditSession')

            // if we get here, we should have something selected...so simply switch the nav manu to the assessment section.
            this.$store.commit("currentNavMenuSelectionID", 'pm-assessment');
        },
        
        handleDeleteSession() {

            AnalyticsMgr.sendEvent('/PatientManagement/PatientSessions', 'click', 'DeleteSession')

            itBnTx.deleteSessionWithSessionID({inSessionID : this.selectedPatientSession.sessionId })
                  .then(response => {
                      response
                      // simulate clicking on a patient again, so as to load all the UI properly
                      // eg select any first session in list, and for that session load all injections etc etc.

                      /*
                            NOTE (IMPORTANT):
                            we set the vuex state of currentPatientID to zero, to force the other listening UI components to pickup
                            the change, otherwise they will not pickup a change in patient ID, and thus not refresh say sessions list.

                            We need to delay the call with set timeout, as if we call it too fast, an event loop has not executed
                            and thus setting the currentPatientID to 0 will just be skipped and it will just change to the same value,
                            which will result in (theorising) vuex code to optimize and say... well nothing has changed so I wont notify the 
                            listeners for redundant message.
                      */
                      this.$store.commit("currentPatientID", 0);
                      setTimeout(()=> { this.handleAllPatientsSelectionChanged(this.patientDetails, undefined) }, 300)
                  })
            
        },
        
        visibilityChanged (isVisible, entry) {
            entry

            if (isVisible == true) {
                AnalyticsMgr.sendPageView('/Patients')

                // force a refresh of UI, as the assessment or injections may have changed, so we need to reflect this in the UI
                this.refreshDataForUI(false)
            }
        },

        // ==================================
        // ======== Address Finder ==========
        // ==================================
        initAddressFinder() {
            // https://addressfinder.com.au/docs/widget_code_generator/

            // ================================================
            // ===== INIT ADDRESS FINDER FOR REFERRER ADDRESS
            // ================================================

            /* eslint-disable no-undef */
            var widget = new AddressFinder.Widget(
                                document.getElementById('referrerAddressFinder'),
                                CONFIG.ADDRESSFINDER_KEY,
                                'AU', {
                                    // "address_params": {
                                    // // "gnaf" : "1",
                                    // "au_paf" : "1",
                                    // // "post_box" : "1"
                                    // }
                                    "address_metadata_params": {
                                        "gps": "1"
                                    },
                                    "address_params": {
                                        "au_paf": "1"
                                    },
                                    "empty_content": "No addresses were found. This could be a new address, or you may need to check the spelling."
                                }
                            );
            
            var self = this
            widget.on('result:select', function(fullAddress, metaData) {
                metaData
                self.patientDetails.referringPhysicianJSON.physicianAddress = fullAddress
                
                // console.log("METADATA: ", metaData, fullAddress)
                // self.addressFinderMetadata.address_line_1 = metaData.address_line_1
                // self.addressFinderMetadata.address_line_2 = metaData.address_line_2
                // self.addressFinderMetadata.locality_name = metaData.locality_name
                // self.addressFinderMetadata.state_territory = metaData.state_territory
                // self.addressFinderMetadata.postcode = metaData.postcode
            });

            // ================================================
            // ===== INIT ADDRESS FINDER FOR PATIENT ADDRESS
            // ================================================

            /* eslint-disable no-undef */
            var widget2 = new AddressFinder.Widget(
                                document.getElementById('patientAddressFinder'),
                                CONFIG.ADDRESSFINDER_KEY,
                                'AU', {
                                    // "address_params": {
                                    // // "gnaf" : "1",
                                    // "au_paf" : "1",
                                    // // "post_box" : "1"
                                    // }
                                    "address_metadata_params": {
                                        "gps": "1"
                                    },
                                    "address_params": {
                                        "au_paf": "1"
                                    },
                                    "empty_content": "No addresses were found. This could be a new address, or you may need to check the spelling."
                                }
                            );
            
            widget2.on('result:select', function(fullAddress, metaData) {

                self.patientContactDetails.contact.postcode             = metaData.postcode
                
                self.patientContactDetails.address.viaAutocomplete      = true
                self.patientContactDetails.address.inputAddress         = fullAddress
                self.patientContactDetails.address.addressLine1         = metaData.address_line_1
                self.patientContactDetails.address.addressLine2         = metaData.address_line_2
                self.patientContactDetails.address.boxType              = metaData.box_type
                self.patientContactDetails.address.boxIdentifier        = metaData.box_identifier
                self.patientContactDetails.address.latitude             = metaData.latitude
                self.patientContactDetails.address.longitude            = metaData.longitude
                self.patientContactDetails.address.placeName            = ""
                self.patientContactDetails.address.countryCode          = "AU"
                self.patientContactDetails.address.countryName          = "Australia"
                self.patientContactDetails.address.postcode             = metaData.postcode
                self.patientContactDetails.address.stateTerritory       = metaData.state_territory
                self.patientContactDetails.address.street               = metaData.street
                self.patientContactDetails.address.streetNumber         = metaData.street_number_1
                self.patientContactDetails.address.localityName         = metaData.locality_name
                self.patientContactDetails.address.unit                 = metaData.unit_identifier

                // console.log("METADATA: ", metaData, fullAddress)
                // self.addressFinderMetadata.address_line_1 = metaData.address_line_1
                // self.addressFinderMetadata.address_line_2 = metaData.address_line_2
                // self.addressFinderMetadata.locality_name = metaData.locality_name
                // self.addressFinderMetadata.state_territory = metaData.state_territory
                // self.addressFinderMetadata.postcode = metaData.postcode
            });

        },

        downloadAddressFinder() {
            var script = document.createElement('script');
            script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
            script.async = true;
            script.onload = this.initAddressFinder;
            document.body.appendChild(script);
        },

        // ==========================
        // = Co Payment Methods
        // ==========================


        loadPatientCopaymentDetails() {
            itBnTx.getCopaymentDetailsForPatient({inPatientID : this.patientDetails.patientId})
                  .then(response => {
                      var copaymentDetails = response.data
                      
                      if (copaymentDetails.payerTier == null) {
                          copaymentDetails.payerTier = { id : 1 /* default to medicare - save user tapping one extra thing... to save time */ }
                      }

                      this.patientCopaymentDetails = copaymentDetails

                      this.copaymentConsentedIDs = []
                      if (copaymentDetails.consents != null) {
                          for (var consentIndex in copaymentDetails.consents) {
                              this.copaymentConsentedIDs.push(copaymentDetails.consents[consentIndex].id)
                          }
                      }
                  })

        },

        savePatientCopaymentDetails() {
            if (this.patientCopaymentDetails.payerTier.id != null) {
                for (var tierIndex in this.copaymentTierTypes) {
                    var aTier = this.copaymentTierTypes[tierIndex]

                    if (aTier.id == this.patientCopaymentDetails.payerTier.id) {
                        aTier.safetyNet = this.patientCopaymentDetails.safetyNet;
                        itBnTx.setCopaymentTier({   inCopaymentConfigID : this.patientCopaymentDetails.uuid ,
                                                    inTierDetails : aTier})
                        break;
                    }
                }
            }
            
            if (this.consentNPaymentConfiguredByPatient == false) {
                var consents = [];

                for (var aConsentIndex in this.copaymentConsentedIDs) {
                    consents.push( { id : this.copaymentConsentedIDs[aConsentIndex] } )
                }

                itBnTx.setConsent({   inCopaymentConfigID : this.patientCopaymentDetails.uuid,
                                            inConsents : consents})
            }
        },

        setupNSavePatientCopaymentDetails() {
            if (this.patientDetails.patientId == null)
                return  // we are a new patient, so save after the patient record is saved.

            if (this.patientCopaymentDetails.uuid == null) {
                itBnTx.getCopaymentDetailsForPatient({inPatientID : this.patientDetails.patientId})
                  .then(response => {
                      var copaymentDetails = response.data

                      this.patientCopaymentDetails.uuid = copaymentDetails.uuid
                      this.savePatientCopaymentDetails()
                  })
            } else {
               this.savePatientCopaymentDetails()
            }

        },
        
        handleViewPrintCopaymentAuthorityForm() {

            AnalyticsMgr.sendEvent('/PatientManagement/EditDetails', 'click', 'ViewPrintCopaymentAuthorityForm')

            window.open(CONFIG.COPAYMENT_CONSENT_FORM_URL(), '_blank' /* , 'location=yes,height=570,width=520,scrollbars=yes,status=yes' */);
        },

        handleManagePaymentMethods() {

            AnalyticsMgr.sendEvent('/PatientManagement/EditDetails', 'click', 'ManageCopaymentMethods')

            // When adding a new patient, we need to write all the data to DB (create patient) before the payment URL can be called to create...
            if (this.patientDetails.patientId == undefined) {

                this.savePatientDetails({inAndCreateNewSession : false, inClosePatientDetails : false })
                
                setTimeout(() => {

                    itBnTx.getPatientPaymentConfigURL({ inCopaymentConfigID : this.patientCopaymentDetails.uuid })
                                    .then(response => {
                                        window.open(response.data.url, '_blank' /* , 'location=yes,height=570,width=520,scrollbars=yes,status=yes' */);
                                    })
            
                }, 1500)

            } else {

                // save details, as we need to ensure stripe pre-requisites satisfied... ie has email and mobile.
                this.savePatientContactDetails()
                    .then(response => {
                            response

                            itBnTx.getPatientPaymentConfigURL({ inCopaymentConfigID : this.patientCopaymentDetails.uuid })
                                .then(response => {
                                    window.open(response.data.url, '_blank' /* , 'location=yes,height=570,width=520,scrollbars=yes,status=yes' */);
                                })
                    })
            }
            
            
        },

        handleSMSPatientPaymentSetupLink() {
            AnalyticsMgr.sendEvent('/PatientManagement/EditDetails', 'click', 'SMSPatientPaymentSetupLink')

            // When adding a new patient, we need to write all the data to DB (create patient) before the payment URL can be called to create...
            if (this.patientDetails.patientId == undefined) {

                this.savePatientDetails({inAndCreateNewSession : false, inClosePatientDetails : false })
                
                setTimeout(() => {

                    itBnTx.sendPatientPaymentSetupSMS({ inOrderID : this.patientCopaymentDetails.uuid })
                          .then(response => {
                                response
                                this.$Message.success('SMS Sent. Ask patient to setup via link in SMS.');

                                this.consentNPaymentConfiguredByPatient = true
                            })
    
                }, 1500)

            } else {

                // save details, as we need to ensure stripe pre-requisites satisfied... ie has email and mobile.
                this.savePatientContactDetails()
                    .then(response => {
                            response

                            itBnTx.sendPatientPaymentSetupSMS({ inOrderID : this.patientCopaymentDetails.uuid })
                                  .then(response => {
                                        response
                                        this.$Message.success('SMS Sent. Ask patient to setup via link in SMS.');

                                        this.consentNPaymentConfiguredByPatient = true
                                    })
                    })
            }
            
        }

    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...
        filterByFirstname(newVal, oldVal) {
            //console.log( newVal, oldVal ) 
            if (newVal.trim() == oldVal && newVal.length != 0 && newVal[newVal.length - 1] == ' ') {
                this.$refs.filterByLastname.focus()
            }
        },
        // filterByLastname() { console.log( this.filterByLastname ) },

        showPatientDetails : function (newValue, oldValue) { 
            oldValue

            if (newValue == true && this.patientDetails.patientId != undefined) {
                this.patientGUIDForQRCode = ""

                // user has made the details view shown, so fetch and render a qrcode for the person to see.
                itBnTx.createPatientGUID({ inPatientID : this.patientDetails.patientId })
                      .then(response => { this.patientGUIDForQRCode = response.data.guid })
            }
        },

        // revalidate the form as the user enters details, so give live feedback that what they entered is ok
        "patientDetails.firstName" :            function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['patientDetailsFormFields'].validate() },
        "patientDetails.lastName" :             function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['patientDetailsFormFields'].validate() },
        "patientDetails.dateOfBirth" :          function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['patientDetailsFormFields'].validate() },
        "patientDetails.patientConditionIds" :  function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['patientDetailsFormFields'].validate() },
        "patientDetails.treatingPhysician" :    function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['patientDetailsFormFields'].validate() },
        "patientDetails.masterPatientId" :      function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['patientDetailsFormFields'].validate() },

        "patientDetails.referringPhysicianJSON.firstName" :         function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['referringPhysicianFormFields'].validate() },
        "patientDetails.referringPhysicianJSON.lastName" :          function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['referringPhysicianFormFields'].validate() },
        "patientDetails.referringPhysicianJSON.physicianAddress" :  function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['referringPhysicianFormFields'].validate() },
        "patientDetails.referringPhysicianJSON.physicianPhone" :    function() { if (this.liveValidating && this.showPatientDetails == true) this.$refs['referringPhysicianFormFields'].validate() },


        "patientDetails.consentToUseDataForResearch"(newVal, oldVal) {
            oldVal
            if (newVal == true
                && (this.patientDetails.dateOfResearchConsent == undefined || this.patientDetails.dateOfResearchConsent == "")) {
                this.patientDetails.dateOfResearchConsent = new Date()
            }
        },

    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>
  
.layout {
    padding: 8px;
    height: 100%;
    width: 100%;

    
}


.drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #334155;
        z-index:2;
 }

.ivu-form-item {
    margin-bottom: 4px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}

.revealValidationMessages {
    margin-bottom: 24px !important;    /* 24px */
}

.spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}

.bntx-patient-details-form-patient {

}

.bntx-patient-details-form-referrer {

}



.preAuthFailed {
    color: rgb(194, 139, 0);
    font-weight: bold;
}

</style>

