<template>
    <div id="pm-assessments" class="layout" v-observe-visibility="visibilityChanged" >
        <!-- ====================================== -->
        <!--    Assessemnt View Video Attachment    -->
        <!-- ====================================== -->

        <Modal v-model="showVideoAttachmentDialog" fullscreen :title="videoAttachmentDialogTitle" :footer-hide="true">
            <video width="100%" height="100%" controls autoplay :src="videoAttachmentDialogVideoURL" >
            </video>
        </Modal>
        <Modal v-model="showDocumentAttachmentDialog"
               fullscreen
               :title="(showDocumentAttachmentDialogMode == 'current' && coverflowMode == true) ? 'Unified Preview (Excludes Videos)' : documentAttachmentDialogTitle"
               :footer-hide="true">
            <!-- <iframe class="fullScreenIFrame" frameborder="0" :src="documentAttachmentDialogDocumentURL" /> -->

            <!-- https://swiperjs.com/demos -->
            
            <swiper v-if="showDocumentAttachmentDialogMode == 'current' && coverflowMode == true" class="swiper" :options="swiperOption">
                <swiper-slide v-for="entry in currentSessionExaminationAttachmentsImagesOnlyData" :key="entry.sessionAttachmentId">
                    <img :src="entry.signedAttachmentURL" />
                </swiper-slide>
                
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>

            <img v-else :src="documentAttachmentDialogDocumentURL" width="100%" />
        </Modal>


        <Tabs v-model="assessmentTabsCurrentSelection" type="card" :animated="false" @on-click="handleTabClicked">

            <!-------------------------------->
            <!-- PRE_INJECTION_SUITABILITY  -->
            <!-------------------------------->

            <TabPane :label="tabLabelSuitability" name="suitability" >
                <Form :model="sessionDetails" :label-width="300" label-position="right">
                    <FormItem label="Patient is pregnant:*">
                        <RadioGroup v-model="sessionDetailsBridge.patientIsPregnant" type="button" button-style="solid">
                            <Radio label="1"><span>Yes</span></Radio>
                            <Radio label="0"><span>No</span></Radio>                       
                        </RadioGroup>
                    </FormItem>
                    
                    <FormItem label="Patient is breastfeeding:*">
                        <RadioGroup v-model="sessionDetailsBridge.patientIsBreastFeeding" type="button" button-style="solid">
                            <Radio label="1"><span>Yes</span></Radio>
                            <Radio label="0"><span>No</span></Radio>                       
                        </RadioGroup>
                    </FormItem>

                    <FormItem label="Patient is pending surgery:">
                        <RadioGroup v-model="sessionDetailsBridge.patientIsPendingSurgery" type="button" button-style="solid">
                            <Radio label="1"><span>Yes</span></Radio>
                            <Radio label="0"><span>No</span></Radio>                       
                        </RadioGroup>
                    </FormItem>

                    <FormItem label="Patient has recently undergone surgery:*">
                        <RadioGroup v-model="sessionDetailsBridge.patientHasRecentlyUndergoneSurgery" type="button" button-style="solid">
                            <Radio label="1"><span>Yes</span></Radio>
                            <Radio label="0"><span>No</span></Radio>                       
                        </RadioGroup>
                    </FormItem>

                    <FormItem label="Patient is taking anticoagulants:*">
                        <RadioGroup v-model="sessionDetailsBridge.patientIsTakingAnticoagulants" type="button" button-style="solid">
                            <Radio label="1"><span>Yes</span></Radio>
                            <Radio label="0"><span>No</span></Radio>                       
                        </RadioGroup>
                    </FormItem>

                    <FormItem label="Patient has received BTX treatment in the past 12 weeks for any condition cosmetic or other *">
                        <RadioGroup v-model="sessionDetailsBridge.patientReceivedBTX" type="button" button-style="solid">
                            <Radio label="1"><span>Yes</span></Radio>
                            <Radio label="0"><span>No</span></Radio>                       
                        </RadioGroup>
                    </FormItem>
                </Form>

                <Row v-show="sessionDetailsBridge.patientIsPregnant == 1
                             || sessionDetailsBridge.patientIsBreastFeeding == 1
                             || sessionDetailsBridge.patientIsPendingSurgery == 1
                             || sessionDetailsBridge.patientHasRecentlyUndergoneSurgery == 1
                             || sessionDetailsBridge.patientIsTakingAnticoagulants == 1
                             || sessionDetailsBridge.patientReceivedBTX == 1
                             ">
                    <span class="preInjectionSuitabilityWarning">There may be risks continuing the treatment.</span>
                </Row>
            </TabPane>

            <!----------------------------->
            <!-- HPI                     -->
            <!----------------------------->
           
            <TabPane :label="tabLabelHPI" name="hpi">
                <Row :gutter="8" style="margin-bottom:42px">
                    <Col v-bind="responsiveHPILeft">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title">
                            <Row slot="title" style="width: 100%">
                                <Col span="12" class="ivu-text-left"> Useful Phrases </Col>
                                <Col span="12" class="ivu-text-right">
                                    <Button type="primary" size="small" @click="handleAddHPI()" style="margin-right: 8px">Add HPI</Button>
                                </Col>
                            </Row>
                            </p>
                            <Scroll>
                                <Tree :data="patientUsefulHPIPhrasesData" show-checkbox :check-directly="true" multiple children-key="hpiPhrase" empty-text="Useful phrases only implemented for patients with Cervical Dystonia. You may still enter free text in the ‘Current Assessment’ box to the right."></Tree>
                            </Scroll>
                        </Card>
                    </Col>
                    <Col v-bind="responsiveHPIRight">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title">Current Assessment - History of Present Illness</p>
                            <Input ref="currentHPINotesTextArea" v-model="sessionDetails.sessionHpiNotes" maxlength="4000" show-word-limit type="textarea" placeholder="Enter assessment here..." :autosize="{ minRows: 13, maxRows: 13 }" style="width: 100%" />
                        </Card>                       
                    </Col>
                </Row>

                <Row :gutter="8" style="height:100%; margin-top: 8px">
                    <Col v-bind="responsiveHPILeft">
                        <Card :bordered="true" >
                            <p slot="title">Previous Session</p>
                            <Table style="width: 100%;" height="400" size="default" draggable :columns="patientSessionsSummaryDateOnlyTableColumnsConfig" :data="patientSessionsSummaryDataMinusCurrentSession" :expand-node="true" border highlight-row @on-current-change="handleHPISessionSelectionChanged">
                                <template slot-scope="{ row }" slot="date"><span :class="currentSessionHighlightClass(row)">{{ row.sessionDate | userFriendlyDate }}</span></template>
                            </Table>
                        </Card>
                    </Col>
                    <Col v-bind="responsiveHPIRight">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title">Previous Assessment - History of Present Illness (Read Only)</p>
                            <Input v-model="selectedPreviousHPISessionData.sessionHpiNotes" maxlength="4000" show-word-limit type="textarea" placeholder="no notes entered" :autosize="{ minRows: 2, maxRows: 13 }" style="width: 100%" />
                            <Button type="primary" style="margin-top: 8px" @click="handleCopyHPIFromPrevSession">Copy from Previous</Button>
                        </Card>
                    </Col>
                </Row>
            </TabPane>

            <!----------------------------->
            <!-- EXAMINATION             -->
            <!----------------------------->

            <TabPane :label="tabLabelExamination" name="examination">
                <Row :gutter="10">
                    <Col v-bind="responsiveExaminationLeft">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title">Previous Sessions</p>
                            <Table style="width: 100%;" height="620" size="default" draggable :columns="patientSessionsSummaryDateOnlyTableColumnsConfig" :data="patientSessionsSummaryDataMinusCurrentSession" border highlight-row @on-current-change="handleExaminationSessionSelectionChanged">
                                <template slot-scope="{ row }" slot="date"><span :class="currentSessionHighlightClass(row)">{{ row.sessionDate | userFriendlyDate }}</span></template>
                            </Table>
                        </Card>
                    </Col>

                    <Col v-bind="responsiveExaminationCenter">
                        <Row :gutter="10" style="width:100%; height:350px; margin-bottom:8px">
                            <Card :bordered="true" style="width:100%; height:100%">
                                <p slot="title">Current Examination Notes</p>
                                <Input v-model="sessionDetails.sessionRecommendations" maxlength="4000" show-word-limit type="textarea" placeholder="Enter assessment here..." :autosize="{ minRows: 12, maxRows: 12 }" style="width: 100%" />
                            </Card>
                        </Row>
                        <Row :gutter="10" style="width:100%; height:350px; ">
                            <Card :bordered="true" style="width:100%">
                                <p slot="title">Previous Selected Examination Notes</p>
                                <Input v-model="selectedPreviousExaminationSessionData.sessionRecommendations" maxlength="4000" show-word-limit type="textarea" placeholder="no notes entered" :autosize="{ minRows: 12, maxRows: 12 }" style="width: 100%" />
                            </Card>
                        </Row>
                    </Col>

                    <Col v-bind="responsiveExaminationRight">
                        <Row :gutter="10" style="width:100%; height:350px; margin-bottom:8px">
                            <Card :bordered="true" style="width:100%; height:100%">
                                <p slot="title">Current Examination Attachments <Checkbox v-model="coverflowMode" class="ivu-fr">Unified Preview</Checkbox></p>

                                <Scroll :height="290">
                                    <Row align="middle">
                                        <Col span="16">
                                            <Upload
                                                multiple
                                                type="drag"
                                                name='uploadedFile'
                                                :show-upload-list="true"
                                                :action="uploadActionURL"
                                                :data="uploadExaminationAttachmentData"
                                                :before-upload="handleBeforeUploadAttachment"
                                                :format="['jpg','jpeg','png','mp4', 'm4v','mov']"
                                                :max-size="104800000"
                                                :on-format-error="handleFormatError"
                                                :on-exceeded-size="handleMaxSize"
                                                :on-success="handleSuccess"
                                                :on-error="handleError"
                                                >
                                                <div style="padding: 10px 0">
                                                    <Icon type="ios-cloud-upload" size="32" style="color: #3399ff"></Icon>
                                                    <p>Click or drag files here to upload</p>
                                                </div>
                                            </Upload>
                                        </Col>
                                        <Col span="8">
                                            <center>
                                                <vue-qrcode :value="companionURLForMediaUpload" :width="200" title="Scan this QR code on your phone, to take photos from your phone to attach to this session." />
                                            </center>
                                        </Col>
                                    </Row>
                                    
                                    <List border size="small" style="width:100%">
                                        <!-- <div slot="header"><b>Attachments</b></div> -->
                                        <Scroll :height="170">
                                            <ListItem v-for="entry in currentSessionExaminationAttachmentsData" :key="entry.sessionAttachmentId">
                                                <Row style="width:100%">
                                                    <Col span="18">{{ entry.attachmentName }}</Col>
                                                    <Col span="2">  <font-awesome-icon style="cursor: pointer" @click="handleAttachmentView(entry, 'current')" :icon="['fal', 'eye']" size="lg" pull="left" fixed-width />                  </Col>
                                                    <Col span="2">  <font-awesome-icon style="cursor: pointer" @click="handleAttachmentDownload(entry)" :icon="['fal', 'cloud-download']" size="lg" pull="left" fixed-width />   </Col>
                                                    <Col span="2">
                                                        <Poptip
                                                            confirm transfer
                                                            title="Are you sure you want to delete this attachment? This action is irreversible."
                                                            @on-ok="handleCurrentSessionAttachmentDelete(entry)">
                                                            <font-awesome-icon style="cursor: pointer" :icon="['fal', 'trash-alt']" size="lg" pull="left" fixed-width />
                                                        </Poptip>
                                                    </Col>
                                                </Row>
                                            </ListItem>
                                        </Scroll>
                                    </List>
                                </Scroll>
                            </Card>
                        </Row>

                        <Row :gutter="10" style="width:100%; height:350px; margin-bottom:8px">
                            <Card :bordered="true" style="width:100%; height:100%">
                                <p slot="title">Previous Selected Examination Attachments</p>
                                
                                <List border size="small" style="width:100%">
                                    <!-- <div slot="header"><b>Attachments</b></div> -->
                                    <Scroll :height="260">
                                        <ListItem v-for="entry in selectedPreviousExaminationSessionAttachmentsData" :key="entry.sessionAttachmentId">
                                            <Row style="width:100%">
                                                <Col span="18">{{ entry.attachmentName }}</Col>
                                                <Col span="2">  <font-awesome-icon style="cursor: pointer" @click="handleAttachmentView(entry, 'previous')" :icon="['fal', 'eye']" size="lg" pull="left" fixed-width />                  </Col>
                                                <Col span="2">  <font-awesome-icon style="cursor: pointer" @click="handleAttachmentDownload(entry)" :icon="['fal', 'cloud-download']" size="lg" pull="left" fixed-width />   </Col>
                                                <Col span="2">
                                                    <Poptip
                                                        confirm transfer
                                                        title="Are you sure you want to delete this attachment? This action is irreversible."
                                                        @on-ok="handleAttachmentDelete(entry)">
                                                        <font-awesome-icon style="cursor: pointer" :icon="['fal', 'trash-alt']" size="lg" pull="left" fixed-width />
                                                    </Poptip>
                                                </Col>
                                            </Row>
                                        </ListItem>
                                    </Scroll>
                                </List>
                            </Card>
                        </Row>
                    </Col>
                </Row>
            </TabPane>


            <!-------------------------------->
            <!-- CERVICAL DYSTONIA          -->
            <!-------------------------------->
            <TabPane :label="tabLabelCervicalDystonia" name="condition-details" >
                <PatientConditionEditor :patientDetails="sessionDetailsBridge" :showWarning="true" />
            </TabPane>


            <!----------------------------->
            <!-- Benefits & Side-Effects -->
            <!----------------------------->

            <TabPane :label="tabLabelBenefitsAndSideEffects" name="benefits-side-effects" >
                 <Row justify="space-around" :gutter="8" style="height:100%">
                    
                    <!----------------------------->
                    <!-- Session Results Table -->
                    <!----------------------------->
                    
                    <Col v-bind="responsiveBnSELeft" style="height:100%">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title">Session Results</p>

                            <Spin v-show="patientBenefitsIsLoading == true || patientSideEffectsIsLoading == true || patientScoresNRatingsIsLoading == true" fix>
                                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                                <div>Loading</div>
                            </Spin>
                            <Table style="width: 100%;" height="height:300px" size="default" draggable :columns="patientSessionsSummaryTableColumnsConfig" :data="patientSessionsSummaryData" border highlight-row @on-current-change="handleBenefitNSideEffectSessionSelectionChanged">
                                <template slot-scope="{ row }" slot="date"><span :class="currentSessionHighlightClass(row)">{{ row.sessionDate | userFriendlyDate }}</span></template>
                                <template slot-scope="{ row }" slot="recorded"><span :class="currentSessionHighlightClass(row)">{{ row.recorded == 'N/A' ? "" : row.recorded }}</span></template>
                            </Table>
                        </Card>
                    </Col>


                    <!----------------------------->
                    <!-- Benefits -->
                    <!----------------------------->
                    
                    <Col v-bind="responsiveBnSECenter" style="height:100%">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title">Benefits (Improvement)</p>

                            <Spin v-show="patientBenefitsIsLoading == true" fix>
                                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                                <div>Loading</div>
                            </Spin>

                            <Form :model="patientBenefits" :label-width="150">
                                <FormItem label="No Benefits">
                                    <Checkbox v-model="patientBenefits.lastSessionNoBenefits" />
                                </FormItem>
                            </Form>

                            <Form :model="patientBenefits" :label-width="150" v-show="!patientBenefits.lastSessionNoBenefits">
                                <FormItem label="Onset of benefit">
                                    <Select v-model="patientBenefits.lastSessionBenefitsDuration">
                                        <Option v-for="item in sharedDurationSelectUIData" :value="item.duration" :key="item.duration">{{ item.display }}</Option>
                                    </Select>
                                </FormItem>
                                <FormItem label="Duration of benefit">
                                    <Select v-model="patientBenefits.noticedBenefitsWearingOffDuration">
                                        <Option v-for="item in sharedDurationSelectUIData" :value="item.duration" :key="item.duration">{{ item.display }}</Option>
                                    </Select>
                                </FormItem>
                            </Form>

                            <Divider />

                            <!-- <Checkbox v-model="medicationPrepInfo.isEmg"> </Checkbox> -->
                            <!-- <Table style="width: 100%;" height="300" :columns="sessionBenefitsTableColumnsConfig" :data="sessionBenefitsTableData" border></Table> -->
                            <List item-layout="horizontal" :split="false" size="small" v-show="!patientBenefits.lastSessionNoBenefits">
                                <ListItem v-for="item in patientBenefits.benefits" :key="item.benefitId">
                                    <Col span="24">
                                        <Row>
                                            <Checkbox v-model="item.giveFeedback" :disabled="isBenefitsAndSideEffectsReadOnly">
                                                <strong><span style="color:#777777">{{ item.benefitName }}</span></strong>
                                            </Checkbox> 
                                        </Row>
                                        <Row :gutter="-80">
                                            <Slider :min="0" :max="10" v-model="item.level" :marks="scaleBenefitsMarks" :disabled="!item.giveFeedback || isBenefitsAndSideEffectsReadOnly" style="width:100%"></Slider>
                                        </Row>
                                    </Col>
                                </ListItem>
                            </List>
                        </Card>
                    </Col>


                    <!----------------------------->
                    <!-- Side Effects -->
                    <!----------------------------->


                    <Col v-bind="responsiveBnSERight" style="height:100%">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title">Side Effects (Severity)</p>

                            <Spin v-show="patientSideEffectsIsLoading == true" fix>
                                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                                <div>Loading</div>
                            </Spin>

                            <Form :model="patientSideEffects" :label-width="150">
                                <FormItem label="No Side Effects">
                                    <Checkbox v-model="patientSideEffects.lastSessionNoSideEffects" />
                                </FormItem>
                            </Form>

                            <Form :model="patientSideEffects" :label-width="150" v-show="!patientSideEffects.lastSessionNoSideEffects">
                                <FormItem label="Onset of side effect">
                                    <Select v-model="patientSideEffects.lastSessionSideEffectsDuration">
                                        <Option v-for="item in sharedDurationSelectUIData" :value="item.duration" :key="item.duration">{{ item.display }}</Option>
                                    </Select>
                                </FormItem>
                                <FormItem label="Duration of side effect">
                                    <Select v-model="patientSideEffects.noticedSideEffectsWearingOffDuration">
                                        <Option v-for="item in sharedDurationSelectUIData" :value="item.duration" :key="item.duration">{{ item.display }}</Option>
                                    </Select>
                                </FormItem>
                            </Form>

                            <Divider />

                            <List item-layout="horizontal" :split="false" size="small" v-show="!patientSideEffects.lastSessionNoSideEffects">
                                <ListItem v-for="item in patientSideEffects.sideEffects" :key="item.sideEffectId">
                                    <Col span="24">
                                        <Row>
                                            <Checkbox v-model="item.giveFeedback" :disabled="isBenefitsAndSideEffectsReadOnly">
                                                <strong><span style="color:#777777">{{ item.sideEffectName }}</span></strong>
                                            </Checkbox> 
                                        </Row>
                                        <Row :gutter="-80">
                                            <Slider :min="0" :max="10" v-model="item.level" :marks="scaleSideEffectsMarks" :disabled="!item.giveFeedback || isBenefitsAndSideEffectsReadOnly" style="width:100%"></Slider>
                                        </Row>
                                    </Col>
                                </ListItem>
                            </List>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
            
            
            <!----------------------------->
            <!-- SCORES & RATINGS        -->
            <!----------------------------->
           
            <TabPane :label="tabLabelScoresAndRatings" name="scores-ratings">
                                
                <Row style="margin-bottom:8px">
                    <RadioGroup v-model="currentlyDisplayingScoreAndRatingIndex" type="button" button-style="solid" style="margin-bottom:8px">
                        <Radio v-for="item in scoresAndRatingConfig"
                               v-show="ratingTypeAvailableToPatient(item.ratingTypeCode)"
                               :key="item.index"
                               :label="item.index">{{item.ratingTypeCode}}</Radio>
                    </RadioGroup>

                    <RadioGroup v-show="practiceSupportsCoPayments" v-model="scoreAndRatingDisplayMode" type="button" button-style="solid" style="margin-bottom:8px; margin-left:16px">
                        <Radio key="details" label="details">Details</Radio>
                        <Radio key="charts" label="charts">Charts</Radio>
                    </RadioGroup>

                    <Button @click="handleRefreshChart" v-show="scoreAndRatingDisplayMode == 'charts'" style="margin-left:8px">Refresh Chart</Button>
                </Row>
                <Row v-show="scoreAndRatingDisplayMode == 'charts'" :gutter="8">
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='QoL'" span="24" style="height:100%">
                        <ReportInsightsQoL ref="chart_QoL" :patientID="patientID" />
                    </Col>
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='TSUI'" span="24" style="height:100%">
                        <!-- <ReportInsightsQoL /> -->
                        No charts available for this section.
                    </Col>
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='TWSTRS'" span="24" style="height:100%">
                        <!-- <ReportInsightsQoL /> -->
                        No charts available for this section.
                    </Col>
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='Eye'" span="24" style="height:100%">
                        <!-- <ReportInsightsQoL /> -->
                        No charts available for this section.
                    </Col>
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='Face'" span="24" style="height:100%">
                        <!-- <ReportInsightsQoL /> -->
                        No charts available for this section.
                    </Col>
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='HIT-6'" span="24" style="height:100%">
                        <ReportInsightsHIT6 ref="chart_HIT-6" :patientID="patientID" />
                    </Col>
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='MIDAS'" span="24" style="height:100%">
                        <!-- <ReportInsightsQoL /> -->
                        No charts available for this section.
                    </Col>
                </Row>
                <Row v-show="scoreAndRatingDisplayMode == 'details'" :gutter="8">
                    <!----------------------------->
                    <!-- Session Results Table -->
                    <!----------------------------->
                    
                    <Col v-bind="responsiveRatingsLeft" style="height:100%">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title">{{scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode}} Results</p>
                            
                            <Spin v-show="patientBenefitsIsLoading == true || patientSideEffectsIsLoading == true || patientScoresNRatingsIsLoading == true" fix>
                                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                                <div>Loading</div>
                            </Spin>
                            <Table style="width: 100%;" height="height:300px" size="default" draggable :columns="patientSessionsSummaryTableColumnsDynamicConfig" :data="patientSessionsSummaryData" border highlight-row @on-current-change="handleScoresNRatingsSessionSelectionChanged">
                                <template slot-scope="{ row }" slot="date"><span :class="currentSessionHighlightClass(row)">{{ row.sessionDate | userFriendlyDate }}</span></template>
                                <template slot-scope="{ row }" slot="score"><span :class="currentSessionHighlightClass(row)">{{ (parseInt(row[scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].summaryKey]) > 0) 
                                                                                                                                        ? truncateToOneDecimalPlace(row[scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].summaryKey]) : '' }}</span></template>
                            </Table>
                        </Card>
                    </Col>


                    <!----------------------------->
                    <!-- HIT6 -->
                    <!----------------------------->
                    
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='HIT-6'" v-bind="responsiveRatingsRight" style="height:100%">
                        <Spin v-show="patientScoresNRatingsIsLoading == true" fix>
                            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin>

                        <Card :bordered="true" style="height:100%">
                            <p slot="title"><span class="scoresNRatingsScoreTitle">Total HIT6 Score: {{ ratingHIT6TotalScore }}</span>&nbsp;&nbsp;<span :class="ratingHIT6TotalScore >= 60 ? 'scoresNRatingsScoreTitle' : ''">({{ ratingHIT6ScoreMessage }})</span></p>

                            <Form v-for="item in ratingHIT6QuestionsFormData" :key="item.hitID" :label-width="230" label-position="left" >
                                <FormItem :label="item.question">
                                    <RadioGroup v-model="ratingHIT6SessionResponses['hit6ScoreByHit'+item.hitID]" type="button" button-style="solid" style="margin-top: 8px">
                                        <Radio v-for="response in ratingHIT6ResponsesRefData" :key="response.scoreID" :label="response.scoreID" style="width:100px; text-align:center;">{{ response.title }}</Radio>
                                        <Button shape="circle" icon="ios-close" style="margin-left:8px" title="Clear selection" @click="ratingHIT6SessionResponses['hit6ScoreByHit'+item.hitID] = 0"></Button>
                                    </RadioGroup>
                                     
                                </FormItem>
                            </Form>

                        </Card>
                    </Col>



                    <!----------------------------->
                    <!-- MIDAS -->
                    <!----------------------------->
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='MIDAS'" v-bind="responsiveRatingsRight" style="height:100%">
                        <Spin v-show="patientScoresNRatingsIsLoading == true" fix>
                            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin>

                        <Card :bordered="true" style="height:100%">
                            <p slot="title"><span class="scoresNRatingsScoreTitle">Total MIDAS Score: {{ ratingMidasTotalScore >= 21 ? ">21" : (ratingMidasTotalScore >= 0 ? ratingMidasTotalScore : 0) }}</span>&nbsp;&nbsp;<span :class="ratingMidasTotalScore >= 21 ? 'scoresNRatingsScoreTitle' : ''">({{ ratingMidasScoreMessage }})</span></p>

                            <Form v-for="(item, index) in ratingMIDASQuestionsFormData" :key="item.midasQuestionId" :label-width="20" label-position="left">
                                <FormItem :label="(index + 1).toString()">
                                    <Row>
                                        <Col v-bind="{ xl: 16,  lg: 16,  md: 16,  sm: 12,  xs: 12  }" style="margin-top: 7px; line-height: 20px;">{{item.midasQuestion}}</Col>
                                        <Col v-bind="{ xl: 8,   lg: 8,   md: 8,  sm: 12,  xs: 12  }" ><InputNumber v-model="ratingMIDASSessionResponses['midas'+item.midasQuestionId]"
                                                                    :min="0"
                                                                    :max="100"
                                                                    controls-outside
                                                                    style="margin-top: 8px; margin-left:8px"
                                                                    :formatter="value => value == -1 ? '' : value"></InputNumber></Col>
                                    </Row>
                                </FormItem>
                            </Form>

                        </Card>
                    </Col>



                    <!----------------------------->
                    <!-- TSUI -->
                    <!----------------------------->
                    
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='TSUI'" v-bind="responsiveRatingsRight" style="height:100%">
                        <Spin v-show="patientScoresNRatingsIsLoading == true" fix>
                            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin>

                        <Card :bordered="true" style="height:100%">
                            <p slot="title"><span class="scoresNRatingsScoreTitle">Total TSUI Score: {{ truncateToOneDecimalPlace(ratingTSUITotalScore) }}</span></p>

                            <Form v-for="item in ratingTSUIQuestionsFormData" :key="item.tsuiID" :label-width="160" label-position="right" >
                                <FormItem :style="(item.shade == 1 ? 'background-color:#F9F9F9;' : '') + 'padding-bottom: 8px'">
                                    <p slot="label"><span v-html="item.question" style="font-weight:500"></span></p>
                                    <RadioGroup v-model="ratingTSUISessionResponses[item.tsuiID]" type="button" button-style="solid" :style=" 'margin-top: 8px'">
                                        <Radio v-for="response in item.answers" :key="response.scoreID" :label="response.scoreID" style="width:160px; text-align:center;" :title="response.tooltip != '' ? response.tooltip : ''">{{ response.title }}</Radio>
                                        <Button shape="circle" icon="ios-close" style="margin-left:8px" title="Clear selection" @click="ratingTSUISessionResponses[item.tsuiID] = undefined"></Button>
                                    </RadioGroup>
                                </FormItem>
                            </Form>
                        </Card>
                    </Col>

                    <!----------------------------->
                    <!-- TWSTRS -->
                    <!----------------------------->
                    
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='TWSTRS'" v-bind="responsiveRatingsRight" style="height:100%">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title"><span class="scoresNRatingsScoreTitle">Total TWSTRS Score: </span></p>
                            <!-- <p slot="title"><span class="scoresNRatingsScoreTitle">Total TSUI Score: {{ ratingMidasTotalScore >= 21 ? ">21" : ratingMidasTotalScore }}</span>&nbsp;&nbsp;<span :style="ratingMidasTotalScore >= 21 ? 'color:red' : ''">({{ ratingMidasScoreMessage }})</span></p> -->

                            TWSTRS - Coming soon.

                            <!-- dont forget to add back GA for this section -->
                        </Card>
                    </Col>

                    <!----------------------------->
                    <!-- QoL -->
                    <!----------------------------->
                    
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='QoL'" v-bind="responsiveRatingsRight" style="height:100%">
                        <Spin v-show="patientScoresNRatingsIsLoading == true" fix>
                            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin>

                        <Card :bordered="true" style="height:100%">
                            <p slot="title"><span class="scoresNRatingsScoreTitle">Total QoL Score: {{ ratingQoLTotalScore }}</span></p>

                            <Form v-for="(item, index) in ratingQoLQuestionsFormData" :key="item.qolID" :label-width="responsiveQoLLabelWidth" label-position="left" >
                                <FormItem :style="(index % 2 ? 'background-color:#F9F9F9;' : '') + 'padding-bottom: 8px'">
                                    <p slot="label"><span v-html="item.question"></span></p>
                                    <RadioGroup v-model="ratingQoLSessionResponses[item.qolID + 'QolScore']" type="button" button-style="solid" :style=" 'margin-top: ' + item.pos + 'px'">
                                        <Radio v-for="response in item.answers" :key="response.scoreID" :label="response.scoreID" style="width:150px; text-align:center;">{{ response.title }}</Radio>
                                        <Button shape="circle" icon="ios-close" style="margin-left:8px" title="Clear selection" @click="ratingQoLSessionResponses[item.qolID + 'QolScore'] = undefined"></Button>
                                    </RadioGroup>
                                </FormItem>
                            </Form>
                        </Card>
                    </Col>


                    <!----------------------------->
                    <!-- Eye -->
                    <!----------------------------->
                    
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='Eye'" v-bind="responsiveRatingsRight" style="height:100%">
                        <Spin v-show="patientScoresNRatingsIsLoading == true" fix>
                            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin>

                        <Card :bordered="true" style="height:100%">
                            <p slot="title"><span class="scoresNRatingsScoreTitle">Disability Index (BSDI): {{ ratingEyeBSDITotalScore >= 0 ? ratingEyeBSDITotalScore : 0 }}</span></p>

                            <Form v-for="item in ratingEyeQuestionsFormData" :key="item.eyeID" :label-width="160" label-position="right" >
                                <FormItem>
                                    <p slot="label"><span v-html="item.question" style="font-weight:500"></span></p>
                                    <RadioGroup v-model="ratingEyeSessionResponses[item.eyeID]" type="button" button-style="solid" :style=" 'margin-top: 8px'">
                                        <Radio v-for="response in item.answers" :key="response.scoreID" :label="response.scoreID" style="width:160px; text-align:center;" :title="response.tooltip != '' ? response.tooltip : ''">{{ response.title }}</Radio>
                                        <Button shape="circle" icon="ios-close" style="margin-left:8px" title="Clear selection" @click="ratingEyeSessionResponses[item.eyeID] = undefined"></Button>
                                    </RadioGroup>
                                </FormItem>
                            </Form>
                        </Card>

                        <Card :bordered="true" style="height:100%">
                            <p slot="title"><span class="scoresNRatingsScoreTitle">Jankovic Rating Scale (JRS): {{ ratingEyeJRSTotalScore >= 0 ? ratingEyeJRSTotalScore : 0 }}</span></p>

                            <Form v-for="item in ratingEye2QuestionsFormData" :key="item.eyeID" :label-width="160" label-position="right" >
                                <FormItem>
                                    <p slot="label"><span v-html="item.question" style="font-weight:500"></span></p>
                                    <RadioGroup v-model="ratingEyeSessionResponses[item.eyeID]" type="button" button-style="solid" :style=" 'margin-top: 8px'">
                                        <Radio v-for="response in item.answers" :key="response.scoreID" :label="response.scoreID" style="width:160px; text-align:center;" :title="response.tooltip != '' ? response.tooltip : ''">{{ response.title }}</Radio>
                                        <Button shape="circle" icon="ios-close" style="margin-left:8px" title="Clear selection" @click="ratingEyeSessionResponses[item.eyeID] = undefined"></Button>
                                    </RadioGroup>
                                </FormItem>
                            </Form>
                        </Card>
                    </Col>


                    <!----------------------------->
                    <!-- Face -->
                    <!----------------------------->
                    
                    <Col v-show="scoresAndRatingConfig[currentlyDisplayingScoreAndRatingIndex].ratingTypeCode=='Face'" v-bind="responsiveRatingsRight" style="height:100%">
                        <Card :bordered="true" style="height:100%">
                            <p slot="title"><span class="scoresNRatingsScoreTitle">Total Face Score: </span></p>
                            <!-- <p slot="title"><span class="scoresNRatingsScoreTitle">Total TSUI Score: {{ ratingMidasTotalScore >= 21 ? ">21" : ratingMidasTotalScore }}</span>&nbsp;&nbsp;<span :style="ratingMidasTotalScore >= 21 ? 'color:red' : ''">({{ ratingMidasScoreMessage }})</span></p> -->

                            Face - Coming soon.
                        </Card>
                    </Col>


                </Row>

            </TabPane>
        </Tabs>
    </div>
</template>

<script>

const {CONFIG} = require('@/js/bntx-config')
import AnalyticsMgr from '@/js/AnalyticsManager.js';
var qs = require('qs');
import VueQrcode from 'vue-qrcode'


const {itBnTx} = require('@/js/itBnTx')
var _ = require('lodash');

import PatientConditionEditor from '@/components/patient-condition-editor.vue'

import ReportInsightsQoL from '@/components/report-insights-qol.vue'
import ReportInsightsHIT6 from '@/components/report-insights-hit6.vue'


// import { Swiper, SwiperSlide } from 'swiper/vue';
// import 'swiper/swiper.min.css';


import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import style (>= Swiper 6.x)
//import 'swiper/swiper-bundle.css'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'



// ================
// == QoL
// ================
let ratingQoLResponsesGoodnessRefData = [
                                { scoreID: 1, title: "Excellent"},
                                { scoreID: 2, title: "Very Good"},
                                { scoreID: 3, title: "Good"},
                                { scoreID: 4, title: "Fair"},
                                { scoreID: 5, title: "Poor"},
                            ]
let ratingQoLResponsesLimitedRefData = [
                                { scoreID: 1, title: "Yes, limited a lot"},
                                { scoreID: 2, title: "Yes, limited a little"},
                                { scoreID: 3, title: "No, not limited at all"},
                            ]
let ratingQoLResponsesTimeRefData = [
                                { scoreID: 1, title: "All of the time"},
                                { scoreID: 2, title: "Most of the time"},
                                { scoreID: 3, title: "Some of the time"},
                                { scoreID: 4, title: "A little of the time"},
                                { scoreID: 5, title: "None of the time"},
                            ]
let ratingQoLResponsesPainRefData = [
                                { scoreID: 1, title: "Not at all"},
                                { scoreID: 2, title: "A little bit"},
                                { scoreID: 3, title: "Moderately"},
                                { scoreID: 4, title: "Quite a bit"},
                                { scoreID: 5, title: "Extremely"},
                            ]

// ================
// == TSUI
// ================
let ratingTSUIResponsesAngleRefData = [
                                { scoreID: 1, title: "< 15"},
                                { scoreID: 2, title: "15 - 30"},
                                { scoreID: 3, title: "31 - 60"},
                                { scoreID: 4, title: "61 - 90"},
                            ]
let ratingTSUIResponsesLeftRightRefData = [
                                { scoreID: "Left", title: "Left"},
                                { scoreID: "Right", title: "Right"},
                            ]

// ================
// == Eye
// ================
let ratingEyeResponsesImpairmentRefData = [
                                { scoreID: 0, title: "None",         tooltip: "No impairment"},
                                { scoreID: 1, title: "Mild",         tooltip: "Mild impairment"},
                                { scoreID: 2, title: "Moderate",     tooltip: "Moderate impairment"},
                                { scoreID: 3, title: "Severe",       tooltip: "Severe impairment"},
                                { scoreID: 4, title: "Not Possible", tooltip: "Not possible due to disease"},
                            ]

const ScoreNRating = {
    QoL:    0,
    TSUI:   1,
    TWSTRS: 2,
    Eye:    3,
    Face:   4,
    HIT6:   5,
    MIDAS:  6,
};

export default {
    name: 'pm-assessments',
    components: {
        PatientConditionEditor,

        ReportInsightsQoL,
        ReportInsightsHIT6,
        
        VueQrcode,

        Swiper,
        SwiperSlide,
    },

    directives: {
        swiper: directive
    },
    
    created () {

        setTimeout(() => {
            itBnTx.getAllMidasQuestions().then(response => {
                this.ratingMIDASQuestionsFormData = response.data;
            })

            itBnTx.getGenders().then(response => { this.genderRefData = response.data })

        }, 2000)
        
        this.currentlyDisplayingScoreAndRatingIndex = ScoreNRating.QoL
        
        this.patientSessionsSummaryTableColumnsDynamicConfig[1].key = this.scoresAndRatingConfig[this.currentlyDisplayingScoreAndRatingIndex].summaryKey;
    },

    destroyed() {
        console.log("going away... quick save")

        this.saveDetailsBackToDB()
    },

    mounted() {
    },

    data () {
            return {
                // following are cached, and loaded as soon as we are visible, so save on unnecessary API calls. Sometimes too dynamic is not good.
                toLoadOnShowUI_patientID: null,
                toLoadOnShowUI_sessionID: null,

                ///////////////////////////////////////////////////////
                genderRefData: [],

                assessmentTabsCurrentSelection : 'suitability',

                sharedDurationSelectUIData : CONFIG.ASSESSMENT_DURATION,

                patientID : 0,

                sessionDetailsBridge : {
                    patientIsPregnant : 0,
                    patientIsBreastFeeding : 0,
                    patientIsPendingSurgery : 0,
                    patientHasRecentlyUndergoneSurgery : 0,
                    patientIsTakingAnticoagulants : 0,
                    patientReceivedBTX : 0,
                    
                    patientConditionIds : [],
                    patientSubconditionIds : [],
                },
                sessionDetails : {},

                needToSyncToDB : false,


                patientUsefulHPIPhrasesData : [],
                selectedPreviousHPISessionData : {},


                currentSessionExaminationAttachmentsData : [],
                selectedPreviousExaminationSessionData : {},
                selectedPreviousExaminationSessionAttachmentsData : [],
                showVideoAttachmentDialog : false,
                videoAttachmentDialogTitle : "",
                videoAttachmentDialogVideoURL : "",

                showDocumentAttachmentDialogMode : 'current',
                coverflowMode : true,
                showDocumentAttachmentDialog : false,
                documentAttachmentDialogTitle : "",
                documentAttachmentDialogDocumentURL : "",


                uploadExaminationAttachmentData : {
                    practiseId : 0,
                    userId : 0,
                    authToken : '',
                    uploadType : "Attachment", //"Signature", "LogoLight", "LogoDark", "Attachment"
                    sessionId : 0
                },


                cachedMasterPatientBenefits : [],      // CACHE TO ONLY CALL API ONCE per patient switching
                cachedMasterPatientSideEffects : [],    // CACHE TO ONLY CALL API ONCE per patient switching

                patientBenefits : {
                    lastSessionNoBenefits : false,
                    lastSessionBenefitsDuration : null,
                    noticedBenefitsWearingOffDuration : null,

                    benefits: []
                },
                patientSideEffects : {
                    lastSessionNoSideEffects : false,
                    lastSessionSideEffectsDuration : null,
                    noticedSideEffectsWearingOffDuration : null,

                    sideEffects : []
                },

                patientBenefitsIsLoading : false,
                patientSideEffectsIsLoading : false,
                patientScoresNRatingsIsLoading : false,


                scaleBenefitsMarks : {
                    0: {
                        style: {
                            color: '#DF787A'
                        },
                        label: this.$createElement('span', 'No Change')
                    },
                    3: '',
                    5: 'Tolerable',
                    7: '',
                    10: {
                        style: {
                            color: '#86df8c'
                        },
                        label: this.$createElement('strong', 'Perfect')
                    },

                },

                scaleSideEffectsMarks : {
                    0: {
                        style: {
                            color: '#86df8c'
                        },
                        label: this.$createElement('span', 'Acceptable')
                    },
                    3: '',
                    5: 'Tolerable',
                    7: '',
                    10: {
                        style: {
                            color: '#DF787A'
                        },
                        label: this.$createElement('strong', 'Extreme')
                    },

                },


// sessionBenefitsTableColumnsConfig: [
                //     {                      title: 'Feedback',            key: 'sessionDate', resizable: true, sortable: true, width: 150 },
                //     {                      title: 'Benefit',             key: 'sessionPhysician', resizable: true, sortable: true, width: 200 },
                //     {                      title: 'Scale',               key: 'totalUnits', resizable: true, sortable: true, minWidth: 80 }
                // ],

                // sessionBenefitsTableData: [],


                /*
                    response array of dicts:

                        recorded: "N/A"
                        sessionDate: 1623840765000
                        sessionHpiNotes: ""
                        sessionId: 1519
                        sessionPhysician: "Peter Li"
                        sessionPhysicianId: 63
                        totalUnits: "BOTOX® 6.0 Units"

                */
                patientSessionsSummaryData : [],
                selectedPreviousSessionID: 0,

                patientSessionsSummaryDateOnlyTableColumnsConfig: [
                    { slot: 'date',        title: 'Date',                key: 'sessionDate', resizable: true, sortable: true },
                ],

                patientSessionsSummaryTableColumnsConfig: [
                    { slot: 'date',        title: 'Date',                key: 'sessionDate', resizable: true, sortable: true, minWidth: 120 },
                    { slot: 'recorded',    title: 'Recorded',            key: 'recorded', resizable: true, sortable: true, width: 120 },
                ],

                // dynamic config is used for Scores and Ratings which has a dynamic second column which shows results based on which rating system is being displayed.
                patientSessionsSummaryTableColumnsDynamicConfig: [
                    { slot: 'date',        title: 'Date',                key: 'sessionDate', resizable: true, sortable: true, width: 120 },
                    { slot: 'score',       title: 'Total Score',         key: 'totalHit6Score', resizable: true, sortable: true, width: 120 },      // this "key" need to dynamically change to change what we display.
                ],
                selectedScoreNRatingPreviousSessionSummary: {},

                patientRatingTypesData : [],    // loaded from API when patient changes

                scoresAndRatingConfig : [
                    { index:ScoreNRating.QoL,    ratingTypeCode: "QoL",    summaryKey: 'totalQolScore' },
                    { index:ScoreNRating.TSUI,   ratingTypeCode: "TSUI",   summaryKey: 'totalTsuiScore' },
                    { index:ScoreNRating.TWSTRS, ratingTypeCode: "TWSTRS", summaryKey: 'totalSeverityScore' },    // actually one of three, other are totalDisabilityScore, totalPainScore
                    { index:ScoreNRating.Eye,    ratingTypeCode: "Eye",    summaryKey: 'totalEyeScore' },
                    { index:ScoreNRating.Face,   ratingTypeCode: "Face",   summaryKey: 'totalFaceScore' },
                    { index:ScoreNRating.HIT6,   ratingTypeCode: "HIT-6",  summaryKey: 'totalHit6Score' },
                    { index:ScoreNRating.MIDAS,  ratingTypeCode: "MIDAS",  summaryKey: 'totalMidasScore' },
                ],
                currentlyDisplayingScoreAndRatingIndex : ScoreNRating.QoL,
                scoreAndRatingDisplayMode : 'details',

                // ================
                // == HIT6
                // ================
                ratingHIT6ResponsesRefData: [
                                                { scoreID: 6, title: "Never"},
                                                { scoreID: 8, title: "Rarely"},
                                                { scoreID: 10, title: "Sometimes"},
                                                { scoreID: 11, title: "Very Often"},
                                                { scoreID: 13, title: "Always"},
                                           ],
                ratingHIT6QuestionsFormData: [
                    { hitID : 1, question : "1. When you have headaches, how often is the pain severe?" },
                    { hitID : 2, question : "2. How often do headaches limit your ability to do usual daily activities including household work, work, school, or social activities?" },
                    { hitID : 3, question : "3. When you have a headache how often do you wish you could lie down?" },
                    { hitID : 4, question : "4. In the past 4 weeks, how often have you felt too tired to do work or daily activities because of your headaches?" },
                    { hitID : 5, question : "5. In the past 4 weeks, how often have you felt fed up or irritated because of your headaches?" },
                    { hitID : 6, question : "6. In the past 4 weeks, how often did headaches limit your ability to concentrate on work or daily activities?" }
                ],
                ratingHIT6SessionResponses : {},


                // ================
                // == MIDAS
                // ================
                ratingMIDASQuestionsFormData: [],   // dynamically loaded from backend
                ratingMIDASSessionResponses : {midas1: -1, midas2: -1, midas3: -1, midas4: -1, midas5: -1 },

                // ================
                // == QoL
                // ================
                
                ratingQoLQuestionsFormData: [
                    { qolID : 'q1',  answers: ratingQoLResponsesGoodnessRefData, pos: 8,   question : "1. In general, would you say your health is:" },
                    { qolID : 'q2a', answers: ratingQoLResponsesLimitedRefData,  pos: 54,  question : "2. The following questions are about activities you might do during a typical day.<br /><br />(a) Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf" },
                    { qolID : 'q2b', answers: ratingQoLResponsesTimeRefData,     pos: 8,   question : "(b) Climbing <b>several</b> flights of stairs" },
                    { qolID : 'q3a', answers: ratingQoLResponsesTimeRefData,     pos: 96,  question : "3. During the past 4 weeks, how much of the time have you had any of the following problems with your work or other regular daily activities <b>as a result of your physical health</b>?<br /><br />(a) Accomplished less than you would like" },
                    { qolID : 'q3b', answers: ratingQoLResponsesTimeRefData,     pos: 8,   question : "(b) Were limited in the kind of work or other activities" },
                    { qolID : 'q4a', answers: ratingQoLResponsesTimeRefData,     pos: 110, question : "4. During the past 4 weeks, how much of the time have you had any of the following problems with your work or other regular daily activities <b>as a result of any emotional problems</b> (such as feeling depressed or anxious)?<br /><br />(a) Accomplished less than you would like" },
                    { qolID : 'q4b', answers: ratingQoLResponsesTimeRefData,     pos: 8,   question : "(b) Did work or activities <b>less carefully than usual</b>" },
                    { qolID : 'q5',  answers: ratingQoLResponsesPainRefData,     pos: 8,   question : "5. During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?" },
                    { qolID : 'q6a', answers: ratingQoLResponsesTimeRefData,     pos: 110, question : "6. These questions are about how you feel and how things have been with you during the past 4 weeks. For each question, please give the one answer that comes closest to the way you have been feeling. How much of the time during the <b>past 4 weeks</b>...<br /><br />(a) Have you felt calm and peaceful?" },
                    { qolID : 'q6b', answers: ratingQoLResponsesTimeRefData,     pos: 8,   question : "(b) Did you have a lot of energy?" },
                    { qolID : 'q6c', answers: ratingQoLResponsesTimeRefData,     pos: 8,   question : "(c) Have you felt downhearted and depressed?" },
                    { qolID : 'q7',  answers: ratingQoLResponsesTimeRefData,     pos: 8,   question : "7. During the past 4 weeks, how much of the time has your physical health or emotional problemsinterfered with your social activities (like visiting friends, relatives, etc.)?" },
                ],
                ratingQoLSessionResponses : {},


                // ================
                // == TSUI
                // ================
                ratingTSUIQuestionsFormData: [
                    { tsuiID : 'chinRotationScore',            answers: ratingTSUIResponsesAngleRefData,      shade: 0, question : "Chin Rotation:" },
                    { tsuiID : 'chinRotationDirection',        answers: ratingTSUIResponsesLeftRightRefData,  shade: 0, question : "" },
                    { tsuiID : 'headTiltScore',                answers: ratingTSUIResponsesAngleRefData,      shade: 1, question : "Head Tilt:" },
                    { tsuiID : 'headTiltDirection',            answers: ratingTSUIResponsesLeftRightRefData,  shade: 1, question : "" },
                    { tsuiID : 'anterocollisRetrocollisScore', answers: [{ scoreID: 0, title: "Absent"},
                                                                        { scoreID: 1, title: "Mild"},
                                                                        { scoreID: 2, title: "Moderate"},
                                                                        { scoreID: 3, title: "Severe"}], 
                                                                                                             shade: 0, question : "Anterocollis/Retrocollis:" },
                    { tsuiID : 'anterocollisRetrocollisType',  answers: [{ scoreID: "Anterocollis", title: "Anterocollis"},
                                                                        { scoreID: "Retrocollis", title: "Retrocollis"},
                                                                        ],                                   shade: 0, question : "" },
                    { tsuiID : 'duration',                     answers: [{ scoreID: 1, title: "Intermittent (<75%)", tooltip: "Intermittent (<75% of the time)"},
                                                                        { scoreID: 2, title: "Constant"},
                                                                        ],                                   shade: 1, question : "Duration:" },
                    { tsuiID : 'shoulderElevationScore',       answers: [{ scoreID: 0, title: "Absent"},
                                                                        { scoreID: 1, title: "Mild & Intermittent"},
                                                                        { scoreID: 2, title: "Mild & Constant"},
                                                                        { scoreID: 2.001, title: "Moderate"},
                                                                        { scoreID: 2.002, title: "Severe & Intermittent"},
                                                                        { scoreID: 3, title: "Severe & Constant"}],
                                                                                                             shade: 0, question : "Sholder Elevation:" },
                    { tsuiID : 'shoulderElevationDirection',   answers: ratingTSUIResponsesLeftRightRefData, shade: 0, question : "" },
                    { tsuiID : 'tremorSeverity',               answers: [{ scoreID: 0, title: "Absent"},
                                                                        { scoreID: 1, title: "Mild"},
                                                                        { scoreID: 1.5, title: "Moderate"},
                                                                        { scoreID: 3, title: "Severe"}],
                                                                                                             shade: 1, question : "Tremor Severity:" },
                    { tsuiID : 'tremorDuration',               answers: [{ scoreID: 0, title: "Absent"},
                                                                        { scoreID: 1, title: "Occassional (<75%)", tooltip: "Occassional (<75% of the time)"},
                                                                        { scoreID: 2, title: "Constant"},
                                                                        ],
                                                                                                             shade: 0, question : "Tremor Duration:" },
                ],
                ratingTSUISessionResponses : {},

                // ================
                // == Eye
                // ================

                ratingEyeQuestionsFormData: [
                    { eyeID : 'bsdiReadingScore',             answers: ratingEyeResponsesImpairmentRefData,  question : "Reading:" },
                    { eyeID : 'bsdiDrivingScore',             answers: ratingEyeResponsesImpairmentRefData,  question : "Driving a vehicle:" },
                    { eyeID : 'bsdiWatchingTvScore',          answers: ratingEyeResponsesImpairmentRefData,  question : "Watching TV:" },
                    { eyeID : 'bsdiShoppingScore',            answers: ratingEyeResponsesImpairmentRefData,  question : "Shopping:" },
                    { eyeID : 'bsdiEverydayActivitiesScore',  answers: ratingEyeResponsesImpairmentRefData,  question : "Doing everyday activities:" },
                    { eyeID : 'bsdiWalkingScore',             answers: ratingEyeResponsesImpairmentRefData,  question : "Getting about on foot (walking):" },
                ],
                ratingEye2QuestionsFormData: [
                    { eyeID : 'jrsSeverityScore',             answers: [{ scoreID: 0, title: "None"},
                                                                        { scoreID: 1, title: "Minimal",         tooltip: "Minimal, increased blinking present only with external stimuli (e.g. bright light, wind, reading, driving, etc)" },
                                                                        { scoreID: 2, title: "Mild",            tooltip: "Mild, but spontaneous eyelid fluttering (without actual spasm), definitely noticeable, possibly embarrassing, but not functionally disabling" },
                                                                        { scoreID: 3, title: "Moderate",        tooltip: "Moderate, very noticeable spasm of eyelids only, mildly incapacitating" },
                                                                        { scoreID: 4, title: "Severe",          tooltip: "Severe, incapacitating spasm of eyelids and possibly other facial muscles" }
                                                                        ],
                                                                                                             question : "Blepharospasm Severity:" },
                    { eyeID : 'jrsFrequencyScore',            answers: [{ scoreID: 0, title: "None"},
                                                                        { scoreID: 1, title: "Slightly Increased",         tooltip: "Slightly increased frequency of blinking" },
                                                                        { scoreID: 2, title: "Eyelid fluttering (<1m)",  tooltip: "Eyelid fluttering lasting less than 1 minute in duration" },
                                                                        { scoreID: 3, title: "Eyelid spasm (>1s)",       tooltip: "Eyelid spasm lasting more than 1 second, but eyes open more than 50% of the waking time" },
                                                                        { scoreID: 4, title: "Functionally 'blind'",       tooltip: "Functionally 'blind' due to persistent closure (blepharospasm more than 50% of the waking time)" }
                                                                        ],
                                                                                                             question : "Blepharospasm Frequency:" },

                ],

                ratingEyeSessionResponses : {},

                companionURLForMediaUpload: '',



                swiperOption: {
                    effect: 'coverflow',
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    coverflowEffect: {
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows : true
                    },
                    pagination: {
                        el: '.swiper-pagination'
                    },
                    keyboard: {
                        enabled: true
                    }
                },
                
                responsiveCervicalDystoniaTypes : { xl: 12,   lg: 12,   md: 24,  sm: 24,  xs: 24  },

                responsiveHPILeft :              { xl: 6,   lg: 6,   md: 24,  sm: 24,  xs: 24  },
                responsiveHPIRight :             { xl: 18,  lg: 18,  md: 24,  sm: 24,  xs: 24  },

                responsiveExaminationLeft :      { xl: 4,   lg: 4,   md: 24,  sm: 24,  xs: 24  },
                responsiveExaminationCenter :    { xl: 10,  lg: 10,  md: 24,  sm: 24,  xs: 24  },
                responsiveExaminationRight :     { xl: 10,  lg: 10,  md: 24,  sm: 24,  xs: 24  },
                
                responsiveBnSELeft :             { xl: 6,   lg: 6,   md: 24,  sm: 24,  xs: 24  },
                responsiveBnSECenter :           { xl: 9,   lg: 9,   md: 24,  sm: 24,  xs: 24  },
                responsiveBnSERight :            { xl: 9,   lg: 9,   md: 24,  sm: 24,  xs: 24  },
                
                responsiveRatingsLeft :          { xl: 5,   lg: 5,   md: 24,  sm: 24,  xs: 24  },
                responsiveRatingsRight :         { xl: 19,  lg: 19,  md: 24,  sm: 24,  xs: 24  },

            }
        },
    computed: {
        currentSelectedPatientDetails()   { return this.$store.state.currentSelectedPatientDetails },
        practiceSupportsCoPayments()      { return this.$store.state.loggedInPracticeDetails.supportsCoPayments },


        // returns the sessions list, but excluding the current session being edited, as this will confuse the user with bad UX - for HPI and Examination Views.
        patientSessionsSummaryDataMinusCurrentSession() { return this.patientSessionsSummaryData.filter(entry => entry.sessionId != this.$store.state.currentSessionID ) },

        currentSessionExaminationAttachmentsImagesOnlyData() {
            var imageOnlyAttachments = this.currentSessionExaminationAttachmentsData.filter(entry => {
                var fileExtension = entry.attachmentName.split('.').pop().toLowerCase(); // get file extension
                
                entry['signedAttachmentURL'] = ''

                if (['mp4', 'm4v', 'mov'].includes(fileExtension)) {
                    return false
                } else {
                    return true
                }
            })

            imageOnlyAttachments.map(entry => {
                itBnTx.getSignedURL({ inURL : entry.attachmentS3Key })
                      .then(response => { 
                          entry['signedAttachmentURL'] = response.data.responseMsg
                      })
            
            })

            return imageOnlyAttachments
        },

        uploadActionURL() {
           return itBnTx.baseUrl + "/rest/user/uploadFile"
        },

        isBenefitsAndSideEffectsReadOnly() {
            return false;
            //return this.selectedPreviousSessionID != this.$store.state.currentSessionID
        },

        // ================
        // == HIT6
        // ================
        ratingHIT6TotalScore() {
            var totalScore = 0

            for (var index = 0; index < this.ratingHIT6QuestionsFormData.length; index++) {
                var score = this.ratingHIT6SessionResponses['hit6ScoreByHit'+this.ratingHIT6QuestionsFormData[index].hitID]
                if (score != undefined) {
                    totalScore += score
                }
            }
            //if (totalScore < 0) totalScore = 0

            return totalScore
        },
        ratingHIT6ScoreMessage() {
            var totalScore = this.ratingHIT6TotalScore

            if (totalScore >= 60) {         return "The patient's headaches are having a very severe impact on their life." }
            else if (totalScore >= 56) {    return "The headaches are having a substantial impact on this patient's life." }
            else if (totalScore >= 50) {    return "The headaches seem to be having some impact on this patient's life." }
            else if (totalScore <= 49) {    return "The headaches seem to be having little to no impact on this patient's life at this time. " }

            return ""
        },

        // ================
        // == MIDAS
        // ================
        ratingMidasTotalScore() {
            var totalScore = 0
            var countEmptyResponses = 0

            for (var index = 0; index < this.ratingMIDASQuestionsFormData.length; index++) {
                var score = this.ratingMIDASSessionResponses['midas' + (index + 1)]
                if (score != undefined && score != -1) {
                    totalScore += score
                } else {
                    countEmptyResponses++
                }
            }
            if (totalScore < 0) totalScore = 0

            // if all responses are 'unset', then set the total score to be a special -1, which we use in other parts of code to not set the 
            // total in the summary list data, which is used when non-undefined to call the API to load the data.
            // If this is out of sync, we try to load something which is not there and thus get an error.
            // This became an issue when we added logic to the API to not write empty data to the DB
            // as this was generating a bunch of entries with DB NULL which is 'not set' which was just rubbish.
            if (totalScore == 0 && countEmptyResponses == this.ratingMIDASQuestionsFormData.length)
                totalScore = -1

            return totalScore
        },
        ratingMidasScoreMessage() {
            var totalMidasScore = this.ratingMidasTotalScore

            if (totalMidasScore == -1 || (totalMidasScore >= 0 && totalMidasScore <= 5)) {  return 'MIDAS Grade I Little or no disability' }
            else if (totalMidasScore >= 6 && totalMidasScore <= 10) {                       return 'MIDAS Grade II Mild disability' }
            else if (totalMidasScore >= 11 && totalMidasScore <= 20) {                      return 'MIDAS Grade III Moderate disability' }
            else if (totalMidasScore >= 21) {                                               return 'MIDAS Grade IV Severe disability' }

            return ""
        },

        // ================
        // == QoL
        // ================
        ratingQoLTotalScore() {
            var totalScore = 0

            for (var index = 0; index < this.ratingQoLQuestionsFormData.length; index++) {
                var score = this.ratingQoLSessionResponses[this.ratingQoLQuestionsFormData[index].qolID + 'QolScore']
                if (score != undefined) {
                    totalScore += score
                }
            }
            //if (totalScore < 0) totalScore = 0

            return totalScore
        },

        // ================
        // == TSUI
        // ================
        ratingTSUITotalScore() {
            var totalScore = 0

            for (var index = 0; index < this.ratingTSUIQuestionsFormData.length; index++) {
                var score = this.ratingTSUISessionResponses[this.ratingTSUIQuestionsFormData[index].tsuiID]
                if (score != undefined && typeof(score) == "number") {
                    totalScore += score
                }
            }
            //if (totalScore < 0) totalScore = 0

            return totalScore
        },


        // ================
        // == Eye
        // ================
        ratingEyeBSDITotalScore() {
            var totalScore = 0
            var countEmptyResponses = 0

            for (var index = 0; index < this.ratingEyeQuestionsFormData.length; index++) {
                var score = this.ratingEyeSessionResponses[this.ratingEyeQuestionsFormData[index].eyeID]
                if (score != undefined) {
                    totalScore += score
                } else {
                    countEmptyResponses++
                }
            }
            //if (totalScore < 0) totalScore = 0

            // if all responses are 'unset', then set the total score to be a special -1, which we use in other parts of code to not set the 
            // total in the summary list data, which is used when non-undefined to call the API to load the data.
            // If this is out of sync, we try to load something which is not there and thus get an error.
            // This became an issue when we added logic to the API to not write empty data to the DB
            // as this was generating a bunch of entries with DB NULL which is 'not set' which was just rubbish.
            if (totalScore == 0 && countEmptyResponses == this.ratingEyeQuestionsFormData.length)
                totalScore = -1

            return totalScore
        },

        ratingEyeJRSTotalScore() {
            var totalScore = 0
            var countEmptyResponses = 0

            for (var index = 0; index < this.ratingEye2QuestionsFormData.length; index++) {
                var score = this.ratingEyeSessionResponses[this.ratingEye2QuestionsFormData[index].eyeID]
                if (score != undefined) {
                    totalScore += score
                } else {
                    countEmptyResponses++
                }
            }
            //if (totalScore < 0) totalScore = 0

            // if all responses are 'unset', then set the total score to be a special -1, which we use in other parts of code to not set the 
            // total in the summary list data, which is used when non-undefined to call the API to load the data.
            // If this is out of sync, we try to load something which is not there and thus get an error.
            // This became an issue when we added logic to the API to not write empty data to the DB
            // as this was generating a bunch of entries with DB NULL which is 'not set' which was just rubbish.
            if (totalScore == 0 && countEmptyResponses == this.ratingEye2QuestionsFormData.length)
                totalScore = -1

            return totalScore
        },

        ratingEyeGrandTotalScore() {
            if (this.ratingEyeBSDITotalScore == -1 && this.ratingEyeJRSTotalScore == -1)
                return -2   // special total
            
            return ((this.ratingEyeBSDITotalScore < 0) ? 0 : this.ratingEyeBSDITotalScore)
                    + 
                   ((this.ratingEyeJRSTotalScore < 0) ? 0 : this.ratingEyeJRSTotalScore)
        },

        responsiveQoLLabelWidth() {
            return screen.width < 500 ? 180 : 300
        },
    },
    methods: {
        // ================
        // == ASSESSMENT TAB - icon rendering...
        // ================
       
        // work around for no slots for tabpane
        tabPaneRenderTitleAndIcon(h, inTitle, inIconID) {
            return h('span', {}, [h('font-awesome-icon',{ props : {  icon: ['fal', inIconID],
                                                                     size: '1x',
                                                                  }
                                                        }),
                                  " " + inTitle])
        },

        tabLabelSuitability(h)               { return this.tabPaneRenderTitleAndIcon(h, "Pre-Injection Suitability", "scalpel") },
        tabLabelCervicalDystonia(h)          { return this.tabPaneRenderTitleAndIcon(h, "Condition Details", "globe") },
        tabLabelHPI(h)                       { return this.tabPaneRenderTitleAndIcon(h, "HPI", "history") },
        tabLabelExamination(h)               { return this.tabPaneRenderTitleAndIcon(h, "Examination", "paperclip") },
        tabLabelBenefitsAndSideEffects(h)    { return this.tabPaneRenderTitleAndIcon(h, "Benefits & Side-Effects", "comment-edit") },
        tabLabelScoresAndRatings(h)          { return this.tabPaneRenderTitleAndIcon(h, "Scores & Ratings", "star-half-alt") },
 
        ///////////////////////////////////

        saveDetailsBackToDB() {
            if (this.needToSyncToDB == true) {
                console.log("ABOUT TO SYNC DETAILS BACK TO DB", this.sessionDetails)

                if (this.sessionDetails.sessionId == undefined) {
                    // well if we get here, we either never loaded anything properly, or something more serious went wrong... 
                    // Either way, we can't save anything as it will generate api errors.                
                    return
                }

                this.sessionDetails.patientIsPregnant                   = this.sessionDetailsBridge.patientIsPregnant == "1" ? true : false
                this.sessionDetails.patientIsBreastFeeding              = this.sessionDetailsBridge.patientIsBreastFeeding == "1" ? true : false
                this.sessionDetails.patientIsPendingSurgery             = this.sessionDetailsBridge.patientIsPendingSurgery == "1" ? true : false
                this.sessionDetails.patientHasRecentlyUndergoneSurgery  = this.sessionDetailsBridge.patientHasRecentlyUndergoneSurgery == "1" ? true : false
                this.sessionDetails.patientIsTakingAnticoagulants       = this.sessionDetailsBridge.patientIsTakingAnticoagulants == "1" ? true : false
                this.sessionDetails.patientReceivedBTX                  = this.sessionDetailsBridge.patientReceivedBTX == "1" ? true : false

                // proxy/protocol fields for patient-condition-editor component.
                this.sessionDetails.conditionIds                        = this.sessionDetailsBridge.patientConditionIds
                this.sessionDetails.subconditions                       = this.sessionDetailsBridge.patientSubconditionIds
                
                itBnTx.updateSession({ inSessionDetails : this.sessionDetails })
                
                // save benefits to server
                // construct an array of dicts with benefits data only for benefits the user has given feedback for.
                var benefitFeedback = _.cloneDeep(this.patientBenefits)

                benefitFeedback.benefits = benefitFeedback.benefits.reduce((accum, entry) => {
                                                if (entry.giveFeedback == true) {
                                                    accum.push({
                                                                    benefitId: entry.benefitId,
                                                                    sessionBenefitDurationPeriod: "Week(s)",
                                                                    sessionBenefitLevel: entry.level
                                                                })
                                                }
                                                return accum
                                            }, [])

                // Save the Benefits to the 'current session id' OR use the selected session in the history if the user has selected a session from there.
                // Use the one the user selected in the history, as the user could have compared with previous sessions, so that session ID will possibly 
                // be out of sync with the original edit patient session ID via the Patient UI screen.
                // THIS IS IMPORTANT, otherwise DATA WILL BE LOST
                itBnTx.setSessionBenefits({       inSessionID : (this.selectedPreviousSessionID == 0) ? this.sessionDetails.sessionId : this.selectedPreviousSessionID,
                                            inSessionBenefits : benefitFeedback})

                // save side effects to server
                // construct an array of dicts with side effects data only for side effects the user has given feedback for.
                var sideeffectsFeedback = _.cloneDeep(this.patientSideEffects)

                sideeffectsFeedback.sideEffects = sideeffectsFeedback.sideEffects.reduce((accum, entry) => {
                                                if (entry.giveFeedback == true) {
                                                    accum.push({
                                                                    sideEffectId: entry.sideEffectId,
                                                                    sessionSideEffectDurationPeriod: "Week(s)",
                                                                    sessionSideEffectLevel: entry.level
                                                                })
                                                }
                                                return accum
                                            }, [])

                // Save the Benefits to the 'current session id' OR use the selected session in the history if the user has selected a session from there.
                // Use the one the user selected in the history, as the user could have compared with previous sessions, so that session ID will possibly 
                // be out of sync with the original edit patient session ID via the Patient UI screen.
                // THIS IS IMPORTANT, otherwise DATA WILL BE LOST
                itBnTx.setSessionSideEffects({  inSessionID : (this.selectedPreviousSessionID == 0) ? this.sessionDetails.sessionId : this.selectedPreviousSessionID,
                                                inSessionSideEffects : sideeffectsFeedback }) 


                //console.log("ratingHIT6SessionResponses", this.ratingHIT6SessionResponses)

                // saves the current score/rating tabs' data back to DB
                this.saveSpecificScoreNRatingDataForSessionID(this.currentlyDisplayingScoreAndRatingIndex,
                                                              this.selectedScoreNRatingPreviousSessionSummary.sessionId)
            }
        },

        handleTabClicked(inTabName) {
            console.log("TAB CLICKED: ", inTabName)

            switch(inTabName) {
                case 'suitability':             AnalyticsMgr.sendPageView('/Assessments/PreInjectionSuitability'); break
                case 'hpi':                     AnalyticsMgr.sendPageView('/Assessments/HPIs'); break
                case 'examination':             AnalyticsMgr.sendPageView('/Assessments/Examination'); break
                case 'benefits-side-effects':   AnalyticsMgr.sendPageView('/Assessments/BenefitsAndSideEffects'); break
                case 'scores-ratings':          this.trackOnScoreAndRatingShown(this.currentlyDisplayingScoreAndRatingIndex); break
            }
            
            // NOTE: this is probably optional... less chatty API calls, but means may lose data if we crash in between switching tabs say...
            //this.saveDetailsBackToDB()
        },

        checkAndLoadSessionData(inPatientIDChangedToo) {
            if (this.toLoadOnShowUI_sessionID != null) {
                this.loadDataOnChangeInSessionID(this.toLoadOnShowUI_sessionID, inPatientIDChangedToo)
                this.toLoadOnShowUI_sessionID = null
            }
        },

        visibilityChanged (isVisible, entry) {
            entry

            console.log("visibilityChanged (ASSESSMENT)", isVisible, this.toLoadOnShowUI_patientID, this.toLoadOnShowUI_sessionID)
            
            if (this.needToSyncToDB == false && isVisible == true)
                this.needToSyncToDB = true

            if (isVisible == false) {
                this.$store.commit("justUpdatingAssessmentData", true)
                this.saveDetailsBackToDB()
                this.needToSyncToDB = false
            }

            if (isVisible == true) {
                
                AnalyticsMgr.sendPageView('/Assessments')

                var patientIDChangedToo = false

                if (this.toLoadOnShowUI_patientID != null) {
                    this.loadDataOnChangeInPatientID(this.toLoadOnShowUI_patientID)
                    this.toLoadOnShowUI_patientID = null
                    patientIDChangedToo = true
                }
                
                if (patientIDChangedToo == false) {
                    this.checkAndLoadSessionData(patientIDChangedToo)
                }
console.log(">>>>>>>>>>>>>>>SETUP")
                this.$options.sockets.onmessage = (data) => {
                    console.log(data)
                    // "" 
                    this.reloadCurrentSessionAttachments()
                }

            }
        },

        // ================
        // == HPI Tab Helpers
        // ================
       
        /*
            
            NOTE: the logic of the internals of this function are essentially just copied from the old BTX code...
            It is a bit cryptic and maybe can be refactored to me more maintainable...
            For now we will just continue to use as it.
        */
        generateAssessmentPhraseForUsefulPhrase({   inPatientFirstname,
                                                    inUsefulPhraseEntry,
                                                    inSubPhraseSelection,
                                                    inGenderInfo,
                                                    inPhraseIndex} = {}) {
            var usefulPhraseTitleID = inUsefulPhraseEntry.hpiPhraseTitle
            var usefulPhraseHPIID = inUsefulPhraseEntry.hpiId
            var groupIndex = 0
            var sentencePrefix = "";
            var adverbs = ['Additionally', 'Furthermore', 'Moreover', 'In addition'];
            var sentence = ""

            _.forEach(inSubPhraseSelection, function(sub_phrases, group_key){
                var index = 0;

                _.forEach(sub_phrases, function(sub_phrase){
                    var startWithName = false;

                    if (index % 3 === 0) { //start a new sentence

                        switch(usefulPhraseHPIID) {
                            case 1:
                                sentencePrefix = usefulPhraseTitleID + " ";
                                break;
                            case 2:
                                sentencePrefix = "The " + usefulPhraseTitleID.toLowerCase() + " ";
                                break;
                            case 3:
                                sentencePrefix = inPatientFirstname + " has noticed that " + inGenderInfo.genderPossessiveAdjective + " " + usefulPhraseTitleID.toLowerCase() + " ";
                                startWithName = true;
                                break;
                            case 4:
                                sentencePrefix = inPatientFirstname + " has noticed that " + inGenderInfo.genderPossessiveAdjective + " symptoms are interfering with " + inGenderInfo.genderPossessiveAdjective + " ability to ";
                                startWithName = true;
                                break;
                            case 5:
                                sentencePrefix = "Patient ";
                                break;
                        }

                        var lowercase = false;
                        if (sentence !== '') { //not first sentence
                            if (/*differentGroup &&*/ groupIndex === 0 && index === 0) {
                                sentence += "\n";
                            } else {
                                sentence += adverbs[inPhraseIndex] + " ";
                                lowercase = true;
                            }
                        }

                        switch(group_key) { //handle special cases
                            case "complaint_b":
                                sentencePrefix = "Patient has noticed that " + inGenderInfo.genderPossessiveAdjective + " head was shaking";
                                break;
                            case "alleviated_b":
                                sentencePrefix = inPatientFirstname + " has noticed that " + inGenderInfo.genderPossessiveAdjective + " symptoms are alleviated through the use of sensory tricks such as -";
                                startWithName = true;
                                break;
                            case "interfere_a":
                                if (sub_phrase === "driving")
                                    sentencePrefix += "drive";
                                else
                                    sentencePrefix += sub_phrase.substring(0, sub_phrase.length - 3);
                                break;
                            case "interfere_b":
                                if (sub_phrase === "job")
                                    sentencePrefix = inPatientFirstname + " has noticed that " + inGenderInfo.genderPossessiveAdjective + " symptoms are interfering with " + inGenderInfo.genderPossessiveAdjective + " job performance";
                                else if (sub_phrase === "other")
                                    sentencePrefix = inPatientFirstname + " has noticed that " + inGenderInfo.genderPossessiveAdjective + " symptoms are interfering with " + inGenderInfo.genderPossessiveAdjective + " -";
                                else
                                    sentencePrefix = inPatientFirstname + " has noticed that " + inGenderInfo.genderPossessiveAdjective + " symptoms are interfering with " + inGenderInfo.genderPossessiveAdjective + " daily activities";
                                startWithName = true;
                                break;
                            case "previous_a":
                                if (sub_phrase === "other")
                                    sentencePrefix += "experienced -";
                                else {
                                    if (sub_phrases.length === 1)
                                        sentencePrefix += "experienced " + sub_phrase + " from the last treatment";
                                    else
                                        sentencePrefix += "experienced " + sub_phrase;
                                }
                                break;
                            case "previous_b":
                            case "previous_c":
                                sentencePrefix += "was " + sub_phrase + " with the result of the last treatment";
                                break;
                            default:
                                if (sub_phrase === "other")
                                    sentencePrefix += '-';
                                else
                                    sentencePrefix += sub_phrase;
                        }
                        if (lowercase && !startWithName)
                            sentencePrefix = sentencePrefix.charAt(0).toLowerCase() + sentencePrefix.slice(1);
                        sentence += sentencePrefix + ", ";
                    } else {
                        if (index % 3 === 2 || (index % 3 === 1 && index === sub_phrases.length - 1)) {
                            sentence = sentence.substring(0, sentence.length - 2) + " ";
                            if (group_key === "previous_a" && sub_phrase !== "other")
                                sentence += "and " + sub_phrase + " from the last treatment. ";
                            else if (group_key === "interfere_a")
                                sentence += "and " + sub_phrase.substring(0, sub_phrase.length - 3) + ". ";
                            else{
                                if (sub_phrase === "other")
                                    sentence += "and -. ";
                                else {
                                    if (sub_phrase === "job")
                                        sentence += "and job performance. ";
                                    else
                                        sentence += "and " + sub_phrase + ". ";
                                }
                            }
                        } else {
                            if (group_key === "interfere_a") {
                                if (sub_phrase === "driving")
                                    sentence += "drive, ";
                                else
                                    sentence += sub_phrase.substring(0, sub_phrase.length - 3) + ", ";
                            }
                            else{
                                if (sub_phrase === "other")
                                    sentence += "-, ";
                                else {
                                    if (sub_phrase === "job")
                                        sentence += "job performance, ";
                                    else
                                        sentence += sub_phrase + ", ";
                                }
                            }
                        }
                    }
                    index++;
                    sentencePrefix = "";
                });
                if (sentence.charAt(sentence.length - 2) === ",")
                    sentence = sentence.substring(0, sentence.length - 2) + ". ";
                groupIndex++;
            });

            return sentence
        },

        handleAddHPI() {
            console.log("HPI", this.patientUsefulHPIPhrasesData)

            /*
                iview framework tree component uses the following keys in the dictionary to manage the UI.

                checked: true - if entry is selected or if ALL children are checked
                indeterminante: true if has children and NOT all children checked, false otherwise
                expanded: whether the children are expanded and visible
                nodeKey: iview internal to identify nodes.

                we will use the checked and indeterminant flags.
            */
            var patientGenderData = this.genderRefData.find(entry => entry.genderCode == this.currentSelectedPatientDetails.genderCode )
            var patientFirstName = this.currentSelectedPatientDetails.firstName.charAt(0).toUpperCase() + this.currentSelectedPatientDetails.firstName.slice(1);

            if (patientGenderData == undefined) patientGenderData = { genderPossessiveAdjective : "their" }

            var phraseIndex = 0
            var fullSentences = ""

            this.patientUsefulHPIPhrasesData.map(usefulPhraseEntry => {
                var checkedHPISubPhrase = usefulPhraseEntry.hpiPhrase.filter(subPhraseEntry => { return (subPhraseEntry.checked != undefined && subPhraseEntry.checked == true) })

                if (checkedHPISubPhrase.length != 0) {
                    var subPhraseSelection = {};

                    // collect the details of subPhrase titles, and group by group identifier
                    checkedHPISubPhrase.map( subPhraseEntry => {
                        if (subPhraseSelection[subPhraseEntry.group] == undefined) subPhraseSelection[subPhraseEntry.group] = []
                        subPhraseSelection[subPhraseEntry.group].push(subPhraseEntry.title)
                    })

                    //console.log("HPI: ", subPhraseSelection)
                    fullSentences += this.generateAssessmentPhraseForUsefulPhrase({ inPatientFirstname  : patientFirstName,
                                                                                    inUsefulPhraseEntry : usefulPhraseEntry,
                                                                                    inSubPhraseSelection: subPhraseSelection,
                                                                                    inGenderInfo        : patientGenderData,
                                                                                    inPhraseIndex       : phraseIndex}) + "\n"
                    phraseIndex += 1
                }

            })


            this.insertHPITextAtInsertionPointForCurrentAssessmentHPI(fullSentences) 
            //this.sessionDetails.sessionHpiNotes += "\n" + fullSentences
            //console.log(fullSentences)

        },

        handleCopyHPIFromPrevSession() { this.insertHPITextAtInsertionPointForCurrentAssessmentHPI(this.selectedPreviousHPISessionData.sessionHpiNotes) },

        insertHPITextAtInsertionPointForCurrentAssessmentHPI(inHPITextToInsert) {
            // intelligently insert the text at the insertion point, which has the advantage we support undo/redo which is helpful to the user/UX
            // 
            // https://stackoverflow.com/questions/13597007/how-can-i-undo-the-programmatic-insertion-of-text-into-a-textarea
            //
            this.$refs.currentHPINotesTextArea.focus()
            document.execCommand('insertText', false, inHPITextToInsert);

            // this way magically sets the data... BUT does not allow UI to handle undo/redo... so we use the mechanism above.
            //this.sessionDetails.sessionHpiNotes = this.sessionDetails.sessionHpiNotes + "\n" + inHPITextToInsert
        },

        handleHPISessionSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            if (currentRow != undefined) {
                this.selectedPreviousHPISessionData = currentRow
            } else {
                this.selectedPreviousHPISessionData = {}
            }
        },


        // ================
        // == EXAMINATION Tab Helpers
        // ================
        reloadCurrentSessionAttachments() {
            itBnTx.getAllSessionAttachmentsForSession({ inSessionID : this.$store.state.currentSessionID })
                  .then(response => { this.currentSessionExaminationAttachmentsData = response.data })
        },

        handleBeforeUploadAttachment(inFile) {
            inFile

            this.uploadExaminationAttachmentData.practiseId = this.$store.state.loggedInDoctorDetails.practiseId
            this.uploadExaminationAttachmentData.userId = this.$store.state.loggedInDoctorDetails.userId
            this.uploadExaminationAttachmentData.sessionId = this.$store.state.currentSessionID
            this.uploadExaminationAttachmentData.authToken = this.$store.state.btxAuthToken

            return true
        },

        handleFormatError (file) {
            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select jpg, jpeg, png, mp4, m4v, mov.'
            });
        },
        handleMaxSize (file) {
            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 100 MB.'
            });
        },

        handleSuccess (response, file, fileList) {
            console.log(response)
            console.log(file)
            console.log(fileList)
            this.reloadCurrentSessionAttachments()
        },

        handleError (error, file, fileList) {
            console.log(error)
            console.log(file)
            console.log(fileList)
        },


        reloadPreviousSessionAttachmentsForSessionID(inSession) {
            itBnTx.getAllSessionAttachmentsForSession({ inSessionID : inSession })
                  .then(response => { this.selectedPreviousExaminationSessionAttachmentsData = response.data })
        },

        handleExaminationSessionSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow
  console.log("handleExaminationSessionSelectionChanged", this.selectedPreviousExaminationSessionData)
          
            if (currentRow != undefined) {
                this.selectedPreviousExaminationSessionData = currentRow
            } else {
                this.selectedPreviousExaminationSessionData = {}
                return
            }
            
            this.reloadPreviousSessionAttachmentsForSessionID(this.selectedPreviousExaminationSessionData.sessionId)
        },

        handleAttachmentView(inAttachmentEntryDetails, inLocation) {

            var fileExtension = inAttachmentEntryDetails.attachmentName.split('.').pop().toLowerCase(); // get file extension

            this.showDocumentAttachmentDialogMode = inLocation

            itBnTx.getSignedURL({ inURL : inAttachmentEntryDetails.attachmentS3Key })
                  .then(response => {
                
                        // Check for video types
                        if (['mp4', 'm4v', 'mov'].includes(fileExtension)) {
                            this.videoAttachmentDialogTitle = inAttachmentEntryDetails.attachmentName
                            this.videoAttachmentDialogVideoURL = response.data.responseMsg
                            this.showVideoAttachmentDialog = true
                        } else {
                            this.documentAttachmentDialogTitle = inAttachmentEntryDetails.attachmentName
                            this.documentAttachmentDialogDocumentURL = response.data.responseMsg
                            this.showDocumentAttachmentDialog = true
                        }
                    });
        },

        handleAttachmentDownload(inAttachmentEntryDetails) {
            
            // get signed URL of the attachment...
            itBnTx.getSignedURL({ inURL : inAttachmentEntryDetails.attachmentS3Key })
                  .then(response => {
                        // then create a dynamic anchor tag, and set its attributes, then simulate a click on it to download.
                        var downloadElement = document.createElement('a');

                        downloadElement.setAttribute('href', response.data.responseMsg);
                        downloadElement.setAttribute('download', inAttachmentEntryDetails.attachmentName);
                        downloadElement.setAttribute('target', "_blank");

                        downloadElement.style.display = 'none';
                        document.body.appendChild(downloadElement);

                        downloadElement.click();

                        document.body.removeChild(downloadElement);
                  })
        },

        handleCurrentSessionAttachmentDelete(inAttachmentEntryDetails) {
            itBnTx.deleteSessionAttachment({ inAttachmentID : inAttachmentEntryDetails.sessionAttachmentId })
                  .then(response => {
                      response; this.reloadCurrentSessionAttachments()
                  })
        },

        handleAttachmentDelete(inAttachmentEntryDetails) {
            itBnTx.deleteSessionAttachment({ inAttachmentID : inAttachmentEntryDetails.sessionAttachmentId })
                  .then(response => {
                      response; this.reloadPreviousSessionAttachmentsForSessionID(this.selectedPreviousExaminationSessionData.sessionId)
                  })
        },

        // ================
        // == BENEFITS AND SIDE EFFECTS Tab Helpers
        // ================

        handleBenefitNSideEffectSessionSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            // persist current settings/changes, before loading new selection data.
            this.saveDetailsBackToDB()

            // clear previously selected data, before loading new data.
            if (currentRow == null) {
                this.selectedPreviousSessionID = 0
            } else {
                this.selectedPreviousSessionID = currentRow.sessionId

                this.syncBenefitsAndSideEffectsForNewSessionID(this.selectedPreviousSessionID,
                                                                false /* load session info */,
                                                                true /* load benefits and side effects */)
            }

        },

        // ================
        // == SCORES AND RATINGS Tab Helpers
        // ================

        ratingTypeAvailableToPatient(inRatingTypeCode) {
            for (var index = 0; index < this.patientRatingTypesData.length; index++) {
                if (this.patientRatingTypesData[index].ratingTypeCode == inRatingTypeCode)
                    return true
            }

            return false
        },

        loadSpecificScoreNRatingDataForSessionID(inScoreNRatingType, inSessionID) {
            let sessionSummaryForSessionID = this.patientSessionsSummaryData
                                                 .find(entry => { return entry.sessionId == inSessionID })
            let sessionSummaryScoreForSessionID = sessionSummaryForSessionID == undefined ? undefined
                                                                                          : sessionSummaryForSessionID[this.scoresAndRatingConfig[inScoreNRatingType].summaryKey] 
            var delayedSetSnRID = undefined

            switch (inScoreNRatingType) {
                case ScoreNRating.QoL:
                    if (sessionSummaryScoreForSessionID != undefined) {
                    
                        delayedSetSnRID = setTimeout(() => {this.patientScoresNRatingsIsLoading = true}, 300)

                        itBnTx.getSessionRatingQol({ inSessionID : inSessionID })
                              .then(response => { this.ratingQoLSessionResponses = response.data })
                              .catch(error => {error; this.ratingQoLSessionResponses = {} })
                              .finally(() => { clearTimeout(delayedSetSnRID); this.patientScoresNRatingsIsLoading = false })
                    } else {
                        this.ratingQoLSessionResponses = {}
                    }
                    break;
                
                case ScoreNRating.TSUI:
                    if (sessionSummaryScoreForSessionID != undefined) {
                    
                        delayedSetSnRID = setTimeout(() => {this.patientScoresNRatingsIsLoading = true}, 300)

                        itBnTx.getSessionRatingTsui({ inSessionID : inSessionID })
                              .then(response => { this.ratingTSUISessionResponses = response.data })
                              .catch(error => {error; this.ratingTSUISessionResponses = {} })
                              .finally(() => { clearTimeout(delayedSetSnRID); this.patientScoresNRatingsIsLoading = false })
                    } else {
                        this.ratingTSUISessionResponses = {}
                    }
                    break;

                case ScoreNRating.TWSTRS:

                    break;

                case ScoreNRating.Eye:
                    if (sessionSummaryScoreForSessionID != undefined) {
                    
                        delayedSetSnRID = setTimeout(() => {this.patientScoresNRatingsIsLoading = true}, 300)

                        itBnTx.getSessionRatingEye({ inSessionID : inSessionID })
                              .then(response => { this.ratingEyeSessionResponses = response.data })
                              .catch(error => {error; this.ratingEyeSessionResponses = {} })
                              .finally(() => { clearTimeout(delayedSetSnRID); this.patientScoresNRatingsIsLoading = false })
                    } else {
                        this.ratingEyeSessionResponses = {}
                    }
                    
                    break;

                case ScoreNRating.Face:

                    break;

                case ScoreNRating.HIT6:
                    if (sessionSummaryScoreForSessionID != undefined) {
                    
                        delayedSetSnRID = setTimeout(() => {this.patientScoresNRatingsIsLoading = true}, 300)
                        
                        itBnTx.getSessionRatingHit6({ inSessionID : inSessionID })
                              .then(response => { this.ratingHIT6SessionResponses = response.data })
                              .catch(error => {error; this.ratingHIT6SessionResponses = {} })
                              .finally(() => { clearTimeout(delayedSetSnRID); this.patientScoresNRatingsIsLoading = false })
                    } else {
                        this.ratingHIT6SessionResponses = {}
                    }
                    break;

                case ScoreNRating.MIDAS:
                    if (sessionSummaryScoreForSessionID != undefined) {

                        delayedSetSnRID = setTimeout(() => {this.patientScoresNRatingsIsLoading = true}, 300)

                        itBnTx.getSessionRatingMidas({ inSessionID : inSessionID })
                              .then(response => {
                                  var responses = response.data;
                                  for (var index = 1; index <= 5; index++)
                                      if (responses['midas' + index] == undefined)
                                          responses['midas' + index] = -1
                                  this.ratingMIDASSessionResponses = responses
                              })
                              .catch(error => {error; this.ratingMIDASSessionResponses = { midas1: -1, midas2: -1, midas3: -1, midas4: -1, midas5: -1 } })
                              .finally(() => { clearTimeout(delayedSetSnRID); this.patientScoresNRatingsIsLoading = false })
                    } else {
                        this.ratingMIDASSessionResponses = { midas1: -1, midas2: -1, midas3: -1, midas4: -1, midas5: -1 }
                    }
                    break;
            }
            
        },

        saveSpecificScoreNRatingDataForSessionID(inScoreNRatingType, inSessionID) {
            var promiseResult = null

            if (inSessionID == undefined)
                return promiseResult

            if (this.patientScoresNRatingsIsLoading == true) {
                console.log("saveSpecificScoreNRatingDataForSessionID - SKIPPED... loading not finished")   // dont write stale data or we get corruptions
                return promiseResult
            }

            switch (inScoreNRatingType) {
                case ScoreNRating.QoL:
                    //console.log("WRITE QOL> ", JSON.stringify(this.ratingQoLSessionResponses))
                    promiseResult = itBnTx.updateSessionRatingQol({ inSessionID : inSessionID,
                                                                inRatingDetails : _.cloneDeep(this.ratingQoLSessionResponses) })
                    break;
                
                case ScoreNRating.TSUI:
                    //console.log("WRITE TSUI> ", JSON.stringify(this.ratingTSUISessionResponses))
                    promiseResult = itBnTx.updateSessionRatingTsui({ inSessionID : inSessionID,
                                                                 inRatingDetails : _.cloneDeep(this.ratingTSUISessionResponses) })
                    break;

                case ScoreNRating.TWSTRS:

                    break;

                case ScoreNRating.Eye:
                    //console.log("WRITE Eye> ", JSON.stringify(this.ratingEyeSessionResponses))
                    promiseResult = itBnTx.updateSessionRatingEye({ inSessionID : inSessionID,
                                                                inRatingDetails : _.cloneDeep(this.ratingEyeSessionResponses) })
                    break;

                case ScoreNRating.Face:

                    break;

                case ScoreNRating.HIT6:
                    //console.log("WRITE HIT6> ", JSON.stringify(this.ratingHIT6SessionResponses))
                    // make sure there is something to write, otherwise will generate an error in backend
                    if (Object.keys(this.ratingHIT6SessionResponses).length != 0) {
                        promiseResult = itBnTx.updateSessionRatingHit6({ inSessionID : inSessionID,
                                                                     inRatingDetails : _.cloneDeep(this.ratingHIT6SessionResponses) })
                    }
                    break;
                 
                case ScoreNRating.MIDAS:
                    var midasResultsCopy = _.cloneDeep(this.ratingMIDASSessionResponses)

                    for (var index = 1; index <= 5; index++)
                        if (midasResultsCopy['midas' + index] == -1)
                            delete midasResultsCopy['midas' + index]
                    
                    //console.log("WRITE MIDAS> ", JSON.stringify(this.midasResultsCopy))
                    promiseResult = itBnTx.updateSessionRatingMidas({ inSessionID : inSessionID,
                                                                  inRatingDetails : midasResultsCopy })
                    break;

            }
            
            return promiseResult
        },

        handleScoresNRatingsSessionSelectionChanged(currentRow, oldCurrentRow) {
            oldCurrentRow

            this.patientSessionsSummaryData.map(entry => {
                if (entry['_highlight'] != undefined)
                    delete entry['_highlight']
            })
            
            // handle situation when UI loaded but no session currently selected.
            if (currentRow == undefined) {
                this.selectedScoreNRatingPreviousSessionSummary = {}
                return
            }

            console.log("handleScoresNRatingsSessionSelectionChanged:", currentRow)
            
            if (oldCurrentRow != undefined) {
                //console.log("handleScoresNRatingsSessionSelectionChanged OLD SessionID:", oldCurrentRow.sessionId)

                this.saveSpecificScoreNRatingDataForSessionID(this.currentlyDisplayingScoreAndRatingIndex,
                                                            oldCurrentRow.sessionId)
            }

            this.selectedScoreNRatingPreviousSessionSummary = currentRow

            this.loadSpecificScoreNRatingDataForSessionID(this.currentlyDisplayingScoreAndRatingIndex,
                                                          this.selectedScoreNRatingPreviousSessionSummary.sessionId)


        },



        currentSessionHighlightClass(sessionDetails) {
            return (sessionDetails.sessionId == this.$store.state.currentSessionID) ? "currentSessionRowHighlight" : ""
        },

        /* this is used to visually correct the score for TSUI which has the 0.001, 0.002 items. */
        truncateToOneDecimalPlace(num) {
            return Math.trunc(num * 10) / 10;
        },


        // ================
        // == MISC load/save/sync Helpers
        // ================

        

        syncBenefitsAndSideEffectsForNewSessionID(inSessionIDToLoad, inLoadSessionInfo, inLoadBenefitsAndSideEffectsInfo) {
         console.log("syncBenefitsAndSideEffectsForNewSessionID", inSessionIDToLoad)   
            if (inLoadSessionInfo == true) {
                itBnTx.getSession({inSessionID : inSessionIDToLoad})
                        .then(response => {
                            this.sessionDetails = response.data; 

                            this.sessionDetailsBridge.patientIsPregnant                  = this.sessionDetails.patientIsPregnant ? "1" : "0"
                            this.sessionDetailsBridge.patientIsBreastFeeding             = this.sessionDetails.patientIsBreastFeeding ? "1" : "0"
                            this.sessionDetailsBridge.patientIsPendingSurgery            = this.sessionDetails.patientIsPendingSurgery ? "1" : "0"
                            this.sessionDetailsBridge.patientHasRecentlyUndergoneSurgery = this.sessionDetails.patientHasRecentlyUndergoneSurgery ? "1" : "0"
                            this.sessionDetailsBridge.patientIsTakingAnticoagulants      = this.sessionDetails.patientIsTakingAnticoagulants ? "1" : "0"
                            this.sessionDetailsBridge.patientReceivedBTX                 = this.sessionDetails.patientReceivedBTX ? "1" : "0"

                            // proxy/protocol fields for patient-condition-editor component.
                            this.sessionDetailsBridge.patientConditionIds                = this.sessionDetails.conditionIds != undefined ? this.sessionDetails.conditionIds : []
                            this.sessionDetailsBridge.patientSubconditionIds             = this.sessionDetails.subconditions != undefined ? this.sessionDetails.subconditions : []

                        })
                        .catch(error => { error; this.sessionDetails = {} })
            }


            if (inLoadBenefitsAndSideEffectsInfo == true) {
                // ======================
                // == LOAD BENEFITS DATA
                // ======================
                
                // clone out cached/clean set of data to our new data variable... this way all the dependant parts of UI
                // will automatically listen/bind to the properties.
                // Adding after we assign will miss out on the auto setup of two way bindings.
                //this.patientBenefits = _.cloneDeep(this.cachedMasterPatientBenefits)

                var delayedSetBenefitsID = setTimeout(() => {this.patientBenefitsIsLoading = true}, 300)

                itBnTx.getSessionBenefits({inSessionID : inSessionIDToLoad })
                        .then(responseSB => {
                            var newPatientBenefits = _.cloneDeep(responseSB.data)
                            newPatientBenefits.benefits = _.cloneDeep(this.cachedMasterPatientBenefits)

                            responseSB.data.benefits.forEach((entry) => {
                                for (var index = 0; index < newPatientBenefits.benefits.length; index++) {
                                    if (entry.benefitId == newPatientBenefits.benefits[index].benefitId) {
                                        newPatientBenefits.benefits[index].giveFeedback = true
                                        newPatientBenefits.benefits[index].level = entry.sessionBenefitLevel
                                    }
                                }
                            })

                            this.patientBenefits = newPatientBenefits   // we set the property in one go, rather than use the this.patientBenefits property and manupulate, as that would indirectly keep updating the DOM, since the whole system is dynamic... optimization.
                            clearTimeout(delayedSetBenefitsID)
                            this.patientBenefitsIsLoading = false
                        })

                // ======================
                // == LOAD SIDE EFFECTS DATA
                // ======================
                
                // clone out cached/clean set of data to our new data variable... this way all the dependant parts of UI
                // will automatically listen/bind to the properties.
                // Adding after we assign will miss out on the auto setup of two way bindings.
                //this.patientSideEffects = _.cloneDeep(this.cachedMasterPatientSideEffects)

                var delayedSetSideEffectsID = setTimeout(() => {this.patientSideEffectsIsLoading = true}, 300)

                itBnTx.getSessionSideEffects({inSessionID : inSessionIDToLoad })
                    .then(responseSSE => {
                        var newPatientSideEffects = _.cloneDeep(responseSSE.data)
                        newPatientSideEffects.sideEffects = _.cloneDeep(this.cachedMasterPatientSideEffects)

                        responseSSE.data.sideEffects.forEach((entry) => {
                            for (var index = 0; index < newPatientSideEffects.sideEffects.length; index++) {
                                if (entry.sideEffectId == newPatientSideEffects.sideEffects[index].sideEffectId) {
                                    newPatientSideEffects.sideEffects[index].giveFeedback = true
                                    newPatientSideEffects.sideEffects[index].level = entry.sessionSideEffectLevel
                                }
                            }
                        })

                        this.patientSideEffects = newPatientSideEffects   // we set the property in one go, rather than use the this.patientBenefits property and manupulate, as that would indirectly keep updating the DOM, since the whole system is dynamic... optimization.
                        clearTimeout(delayedSetSideEffectsID)
                        this.patientSideEffectsIsLoading = false
                    })
            }
        },

        highlightCurrentSessionEntryBySessionID() {
            if (this.patientSessionsSummaryData.length != 0) {
                var currentSessionEntryBySessionID = this.patientSessionsSummaryData.find(entry => { return entry.sessionId == this.$store.state.currentSessionID })
                
                if (currentSessionEntryBySessionID != undefined) {
                    currentSessionEntryBySessionID['_highlight'] = true
                    this.selectedPreviousSessionID = this.$store.state.currentSessionID
                } else {
                    // if we can't find it... just select the first session in the list
                    currentSessionEntryBySessionID = this.patientSessionsSummaryData[0]
                    currentSessionEntryBySessionID['_highlight'] = true
                    this.selectedPreviousSessionID = currentSessionEntryBySessionID.sessionId
                }

                // this odd ball thing is to force the data to re-sync with UI... for _highlight flag.
                this.patientSessionsSummaryData = _.cloneDeep(this.patientSessionsSummaryData)


                // handle the syncing of first time loading of scores and ratings for the selected session
                this.selectedScoreNRatingPreviousSessionSummary = currentSessionEntryBySessionID
                this.loadSpecificScoreNRatingDataForSessionID(this.currentlyDisplayingScoreAndRatingIndex,
                                                          this.selectedScoreNRatingPreviousSessionSummary.sessionId)
            }
        },


        loadDataOnChangeInPatientID(inPatientID) {
            this.patientID = inPatientID

            if (inPatientID != 0) {
                
                // init some stuff...
                this.selectedScoreNRatingPreviousSessionSummary = {}
                this.selectedPreviousHPISessionData = {}
                this.selectedPreviousExaminationSessionData = {}

                itBnTx.getSessionSummaryForPatient({ inPatientID : inPatientID })
                      .then(response => {
                          this.patientSessionsSummaryData = response.data;
                          this.highlightCurrentSessionEntryBySessionID()
                          this.checkAndLoadSessionData(true /* patient id changed too */)
                      })
                      .catch(error => { error; this.patientSessionsSummaryData = [] })


                // get the available rating types available for a patient, which is dependent on their condition.
                itBnTx.getRatingTypesForPatient({ inPatientID : inPatientID })
                      .then(response => {
                          this.patientRatingTypesData = response.data

                          // lets show/select the first available rating scheme the patient supports
                          for (var index = 0; index < this.scoresAndRatingConfig.length; index++) {
                              if (this.patientRatingTypesData.find(entry => { return entry.ratingTypeCode == this.scoresAndRatingConfig[index].ratingTypeCode }) != undefined) {
                                  this.currentlyDisplayingScoreAndRatingIndex = index
                                  break
                              }
                          }
                      })
                

                // ===================
                // == FETCH AND CACHE PER PATIENT USEFUL HPI PHRASES (based on their condition) = currently hardcoded list I think (was never fully finished to be dynamic.)
                // ===================
                if (this.currentSelectedPatientDetails.patientConditionIds.includes(3) == true) {    // 3 = Cervical Dystonia"
                    itBnTx.getPatientHpis({ inPatientID : inPatientID })
                        .then(response => {
                            
                            var usefulHPICopy = response.data
                            
                            // get a copy then add our needed additional attributes
                            usefulHPICopy.forEach((entry) => {
                                entry['title'] = entry['hpiPhraseTitle']
                                entry['expand'] = true
                            })
                        
                            // THEN set to our new data variable... this way all the dependant parts of UI
                            // will automatically listen/bind to the properties.
                            // Adding after we assign will miss out on the auto setup of two way bindings.
                            this.patientUsefulHPIPhrasesData = usefulHPICopy
                        })
                } else {
                    this.patientUsefulHPIPhrasesData = []
                }

                // ===================
                // == FETCH AND CACHE PER PATIENT BENEFITS (based on their condition)
                // ===================
                this.cachedMasterPatientBenefits = []
                itBnTx.getPatientBenefits({ inPatientID : inPatientID })
                    .then(response => {
                        
                        var benefitsCopy = response.data
                        
                        // get a copy then add our needed additional attributes
                        benefitsCopy.forEach((entry) => {
                            entry['giveFeedback'] = false
                            entry['level'] = 5
                        })
                    
                        // THEN set to our new data variable... this way all the dependant parts of UI
                        // will automatically listen/bind to the properties.
                        // Adding after we assign will miss out on the auto setup of two way bindings.
                        this.cachedMasterPatientBenefits = benefitsCopy
                    })

                // ===================
                // == FETCH AND CACHE PER PATIENT SIDE EFFECTS (based on their condition)
                // ===================
                this.cachedMasterPatientSideEffects = []
                itBnTx.getPatientSideEffects({ inPatientID : inPatientID})
                    .then(response => {
                        
                        var sideEffectsCopy = response.data
                        
                        // get a copy then add our needed additional attributes
                        sideEffectsCopy.forEach((entry) => {
                            entry['giveFeedback'] = false
                            entry['level'] = 5
                        })
                    
                        // THEN set to our new data variable... this way all the dependant parts of UI
                        // will automatically listen/bind to the properties.
                        // Adding after we assign will miss out on the auto setup of two way bindings.
                        this.cachedMasterPatientSideEffects = sideEffectsCopy
                    })

            } else {
                this.patientSessionsSummaryData = []
                this.selectedPreviousHPISessionData = {}
                this.selectedPreviousExaminationSessionData = {}
            }
        },

        loadDataOnChangeInSessionID(inSessionID, inPatientIDChangedToo) {
            
            if (inSessionID != 0) {

                // dont do stuff if we have already done it...
                if (inPatientIDChangedToo == false) {
                    // clear any previous highlight...
                    this.patientSessionsSummaryData.map(entry => {
                        if (entry['_highlight'] != undefined)
                            delete entry['_highlight']
                    })

                    //this.selectedPreviousSessionID = inSessionID
                    this.highlightCurrentSessionEntryBySessionID()
                }

                // var currentSessionEntryBySessionID = this.patientSessionsSummaryData.find(entry => { return entry.sessionId == this.$store.state.currentSessionID })

                // this.handleScoresNRatingsSessionSelectionChanged(currentSessionEntryBySessionID, undefined)

                /*
                    we pre-load the session info before this call... as its ready to load immediately
                    The benefits and side effects need to wait till the master cache is loaded before we can render properly.. 
                */
                this.syncBenefitsAndSideEffectsForNewSessionID(inSessionID,
                                                                true /* load session info */,
                                                                false /* load benefits and side effects */)
                setTimeout(() => {
                     this.syncBenefitsAndSideEffectsForNewSessionID(inSessionID,
                                                                false /* load session info */,
                                                                true /* load benefits and side effects */)
                }, (this.cachedMasterPatientBenefits.length == 0 && this.cachedMasterPatientSideEffects.length == 0) ? 1500 : 0)    // if the master benefits and side effects has been loaded, call straight away.

                this.reloadCurrentSessionAttachments()


                // ========================
                // Create QR code for companion app - signature
                // ========================
                itBnTx.getCompanionToken()
                    .then(response => {
                        response
                            this.companionURLForMediaUpload = itBnTx.getLocationOrigin()
                                                            + "/#/capture-media?"
                                                            + qs.stringify({
                                                                    a : response.data.companionToken,//this.$store.state.btxAuthToken response.data.companionToken,                           // obscured key: authToken
                                                                    p : this.$store.state.loggedInDoctorDetails.practiseId,     // obscured key: practiseId
                                                                    u : this.$store.state.loggedInDoctorDetails.userId,         // obscured key: userId
                                                                    s : this.$store.state.currentSessionID,                     // obscured key: sessionId
                                                                    n : this.currentSelectedPatientDetails.firstName            // obscured key: patient name
                                                                })
                            console.log(this.companionURLForMediaUpload)
                        })
                // ========================

            } else {
                this.sessionDetails = {}
            }
        },


        syncScoreInSummaryTableForScoreNRatingType(inType, inNewScore) {
// console.log("syncScoreInSummaryTableForScoreNRatingType", inType, inNewScore)

            // // update our copy of current selection... various parts of UI use this. if we have references in JS, then this would simply point to the one below.
            // if (this.selectedScoreNRatingPreviousSessionSummary[this.scoresAndRatingConfig[inType].summaryKey] != undefined
            //     || ((inType == ScoreNRating.QoL || inType == ScoreNRating.HIT6) && inNewScore != 0)
            //     || (inType == ScoreNRating.MIDAS && inNewScore != -1)
            //     || (inType == ScoreNRating.Eye && inNewScore != -2)
            //     || ((inType == ScoreNRating.TSUI || inType == ScoreNRating.TWSTRS || inType == ScoreNRating.Face))
            //     ) {
            //     this.selectedScoreNRatingPreviousSessionSummary[this.scoresAndRatingConfig[inType].summaryKey] = inNewScore
            // } else {
            //     // remove the key/value so the UI in other parts gets the correct value.
            //     // this case is more when we have a value, but user clears everything, and we need to ensure the value is unset back to init value.
            //     if (this.selectedScoreNRatingPreviousSessionSummary[this.scoresAndRatingConfig[inType].summaryKey] != undefined)
            //         delete this.selectedScoreNRatingPreviousSessionSummary[this.scoresAndRatingConfig[inType].summaryKey]
            // }

            // find the entry in our summary array which we need to update.
            var summarySessionEntry = this.patientSessionsSummaryData.find(entry => { return entry.sessionId == this.selectedScoreNRatingPreviousSessionSummary.sessionId })
            
            // should not be not-found... just just in case, check for it.
            if (summarySessionEntry != undefined) {
                // update to new value, thus causing UI to dynamically update the table row approperiately.
                if (/* summarySessionEntry[this.scoresAndRatingConfig[inType].summaryKey] != undefined
                    || */((inType == ScoreNRating.QoL || inType == ScoreNRating.HIT6) && inNewScore != 0)
                    || (inType == ScoreNRating.MIDAS && inNewScore != -1)
                    || (inType == ScoreNRating.Eye && inNewScore != -2)
                    || ((inType == ScoreNRating.TSUI || inType == ScoreNRating.TWSTRS || inType == ScoreNRating.Face))
                    ) {
                    summarySessionEntry[this.scoresAndRatingConfig[inType].summaryKey] = inNewScore
                } else {
                    // remove the key/value so the UI in other parts gets the correct value.
                    // this case is more when we have a value, but user clears everything, and we need to ensure the value is unset back to init value.
                    if (summarySessionEntry[this.scoresAndRatingConfig[inType].summaryKey] != undefined)
                        delete summarySessionEntry[this.scoresAndRatingConfig[inType].summaryKey]
                }

                // some odd behaviour where by when updating it un-highlights the row in the table... so we just tell the framework to highlight the selection again.
                summarySessionEntry['_highlight'] = true

                // force Table Refresh to update total score.
                this.patientSessionsSummaryData = _.cloneDeep(this.patientSessionsSummaryData)
                this.selectedScoreNRatingPreviousSessionSummary = summarySessionEntry
                
            }
        },

        trackOnScoreAndRatingShown(inShowingID) {
            switch (inShowingID) {
                case ScoreNRating.QoL:      AnalyticsMgr.sendPageView('/Assessments/ScoresAndRatings/QoL'); break
                case ScoreNRating.TSUI:     AnalyticsMgr.sendPageView('/Assessments/ScoresAndRatings/TSUI'); break
                case ScoreNRating.TWSTRS:   AnalyticsMgr.sendPageView('/Assessments/ScoresAndRatings/TWSTRS'); break
                case ScoreNRating.Eye:      AnalyticsMgr.sendPageView('/Assessments/ScoresAndRatings/Eye'); break
                case ScoreNRating.Face:     AnalyticsMgr.sendPageView('/Assessments/ScoresAndRatings/Face'); break
                case ScoreNRating.HIT6:     AnalyticsMgr.sendPageView('/Assessments/ScoresAndRatings/HIT6'); break
                case ScoreNRating.MIDAS:    AnalyticsMgr.sendPageView('/Assessments/ScoresAndRatings/MIDAS'); break
            }
        },

        // ==================
        // = CHARTS
        // ==================
        handleRefreshChart() {

            // save the current tabs data back to DB
            var promiseResult = this.saveSpecificScoreNRatingDataForSessionID(this.currentlyDisplayingScoreAndRatingIndex,
                                                                              this.selectedScoreNRatingPreviousSessionSummary.sessionId)
            
            var self = this

            function refreshChart() {
                switch (self.scoresAndRatingConfig[self.currentlyDisplayingScoreAndRatingIndex].ratingTypeCode) {
                    case 'HIT-6':
                        self.$refs['chart_HIT-6'].refreshDataForUI()
                        break;
                    case 'QoL':
                        self.$refs['chart_QoL'].refreshDataForUI()
                        break;
                    default:
                        break;
                }
            }

            if (promiseResult != null) {
                promiseResult.finally(() => {
                    refreshChart()
                })
            } else {
                refreshChart()
            }
        },


    },

    watch : {
    // "propertyLevel1", "propertyLevel1.propertyLevel2"...

        'showVideoAttachmentDialog': function (newVal, oldVal) {
            newVal  // shut up lint.

            // if user is dismissing the UI... unload the resources for the video, so it stops playing.
            if (oldVal == true) {
                this.videoAttachmentDialogVideoURL = "about:blank";
                this.videoAttachmentDialogTitle = "";
            }
        },

        'showDocumentAttachmentDialog': function (newVal, oldVal) {
            newVal  // shut up lint.

            // if user is dismissing the UI... unload the resources for the video, so it stops playing.
            if (oldVal == true) {
                this.documentAttachmentDialogDocumentURL = "about:blank";
                this.documentAttachmentDialogTitle = "";
            }
        },
        
/*

    RESPONSE array of dict
        {
            "benefitId": 1,
            "benefitName": "Pain",
            "created": 1395208550934,
            "createdBy": 0
        },
        
  */
 /*

    RESPONSE array of dicts

        { "benefitId" : 2,
            "created" : 1392937034559,
            "createdBy" : 2,
            "sessionBenefitLevel" : 3,
            "sessionBenefitDuration" : 2,
            "sessionBenefitDurationPeriod" : "Week(s)",
            "sessionBenefitId" : 4,
            "sessionId" : 4
        },
*/
/*

    RESPONSE array of dict
        {
            "sideEffectId": 7,
            "sideEffectName": "Difficulty Swallowing",
            "created": 1395208550953,
            "createdBy": 0
        },
        
  */
/*

    RESPONSE array of dicts

    { "sideEffectId" : 2,
            "created" : 1392937034559,
            "createdBy" : 2,
            "sessionSideEffectLevel" : 3
            "sessionSideEffectDuration" : 2,
            "sessionSideEffectDurationPeriod" : "Week(s)",
            "sessionSideEffectId" : 4,
            "sessionId" : 4
        },
*/

        "$store.state.currentPatientID" (newValue, oldValue) { oldValue; this.toLoadOnShowUI_patientID = newValue; this.assessmentTabsCurrentSelection = 'suitability'; console.log("ASSESSMENT UI received new currentPatientID", newValue) },
        "$store.state.currentSessionID" (newValue, oldValue) { oldValue; this.toLoadOnShowUI_sessionID = newValue; console.log("ASSESSMENT UI received new currentSessionID", newValue) },

        currentlyDisplayingScoreAndRatingIndex(newValue, oldValue) {
            console.log("SAVING  >>> '" + this.scoresAndRatingConfig[oldValue].ratingTypeCode + "'")
            console.log("LOADING <<< '" + this.scoresAndRatingConfig[newValue].ratingTypeCode + "'")
            
            this.trackOnScoreAndRatingShown(newValue)

            // save the previous tabs data back to DB
            this.saveSpecificScoreNRatingDataForSessionID(oldValue,
                                                          this.selectedScoreNRatingPreviousSessionSummary.sessionId)

            // load the newly selected tab data into UI from DB
            this.loadSpecificScoreNRatingDataForSessionID(this.currentlyDisplayingScoreAndRatingIndex,
                                                          this.selectedScoreNRatingPreviousSessionSummary.sessionId)
        },

        ratingQoLTotalScore(newValue, oldValue)      { oldValue; this.syncScoreInSummaryTableForScoreNRatingType(ScoreNRating.QoL, newValue) },
        ratingTSUITotalScore(newValue, oldValue)     { oldValue; this.syncScoreInSummaryTableForScoreNRatingType(ScoreNRating.TSUI, newValue) },
        ratingEyeBSDITotalScore()                    { this.syncScoreInSummaryTableForScoreNRatingType(ScoreNRating.Eye, this.ratingEyeGrandTotalScore) },
        ratingEyeJRSTotalScore()                     { this.syncScoreInSummaryTableForScoreNRatingType(ScoreNRating.Eye, this.ratingEyeGrandTotalScore) },
        ratingHIT6TotalScore(newValue, oldValue)     { oldValue; this.syncScoreInSummaryTableForScoreNRatingType(ScoreNRating.HIT6, newValue) },
        ratingMidasTotalScore(newValue, oldValue)    { oldValue; this.syncScoreInSummaryTableForScoreNRatingType(ScoreNRating.MIDAS, newValue) },

    }

}

/*
  debugging css:

  style="border: 2px solid red;"

*/
</script>

<style scoped>

.layout {
    padding: 8px;
    height: 100%;
}

.currentSessionRowHighlight {
    font-weight: bold;
}

.ivu-form-item-content {
    position: relative;
    line-height: 20px;
    font-size: 14px;
}

.ivu-form-item {
    margin-bottom: 2px;    /* 24px */
    vertical-align: top;
    zoom: 1;
}


.preInjectionSuitabilityWarning {
    color:#C45D2F;
    font-weight:400;
    font-size:16px
}


.scoresNRatingsScoreTitle {
    color: #fbb797; /* #C45D2F */

}


.fullScreenIFrame {
  width: 100%;
  height: 100%;
  margin: -6px 0px 0px 0px;
  
  /* border: 3px solid red; */
}



.spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}




.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: 400px;
}

.swiper-slide img {
  display: block;
  width: auto;
  height: 100%;

  aspect-ratio:auto 640/480;
}

</style>

<style>

.ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
    height: 32px;
    padding-bottom: 5px;
    background: #4ceafb !important;
    transform: translateZ(0);
    border-color: #dcdee2;
    color: #334155;
}



</style>