/*
    This file represents the assistive intro help guide for various sections of the UI.

    It is defined in sections, allowing individual sections to be run, or they can be appended together to make a 
    different set of animated sequences.

    In theory new features can also use this mechanism to highlight to the user.

    https://introjs.com/docs/examples/basic/json-config
*/

export default {

noHelpForThisSection() {
    return [
        {
            title:    "Oops",
            intro:    "Sorry there is currently no help for this screen.",
        }
    ]
},
    

introHelpLocation() {
    return [
        {
            element:  document.querySelector("#side_nav_helpmenu"),
            position: "top", 
            title:    "Help",
            intro:    "You can always access help like this from here.",
        }
    ]
},

mainNavIntro() {
    return [
        {
            element:  document.querySelector("#side_nav_patient_management"),
            position: "right", 
            title:    "Patients",
            intro:    "Everything you need to manage patients, sessions and their injections can be found here.",
        },
        {
            element:  document.querySelector("#side_nav_administration"),
            position: "right", 
            title:    "Practice Admin",
            intro:    "Manage your practice, referrers, patients and billing here.",
        },

    ]
},


patientsMainUIIntros() {
    return [
        {
            element:  document.querySelector("#patient_main_patient_list"),
            position: "auto", 
            title:    "Active Patients",
            intro:    "Locate all your active patients here.",
        },
        {
            element:  document.querySelector("#patient_main_patient_list_search"),
            position: "auto", 
            title:    "Search Patients",
            intro:    "Search your patients by name for even quicker access.",
        },
        {
            element:  document.querySelector("#patient_main_add_edit_patient"),
            position: "auto", 
            title:    "Add Patient",
            intro:    "Add new patients or edit and update a patient’s details such as conditions and referrer physician.",
        },
        {
            element:  document.querySelector("#patient_main_patient_sessions"),
            position: "auto", 
            title:    "Patient Sessions",
            intro:    "To get started, view sessions of a patient here.",
        },
        {
            element:  document.querySelector("#patient_main_assessments_summary"),
            position: "auto", 
            title:    "Scores & Ratings",
            intro:    "View score summary of a patient’s assessments from a specific session.",
        },
        {
            element:  document.querySelector("#patient_main_injection_summary"),
            position: "auto", 
            title:    "Injections",
            intro:    "Have a quick look at a summary of injections included in a specific session.",
        },
        {
            element:  document.querySelector("#patient_main_add_edit_delete_session"),
            position: "auto", 
            title:    "Start Session",
            intro:    "Let's <b>begin</b> a session now!<br /><br />Click 'New Session' or edit an existing session by clicking 'Edit Session' or by double clicking a line item.",
        },
    ]
},

injectionsMainUIIntros() {
    return [
        {
            element:  document.querySelector("#injections_main_medication_preparation"),
            position: "auto", 
            title:    "Medication",
            intro:    "This is where it all begins!<br /><br /><ol style='margin-left:16px'><li>Select a medication, amount and dilution</li><li>Optionally enter the batch number and expiry</li><li>Adjust the site amount as needed, and whether EMG was used</li></ol><br />Then start injecting!",
        },
        {
            element:  document.querySelector("#injections_main_muscle_navigation"),
            position: "auto", 
            title:    "Muscle Navigation",
            intro:    "Pick a body region.<br />Select a view.<br />Jump to a layer/depth.<br />Select a muscle to inject.<br /><br />TIP: The muscle list shows muscles in the current view in <b>bold</b>, and then all muscles in the entire body that are available afterwards. Selecting any muscle will automatically jump to that view.",
        },
        {
            element:  document.querySelector("#injectionNavigation"),
            position: "auto", 
            title:    "Tools Palette",
            intro:    "This little gem not only allows you to navigate easily between views and layers, it also allows undo and redo (when applicable) of injections you clicked/recorded by mistake.<br /><br />You can also toggle (when applicable) previous session injections, to assist in seeing where a previous injection was made to appropriately inject in the current session.<br /><br />Oh and there is just one last thing.<br />The info button is a doorway to a wealth of information including (where applicatble):<ul style='margin-left:16px'><li>Surface Anatomy Information</li><li>Cadaver Videos</li><li>EMG Videos</li><li>3D Models</li></ul>",
        },
        {
            element:  document.querySelector("#injections_main_injection_summary_thumbnails"),
            position: "auto", 
            title:    "Injection Summary",
            intro:    "Quickly identify which regions were injected into.<br /><br />View current injections in green, any previous session injections in blue, and EMG injections as triangles.",
        },
        {
            element:  document.querySelector("#injectionViewContainer"),
            position: "auto", 
            title:    "Injection View",
            intro:    "This is where the fun begins.<br /><br /><ul style='margin-left:16px'><li>Point and click a muscle to select it.</li><li>Point and click a selcted muscle to inject.</li><li>Click on an injection point to optionally delete it.</li></ul><br />That's it! It's that easy!",
        },
        {
            element:  document.querySelector("#injections_main_injection_protocol"),
            position: "right", 
            title:    "Injection Protocol",
            intro:    "Ever wish a group/pattern of injections which takes time to record could be entered with a click of a button?<br /><br />Your wish has come true!<br /><br />Simply select an injection protocol, click the Inject button, and bingo you have just saved yourself 10 minutes of your day!<br /><br />Where do Injection Protocols come from you ask?<br />You can create your own from your current session. Simply select create injection protocol, specify a name, assign a condition, save, and your done.<br /><br />It could not be easier.",
        },
        {
            element:  document.querySelector("#injections_main_current_session_injections"),
            position: "auto", 
            title:    "Current Injections",
            intro:    "Quickly view a summary of what, how much and where injections were made in the patients current editing session.<br /><br />TIP: select a muscle to quickly view in the injection view the specific muscle and location(s) of the injection(s)",
        },
        {
            element:  document.querySelector("#injections_main_previous_sessions_and_injections"),
            position: "auto", 
            title:    "Previous Sessions",
            intro:    "View a patients previous sessions, as well as injection(s). Select a muscle to view where the injection(s) were made.<br /><br />TIP: Duplicate a previous sessions' injections to save more time in your day.",
        },
    ]
},





}