<template>
  <div >
    <!-- <Layout>
      <Header></Header>
      <Content> -->
        <Row justify="center" style="margin-top:100px"> 
            <img src="@/assets/app_logo.svg" style="width:200px;padding:16px;"/>
            
            <Card style="width: 320px;">
                <span v-if="id=='exp-cfg-link'">The link you tried to view has now expired. Please request your clinic to send you a new link.</span>
                <!-- <span v-else-if="cameFrom=='patient'">Thank you for configuring your Patient Payment Method.</span> -->
            </Card>
        </Row>
      <!-- </Content>
      <Footer><i-copyright /></Footer>
    </Layout> -->
  </div>
</template>

<script>

//const {CONFIG} = require('@/js/bntx-config')
// import AnalyticsMgr from '@/js/AnalyticsManager.js';
// const {itBnTx} = require('@/js/itBnTx')

// import Vue from 'vue'




export default {
    name: 'Notice',
    props: {

    },

    created () {

    // AnalyticsMgr.sendPageView('/LoginScreen')
    },
    mounted() {
        window.name = 'bntx-notice';

        /*
            https://inject.bntxinteract.com/#/subscription?checkout=success&session_id=SESSIONID
            https://inject.bntxinteract.com/#/subscription?checkout=cancelled
            https://inject.bntxinteract.com/#/subscription?from=customerportal
        */
        if (this.$route.query.id != undefined) {
            this.id = this.$route.query.id
        } else {
            // should not browser here by itself, if we do, redirect to login screen
            this.$router.push({name:"login"});
        }
    },

    data () {
        return {
            id : ''

        }
    },

    computed: {

    },

    methods: {

    },

    watch: {



    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
