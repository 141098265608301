<template>
    <!-- tailwindcss class="w-full bg-white text-center" -->
    <div class="" style="height: 300px; width: 100%; background-color: rgba(255, 255, 255, 1); text-align: center;">
        <div>Sign your signature in the box below.</div>

        <!-- tailwindcss class="bg-gray-100 w-full shadow-lg" -->
        <vueSignature class="" style="background-color: rgba(243, 244, 246, 1); width: 100%;" ref="signature" :sigOption="sigOption"></vueSignature> 
        
        <!-- tailwindcss class="flex p-4 space-x-5 justify-center text-lg" -->
        <div class="" style="display: flex; padding: 1rem; justify-content: center; font-size: 1.125rem; line-height: 1.75rem;">

            <!-- tailwindcss class="w-20 bg-gray-500 hover:bg-gray-600 rounded-lg shadow-md py-2 px-4 cursor-pointer text-white text-center" -->
            <div class="" 
                style="margin-right: 16px; width: 5rem; background-color: rgba(107, 114, 128, 1); border-radius: 0.5rem;padding-top: 0.5rem; padding-bottom: 0.5rem; padding-left: 1rem; padding-right: 1rem; cursor: pointer;color: rgba(255, 255, 255, 1);text-align: center;" @click="clear">Clear</div>

            <!-- tailwindcss class="w-20 bg-gray-500 hover:bg-gray-600 rounded-lg shadow-md py-2 px-4 cursor-pointer text-white text-center" -->
            <div class="" 
                style="margin-right: 16px; width: 5rem; background-color: rgba(107, 114, 128, 1); border-radius: 0.5rem;padding-top: 0.5rem; padding-bottom: 0.5rem; padding-left: 1rem; padding-right: 1rem; cursor: pointer;color: rgba(255, 255, 255, 1);text-align: center;" @click="undo">Undo</div>

            <!-- tailwindcss class="w-52 bg-green-500 hover:bg-green-600 rounded-lg shadow-md py-2 px-4 cursor-pointer text-white text-center" -->
            <div class=""
                style="width: 13rem; background-color: rgba(16, 185, 129, 1); border-radius: 0.5rem;padding-top: 0.5rem; padding-bottom: 0.5rem; padding-left: 1rem; padding-right: 1rem; cursor: pointer;color: rgba(255, 255, 255, 1);text-align: center;" @click="save">
                <span v-if="!uploadInProgress">Save</span>
                <span v-else>Saving... please wait.</span>
            </div>
        </div>
        <!-- <Row :style="{ height: 'calc(100% - 55px)', width: '100%', 'margin-bottom': '10px' }">
            <vueSignature ref="signature" :sigOption="sigOption"></vueSignature> 
        </Row> -->
        <!-- <Row type="flex" justify="space-around">
            <Col><Button @click="clear">Clear</Button></Col>
            <Col><Button @click="undo">Undo</Button></Col>
            <Col>
                <Button type="primary"
                        style="width: 100%;"
                        :loading="uploadInProgress"
                        @click="save">
                    <span v-if="!uploadInProgress">Save</span>
                    <span v-else>Saving... please wait.</span>
                </Button>
            </Col>
        </Row> -->
    </div>
</template>

<script>

//import CONFIG from '@/js/config.json';
//const {CONFIG} = require('@/js/bntx-config')
import AnalyticsMgr from '@/js/AnalyticsManager.js';
const {itBnTx} = require('@/js/itBnTx')


// https://github.com/WangShayne/vue-signature
import vueSignature from "vue-signature"

const axios = require('axios');


export default {
    name: 'CaptureSignature',
    props: {

    },

    components: {
        vueSignature,
    },

    created () {

        AnalyticsMgr.sendPageView('/CaptureSignature')
    },
    mounted() {

        var queryParams = this.$route.query

        if (queryParams.a != undefined) { 
            this.authToken = queryParams.a;
            
            this.$connect(itBnTx.baseWebSocketURL + '/rest/ws/notify?t=' + this.authToken, {
                reconnection: true, // (Boolean) whether to reconnect automatically (false)
                reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
                reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
            })
        } else {
            this.$router.push({name:"login"});
        }

        if (queryParams.p != undefined) { this.practiseId = queryParams.p;  } else { this.$router.push({name:"login"}); }
        if (queryParams.u != undefined) { this.userId = queryParams.u;      } else { this.$router.push({name:"login"}); }

    },

    data () {
        return {
           authToken : '',
           practiseId : '',
           userId : '',

           sigOption : {
				penColor:"rgb(0, 0, 0)",
				backgroundColor:"rgb(255,255,255, 0)"
			},

           uploadInProgress : false,
        }
    },

    computed: {
        uploadActionURL() {
            return itBnTx.baseUrl + "/rest/user/uploadFile"
        },
    },

    methods: {
        // https://stackoverflow.com/questions/26667820/upload-a-base64-encoded-image-using-formdata
        DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], { type: mimeString })
        },

       //async 
       save() {
            if (this.uploadInProgress == true)
                return; // dont do it twice.

			var png_data_uri = this.$refs.signature.save()
			
            const formData = new FormData();

            formData.append('authToken', this.authToken);
            formData.append('practiseId', this.practiseId);
            formData.append('userId', this.userId);
            formData.append('uploadType', 'Signature');

            const file = this.DataURIToBlob(png_data_uri)
            formData.append('uploadedFile', file, 'signature.png') 

            this.uploadInProgress = true

            //const res = await 
            axios.post(this.uploadActionURL, formData, {
                    'Content-Type': 'multipart/form-data'
                })
                .then(response => {
                    console.log(response);
                    this.$Message.success('Uploaded.');

                    AnalyticsMgr.sendEvent('/CaptureSignature', 'api', 'CaptureMedia_CaptureSignature_Success')

                    try {
                        this.$socket.send('{"cmd":"USIG"}')
                    } catch(error) {
                        console.log(error)  
                    }
                })
                .catch(error => {
                    console.log(error);

                    AnalyticsMgr.sendEvent('/CaptureSignature', 'api', 'CaptureMedia_CaptureSignature_Failed')

                    this.$Message.error('ERROR uploading.');
                })
                .then(() => {
                    // always executed
                    this.uploadInProgress = false
                });  

            // console.log(res.data.files); // 'yinyang.png': an extremely long binary string

            // console.log(res.data.form); // form: { id: '1', string: 'Text we want to add to the submit' }

            // console.log(res.data.headers); 
		},
		clear() { this.$refs.signature.clear(); },
		undo() { this.$refs.signature.undo(); },

		addWaterMark(){
			this.$refs.signature.addWaterMark({
				text:"mark text",          // watermark text, > default ''
				font:"20px Arial",         // mark font, > default '20px sans-serif'
				style:'all',               // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill		
				fillStyle:"red",           // fillcolor, > default '#333' 
				strokeStyle:"blue",	   // strokecolor, > default '#333'	
				x:100,                     // fill positionX, > default 20
				y:200,                     // fill positionY, > default 20				
				sx:100,                    // stroke positionX, > default 40
				sy:200                     // stroke positionY, > default 40
			});
		},

    },

    watch: {
   
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* https://stackoverflow.com/questions/1719452/how-to-make-a-div-always-full-screen */
.container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /* background: rgba(51,51,51,0.5); */
    z-index: 10;
  }

</style>
