


let BnTxError = {
    serviceNotFoundException                  : 1000,     // (HTTP 500) Required service not found
    neuroServiceException                     : 1001,     // (HTTP 500) An internal error occurred in the Neuro Service layer
    reportServiceException                    : 1002,     // (HTTP 500) An internal error occurred in the Report Service layer
    securityServiceException                  : 1003,     // (HTTP 500) An internal error occurred in the Security Service layer
    userServiceException                      : 1004,     // (HTTP 500) An internal error occurred in the User Service layer
    practiseServiceException                  : 1005,     // (HTTP 500) An internal error occurred in the Practise Service
    invalidParameterException                 : 1006,     // (HTTP 400) Invalid Parameter Exception
    notFoundException                         : 1007,     // (HTTP 400) Not Found Exception 
    userAuthenticationException               : 1008,     // (HTTP 401) User Authentication Exception
    invalidPermissionException                : 1009,     // (HTTP 401) User Authentication Exception

    alreadyExistsException                    : 1010,     // (HTTP 400) Already Exists Exception
    userNotFoundException                     : 1011,     // (HTTP 500) User not found
    accountLockedException                    : 1012,     // (HTTP 401) User account locked exception
    insufficientPermissionsException          : 1013,     // (HTTP 401) User does not have permissions for this operation
    failedToSendEmailException                : 1014,     // (HTTP 500) Failed to send the email to the user
    passwordMismatchException                 : 1015,     // (HTTP 400) User password does not match
    passwordServiceException                  : 1016,     // (HTTP 500) Password Service exception
    notNullException                          : 1017,     // (HTTP 400) Not Null Exception
    invalidAuthenticationTokenException       : 1018,     // (HTTP 401) Invalid Authentication Token
    inactiveOrDeletedStatusException          : 1019,     // (HTTP 401) Inactive or deleted status exception
    muscleDataServiceException                : 1020,     // (HTTP 500) An internal error occurred in the Muscle Data Service layer!
    passwordLengthException                   : 1021,     // (HTTP 400) Password must be between " + HashUtil.MIN_PASSWORD_LENGTH + " and " + HashUtil.MAX_PASSWORD_LENGTH + " characters
    practiseLogoLengthException               : 1022,     // (HTTP 400) Practise Logo size cannot be over " + GlobalSettings.PRACTISE_LOGO_SIZE

}


module.exports = { BnTxError };
