const axios = require("axios");

const { BnTxError } = require("./Helper/BnTxError")

const { getEnvironment, getLocationOrigin } = require("./Helper/getEnvironment");

const { initialize } = require("./Helper/initialize");
const { login } = require("./Helper/login");

//const { getMuscleImagesData } = require("./Helper/sessionServices");
const sessionServices = require("./Helper/sessionServices");
const activationCodeServices = require("./Helper/activationCodeServices");
const adminServices = require("./Helper/adminServices");
const practiseServices = require("./Helper/practiseServices");
const patientServices = require("./Helper/patientServices")
const copaymentServices = require("./Helper/copaymentServices")
const userServices = require("./Helper/userServices")
const tokenServices = require("./Helper/tokenServices")
const billingServices = require("./Helper/billingServices")
const sessionOrderServices = require("./Helper/sessionOrderServices")
const medicineStockServices = require("./Helper/medicineStockServices")
const injectMedicinesServices = require("./Helper/injectMedicinesServices")

const utilityServices = require("./Helper/utilityServices")


function sendRequestRawNoCache({
                        inMethod = 'get',
                        inURI = '/',
                        inRequireAuth = true,
                        inQueryParams = {},
                        inAdditionalHeaders = {},
                        inBodyPayloadData = '',
                        inSilenceError = false,
                        inSilenceResponseDebug = false,
                        inSendBodyContentType = 'application/json',
                        inResponseType = 'json'
                        }={}) {

    return new Promise((resolve, reject) => {
        
        function rejectAndShowError(inContext, inError) {
            if (inSilenceError == false) {
                try {
                    inContext.callbacks.error(inError)
                } catch (err) {
                    console.log("error callback threw EXCEPTION: ", err)
                }
            }

            reject(inError)
        }
    
        var headers = inAdditionalHeaders == null ? {} : inAdditionalHeaders;

        if (inRequireAuth == true) {
            
            /*
                a bit of a hack, probably should one day use oauth and grants to alter what users can call which apis
            */            
            if (this.btxAuthToken.startsWith("copay-") == true) {  // copay-JSAKLFHJLKASHFKJLAHSF
                headers["copayment-setup-nanoid"] = this.btxAuthToken.substring(6);     // strip the "copay-" portion
            } else {
                headers["x-neurointeract-authtoken"] = this.btxAuthToken;
            }
        }

        headers["content-type"] = inSendBodyContentType

        const axiosInstance = axios.create({ 
            headers,
            timeout : 60000     /* milliseconds */
         });
        
        if (this.debugLevel >= 1) {
            console.log("API Request: " + inMethod.toUpperCase() + " " + this.baseUrl + inURI);
        }

        axiosInstance
            .request(
                {
                    method: inMethod,
                    url: inURI,
                    baseURL: this.baseUrl,
                    params: inQueryParams,
                    data: inBodyPayloadData,
                    responseType: inResponseType,   /* this is the default, but added for clarity */
                }
            )
            .then(response => {
                if (this.debugLevel >= 3) {
                    if (inSilenceResponseDebug == false) {
                        console.log("API Response (no error): [" + inMethod.toUpperCase() + " " + inURI + "]", response.data);
                    } else {
                        console.log("API Response (no error): [" + inMethod.toUpperCase() + " " + inURI + "] [OUTPUT suppressed - inSilenceResponseDebug set to true]");
                    }
                }
                
                resolve({
                            data: response.data,
                            response
                        });
            })
            .catch(error => {
                if (this.debugLevel >= 2) {
                    console.log("API Response (ERROR): [" + inMethod.toUpperCase() + " " + inURI + "]", /* error.toJSON(), */ error.response);
                }

                // Look for Content-Type which could be json eg. application/json, text/x-json
                if (error.response != undefined
                    && error.response.data != null
                    && error.response.headers['content-type'].indexOf('json') > -1) {
                    //
                    // Example: {"responseMsg":"There is no sessionRatingEye found in the database","responseCode":1007}
                    //
                
                    if (error.response.data.responseCode === BnTxError.invalidAuthenticationTokenException) {
                        // nice JSON error, appears to be an expired token, so re-login and get a new one.
                        this.login( { inEmail : this.d.e, inPassword : this.d.p } )
                            .then(response => {
                                response

                                // login OK, got new auth token, so retry the original API call...
                                this.sendRequest({
                                                inMethod,
                                                inURI,
                                                inRequireAuth,
                                                inQueryParams,
                                                inAdditionalHeaders,
                                                inBodyPayloadData,
                                                inResponseType})
                                    .then(response => { resolve(response) })
                                    .catch(error => { rejectAndShowError(this, error) })

                            }).catch(error => {
                                // get here if account has been locked from elsewhere or account no longer exists or whatever.
                                
                                if (error.response != undefined) {
                                    rejectAndShowError(this, {
                                        responseCode: error.response.data.responseCode,
                                        responseMsg: error.response.data.responseMsg,
                                        error /* include the raw error object, rarely will use, but may be needed */
                                    });
                                } else {
                                    rejectAndShowError(this, {
                                        responseCode: -667,
                                        responseMsg: 'not logged in',
                                        error /* include the raw error object, rarely will use, but may be needed */
                                    });
                                }
                            })
                        
                    } else {
                        // nice JSON error, but not one like auth so just return it.
                        rejectAndShowError(this, {
                            responseCode: error.response.data.responseCode,
                            responseMsg: error.response.data.responseMsg,
                            error /* include the raw error object, rarely will use, but may be needed */
                        });
                    }
                        
                } else {
                    if (error.response == undefined) {
                        // if we get here... things are serious...
                        rejectAndShowError(this, {
                            responseCode: -666,
                            responseMsg: "You may have networking / connectivity issues.",
                            error /* include the raw error object, rarely will use, but may be needed */
                        });
                    } else {
                        // we get here when its not a backend 'nice JSON error' but a webserver error, so just return the HTTP error.
                        rejectAndShowError(this, {
                            responseCode: -1 * error.response.status,
                            responseMsg: error.response.statusText,
                            error /* include the raw error object, rarely will use, but may be needed */
                        });
                    }
                }
            });
        });
   
}

/* sendRequest With Caching Capability */
function sendRequest({
                        inMethod = 'get',
                        inURI = '/',
                        inRequireAuth = true,
                        inQueryParams = {},
                        inAdditionalHeaders = {},
                        inBodyPayloadData = '',
                        inSilenceError = false,
                        inSilenceResponseDebug = false,
                        inSendBodyContentType = 'application/json',
                        inResponseType = 'json',
                        inCacheKey = '',             /* if not set, no caching, if set, it will check cache for non-empty result, and if available will return */
                        inClearCacheKey = ''         /* if API request is successful, the cache using this key will be cleared. used for create/update/delete and marking cache as dirty */
                        }={}) {

    return new Promise((resolve, reject) => {

        if (inCacheKey != '' && this.CACHE[inCacheKey] != undefined) {

            if (this.debugLevel >= 1) {
                console.log("API Request: " + this.baseUrl + inURI + " [CACHED DETAILS RETURNED] ", this.CACHE[inCacheKey]);
            }
            
            resolve({data: this.CACHE[inCacheKey], response : null })
        } else {
            this.sendRequestRawNoCache({
                            inMethod,
                            inURI,
                            inRequireAuth,
                            inQueryParams,
                            inAdditionalHeaders,
                            inBodyPayloadData,
                            inSilenceError,
                            inSilenceResponseDebug,
                            inSendBodyContentType,
                            inResponseType
                            })
                .then(response => { 
                    this.CACHE[inCacheKey] = response.data;

                    if (inClearCacheKey != '') {
                        delete(this.CACHE[inClearCacheKey])
                    }

                    resolve(response)
                })
                .catch(error => { reject(error) })
        }
    })
}

function clearCacheForKey({ inKey } = {}) {
    if (inKey != '') {
        delete(this.CACHE[inKey])
    }
}

function clearAllCache() {
    this.CACHE = {}
}


let itBnTx = {
    environment: "",
    hubUrl: "",
    baseUrl: "",
    baseWebSocketURL: "",
    btxAuthToken: "",

    CACHE: {},
    
    debugLevel: 3,      /*  0 = off, 
                            1 = request log,
                            2 = request + errors,
                            3 = request + errors + response
                         */

    d : { e : "", p : "" },  // login details, email/password

    configs: {
        prod: {
            hubUrl:            "https://hub.bntxinteract.com",
            serviceUrl:        "https://inject.bntxinteract.com",
            websocketBaseUrl:  "wss://inject.bntxinteract.com",
        },

        test: {
            hubUrl:            "https://test-inject.bntxinteract.com",
            serviceUrl:        "https://test-inject.bntxinteract.com",
            websocketBaseUrl:  "wss://test-inject.bntxinteract.com",
        },

        dockerdev: {
            hubUrl:            "http://localhost:8081",
            serviceUrl:        "http://localhost:8081",
            websocketBaseUrl:  "ws://localhost:8081",
        },
    },

    callbacks: {
      initialize: function() { console.log('itBnTx.callbacks.initialize(); complete'); },
      authToken: function() { console.log('itBnTx.callbacks.authToken(); acquired'); },
      error: function() { console.log('itBnTx.callbacks.error(); received'); }
    },

    getEnvironment,
    getLocationOrigin,

    initialize,
    login,

    clearCacheForKey,
    clearAllCache,

    sendRequest,
    sendRequestRawNoCache,


    // ================
    // activationCodeServices
    // ================
    createSponsor                           : activationCodeServices.createSponsor,
    updateSponsor                           : activationCodeServices.updateSponsor,
    getAllSponsors                          : activationCodeServices.getAllSponsors,
    deleteSponsorWithSponsorID              : activationCodeServices.deleteSponsorWithSponsorID,

    createCampaign                          : activationCodeServices.createCampaign,
    updateCampaign                          : activationCodeServices.updateCampaign,
    getAllCampaigns                         : activationCodeServices.getAllCampaigns,
    deleteCampaignWithCampaignID            : activationCodeServices.deleteCampaignWithCampaignID,

    getActivationCodesForCampaignID         : activationCodeServices.getActivationCodesForCampaignID,


    // ================
    // adminServices
    // ================
    getAllConditions                        : adminServices.getAllConditions,

    getSideEffects                          : adminServices.getSideEffects,

    getGenders                              : adminServices.getGenders,

    pingBEURI                               : adminServices.pingBEURI,


    // ================
    // billingServices
    // ================
    getSubscriptionPlans                   : billingServices.getSubscriptionPlans,
    createCheckoutSession                  : billingServices.createCheckoutSession,
    getCustomerPortalURL                   : billingServices.getCustomerPortalURL,

    createSubscriptionUsingActivationCode  : billingServices.createSubscriptionUsingActivationCode,

    resetPracticeStripe                    : billingServices.resetPracticeStripe,
    updateTrialStartDate                   : billingServices.updateTrialStartDate,

    updateCCExpiryDate                     : billingServices.updateCCExpiryDate,


    // ================
    // userServices
    // ================
    createUser                              : userServices.createUser,
    updateUser                              : userServices.updateUser,
    deleteUser                              : userServices.deleteUser,

    switchPractise                          : userServices.switchPractise,

    createUserPref                          : userServices.createUserPref,
    getUserPref                             : userServices.getUserPref,
    updateUserPref                          : userServices.updateUserPref,
    deleteUserPref                          : userServices.deleteUserPref,

    searchUsersInPractise                   : userServices.searchUsersInPractise,

    changePassword                          : userServices.changePassword,
    resetPassword                           : userServices.resetPassword,
    validateEmail                           : userServices.validateEmail,

    resendValidateEmail                     : userServices.resendValidateEmail,
    
    // ================
    // patientServices
    // ================
    getAllPatients                          : patientServices.getAllPatients,
    getPatient                              : patientServices.getPatient,
    searchPatientsByName                    : patientServices.searchPatientsByName,
    searchPatientsInPractise                : patientServices.searchPatientsInPractise,
    
    createPatient                           : patientServices.createPatient,
    updatePatient                           : patientServices.updatePatient,
    deletePatientWithPatientID              : patientServices.deletePatientWithPatientID,

    getPatientContactDetails                : patientServices.getPatientContactDetails,
    createPatientContactDetails             : patientServices.createPatientContactDetails,
    updatePatientContactDetails             : patientServices.updatePatientContactDetails,

    getPatientBenefits                      : patientServices.getPatientBenefits,
    getPatientSideEffects                   : patientServices.getPatientSideEffects,
    getPatientHpis                          : patientServices.getPatientHpis,
    getRatingTypesForPatient                : patientServices.getRatingTypesForPatient,

    getPatientFeedbackInsights              : patientServices.getPatientFeedbackInsights,
    getPatientFeedbackSymptoms              : patientServices.getPatientFeedbackSymptoms,

    createPatientGUID                       : patientServices.createPatientGUID,

    getPatientConnections                   : patientServices.getPatientConnections,
    requestPatientConnection                : patientServices.requestPatientConnection,
    getRequestConnectionDetails             : patientServices.getRequestConnectionDetails,
    validatePatientConnection               : patientServices.validatePatientConnection,

    createPatientPref                       : patientServices.createPatientPref,
    getPatientPref                          : patientServices.getPatientPref,
    updatePatientPref                       : patientServices.updatePatientPref,
    deletePatientPref                       : patientServices.deletePatientPref,


    // ================
    // copaymentServices
    // ================
    getAllCopaymentConsents                 : copaymentServices.getAllCopaymentConsents,
    getAllCopaymentTiers                    : copaymentServices.getAllCopaymentTiers,

    getCopaymentDetailsForPatient           : copaymentServices.getCopaymentDetailsForPatient,
    getPatientPaymentConfigURL              : copaymentServices.getPatientPaymentConfigURL,
    getMobilePatientPaymentConfigURL        : copaymentServices.getMobilePatientPaymentConfigURL,

    getStripeClientSecretForCopaymentUUID   : copaymentServices.getStripeClientSecretForCopaymentUUID,

    setCopaymentTier                        : copaymentServices.setCopaymentTier,
    getPatientConsentedConsents             : copaymentServices.getPatientConsentedConsents,
    setConsent                              : copaymentServices.setConsent,

    sendPatientPaymentSetupSMS              : copaymentServices.sendPatientPaymentSetupSMS,
    validatePatientCopaymentSMSCode         : copaymentServices.validatePatientCopaymentSMSCode,
    
    // ================
    // sessionServices
    // ================
    getSessionsForPatient                   : sessionServices.getSessionsForPatient,
    getSessionsForUser                      : sessionServices.getSessionsForUser,
    getSession                              : sessionServices.getSession,

    createSession                           : sessionServices.createSession,
    updateSession                           : sessionServices.updateSession,
    deleteSessionWithSessionID              : sessionServices.deleteSessionWithSessionID,

    setSessionBenefits                      : sessionServices.setSessionBenefits,
    getSessionBenefits                      : sessionServices.getSessionBenefits,

    setSessionSideEffects                   : sessionServices.setSessionSideEffects,
    getSessionSideEffects                   : sessionServices.getSessionSideEffects,


    getSessionRatingTsui                    : sessionServices.getSessionRatingTsui,
    createSessionRatingTsui                 : sessionServices.createSessionRatingTsui,
    updateSessionRatingTsui                 : sessionServices.updateSessionRatingTsui,
    deleteSessionRatingTsui                 : sessionServices.deleteSessionRatingTsui,

    getSessionRatingTwstrs                  : sessionServices.getSessionRatingTwstrs,
    createSessionRatingTwstrs               : sessionServices.createSessionRatingTwstrs,
    updateSessionRatingTwstrs               : sessionServices.updateSessionRatingTwstrs,
    deleteSessionRatingTwstrs               : sessionServices.deleteSessionRatingTwstrs,

    getSessionRatingEye                     : sessionServices.getSessionRatingEye,
    createSessionRatingEye                  : sessionServices.createSessionRatingEye,
    updateSessionRatingEye                  : sessionServices.updateSessionRatingEye,
    deleteSessionRatingEye                  : sessionServices.deleteSessionRatingEye,

    getSessionRatingHit6                    : sessionServices.getSessionRatingHit6,
    createSessionRatingHit6                 : sessionServices.createSessionRatingHit6,
    updateSessionRatingHit6                 : sessionServices.updateSessionRatingHit6,
    deleteSessionRatingHit6                 : sessionServices.deleteSessionRatingHit6,

    getSessionRatingFace                    : sessionServices.getSessionRatingFace,
    createSessionRatingFace                 : sessionServices.createSessionRatingFace,
    updateSessionRatingFace                 : sessionServices.updateSessionRatingFace,
    deleteSessionRatingFace                 : sessionServices.deleteSessionRatingFace,
    deleteAllSessionRatingFace              : sessionServices.deleteAllSessionRatingFace,

    getSessionRatingQol                     : sessionServices.getSessionRatingQol,
    createSessionRatingQol                  : sessionServices.createSessionRatingQol,
    updateSessionRatingQol                  : sessionServices.updateSessionRatingQol,
    deleteSessionRatingQol                  : sessionServices.deleteSessionRatingQol,

    getAllMidasQuestions                    : sessionServices.getAllMidasQuestions,
    getSessionRatingMidas                   : sessionServices.getSessionRatingMidas,
    createSessionRatingMidas                : sessionServices.createSessionRatingMidas,
    updateSessionRatingMidas                : sessionServices.updateSessionRatingMidas,
    deleteSessionRatingMidas                : sessionServices.deleteSessionRatingMidas,




    getInjectionsForSession                 : sessionServices.getInjectionsForSession,
    getInjectionSummaryForSession           : sessionServices.getInjectionSummaryForSession,

    getInjection                            : sessionServices.getInjection,
    createInjection                         : sessionServices.createInjection,
    updateInjection                         : sessionServices.updateInjection,
    deleteInjection                         : sessionServices.deleteInjection,

    deleteInjectionsForSession              : sessionServices.deleteInjectionsForSession,

    getMuscleImages                         : sessionServices.getMuscleImages,
    getMuscleImagesData                     : sessionServices.getMuscleImagesData,

    getDefaultInjectionView                 : sessionServices.getDefaultInjectionView,

    createSessionReport                     : sessionServices.createSessionReport,        // <<<<<<<<<<
    downloadSessionReport                   : sessionServices.downloadSessionReport,
    emailSessionReport                      : sessionServices.emailSessionReport,



    getSessionSummaryForPatient             : sessionServices.getSessionSummaryForPatient,
    duplicateInjectionsForSession           : sessionServices.duplicateInjectionsForSession,
    getAllInjectionProtocols                : sessionServices.getAllInjectionProtocols,
    getAllSessionAttachmentsForSession      : sessionServices.getAllSessionAttachmentsForSession,
    
    getSignedURL                            : sessionServices.getSignedURL,
    
    deleteSessionAttachment                 : sessionServices.deleteSessionAttachment,

    


    // ================
    // practiceServices
    // ================
    registerPractise                        : practiseServices.registerPractise,

    createPractise                          : practiseServices.createPractise,
    getPractise                             : practiseServices.getPractise,
    getAllPractises                         : practiseServices.getAllPractises,
    updatePractise                          : practiseServices.updatePractise,
    deletePractise                          : practiseServices.deletePractise,

    getAllTreatingPhysicians                : practiseServices.getAllTreatingPhysicians,

    createIpWhiteList                       : practiseServices.createIpWhiteList,
    updateIpWhiteList                       : practiseServices.updateIpWhiteList,
    deleteIpWhiteList                       : practiseServices.deleteIpWhiteList,
    searchIpWhiteListInPractise             : practiseServices.searchIpWhiteListInPractise,

    createReferringPhysician                : practiseServices.createReferringPhysician,
    updateReferringPhysician                : practiseServices.updateReferringPhysician,
    deleteReferringPhysician                : practiseServices.deleteReferringPhysician,
    searchReferringPhysiciansInPractise     : practiseServices.searchReferringPhysiciansInPractise,
    searchReferringPhysiciansInPractiseNoCache: practiseServices.searchReferringPhysiciansInPractiseNoCache,

    createPractiseInjectionProtocol         : practiseServices.createPractiseInjectionProtocol,
    updatePractiseInjectionProtocol         : practiseServices.updatePractiseInjectionProtocol,
    deletePractiseInjectionProtocol         : practiseServices.deletePractiseInjectionProtocol,
    searchInjectionProtocolsInPractise      : practiseServices.searchInjectionProtocolsInPractise,

    createPractisePref                      : practiseServices.createPractisePref,
    getPractisePref                         : practiseServices.getPractisePref,
    updatePractisePref                      : practiseServices.updatePractisePref,
    deletePractisePref                      : practiseServices.deletePractisePref,


    // ================
    // sessionOrderServices
    // ================

    getAllSessionOrders                     : sessionOrderServices.getAllSessionOrders,
    createOrderForSession                   : sessionOrderServices.createOrderForSession,
    updateOrderForSession                   : sessionOrderServices.updateOrderForSession,
    deleteOrderForSession                   : sessionOrderServices.deleteOrderForSession,
    changeOrderStatusForSession             : sessionOrderServices.changeOrderStatusForSession,

    getAllOrderAttachmentsForOrder          : sessionOrderServices.getAllOrderAttachmentsForOrder,
    deleteOrderAttachment                   : sessionOrderServices.deleteOrderAttachment,


    // ================
    // medicineStockServices
    // ================

    updateMedicineStockForPhysician         : medicineStockServices.updateMedicineStockForPhysician,
    getMedicineStockForPhysician            : medicineStockServices.getMedicineStockForPhysician,
   
    // ================
    // injectMedicinesServices
    // ================

    getInjectMedicines                      : injectMedicinesServices.getInjectMedicines,
    

    // ================
    // tokenServices
    // ================
    getEducationToken                       : tokenServices.getEducationToken,
    getCompanionToken                       : tokenServices.getCompanionToken,


    // ================
    // utilityServices
    // ================
    gestimateUserLocationDetails            : utilityServices.gestimateUserLocationDetails,

};
  
module.exports = {
    itBnTx,
    BnTxError
};