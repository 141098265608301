

/*
    RESPONSE array of dicts:

        { "created" : 1394418579065,
            "createdBy" : 2,
            "lastSessionBenefitNotes" : "benefit notes go here",
            "lastSessionBenefitsDuration" : 2,
            "lastSessionBenefitsPeriod" : "Days",
            "lastSessionBenefitsSatisfaction" : "Very Satisfied",
            "lastSessionNoBenefits" : false,
            "lastSessionNoSideEffects" : false,
            "lastSessionSideEffectsDuration" : 3,
            "lastSessionSideEffectsNotes" : "Side effects go here",
            "lastSessionSideEffectsPeriod" : "Weeks",
            "lastSessionSideEffectsSatisfaction" : "Not bad",
            "patientHasNotUndergoneRecentSurgery" : true,
            "patientId" : 2,
            "patientIsNotBreastFeeding" : true,
            "patientIsNotPendingSurgery" : true,
            "patientIsNotPregnant" : true,
            "patientIsNotTakingAnticoagulants" : true,
            "patientReceivedBTX" : true,
            "userId" : 2,
            "sessionAssessments" : "Assessments go here",
            "sessionDate" : 1394418579065,
            "sessionHpiNotes" : "Test HPI Notes",
            "sessionId" : 1,
            "sessionRecommendations" : "I recommend the following bllah de blah",
            "injectionNotes" : "fsadfasdfsadf"
        }
*/


function getSessionsForPatient({
                        inPatientID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/patient',
                        inRequireAuth: true,
                        inQueryParams: {
                            patientId : inPatientID
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE array of dicts:

        { "created" : 1394418579065,
            "createdBy" : 2,
            "lastSessionBenefitNotes" : "benefit notes go here",
            "lastSessionBenefitsDuration" : 2,
            "lastSessionBenefitsPeriod" : "Days",
            "lastSessionBenefitsSatisfaction" : "Very Satisfied",
            "lastSessionNoBenefits" : false,
            "lastSessionNoSideEffects" : false,
            "lastSessionSideEffectsDuration" : 3,
            "lastSessionSideEffectsNotes" : "Side effects go here",
            "lastSessionSideEffectsPeriod" : "Weeks",
            "lastSessionSideEffectsSatisfaction" : "Not bad",
            "patientHasNotUndergoneRecentSurgery" : true,
            "patientId" : 2,
            "patientIsNotBreastFeeding" : true,
            "patientIsNotPendingSurgery" : true,
            "patientIsNotPregnant" : true,
            "patientIsNotTakingAnticoagulants" : true,
            "patientReceivedBTX" : true,
            "userId" : 2,
            "sessionAssessments" : "Assessments go here",
            "sessionDate" : 1394418579065,
            "sessionHpiNotes" : "Test HPI Notes",
            "sessionId" : 1,
            "sessionRecommendations" : "I recommend the following bllah de blah",
            "injectionNotes" : "fsadfasdfsadf"
        }
*/


function getSessionsForUser({
                        inUserID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/user',
                        inRequireAuth: true,
                        inQueryParams: {
                            userId : inUserID
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE

    NOTE: this is the updated payload which is used by the various session APIs... @ 7 Oct 2021

        {
            conditionIds: [5]
            created: 1534303338912
            createdBy: 1
            lastSessionBenefitNotes: ""
            lastSessionBenefitsDuration: 2
            lastSessionBenefitsPeriod: ""
            lastSessionBenefitsSatisfaction: ""
            lastSessionNoBenefits: false
            lastSessionNoSideEffects: false
            lastSessionSideEffectsNotes: ""
            lastSessionSideEffectsPeriod: ""
            lastSessionSideEffectsSatisfaction: ""
            modified: 1630380765049
            modifiedBy: 63
            notesForReport: ""
            noticedBenefitsWearingOffDuration: 10
            noticedBenefitsWearingOffPeriod: ""
            noticedSideEffectsWearingOffPeriod: ""
            patientConsentsToInjection: false
            patientHasRecentlyUndergoneSurgery: false
            patientId: 28703
            patientIsBreastFeeding: true
            patientIsPendingSurgery: false
            patientIsPregnant: false
            patientIsTakingAnticoagulants: false
            patientReceivedBTX: false
            referringPhysicianId: 3148
            sessionAssessments: ""
            sessionDate: 1534303338000
            sessionHpiNotes: "today I ned\nwhat can I do\nwhat can I do"
            sessionId: 1247
            sessionRecommendations: "vdvsdfgsdfg\ngsdfgsdf\nsdfgsd"
            userId: 418
        }
    NOTE: version=2 API no longer has benefits and side effects fields:
*/


function getSession({
                        inSessionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID,
                        inRequireAuth: true,
                        inQueryParams: {
                            version: 2
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

    { 
        "lastSessionBenefitNotes" : "benefit notes go here",
        "lastSessionBenefitsDuration" : 2,
        "lastSessionBenefitsPeriod" : "Days",
        "lastSessionBenefitsSatisfaction" : "Very Satisfied",
        "lastSessionNoBenefits" : false,
        "lastSessionNoSideEffects" : false,
        "lastSessionSideEffectsDuration" : 3,
        "lastSessionSideEffectsNotes" : "Side effects go here",
        "lastSessionSideEffectsPeriod" : "Weeks",
        "lastSessionSideEffectsSatisfaction" : "Not bad",
        "patientHasNotUndergoneRecentSurgery" : true,
        "patientId" : 2,
        "patientIsNotBreastFeeding" : true,
        "patientIsNotPendingSurgery" : true,
        "patientIsNotPregnant" : true,
        "patientIsNotTakingAnticoagulants" : true,
        "patientReceivedBTX" : true,
        "userId" : 2,
        "sessionAssessments" : "Assessments go here",
        "sessionDate" : 1393884938727,
        "sessionHpiNotes" : "Test HPI Notes",
        "sessionRecommendations" : "I recommend the following bllah de blah",
        "injectionNotes" : "fsadfasdfsadf"
        }

    RESPONSE

        { "created" : 1394418579065,
            "createdBy" : 2,
            "lastSessionBenefitNotes" : "benefit notes go here",
            "lastSessionBenefitsDuration" : 2,
            "lastSessionBenefitsPeriod" : "Days",
            "lastSessionBenefitsSatisfaction" : "Very Satisfied",
            "lastSessionNoBenefits" : false,
            "lastSessionNoSideEffects" : false,
            "lastSessionSideEffectsDuration" : 3,
            "lastSessionSideEffectsNotes" : "Side effects go here",
            "lastSessionSideEffectsPeriod" : "Weeks",
            "lastSessionSideEffectsSatisfaction" : "Not bad",
            "patientHasNotUndergoneRecentSurgery" : true,
            "patientId" : 2,
            "patientIsNotBreastFeeding" : true,
            "patientIsNotPendingSurgery" : true,
            "patientIsNotPregnant" : true,
            "patientIsNotTakingAnticoagulants" : true,
            "patientReceivedBTX" : true,
            "userId" : 2,
            "sessionAssessments" : "Assessments go here",
            "sessionDate" : 1394418579065,
            "sessionHpiNotes" : "Test HPI Notes",
            "sessionId" : 1,
            "sessionRecommendations" : "I recommend the following bllah de blah",
            "injectionNotes" : "fsadfasdfsadf"
        }
    NOTE: version=2 API no longer has benefits and side effects fields:
*/


function createSession({
                        inNewSessionDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session',
                        inRequireAuth: true,
                        inQueryParams: {
                            version: 2
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inNewSessionDetails)
                        });
}


/*
    REQUEST

    { "created" : 1394418579065,
            "createdBy" : 2,
            "lastSessionBenefitNotes" : "benefit notes go here",
            "lastSessionBenefitsDuration" : 2,
            "lastSessionBenefitsPeriod" : "Days",
            "lastSessionBenefitsSatisfaction" : "Very Satisfied",
            "lastSessionNoBenefits" : false,
            "lastSessionNoSideEffects" : false,
            "lastSessionSideEffectsDuration" : 3,
            "lastSessionSideEffectsNotes" : "Side effects go here",
            "lastSessionSideEffectsPeriod" : "Weeks",
            "lastSessionSideEffectsSatisfaction" : "Not bad",
            "patientHasNotUndergoneRecentSurgery" : true,
            "patientId" : 2,
            "patientIsNotBreastFeeding" : true,
            "patientIsNotPendingSurgery" : true,
            "patientIsNotPregnant" : true,
            "patientIsNotTakingAnticoagulants" : true,
            "patientReceivedBTX" : true,
            "userId" : 2,
            "sessionAssessments" : "Assessments go here",
            "sessionDate" : 1394418579065,
            "sessionHpiNotes" : "Test HPI Notes",
            "sessionId" : 1,
            "sessionRecommendations" : "I recommend the following bllah de blah",
            "injectionNotes" : "fsadfasdfsadf"
        }

    RESPONSE

        { "created" : 1394418579065,
            "createdBy" : 2,
            "lastSessionBenefitNotes" : "benefit notes go here",
            "lastSessionBenefitsDuration" : 2,
            "lastSessionBenefitsPeriod" : "Days",
            "lastSessionBenefitsSatisfaction" : "Very Satisfied",
            "lastSessionNoBenefits" : false,
            "lastSessionNoSideEffects" : false,
            "lastSessionSideEffectsDuration" : 3,
            "lastSessionSideEffectsNotes" : "Side effects go here",
            "lastSessionSideEffectsPeriod" : "Weeks",
            "lastSessionSideEffectsSatisfaction" : "Not bad",
            "patientHasNotUndergoneRecentSurgery" : true,
            "patientId" : 2,
            "patientIsNotBreastFeeding" : true,
            "patientIsNotPendingSurgery" : true,
            "patientIsNotPregnant" : true,
            "patientIsNotTakingAnticoagulants" : true,
            "patientReceivedBTX" : true,
            "userId" : 2,
            "sessionAssessments" : "Assessments go here",
            "sessionDate" : 1394418579065,
            "sessionHpiNotes" : "Test HPI Notes",
            "sessionId" : 1,
            "sessionRecommendations" : "I recommend the following bllah de blah",
            "injectionNotes" : "fsadfasdfsadf"
        }

    NOTE: version=2 API no longer has benefits and side effects fields:
            "lastSessionBenefitNotes" : "benefit notes go here",    ?? never returned anyway
            "lastSessionBenefitsDuration" : 2,
            "lastSessionBenefitsPeriod" : "Days",
            "lastSessionBenefitsSatisfaction" : "Very Satisfied",   ?? never returned anyway
            "lastSessionNoBenefits" : false,
            "lastSessionNoSideEffects" : false,
            "lastSessionSideEffectsDuration" : 3,
            "lastSessionSideEffectsNotes" : "Side effects go here", ?? never returned anyway
            "lastSessionSideEffectsPeriod" : "Weeks",
            "lastSessionSideEffectsSatisfaction" : "Not bad",       ?? never returned anyway

*/


function updateSession({
                        inSessionDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session',
                        inRequireAuth: true,
                        inQueryParams: {
                            version: 2
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inSessionDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteSessionWithSessionID({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

    Array of Session Benefit JSONs to set

        [ 
            {
                benefitId: 1,
                sessionBenefitDurationPeriod: "Week(s)",
                sessionBenefitLevel: 6
            }
        ]

    RESPONSE array of dicts

        {
            benefitId: 1,
            created: 1625823925508,
            createdBy: 63,
            sessionBenefitDuration: 0,
            sessionBenefitDurationPeriod: "Week(s)",
            sessionBenefitId: 1847,
            sessionBenefitLevel: 6,
            sessionId: 1522
        },
*/


function setSessionBenefits({
                        inSessionID,
                        inSessionBenefits
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/benefits2',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inSessionBenefits)
                        });
}

/*

    RESPONSE array of dicts

        { "benefitId" : 2,
            "created" : 1392937034559,
            "createdBy" : 2,
            "sessionBenefitLevel" : 3,
            "sessionBenefitDuration" : 2,
            "sessionBenefitDurationPeriod" : "Week(s)",
            "sessionBenefitId" : 4,
            "sessionId" : 4
        },
*/


function getSessionBenefits({
                        inSessionID,
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/benefits',
                        inRequireAuth: true,
                        inQueryParams: {
                            version: 2
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

        Array of Session Side Effect JSONs to set

        [
            {
                sessionSideEffectDurationPeriod: "Week(s)",
                sessionSideEffectLevel: 2,
                sideEffectId: 1
            }
        ]

    RESPONSE array of dicts

         {
            created: 1625823925509,
            createdBy: 63,
            sessionId: 1522,
            sessionSideEffectDuration: 0,
            sessionSideEffectDurationPeriod: "Week(s)",
            sessionSideEffectId: 1294,
            sessionSideEffectLevel: 2,
            sideEffectId: 1
        },
*/


function setSessionSideEffects({
                        inSessionID,
                        inSessionSideEffects
                        } = {}) {

return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/sideEffects2',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inSessionSideEffects)
                        });
}

/*

    RESPONSE array of dicts

    { "sideEffectId" : 2,
            "created" : 1392937034559,
            "createdBy" : 2,
            "sessionSideEffectLevel" : 3
            "sessionSideEffectDuration" : 2,
            "sessionSideEffectDurationPeriod" : "Week(s)",
            "sessionSideEffectId" : 4,
            "sessionId" : 4
        },
*/


function getSessionSideEffects({
                        inSessionID,
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/sideEffects',
                        inRequireAuth: true,
                        inQueryParams: {
                            version: 2
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

//========== TSUI


/*
    RESPONSE

        Object of Session Rating TSUI
        {
        "duration": 1.0,
        "sessionId": 24,
        "created": 1395632941537,
        "createdBy": 2,
        "sessionRatingTsuiId": 18,
        "chinRotationScore": 1.0,
        "chinRotationDirection": "Left",
        "headTiltScore": 1.0,
        "headTiltDirection": "Left",
        "anterocollisRetrocollisScore": 1.0,
        "anterocollisRetrocollisType": "Retrocollis",
        "shoulderElevationScore": 1.0,
        "shoulderElevationDirection": "Left",
        "tremorSeverity": 1.0,
        "tremorDuration": 1.0
        }
*/


function getSessionRatingTsui({
                        inSessionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/rating/tsui',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

    Object of Session Rating TSUI
        {
        "duration": 1.0,
        "sessionId": 2,
        "created": 1396317862513,
        "createdBy": 2,
        "chinRotationScore": 1.0,
        "chinRotationDirection": "Left",
        "headTiltScore": 1.0,
        "headTiltDirection": "Left",
        "anterocollisRetrocollisScore": 1.0,
        "anterocollisRetrocollisType": "Retrocollis",
        "shoulderElevationScore": 1.0,
        "shoulderElevationDirection": "Left",
        "tremorSeverity": 1.0,
        "tremorDuration": 1.0
        }

    RESPONSE

         Object of Session Rating TSUI
        {
        "duration": 1.0,
        "sessionId": 24,
        "created": 1395632941537,
        "createdBy": 2,
        "sessionRatingTsuiId": 18,
        "chinRotationScore": 1.0,
        "chinRotationDirection": "Left",
        "headTiltScore": 1.0,
        "headTiltDirection": "Left",
        "anterocollisRetrocollisScore": 1.0,
        "anterocollisRetrocollisType": "Retrocollis",
        "shoulderElevationScore": 1.0,
        "shoulderElevationDirection": "Left",
        "tremorSeverity": 1.0,
        "tremorDuration": 1.0
        }
*/


function createSessionRatingTsui({ 
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/rating/tsui',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*
    REQUEST

         Object of Session Rating TSUI
        {
        "duration": 2.0,
        "sessionId": 2,
        "created": 1396317862513,
        "createdBy": 2,
        "sessionRatingTsuiId": 2,
        "chinRotationScore": 1.0,
        "chinRotationDirection": "Left",
        "headTiltScore": 1.0,
        "headTiltDirection": "Left",
        "anterocollisRetrocollisScore": 1.0,
        "anterocollisRetrocollisType": "Retrocollis",
        "shoulderElevationScore": 1.0,
        "shoulderElevationDirection": "Left",
        "tremorSeverity": 1.0,
        "tremorDuration": 1.0
        }

    RESPONSE

        
         Object of Session Rating TSUI
        {
        "duration": 1.0,
        "sessionId": 24,
        "created": 1395632941537,
        "createdBy": 2,
        "sessionRatingTsuiId": 18,
        "chinRotationScore": 1.0,
        "chinRotationDirection": "Left",
        "headTiltScore": 1.0,
        "headTiltDirection": "Left",
        "anterocollisRetrocollisScore": 1.0,
        "anterocollisRetrocollisType": "Retrocollis",
        "shoulderElevationScore": 1.0,
        "shoulderElevationDirection": "Left",
        "tremorSeverity": 1.0,
        "tremorDuration": 1.0
        }
*/


function updateSessionRatingTsui({
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/rating/tsui',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteSessionRatingTsui({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/rating/tsui',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}




//========== TWSTRS


/*
    RESPONSE 

        Object of Session Rating TWSTRS
        {
        "sessionId": 3,
        "created": 1396321366344,
        "createdBy": 2,
        "sessionRatingTwstrsId": 2,
        "severityRotationScore": 1.0,
        "severityRotationDirection": "Left",
        "severityLaterocollisScore": 1.0,
        "severityLaterocollisRotationDirection": "Right",
        "severityLateralShiftScore": 1.0,
        "severityLateralShiftDirection": "Left",
        "severitySagittalShiftScore": 1.0,
        "severitySagittalShiftDirection": "Right",
        "severityMaximumSubmaximalType": "type",
        "severityMaximumSubmaximalScore": 1.0,
        "severityEffectsOfSensoryTricksScore": 1.0,
        "severityShoulderElevationScore": 1.0,
        "severityRangeOfMotionScore": 1.0,
        "severityTimeScore": 1.0,
        "disabilityWorkScore": 1.0,
        "disabilityActivitiesOfDailyLivingScore": 1.0,
        "disabilityDrivingScore": 1.0,
        "disabilityReadingScore": 1.0,
        "disabilityTelevisionScore": 1.0,
        "disabilityOutsideActivitiesScore": 1.0,
        "painSeverityBestScore": 1.0,
        "painSeverityWorstScore": 1.0,
        "painSeverityUsualScore": 1.0,
        "painDuration": 1.0,
        "painDegreeOf": 1.0
        }
*/


function getSessionRatingTwstrs({
                        inSessionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/rating/twstrs',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

    Object of Session Rating TWSTRS
        {
        "painDuration": 1.0,
        "severityRotationScore": 1.0,
        "severityRotationDirection": "Left",
        "severityLaterocollisScore": 1.0,
        "severityLaterocollisRotationDirection": "Right",
        "severityAnterocollisRetocollisScore": 1.0,
        "severityAnterocollisRetocollisType": "type",
        "severityLateralShiftScore": 1.0,
        "severityLateralShiftDirection": "Left",
        "severitySagittalShiftScore": 1.0,
        "severitySagittalShiftDirection": "Right",
        "severityMaximumSubmaximalType": "type",
        "severityMaximumSubmaximalScore": 1.0,
        "severityEffectsOfSensoryTricksScore": 1.0,
        "severityShoulderElevationScore": 1.0,
        "severityRangeOfMotionScore": 1.0,
        "severityTimeScore": 1.0,
        "disabilityWorkScore": 1.0,
        "disabilityActivitiesOfDailyLivingScore": 1.0,
        "disabilityDrivingScore": 1.0,
        "disabilityReadingScore": 1.0,
        "disabilityTelevisionScore": 1.0,
        "disabilityOutsideActivitiesScore": 1.0,
        "painSeverityBestScore": 1.0,
        "painSeverityWorstScore": 1.0,
        "painSeverityUsualScore": 1.0,
        "painDegreeOf": 1.0
        }

    RESPONSE

        Object of Session Rating TWSTRS
        {
        "sessionId": 4,
        "created": 1396321494167,
        "createdBy": 2,
        "modified": 1396321494262,
        "modifiedBy": 2,
        "sessionRatingTwstrsId": 3,
        "severityRotationScore": 1.0,
        "severityRotationDirection": "Left",
        "severityLaterocollisScore": 1.0,
        "severityLaterocollisRotationDirection": "Right",
        "severityLateralShiftScore": 1.0,
        "severityLateralShiftDirection": "Left",
        "severitySagittalShiftScore": 1.0,
        "severitySagittalShiftDirection": "Right",
        "severityMaximumSubmaximalType": "type",
        "severityMaximumSubmaximalScore": 1.0,
        "severityEffectsOfSensoryTricksScore": 1.0,
        "severityShoulderElevationScore": 1.0,
        "severityRangeOfMotionScore": 1.0,
        "severityTimeScore": 1.0,
        "disabilityWorkScore": 1.0,
        "disabilityActivitiesOfDailyLivingScore": 1.0,
        "disabilityDrivingScore": 1.0,
        "disabilityReadingScore": 1.0,
        "disabilityTelevisionScore": 1.0,
        "disabilityOutsideActivitiesScore": 1.0,
        "painSeverityBestScore": 1.0,
        "painSeverityWorstScore": 1.0,
        "painSeverityUsualScore": 1.0,
        "painDuration": 2.0,
        "painDegreeOf": 1.0
        }
*/


function createSessionRatingTwstrs({ 
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/rating/twstrs',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*
    REQUEST

        Object of Session Rating TWSTRS
        {
        "sessionId": 4,
        "sessionRatingTwstrsId": 3,
        "painDuration": 2.0,
        "created": 1396321494167,
        "createdBy": 2,
        "severityRotationScore": 1.0,
        "severityRotationDirection": "Left",
        "severityLaterocollisScore": 1.0,
        "severityLaterocollisRotationDirection": "Right",
        "severityLateralShiftScore": 1.0,
        "severityLateralShiftDirection": "Left",
        "severitySagittalShiftScore": 1.0,
        "severitySagittalShiftDirection": "Right",
        "severityMaximumSubmaximalType": "type",
        "severityMaximumSubmaximalScore": 1.0,
        "severityEffectsOfSensoryTricksScore": 1.0,
        "severityShoulderElevationScore": 1.0,
        "severityRangeOfMotionScore": 1.0,
        "severityTimeScore": 1.0,
        "disabilityWorkScore": 1.0,
        "disabilityActivitiesOfDailyLivingScore": 1.0,
        "disabilityDrivingScore": 1.0,
        "disabilityReadingScore": 1.0,
        "disabilityTelevisionScore": 1.0,
        "disabilityOutsideActivitiesScore": 1.0,
        "painSeverityBestScore": 1.0,
        "painSeverityWorstScore": 1.0,
        "painSeverityUsualScore": 1.0,
        "painDegreeOf": 1.0
        }

    RESPONSE

              Object of Session Rating TWSTRS
        {
        "sessionId": 4,
        "created": 1396321494167,
        "createdBy": 2,
        "modified": 1396321494262,
        "modifiedBy": 2,
        "sessionRatingTwstrsId": 3,
        "severityRotationScore": 1.0,
        "severityRotationDirection": "Left",
        "severityLaterocollisScore": 1.0,
        "severityLaterocollisRotationDirection": "Right",
        "severityLateralShiftScore": 1.0,
        "severityLateralShiftDirection": "Left",
        "severitySagittalShiftScore": 1.0,
        "severitySagittalShiftDirection": "Right",
        "severityMaximumSubmaximalType": "type",
        "severityMaximumSubmaximalScore": 1.0,
        "severityEffectsOfSensoryTricksScore": 1.0,
        "severityShoulderElevationScore": 1.0,
        "severityRangeOfMotionScore": 1.0,
        "severityTimeScore": 1.0,
        "disabilityWorkScore": 1.0,
        "disabilityActivitiesOfDailyLivingScore": 1.0,
        "disabilityDrivingScore": 1.0,
        "disabilityReadingScore": 1.0,
        "disabilityTelevisionScore": 1.0,
        "disabilityOutsideActivitiesScore": 1.0,
        "painSeverityBestScore": 1.0,
        "painSeverityWorstScore": 1.0,
        "painSeverityUsualScore": 1.0,
        "painDuration": 2.0,
        "painDegreeOf": 1.0
        }
*/


function updateSessionRatingTwstrs({
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/rating/twstrs',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteSessionRatingTwstrs({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/rating/twstrs',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



//========== Eye


/*
    RESPONSE 

        Object of Session Rating Eye
        {
        "sessionId": 6,
        "created": 1396912452691,
        "createdBy": 2,
        "modified": 1396912452732,
        "modifiedBy": 2,
        "sessionRatingEyeId": 4,
        "bsdiReadingScore": 1.0,
        "bsdiDrivingScore": 2.0,
        "bsdiWatchingTvScore": 1.0,
        "bsdiShoppingScore": 1.0,
        "bsdiEverydayActivitiesScore": 1.0,
        "bsdiWalkingScore": 1.0,
        "jrsSeverityScore": 1.0,
        "jrsFrequencyScore": 1.0
        }
*/


function getSessionRatingEye({
                        inSessionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/rating/eye',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

    Object of Session Rating Eye
        {
        "bsdiDrivingScore": 1.0,
        "bsdiReadingScore": 1.0,
        "bsdiWatchingTvScore": 1.0,
        "bsdiShoppingScore": 1.0,
        "bsdiEverydayActivitiesScore": 1.0,
        "bsdiWalkingScore": 1.0,
        "jrsSeverityScore": 1.0,
        "jrsFrequencyScore": 1.0
        }

    RESPONSE

       Object of Session Rating Eye
        {
        "sessionId": 6,
        "created": 1396912452691,
        "createdBy": 2,
        "modified": 1396912452732,
        "modifiedBy": 2,
        "sessionRatingEyeId": 4,
        "bsdiReadingScore": 1.0,
        "bsdiDrivingScore": 2.0,
        "bsdiWatchingTvScore": 1.0,
        "bsdiShoppingScore": 1.0,
        "bsdiEverydayActivitiesScore": 1.0,
        "bsdiWalkingScore": 1.0,
        "jrsSeverityScore": 1.0,
        "jrsFrequencyScore": 1.0
        }
*/


function createSessionRatingEye({ 
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/rating/eye',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*
    REQUEST

        Object of Session Rating Eye
        {
        "sessionId": 6,
        "sessionRatingEyeId": 4,
        "bsdiDrivingScore": 2.0,
        "created": 1396912452691,
        "createdBy": 2,
        "bsdiReadingScore": 1.0,
        "bsdiWatchingTvScore": 1.0,
        "bsdiShoppingScore": 1.0,
        "bsdiEverydayActivitiesScore": 1.0,
        "bsdiWalkingScore": 1.0,
        "jrsSeverityScore": 1.0,
        "jrsFrequencyScore": 1.0
        }

    RESPONSE

            Object of Session Rating Eye
        {
        "sessionId": 6,
        "created": 1396912452691,
        "createdBy": 2,
        "modified": 1396912452732,
        "modifiedBy": 2,
        "sessionRatingEyeId": 4,
        "bsdiReadingScore": 1.0,
        "bsdiDrivingScore": 2.0,
        "bsdiWatchingTvScore": 1.0,
        "bsdiShoppingScore": 1.0,
        "bsdiEverydayActivitiesScore": 1.0,
        "bsdiWalkingScore": 1.0,
        "jrsSeverityScore": 1.0,
        "jrsFrequencyScore": 1.0
        }
*/


function updateSessionRatingEye({
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/rating/eye',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteSessionRatingEye({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/rating/twstrs',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}




//========== HIT6


/*
    RESPONSE 

       Object of Session Rating HIT6
        {
        "sessionId": 2,
        "hit6ScoreByHit1": 6,
        "hit6ScoreByHit2": 8,
        "hit6ScoreByHit3": 10,
        "hit6ScoreByHit4": 11,
        "hit6ScoreByHit5": 13,
        "hit6ScoreByHit6": 8,
        "created": 1397373101731,
        "createdBy": 2,
        "modified": 1397373101740,
        "modifiedBy": 2,
        "sessionRatingHit6Id": 2
        }
*/


function getSessionRatingHit6({
                        inSessionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/rating/hit6',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

    Object of Session Rating HIT6
        {
        "hit6ScoreByHit1": 6,
        "hit6ScoreByHit2": 8,
        "hit6ScoreByHit3": 10,
        "hit6ScoreByHit4": 11,
        "hit6ScoreByHit5": 13,
        "hit6ScoreByHit6": 8
        }

    RESPONSE

       Object of Session Rating HIT6
        {
        "sessionId": 2,
        "hit6ScoreByHit1": 6,
        "hit6ScoreByHit2": 8,
        "hit6ScoreByHit3": 10,
        "hit6ScoreByHit4": 11,
        "hit6ScoreByHit5": 13,
        "hit6ScoreByHit6": 8,
        "created": 1397373101731,
        "createdBy": 2,
        "modified": 1397373101740,
        "modifiedBy": 2,
        "sessionRatingHit6Id": 2
        }
*/


function createSessionRatingHit6({ 
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/rating/hit6',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*
    REQUEST

        Object of Session Rating HIT6
        {
        "sessionId": 2,
        "hit6ScoreByHit1": 6,
        "hit6ScoreByHit2": 8,
        "hit6ScoreByHit3": 10,
        "hit6ScoreByHit4": 11,
        "hit6ScoreByHit5": 13,
        "hit6ScoreByHit6": 8,
        "created": 1397373101731,
        "createdBy": 2,
        "sessionRatingHit6Id": 2
        }

    RESPONSE

        Object of Session Rating HIT6
        {
        "sessionId": 2,
        "hit6ScoreByHit1": 6,
        "hit6ScoreByHit2": 8,
        "hit6ScoreByHit3": 10,
        "hit6ScoreByHit4": 11,
        "hit6ScoreByHit5": 13,
        "hit6ScoreByHit6": 8,
        "created": 1397373101731,
        "createdBy": 2,
        "modified": 1397373101740,
        "modifiedBy": 2,
        "sessionRatingHit6Id": 2
        }

*/


function updateSessionRatingHit6({
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/rating/hit6',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteSessionRatingHit6({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/rating/hit6',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



//========== Face


/*
    RESPONSE array of 

       Object of Session Rating Face
        {
        "sessionId": 14,
        "muscleId": 8,
        "created": 1397545171468,
        "createdBy": 2,
        "modified": 1397545171517,
        "modifiedBy": 2,
        "sessionRatingFaceId": 1,
        "faceSpasmScoreBySpasmLeftScore": 1,
        "faceSpasmScoreBySpasmRightScore": 1,
        "faceStrengthScoreByStrengthLeftScore": 2,
        "faceStrengthScoreByStrengthRightScore": 3,
        "otherObservations": "Other Observations"
        }
*/


function getSessionRatingFace({
                        inSessionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/rating/face',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

        Object of Session Rating Face
        {
        "muscleId": 8,
        "faceSpasmScoreBySpasmLeftScore": 0,
        "faceSpasmScoreBySpasmRightScore": 1,
        "faceStrengthScoreByStrengthLeftScore": 2,
        "faceStrengthScoreByStrengthRightScore": 3,
        "otherObservations": "Other Observations"
        }

    RESPONSE

       Object of Session Rating Face
        {
        "sessionId": 14,
        "muscleId": 8,
        "created": 1397545171468,
        "createdBy": 2,
        "modified": 1397545171517,
        "modifiedBy": 2,
        "sessionRatingFaceId": 1,
        "faceSpasmScoreBySpasmLeftScore": 1,
        "faceSpasmScoreBySpasmRightScore": 1,
        "faceStrengthScoreByStrengthLeftScore": 2,
        "faceStrengthScoreByStrengthRightScore": 3,
        "otherObservations": "Other Observations"
        }
*/


function createSessionRatingFace({ 
                        inSessionID,    
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/rating/face',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*
    REQUEST

        Object of Session Rating Eye
        {
        "sessionId": 14,
        "muscleId": 8,
        "sessionRatingFaceId": 1,
        "faceSpasmScoreBySpasmLeftScore": 1,
        "created": 1397545171468,
        "createdBy": 2,
        "faceSpasmScoreBySpasmRightScore": 1,
        "faceStrengthScoreByStrengthLeftScore": 2,
        "faceStrengthScoreByStrengthRightScore": 3,
        "otherObservations": "Other Observations"
        }

    RESPONSE

        Object of Session Rating Face
        {
        "sessionId": 14,
        "muscleId": 8,
        "created": 1397545171468,
        "createdBy": 2,
        "modified": 1397545171517,
        "modifiedBy": 2,
        "sessionRatingFaceId": 1,
        "faceSpasmScoreBySpasmLeftScore": 1,
        "faceSpasmScoreBySpasmRightScore": 1,
        "faceStrengthScoreByStrengthLeftScore": 2,
        "faceStrengthScoreByStrengthRightScore": 3,
        "otherObservations": "Other Observations"
        }

*/


function updateSessionRatingFace({
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/rating/face',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteSessionRatingFace({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/rating/face',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteAllSessionRatingFace({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/rating/face/all',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


//========== Qol


/*
    RESPONSE

       Object of SessionRatingQol
        {
        "sessionId": 1,
        "sessionRatingQolId": 1,
        "created": 1398756339990,
        "createdBy": 2,
        "modified": 1398756340052,
        "modifiedBy": 2,
        "q1QolScore": 2,
        "q2aQolScore": 1,
        "q2bQolScore": 1,
        "q3aQolScore": 1,
        "q3bQolScore": 1,
        "q4aQolScore": 1,
        "q4bQolScore": 1,
        "q5QolScore": 1,
        "q6aQolScore": 1,
        "q6bQolScore": 1,
        "q6cQolScore": 1,
        "q7QolScore": 1,
        "totalQolScore": 13
        }
*/


function getSessionRatingQol({
                        inSessionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/rating/qol',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

        Object of SessionRatingQol to create
        {
        "q1QolScore": 1,
        "q2aQolScore": 1,
        "q2bQolScore": 1,
        "q3aQolScore": 1,
        "q3bQolScore": 1,
        "q4aQolScore": 1,
        "q4bQolScore": 1,
        "q5QolScore": 1,
        "q6aQolScore": 1,
        "q6bQolScore": 1,
        "q6cQolScore": 1,
        "q7QolScore": 1
        }

    RESPONSE

      Object of SessionRatingQol
        {
        "sessionId": 1,
        "sessionRatingQolId": 1,
        "created": 1398756339990,
        "createdBy": 2,
        "modified": 1398756340052,
        "modifiedBy": 2,
        "q1QolScore": 2,
        "q2aQolScore": 1,
        "q2bQolScore": 1,
        "q3aQolScore": 1,
        "q3bQolScore": 1,
        "q4aQolScore": 1,
        "q4bQolScore": 1,
        "q5QolScore": 1,
        "q6aQolScore": 1,
        "q6bQolScore": 1,
        "q6cQolScore": 1,
        "q7QolScore": 1,
        "totalQolScore": 13
        }
*/


function createSessionRatingQol({ 
                        inSessionID,    
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/rating/qol',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*
    REQUEST

        Object of SessionRatingQol to update
        {
        "sessionId": 1,
        "sessionRatingQolId": 1,
        "q1QolScore": 2,
        "created": 1398756339990,
        "createdBy": 2,
        "q2aQolScore": 1,
        "q2bQolScore": 1,
        "q3aQolScore": 1,
        "q3bQolScore": 1,
        "q4aQolScore": 1,
        "q4bQolScore": 1,
        "q5QolScore": 1,
        "q6aQolScore": 1,
        "q6bQolScore": 1,
        "q6cQolScore": 1,
        "q7QolScore": 1
        }

    RESPONSE

        Object of SessionRatingQol
        {
        "sessionId": 1,
        "sessionRatingQolId": 1,
        "created": 1398756339990,
        "createdBy": 2,
        "modified": 1398756340052,
        "modifiedBy": 2,
        "q1QolScore": 2,
        "q2aQolScore": 1,
        "q2bQolScore": 1,
        "q3aQolScore": 1,
        "q3bQolScore": 1,
        "q4aQolScore": 1,
        "q4bQolScore": 1,
        "q5QolScore": 1,
        "q6aQolScore": 1,
        "q6bQolScore": 1,
        "q6cQolScore": 1,
        "q7QolScore": 1,
        "totalQolScore": 13
        }

*/


function updateSessionRatingQol({
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/rating/qol',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteSessionRatingQol({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/rating/qol',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



//========== MIDAS

/*

    RESPONSE
    Array of MidasQuestions
        [
            {
                "created": 1462259235289,
                "createdBy": 0,
                "midasQuestionId": 1,
                "midasQuestion": "On how many days in the last 3 months did you miss work or school because your headaches?"
            },
            {
                "created": 1462259235289,
                "createdBy": 0,
                "midasQuestionId": 2,
                "midasQuestion": "How many days in the last 3 months was your productivity at work or school reduced by half or more because of your headaches?\n(Do not include days you counted in question 1 where you missed work or school.)"
            },
            {
                "created": 1462259235289,
                "createdBy": 0,
                "midasQuestionId": 3,
                "midasQuestion": "On how many days in the last 3 months did you not do household work because of your headaches?"
            },
            {
                "created": 1462259235289,
                "createdBy": 0,
                "midasQuestionId": 4,
                "midasQuestion": "How many days in the last three months was your productivity in household work reduced by half of more because of your headaches?\n(Do not include days you counted in question 3 where you did not do household work.)"
            },
            {
                "created": 1462259235289,
                "createdBy": 0,
                "midasQuestionId": 5,
                "midasQuestion": "On how many days in the last 3 months did you miss family, social or leisure activities because of your headaches?"
            }
        ]
*/
function getAllMidasQuestions() {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/midas',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inCacheKey: "getAllMidasQuestions"
                        });
}


/*
    RESPONSE

       Object of SessionRatingMidas
        {
        "sessionId": 1,
        "sessionRatingMidasId": 1,
        "created": 1398756339990,
        "createdBy": 2,
        "midas1": 1,
        "midas2": 1,
        "midas3": 1,
        "midas4": 1,
        "midas5": 1,
        "totalMidasScore": 5
        }
*/


function getSessionRatingMidas({
                        inSessionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/rating/midas',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST

        Object of SessionRatingMidas to create
        {
            "sessionId": 1,
            "sessionRatingMidasId": 1,
            "midas1": 1,
            "midas2": 1,
            "midas3": 1,
            "midas4": 1,
            "midas5": 1,
        }

    RESPONSE

      Object of SessionRatingMidas
        {
        "sessionId": 1,
        "sessionRatingMidasId": 1,
        "created": 1398756339990,
        "createdBy": 2,
        "midas1": 1,
        "midas2": 1,
        "midas3": 1,
        "midas4": 1,
        "midas5": 1,
        "totalMidasScore": 5
        }


*/


function createSessionRatingMidas({ 
                        inSessionID,    
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/rating/midas',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*
    REQUEST

        Object of SessionRatingMidas to update
        {
        "sessionId": 1,
        "sessionRatingMidasId": 1,
        "midas1": 1,
        "midas2": 1,
        "midas3": 1,
        "midas4": 1,
        "midas5": 1,
        }

    RESPONSE

        Object of SessionRatingMidas
        {
        "sessionId": 1,
        "sessionRatingMidasId": 1,
        "created": 1398756339990,
        "createdBy": 2,
        "modified": 1398756340052,
        "modifiedBy": 2,
        "midas1": 1,
        "midas2": 1,
        "midas3": 1,
        "midas4": 1,
        "midas5": 1,
        "totalMidasScore": 5
        }

*/


function updateSessionRatingMidas({
                        inSessionID,
                        inRatingDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/rating/midas',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRatingDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteSessionRatingMidas({inSessionID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/rating/midas',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}






/*
    response array of dicts:

        created: 1623287169093
        createdBy: 63
        injectionId: 12786
        injectionMedicationAmount: 100
        injectionMedicationDilution: 2
        injectionMedicationId: 1
        injectionSiteAmount: 1
        injectionSiteDilution: 0.02
        injectionXPoint: 0.3411
        injectionYPoint: 0.1525
        isEmg: false
        medicationBatch: ""
        muscleImageId: 7
        sessionId: 1516
        unitByInjectionMedicationAmountUnitCode: "ml"
        unitByInjectionMedicationDilutionUnitCode: "ml"
        unitByInjectionSiteAmountUnitCode: "ml"
        unitByInjectionSiteDilutionUnitCode: "ml"
*/
function getInjectionsForSession({inSessionID} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/injection/all',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',
                        });
}



/*
    response array of dicts:

        injectionSummaryName: "Frontalis Left"
        injectionTotalAmount: 1
        lateralPosition: "Left"
        medication: "BOTOX®"
        view: "Anterior"

*/
function getInjectionSummaryForSession({inSessionID} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/injectionSummary',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}




//========== Injections


/*
    RESPONSE

       Object of Injection
        {
        "sessionId": 3,
        "muscleImageId": 1,
        "unitByInjectionMedicationAmountUnitCode": "cc",
        "unitByInjectionSiteAmountUnitCode": "mg",
        "unitByInjectionMedicationDilutionUnitCode": "mg",
        "unitByInjectionSiteDilutionUnitCode": "mg",
        "injectionMedicationId": 1,
        "injectionXPoint": 10,
        "injectionYPoint": 20,
        "injectionId": 3,
        "created": 1402450268615,
        "createdBy": 2,
        "modified": 1402450268750,
        "modifiedBy": 2,
        "injectionMedicationAmount": 2.0,
        "injectionMedicationDilution": 2.0,
        "injectionSiteAmount": 3.0,
        "injectionSiteDilution": 4.0,
        "medicationBatch": "32323",
        "medicationExpireDate": 1396912452691,
        "isEmg": false
        }
*/


function getInjection({
                        inSessionID,
                        inInjectionID
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/injection/' + inInjectionID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    REQUEST
            
        JSON Object of Injection to create
        {
        "muscleImageId": 1,
        "unitByInjectionMedicationAmountUnitCode": "mg",
        "unitByInjectionSiteAmountUnitCode": "mg",
        "unitByInjectionMedicationDilutionUnitCode": "mg",
        "injectionMedicationId": 1,
        "unitByInjectionSiteDilutionUnitCode": "mg",
        "injectionMedicationAmount": 1.0,
        "injectionMedicationDilution": 2.0,
        "injectionSiteAmount": 3.0,
        "injectionSiteDilution": 4.0,
        "injectionXPoint": 10,
        "injectionYPoint": 20,
        "medicationBatch": "32323",
        "medicationExpireDate": 1396912452691,
        "isEmg": false
        }

    RESPONSE

         Object of Injection
        {
        "sessionId": 3,
        "muscleImageId": 1,
        "unitByInjectionMedicationAmountUnitCode": "cc",
        "unitByInjectionSiteAmountUnitCode": "mg",
        "unitByInjectionMedicationDilutionUnitCode": "mg",
        "unitByInjectionSiteDilutionUnitCode": "mg",
        "injectionMedicationId": 1,
        "injectionXPoint": 10,
        "injectionYPoint": 20,
        "injectionId": 3,
        "created": 1402450268615,
        "createdBy": 2,
        "modified": 1402450268750,
        "modifiedBy": 2,
        "injectionMedicationAmount": 2.0,
        "injectionMedicationDilution": 2.0,
        "injectionSiteAmount": 3.0,
        "injectionSiteDilution": 4.0,
        "medicationBatch": "32323",
        "medicationExpireDate": 1396912452691,
        "isEmg": false
        }
*/


function createInjection({ 
                        inSessionID,    
                        inInjectionDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/injection',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inInjectionDetails)
                        });
}


/*
    REQUEST

        JSON Object of Injection to update
        {
        "sessionId": 3,
        "injectionId": 3,
        "unitByInjectionMedicationAmountUnitCode": "cc",
        "injectionMedicationId": 1,
        "created": 1402450268615,
        "createdBy": 2,
        "muscleImageId": 1,
        "unitByInjectionSiteAmountUnitCode": "mg",
        "unitByInjectionMedicationDilutionUnitCode": "mg",
        "unitByInjectionSiteDilutionUnitCode": "mg",
        "injectionMedicationAmount": 2.0,
        "injectionMedicationDilution": 2.0,
        "injectionSiteAmount": 3.0,
        "injectionSiteDilution": 4.0,
        "injectionXPoint": 10,
        "injectionYPoint": 20,
        "medicationBatch": "32323",
        "medicationExpireDate": 1396912452691
        "isEmg": false
        }

    RESPONSE

        Object of Injection
        {
        "sessionId": 3,
        "muscleImageId": 1,
        "unitByInjectionMedicationAmountUnitCode": "cc",
        "unitByInjectionSiteAmountUnitCode": "mg",
        "unitByInjectionMedicationDilutionUnitCode": "mg",
        "unitByInjectionSiteDilutionUnitCode": "mg",
        "injectionMedicationId": 1,
        "injectionXPoint": 10,
        "injectionYPoint": 20,
        "injectionId": 3,
        "created": 1402450268615,
        "createdBy": 2,
        "modified": 1402450268750,
        "modifiedBy": 2,
        "injectionMedicationAmount": 2.0,
        "injectionMedicationDilution": 2.0,
        "injectionSiteAmount": 3.0,
        "injectionSiteDilution": 4.0,
        "medicationBatch": "32323",
        "medicationExpireDate": 1396912452691,
        "isEmg": false
        }

*/


function updateInjection({
                        inSessionID,
                        inInjectionID,
                        inInjectionDetails
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/session/' + inSessionID + '/injection/' + inInjectionID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inInjectionDetails)
                        });
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteInjection({
                        inSessionID,
                        inInjectionID
                    } = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/injection/' + inInjectionID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deleteInjectionsForSession({
                        inSessionID
                    } = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/' + inSessionID + '/injection/all',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}









function getMuscleImages() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/muscleImages',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inCacheKey: "getMuscleImages"
                        });
}






function getMuscleImagesData() {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/getMuscleImagesData',
                        inRequireAuth: true,
                        inQueryParams: {
                            version: 2
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',

                        inCacheKey: "getMuscleImagesData"
                        });
}

/*

    endpoint "/session/default-injection-view" to return the default injection view payload based on session's conditions, it requires neuroAuthToken in the header and sessionId in the query parameter
    {
        "muscleGroupId": 3,
        "viewCode": "A"
    }
*/
function getDefaultInjectionView({
                        inSessionID
                    } = {}) {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/session/default-injection-view',
        inRequireAuth: true,
        inQueryParams: {
            sessionId : inSessionID
        },
        inAdditionalHeaders: {},
        inBodyPayloadData: ''
    });
}


function createSessionReport({
                        inSessionID,
                        inColor = true,
                        inGMTOffsetMins = 1 /* special number - resolve to browsers current time zone offset */
                    } = {}) {
    
    if (inGMTOffsetMins == 1) {
        var date = new Date();
        var offset = date.getTimezoneOffset();

        inGMTOffsetMins = -offset
    }    

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/createSessionReport',
                        inRequireAuth: true,
                        inQueryParams: {
                            color : inColor ? 'true' : 'false',
                            gmtOffsetMins : inGMTOffsetMins
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',
                        inResponseType : 'text',
                        inSilenceResponseDebug: true
                        });
}

/* 
    gets response as a blob:

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
*/
function downloadSessionReport({
                        inSessionID,
                        inColor = true,
                        inGMTOffsetMins = 1 /* special number - resolve to browsers current time zone offset */
                    } = {}) {
    
    if (inGMTOffsetMins == 1) {
        var date = new Date();
        var offset = date.getTimezoneOffset();

        inGMTOffsetMins = -offset
    }    

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/' + inSessionID + '/downloadSessionReport',
                        inRequireAuth: true,
                        inQueryParams: {
                            color : inColor ? 'true' : 'false',
                            gmtOffsetMins : inGMTOffsetMins
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',
                        inResponseType : 'blob',    // ensure we receive the binary data as a Blob, so we get a raw blob object in callback.
                        inSilenceResponseDebug: true
                        });
}

function emailSessionReport({
                        inSessionID,
                        inRequestParams
                    } = {}) {
    
    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/session/' + inSessionID + '/emailSessionReport',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inRequestParams)
                       });
}






/*
    response array of dicts:
    {
        recorded: "N/A"
        sessionDate: 1623840765000
        sessionHpiNotes: ""
        sessionId: 1519
        sessionPhysician: "Peter Li"
        sessionPhysicianId: 63
        totalUnits: "BOTOX® 6.0 Units"
    }

    or with scores computed where applicable
    {
        recorded: "Yes"
        sessionDate: 1488761689000
        sessionHpiNotes: "Patient complains of head turning to the right. \n& has noticed that her symptoms are alleviated by medication. \n& has noticed that her symptoms are interfering with her ability to speak. "
        sessionId: 877
        sessionPhysician: "Prajakta Kulkarni"
        sessionPhysicianId: 374
        totalDisabilityScore: "0.0"
        totalEyeScore: "1.0"
        totalHit6Score: "16.0"
        totalPainScore: "0.0"
        totalQolScore: "1"
        totalTsuiScore: "9.0"
        totalSeverityScore: "2.0"
        totalMidasScore: "0"
        totalFaceScore: "8.0"
        totalUnits: ""
    }

*/


function getSessionSummaryForPatient({
                        inPatientID,
                        inStart = 0,
                        inLimit = 25
                        } = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/patient/summary',
                        inRequireAuth: true,
                        inQueryParams: {

                            patientId : inPatientID,
                                start : inStart,
                                limit : inLimit
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



/*
    Array of Injection Objects for the new session
    [
        {
            "sessionId": 4,
            "muscleImage": {
            "image":{
            "view" : {}
            "layer" : {}
            "lateralPosition" : {}
        }
        }
            "unitByInjectionMedicationAmountUnitCode": "mg",
            "unitByInjectionSiteAmountUnitCode": "mg",
            "unitByInjectionMedicationDilutionUnitCode": "mg",
            "unitByInjectionSiteDilutionUnitCode": "mg",
            "injectionMedicationId": 1,
            "injectionXPoint": 10,
            "injectionYPoint": 20,
            "injectionId": 4,
            "created": 1402450477449,
            "createdBy": 2,
            "injectionMedicationAmount": 1.0,
            "injectionMedicationDilution": 2.0,
            "injectionSiteAmount": 3.0,
            "injectionSiteDilution": 4.0
        }
    ]

*/

function duplicateInjectionsForSession({
                                inCopyFromSessionID,
                                inCopyToSessionID,
                                inMedicationBatchString,
                                inMedicationExpiryDate} = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/duplicate/' + inCopyFromSessionID + '/' + inCopyToSessionID,
                        inRequireAuth: true,
                        inQueryParams: {

                      medicationBatch : inMedicationBatchString,
                 medicationExpireDate : inMedicationExpiryDate
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



/*
    Array of injection protocols 
    [
        {
            "sessionId": 1,
            "injectionProtocolId": 1,
            "injectionProtocolName": "Preempt",
            "created": 1486956681368,
            "createdBy": 0,
            "modified": null,
            "modifiedBy": null
        }
    ]

*/

function getAllInjectionProtocols({
                        inPatientID } = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/protocol/' + inPatientID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    Array of session attachments 
    [
        {
            attachmentName: "ES65Y4d - Imgur.mp4"
            attachmentS3Key: "1/session/1315/ES65Y4d - Imgur.mp4"
            created: 1591024801071
            createdBy: 512
            sessionAttachmentId: 47
            sessionId: 1315
        }
    ]

*/

function getAllSessionAttachmentsForSession({
                            inSessionID } = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/attachments/' + inSessionID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE

    Returns a JSON Success Response

        {
            "responseCode":0,
            "responseMsg":"https://test-btxinteract-files.s3.amazonaws.com/1/signature/1.jpeg?AWSAccessKeyId=AKIAJBPN3PHQAXPFZ7CA&Expires=1626615987&Signature=mQa0ejc7nb9m83cJx%2FCRsDjXmqo%3D"
        }
*/
function getSignedURL({
                        inURL,
                    } = {}) {
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/session/signedurl',
                        inRequireAuth: true,
                        inQueryParams: {
                            objectKey: inURL
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE

    Returns a JSON Success Response

        {
            "responseCode":0,
            "responseMsg" : "Session attachment successfully deleted."
        }
*/
function deleteSessionAttachment({
                        inAttachmentID,
                    } = {}) {
    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/session/attachments',
                        inRequireAuth: true,
                        inQueryParams: {
                            attachmentId: inAttachmentID
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}



module.exports = { 

    getSessionsForPatient,
    getSessionsForUser,
    getSession,

    createSession,
    updateSession,
    deleteSessionWithSessionID,

    setSessionBenefits,
    getSessionBenefits,

    setSessionSideEffects,
    getSessionSideEffects,


    getSessionRatingTsui,
    createSessionRatingTsui,
    updateSessionRatingTsui,
    deleteSessionRatingTsui,

    getSessionRatingTwstrs,
    createSessionRatingTwstrs,
    updateSessionRatingTwstrs,
    deleteSessionRatingTwstrs,

    getSessionRatingEye,
    createSessionRatingEye,
    updateSessionRatingEye,
    deleteSessionRatingEye,

    getSessionRatingHit6,
    createSessionRatingHit6,
    updateSessionRatingHit6,
    deleteSessionRatingHit6,

    getSessionRatingFace,
    createSessionRatingFace,
    updateSessionRatingFace,
    deleteSessionRatingFace,
    deleteAllSessionRatingFace,

    getSessionRatingQol,
    createSessionRatingQol,
    updateSessionRatingQol,
    deleteSessionRatingQol,

    getAllMidasQuestions,
    getSessionRatingMidas,
    createSessionRatingMidas,
    updateSessionRatingMidas,
    deleteSessionRatingMidas,



    getInjectionsForSession,
    getInjectionSummaryForSession,

    getInjection,
    createInjection,
    updateInjection,
    deleteInjection,

    deleteInjectionsForSession,

    getMuscleImages,
    getMuscleImagesData,

    getDefaultInjectionView,

    createSessionReport,        // <<<<<<<<<<
    downloadSessionReport,
    emailSessionReport,




    getSessionSummaryForPatient,
    duplicateInjectionsForSession,
    getAllInjectionProtocols,
    getAllSessionAttachmentsForSession,

    getSignedURL,

    deleteSessionAttachment,




    
};
