/*
    RESPONSE array of dict

     { "chartNotes" : null,
    "conditionDuration" : null,
    "conditionDurationPeriod" : null,
    "conditionFirstReported" : null,
    "conditionFirstTreated" : null,
    "consentToUseDataForHcpEducation" : false,
    "consentToUseDataForResearch" : false,
    "created" : 1392273908256,
    "createdBy" : 1,
    "dateOfBirth" : 1392273908256,
    "dateOfEducationConsent" : null,
    "dateOfResearchConsent" : null,
    "firstName" : "Aaron Peter",
    "gender" : null,
    "lastName" : "McMullen TEST",
    "middleName" : null,
    "modified" : null,
    "modifiedBy" : null,
    "occupation" : null,
    "patientConditionIds" : [ 1,
        3,
        5
      ],
    "referringPhysicianJSON": {
      "createdBy": 1,
      "created": 1484585456037,
      "practiseId": 1,
      "referringPhysicianId": 2,
      "firstName": "leo",
      "lastName": "chen",
      "physicianEmail": "leo@interact.technology"
    },
    "patientId" : 23,
    "masterPatientId" : "sdfsdf232323",
    "patientEmail" : 1,
    "treatingPhysician" : "Dr. Leo Chen"
    "referringPhysician" : "Dr. Laura Luo"
    "reportNotes" : null,
    "title" : null,
    "practiseId" : 1
  },
*/
function getAllPatients({inStart = 0,
                         inLimit = 100} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/all',
                        inRequireAuth: true,
                        inQueryParams: {
                            start : inStart,
                            limit : inLimit
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE

     { "chartNotes" : null,
    "conditionDuration" : null,
    "conditionDurationPeriod" : null,
    "conditionFirstReported" : null,
    "conditionFirstTreated" : null,
    "consentToUseDataForHcpEducation" : false,
    "consentToUseDataForResearch" : false,
    "created" : 1392273908256,
    "createdBy" : 1,
    "dateOfBirth" : 1392273908256,
    "dateOfEducationConsent" : null,
    "dateOfResearchConsent" : null,
    "firstName" : "Aaron Peter",
    "gender" : null,
    "lastName" : "McMullen TEST",
    "middleName" : null,
    "modified" : null,
    "modifiedBy" : null,
    "occupation" : null,
    "patientConditionIds" : [ 1,
        3,
        5
      ],
    "referringPhysicianJSON": {
      "createdBy": 1,
      "created": 1484585456037,
      "practiseId": 1,
      "referringPhysicianId": 2,
      "firstName": "leo",
      "lastName": "chen",
      "physicianEmail": "leo@interact.technology"
    },
    "patientId" : 23,
    "masterPatientId" : "sdfsdf232323",
    "patientEmail" : 1,
    "treatingPhysician" : "Dr. Leo Chen"
    "referringPhysician" : "Dr. Laura Luo"
    "reportNotes" : null,
    "title" : null,
    "practiseId" : 1
  },
*/
function getPatient({inPatientID} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/' + inPatientID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE array of dict

     { "chartNotes" : null,
    "conditionDuration" : null,
    "conditionDurationPeriod" : null,
    "conditionFirstReported" : null,
    "conditionFirstTreated" : null,
    "consentToUseDataForHcpEducation" : false,
    "consentToUseDataForResearch" : false,
    "created" : 1392273908256,
    "createdBy" : 1,
    "dateOfBirth" : 1392273908256,
    "dateOfEducationConsent" : null,
    "dateOfResearchConsent" : null,
    "firstName" : "Aaron Peter",
    "gender" : null,
    "lastName" : "McMullen TEST",
    "middleName" : null,
    "modified" : null,
    "modifiedBy" : null,
    "occupation" : null,
    "patientConditionIds" : [ 1,
        3,
        5
      ],
    "referringPhysicianJSON": {
      "createdBy": 1,
      "created": 1484585456037,
      "practiseId": 1,
      "referringPhysicianId": 2,
      "firstName": "leo",
      "lastName": "chen",
      "physicianEmail": "leo@interact.technology"
    },
    "patientId" : 23,
    "masterPatientId" : "sdfsdf232323",
    "patientEmail" : 1,
    "treatingPhysician" : "Dr. Leo Chen"
    "referringPhysician" : "Dr. Laura Luo"
    "reportNotes" : null,
    "title" : null,
    "practiseId" : 1
  },
*/
function searchPatientsByName({
                            inSearchFirstnameString = "",
                            inSearchLastnameString = "",
                            inFlag1 = undefined,
                            inFlag2 = undefined,
                            inFlag3 = undefined,
                            inStart = 0,
                            inLimit = 100} = {}) {
    var queryParams = {
                        firstName : inSearchFirstnameString,
                        lastName : inSearchLastnameString,
                        start : inStart,
                        limit : inLimit
                    }
    if (inFlag1 != undefined && inFlag1 != '')
        queryParams.flag1 = inFlag1
    if (inFlag2 != undefined && inFlag2 != '')
        queryParams.flag2 = inFlag2
    if (inFlag3 != undefined && inFlag3 != '')
        queryParams.flag3 = inFlag3
    
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/search/name',
                        inRequireAuth: true,
                        inQueryParams: queryParams,
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE array of dict

     { "chartNotes" : null,
    "conditionDuration" : null,
    "conditionDurationPeriod" : null,
    "conditionFirstReported" : null,
    "conditionFirstTreated" : null,
    "consentToUseDataForHcpEducation" : false,
    "consentToUseDataForResearch" : false,
    "created" : 1392273908256,
    "createdBy" : 1,
    "dateOfBirth" : 1392273908256,
    "dateOfEducationConsent" : null,
    "dateOfResearchConsent" : null,
    "firstName" : "Aaron Peter",
    "gender" : null,
    "lastName" : "McMullen TEST",
    "middleName" : null,
    "modified" : null,
    "modifiedBy" : null,
    "occupation" : null,
    "patientConditionIds" : [ 1,
        3,
        5
      ],
    "referringPhysicianJSON": {
      "createdBy": 1,
      "created": 1484585456037,
      "practiseId": 1,
      "referringPhysicianId": 2,
      "firstName": "leo",
      "lastName": "chen",
      "physicianEmail": "leo@interact.technology"
    },
    "patientId" : 23,
    "masterPatientId" : "sdfsdf232323",
    "patientEmail" : 1,
    "treatingPhysician" : "Dr. Leo Chen"
    "referringPhysician" : "Dr. Laura Luo"
    "reportNotes" : null,
    "title" : null,
    "practiseId" : 1
  },
*/
function searchPatientsInPractise({
                            inStart = 0,
                            inLimit = 100} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/practise',
                        inRequireAuth: true,
                        inQueryParams: {
                            start : inStart,
                            limit : inLimit
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*
    REQUEST:
    { 
        "masterPatientId" : "sdfsdf232323"
        "chartNotes" : "creating chart notes",
        "conditionDuration" : 5,
        "conditionDurationPeriod" : "Days",
        "consentToUseDataForHcpEducation" : false,
        "consentToUseDataForResearch" : false,
        "created" : 1441609693284,
        "createdBy" : 1,
        "dateOfBirth" : 1441609693284,
        "dateOfEducationConsent" : 1441609693284,
        "dateOfResearchConsent" : 1441609693284,
        "firstName" : "leo",
        "genderCode" : "M",
        "lastName" : "chen",
        "patientConditionIds" : [ 1,
            3,
            5
            ],
        "practiseId" : 1,
        "referringPhysician" : "Dr. leo chen",
        "title" : "Dr",
        "treatingPhysician" : "Dr. laura luo",
        "otherCondition" : "blablabla"
        "referringPhysicianJSON": {
            "practiseId": 1,
            "referringPhysicianId": 2,
            "firstName": "leo",
            "lastName": "chen",
            "physicianEmail": "leo@interact.technology"
            },
        }
    RESPONSE:

     { "chartNotes" : null,
        "conditionDuration" : null,
        "conditionDurationPeriod" : null,
        "conditionFirstReported" : null,
        "conditionFirstTreated" : null,
        "consentToUseDataForHcpEducation" : false,
        "consentToUseDataForResearch" : false,
        "created" : 1392273908256,
        "createdBy" : 1,
        "dateOfBirth" : 1392273908256,
        "dateOfEducationConsent" : null,
        "dateOfResearchConsent" : null,
        "firstName" : "Aaron Peter",
        "gender" : null,
        "lastName" : "McMullen TEST",
        "middleName" : null,
        "modified" : null,
        "modifiedBy" : null,
        "occupation" : null,
        "patientConditionIds" : [ 1,
            3,
            5
        ],
        "referringPhysicianJSON": {
        "createdBy": 1,
        "created": 1484585456037,
        "practiseId": 1,
        "referringPhysicianId": 2,
        "firstName": "leo",
        "lastName": "chen",
        "physicianEmail": "leo@interact.technology"
        },
        "patientId" : 23,
        "masterPatientId" : "sdfsdf232323",
        "patientEmail" : 1,
        "treatingPhysician" : "Dr. Leo Chen"
        "referringPhysician" : "Dr. Laura Luo"
        "reportNotes" : null,
        "title" : null,
        "practiseId" : 1
    },
*/
function createPatient({inPatientDetails} = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/patient',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inPatientDetails)
                        });
}

/*
    REQUEST:
        Patient JSON to update including array of condition Ids

        { "chartNotes" : null,
        "conditionDuration" : null,
        "conditionDurationPeriod" : null,
        "conditionFirstReported" : null,
        "conditionFirstTreated" : null,
        "consentToUseDataForHcpEducation" : false,
        "consentToUseDataForResearch" : false,
        "created" : 1392275598432,
        "createdBy" : 1,
        "dateOfBirth" : 1392273908256,
        "dateOfEducationConsent" : null,
        "dateOfResearchConsent" : null,
        "firstName" : "Aaron Peter",
        "gender" : null,
        "lastName" : "McMullen UPDATED",
        "middleName" : null,
        "occupation" : null,
        "patientConditionIds" : [ 1,
            3,
            5
            ],
        "referringPhysicianJSON": {
            "practiseId": 1,
            "referringPhysicianId": 2,
            "firstName": "leo",
            "lastName": "chen",
            "physicianEmail": "leo@interact.technology",
            "createdBy": 1,
            "created": 1484585456037,
            },
        "patientId" : 30,
        "patientEmail" : "test@test.com",
        "treatingPhysician" : "Dr. Leo Chen"
        "referringPhysician" : "Dr. Laura Luo"
        "reportNotes" : null,
        "title" : null,
        "practiseId" : 1,
        "otherCondition" : "blablabla",
        }


    RESPONSE:

     { "chartNotes" : null,
        "conditionDuration" : null,
        "conditionDurationPeriod" : null,
        "conditionFirstReported" : null,
        "conditionFirstTreated" : null,
        "consentToUseDataForHcpEducation" : false,
        "consentToUseDataForResearch" : false,
        "created" : 1392273908256,
        "createdBy" : 1,
        "dateOfBirth" : 1392273908256,
        "dateOfEducationConsent" : null,
        "dateOfResearchConsent" : null,
        "firstName" : "Aaron Peter",
        "gender" : null,
        "lastName" : "McMullen TEST",
        "middleName" : null,
        "modified" : null,
        "modifiedBy" : null,
        "occupation" : null,
        "patientConditionIds" : [ 1,
            3,
            5
        ],
        "referringPhysicianJSON": {
        "createdBy": 1,
        "created": 1484585456037,
        "practiseId": 1,
        "referringPhysicianId": 2,
        "firstName": "leo",
        "lastName": "chen",
        "physicianEmail": "leo@interact.technology"
        },
        "patientId" : 23,
        "masterPatientId" : "sdfsdf232323",
        "patientEmail" : 1,
        "treatingPhysician" : "Dr. Leo Chen"
        "referringPhysician" : "Dr. Laura Luo"
        "reportNotes" : null,
        "title" : null,
        "practiseId" : 1
    },
*/
function updatePatient({inPatientDetails} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/patient',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify(inPatientDetails)
                    });
 
}


/*

    RESPONSE
    { 
        "responseCode" : 0,
        "responseMsg" : "Delete successful..."
    }
*/
function deletePatientWithPatientID({inPatientID} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/patient/' + inPatientID,
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/* 
    response payload see below... createPatientContactDetails()
*/
function getPatientContactDetails({inPatientID} = {}) {
    return this.sendRequest({
        inMethod: 'get',
        inURI: '/rest/patient/contact-details/' + inPatientID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        });
}

/*
    body:
    
    example
    {
        "contact" : {
            "mobile" : "0123123212",
            "email" : "dummy.email@email.com",
            "postcode" : "0000"
        },
        "address" : {
            "viaAutocomplete" : false,
            "inputAddress" : "100 sydney street, sydney nsw 2000"
        }
    }

    returns:

    {
        "id": 1,
        "uuid": "d9195c99-b7c6-4dc5-bdde-ac348dcf40e1",
        "patientId": 1,
        "contact": {
            "id": 1,
            "uuid": "c6af626b-68f0-4096-a7ee-78dc73847293",
            "contactType": "PP",
            "mobile": "0123123212",
            "phoneNumber": null,
            "email": "dummy.email@email.com",
            "postcode": "0000",
            "name": null,
            "created": 1649203833806,
            "createdBy": 349,
            "modified": null,
            "modifiedBy": null
        },
        "address": {
            "id": 1,
            "uuid": "92d36949-472a-4a52-8a9f-e465015e668d",
            "viaAutocomplete": false,
            "inputAddress": "100 sydney street, sydney nsw 2000",
            "addressLine1": null,
            "addressLine2": null,
            "boxType": null,
            "boxIdentifier": null,
            "latitude": null,
            "longitude": null,
            "placeName": null,
            "countryCode": null,
            "countryName": null,
            "postcode": null,
            "stateTerritory": null,
            "street": null,
            "streetNumber": null,
            "localityName": null,
            "unit": null,
            "created": 1649203833814,
            "createdBy": 349,
            "modified": null,
            "modifiedBy": null
        },
        "created": 1649203833796,
        "createdBy": 349,
        "modified": null,
        "modifiedBy": null
    }

    NOTE: request body can include many of the fields in the response contact/address structure.
*/
function createPatientContactDetails({inPatientID, inDetails} = {}) {
    return this.sendRequest({
        inMethod: 'post',
        inURI: '/rest/patient/contact-details/' + inPatientID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: JSON.stringify(inDetails)
        });
}

/* 
    inDetails - see above 

*/
function updatePatientContactDetails({inPatientID, inDetails} = {}) {
    return this.sendRequest({
        inMethod: 'post',
        inURI: '/rest/patient/contact-details/' + inPatientID,
        inRequireAuth: true,
        inQueryParams: {},
        inAdditionalHeaders: {},
        inBodyPayloadData: JSON.stringify(inDetails)
        });
}



/*

    RESPONSE array of dict
        {
            "benefitId": 1,
            "benefitName": "Pain",
            "created": 1395208550934,
            "createdBy": 0
        },
        
  */
function getPatientBenefits({inPatientID} = {}) {
    
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/' + inPatientID + '/benefits',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*

    RESPONSE array of dict
        {
            "sideEffectId": 7,
            "sideEffectName": "Difficulty Swallowing",
            "created": 1395208550953,
            "createdBy": 0
        },
        
  */
function getPatientSideEffects({inPatientID} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/' + inPatientID + '/sideEffects',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}
        
        
/*

    RESPONSE array of dict
         {
            "hpiId": 1,
            "hpiPhraseTitle": "Patient complains of",
            "hpiPhrase": [
                {
                    "title": "head turning to the right",
                    "group": "complaint_a"
                },
                {
                    "title": "head turning to the left",
                    "group": "complaint_a"
                },
                {
                    "title": "head pulling backwards",
                    "group": "complaint_a"
                },
                {
                    "title": "head pulling forwards",
                    "group": "complaint_a"
                },
                {
                    "title": "head tilting to the left",
                    "group": "complaint_a"
                },
                {
                    "title": "head tilting to the right",
                    "group": "complaint_a"
                },
                {
                    "title": "head tremor",
                    "group": "complaint_b"
                },
                {
                    "title": "other",
                    "group": "complaint_a"
                }
            ]
        }
        
  */
function getPatientHpis({inPatientID} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/' + inPatientID + '/hpis',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}
        
        
/*

    RESPONSE array of dict
          {
                "created": 1398134951563,
                "createdBy": 0,
                "ratingTypeCode": "TWSTRS",
                "ratingTypeName": "Toronto Western Spasmodic Torticollis Rating Scale"
            },
        
  */
function getRatingTypesForPatient({inPatientID} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/' + inPatientID + '/ratingTypes',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}
        

/*

    RESPONSE array of dict
          

        
  */
function getPatientFeedbackInsights({inPatientID,
                                     inStartDate = null /* format "YYYY-MM-DD" */,
                                     inEndDate = null /* format "YYYY-MM-DD" */,
                                     inType = 1 /* 1 = QoL, 2 = HIT6 */} = {}) {

    var params = {
        patientId : inPatientID,
        type : inType
    }

    if (inStartDate != null) {
        params['startDate'] = inStartDate
    }
    if (inEndDate != null) {
        params['endDate'] = inEndDate
    }

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/feedback/insights',
                        inRequireAuth: true,
                        inQueryParams: params,
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*

    RESPONSE array of dict
          
        
  */
function getPatientFeedbackSymptoms({inPatientID,
                                    inStartDate = null /* format "YYYY-MM-DD" */,
                                    inEndDate = null /* format "YYYY-MM-DD" */,
                                    inType = 1 /* 1 = QoL, 2 = HIT6 */} = {}) {
    var params = {
        patientId : inPatientID,
        type : inType
    }

    if (inStartDate != null) {
        params['startDate'] = inStartDate
    }
    if (inEndDate != null) {
        params['endDate'] = inEndDate
    }
                                    
    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/feedback/symptoms',
                        inRequireAuth: true,
                        inQueryParams: params,
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE is a dict

    {
        "guid" : "bd6eb81d-f162-40fa-a423-67d55755a13c"
    }

*/
function createPatientGUID({inPatientID} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/createguid',
                        inRequireAuth: true,
                        inQueryParams: {
                            patientId : inPatientID
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}


/*
    RESPONSE:

    {
        "id": 1,
        "uuid": "697453898770568939719",
        "patientId": 1,
        "practiceId": 1,
        "status": "requested",
        "requestedTime": 1683859639920,
        "connectedTime": null,
        "disconnectedTime": null,
        "act": null,
        "rft": null,
        "actExpIn": null,
        "rftExpIn": null,
        "oauthSessionId": null,
        "created": 1683859639920,
        "createdBy": 349
    }
*/
function getPatientConnections({inPatientID} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/connections',
                        inRequireAuth: true,
                        inQueryParams: {
                            patient_id : inPatientID
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: '',
                        inSilenceError : true
                        });
}

/*
    RESPONSE:

    {
        "id": 1,
        "uuid": "697453898770568939719",
        "patientId": 1,
        "practiceId": 1,
        "status": "requested",
        "requestedTime": 1683859639920,
        "connectedTime": null,
        "disconnectedTime": null,
        "act": null,
        "rft": null,
        "actExpIn": null,
        "rftExpIn": null,
        "oauthSessionId": null,
        "created": 1683859639920,
        "createdBy": 349
    }
*/
function requestPatientConnection({inPatientID, inForceReconnect = false} = {}) {
// console.log("PATIENT ID: " + inPatientID);
    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/patient/connections',
                        inRequireAuth: true,
                        inQueryParams: {
                            patient_id : inPatientID,
                            force : inForceReconnect
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*
    RESPONSE:

    {
        "practiseName": "Refresh Richmond 1",
        "practiseAddress": "144 Starkey Street",
        "practiseCity": "Killarney Heights ",
        "practisePostcode": "2087",
        "countryCode": "AU",
        "practiseLogo": "1/1_Logo.png",
        "practiseLogoLight": "1/1_LogoLight.png",
        "practiseLogoDark": "1/1_LogoDark.png",
        "practiseContactName": "Prajakta...foo01",
        "practiseContactPhone": "0413025975",
        "practiseContactEmail": "carolina@c.com"
    }
*/
function getRequestConnectionDetails({inConnectionNanoID} = {}) {

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/connections/' + inConnectionNanoID,
                        inRequireAuth: false,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

/*
    REQUEST:

    {
        "lastName": "smith",
        "dateOfBirth": "1990-01-01"
    }

    RESPONSE:

    {
        "oauthUrl": "https://web.coralline.app/oauth/authorize?client_id=com.bntxinteract.inject&code_challenge=BbeKtsnYk1aDrGGq3hbfFaj6apcbpbqRhaluuKqZbBU&redirect_uri=https://test-inject.bntxinteract.com/rest/oauth2/callback&response_type=code&state=eyJjbGluaWNOYW1lIjoiUmVmcmVzaCBSaWNobW9uZCAxIiwicGF0aWVudERvYiI6IjE5OTAtMDEtMDEiLCJwYXRpZW50SWQiOiJRcUk0MjJfRTVTWEp6SGJOUnNQY0MifQ=="
    }
*/
function validatePatientConnection({inConnectionNanoID, inLastname, inDOB} = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/patient/connections/' + inConnectionNanoID + "/validate",
                        inRequireAuth: false,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify({
                                                            lastName : inLastname,
                                                            dateOfBirth : inDOB
                                                        })
                        });
}







function createPatientPref({inPrefKey, inPrefValue} = {}) {

    return this.sendRequest({
                        inMethod: 'post',
                        inURI: '/rest/patient/pref',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify({ 'key' : inPrefKey, 'value' : inPrefValue})
                        });
}

function getPatientPref({inPrefKey, inDefaultValue = null} = {}) {

    var requestPayload = {}

    requestPayload['key'] = inPrefKey

    if (inDefaultValue != null) {
        requestPayload['defaultValue'] = inDefaultValue
    }

    return this.sendRequest({
                        inMethod: 'get',
                        inURI: '/rest/patient/pref',
                        inRequireAuth: true,
                        inQueryParams: requestPayload,
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}

function updatePatientPref({inPrefKey, inPrefValue} = {}) {

    return this.sendRequest({
                        inMethod: 'put',
                        inURI: '/rest/patient/pref',
                        inRequireAuth: true,
                        inQueryParams: {},
                        inAdditionalHeaders: {},
                        inBodyPayloadData: JSON.stringify({ 'key' : inPrefKey, 'value' : inPrefValue})
                        });
}

function deletePatientPref({inPrefKey} = {}) {

    return this.sendRequest({
                        inMethod: 'delete',
                        inURI: '/rest/patient/pref',
                        inRequireAuth: true,
                        inQueryParams: {
                            key : inPrefKey
                        },
                        inAdditionalHeaders: {},
                        inBodyPayloadData: ''
                        });
}






module.exports = { 
    getAllPatients,
    getPatient,
    searchPatientsByName,
    searchPatientsInPractise,
    
    createPatient,
    updatePatient,
    deletePatientWithPatientID,

    getPatientContactDetails,
    createPatientContactDetails,
    updatePatientContactDetails,

    getPatientBenefits,
    getPatientSideEffects,
    getPatientHpis,
    getRatingTypesForPatient,

    getPatientFeedbackInsights,
    getPatientFeedbackSymptoms,

    createPatientGUID,

    getPatientConnections,
    requestPatientConnection,
    getRequestConnectionDetails,
    validatePatientConnection,


    createPatientPref,
    getPatientPref,
    updatePatientPref,
    deletePatientPref,

};
